import Breadcrumbs from "../Breadcrumbs";
import {useState, useContext} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function Care() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const [block, setBlock] = useState('')

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Уход за вещеами'
            }
        ]
    }

    return (<main className="main-payment">
            <section className="text-page pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs()}/>
                        </div>
                    </div>
                    <div className="row text-page__wrap mb">
                        <div className="col-lg-6 text-page__left f-exp">
                            <h1 className="text-page__title">Уход за вещами</h1>
                            <blockquote>
                                Бережное обращение и правильный уход за тканями и текстильными
                                изделиями способствуют увеличению срока эксплуатации продукции.
                                Cледуйте символам по уходу на мягкой этикетке внутри изделия.
                            </blockquote>
                        </div>
                        <div className="col-lg-6 text-page__right">
                            <div className="text-page__list">
                                <div className={"text-page__list-block information bd " + (block === 't1' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't1' ? '' : 't1')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-wash"/>
                                        </div>
                                        <p>Стирка</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't1' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-wash-1"/> Изделие может
                                                подвергаться машинной стирке при температуре не выше 30
                                                градусов, при этом механическое воздействие и полоскание
                                                соответствует нормальной программе машины.
                                            </p>
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-wash-2"/> Изделие может
                                                подвергаться машинной стирке при температуре не выше 40
                                                градусов, при этом механическое воздействие и полоскание
                                                соответствует нормальной программе машины.
                                            </p>
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-wash-3"/> Разрешена только
                                                ручная стирка при температуре не более 40 градусов в
                                                короткий промежуток времени мягкими моющими средствами,
                                                изделие должно слегка выжиматься без выкручивания.
                                            </p>
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-wash-4"/> Изделие не должно
                                                подвергаться стирке.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't2' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't2' ? '' : 't2')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-bleach"/>
                                        </div>
                                        <p>Отбеливание</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't2' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-bleach-1"/> Изделие не
                                                должно подвергаться отбеливанию.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't3' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't3' ? '' : 't3')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-ironing"/>
                                        </div>
                                        <p>Глажение</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't3' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-ironing-1"/> Температура
                                                утюга не должна превышать 110 градусов.
                                            </p>
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-ironing-2"/> Температура
                                                утюга не должна превышать 150 градусов.
                                            </p>
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-ironing-3"/> Изделие не
                                                должно подвергаться глажению.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't4' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't4' ? '' : 't4')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-wash-dry"/>
                                        </div>
                                        <p>Химическая чистка</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't4' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-wash-dry"/> Щадящая чистка
                                                на основе перхлорэтилена (p = perchloroethylene).
                                            </p>
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-wash-dry-1"/> Щадящая чистка
                                                только фреонами или уайт-спиритом (f=flammable).
                                            </p>
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-wash-dry-2"/> Химическая
                                                чистка запрещена.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't5' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't5' ? '' : 't5')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-spin"/>
                                        </div>
                                        <p>Отжим и сушка</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't5' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p className="ico-cont">
                                                <i className="ico ico-left ico-spin-1"/> Запрещено отжимать
                                                и высушивать в стиральной машине.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't6' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't6' ? '' : 't6')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-info"/>
                                        </div>
                                        <p>Информация по уходу за изделиями из ткани</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't6' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Стирка</h5>
                                            <p>
                                                Стирайте изделие в стиральной машине при температуре 30-40
                                                0С. Для стирки используйте мягкие моющие средства без
                                                оптических отбеливателей. При первой стирке рекомендуется
                                                стирать изделие отдельно. Механическое воздействие и
                                                полоскание должны соответствовать умеренной программе или
                                                программе деликатной стирки машины. Перед стиркой полностью
                                                застегните все застежки.
                                            </p>
                                            <p>
                                                Тщательно полоскайте изделие, поскольку остатки моющего
                                                средства могут испортить цвет изделия во время сушки. В
                                                последнюю воду полоскания добавьте специальное средство,
                                                которое удалит статическое электричество.
                                            </p>
                                            <p>
                                                Помните, что изделие из натуральных и искусственных
                                                материалов после стирки может дать усадку. Не рекомендуется
                                                сильно выкручивать и отжимать изделие на центрифуге.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Сушка</h5>
                                            <p>
                                                Изделие не рекомендуется сильно отжимать и скручивать.
                                                Аккуратно распрямите его и дайте стечь воде. При сушке
                                                избегайте прямого попадания солнечных лучей.
                                            </p>
                                            <p>
                                                На изделиях со светлым верхом после стирки целиком возможно
                                                образование потемнения в районе магнитных кнопок.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Глажка</h5>
                                            <p>
                                                Обратите внимание на символы по уходу за изделием. Если
                                                данное изделие разрешает глажение, то во избежание появления
                                                лоснящихся разводов его необходимо гладить с изнаночной
                                                стороны (со стороны подкладки) при температуре,
                                                соответствующей составу волокон. Не подвергайте глажению
                                                детали изделия с принтами.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Хранение</h5>
                                            <p>
                                                Изделие рекомендуется хранить чистым в расправленном виде,
                                                беречь от влаги. До стирки храните изделие в сухом
                                                проветриваемом месте. Не стоит надолго откладывать стирку –
                                                всевозможные загрязнения при длительном хранении
                                                разрушительно воздействуют на материал. Не храните изделие
                                                совместно с веществами, обладающими специфическим запахом.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't7' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't7' ? '' : 't7')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-info"/>
                                        </div>
                                        <p>Информация по уходу за изделиями из джинсовой ткани</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't7' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Стирка</h5>
                                            <p>
                                                Возможна машинная стирка с уменьшенным механическим
                                                воздействием при температуре 40 0С и использованием моющих
                                                средств без оптических отбеливателей. Перед стиркой
                                                застегните молнии и выверните наизнанку. Стирайте изделие
                                                отдельно от других вещей.
                                            </p>
                                            <p>
                                                Помните, что изделия из натуральных и искусственных
                                                материалов (в том числе джинсовых) имеют усадку после
                                                стирки. Во избежание большой усадки стирайте изделие в
                                                прохладной воде и не сушите в горячей сушке.
                                            </p>
                                            <p>
                                                Иногда фурнитура и молнии могут оставить следы ржавчины на
                                                ткани. Чтобы не допускать появления рыжих пятен, не
                                                замачивайте джинсовую одежду и не давайте ей длительное
                                                время находиться во влажном состоянии.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Сушка</h5>
                                            <p>
                                                Изделие не рекомендуется сильно отжимать и скручивать.
                                                Аккуратно распрямите его и дайте стечь воде. При сушке
                                                избегайте прямого попадания солнечных лучей.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Глажка</h5>
                                            <p>
                                                Во избежание появления лоснящихся разводов производите
                                                глажение с изнанки. Не подвергайте глажению детали изделия с
                                                принтами.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Хранение</h5>
                                            <p>
                                                Производится в сухом, хорошо проветриваемом месте в чистом
                                                виде. Нежелательно хранение рядом с вещами, имеющими резкий
                                                запах. Не храните изделие влажным в сложенном виде.
                                            </p>
                                            <p>
                                                Ткань джинсового изделия могла подвергаться специальной
                                                процедуре для достижения «стираного» эффекта. Наибольшее
                                                изменение цвета и оборванные нити не являются дефектом. Это
                                                результат обработки, которая является отличительной чертой
                                                модного стиля «Vintage». На изделиях, не подвергавшихся
                                                специальной процедуре отделки, в начале использования
                                                возможен сход красителя.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't8' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't8' ? '' : 't8')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-info"/>
                                        </div>
                                        <p>Информация по уходу за изделиями, требующими сухой чистки</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't8' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p>
                                                Данное изделие предусматривает только сухую чистку при
                                                уходе.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Сухая чистка</h5>
                                            <p>
                                                Сухая чистка изделия в зависимости от символа, указанного на
                                                ярлыке, может производиться в тетрахлорэтилене,
                                                монофтортотрихлорпентане или трифтортрихлорэтане,
                                                уайт-спирите. При этом чистка должна производиться без
                                                усиления механических воздействий и увеличения температуры
                                                при чистке и отжиме. При небольших загрязнениях допускается
                                                замыть верх изделия, используя теплую воду и щадящие моющие
                                                средства.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Глажение</h5>
                                            <p>
                                                Во избежание появления лоснящихся разводов производите
                                                глажение изделия со стороны подкладки. Не подвергайте
                                                глажению детали изделия с принтами.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Хранение</h5>
                                            <p>
                                                Производится в сухом, хорошо проветриваемом месте в чистом
                                                виде. Нежелательно хранение рядом с вещами, имеющими резкий
                                                запах. Не храните рядом с вещами, имеющими резкий запах. Не
                                                храните изделие влажным в сложенном виде.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't9' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't9' ? '' : 't9')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-info"/>
                                        </div>
                                        <p>Информация по уходу за трикотажными изделиями</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't9' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Стирка</h5>
                                            <p>
                                                Рекомендуется кратковременная ручная стирка (без
                                                замачивания) в теплой воде 30-40 0С щадящими моющими
                                                средствами без отбеливателей. При этом изделие должно
                                                стираться без трения, а выжиматься без выкручивания.
                                                Помните, что изделие из натуральных и искусственных
                                                материалов после стирки может дать усадку.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Сушка</h5>
                                            <p>
                                                Для сушки поместите изделие на чистую ткань, прикрывая этой
                                                же тканью сверху и, прижимая руками ткань, удалите излишнюю
                                                влагу.
                                            </p>
                                            <p>
                                                Распрямите изделие, придав ему изначальную форму на ровной
                                                поверхности, и сушите при комнатной температуре. Не
                                                подвешивайте выстиранное изделие. Изделие нельзя сушить в
                                                непосредственной близости от радиатора и под прямыми
                                                солнечными лучами.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Глажение</h5>
                                            <p>
                                                Обратите внимание на символы по уходу за изделием. Если
                                                данное изделие разрешает глажение, то во избежание появления
                                                лоснящихся разводов его необходимо гладить с изнаночной
                                                стороны по направлению петель при температуре,
                                                соответствующей составу волокон. Не подвергайте глажению
                                                детали изделия с принтами.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Хранение</h5>
                                            <p>
                                                Изделие рекомендуется хранить чистым в расправленном виде,
                                                беречь от влаги.
                                            </p>
                                            <p>
                                                До стирки храните изделие в сухом проветриваемом месте. Не
                                                стоит надолго откладывать стирку – всевозможные загрязнения
                                                при длительном хранении разрушительно воздействуют на
                                                материал. Не храните изделие совместно с веществами,
                                                обладающими специфическим запахом.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Пилинг</h5>
                                            <p>
                                                При эксплуатации трикотажных изделий образование пилинга
                                                практически неизбежно. Пилинг возникает из-за добавления
                                                синтетических материалов, особенностей структуры полотна, а
                                                также из-за неправильного ухода за изделием. Если во время
                                                длительной носки на изделии появляется пилинг (мелкие шарики
                                                из закатанных волокон), осторожно удалите их с помощью
                                                специального устройства, разложив изделие на жесткой,
                                                гладкой поверхности.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't10' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't10' ? '' : 't10')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-info"/>
                                        </div>
                                        <p>Информация по уходу за купальными изделиями</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't10' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p>
                                                Материал для купальных изделий требует обращения с особой
                                                осторожностью. Полощите изделие немедленно после
                                                использования и не оставляйте его храниться влажным.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Стирка</h5>
                                            <p>
                                                Стирайте изделие вручную, отдельно от другого белья, с
                                                мягким моющим средством.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Сушка</h5>
                                            <p>
                                                Не сушите изделие на солнце, при этом может измениться
                                                яркость цветов. Помните, что хлорированная вода разрушающе
                                                действует на цвет и материал купальных изделий. Не
                                                подвергайте изделие сильному трению о грубые поверхности.
                                            </p>
                                            <p>
                                                При использовании масла (крема, лосьона) для загара
                                                убедитесь, что полностью вымыли его из изделия после
                                                использования, т.к. оно может нанести вред материалу
                                                изделия.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't11' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't11' ? '' : 't11')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-info"/>
                                        </div>
                                        <p>Информация по уходу за изделиями из мембранных тканей</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't11' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p>
                                                Одежда из мембранных тканей требует значительного ухода.
                                                Экстремальные условия, вода, грязь, длительное использование
                                                - все это лишает изделие таких исключительных свойств, как
                                                способность «дышать», выводить влагу наружу и оставаться
                                                сухим. Чтобы сохранить мембрану, необходимо выполнять ряд
                                                процедур по ее очистке. Для этого достаточно теплой воды без
                                                моющих средств и мягкой ворсистой щетки. Нужно учитывать,
                                                что изделия с «пропиткой» желательно стирать как можно реже,
                                                т.к. при стирке такая ткань теряет свои водозащитные
                                                свойства.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Стирка</h5>
                                            <p>
                                                Рекомендуется кратковременная ручная стирка (без
                                                замачивания) в теплой воде до 300С. Для стирки необходимо
                                                использовать профессиональные стиральные средства по уходу
                                                за мембраной (например, Grange's Extreame Cleaner, Kongur
                                                Tex Care и др.). Небольшие загрязнения желательно замыть,
                                                используя теплую воду и перечисленные моющие средства.
                                                Нельзя подвергать изделие химической чистке. После стирки,
                                                изделие надо отжать вручную, используя влаговбирающий
                                                материал, но не скручивая при этом изделие.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Сушка</h5>
                                            <p>
                                                Категорически запрещается сушить мембранные материалы на
                                                батареях, обогревателях, в стиральных машинах, вблизи
                                                открытого огня. Все это приводит к разрушению мембраны.
                                                Рекомендуется сушка при комнатной температуре в
                                                расправленном виде и желательно в горизонтальном положении.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Глажение</h5>
                                            <p>
                                                Не допускается, при этом возможно отпаривание в щадящем
                                                режиме. После стирки очень важно восстановить
                                                водоотталкивающие свойства внешней ткани, для чего
                                                используют специальные пропитки в виде жидкостей или
                                                аэрозолей (например, Kongur Membrane Protection, Granger's
                                                XT Water Repellent Spray). При этом надо учитывать следующие
                                                факторы: для различных видов материалов существуют различные
                                                специальные средства; свойства пропитки на водяной основе
                                                активизируются после воздействия на одежду высокой
                                                температуры, что достигается сушкой; использование жидкостей
                                                или аэрозолей может привести к небольшому изменению цвета
                                                материала; нельзя восстанавливать водоотталкивающие покрытия
                                                на грязных вещах, т.к. при этом покрытие может полностью
                                                разрушиться (изделие нужно предварительно постирать или
                                                почистить). Хранение: производится в сухом, хорошо
                                                проветриваемом месте в чистом виде. Нежелательно хранение
                                                рядом с вещами, имеющими резкий запах. Не храните изделие
                                                влажным в сложенном виде.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                <div className={"text-page__list-block information bd " + (block === 't12' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't12' ? '' : 't12')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-info"/>
                                        </div>
                                        <p>Информация по уходу за перо – пуховыми изделиями</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't12' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <p>
                                                Согласно ГОСТ 30332-95 (ГОСТ Р 50576-93), изделия с
                                                перопуховым утеплителем не должны подвергаться стирке и
                                                глажению, поэтому при небольших загрязнениях следует замыть
                                                верх изделия, используя теплую воду и щадящие моющие
                                                средства. Это не даст пуху намокнуть, так как ткани верха
                                                изделия и пухового пакета имеют водостойкое покрытие.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Стирка</h5>
                                            <p>
                                                К стирке пухового изделия целиком прибегайте только в
                                                крайних случаях. Если Вы все-таки решили постирать пуховое
                                                изделие целиком, то возможна машинная стирка в деликатном
                                                режиме при температуре 300С с использованием специальных
                                                моющих средств, не содержащих отбеливающих веществ. Перед
                                                стиркой следует отстегнуть меховые оторочки и капюшон от
                                                изделия, соединить вместе рукава и застегнуть застежки типа
                                                Velcro (липучки). Стирка изделия с другими вещами не
                                                рекомендуется.
                                            </p>
                                            <p>
                                                Помните, что пух хорошо впитывает моющее средство, но плохо
                                                его отдает. Поэтому, во избежание мыльных разводов на ткани,
                                                изделие нуждается в дополнительном полоскании до полного
                                                вымывания моющего средства. После основной стирки
                                                рекомендуется дополнительное полоскание изделия не менее 2-3
                                                раз.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Отжим</h5>
                                            <p>Производится при высоких оборотах барабана.</p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Сушка</h5>
                                            <p>
                                                Производится в расправленном подвешенном состоянии при
                                                периодическом встряхивании. Запрещается сушить изделие на
                                                или вблизи нагревательных приборов (батареи центрального
                                                отопления и электронагреватели).
                                            </p>
                                            <p>
                                                Помните, что пух после стирки теряет часть своих основных
                                                качеств (упругость и теплоизоляцию).
                                            </p>
                                            <p>
                                                На изделиях со светлым верхом после стирки целиком возможно
                                                образование небольших разводов и пятен, а также потемнение в
                                                районе магнитных кнопок.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Глажение</h5>
                                            <p>
                                                Не допускается, при этом возможно отпаривание в щадящем
                                                режиме.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Хранение</h5>
                                            <p>
                                                Производится в сухом, хорошо проветриваемом месте в чистом
                                                виде. Нежелательно хранение рядом с вещами, имеющими резкий
                                                запах, т.к. пух имеет свойство их впитывать. Регулярно
                                                взбивайте и проветривайте изделие. Не храните изделие
                                                влажным в сложенном виде, герметично закрытым и плотно
                                                запакованным.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-page__banners">
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="/img/text-page-banner-1.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="/img/text-page-banner-1.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Магазины</h2>
                                <p>
                                    Найти ближайший магазин Baon
                                    <br/> в России и Казахстане
                                </p>
                                <a className="btn btn-primary" href="/shops">
                                    Найти магазин
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="/img/text-page-banner-2.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="/img/text-page-banner-2.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Есть вопрос?</h2>
                                <p>
                                    Звоните 8 (800) 500-73-17 (звонок по России
                                    <br/> бесплатный) или задайте вопрос на сайте
                                </p>
                                <button className="btn btn-primary" onClick={(event) => {
                                    event.preventDefault()
                                    dispatch({type: ReducerActionType.TOGGLE_QUESTION_POPUP})
                                }}>
                                    Подробнее
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}
