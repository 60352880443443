import {useState} from "react";

export default function Payments() {
    const [isShow, setIsShow] = useState(true)

    return (<div className={"product__kv-info-cols " + (isShow && "show")}>
        <p className={"product__kv-info-cols-link f-exp " + (isShow && "active")}>Оплата <i className="ico ico-arrow-bot-b" onClick={() => {setIsShow(!isShow)}}></i></p>
        {isShow && <div className="product__kv-info-cols-list">
            <ul>
                <li><p><b>Картой онлайн</b>Оплата картами Visa, Mastercard, МИР, или картой
                    рассрочки “Халва”</p></li>
                <li><p><b>При получении</b>Возможна оплата курьеру при получении наличными или
                    картой</p></li>
                <li><p><b>Подели</b>Возможна оплата при помощи сервиса оплаты покупок по частям
                    “Подели”</p></li>
            </ul>
        </div>}
    </div>)
}