import Spacer from '../Spacer/Spacer.jsx'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const Button = props => {
	return (
		<>
			<div className='mblock text-center'>
				<a href={props.params.url}>
					<img className="landing-img-btn" src={getBaseImagePath() + props.params.img} alt={props.params.img} />
				</a>
			</div>
			<Spacer />
		</>
	)
}

export default Button
