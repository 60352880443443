import axios from "axios";
import {KEY} from "../ui/dealer/AuthForm";

const axiosHttp = axios.create({
    responseType: "json",
    baseURL: process.env.REACT_APP_ENDPOINT,
});

export default function fetchData(url: string, params: any = {}, method: string = 'GET') {

    params['__url__'] = window.location.pathname
    const customer = localStorage.getItem('customer');
    params['__is_auth__'] = customer ? JSON.parse(customer)?.id : false

    axiosHttp.defaults.headers.common['Request-Url'] = window.location.pathname
    axiosHttp.defaults.headers.common['Is-Auth'] = customer ? JSON.parse(customer)?.id : false

    switch (method) {
        case 'DELETE':
            return axiosHttp.delete(url).then(response => response.data);
        case 'PUT':
            return axiosHttp.put(url, params).then(response => response.data);
        case 'POST':
            return axiosHttp.post(url, params).then(response => response.data);
        case 'GET':
            return axiosHttp.get(url).then(response => response.data);
        default:
            return axiosHttp.post(url, params).then(response => response.data);
    }
}
export function fetchDataDealer(url: string, params: any = {}, method: string = 'GET') {

    params['__url__'] = window.location.pathname
    const dealer = localStorage.getItem(KEY);
    params['__dealer__'] = dealer ? JSON.parse(dealer) : null

    switch (method) {
        case 'DELETE':
            return axiosHttp.delete(url).then(response => response.data);
        case 'PUT':
            return axiosHttp.put(url, params).then(response => response.data);
        case 'POST':
            return axiosHttp.post(url, params).then(response => response.data);
        case 'GET':
            return axiosHttp.get(url).then(response => response.data);
        default:
            return axiosHttp.post(url, params).then(response => response.data);
    }
}