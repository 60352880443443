import {useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import isEmail from 'validator/es/lib/isEmail';
import Input from 'react-phone-number-input/input';
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import Breadcrumbs from "./../Breadcrumbs";
import {checkEmailAndPhone} from "../../../api/manzana";

export default function LinkPhoneAndEmail() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const {register, getValues, trigger, reset, watch, control, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const send = () => {
        trigger().then(st => {
                if (st) {
                    dispatch({type: ReducerActionType.LOAD})
                    checkEmailAndPhone({
                        phone: getValues('phone'),
                        email: getValues('email')
                    }).then(
                        response => {
                            if(response.status === 'Ok'){
                                setIsChecked(true)
                                dispatch({type: ReducerActionType.SUCCESS})
                            }
                        }, error => {
                            dispatch({type: ReducerActionType.ERROR, payload: error.response?.data?.message || error.response?.data?.detail})
                        } )
                }else{
                    dispatch({type: ReducerActionType.ERROR, payload: 'Пожалуйста проверьте поля формы'})
                }
            }
        )


    }

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Регистрация в программе лояльности'
            }
        ]
    }

    return <main className="main-company">
        <section className="page-company pt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Breadcrumbs breadcrumbs={getBreadcrumbs()}/>
                    </div>
                </div>
                <div className="row">
                    {!isChecked && <div className="col">
                        <h2 className="f-exp text-center">Данные программы лояльности</h2>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <div className="form-wrap big col-lg-4 mx-auto ">
                            <div className="form-group">
                                <label htmlFor=""><b>Введите номер телефона, указанный при регистрации в программе
                                    лояльности</b></label>
                                <div className="form-group">
                                    <Controller
                                        control={control}
                                        name="phone"
                                        rules={{
                                            required: true,
                                            validate: v => /^[+\d]{10,20}$/.test(v)
                                        }}
                                        render={({field: {onChange, name, value}}) => (
                                            <Input
                                                className={'form-control form-control-lg ' + (errors.phone ? ' is-invalid' : '')}
                                                placeholder="+7 ___ ___-__-__"
                                                name={name}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            <div className="form-group">
                                <label htmlFor=""><b>Введите E-mail</b></label>
                                <div className="form-group">
                                    <input type="text"
                                           className={'form-control form-control-lg ' + (errors.email ? ' is-invalid' : '')}
                                           placeholder="введите email" {...register("email", {
                                        required: true,
                                        validate: {
                                            checkEmail: v => isEmail(v)
                                        }
                                    })}/>
                                    <i className="ico ico-exclamation ico-form right"/>
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            {!state.isLoading && <div className="form-group text-center">
                                <button className={'btn btn-danger'} onClick={send}>Зарегистрировать E-mail</button>
                            </div>}
                        </div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                    </div>}
                    {isChecked && <div className="col">
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                        <h2 className="f-exp text-center">Пожалуйста проверьте<br/> свою электронную
                            почту <u>{getValues('email')}</u><br/> и перейдите по ссылке в письме</h2>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>}
                </div>
            </div>
        </section>
    </main>

}