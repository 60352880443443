import {getBaseImagePath} from "../Utils";
import Money from "../../core/Money";
import {Controller, useForm} from "react-hook-form";
import Input from "react-phone-number-input/input";
import isEmail from "validator/es/lib/isEmail";
import React, {useContext, useEffect, useState} from "react";
import useModel from "../useModel";
import ModelInterface from "../../../interfaces/model/ModelInterface";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ShopInterface} from "../../../interfaces/shop/ShopInterface";
import {ModelStockShopInterface} from "../../../interfaces/model/ModelStockShopInterface";
import SabManagers from "../../checkout/SabManagers";

export default function SecondStep({
                                       model,
                                       stocks,
                                       selectedColor,
                                       selectedSize,
                                       selectedShop,
                                       quantity,
                                       setQuantity,
                                       createOrder
                                   }: {
    model: ModelInterface,
    stocks: ModelStockShopInterface[],
    selectedColor: string,
    selectedSize: string,
    selectedShop: ShopInterface,
    quantity: number,
    setQuantity: Function,
    createOrder: Function
}) {
    const {getCurrentColorName, getSelectedFrontImage} = useModel(model, selectedColor)
    const [load, setLoad] = useState(false)

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {register, getValues, reset, control, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        if (state.customer?.id) {
            reset({
                fio: state.customer.last_name + ' ' + state.customer.first_name,
                phone: state.customer.phone_with_prefix || state.customer.phone,
                email: state.customer.email
            })
        }
    }, [state.customer?.id])

    const getCurrentStock = () => {
        return stocks.find(stock => stock.special_color_name === selectedColor && stock.shop_id === selectedShop?.id && stock.size === selectedSize)
    }

    return (<div className="modal-rezerv__registrate">
        <div className="modal-basket__product">
            <div className="modal-basket__product-img">
                <img src={getBaseImagePath() + getSelectedFrontImage()?.url} alt=""/>
            </div>
            <div className="modal-basket__product-info">
                <div className="wrap">
                    <div className="left">
                        <h6>{model.inetshop_name}</h6>
                        <ul>
                            <li>Размер: {selectedSize}</li>
                            <li>Цвет: {getCurrentColorName()}</li>
                            <li>Артикул: {model?.articul}</li>
                        </ul>
                    </div>
                    <div className="right">
                        <div className="price f-exp">
                            <h6><Money value={model?.action_price}/></h6>
                            {model?.extra_param.discount > 0 && model?.base_price.amount > model?.action_price.amount &&
                                <h6 className="old"><Money value={model?.base_price}/></h6>}
                        </div>
                    </div>
                </div>
                <div className="product-numbers">
                    <i className="option opt--value opt--menus" onClick={() => {
                        if (quantity > 1) {
                            setQuantity(quantity - 1)
                        }
                    }}></i>
                    <input className="option opt--number" type="text"
                           value={quantity} onChange={() => {
                    }} readOnly={true}/>
                    <i className="option opt--value opt--plus" onClick={() => {
                        if (quantity < (getCurrentStock()?.quantity || 0)) {
                            setQuantity(quantity + 1)
                        }
                    }}></i>
                </div>
            </div>
        </div>
        <div className="info">
            <h3 className="f-exp">{selectedShop.name}</h3>
            <p>{selectedShop.extra_param?.address || selectedShop.address} {selectedShop.extra_param?.address2}</p>
            <p>{selectedShop.extra_param?.operation_time}</p>
        </div>
        <form action="">
            <div className="form-group">
                <label>ФИО</label>
                <input type="text" className={'form-control' + (errors.fio ? ' is-invalid' : '')}
                       placeholder="ФИО" {...register("fio", {required: true})}/>
            </div>
            <div className="form-group">
                <label htmlFor="">Телефон</label>
                <Controller
                    control={control}
                    name="phone"
                    rules={{
                        required: true,
                        validate: v => /^[+\d]{10,20}$/.test(v)
                    }}
                    render={({field: {onChange, name, value}}) => (
                        <Input
                            className={'form-control ' + (errors.phone ? ' is-invalid' : '')}
                            placeholder="+7 ___ ___-__-__"
                            name={name}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </div>
            <div className="form-group">
                <label htmlFor="">E-mail</label>
                <input type="text" className={'form-control' + (errors.email ? ' is-invalid' : '')}
                       placeholder="введите email" {...register("email", {
                    required: true,
                    validate: {
                        checkEmail: v => isEmail(v)
                    }
                })}/>
            </div>
            <div className="form-group">
                <label htmlFor="">Комментарий к заказу</label>
                <textarea rows={3} className={'form-control ' + (errors.comment ? ' is-invalid' : '')}
                          placeholder="Комментарий" {...register("comment")}></textarea>
            </div>
            <SabManagers/>
            <div className="form-text">
                <small>
                    Списание и начисление бонусов за покупку будет произведено
                    <br className="desk"/> на кассе магазина. При выкупе
                    заказанного товара в течение 2 дней в подарок будет начислено
                    500 подарочных бонусов.
                </small>
            </div>
            {!load && <div className="form-btns">
                <button className="btn btn-secondary" type={'button'} onClick={() => {
                    if (!Object.keys(errors).length) {
                        dispatch({type: ReducerActionType.LOAD})
                        createOrder(getValues())
                        setLoad(true)
                    }
                }}>
                    Отправить заказ
                </button>
            </div>}
            <div className="form-text">
                <p>
                    Нажимая кнопку «Отправить», Вы соглашаетесь
                    <br className="mob"/> с <a href="/landing/26">Политикой конфиденциальности</a>
                    <br className="desk"/> и <a href="/landing/27">Политикой обработки cookies</a>
                </p>
            </div>
        </form>
    </div>)
}