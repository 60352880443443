import ModelColorItemInterface from "../../../interfaces/model/ModelColorItemInterface";
import {useQuery} from "@tanstack/react-query";
import {getModelColorItems} from "../../../api/catalog";
import {useContext} from "react";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../../GlobalReducer";
import ModelListCard from "../../catalog/ModelListCard";

export default function Favorite() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const getPathName = () => {
        const favorites: ModelColorItemInterface[] = state.favorites
        return favorites.map(modelColorItem => modelColorItem.model_id).join(',')
    }

    const {data: modelColorItemsData} = useQuery({
        queryKey: ['favorite'],
        queryFn: () => getModelColorItems({
            url: '/catalog/index/models/' + getPathName()
        })
    })

    const {model_color_items: modelColorItems, meta, category, breadcrumbs, banners, tags} = modelColorItemsData || {
        model_color_items: [],
        meta: undefined
    }

    const getFavoriteItems = () => {
        const favorites: ModelColorItemInterface[] = state.favorites
        return modelColorItems.filter(modelColorItem => favorites.find(modelColorItemFav => modelColorItemFav.model_id === modelColorItem.model_id && modelColorItemFav.color_special === modelColorItem.color_special))
    }


    return (<div className="tab-pane fade active show">
            <h3 className="lk__title f-exp desk">Избранное</h3>
            <div className="lk__favorite">
                {!getFavoriteItems().length && <h1 className={'text-center text-danger'}>В избранном ничего нет</h1>}
                {getFavoriteItems().map(modelColorItem => <ModelListCard modelColorItem={modelColorItem} />)}
            </div>
        </div>
    )
}