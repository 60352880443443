import '../../styles/styles.scss'
import Spacer from '../Spacer/Spacer.jsx'

const ButtonElem = props => {
	return (
		<>
			<div className={'text-center'}>
				<a
					href={props.params.url}
					className={
						props.params.clsArray ? props.params.clsArray.join(' ') : 'btn btn-secondary'
					}
				>
					{props.params.text}
				</a>
			</div>
			<Spacer />
		</>
	)
}

export default ButtonElem
