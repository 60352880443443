import {useLocation, useParams} from "react-router-dom";
import Landing from "./Landing";
import {loadLanding} from "../../api/landing";
import {useQuery} from "@tanstack/react-query";
import {useContext, useEffect} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

type QueryDataParams = {
    landingId: string
}


export default function LandingByRoute() {
    const landingIdByPath: any = {
        'investors': 60
    }

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const queryData = useParams<QueryDataParams>()
    const location = useLocation()

    const getLandingId = () => {
        let path = location.pathname.substring(1, 200);
        path = path.substring(0, path.length - 1);

        if (landingIdByPath[path]) {
            return landingIdByPath[path]
        }

        return queryData.landingId || ''
    }

    const {data, status, error} = useQuery({
        queryKey: [...['landing'], [location.pathname], queryData],
        queryFn: () => loadLanding(getLandingId())
    })

    useEffect(() => {
        if(!data) return

        const meta = {
            title: data.title || data.name,
            description: data.description || ''
        }

        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: meta})
    }, [data]);


    return (<>
        <main className="landing-page">
            {data?.items?.length && <div className="landing"><Landing landingItems={data.items}
                                                                      error={error ? error.name + error.message : ''}/>
            </div>}
        </main>
    </>)
}