import ModelInterface from "../../interfaces/model/ModelInterface";
import {useEffect, useState} from "react";
import {NumericFormat} from "react-number-format";
import {ColorInterface} from "../../interfaces/dealer/ColorInterface";
import {StatisticByCapsule, StatisticByCategory, StatisticByGender} from "../../interfaces/dealer/StatisticItemsInterface";
import {OrderInterface as OrderForCreateInterface} from "../../interfaces/dealer/create/OrderInterface";
import {OrderPositionInterface} from "../../interfaces/dealer/create/OrderPositionInterface";

const KEY_DELIMITER = '|';

const FormatedNumber = ({value}: {value: number}) => {
    return <NumericFormat value={value} displayType={'text'} thousandSeparator={' '} renderText={(value) => { return value; }} />
}

interface StatisticByModel {
    quantity: number,
    sum: number,
    price: number,
    model: ModelInterface,
    colors: ColorInterface[]
    positions: OrderPositionInterface[]
}


export default function OrderStatisticForCreateOrder({order}: {
    order: OrderForCreateInterface
}) {

    const [statistic, setStatistic] = useState<{
        byCapsule: StatisticByCapsule[],
        byCategory: StatisticByCategory[],
        byModel: StatisticByModel[],
        byGender: StatisticByGender[]
    } | undefined>(undefined)

    useEffect(() => {
        setStatistic(getStatistic())
    }, [order])

    const getStatistic = () => {
        let statisticByCapsule = new Map<string, StatisticByCapsule>();
        let statisticByCategory = new Map<string, StatisticByCategory>();
        let statisticByModel = new Map<string, StatisticByModel>();
        let statisticByGender = new Map<string, StatisticByGender>();

        order.positions.map(position => {
            const model = position.model

            const gender = model?.gender || {id: '0', name: 'Без пола'}
            const category = model?.category || {id: '0', name: 'Без категории'}
            const capsuleRelation = model?.capsules_relations?.find(relation => relation.color_special === position.color.special)
            const capsule = model?.capsules.find(capsule => capsule.id === capsuleRelation?.capsuleId) || {
                id: '0',
                name: 'Без капсулы'
            }

            const keyByCapsule: string = gender.id + KEY_DELIMITER + capsule.id + KEY_DELIMITER + category.id;
            const quantity: number = (statisticByCapsule.get(keyByCapsule)?.quantity || 0) + position.quantity
            const sum: number = (statisticByCapsule.get(keyByCapsule)?.sum || 0) + (position.quantity * (position?.model?.dealer_price?.amount || 0))
            statisticByCapsule.set(keyByCapsule, {
                quantity: quantity,
                sum: sum,
                capsule: capsule,
                category: category,
                gender: gender
            })

            const keyByCategory: string = gender.id + KEY_DELIMITER + category.id;
            const quantity2: number = (statisticByCategory.get(keyByCategory)?.quantity || 0) + position.quantity
            const sum2: number = (statisticByCategory.get(keyByCategory)?.sum || 0) + (position.quantity * (position?.model?.dealer_price?.amount || 0))
            statisticByCategory.set(keyByCategory, {
                quantity: quantity2,
                sum: sum2,
                category: category,
                gender: gender
            })


            const keyByModel: string = model.id;
            const quantity3: number = (statisticByModel.get(keyByModel)?.quantity || 0) + position.quantity
            const sum3: number = (statisticByModel.get(keyByModel)?.sum || 0) + (position.quantity * (position?.model?.dealer_price?.amount || 0))
            statisticByModel.set(keyByModel, {
                price: position?.model?.dealer_price?.amount || 0,
                quantity: quantity3,
                sum: sum3,
                model: model,
                colors: (statisticByModel.get(keyByModel)?.colors || []).concat([{color: position.color.color, hex: position.color.hex, humanName: position.color.humanName, special: position.color.special}]).filter((v, i, a) => a.findIndex(t => (t.color === v.color && t.special === v.special)) === i),
                positions: (statisticByModel.get(keyByModel)?.positions || []).concat([position])
            })

            const quantity4: number = (statisticByGender.get(model.gender.id)?.quantity || 0) + position.quantity
            const sum4: number = (statisticByGender.get(model.gender.id)?.sum || 0) + (position.quantity * (position.model.dealer_price?.amount || 0))
            statisticByGender.set(model.gender.id, {
                quantity: quantity4,
                sum: sum4,
                gender: gender
            })
        })

        console.log(Array.from(statisticByModel.values()))

        return {
            byCapsule: Array.from(statisticByCapsule.values()),
            byCategory: Array.from(statisticByCategory.values()),
            byModel: Array.from(statisticByModel.values()),
            byGender: Array.from(statisticByGender.values())
        }
    }

    const getTotalQuantity = () => {
        return order.positions.reduce((acc, current) => acc + current.quantity, 0)
    }

    const getTotalSum = () => {
        return order.positions.reduce((acc, current) => acc + current.quantity * (current.model.dealer_price?.amount || 0), 0)
    }

    return (<div>
        <h1 className={'text-center'}>Общая статистика по заказу</h1>
        <h3>По капсулам</h3>
        {statistic && <table className="table table striped">
            <thead>
            <tr>
                <th>Пол</th>
                <th>Капсула</th>
                <th>Категория</th>
                <th className={'text-end'}>Количество, шт</th>
                <th className={'text-end'}>Сумма, руб.</th>
                <th className={'text-end'}>Количество, %</th>
                <th className={'text-end'}>Сумма, %</th>
            </tr>
            </thead>
            <tbody>
            {statistic.byCapsule.map((recordByCapsule: StatisticByCapsule, index: number  ) => <tr key={index}>
                <td>{recordByCapsule.gender.name}</td>
                <td>{recordByCapsule.capsule.name}</td>
                <td>{recordByCapsule.category.name}</td>
                <td className={'text-end'}><FormatedNumber value={recordByCapsule.quantity} /></td>
                <td className={'text-end'}><FormatedNumber value={recordByCapsule.sum / 100} /></td>
                <td className={'text-end'}>{(recordByCapsule.quantity/ getTotalQuantity() * 100).toFixed(2)}</td>
                <td className={'text-end'}>{(recordByCapsule.sum / getTotalSum() * 100).toFixed(2)}</td>
                </tr>)
            }
            </tbody>
            <thead style={{borderTop: '3px solid black'}}>
            {statistic.byGender.map((recordByGender: StatisticByGender, index: number  ) => <tr key={index}>
                <th colSpan={3} className={'text-end'}>ВСЕГО ({recordByGender.gender.name})</th>
                <th className={'text-end'}><FormatedNumber value={recordByGender.quantity} /></th>
                <th className={'text-end'}><FormatedNumber value={recordByGender.sum / 100} /></th>
                <th className={'text-end'}>{(recordByGender.quantity/ getTotalQuantity() * 100).toFixed(2)}</th>
                <th className={'text-end'}>{(recordByGender.sum / getTotalSum() * 100).toFixed(2)}</th>
            </tr>)}
            </thead>
            <thead>
                <tr>
                    <th colSpan={3} className={'text-end'}>ВСЕГО</th>
                    <th className={'text-end'}><FormatedNumber value={getTotalQuantity()} /></th>
                    <th className={'text-end'}><FormatedNumber value={getTotalSum() / 100} /></th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
        </table>}

        <h3>По категориям</h3>
        {statistic && <table className="table table striped">
            <thead>
            <tr>
                <th>Пол</th>
                <th>Категория</th>
                <th className={'text-end'}>Количество, шт</th>
                <th className={'text-end'}>Сумма, руб.</th>
                <th className={'text-end'}>Количество, %</th>
                <th className={'text-end'}>Сумма, %</th>
            </tr>
            </thead>
            <tbody>
            {statistic.byCategory.map((recordByCategory: StatisticByCategory, index: number  ) => <tr key={index}>
                <td>{recordByCategory.gender.name}</td>
                <td>{recordByCategory.category.name}</td>
                <td className={'text-end'}><FormatedNumber value={recordByCategory.quantity} /></td>
                <td className={'text-end'}><FormatedNumber value={recordByCategory.sum / 100} /></td>
                <td className={'text-end'}>{(recordByCategory.quantity/ getTotalQuantity() * 100).toFixed(2)}</td>
                <td className={'text-end'}>{(recordByCategory.sum / getTotalSum() * 100).toFixed(2)}</td>
                </tr>)
            }
            </tbody>
            <thead style={{borderTop: '3px solid black'}}>
            {statistic.byGender.map((recordByGender: StatisticByGender, index: number  ) => <tr key={index}>
                <th colSpan={2} className={'text-end'}>ВСЕГО ({recordByGender.gender.name})</th>
                <th className={'text-end'}><FormatedNumber value={recordByGender.quantity} /></th>
                <th className={'text-end'}><FormatedNumber value={recordByGender.sum / 100} /></th>
                <th className={'text-end'}>{(recordByGender.quantity/ getTotalQuantity() * 100).toFixed(2)}</th>
                <th className={'text-end'}>{(recordByGender.sum / getTotalSum() * 100).toFixed(2)}</th>
            </tr>)}
            </thead>
            <thead>
                <tr>
                    <th colSpan={2} className={'text-end'}>ВСЕГО</th>
                    <th className={'text-end'}><FormatedNumber value={getTotalQuantity()} /></th>
                    <th className={'text-end'}><FormatedNumber value={getTotalSum() / 100} /></th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
        </table>}

        <h3>Детальная статистика по заказу</h3>
        {statistic && statistic.byModel.map(recordByModel => <div key={recordByModel.model.id}>
            <h5>
                {recordByModel.model.capsules.map(capsule => capsule.name).join(', ') || 'Без капсулы'}&nbsp;/&nbsp;
                {recordByModel.model.category.name}&nbsp;/&nbsp;
                {recordByModel.model.gender.name}&nbsp;/&nbsp;
                {recordByModel.model.inetshop_name} {recordByModel.model.articul}
            </h5>

            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>Цвет</th>
                    <th className={'text-end'}>Цена</th>
                    <th className={'text-end'}>Штук</th>
                    <th className={'text-end'}>Сумма</th>
                    {recordByModel.model.sizes.map(size => <th style={{width: '50px'}} className={'text-end'} key={size}>{size}</th>)}
                    <th>Итог</th>
                </tr>
                </thead>
                <tbody>
                {recordByModel.colors.map((color, index) => {

                    const printImage = recordByModel.model.images.find(image => image.special_color_name === color.special && image.type === 'print')


                    return <tr key={color.special}>
                        <td>
                            <div className={'dealer-color-order-position d-print-none'} style={printImage ? {background: 'url(' + printImage.url + ')', backgroundSize: 'cover'} : {backgroundColor: color.hex}}></div> {color.color}</td>
                        {!index && <td className={'text-end '} style={{verticalAlign: 'middle'}} rowSpan={recordByModel.colors.length}><FormatedNumber value={recordByModel.price / 100}/></td>}
                        {!index && <td className={'text-end'} style={{verticalAlign: 'middle'}} rowSpan={recordByModel.colors.length}><FormatedNumber value={recordByModel.quantity}/></td>}
                        {!index && <td className={'text-end'} style={{verticalAlign: 'middle'}} rowSpan={recordByModel.colors.length}><FormatedNumber value={recordByModel.sum / 100}/></td>}
                        {recordByModel.model.sizes.map(size => {

                            const quantity = recordByModel.positions.find(position => position.size === size && position.color.special === color.special)?.quantity || 0
                            return <td key={size}
                                       className={'text-end ' + (quantity && 'bg-warning-lighter')}>{quantity}</td>
                        })}
                        <td className={'text-end bg-success-lighter'}
                            style={{width: '50px'}}>{recordByModel.positions.filter(position => position.color.special === color.special).reduce((acc, current) => acc + current.quantity, 0)}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>)}
    </div>)
}