import React, {ReactNode, useContext} from "react";
import {GlobalContext} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function CentralModal({children}: {children: ReactNode}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    return (<div className={"modal fade modal-system modal-main-system " + (state.error ? "show" : "")}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>
    </div>)
}