import DictionaryInitFooterInterface from "../../interfaces/api/DictionaryInitInterface";
import moment from "moment/moment";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import React, {useContext} from "react";
import TechInfo from "./TechInfo";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import FooterDropdown from "../core/FooterDropdown";


interface FooterProps extends DictionaryInitFooterInterface {
    contact: {
        phones: {
            office: string
            shop: string
        }
    }
}

export default function Footer({navigation, partners, socialServices, paymentTypes, contact}: FooterProps) {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const getYear = () => {
        return moment().format('YYYY')
    }

    return (<footer>
        <div className="container-fluid">
            <div className="footer__wrap">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="footer__shop">
                            <h5 className="f-exp">Попробуйте наше<br className="desk"/> приложение.<br
                                className="mob"/> Покупать<br className="desk"/> стало еще проще.</h5>
                            <img className="desk" src="/img/qr.png" alt="" width="260"/>
                            <div className="btns-app mob">
                                <ul>
                                    <li><a className="btn-shop btn-shop--ap" href="https://apps.apple.com/ru/app/id1082678582" target={'_blank'}><img src="/img/svg/btn-ap.svg"
                                                                                          alt=""/></a></li>
                                    <li><a className="btn-shop btn-shop--gg" href="https://play.google.com/store/apps/details?id=com.notissimus.baon&hl=ru"  target={'_blank'}><img src="/img/svg/btn-gg.svg"
                                                                                          alt=""/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="footer__menu">
                            <div className="row desk">
                                {navigation.map((item: any) =>
                                    <div className="col-lg-4" key={item.name}>
                                        <h3 className="footer__menu-link f-exp">{item.name}</h3>
                                        <ul className="footer__menu-collapse">
                                            {item.items.map((sitem: any) =>
                                                <li key={sitem.url}><a href={sitem.url}>{sitem.name}</a></li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                              <div className="row mob">
                                    {navigation.map((item: any) => (
                                        <div className="col-lg-4" key={item.name}>
                                            <FooterDropdown>       
                                                    <FooterDropdown.Header>
                                                        {item.name}
                                                    </FooterDropdown.Header>
                                                    <FooterDropdown.Content>
                                                        <ul className="footer__menu-collapse">
                                                            {item.items.map((sitem: any) => (
                                                                <li key={sitem.url}>
                                                                    <a href={sitem.url}>{sitem.name}</a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </FooterDropdown.Content>
                                            </FooterDropdown>
                                        </div>
                                    ))}
                                </div>
                        </div>
                        <div className="footer__data">
                            <div className="row">
                                <div className="col-lg-4 col-6">
                                    <h4>Интернет магазин</h4>
                                    <a className="phone f-exp"
                                       href={"tel:" + contact.phones.shop.replace(/[^0-9]/g, '')}>{contact.phones.shop}</a>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <h4>Офис</h4>
                                    <a className="phone f-exp"
                                       href={"tel:" + contact.phones.office.replace(/[^0-9]/g, '')}>{contact.phones.office}</a>
                                </div>
                                <div className="col-lg-4 desk">
                                    <h4>Мы в соцсетях</h4>
                                    <ul className="social">
                                        {socialServices.map((item: any) => <li key={item.icon}><a
                                            className={"soc " + item.icon} href={item.url}></a></li>)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer__btns">
                            <div className="row">
                                <div className="col mob">
                                    <div className="footer__btns-list">
                                        <p>Мы в соцсетях</p>
                                        <ul className="social">
                                            {socialServices.map((item: any) => <li key={item.icon}><a
                                                className={"soc " + item.icon} href={item.url}></a></li>)}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="footer__btns-list">
                                        <p>Способы оплаты</p>
                                        <ul className="cards">
                                            {paymentTypes.map((item: any) => <li key={item.image}><img src={item.image}
                                                                                                       alt=""
                                                                                                       width="52"/>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 desk">
                                </div>
                                <div className="col-lg-4">
                                    <div className="footer__btns-list">
                                        <p>Партнеры</p>
                                        <ul className="partners">
                                            {partners.map((item: any) => <li key={item.image}><img src={item.image} alt="" width="32" /></li> )}
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {state.showTechInfo && <TechInfo />}
            <span className={'text-muted cursor-pointer t-info'} onClick={() => {
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showTechInfo', payload: !state.showTechInfo})
            }}>.</span>


            <div className="footer__bot">
                <p className="f-exp">© Baon, 2002-{getYear()}</p>
            </div>
        </div>
    </footer>
)
}
