import {useQuery} from "@tanstack/react-query";
import {chooseYourLook} from "../../../api/page";
import React, {useContext, useEffect, useState} from "react";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import ChooseYourLook from "../ChooseYourLook";
import Item from "./Item";
import ChooseYourLookItemInterface from "../../../interfaces/main/ChooseYourLookItemInterface";
import Modal from "./Modal";

export function Page() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [currentSlide, setCurrentSlide] = useState<number>(0)
    const [localItems, setLocalItems] = useState<ChooseYourLookItemInterface[]>([])
    const [localItemsOnPage, setLocalItemsOnPage] = useState<ChooseYourLookItemInterface[]>([])
    const [selectedItem, setSelectedItem] = useState<ChooseYourLookItemInterface | null>(null)

    const {data: chooseYourLookItems} = useQuery({
        queryKey: ['page', 'choose-your-look', !!state.customer?.id],
        queryFn: () => chooseYourLook(!!state.customer?.id)
    })

    useEffect(() => {
        if (chooseYourLookItems) {
            const sortedItems = chooseYourLookItems.filter(item => item.on_main && item.is_active).sort((a: ChooseYourLookItemInterface, b: ChooseYourLookItemInterface) => {
                if(a.sort_id && b.sort_id){
                    if(a.sort_id === b.sort_id) return 0
                    return a.sort_id > b.sort_id ? 1 : -1
                }

                return a.id > b.id ? 1 : -1
            })
            setLocalItems(JSON.parse(JSON.stringify(sortedItems)))
            setLocalItemsOnPage(JSON.parse(JSON.stringify(sortedItems)))
        }
    }, [chooseYourLookItems]);

    const changeItem = (item: ChooseYourLookItemInterface) => {
        const objIndex = localItemsOnPage.findIndex(litem => item.id == litem.id);
        localItemsOnPage[objIndex].points = item.points
        setLocalItemsOnPage([...localItemsOnPage])
        console.log(item.points)
        // setSelectedItem(item)
    }

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBackdrop', payload: !!selectedItem})
    }, [selectedItem]);

    return (<main>
        {selectedItem && <Modal setSelectedItem={setSelectedItem} item={selectedItem}/>}
        <section className="page-look pt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <ul className="breadcrumb desk">
                            <li className="breadcrumb-item active"><a href=""><i className="ico ico-home-g"></i></a>
                            </li>
                            <li className="breadcrumb-item" aria-current="page">Все образы</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="page-look__title f-exp"><h1>все образы</h1><h2>Все образы</h2></div>
                    </div>
                </div>
            </div>
        </section>

        {localItems.length > 0 && <ChooseYourLook items={localItems} showIntro={false}/>}

        <section className="page-look">
            <div className="container-fluid">
                <div className="row">
                    <div className="col page-look__list">

                        {localItemsOnPage.length > 0 && localItemsOnPage.map((item, index) => {

                            let type = 'center'

                            switch ((index + 3) % 3) {
                                case 1:
                                    type = 'start';
                                    break;
                                case 2:
                                    type = 'end';
                                    break;
                            }

                            const hasActivePoints = item.points.filter(point => point.isActive).length > 0

                            if(index%2 == 0){
                                return (<div key={item.id} className={"page-look__wrap align-" + type + " row"}>
                                    <div className="offset-lg-2 col-lg-3 order--1">
                                        <div className={"page-look__img " + (hasActivePoints && 'active-inst')}>
                                            <Item item={item} changeItem={changeItem} setSelectedItem={setSelectedItem}/>
                                        </div>
                                    </div>
                                    <div className="offset-lg-1 col-lg-6 page-look__text order--2">
                                        <h1 className="page-look__text-title f-exp">{item.title || item.name}</h1>
                                        <p>{item.description}</p>
                                    </div>
                                </div>)
                            }else{
                                return (<div key={item.id} className={"page-look__wrap align-" + type + " row"}>
                                    <div className="col-lg-7 page-look__text order--2">
                                        <h1 className="page-look__text-title f-exp">{item.title || item.name}</h1>
                                        <p>{item.description}</p>
                                    </div>
                                    <div className="offset-lg-1 col-lg-3 order--1">
                                        <div className={"page-look__img " +  (hasActivePoints && 'active-inst')}>
                                            <Item item={item} changeItem={changeItem}
                                                  setSelectedItem={setSelectedItem}/>
                                        </div>
                                    </div>
                                </div>)
                            }
                        })}
                    </div>
                </div>
            </div>
        </section>
    </main>)
}