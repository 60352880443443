import React, {useRef, useState, useEffect} from "react";
import Slider from "react-slick";
import {getBaseImagePath} from "../Utils";
import ModelImageInterface from "../../../interfaces/model/ModelImageInterface";
import ModelVideoInterface from "../../../interfaces/model/ModelVideoInterface";
import BreadcrumbInterface from "../../../interfaces/core/BreadcrumbInterface";
import {useNavigate} from "react-router-dom";
import {convertImageToSize, ImageSize} from "../../../utils/Image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SliderSlick({
    images,
    videos = [],
    altString,
    breadcrumbs,
    onImageClick,
}: {
    images: ModelImageInterface[];
    videos?: ModelVideoInterface[];
    altString: string;
    breadcrumbs: BreadcrumbInterface[];
    onImageClick?: (index: number) => void; 
}) {
    const [slideIndex, setSlideIndex] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [videoThumbnails, setVideoThumbnails] = useState<string[]>([]);
    const mainSliderRef = useRef<Slider | null>(null);
    const thumbSliderRef = useRef<Slider | null>(null);
    const navigate = useNavigate();

    const sliderRef = useRef(null);

    useEffect(() => {
        const generateThumbnails = async () => {
            const thumbnails: string[] = [];
            for (const video of videos) {
                const thumbnail = await createVideoThumbnail(video.url);
                thumbnails.push(thumbnail);
            }
            setVideoThumbnails(thumbnails);
        };

        generateThumbnails();
    }, [videos]);

    const createVideoThumbnail = (videoUrl: string): Promise<string> => {
        return new Promise((resolve) => {
            const videoElement = document.createElement("video");
            videoElement.src = videoUrl;
            videoElement.currentTime = 1;

            videoElement.addEventListener("loadeddata", () => {
                const canvas = document.createElement("canvas");
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                const context = canvas.getContext("2d");
                if (context) {
                    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                    const thumbnail = canvas.toDataURL("image/png");
                    resolve(thumbnail);
                }
            });

            videoElement.load();
        });
    };

    const mainSliderSettings = {
        asNavFor: thumbSliderRef.current || undefined,
        slidesToShow: 2.3,
		// swipeToSlide: true,
		initialSlide: 0, 
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        fade: false,
        centerMode: false,
        centerPadding: "0px",
		speed: 300,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                    autoplay: false,
					initialSlide: 0, 
                },
            },
        ],
        beforeChange: (_: number, next: number) => {
            setSlideIndex(next);
            if (thumbSliderRef.current) {
                thumbSliderRef.current.slickGoTo(next);
            }
        },
    };

    const thumbSliderSettings = {
        asNavFor: mainSliderRef.current || undefined,
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: videoThumbnails.length + images.length > 9,
        arrows: false,
        dots: false,
        centerMode: true,
        centerPadding: "0px",
        focusOnSelect: true,
        swipeToSlide: true,
		speed: 300,
        beforeChange: (_: number, next: number) => {
            setSlideIndex(next);
            if (mainSliderRef.current) {
                mainSliderRef.current.slickGoTo(next);
            }
        },
    };

    const handleImageClick = (index: number) => {
    if (onImageClick) {
        onImageClick(index);
    }
    if (window.innerWidth <= 991) {
        setSlideIndex(index);
        setIsPopupOpen(true);
    }
};

    return (
        <div className="product__kv-slider">
            <a
                className="product__kv-all-image btn btn-primary"
                href="/"
                onClick={(event) => {
                    event.preventDefault();
                }}
            >
                <i className="ico ico-left ico-eye-black ico-color"></i>Весь образ
            </a>

            <div className="product__kv-slider__wrap">
                {//@ts-ignore
                    <Slider className="product__kv-slider-main" {...mainSliderSettings} ref={mainSliderRef}>
                    {videos.map((video, index) => (
                        <div key={`video-${index}`}>
                            <video
                                key={video.url} 
                                muted
                                loop
                                autoPlay
                                playsInline
                                preload="none"
                                style={{width: "100%"}}
                                className="product-kv-slideVideo"
                            >
                                <source src={`${video.url}?timestamp=${Date.now()}`} type="video/mp4" />
                                Видео не поддерживается браузером
                            </video>
                        </div>
                    ))}
                    {images.map((image, index) => (
                        <a
                            key={image.url}
                            className="slide"
                            href="/"
                            onClick={(event) => {
                                event.preventDefault();
                                handleImageClick(index);
                            }}
                        >
                            <img
                                src={getBaseImagePath() + convertImageToSize(image, ImageSize.ORIIGINAL).url}
                                alt={altString + (index ? " - " + (index + 1) : "")}
                            />
                        </a>
                    ))}
                </Slider>}

                {   //@ts-ignore
                    <Slider className="product__kv-slider-add desk" {...thumbSliderSettings} ref={thumbSliderRef}>
                    {videoThumbnails.map((thumbnail, index) => (
                        <div key={`video-thumbnail-${index}`} className="slide">
                            <i className="ico ico-play ico-color"></i>
                            <img
                                className={"pointer"}
                                style={{
                                    border: slideIndex === index ? "1px solid grey" : "0px solid grey",
                                }}
                                src={thumbnail}
                                alt={`Video thumbnail ${index + 1}`}
                                onClick={() => {
                                    if (mainSliderRef.current) {
                                        mainSliderRef.current.slickGoTo(index);
                                    }
                                }}
                            />
                        </div>
                    ))}
                    {images.map((image, index) => (
                        <div key={image.url} className="slide">
                            <img
                                className={"pointer"}
                                style={{
                                    border: slideIndex === videos.length + index ? "1px solid grey" : "0px solid grey",
                                }}
                                src={getBaseImagePath() + convertImageToSize(image, ImageSize.THUMBNAIL).url}
                                alt=""
                                onClick={() => {
                                    if (mainSliderRef.current) {
                                        mainSliderRef.current.slickGoTo(videos.length + index);
                                    }
                                }}
                            />
                        </div>
                    ))}
                </Slider>}
            </div>

            <div className="product__kv-slider-controls desk">
                <div
                    className="slick-btn slick-btn-prev"
                    onClick={() => {
                        if (mainSliderRef.current) {
                            mainSliderRef.current.slickPrev();
                            setSlideIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
                        }
                    }}
                ></div>
                <div
                    className="slick-btn slick-btn-next"
                    onClick={() => {
                        if (mainSliderRef.current) {
                            mainSliderRef.current.slickNext();
                            setSlideIndex((prevIndex) => (prevIndex >= images.length - 1 ? 0 : prevIndex + 1));
                        }
                    }}
                ></div>
            </div>

            {breadcrumbs &&
                breadcrumbs.map((breadcrumb, index) => (
                    <p key={index}>
                        <a
                            href={"/"}
                            className="product__kv-back-page mob"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(-1);
                            }}
                        >
                            <i className="ico ico-arrow-left-b"></i>
                        </a>
                    </p>
                ))}
        </div>
    );
}
