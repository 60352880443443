import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

import {useState, useEffect, useContext} from "react";
import {useForm} from "react-hook-form";
import RightModalFull from "./RightModalFull";
import {addQuestion} from "../../../api/customer";
import {QuestionInterface} from "../../../interfaces/customer/QuestionInterface";

function Question() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const [isSubmit, setIsSubmit] = useState(false)

    const {register, handleSubmit, getValues, reset, formState: {errors, dirtyFields}} = useForm();

    let formSubmit = async (data: any) => {
        let fields = getValues()
        fields.customer_id = state.customer?.id
        fields.customer_sign = state.customer?.sign
        addQuestion(fields as QuestionInterface)
        setIsSubmit(true)
    };

    return (<div className="modal-body">
        {!isSubmit && <div className="modal-form">
            <h4 className="modal-form__title f-exp">Задать вопрос</h4>
            <form onSubmit={handleSubmit(formSubmit)}>
                <div className="form-group">
                    <label className="form-label">Имя</label>
                    <input
                        className={'form-control ' + (errors.fio ? ' is-invalid ' : '') + (!errors.fio && dirtyFields.fio ? ' is-valid ' : '')}
                        type="text"
                        placeholder="Имя"
                        id="name"
                        {...register("fio", {required: true})}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Телефон</label>
                    <input
                        className={'form-control ' + (errors.phone ? ' is-invalid ' : '') + (!errors.phone && dirtyFields.phone ? ' is-valid ' : '')}
                        type="text"
                        placeholder="+7"
                        id="phone"
                        {...register("phone", {required: true})}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">E-mail</label>
                    <input
                        id="email"
                        type="email"
                        placeholder="E-mail"
                        className={'form-control ' + (errors.email ? ' is-invalid ' : '') + (!errors.email && dirtyFields.email ? ' is-valid ' : '')}
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Email заполнен неправильно",
                            },
                        })}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Номер заказа</label>
                    <input
                        className={'form-control ' + (errors.orderId ? ' is-invalid ' : '') + (!errors.orderId && dirtyFields.orderId ? ' is-valid ' : '')}
                        type="text"
                        placeholder="Введите номер заказа"
                        id="orderId"
                        {...register("orderId", {required: false})}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Удобное время для звонка</label>
                    <input
                        className={'form-control ' + (errors.callTime ? ' is-invalid ' : '') + (!errors.callTime && dirtyFields.callTime ? ' is-valid ' : '')}
                        type="text"
                        placeholder="Удобное время для звонка"
                        id="callTime"
                        {...register("callTime", {required: false})}
                    />
                </div>
                <div className="form-group">
                    <label className="form-label">Ваш вопрос</label>
                    <textarea
                        className={'form-control ' + (errors.question ? ' is-invalid ' : '') + (!errors.question && dirtyFields.question ? ' is-valid ' : '')}
                        placeholder="Вопрос"
                        id="question"
                        {...register("question", {required: true})}
                    />
                </div>
                <div className="form-btns">
                    <button className="btn btn-secondary">Отправить</button>
                </div>
                <div className="form-foot">
                    <p>
                        Нажимая кнопку “Отправить”, Вы соглашаетесь с{" "}
                        <a href="/landing/26" target="blank">
                            Политикой конфиденциальности
                        </a>{" "}
                        и
                        <a href="/landing/27" target="blank">
                            Политикой обработки cookies
                        </a>
                    </p>
                </div>
            </form>
        </div>}

        {isSubmit && <div className="modal-form">
            <h4 className="modal-form__title f-exp">Ваш вопрос отправлен</h4>
            <form action="">
                <div className="form-text">
                    <p>
                        Мы пришлём Вам ответ в течение 3 рабочих дней
                        <br/> на указанный адрес электронной почты.
                    </p>
                </div>
                <div className="form-btns">
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            dispatch({type: ReducerActionType.CLOSE_ALL_POPUP});
                        }}
                    >
                        Ок
                    </button>
                </div>
            </form>
        </div>}
    </div>)
}

export default function QuestionPopup() {
    return (<RightModalFull>
        <Question />
    </RightModalFull>)
}
