import Money from "../core/Money";
import React, {useContext, useEffect, useRef} from "react";
import {
    CREDIT_URL,
    PAYMENT_TYPE_CREDIT_ID,
    PAYMENT_TYPE_CREDIT_CARD_ID,
    PAYMENT_TYPE_PODELI_ID,
    PAYMENT_TYPE_SBP_ID
} from "../../Config";

import {getCreditCardPaymentLink, getSbpPaymentLink, podeliLink} from "../../api/checkout";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {OrderInterface} from "../../interfaces/customer/OrderInterface";
import {getBaseImagePath} from "../catalog/Utils";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import useDatalayer from "../../utils/useDataLayer";

function OrderSuccess({orders, accuralSum, isFastOrder = false}: {orders: OrderInterface[], accuralSum: number, isFastOrder: boolean}) {
    // eslint-disable-next-line
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {successOrder: dataLayerManagerSuccessOrder} = useDatalayer()


    let processedOrderId: {current: any} = useRef([])

    // const paymentLink = (order: OrderInterface) => {
    //     return '/api/yookassa/create-payment/' + order.id + '/' + order.sign + '/'
    // }

    const creditLink = (order: OrderInterface) => {
        return order.credit_application_id ? CREDIT_URL + order.credit_application_id : null
    }

    const tracking = () => {
        return orders.map(order => order.id).join(',')
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (orders.length === 1) {
            const order = orders[0]
            let timeoutId = 0
            switch (order.payment.id) {
                case PAYMENT_TYPE_PODELI_ID:
                    podeliLink(order).then(response => {
                        if (response.status === 'ok') {
                            timeoutId = window.setTimeout(() => {
                                window.location.href = response.redirect
                            }, 5000);
                            console.log('Редирект на ' + response.redirect + ' через 5 секунд');
                        } else {
                            dispatch({type: ReducerActionType.ERROR, payload: 'При запросе ссылки на Podeli произошла ошибка : ' + response.detail})
                        }
                    }).catch(error => {
                        console.error(error)
                        dispatch({type: ReducerActionType.ERROR, payload: 'При запросе ссылки на Podeli произошла ошибка : ' + (error.response?.data?.message || error.response?.data?.detail)})
                    })
                    break;
                case PAYMENT_TYPE_CREDIT_CARD_ID:
                    getCreditCardPaymentLink(order.id.toString(), order.sign).then(result => {
                        if(result.url){
                            window.location.href = result.url
                        }
                        console.log('Редирект payment link ' , result);
                    })
                    break;
                case PAYMENT_TYPE_SBP_ID:
                    console.log('Detect SBP');
                    getSbpPaymentLink(order.id.toString(), order.sign).then(result => {
                        if(result.url){
                            window.location.href = result.url
                        }
                        console.log('Редирект payment link ' , result);
                    })
                    break;
                case PAYMENT_TYPE_CREDIT_ID:
                    if (!creditLink(order)) {
                        return
                    }
                    timeoutId = window.setTimeout(() => {
                        // @ts-ignore
                        window.location.href = creditLink(order)
                    }, 5000);
                    console.log('Редирект на ' + creditLink(order) + ' через 5 секунд');
                    break;
                default:
                    break;
            }
            return () => {
                window.clearTimeout(timeoutId)
            }
        }
        // eslint-disable-next-line
    }, [tracking()])

    useEffect(() => {
        if (!tracking()) return
        orders.forEach(order => {
            if (processedOrderId.current.indexOf(order.id) === -1) {
                processedOrderId.current.push(order.id)
                dataLayerManagerSuccessOrder(order.positions, {
                    "transaction_id": order.id,
                    "value": order.total_with_delivery_and_discount.amount / 100,
                    "shipping": order.delivery_cost.amount / 100,
                    "coupon": order.promocodes.map(promocode => promocode.code)
                })
            }
        })
        // eslint-disable-next-line
    }, [tracking()])

    return (<div className="basket-step-2" style={{display: "block"}}>
            <div className="row">
                <div className="col">
                    <div className="site-title__wrap">
                        <h2 className="site-title f-exp">Ваш заказ оформлен</h2>
                    </div>
                </div>
            </div>
            {orders.map(order => <div key={order.id} className="order__confirmed">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="">
                            <div className="order__confirmed-top">
                                <div>
                                    <h5>Заказ от {order.create_date_formated}</h5>
                                    <ul>
                                        <li>
                                            <h6>№{order.id}</h6>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <p className="ico-cont">*/}
                                        {/*        <i className="ico ico-left ico-check-circle"/>*/}
                                        {/*        Оплачено*/}
                                        {/*    </p>*/}
                                        {/*</li>*/}
                                    </ul>
                                    <h6>Сумма заказа: <Money value={order.total_final}/></h6>
                                </div>
                                {/*<h6 className="f-exp">Доставка в ПВЗ: 8 августа</h6>*/}
                                <p className="f-exp">
                                    {order.delivery_address}
                                </p>
                                {/*<small>Есть примерка, бесплатно</small>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="order__confirmed-products">
                            {order.positions.map(position => <div key={position.id} className="basket__products-block bd">
                                <div className="img">
                                    <img src={getBaseImagePath() + position.image?.url} alt=""/>
                                </div>
                                <div className="info">
                                    <div className="text">
                                        <div className="left">
                                            <h4>{position.model.inetshop_name}</h4>
                                            <ul>
                                                <li>Размер: {position.size}</li>
                                                <li>Цвет: {position.color}</li>
                                                <li>Артикул: {position.model.articul}</li>
                                            </ul>
                                            <div>Количество: {position.quantity}</div>
                                        </div>
                                        <div className="right f-exp">
                                            <p><Money value={position.total_with_bonus} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                        {order.delivery_cost.amount > 0 && <div className="order__confirmed-bonuses">
                            <h4 className="f-exp">Доставка</h4>
                            <p><Money value={order.delivery_cost} /></p>
                        </div>}

                        {order.bonus.amount > 0 && <div className="order__confirmed-bonuses">
                            <h4 className="f-exp">Бонусы</h4>
                            <p>Использовано {order.bonus.amount / 100} бонусов.</p>
                            <p>Будет начислено {accuralSum} бонусов.</p>
                        </div>}
                        <div className="order__confirmed-btns form-btns">
                            <a className="btn btn-secondary-tr" href="/personal-area/orders">
                                Посмотреть заказ
                            </a>
                            <a className="btn btn-secondary" href="/">
                                Вернуться в каталог
                            </a>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    )
}

export default OrderSuccess