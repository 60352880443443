import React from "react";
import BalanceForm from "./BalanceForm";
import Modal from "react-bootstrap/Modal";

export default function BalanceFormPopup({handleClose}: {handleClose: () => void}) {


    return (<Modal animation={false} show={true} centered={true}  onHide={handleClose} bsPrefix={ 'bootstrap-v5 modal'}>
        <Modal.Header closeButton>
            <Modal.Title>Проверить баланс сертификата</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <BalanceForm />
        </Modal.Body>
    </Modal>)
}