import {useContext, useEffect, useState} from "react";
import {sabManagers} from "../../api/checkout";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {SabManagerInterface} from "../../interfaces/checkout/SabManagerInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function SabManagers() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext)
    const [managers, setManagers] = useState<SabManagerInterface[]>([])


    useEffect(() => {
        if (!state.customer?.id) return
        sabManagers(state.customer).then((managers) => {
            setManagers(managers)
            if (managers?.length) {
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'hasSabManagers', payload: true})
            }
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {managers.length > 0 && <div className="card mb-4 border border-danger border-3 rounded">
                <div className="card-body">
                    <div className="row mb-3 mt-2 block-header ">
                        <div className="block-header">
                            Выберите менеджера
                        </div>
                        <div className="mt-2">
                            <div className="form-group select-arrow">
                                <select className={"form-control"} onChange={(e) => {
                                    if (!e.target.value) return
                                    dispatch({type: ReducerActionType.SET_PARAM, paramName: 'sabManager', payload: managers.find(manager => manager.id === e.target.value)})
                                }} value={state.sabManager?.id}>
                                    <option value="">-- выберите менеджера --</option>
                                    {managers.map(manager => <option key={manager.id} value={manager.id}>{manager.name}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}