import TopNavigation from "./TopNavigation";
import InfoText from "./InfoText";
import RefundInterface from "../../../interfaces/customer/RefundInterface";
import isEmail from "validator/es/lib/isEmail";
import {Controller, useForm} from "react-hook-form";
import Input from "react-phone-number-input/input";
import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {OrderInterface} from "../../../interfaces/customer/OrderInterface";

export default function Step1({order, params, setParams}: {
    order: OrderInterface | undefined
    params: RefundInterface,
    setParams: (params: RefundInterface) => void
}) {

    const {register, reset, handleSubmit, control, formState: {errors}} = useForm();

    useEffect(() => {
        if(params.isInit){
            reset(params)
        }
    }, [params.isInit]);

    const formSubmit = (formParams: any) => {
        setParams({...params, ...formParams, ...{step: 2}})
    }

    return (<div className="lk__refund-petition--lk">
        <form onSubmit={handleSubmit(formSubmit)}>
            <div className="row">
                <div className="col-lg-5">
                    <TopNavigation step={1}/>
                    <div className="form-line">
                        <label>Номер заказа</label>
                        <div className="form-group">
                            { !params.orderId && <input type="text" placeholder="Номер заказа"
                                   className={"form-control" + (errors.orderId ? ' is-invalid' : '')} {...register("orderId", {
                                required: true
                            })}/>}
                            { !!params.orderId && <b>{params.orderId}</b>}
                        </div>
                    </div>
                    <div className="form-line">
                        <label>Имя</label>
                        <input className={"form-control" + (errors.lastName ? ' is-invalid' : '')} type="text"
                               placeholder="Имя" {...register("firstName", {
                            required: true
                        })}/>
                    </div>
                    <div className="form-line">
                        <label>Фамилия</label>
                        <div className="form-group">
                            <input className={"form-control" + (errors.lastName ? ' is-invalid' : '')} type="text"
                                   placeholder="Фамилия" {...register("lastName", {
                                required: true
                            })}/>
                        </div>
                    </div>
                    <div className="form-line">
                        <label>Отчество</label>
                        <div className="form-group">
                            <input className={"form-control" + (errors.patronymicName ? ' is-invalid' : '')} type="text"
                                   placeholder="Отчество" {...register("patronymicName", {
                                required: true
                            })}/>
                        </div>
                    </div>
                    <div className="form-col">
                        <label>Паспорт</label>
                        <div className="form-col-wrap">
                            <div className="form-group">
                                <Row>
                                    <Col>
                                        <input className={"form-control" + (errors.passportSeries ? ' is-invalid' : '')}
                                               type="text"
                                               placeholder="Серия" {...register("passportSeries", {
                                            required: true,
                                            validate: v => /^[+\d]{4}$/.test(v)
                                        })}/>
                                    </Col>
                                    <Col>
                                        <input className={"form-control" + (errors.passportNumber ? ' is-invalid' : '')}
                                               type="text"
                                               placeholder="Номер" {...register("passportNumber", {
                                            required: true,
                                            validate: v => /^[+\d]{6}$/.test(v)
                                        })}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group">
                                <input className={"form-control" + (errors.passportIssued ? ' is-invalid' : '')}
                                       type="text"
                                       placeholder="Кем выдан" {...register("passportIssued", {
                                    required: true
                                })}/>
                            </div>
                        </div>
                    </div>
                    <div className="form-line">
                        <label>E-mail</label>
                        <div className="form-group">
                            <input className={"form-control" + (errors.email ? ' is-invalid' : '')} type="text"
                                   placeholder="E-mail" {...register("email", {
                                required: true,
                                validate: {
                                    checkEmail: v => isEmail(v)
                                }
                            })}/>
                        </div>
                    </div>
                    <div className="form-line">
                        <label>Телефон</label>
                        <div className="form-group">
                            <Controller
                                control={control}
                                name="phone"
                                rules={{
                                    required: true,
                                    validate: v => /^[+\d]{10,20}$/.test(v)
                                }}
                                render={({field: {onChange, name, value}}) => (
                                    <Input
                                        className={'form-control ' + (errors.phone ? ' is-invalid' : '')}
                                        placeholder="+7 ___ ___-__-__"
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-btns">
                            {/*<button className="btn btn-secondary-tr" onClick={(event) => {*/}
                            {/*    event.preventDefault();*/}
                            {/*}} style={{marginRight: '1.5rem', width: '18.5rem'}}>Назад</button>*/}
                            <button className="btn btn-secondary" style={{width: '18.5rem'}}>Далее</button>
                        </div>
                    </div>
                </div>
                <InfoText/>
            </div>
        </form>
    </div>)
}