// import SocialIcons from "./SocialIcons";
import { Controller, useForm } from "react-hook-form";
import {checkPhoneCode, loginByPhone} from "../../api/customer";
import {useContext, useEffect, useRef, useState, useCallback } from "react";
import Input from "react-phone-number-input/input";
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import LoginTypeSwitch from "./LoginTypeSwitch";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {useNavigate} from "react-router-dom";
import OpenidBlock from "./OpenidBlock";

const BLOCK_PHONE = 'phone';

const BLOCK_CODE = 'code';

const RESEND_SECONDS = 120;

function str_pad_left(string: string, pad: string, length: number) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}

function LoginByPhone({setError, setLoading, setCustomer}: {
    setError: Function,
    setLoading: Function,
    setCustomer: Function
}) {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [blockType, setBlockType] = useState(BLOCK_PHONE);
    const [userId, setUserId] = useState(0);
    const [seconds, setSeconds] = useState(0)
    const [prettySeconds, setPrettySeconds] = useState('')
    const codeInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (blockType === BLOCK_CODE) {
            const timer = setTimeout(() => {
                if (codeInputRef.current) {
                    codeInputRef.current.focus();
                }
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [blockType]);

    const {control, register, getValues, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const navigate = useNavigate()

    const { ref: hookFormRef, ...registerProps } = register("code", { 
        required: true,
        shouldUnregister: true
    });

    const combinedRefs = useCallback(
        (element: HTMLInputElement | null) => {
        if (element) {
            codeInputRef.current = element; 
        }
        if (typeof hookFormRef === "function") {
            hookFormRef(element);
        }

        if (element && blockType === BLOCK_CODE) {
            setTimeout(() => element.focus(), 0);
        }
        },
        [hookFormRef, blockType]
    );

    useEffect(() => {
    if (blockType === BLOCK_CODE && codeInputRef.current) {
        codeInputRef.current.focus();
    }
    }, [blockType]);

    useEffect(() => {
        const timeoutId = window.setTimeout(() => {
            if (!seconds) {
                return
            }
            setSeconds(seconds - 1)

            const minutes = Math.floor(seconds / 60)
            const sec = seconds - minutes * 60;
            const finalTime = str_pad_left(minutes.toString(), '0', 2) + ':' + str_pad_left(sec.toString(), '0', 2);

            setPrettySeconds(finalTime)

        }, 1000)

        return () => window.clearTimeout(timeoutId)
    }, [seconds])

    useEffect(() => {
        if (getValues().code && getValues().code.length === 6) {
            sendCode()
        }
        // eslint-disable-next-line
    }, [getValues().code])

    const sendPhone = () => {
        setError('')
        setSeconds(RESEND_SECONDS)

        const fields = getValues()
        if (!fields.phone) {
            setError('Не указаны телефон')
            return
        }
        setLoading(true)
        loginByPhone(fields.phone).then(status => {
            setUserId(status.user.id)
            setBlockType(BLOCK_CODE)
            setTimeout(() => {
            if (codeInputRef.current) {
                codeInputRef.current.focus();
            }
        }, 100);
        }).catch(error => {
            const errors = error.toJSON();
            if (errors.status === 401) {
                setError("Неверный логин или пароль")
                return
            }
            setError(error.response?.data?.detail || error.response?.data?.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    const sendCode = () => {
        setError('')
        const fields = getValues()
        if (!fields.code) {
            setError('Не указан код')
            return
        }
        setLoading(true)
        checkPhoneCode(userId, fields.code).then(status => {
            setCustomer(status.user)
            if (state.nextAction === 'goToCabinet') {
                navigate('/personal-area/')
            }
            dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: ''})

        }).catch(error => {
            const errors = error.toJSON();
            if (errors.status === 401) {
                setError("Неверный логин или пароль")
                return
            }
            setError(error.response?.data?.detail || error.response?.data?.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (<>
            <h4 className="modal-form__title f-exp">Вход или регистрация</h4>
            <LoginTypeSwitch/>
            <div className="tab-content">
                <div className="tab-pane fade active show">
                    {blockType === BLOCK_PHONE &&
                        <div className={'text-muted'}>На указанный номер поступит СМС с кодом</div>}
                    {blockType === BLOCK_CODE &&
                        <div className={'text-muted'}>Мы отправили подтверждение на номер </div>}

                    {blockType === BLOCK_PHONE && <>
                        <form onSubmit={(e) => { e.preventDefault(); }} >
                            <div className="form-group">
                                <label className="form-label">Номер телефона</label>
                                <Controller
                                    control={control}
                                    name="phone"
                                    rules={{
                                        required: true,
                                        validate: v => /^[+\d]{12,20}$/.test(v)
                                    }}
                                    render={({field: {onChange, name, value}}) => (
                                        <Input
                                            className={'form-control ' + (errors.phone ? ' is-invalid' : '')}
                                            placeholder="+7 ___ ___-__-__"
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                            <div className="form-btns">
                                <button type={'button'} className="btn btn-secondary" onClick={sendPhone}>Получить код
                                </button>
                            </div>
                            <OpenidBlock />
                            <div className="form-foot">
                <p>Нажимая кнопку “Отправить”, Вы соглашаетесь с <a href="/landing/26/">Политикой конфиденциальности</a> и <a
                    href="/landing/27/">Политикой обработки cookies</a></p>
                            </div>
                        </form>
                    </>}
                    {blockType === BLOCK_CODE && <>
                        <form>
                            <div className="form-group">
                                <label className="form-label">Код из СМС</label>
                            <input
                                type="text"
                                ref={combinedRefs}
                                autoComplete={"one-time-code"}
                                className={
                                "form-control text-center form-control-lg " +
                                (errors.password ? " is-invalid" : "")
                                }
                                placeholder="_ _ _ _ _ _"
                                {...registerProps}
                            />
                            </div>

                            {seconds > 0 &&
                                <div className={'text-muted text-center'}>Получить новый код можно
                                    через {prettySeconds}</div>}
                            {seconds <= 0 &&
                                <div className={'text-muted text-center'}><span className={'control'} onClick={() => {
                                    sendPhone()
                                }}>Отправить код повторно</span></div>}

                            <div className="form-btns mt-4">
                                <button type={'button'} className="btn btn-secondary" onClick={sendCode}>Войти</button>
                            </div>
                        </form>
                    </>}
                </div>

            </div>
        </>
    )
}

export default LoginByPhone

