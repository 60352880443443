import Menu from "./header/Menu";
import React, {useState, useContext, useEffect} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import MenuInterface from "../../interfaces/header/MenuInterface";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import {Link, useNavigate} from "react-router-dom";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import HeaderProgressBar from "./HeaderProgressBar";
import MenuLinkInterface from "../../interfaces/header/MenuLinkInterface";
import {LAST_OPEN_MENU_KEY} from "../../Config";

export default function Header({logo, menuItems, menuLinkItems} : {logo: string, menuItems: MenuInterface[], menuLinkItems: MenuLinkInterface[]}){
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const [selectedMobMenu, setSelectedMobMenu] = useState('');

    const navigate = useNavigate()

    const toggleCurrentMenu = (menu:MenuInterface) => {
        if(!menu){
            dispatch({type:  ReducerActionType.SET_HEADER_MENU, payload: null})
            return
        }

        localStorage.setItem(LAST_OPEN_MENU_KEY, menu.name)
        if(state.headerMenu && state.headerMenu.name == menu.name){
            dispatch({type: ReducerActionType.SET_HEADER_MENU, payload: state.headerMenu ? null : menu})
        } else {
            dispatch({type: ReducerActionType.SET_HEADER_MENU, payload: menu})
        }
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'hideNavbar' , payload: false})
    }

    const hideMobMenu = () => {
        setSelectedMobMenu('');
        dispatch({ type: ReducerActionType.SET_PARAM, paramName: 'hideNavbar', payload: false });
    };

    const getBasketCount = () => {
        return state.basket.reduce((sum: number, basketPosition: BasketItemInterface) => {
            return sum + basketPosition.quantity
        }, 0)
    }

    useEffect(() => {
        if(state.headerMenu){
            $('body').addClass('menu-open');
        }else{
            $('body').removeClass('menu-open');
        }
    }, [state.headerMenu]);

    return (<header>
        <div className="container-fluid">
            <nav className="navbar navbar-expand-lg">
                <button className={"navbar-toggler " + (!state.headerMenu ? ' collapsed ' : '')} type="button"
                        onClick={() => {
                            const lastSavedMenu = menuItems.find(menuItem => menuItem.name === localStorage.getItem(LAST_OPEN_MENU_KEY))
                            toggleCurrentMenu(lastSavedMenu || menuItems[0]);
                            hideMobMenu();
                        }}>
                    <i></i>
                    <i></i>
                </button>
                <ul className="navbar-links">
                    <li><a className="navbar-brand" href=""><img src={logo} alt=""/></a></li>
                    <li className="desk"><a className="navbar-city ico-cont" href="/" onClick={(event) => {
                        event.preventDefault()
                        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showRegionFinder', payload: true})
                    }}><i
                        className="ico ico-point"></i>{state.region.clear_name || 'Выберите город'}</a></li>
                </ul>


                <div
                    className={"collapse navbar-collapse " + (state.headerMenu ? ' show ' : '') + (state.hideNavbar ? ' active ' : '')}
                    id="navbarMenu">
                    <div className="collapse__wrap">
                        <div className="collapse__inner">
                            <ul className="navbar-nav">
                                {menuItems.map((menu: MenuInterface) => <li key={menu.name}
                                                                            className={"nav-item " + (!menu.showOnMobile ? 'desk' : '')}>
                                    <button
                                        className={"nav-link f-exp" + (!state.headerMenu || (state.headerMenu && state.headerMenu.name !== menu.name) ? ' collapsed' : '')}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            toggleCurrentMenu(menu)
                                        }}>{menu.name}</button>
                                </li>)}
                                {menuLinkItems.map((menu: MenuLinkInterface) => <li key={menu.name}
                                                                                    className={"nav-item desk "}>
                                    <Link className={"nav-link f-exp collapsed " + menu.class}
                                          to={menu.url}>{menu.name}</Link>
                                </li>)}
                            </ul>
                            {menuItems.map((menu: MenuInterface) => <Menu key={menu.name} menu={menu.data} additionalLinks={menuLinkItems}  hideMobMenu={hideMobMenu} selectedMobMenu={selectedMobMenu} setSelectedMobMenu={setSelectedMobMenu}
                                                                          isShow={state.headerMenu?.name === menu.name}/>)}
                        </div>
                    </div>
                </div>

                <ul className="navbar-icons">
                    <li><a href="/" onClick={(event) => {
                        event.preventDefault()
                        dispatch({type: ReducerActionType.TOGGLE_SEARCH_BOARD})
                    }}><i className="ico ico-search search-board__btn" ></i></a></li>
                    <li className="desk"><Link to={"/favorites/"}><i className="ico ico-heart"></i><span
                        className="nubmer">{state.favorites.length || 0}</span></Link></li>
                    <li className="desk"><a href="/" onClick={(event) => {
                        event.preventDefault()

                        if (state.customer && state.customer?.id && state.customer?.sign) {
                            navigate('/personal-area/')
                            return
                        }

                        dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'})
                        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: 'goToCabinet'})
                    }}><i className="ico ico-profile-b"></i></a></li>
                    <li className="desk"><a href="/" onClick={(event) => {
                        event.preventDefault()
                        dispatch({type: ReducerActionType.TOGGLE_BASKET_POPUP})
                    }}><i className="ico ico-bag"></i>{state.basket.length > 0 &&
                        <span className="nubmer active">{getBasketCount()}</span>}</a></li>
                </ul>
            </nav>
            {state.isLoading && <HeaderProgressBar/>}
        </div>
    </header>)
}