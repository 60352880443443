import React, {useEffect, useState} from 'react'
import Spacer from '../Spacer/Spacer.jsx'
import styles from './BannerText.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const loadImage = (setImageDimensions, bannerImg) => {
	const img = new Image()
	img.src = bannerImg

	img.onload = () => {
		setImageDimensions({
			height: img.height,
			width: img.width,
		})
	}
	img.onerror = err => {
		console.error(err)
	}
}

const BannerText = props => {
	const [imageDimensions, setImageDimensions] = useState({})

	const bannerImg = props.params.img

	useEffect(() => {
		loadImage(setImageDimensions, bannerImg)
	}, [])

	return (
		<>
			{Object.keys(imageDimensions).length === 0 ? (
				<></>
			) : (
				<>
					<div className='mblock '>
						<div className={'text-page__main-banner '}>
							<div className={'banner-on-main ' + styles.centeredSlider}>
								<picture
									fullpage-banner-size=''
									width={imageDimensions.width}
									height={imageDimensions.height}
								>
									<source media="(max-width: 992px)" srcSet={getBaseImagePath() + (props.params.imgmob ? props.params.imgmob : bannerImg)} />
									<img src={getBaseImagePath() + bannerImg}
										alt={bannerImg}
									/>
								</picture>
								<div className="text">
									<h3 className="f-exp" dangerouslySetInnerHTML={{__html: props.params.title}}></h3>
									<h5>{props.params.text}</h5>
								</div>
							</div>
						</div>
						<div className='landing_imageHeight' style={{height: imageDimensions.height + 'px'}}>
							&nbsp;
						</div>
					</div>

					<Spacer />
				</>

			)}

		</>
	)
}

export default BannerText
