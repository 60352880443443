export const objclone = (val: any): any => {
    if (!val) return val
    return JSON.parse(JSON.stringify(val))
}

export const isEmailValid = (email: string): boolean => {
    return email
        .toLowerCase()
        .match(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/
        ) === null;
};

export const capitalizeFirstLetter = (value: string | undefined): string => {
    if (!value) return ''
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
}

export const getSuffixByCount = (index: number) => {

    const lastIndex = parseInt((index + '').substring(-1))

    if (lastIndex === 1) {
        return '';
    }
    if (lastIndex > 1 && lastIndex < 5) {
        return 'а';
    } else {
        return 'ов';
    }
}

export const getSearchParams = (location: string) => {
    const search = location.substring(1);
    if(!search) return ''
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })

}

export const transliterate = (word: string) => {
    const a = {
        "Ё": "YO",
        "Й": "I",
        "Ц": "TS",
        "У": "U",
        "К": "K",
        "Е": "E",
        "Н": "N",
        "Г": "G",
        "Ш": "SH",
        "Щ": "SCH",
        "З": "Z",
        "Х": "H",
        "Ъ": "'",
        "ё": "yo",
        "й": "i",
        "ц": "ts",
        "у": "u",
        "к": "k",
        "е": "e",
        "н": "n",
        "г": "g",
        "ш": "sh",
        "щ": "sch",
        "з": "z",
        "х": "h",
        "ъ": "'",
        "Ф": "F",
        "Ы": "I",
        "В": "V",
        "А": "A",
        "П": "P",
        "Р": "R",
        "О": "O",
        "Л": "L",
        "Д": "D",
        "Ж": "ZH",
        "Э": "E",
        "ф": "f",
        "ы": "i",
        "в": "v",
        "а": "a",
        "п": "p",
        "р": "r",
        "о": "o",
        "л": "l",
        "д": "d",
        "ж": "zh",
        "э": "e",
        "Я": "Ya",
        "Ч": "CH",
        "С": "S",
        "М": "M",
        "И": "I",
        "Т": "T",
        "Ь": "'",
        "Б": "B",
        "Ю": "YU",
        "я": "ya",
        "ч": "ch",
        "с": "s",
        "м": "m",
        "и": "i",
        "т": "t",
        "ь": "'",
        "б": "b",
        "ю": "yu"
    };

    return word.split('').map((char: string) => {
        // @ts-ignore
        return a[char] || char;
    }).join("");
}

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0; // Random number between 0 and 15
        const v = c === 'x' ? r : (r & 0x3 | 0x8); // If 'y', set bits to 10xx
        return v.toString(16);
    });
}