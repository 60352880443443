import Navigation from "./personal-area/Navigation";
import {Outlet, useNavigate} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {credentials} from "../../api/customer";
import {CustomerCredentialResponse} from "../../interfaces/api/CustomerCredentialResponseInterface";
import React, {useContext, useEffect, useState} from "react";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import {LOCAL_STORAGE_KEY_CUSTOMER} from "../../Config";

export default function PersonalArea() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [error, setError] = useState('')
    const [showAuthBlock, setShowAuthBlock] = useState(false)

    const navigate = useNavigate()

    const {data , status} = useQuery<CustomerCredentialResponse,  "error" | "success" | "pending">({
        queryKey: [...['customer', 'credentials', state.codeword, state.customer?.id]],
        queryFn: () => credentials(state.customer.id || 0, state.customer.sign || '') ,
        retry: false,
        enabled: !!(state.customer.id && state.customer.sign)
    })

    useEffect(() => {
        if(!state.customer.id || !state.customer.sign){
            setShowAuthBlock(true)
            return
        }

        setShowAuthBlock(false)

        switch(status){
            case 'pending':
                dispatch({type: ReducerActionType.LOAD})
                return;
            case 'error':
                dispatch({type: ReducerActionType.SUCCESS})
                setError('В процессе выполнения запроса возникли ошибки. Вам необходимо ввести логин и пароль.')

                dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'})
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: 'goToCabinet'})

                localStorage.removeItem(LOCAL_STORAGE_KEY_CUSTOMER)
                return;
            case 'success':
                // не ошибка, глючит параллельная загрузка с логином
                dispatch({type: ReducerActionType.SUCCESS})
                dispatch({type: ReducerActionType.SUCCESS})
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'customer', payload: data.customer})
                return;
        }


    }, [status]);

    return (<main className="main-lk">
            <section className="lk">
                {showAuthBlock && <div className="container-fluid">
                    <h5 className="basket__form-title f-exp text-center">Для просмотра данных в личном кабинете необходимо зарегистрироваться или авторизоваться</h5>
                    <div className="container" style={{maxWidth: '700px', marginTop: '100px', marginBottom: '100px'}}>
                        <div className="row ">
                            <div className="col">
                                <button className="btn btn-secondary btn-block" onClick={() => {
                                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.LOGIN})
                                    dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: 'goToCabinet'})
                                }}>Авторизоваться
                                </button>
                            </div>
                            <div className="col">
                                <button className="btn btn-outline-secondary btn-block"
                                        onClick={() => {
                                            dispatch({
                                                type: ReducerActionType.SHOW_POPUP,
                                                payload: PopupType.REGISTRATION
                                            })
                                            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: 'goToCabinet'})
                                        }}>Зарегистрироваться
                                </button>
                            </div>
                        </div>
                    </div>

                </div>}
                {!showAuthBlock && <div className="container-fluid">
                    {status === 'error' && <div className="row lk__wrap">
                        <h3 className={'text-center text-danger'}>{error}</h3>

                    </div>}
                    {status === 'success' && <div className="row lk__wrap">
                        <div className="col-lg-2 lk__menu">
                            <Navigation customer={data.customer}/>
                        </div>
                        <div className="col-lg-10 lk__container">
                            <div className="tab-content">
                                <Outlet context={data}/>
                            </div>
                        </div>
                    </div>}
                </div>}
            </section>
    </main>)
}