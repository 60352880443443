import {useQuery} from "@tanstack/react-query";

import Breadcrumbs from "./../Breadcrumbs";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import Moment from "react-moment";
import {PromotionInterface} from "../../../interfaces/news/PromotionInterface";

import {getPromotions} from "../../../api/news";

export default function PromotionItem() {
    const {data} = useQuery({
        queryKey: [...["promotions"]],
        queryFn: () => getPromotions(),
    });

    const {posts, breadcrumbs} = data || {};

    const [post, setPost] = useState<PromotionInterface | null>(null);

    const queryData = useParams<{promotionId: string}>();

    useEffect(() => {
        const findedPost = posts?.find((post) => post.id.toString() === queryData.promotionId);
        if (findedPost) {
            setPost(findedPost);
        }
    }, [posts]);

    return (
        <main className="main-shops">
            <section className="shops pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={breadcrumbs || []} />
                        </div>
                    </div>
                </div>
                {post && (
                    <div className="stock" style={{display: "block"}}>
                        <div className="container-fluid">
                            <div className="row desk">
                                <div className="col">
                                    <div className="stock-head">
                                        <h3 className="f-exp">{post.title}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row stock-body">
                                <div className="col-lg-5 stock-text">
                                    <div className="stock-info">
                                        <p>
                                            <Moment format={"DD.MM.YYYY"}>{post?.news_date}</Moment>
                                        </p>
                                        <picture>
                                            <source srcSet={post.image} media="(max-width: 992px)" />
                                            <img src={post.image} alt={post.image} />
                                        </picture>
                                        <h3 className="f-exp mob">{post.title}</h3>
                                        <div className="stock-info-body">
                                            <div dangerouslySetInnerHTML={{__html: post?.body || ""}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </main>
    );
}
