export default function Vacancies() {
    return (<div
        className="page-company__vacancies tab-pane fade show active"
    >
        <div className="row">
            <div className="col">
                <div className="page-company__vacancies-tabs">
                    <ul className="nav nav-tabs f-exp" id="myvacTab" role="tablist">
                        <li className="nav-item" role="company">
                            <button
                                className="nav-link active"
                                id="company_vac-tab-1"
                                data-bs-toggle="tab"
                                data-bs-target="#company_vac-sect-1"
                                type="button"
                                role="tab"
                                aria-controls="company_vac-sect-1"
                                aria-selected="true"
                            >
                                Москва
                            </button>
                        </li>
                        <li className="nav-item" role="company_vac">
                            <button
                                className="nav-link"
                                id="company_vac-tab-2"
                                data-bs-toggle="tab"
                                data-bs-target="#company_vac-sect-2"
                                type="button"
                                role="tab"
                                aria-controls="company_vac-sect-2"
                                aria-selected="true"
                            >
                                Регионы
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="company_vacTabContent">
                    <div
                        className="tab-pane fade show active"
                        id="company_vac-sect-1"
                        role="tabpanel"
                        aria-labelledby="company_vac-tab-1"
                    >
                        <div className="row page-company__vacancies-list">
                            <div className="col-lg-3">
                                <div className="block bd" data-attr="vacancy-1">
                                    <h5>ТРК Вселенная</h5>
                                    <h6 className="f-exp">Администратор сада</h6>
                                    <p>650 000 Р - 800 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="block bd">
                                    <h5>ТРК Весна</h5>
                                    <h6 className="f-exp">Администратор магазина</h6>
                                    <p>65 000 Р - 80 000 Р</p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="page-company__vacancy"
                            id="vacancy-1"
                            style={{display: "none"}}
                        >
                            <div className="row">
                                <div className="col-lg-1 offset-lg-1">
                                    <i className="back ico ico-arrow-left-b"/>
                                    <h5 className="page-company__vacancy-title f-exp mob">
                                        Администратор магазина
                                    </h5>
                                </div>
                                <div className="page-company__vacancy-main col-lg-5">
                                    <h5 className="page-company__vacancy-title f-exp desk">
                                        Администратор магазина
                                    </h5>
                                    <h6 className="f-exp">Что предлагаем мы:</h6>
                                    <ul>
                                        <li>
                                            Достойный уровень заработной платы - оклад, премии
                                            от личных продаж. Ваш доход зависит только от Вас!
                                        </li>
                                        <li>График работы - 2/2.</li>
                                        <li>
                                            Бесплатное корпоративное обучение технике продаж,
                                            ассортименту, мерчандайзингу, модным тенденциям
                                            наступающих сезонов.{" "}
                                        </li>
                                        <li>Скидка на все товары Baon – 40%</li>
                                        <li>Профессиональный и карьерный рост</li>
                                        <li>Конкурсы для сотрудников с денежными призами</li>
                                        <li>Стильная корпоративная форма от компании</li>
                                        <li>Дополнительная мотивация за стаж работы</li>
                                        <li>
                                            У нас можно работать вместе с друзьями! В компании
                                            действует акция «Приведи друга» и получи
                                            материальную премию
                                        </li>
                                    </ul>
                                    <h6 className="f-exp">Что мы ждём от тебя:</h6>
                                    <ul>
                                        <li>
                                            Опыт работы в аналогичной должности (администратор
                                            или старший продавец магазина одежды/обуви или
                                            другие направления Fashion Retail)
                                        </li>
                                        <li>Знание техники продаж</li>
                                        <li>Опыт работы с показателями KPI</li>
                                        <li>Опыт и навыки управления персоналом</li>
                                        <li>Знание кассовой дисциплины</li>
                                        <li>
                                            Отличные коммуникативные, лидерские и
                                            организационные навыки
                                        </li>
                                    </ul>
                                    <h6 className="f-exp">
                                        Что будет входить в обязаности:
                                    </h6>
                                    <ul>
                                        <li>
                                            Формирование эффективной команды: мотивация на
                                            выполнение планов продажи достижению
                                            KPI-показателей, постановка задач, контроль
                                            выполнения
                                        </li>
                                        <li>
                                            Открытие и подготовка магазина к работе, закрытие
                                            магазина
                                        </li>
                                        <li>
                                            Проведение собеседований, стажировки и обучения
                                            новых сотрудников
                                        </li>
                                        <li>Консультирование и обслуживание покупателей</li>
                                        <li>Осуществление расчетов с покупателями</li>
                                        <li>Организация приемки товара, мерчандайзинг</li>
                                        <li>Ведение отчетности</li>
                                    </ul>
                                </div>
                                <div className="page-company__vacancy-aside col-lg-3">
                                    <div className="bd">
                                        <ul>
                                            <li>
                                                <h6>Город</h6>
                                                <p className="f-exp">Москва</p>
                                            </li>
                                            <li>
                                                <h6>График работы</h6>
                                                <p className="f-exp">2/2</p>
                                            </li>
                                            <li>
                                                <h6>Адрес работы</h6>
                                                <p className="f-exp">
                                                    ТРК Весна
                                                    <br/> Алтуфьевское шоссе, 1-й километр, вл3с1
                                                </p>
                                            </li>
                                            <li>
                                                <h6>Зарплата</h6>
                                                <p className="f-exp">65 000 Р - 80 000 Р</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <a className="btn btn-secondary" href="">
                                        Откликнуться на вакансию
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                    ></div>
                </div>
            </div>
        </div>

        <div className="row page-company__vacancies-show">
            <div className="col-lg-10 offset-lg-1">
                <div className="page-company__vacancies-banner">
                    <h4 className="f-exp">
                        Хотите работать в BAON?
                        <br/> Расскажите нам о себе
                    </h4>
                    <a className="btn btn-secondary" href="">
                        Рассказать
                    </a>
                    <picture>
                        <source
                            srcSet="/img/company/vacancy-banner.png"
                            media="(max-width: 992px)"
                        />
                        <img src="/img/company/vacancy-banner.png" alt=""/>
                    </picture>
                </div>
            </div>
        </div>
    </div>)
}