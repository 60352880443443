import React, {useEffect, useState} from 'react'
import Spacer from '../Spacer/Spacer.jsx'
import styles from './LongBanner.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const loadImage = (setImageDimensions, bannerImg) => {
	const img = new Image()
	img.src = bannerImg

	img.onload = () => {
		setImageDimensions({
			height: img.height,
			width: img.width,
		})
	}
	img.onerror = err => {
		console.error(err)
	}
}

const LongBanner = props => {
	const [imageDimensions, setImageDimensions] = useState({})

	const bannerImg = props.params.img

	useEffect(() => {
		loadImage(setImageDimensions, bannerImg)
	}, [])

	return (
		<>
			{Object.keys(imageDimensions).length === 0 ? (
				<></>
			) : (
				<>
					<div className='mblock '>
						<div className={'banner-on-main ' + styles.imageLong}>
							{props.params.url && props.params.url !== '' ? (
								<a href={props.params.url} >
									<div className={'banner-on-main ' + styles.centeredSlider}>
										<picture
											fullpage-banner-size=''
											width={imageDimensions.width}
											height={imageDimensions.height}
										>
											<source media="(max-width: 768px)" srcSet={getBaseImagePath() + props.params.imgmob} />
											<img src={getBaseImagePath() + bannerImg}
												alt={bannerImg}
											/>
										</picture>
									</div>
								</a>
							) : (
								<picture>
									<source media="(max-width: 768px)" srcSet={getBaseImagePath() + props.params.imgmob} />
									<img src={getBaseImagePath() + bannerImg}
										alt={bannerImg}
										fullpage-banner-size=''
										width={imageDimensions.width}
										height={imageDimensions.height} />
								</picture>
							)}

						</div>
						<div className={styles.imageHeight} style={{height: imageDimensions.height + 'px'}}>
							&nbsp;
						</div>
					</div>
				</>
			)}
			<Spacer />
		</>
	)
}

export default LongBanner
