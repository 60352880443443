import {useQuery} from "@tanstack/react-query";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState, useRef} from "react";
import {getModelById, getModelDeliveryAndPayment} from "../../api/catalog";
import Money from "../core/Money";
import {getSuffixByCount, objclone} from "../../utils/Utils";
import Review from "./model-card/Review";
import Description from "./model-card/Description";
import PurchaseReturn from "./model-card/PurchaseReturn";
import HowToUse from "./model-card/HowToUse";
// import Slider from "./model-card/Slider";
import Payments from "./model-card/Payments";
import Stars from "./model-card/Stars";
import {getBaseImagePath} from "./Utils";
import ReviewEstimateGraph from "./model-card/ReviewEstimateGraph";
import Breadcrumbs from "../core/Breadcrumbs";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {EcommerceAction} from "../../utils/useDataLayer";
import useDatalayer from "../../utils/useDataLayer";
import StoreReserv from "./StoreReserv";
import RightModal from "../core/popup-collection/RightModal";
import useModel from "./useModel";
import NotFoundPage from "../core/pages/NotFoundPage";
import Properties from "./model-card/Properties";
import SocialShare from "./SocialShare";
import HorizontalModelSlider from "../main/NowBuy";

import SliderSlick from "./model-card/SliderSlick";
import CardMobilePopup from "./model-card/CardMobilePopup";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import ModalReview from "./ModalReview";

import LandingContent from "./model-card/LandingContent";
import SizeSelectionPopup from './SizeSelectionPopup';
import DeliveryInfo from "./model-card/DeliveryInfo";
import {MOSCOW_REGION_ID} from "../../Config";
import PropertyList from "./model-card/PropertyList";
import ModelColorItemInterface from "../../interfaces/model/ModelColorItemInterface";

type QueryDataParams = {
    modelId: string
    slug: string
    color: string
}

enum TabType {
    ABOUT = 'about',
    REVIEW = 'review',
    CARE = 'care',
    RETURN = 'return'
}

export default function ModelCard() {
    const {action: dataLayerManagerAction} = useDatalayer();

    const basePath = ['catalog', 'model']
    const queryData = useParams<QueryDataParams>();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [selectedColor, setSelectedColor] = useState(queryData.color || '');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedTab, setSelectedTab] = useState(TabType.ABOUT);
    const [showPriceDetail, setShowPriceDetail] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [showStoreReserv, setShowStoreReserv] = useState(false);

    const [showReviewPopup, setShowReviewPopup] = useState(false);
    const [reviewPopup, setReviewPopup] = useState(null);

    const [showPodeliHint, setShowPodeliHint] = useState(false)
    const [showStoreReservHint, setShowStoreReservHint] = useState(false)


    const [clearCache, setClearCache] = useState(false)
    const refReviews = useRef<HTMLDivElement | null>(null)

    const [isOpen, setIsOpen] = useState(false);

    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [showSizePopup, setShowSizePopup] = useState<boolean>(false);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);

    const {data: modelData, status} = useQuery({
        queryKey: [...basePath, ...[queryData.modelId], clearCache],
        queryFn: () => getModelById(queryData.modelId || '', clearCache),
        //@ts-ignore
        retry: !window.NOSCRIPT ? false : 1
    })

    const {data: deliveryAndPaymentData, status: deliveryAndPaymentStatus} = useQuery({
        queryKey: [...basePath, ...[queryData.modelId], [state.region?.id], clearCache],
        queryFn: () => getModelDeliveryAndPayment(queryData.modelId || '', state.region?.id || MOSCOW_REGION_ID, clearCache),
        enabled: !!queryData.modelId,
        //@ts-ignore
        retry: !window.NOSCRIPT ? false : 1
    })

    const {
        model,
        meta,
        model_color_items: modelColorItems,
        breadcrumbs,
        store_reserv_stocks_quantity: storeReservStocksQuantity,
        landings,
        capsule_relations,
        capsule_model_colors,
        fashion_collection,
        same_color_items
    } = modelData || {model: undefined, meta: undefined}

    const {
        getImagesByColor,
        getVideosByColor,
        getFrontImages,
        getCurrentColorName,
        isSizeAvailable,
        getSelectedFrontImage,
        getImageAlt
    } = useModel(model, selectedColor)

    const openPopup = (index: number) => {
        setSlideIndex(index);
        setIsPopupOpen(true);
    };

    const [videos, setVideos] = useState(getVideosByColor ? getVideosByColor() : undefined);

    useEffect(() => {
        if (getVideosByColor) {
            const updatedVideos = getVideosByColor();
            setVideos(updatedVideos);
        }
    }, [selectedColor, model]);

    const handleColorClick = (color: string) => {
        setSelectedColor(color);
    };

    // TODO: весь образ
    // const categoryId = ['tsb-tso-000012', 'tsb-tso-000014', 'tsb-tst-000009'];
    // const {data: nowBuyModelItemsData} = useQuery({
    //     queryKey: [categoryId],
    //     queryFn: () => getModelColorItems({
    //         category_id: categoryId,
    //         gender_id: ['aa-aa-000002'],
    //         randomize: true,
    //         limit: 4
    //     })
    // })

    // const {model_color_items: nowBuyModelItems, meta: _} = nowBuyModelItemsData || {
    //     model_color_items: [],
    //     meta: undefined
    // }

    // const getImages = () => model?.images.filter(image => !(image.type === 'print')) || []

    useEffect(() => {
        // @ts-ignore
        window.prerenderReady = status !== 'pending';
    }, [status]);

    useEffect(() => {
        if (modelColorItems && meta) {
            const currentModelColorItem = model?.items?.find(colorItem => colorItem.color_special?.toLowerCase() === selectedColor?.toLowerCase())
            if (meta.title && meta.description && currentModelColorItem) {
                meta.title = meta.title.replace('[[[color]]]', currentModelColorItem.human_name || currentModelColorItem.color)
                meta.description = meta.description.replace('[[[color]]]', currentModelColorItem.human_name || currentModelColorItem.color)
                meta.image = 'https://baon.ru' + getSelectedFrontImage()?.url.replace(/shopcatalog\//, 'shopcatalog-resize/big/')
            }
        }
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: meta})
    }, [meta, selectedColor, model]);


    useEffect(() => {
            setSelectedColor(queryData.color || '')
        },
        [queryData.color]
    )

    useEffect(() => {
            const timeoutId = window.setTimeout(() => {
                window.scrollTo(0, 0)
            }, 100)
            return () => {
                window.clearTimeout(timeoutId)
            }
        },
        [queryData.modelId]
    )

    useEffect(() => {
        //@ts-ignore
        // $('.scrollbar-inner').scrollbar({"scrollx": "advanced", "scrolly": "advanced"});
        if ($(".main-product").length) {
            $(window).scroll(function () {
                var pr_scroll = $(window).scrollTop();
                var pr_firstBlock = $('.product__tabs');
                var pr_secondBlock = $('.submenu-product');
                //@ts-ignore
                if (pr_scroll >= pr_firstBlock.offset()?.top) {
                    pr_secondBlock.addClass('fixed');
                } else {
                    pr_secondBlock.removeClass('fixed');
                }
            });
        }
    }, []);

    useEffect(() => {
        const selectedColorItem = modelColorItems?.find(modelColorItem => modelColorItem.color_special?.toLowerCase() === selectedColor?.toLowerCase())
        if (selectedColorItem) {
            dataLayerManagerAction(EcommerceAction.VIEW_ITEM, [selectedColorItem])
        }
    }, [modelColorItems, selectedColor]);

    useEffect(() => {
        if (model && capsule_relations && capsule_model_colors) {
            const currentColorName = selectedColor.toLowerCase();
            const items = getModelsByCapsuleRelations(
                currentColorName,
                capsule_relations,
                capsule_model_colors,
                model?.id,
            );
            setFilteredItems(items);
        }
    }, [model, capsule_relations, capsule_model_colors, selectedColor]);

    const getFashionCollectionItems = (): ModelColorItemInterface[] => {
        return fashion_collection?.find(item => item.color === selectedColor)?.items || []
    }

    const getSameColorsItems = (): ModelColorItemInterface[] => {
        const currentColorHumanName = modelColorItems?.find(item => item.color_special.toLowerCase() === selectedColor)?.color_human_name_eng
        return same_color_items?.filter(item => item.color_human_name_eng === currentColorHumanName) || []
    }

    const getPodeliPayment = () => {
        let podeliPrice = objclone(model?.action_price)
        if (podeliPrice) {
            podeliPrice.amount = podeliPrice.amount / 4
        }
        return podeliPrice
    }

    const handleSizeSelection = (size: string) => {
        setSelectedSize(size);
        setShowSizePopup(false);
    };

    const addToBasket = () => {
        if (!modelColorItems || !selectedSize) {
            setShowSizePopup(true);
            return;
        }

        handleAddToBasket();
    };

    const handleAddToBasket = () => {
        if (!modelColorItems) {
            console.log("modelColorItems undefined");
            return;
        }

        const selectedColorItem = modelColorItems.find(
            (colorItem) =>
                colorItem.color_special?.toLowerCase() === selectedColor?.toLowerCase()
        );

        if (!selectedColorItem) {
            console.log("Не удалось найти цвет");
            return;
        }

        const item = model?.items.find(
            (item) =>
                item.color_special?.toLowerCase() === selectedColor?.toLowerCase() &&
                item.size === selectedSize
        );

        if (!item) {
            console.error("Не удалось найти цвет и размер");
            return;
        }

        const basketItem: BasketItemInterface = {
            modelColorItem: selectedColorItem,
            //@ts-ignore
            itemId: item.id,
            color: selectedColor,
            size: selectedSize,
            quantity: 1,
            createdAt: new Date(),
        };

        //  console.log("basketItem", basketItem);

        dispatch({type: ReducerActionType.ADD_TO_BASKET, payload: basketItem});
        dataLayerManagerAction(EcommerceAction.ADD_TO_CART, [selectedColorItem]);
    };


    const addToFavorite = () => {
        if (!modelColorItems) {
            return
        }

        const selectedColorItem = modelColorItems.find(colorItem => colorItem.color_special?.toLowerCase() === selectedColor?.toLowerCase())

        if (selectedColorItem) {
            dispatch({
                type: ReducerActionType.ADD_TO_FAVORITE,
                payload: selectedColorItem
            })

            dataLayerManagerAction(EcommerceAction.ADD_TO_WISHLIST, [selectedColorItem])
        }
    }

    if (status === 'error') {
        return (<NotFoundPage/>);
    }

    const getCurrentUrl = () => {
        if (!model) return undefined
        return '/catalog/' + model.id + '/' + model.special_transliterated_name + '/' + selectedColor.toLowerCase() + '/'
    }

    const showBlockFade = () => getFrontImages()?.length > 9

    const closeStoreReserv = () => setShowStoreReserv(false)

    const ShowReviewPopup = (model: any) => {
        setReviewPopup(model);
        setShowReviewPopup(true);
    };


    const getModelsByCapsuleRelations = (
        currentColorName: string,
        capsule_relations: any,
        capsule_model_colors: any,
        currentModelId: string
    ): any[] => {
        const relations = capsule_relations[currentColorName] || [];
        const models = capsule_model_colors || [];

        // console.log("Relations currentColor:", relations);
        // console.log("Capsule model colors:", models);

        const filteredModels = models.filter((model: any) => model.model_id !== currentModelId);

        if (relations.length === 0) {
            const matchingModels = filteredModels.filter(
                (model: any) => model.color_special?.toLowerCase() === currentColorName
            );

            const additionalModels = filteredModels.filter(
                (model: any) => model.color_special?.toLowerCase() !== currentColorName
            );

            const result = [...matchingModels, ...additionalModels];

            // console.log("Relations result:", result);

            return result.slice(0, 100);
        }

        const matchingModels = filteredModels.filter((model: any) => {
            const isInRelations = relations.includes(model.model_id);
            const isColorMatching = model.color_special?.toLowerCase() === currentColorName;

            return isInRelations && isColorMatching;
        });

        const additionalModels = filteredModels.filter((model: any) => {
            const isInRelations = relations.includes(model.model_id);
            const isColorNotMatching = model.color_special?.toLowerCase() !== currentColorName;

            return isInRelations && isColorNotMatching;
        });

        const result = [...matchingModels, ...additionalModels].sort(
            (a, b) => relations.indexOf(a.model_id) - relations.indexOf(b.model_id)
        );

        console.log("Final relations result:", result);

        return result.slice(0, 100);
    };

    return (<>

        {showSizePopup && (
            <SizeSelectionPopup
                sizes={model?.sizes || []}
                isSizeAvailable={isSizeAvailable}
                onClose={() => setShowSizePopup(false)}
                onSelectSize={(size) => {
                    handleSizeSelection(size);
                }}
                selectedColorItem={{
                    ...modelColorItems?.find(
                        (colorItem) =>
                            colorItem.color_special?.toLowerCase() === selectedColor?.toLowerCase()
                    ),
                    items: model?.items,
                }}
                dispatch={dispatch}
            />
        )}


        {showReviewPopup && (
            <>
                {reviewPopup && (
                    <ModalReview model={model} selectedSize={selectedSize} selectedColor={selectedColor}
                                 getFrontImages={getFrontImages} getBaseImagePath={getBaseImagePath}
                                 getImageAlt={getImageAlt} setShowReviewPopup={setShowReviewPopup}></ModalReview>
                )}
            </>
        )}

        {showStoreReserv && <RightModal additionalClass={''} closeFunction={closeStoreReserv}>
            {model && (storeReservStocksQuantity || 0) > 0 && <StoreReserv
                model={model}
                closeFunction={closeStoreReserv}
            />}
        </RightModal>}

        {(getImagesByColor()) &&
            <CardMobilePopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                initialSlideIndex={slideIndex}
                images={getImagesByColor()}
                altString={getImageAlt()}
            />
        }

        {model && <div className="submenu-product desk">
            <div className="container-fluid">
                <div className="submenu-product__wrap">
                    <div className="submenu-product__img desk"><img
                        src={getBaseImagePath() + getSelectedFrontImage()?.url} alt={getImageAlt()}/></div>
                    <div className="submenu-product__info">
                        <h3>{model?.inetshop_name}</h3>
                        <ul className="desk">
                            {selectedSize && <li>Размер: {selectedSize}</li>}
                            {selectedColor && <li>Цвет: {selectedColor.toUpperCase()}</li>}
                            <li>Артикул: {model?.articul}</li>
                        </ul>
                        <div className="stars desk">
                            <i className="ico ico-star"></i>
                            <p>{model?.review_average_estimate || 5}</p>
                        </div>
                    </div>
                    {model && <div className="submenu-product__price desk">
                        {model?.discount > 0 && model?.base_price.amount > model?.action_price.amount &&
                            <h5 className="submenu-product__price--new"><span className="f-exp"><Money
                                value={model?.action_price}/></span>
                                {model?.discount > 0 && (
                                    <span className="discount">-{model?.discount}%</span>)}</h5>}
                        {model?.base_price &&
                            <p className={"f-exp " + (model?.discount > 0 ? "submenu-product__price--old" : "")}><Money
                                value={model?.base_price}/></p>}
                    </div>}
                    <div className="submenu-product__btns">
                        <button className="btn btn-secondary" onClick={(event) => {
                            event.preventDefault()
                            addToBasket();
                        }}>Добавить в корзину
                        </button>
                        <button className="btn btn-secondary-tr" onClick={(event) => {
                            event.preventDefault()
                            addToFavorite()
                        }}><i className="ico ico-heart"></i></button>
                    </div>
                </div>
            </div>
        </div>}

        <main className="main-product">
            <section className="product">
                <div className="product__kv">
                    {(getImagesByColor() && breadcrumbs) &&
                        <SliderSlick images={getImagesByColor()} videos={videos || []} altString={getImageAlt()}
                                     breadcrumbs={breadcrumbs} onImageClick={(index) => openPopup(index)}/>
                    }
                    <div className="product__kv-info">
                        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs}/>}
                        <h1 className="product__kv-info-title f-exp">{model?.inetshop_name}</h1>
                        <div className="product__kv-info-meta">
                            {model?.review_average_estimate > 0 &&
                                <Stars reviewAverageEstimate={model?.review_average_estimate}/>}
                            {model?.review_count > 0 && <div className="feedback ico-cont">
                                <i className="ico ico-feedback ico-left"></i>
                                <p className="product__kv-info-meta-feedback" onClick={() => {
                                    setSelectedTab(TabType.REVIEW)
                                    refReviews.current?.scrollIntoView({
                                        behavior: 'smooth'
                                    })
                                }}>{model?.review_count || 0} отзыв{getSuffixByCount(model?.review_count || 0)}</p>
                            </div>}
                            <div className="articulus ico-cont share__block">
                                <a
                                    className="ico-cont information__link f-exp"
                                    href="/"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setShowShare(!showShare);
                                    }}
                                >
                                    <i className="ico ico-share ico-left"/>
                                </a>
                                {showShare && (
                                    <SocialShare getCurrentUrl={getCurrentUrl()}/>
                                )}
                                <p>Артикул {model?.articul}</p>
                            </div>
                        </div>
                        <div className="product__kv-info-price">
                            {model && <div className="product__kv-info-price-btn">
                                <div className="product__kv-info-price-title">
                                    <h5 className="f-exp"><Money value={model?.action_price}/></h5>
                                    {model?.discount > 0 && model?.base_price.amount > model?.action_price.amount &&
                                        <span>-{model?.discount}%</span>}
                                    {model?.discount > 0 && model?.base_price.amount > model?.action_price.amount &&
                                        <p className="f-exp"><Money value={model?.base_price}/></p>}
                                </div>
                                {model?.discount > 0 && <i className="ico ico-arrow-bot-b" onClick={() => {
                                    setShowPriceDetail(!showPriceDetail)
                                }}></i>}
                            </div>}

                            {modelData?.accrual_sum &&
                                <h6>до {modelData?.accrual_sum.amount / 100} бонус{getSuffixByCount(modelData?.accrual_sum.amount / 100)} за
                                    покупку</h6>}
                            {showPriceDetail && model?.discount && model?.discount > 0 &&
                                <div className="product__kv-info-price-col">
                                    <ul>
                                        {model.discount_reasons.map(reason => <li><p>{reason.name}</p><p>
                                            <b>- <Money value={reason.discount_absolute}/> </b></p></li>)}
                                    </ul>
                                </div>}
                        </div>
                        <div className="product__kv-info-split">
                            <div className="product__kv-info-split-title"><img
                                src="https://cdn.podeli.ru/New-Widgets/ReactWidget/logotype.svg"
                                alt=""/>
                            </div>
                            <div className="product__kv-info--hint">
                                {getPodeliPayment() &&
                                    <p className="ico-cont">х 4 платежа по <Money value={getPodeliPayment()}/></p>}
                                <span className="hint">
                            <i className="ico ico-question ico-right" onMouseEnter={() => {
                                setShowPodeliHint(true)
                            }} onMouseLeave={() => {
                                setShowPodeliHint(false)
                            }}></i>
                                    {showPodeliHint && <span className="hint__text">
                                <p>Оплатите с Подели только 25% стоимости покупки. Оставшиеся три платежа спишутся автоматически с шагом в две недели.</p>
                            </span>}
                        </span>
                            </div>
                        </div>


                        <div className="product__kv-info-model">
                            <p>{getCurrentColorName()}</p>
                            <div className={"product__kv-info-colors" + (showBlockFade() ? ' block-fade' : '')}>
                                <div className="swiper-wrapper">
                                    {model?.images && getFrontImages().map((image, index) => <Link
                                        to={'/catalog/' + model.id + '/' + model.special_transliterated_name + '/' + image.special_color_name.toLowerCase() + '/'}
                                        key={image.url}
                                        className={"swiper-slide " + (image.special_color_name.toLowerCase() === selectedColor ? "active" : "")}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleColorClick(image.special_color_name.toLowerCase());
                                        }}
                                    ><img
                                        src={getBaseImagePath() + image.url.replace('shopcatalog-resize/big', 'shopcatalog-resize/small')}
                                        alt={getImageAlt() + (index ? ' ' + (index + 1) : '')}/></Link>)}
                                </div>
                            </div>
                        </div>
                        <div className="product__kv-info-sizes">
                            <div className="top">
                                <p>Выберите размер</p>
                                <a href="/" onClick={(event) => {
                                    event.preventDefault()
                                    dispatch({type: ReducerActionType.TOGGLE_TABLEOFSIZES_POPUP})
                                }}>таблица размеров</a>
                            </div>
                            <ul>
                                {model?.sizes.map(size => <li key={size}><a
                                    className={(selectedSize === size ? " active " : "") + (size.length > 4 ? " label-long" : "") + (!isSizeAvailable(size) ? " disabled " : "")}
                                    href="/" onClick={(event) => {
                                    event.preventDefault()
                                    if (isSizeAvailable(size)) {
                                        setSelectedSize(size)
                                    }
                                }}>{size}</a></li>)}
                            </ul>
                            {/*<div className="bot">*/}
                            {/*    <p>Рост модели: 175 см, Размер на модели: S</p>*/}
                            {/*</div>*/}
                        </div>
                        <div className="product__kv-info-btns">
                            <button className="btn-one btn btn-secondary desk" onClick={(event) => {
                                event.preventDefault()
                                addToBasket();
                            }}>Добавить в корзину
                            </button>
                            <button className="btn-two btn btn-secondary-tr desk" onClick={(event) => {
                                event.preventDefault()
                                addToFavorite()
                            }}><i className="ico ico-heart"></i></button>

                            {(storeReservStocksQuantity || null) &&
                                <div className="product__kv-info--hint">
                                    <a className="link ico-cont" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setShowStoreReserv(true)
                                    }}>Зарезервировать в магазине</a>
                                    <span className="hint">
                                    <i className="ico ico-question ico-right" onMouseEnter={() => {
                                        setShowStoreReservHint(true)
                                    }} onMouseLeave={() => {
                                        setShowStoreReservHint(false)
                                    }}></i>
                                        {showStoreReservHint && <span className="hint__text">
                                        <p>Выберите удобный магазин для брони товара. Срок резерва: 2 дня. Время сборки заказа не более 8 часов</p>
                                    </span>}
                                </span>
                                </div>}
                        </div>
                        {deliveryAndPaymentData && <DeliveryInfo deliveryAndPaymentData={deliveryAndPaymentData}/>}
                        {model && <PropertyList model={model}/>}
                        <Payments/>
                    </div>
                </div>
                <div className="product__tabs" ref={refReviews}>
                    <i className="anchor-point"></i>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="product__tabs-nav">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <button
                                                className={"nav-link" + (selectedTab === TabType.ABOUT ? " active" : "")}
                                                onClick={() => {
                                                    setSelectedTab(TabType.ABOUT)
                                                }}>Описание
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={"nav-link" + (selectedTab === TabType.REVIEW ? " active" : "")}
                                                onClick={() => {
                                                    setSelectedTab(TabType.REVIEW)
                                                }}>Отзывы<sup>{model?.review_count}</sup></button>
                                        </li>
                                        {/*<li className="nav-item">*/}
                                        {/*    <button className={"nav-link" + (selectedTab === TabType.CARE ? " active" : "")} onClick={() => {*/}
                                        {/*        setSelectedTab(TabType.CARE)*/}
                                        {/*    }}>Уход*/}
                                        {/*    </button>*/}
                                        {/*</li>*/}
                                        <li className="nav-item">
                                            <button
                                                className={"nav-link" + (selectedTab === TabType.RETURN ? " active" : "")}
                                                onClick={() => {
                                                    setSelectedTab(TabType.RETURN)
                                                }}>Возврат
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-content">
                                <div
                                    className={"tab-pane fade" + (selectedTab == TabType.ABOUT ? ' show active' : '')}>
                                    <div className="product__tabs-cont product__description">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="left">
                                                    <SimpleBar style={{maxHeight: 800, overflowX: 'hidden'}}
                                                               autoHide={false}>
                                                        <div className="scrollbar-inner">
                                                            <Description model={model}/>
                                                        </div>
                                                    </SimpleBar>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <h5 className="f-exp mob">Описание</h5>
                                                <div className="right">
                                                    <div className="top"><i></i></div>
                                                    {model && <Properties model={model} mode={"desc"}/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"tab-pane fade" + (selectedTab == TabType.REVIEW ? ' show active' : '')}>
                                    <div className="product__tabs-cont product__feedback">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="left">
                                                    <SimpleBar style={{maxHeight: 800}} autoHide={false}>
                                                        <div className="scrollbar-inner">
                                                            <div className="product__feedback-list">
                                                                {model?.approved_reviews && model?.approved_reviews.map(review =>
                                                                    <Review key={review.id} review={review}/>)}
                                                            </div>
                                                            {/*<a className="link-more f-exp" href=""><span>Показать еще</span><span>Скрыть</span></a>*/}
                                                        </div>
                                                    </SimpleBar>
                                                    <div className="mob"><a className="btn btn-secondary"
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                {
                                                                                    (!state.customer?.id) && dispatch({
                                                                                        type: ReducerActionType.SHOW_POPUP,
                                                                                        payload: 'showAuthPopup'
                                                                                    })
                                                                                }
                                                                                {
                                                                                    (state.customer?.id) && ShowReviewPopup(model);
                                                                                }
                                                                            }}>Написать
                                                        отзыв</a></div>
                                                </div>
                                            </div>
                                            <div className="col-4 desk">
                                                <div className="right">
                                                    <div className="top">
                                                        {model?.review_average_estimate > 0 && <Stars
                                                            reviewAverageEstimate={model?.review_average_estimate}/>}
                                                        <h5 className="f-exp">{model?.review_average_estimate} /
                                                            5</h5>
                                                    </div>
                                                    <div className="mid">
                                                        <ReviewEstimateGraph
                                                            reviews={model?.approved_reviews || []}/>
                                                    </div>
                                                    <div className="bot">
                                                        <button className="btn btn-secondary"
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    {
                                                                        (!state.customer?.id) && dispatch({
                                                                            type: ReducerActionType.SHOW_POPUP,
                                                                            payload: 'showAuthPopup'
                                                                        })
                                                                    }
                                                                    {
                                                                        (state.customer?.id) && ShowReviewPopup(model);
                                                                    }
                                                                }}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#modal-feedback">Написать отзыв
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"tab-pane fade" + (selectedTab == TabType.CARE ? ' show active' : '')}>
                                    <div className="product__tabs-cont product__text">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="left">
                                                    <SimpleBar style={{maxHeight: 800}} autoHide={false}>
                                                        <div className="scrollbar-inner">
                                                            <HowToUse/>
                                                        </div>
                                                    </SimpleBar>
                                                </div>
                                            </div>
                                            <div className="col-4 desk">
                                                <div className="right">
                                                    <h3 className="ico-cont"><i
                                                        className="ico ico-left ico-question"></i>Согласно ГОСТ
                                                        30332-95
                                                    </h3>
                                                    <p>изделия с перопуховым утеплителем не должны подвергаться
                                                        стирке и
                                                        глажению, поэтому при небольших загрязнениях следует замыть
                                                        верх
                                                        изделия, используя теплую воду и щадящие моющие средства.
                                                        Это не
                                                        даст пуху намокнуть, так как ткани верха изделия и пухового
                                                        пакета имеют водостойкое покрытие.</p>
                                                    <p><span>Регулярно взбивайте и проветривайте изделие.</span></p>
                                                    <p><span>Не храните изделие влажным в сложенном виде, герметично закрытым и плотно запакованным.</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"tab-pane fade" + (selectedTab == TabType.RETURN ? ' show active' : '')}>
                                    <div className="product__tabs-cont product__text">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="left">
                                                    <SimpleBar style={{maxHeight: 800}} autoHide={false}>
                                                        <div className="scrollbar-inner">
                                                            <PurchaseReturn/>
                                                        </div>
                                                    </SimpleBar>
                                                </div>

                                            </div>
                                            <div className="col-4 desk">
                                                <div className="right">
                                                    <h3>Вы вправе отказаться от товара в течение 10 рабочих
                                                        дней</h3>
                                                    <p>с момента получения (в период проведения специальных акций
                                                        указанные сроки могут быть увеличены). </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {landings && landings.length > 0 && (
                    <>
                        <div className="product__page__landing">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="product-tab-landings">
                                            <div className={`product-tab-landings__content ${isOpen ? '' : 'blur'}`}
                                                 style={{
                                                     maxHeight: isOpen ? "none" : "500px",
                                                     overflow: isOpen ? "visible" : "hidden",
                                                     position: "relative",
                                                     clipPath: isOpen ? "none" : "inset(0 0 calc(100% - 500px) 0)",
                                                 }}
                                            >
                                                {landings && landings.length > 0 ? (
                                                    <LandingContent landings={landings || []}/>
                                                ) : ''}
                                            </div>
                                            <a className="information__link f-exp" href="/"
                                               onClick={(event) => {
                                                   event.preventDefault();
                                                   setIsOpen(!isOpen);
                                               }}
                                            >
                                                {!isOpen && <span>Подробнее</span>}
                                                {isOpen && <span>Свернуть</span>}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*    <AboutMaterials/>*/}
                        </div>
                    </>
                )}

                <div className="product__btns-add mob">
                    <div className="wrap">
                        {model && <button className="btn btn-secondary btn-one" onClick={(event) => {
                            event.preventDefault()
                            addToBasket();
                        }}>Добавить в корзину • <Money value={model?.action_price}/></button>}
                        <button className="btn btn-secondary-tr btn-two" onClick={(event) => {
                            event.preventDefault()
                            addToFavorite()
                        }}><i className="ico ico-heart"></i></button>
                    </div>
                </div>
            </section>
            {/*<section className="now-buy now-buy--big">*/}
            {/*    <div className="point"></div>*/}
            {/*    <div className="container-fluid">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col">*/}
            {/*                <h2 className="title f-exp">Весь образ</h2>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row now-buy-row">*/}
            {/*            {nowBuyModelItems && nowBuyModelItems.map(modelColorItem => <div key={modelColorItem.id}*/}
            {/*                                                                             className="col-lg-3 col-6">*/}
            {/*                <ModelListCard modelColorItem={modelColorItem}/>*/}
            {/*            </div>)}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            {getFashionCollectionItems().length > 0 &&  <HorizontalModelSlider modelColorItems={getFashionCollectionItems()} header={"Весь образ"} slidesPerViewDesktop={4}/>}

            {filteredItems && filteredItems.length > 0 && (
                <HorizontalModelSlider modelColorItems={filteredItems} header={"Товары из капсулы"}/>
            )}

            {getSameColorsItems().length > 0 &&  <HorizontalModelSlider modelColorItems={getSameColorsItems()} header={"Похожие товары"} />}

        </main>
        {!clearCache && <span className={'text-white pointer px-3 py-1'} onClick={() => {
            setClearCache(true)
        }} aria-label={'очистить кеш'}>.</span>}
        {clearCache &&
            <div className={'text-center alert alert-danger mx-5'}><span>Данные без кеша / кеш обновлен</span></div>}
    </>)
}