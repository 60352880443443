import React from "react";
import LandingInterface from "../../../interfaces/landing/LandingInterface";
import Landing from "../../landing/Landing";

interface LandingProps {
    landings: LandingInterface[];
}

const LandingContent: React.FC<LandingProps> = ({landings}) => {
    return (
        <div className="landing-page">
            {landings.map((landing: LandingInterface) => (
                <div className="landing" key={landing.id}>
                    <Landing landingItems={landing.items} error={""} />
                </div>
            ))}
        </div>
    );
};

export default LandingContent;
