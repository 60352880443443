import {BannerInterface} from "../../interfaces/core/BannerInterface";

export default function WMBanners({banners}: {banners: BannerInterface[]}) {
    return (<section className="banners mb">
        <div className="container-fluid">
            <div className="row">
                {banners.map(banner => <div key={banner.image} className="col-lg-6">
                    <div className="banner">
                        <a href={banner.url}><picture className="banner__img">
                            <source srcSet={banner.image} media="(max-width: 992px)"/>
                            <img src={banner.image} alt=""/>
                        </picture></a>
                        <h2 className="banner__medium-title f-exp">{banner.title}</h2>
                        <p className="banner__text">{banner.description}</p>
                    </div>
                </div>)}
            </div>
        </div>
    </section>);
}
