import Order from "./Order";
import ReviewRequiredCard from "./ReviewRequiredCard";
import {useOutletContext} from "react-router-dom";
import {CustomerCredentialResponse} from "../../../interfaces/api/CustomerCredentialResponseInterface";

export default function Main() {
    const data = useOutletContext<CustomerCredentialResponse>();

    return (<div className="tab-pane fade show active">
            {/*<div className="lk__main">*/}
            {/*    <div className="lk__main-banner">*/}
            {/*        <picture>*/}
            {/*            <source*/}
            {/*                srcSet="/img/main-banner.jpg"*/}
            {/*                media="(max-width: 1024px)"*/}
            {/*            />*/}
            {/*            <img src="/img/main-banner.jpg" alt=""/>*/}
            {/*        </picture>*/}
            {/*        <h4>до -70% на коллекцию SS’23</h4>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="lk__main-top">
                <ul>
                    <li className="bd">
                        <p>Бонусные баллы</p>
                        <h5 className="f-exp">{data.bonus_info?.active_balance || 0} Б</h5>
                    </li>
                    <li className="bd">
                        <p>Мои отзывы</p>
                        <h5 className="f-exp">{data.reviews.length} товаров</h5>
                    </li>
                </ul>
            </div>
            <div className="lk__main-body">
                <h4 className="lk__main-body--title f-exp">Активные заказы</h4>
                <div className="lk__orders">
                    {data.orders.slice(0, 4).map(order => <Order key={order.id} order={order} />)}
                </div>
            </div>
            {/*<div className="lk__main-foot">*/}
            {/*    <h4 className="f-exp">4 покупки ожидают оценки</h4>*/}
            {/*    <h5>Оставьте отзыв и получите бонусные баллы от Baon</h5>*/}
            {/*    <div*/}
            {/*        className="lk__main-foot-list swiper-initialized swiper-horizontal swiper-backface-hidden">*/}
            {/*        <div className="swiper-wrapper" >*/}
            {/*            <ReviewRequiredCard />*/}
            {/*            <ReviewRequiredCard />*/}
            {/*            <ReviewRequiredCard />*/}
            {/*            <ReviewRequiredCard />*/}
            {/*        </div>*/}
            {/*        <span*/}
            {/*            className="swiper-notification"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}