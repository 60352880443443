import ModelInterface from "../../interfaces/model/ModelInterface";
import {useEffect, useState} from "react";
import ModelImageInterface from "../../interfaces/model/ModelImageInterface";
import {DEFAULT_SHOP_ID, SIZE_ORDER} from "../../Config";
import ModelVideoInterface from "../../interfaces/model/ModelVideoInterface";
import image from "../landing/components/Image/Image";
import {ColorInterface} from "../../interfaces/dealer/ColorInterface";

export default function useModel(model: ModelInterface | undefined, selectedColor: string = '') {
    const [selectedSize, setSelectedSize] = useState('');

    const getImages = () => model?.images?.filter(image => !(image.type === 'print')) || []
    const getVideos = () => model?.videos || [];

    useEffect(() => {
        if (!isSizeAvailable(selectedSize)) {
            setSelectedSize('')
        }
    }, [selectedColor])

    const getImagesByColor = (): ModelImageInterface[] => {
        if (!model?.images) {
            return [];
        }
        return getImages()?.filter(image => image.special_color_name?.toLowerCase() === selectedColor?.toLowerCase())
    }

    const getPrintImageByColor = (): ModelImageInterface | undefined => {
        return getImagesByColor().find(image => image.type === 'print')
    }

    const getVideosByColor = (): ModelVideoInterface[] => {
        if (!model?.videos) {
            return [];
        }
        return getVideos().filter(video => video.special_color_name?.toLowerCase() === selectedColor?.toLowerCase())
    }

    const getAvailableSpecialColors = () => {
        return model?.items?.filter(item => item.stocks.map(stock => stock.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0) > 0).map(modelItem => {
            return modelItem.color_special
        }).filter((value, index, array) => {
            return array.indexOf(value) === index;
        })
    }

    const getFrontImages = (): ModelImageInterface[] => {
        if (!model?.images) {
            return [];
        }
        const existSpecialColors = getAvailableSpecialColors() || []

        return getImages()?.filter(image => image.type === "front").filter(image => existSpecialColors.find(color => color.toLowerCase() === image.special_color_name.toLowerCase()))
    }

    const getFrontImageByColor = (): ModelImageInterface|null => {
        if (!model?.images) {
            return null;
        }
        let frontImage: ModelImageInterface|undefined =  getFrontImages().filter(image => image.type === "front").find(image => true)

        if(!frontImage) {
            frontImage = getFrontImages()?.find(image => true)
        }

        return frontImage || null
    }

    const getCurrentColorName = (): string => {
        const firstItem = model?.items.find(item => item.color_special?.toLowerCase() === selectedColor?.toLowerCase());
        return firstItem ? firstItem.color : "No color"
    }

    const isSizeAvailable = (size: string, shopId = DEFAULT_SHOP_ID): boolean => {
        const item = model?.items?.find(item => item.size === size && item.color_special?.toLowerCase() === selectedColor?.toLowerCase())
        if (!item) {
            return false;
        }
        const retailStock = item?.stocks?.find(stock => stock.shop_id === shopId)


        if (!retailStock) {
            return false;
        }

        return retailStock.quantity > 0
    }

    const getSelectedFrontImage = (): ModelImageInterface | undefined => {
        if (!model?.images) {
            return undefined;
        }
        return getImages()?.find(image => image.type === "front" && image.special_color_name?.toLowerCase() === selectedColor?.toLowerCase())
    }

    const getMainImage = (): ModelImageInterface | undefined => {
        const mainColorName = model?.extra_param?.main_color_name?.toLowerCase()

        let mainImage = getImages()?.find(image => image.special_color_name === mainColorName)

        if(!mainImage) {
            mainImage =  getImages()?.find(image => true)
        }

        return mainImage
    }

    const getImageAlt = (): string => {
        return model?.inetshop_name + ' Baon ' + model?.articul
    }

    const getUniqueSizes = (onlyShowable = false) => {
        let sizes: string[] = []
        model?.items.filter(item => !onlyShowable || item.is_showable).forEach(item => {
            if (!sizes.includes(item.size)) {
                sizes.push(item.size)
            }
        })
        const sorteredSize = SIZE_ORDER.split('|').filter(size => sizes.indexOf(size) > -1)
        const undetectedSizes = sizes.filter(size => sorteredSize.indexOf(size) === -1)

        return sorteredSize.concat(undetectedSizes)
    }

    const getUniqueColors = (onlyShowable = false) => {
        let colors: ColorInterface[] = []
        model?.items.filter(item => !onlyShowable || item.is_showable).forEach(item => {
            if (!colors.map(color => color.color).includes(item.color)) {
                colors.push({
                    color: item.color,
                    humanName: item.color,
                    hex: item.hex,
                    special: item.color_special
                })
            }
        })
        return colors.sort()
    }

    const getPrintByColor = (color: string) => {
        return model?.images.find(image => image.special_color_name === color && image.type === 'print')?.url
    }

    return {getImages, getVideosByColor, getImagesByColor, getFrontImages, getFrontImageByColor, getPrintImageByColor, getMainImage, getCurrentColorName, isSizeAvailable, getSelectedFrontImage, getImageAlt, getUniqueSizes, getUniqueColors, getPrintByColor}
}