import {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {sendSubscribeMail} from "../../api/landing";

export enum SubscribeStatus {
    subscribeDefault = "subscribeDefault",
    subscribeSent = "subscribeSent",
    subscribeError = "subscribeError",
}

export default function Subscribe() {
    const [subscribeForm, setSubscribeForm] = useState<SubscribeStatus>(SubscribeStatus.subscribeDefault);

    const [message, setMessage] = useState("");

    const {register, handleSubmit, reset, formState} = useForm();

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({email: ""});
        }
    }, [formState, reset]);

    let formSubmit = async (data: any) => {
        try {
            const res = await sendSubscribeMail({
                email: data.email,
                city_name: data.city_name,
                region_name: data.region_name,
            });
            setSubscribeForm(SubscribeStatus.subscribeSent);
        } catch (err) {
            setSubscribeForm(SubscribeStatus.subscribeError);
        }
    };

    return (
        <section className="subscribe mb">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div
                            className={
                                "subscribe__wrap " +
                                (subscribeForm === SubscribeStatus.subscribeDefault ? "active" : "")
                            }
                        >
                            <div className="subscribe__block">
                                <h5 className="f-exp">
                                    Подпишитесь и получите
                                    <br className="mob" /> скидку 10% на первую покупку!
                                </h5>
                                <form onSubmit={handleSubmit(formSubmit)}>
                                    <div className="form-group">
                                        <input
                                            id="email"
                                            type="email"
                                            placeholder="Введите ваш e-mail"
                                            className="form-control"
                                            {...register("email", {
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Email заполнен неправильно",
                                                },
                                            })}
                                        />
                                        <button className="btn btn-secondary btn-bd" type="submit">
                                            <i
                                                className="ico ico-arrow-right-w mob"
                                                onClick={() => setSubscribeForm(SubscribeStatus.subscribeSent)}
                                            ></i>
                                            <span className="desk">Подписаться</span>
                                        </button>
                                    </div>
                                </form>
                                <div className="message">{message ? <p>{message}</p> : null}</div>
                            </div>
                        </div>
                        <div
                            className={
                                "subscribe__wrap " + (subscribeForm === SubscribeStatus.subscribeError ? "active" : "")
                            }
                        >
                            <div className="subscribe__block">
                                <h5 className="f-exp">
                                    Указанный e-mail уже подписан на получение новостей от BAON! Спасибо, что Вы с нами!
                                </h5>
                                <div className="form-group subscribe__sb">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => setSubscribeForm(SubscribeStatus.subscribeDefault)}
                                    >
                                        ОК
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                "subscribe__wrap " + (subscribeForm === SubscribeStatus.subscribeSent ? "active" : "")
                            }
                        >
                            <div className="subscribe__block">
                                <h5 className="f-exp">
                                    Спасибо, что подписались на рассылку BAON.
                                    <br /> Подтвердите подписку по ссылке в письме, высланном на Ваш e-mail.
                                </h5>
                                <div className="form-group subscribe__sb">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => setSubscribeForm(SubscribeStatus.subscribeDefault)}
                                    >
                                        ОК
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
