import {Link} from "react-router-dom";
import LinkExtendedInterface from "../../interfaces/core/LinkExtendedInterface";

export default function Shops({shop}:{shop:LinkExtendedInterface})
{
    return (<section className="shops">
        {shop && <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="banner__outer">
                        <div className="banner">
                            <picture className="banner__img">
                                <source srcSet={shop.image} media="(max-width: 992px)" />
                                    <img src={shop.image} alt="" />
                            </picture>
                            <h1 className="banner__title f-exp">{shop.title}</h1>
                            <p className="banner__text">{shop.description}</p>
                            <Link className="btn btn-primary" to={shop.url}>Подробнее</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </section>)
}