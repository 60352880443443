import React, {useContext} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {Controller} from "react-hook-form";
import Input from "react-phone-number-input/input";
import isEmail from "validator/es/lib/isEmail";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

function CommentForm() {
    /* eslint-disable no-unused-vars */
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    return (
        <>
            <div className="basket__form bd">
                <form action="">
                    <h5 className="basket__form-title f-exp">Комментарий к заказу</h5>
                    <div className="form-wrap big">
                        <div className="form-group">
                            <div className="form-group">
                                <textarea className={'form-control'} placeholder="Комментарий к заказу"
                                          onChange={(e) => {
                                              dispatch({
                                                  type: ReducerActionType.SET_PARAM,
                                                  paramName: 'comment',
                                                  payload: e.target.value
                                              })
                                          }}></textarea>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </>
    )
}

export default CommentForm