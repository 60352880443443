import {Button, ButtonGroup, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import {FiltersInterface} from "../../interfaces/dealer/FiltersInterface";

enum QualityType {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high'
}

enum VersionType {
    BASE = 'base',
    EXTENDED = 'extended'
}

enum GroupByType {
    CATEGORY = 'category',
    CAPSULE = 'capsule'
}

export default function PrintCatalog({filters}: { filters: FiltersInterface }) {
    const [quality, setQuality] = useState<QualityType>(QualityType.LOW);
    const [version, setVersion] = useState<VersionType>(VersionType.BASE);
    const [useDealerPrice, setUseDealerPrice] = useState(false)
    const [useStockPrice, setUseStockPrice] = useState(false)
    const [groupBy, setGroupBy] = useState<GroupByType|undefined>(undefined)

    const printCatalog = () => {
        const data = {...filters, ...{"group_by": groupBy || '' , "include_dealer_price": useDealerPrice, "include_stock_price" : useStockPrice, "quality": quality, "version": version, '__dealer__' : null }};

        window.open('/api/dealer/models-print?filters=' + JSON.stringify(data) , '_blank');
    }

    return (<Row className={'bg-light p-4 my-4 border-radius'}>
            <Col md={12}>
                <h2>Печать каталога</h2>
            </Col>
            <Col md={3}>
                <h5>Качество изображения:</h5>
                <ButtonGroup aria-label="Basic example">
                    <Button variant={ (quality === QualityType.LOW ? '' : 'outline-' ) +  'secondary'} onClick={() => { setQuality(QualityType.LOW) }} className={'btn-small'}>Низкое</Button>
                    <Button variant={(quality === QualityType.MEDIUM ? '' : 'outline-') +  'secondary'} onClick={() => { setQuality(QualityType.MEDIUM) }} className={'btn-small'}>Среднее</Button>
                    <Button variant={(quality === QualityType.HIGH ? '' : 'outline-') +  'secondary'} onClick={() => { setQuality(QualityType.HIGH) }} className={'btn-small'}>Высокое</Button>
                </ButtonGroup>
            </Col>
            <Col md={3}>
                <h5>Версия каталога:</h5>
                <ButtonGroup aria-label="Basic example">
                    <Button variant={ (version === VersionType.BASE ? '' : 'outline-' ) +  'secondary'} onClick={() => { setVersion(VersionType.BASE) }} className={'btn-small'}>Базовая</Button>
                    <Button variant={(version === VersionType.EXTENDED ? '' : 'outline-') +  'secondary'} onClick={() => { setVersion(VersionType.EXTENDED) }} className={'btn-small'}>Расширенная</Button>
                </ButtonGroup>
            </Col>
            <Col md={3}>
                <h5>Включить цены:</h5>
                <ButtonGroup aria-label="Basic example">
                    <Button variant={ (!useDealerPrice ? 'outline-' : '') +  'secondary'} onClick={() => {setUseDealerPrice(!useDealerPrice)}} className={'btn-small'}>Оптовые</Button>
                    <Button variant={(!useStockPrice ? 'outline-' : '') + 'secondary'} onClick={() => {setUseStockPrice(!useStockPrice)}}  className={'btn-small'}>Розничные</Button>
                </ButtonGroup>
            </Col>
            <Col md={3}>
                <h5>Групировать по:</h5>
                <ButtonGroup aria-label="Basic example">
                    <Button variant={ (groupBy === GroupByType.CATEGORY ? '' : 'outline-' ) +  'secondary'} onClick={() => { setGroupBy(groupBy === GroupByType.CATEGORY ? undefined : GroupByType.CATEGORY) }} className={'btn-small'}>Категориям</Button>
                    <Button variant={(groupBy === GroupByType.CAPSULE ? '' : 'outline-') +  'secondary'} onClick={() => { setGroupBy(groupBy === GroupByType.CAPSULE ? undefined : GroupByType.CAPSULE) }} className={'btn-small'}>Капсулам</Button>
                </ButtonGroup>
            </Col>
            <Col md={12} className={'pt-4'}>
                <Button variant={'secondary'} onClick={printCatalog}>Распечатать каталог</Button>
            </Col>

        </Row>
    )
}