import fetchData from "../utils/HttpClient";
import RefundInterface from "../interfaces/customer/RefundInterface";
import {CustomerInterface} from "../interfaces/customer/CustomerInterface";
import {RegionInterface} from "../interfaces/core/RegionInterface";

const basePathRefund = '/api/refund';

export function create(refund: RefundInterface, customer: CustomerInterface): Promise<{ id: number, key: string }> {
    return fetchData(basePathRefund + '/create', {
        refund: refund,
        customer: customer
    }, 'POST')
}

export function credentials(refund: RefundInterface, customer: CustomerInterface): Promise<{ key: string }> {
    return fetchData(basePathRefund + '/credentials', {
        refund: refund,
        customer: customer
    }, 'POST')
}