import fetchData from "../utils/HttpClient";
import {
    CheckEmailAndPhoneRequestInterface,
    CheckEmailAndPhoneResponseInterface, CheckEmailRequestInterface, CheckEmailResponseInterface
} from "../interfaces/api/CheckEmailAndPhoneInterface";

export async function checkEmailAndPhone(data:CheckEmailAndPhoneRequestInterface): Promise<CheckEmailAndPhoneResponseInterface>  {
    return fetchData( '/api/manzana/check-phone-and-email', data , 'POST')
}

export async function checkEmail(data:CheckEmailRequestInterface): Promise<CheckEmailAndPhoneResponseInterface>  {
    return fetchData( '/api/manzana/check-email', data , 'POST')
}