export default function PurchaseReturn() {
    return (<>
        <div className="product__text-block">
            <h3 className="product__text-block-link">
                <span>1</span> Заполнить заявление на возврат: <i
                className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <ul>
                    <li>в личном кабинете перейдите в МОИ ЗАКАЗЫ или,
                        если заказ был оформлен через оператора
                        call-центра, перейдите по ссылке
                    </li>
                    <li>выберите заказ, по которому хотите сделать
                        возврат товара
                    </li>
                    <li>нажмите на ОФОРМИТЬ ВОЗВРАТ</li>
                    <li>заполните все поля и нажмите РАСПЕЧАТАТЬ</li>
                    <li>отправьте на электронную почту vozvrat@baon.ru
                        копию паспорта с указанием номера заказа (только
                        для нерезидентов РФ).
                    </li>
                </ul>
                <blockquote>
                    <span>Заполненное заявление автоматически отправится менеджеру по возвратам BAON, а также продублируется на вашу электронную почту</span>
                </blockquote>
            </div>
        </div>
        <div className="product__text-block">
            <h3 className="product__text-block-link">
                <span>2</span> Передать товар с приложенным заявлением о
                возврате <i className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <ul>
                    <li>При возврате в ФИРМЕННЫЙ МАГАЗИН - заполните
                        заявление на возврат. После заполнения заявления
                        распечатайте его либо сохраните скриншот или
                        фото. Товар передайте сотруднику розничного
                        магазина, назвав номер электронного заявления
                        (заполнение заявления в рукописном виде в
                        фирменном магазине не производится).
                    </li>
                    <li>При возврате ПОЧТОЙ РОССИИ-отправляете вещи
                        самостоятельно через отделение почты. Пришлите
                        трек-номер или иной идентификатор для
                        отслеживания возвращаемого товара на адрес эл.
                        почты vozvrat@baon.ru, чтобы мы могли оперативно
                        его получить.
                    </li>
                    <li>Адрес для отправки посылок Почтой России:
                        143430. обл. Московская, г. Красногорск, рп.
                        Нахабино, ул. Советская, дом 28, ООО "Бифлекс".
                    </li>
                    <li>Обратите внимание: расходы по пересылке товара
                        покупатель берёт на себя. В том случае, если вы
                        возвращаете товар по причине брака Почтой
                        России, расходы будут компенсированы (при выборе
                        других транспортных компаний стоимость обратной
                        пересылки не компенсируется.) Сохраните
                        квитанцию об оплате почтовых услуг и пришлите
                        скан/фото на e-mail vozvrat@baon.ru.
                    </li>
                </ul>
            </div>
        </div>
        <div className="product__text-block">
            <h3 className="product__text-block-link">
                <span>3</span> Ожидать перевода денежных средств <i
                className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <p>Денежные средства переводятся в течение 10 рабочих
                    дней с момента фактического получения товара,
                    подлежащего возврату. Обратите внимание: если вы
                    оплачивали заказ банковской картой, то возврат
                    денежных средств должен быть произведён на неё
                    же.</p>
            </div>
        </div>
    </>)
}