import Money from "../core/Money";
import React, {useContext, useEffect, useState, useRef} from "react";
import {ecommerceDataLayer} from "./utils/marketing/Service";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {DeliveryInterface} from "../../interfaces/checkout/DeliveryInterface";
import {LocationInterface} from "../../interfaces/checkout/LocationInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {BasketPartConfigInterface} from "../../interfaces/checkout/BasketPartConfigInterface";
import Locations from "./Locations";

const COURIER = 'courier';

const PICKUP = 'pickup';

function DeliveryList(
    {
        deliveries,
        basketPartConfig,
        setBasketPartConfig
    }: {
        deliveries: DeliveryInterface[],
        basketPartConfig: BasketPartConfigInterface,
        setBasketPartConfig: (basketPartConfig: BasketPartConfigInterface) => void
    }) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [deliveryType, setDeliveryType] = useState(PICKUP)

    const [isShowLocationPopup, setIsShowLocationPopup] = useState(false)

    useEffect(() => {
        const isShow = !!(basketPartConfig?.delivery?.locations?.length && !basketPartConfig.location);
        setIsShowLocationPopup(isShow)
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBackdrop', payload: isShow})
    }, [basketPartConfig.delivery?.delivery_type.id, basketPartConfig.location?.uid]);

    const period = (delivery: DeliveryInterface) => {
        // если реализована логика со smart периодом, когда для отдельных типов доставки, реализовано название
        if (delivery.smart_period_name) {
            return delivery.smart_period_name
        }

        const minimumPeriod = (locations: LocationInterface[]) => {
            const period = locations.map(location => {
                return location.period_from
            })
            return Math.min(...period);
        }

        const maximumPeriod = (locations: LocationInterface[]) => {
            const period = locations.map(location => {
                return location.period_to
            })
            return Math.max(...period);
        }

        const min = minimumPeriod(delivery.locations || [delivery.first_customer_delivery_courier])
        const max = maximumPeriod(delivery.locations || [delivery.first_customer_delivery_courier])
        return (min === max ? min : min + '-' + max) + ' дн.'
    }

    const getFilteredDeliveryList = (dtype: string) => {
        return deliveries?.filter(delivery => {
            return (dtype === COURIER && (/курьер/).test(delivery.delivery_type?.name?.toLowerCase())) || (dtype === PICKUP && !(/курьер/).test(delivery.delivery_type.name.toLowerCase()))
        })
    }

    return (<>
        {basketPartConfig?.delivery?.locations && isShowLocationPopup &&  <div
            onClick={() => {
                setIsShowLocationPopup(false)
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBackdrop', payload: false})
            }}
            className="modal fade modal-map modal--points show"
            style={{display: "block"}}
        >
            <div className="modal-dialog modal-dialog-centered" role="document" onClick={(e) => {e.stopPropagation()}}>
                <div className="modal-content">
                    <button type="button" className="close" onClick={() => {
                         setIsShowLocationPopup(false)
                        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBackdrop', payload: false})
                    }}>&times;</button>
                    <Locations basketPartConfig={basketPartConfig} setBasketPartConfig={setBasketPartConfig}/>
                </div>
            </div>
        </div>}

        <div className="basket__form bd">
            <h5 className="basket__form-title f-exp">Способ доставки</h5>
            <ul className="nav nav-tabs f-exp" id="myTab" role="tablist">
                {getFilteredDeliveryList(COURIER)?.length > 0 && <li className="nav-item" role="presentation">
                    <button className={"nav-link " + (deliveryType === COURIER ? 'active' : '')} onClick={() => {
                        setDeliveryType(COURIER)
                    }} type="button">
                        Курьер
                    </button>
                </li>}
                <li className="nav-item" role="presentation">
                    <button className={"nav-link " + (deliveryType === PICKUP ? 'active' : '')} onClick={() => {
                        setDeliveryType(PICKUP)
                    }} type="button">
                        Самовывоз
                    </button>
                </li>
            </ul>


            <div className={"form-radios--list"}>
                {getFilteredDeliveryList(deliveryType)?.map((delivery) => <label key={delivery.delivery_type.id}
                                                                                 className={"form-radio bd " + (basketPartConfig.delivery?.delivery_type.name === delivery.delivery_type.name && 'active')}
                                                                                 onClick={() => {
                                                                                     setBasketPartConfig({
                                                                                         ...basketPartConfig,
                                                                                         delivery: delivery,
                                                                                         location: null,
                                                                                         address: null
                                                                                     })
                                                                                     setIsShowLocationPopup(true)
                                                                                     dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBackdrop', payload: true})

                                                                                     // @ts-ignore
                                                                                     // TODO: Перенести на уровень выше через useEffect
                                                                                     // ecommerceDataLayer('checkout', 'checkout', state.positions, 3, delivery.delivery_type.name)
                                                                                 }}>
                    <i className="label-dot"/>
                    <h6>{period(delivery)}</h6>
                    <p className="f-exp">{delivery.delivery_type.name}</p>
                    <small>{delivery.first_customer_delivery_courier.price.amount > 0 &&
                        <span>Стоимость доставки от <Money
                            value={delivery.first_customer_delivery_courier.price}/> </span>}
                        {delivery.first_customer_delivery_courier.price.amount === 0 &&
                            <span>Бесплатно</span>}, {delivery.has_fitting ? 'есть примерка' : 'нет примерки'}</small>
                </label>)}
            </div>
            {basketPartConfig.location && <div className="col mt-4">
                <h5>
                    <b>Выбранный
                        адрес: </b> {basketPartConfig.location.address}, {basketPartConfig.location.fitting ? 'есть примерка' : 'нет примерки'},&nbsp;
                    {basketPartConfig.location.price.amount ? <Money value={basketPartConfig.location.price}/> :
                        <span> бесплатно</span>} | <span className={'dashed pointer'} onClick={(event) => {
                    event.preventDefault()
                    setBasketPartConfig({
                        ...basketPartConfig,
                        location: null,
                        address: null
                    })
                }}>изменить</span>

                </h5>
                <div>

                </div>
            </div>}
        </div>
    </>)
}

export default DeliveryList