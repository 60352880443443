import fetchData from "../utils/HttpClient";
import {DictionaryInitInterface} from "../interfaces/api/DictionaryInitInterface";
import {ShopInterface} from "../interfaces/shop/ShopInterface";
import PageMetaInterface from "../interfaces/core/PageMetaInterface";
import BreadcrumbInterface from "../interfaces/core/BreadcrumbInterface";

export function loadInit(): Promise<DictionaryInitInterface> {
    return fetchData('/api/app-init')
}

export function getShops(): Promise<{shops: ShopInterface[], meta: PageMetaInterface, breadcrumbs: BreadcrumbInterface[]}> {
    return fetchData('/api/shops')
}