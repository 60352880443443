import {Button, Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import Money from "../../core/Money";
import DatePicker from 'react-datepicker';
import {registerLocale} from "react-datepicker";
import {ru} from 'date-fns/locale/ru';
import AgreementLabel from "./../AgreementLabel";
import isEmail from "validator/es/lib/isEmail";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import moment from "moment/moment";
import {createSertificate} from "../../../api/gift-certificate";
import BalanceFormPopup from "./BalanceFormPopup";
import {PAYMENT_TYPE_CREDIT_CARD_ID, PAYMENT_TYPE_SBP_ID} from "../../../Config";
import usePaymentRedirect from "../usePaymentRedirect";
import {OrderInterface} from "../../../interfaces/customer/OrderInterface";

registerLocale('ru', ru)


export default function CreateGiftCertificateForm() {
    const nominals = [1000, 3000, 5000, 10000, 30000, 50000];
    const designs = [1, 2, 3];
    const timeZones = [
        {value: "2", label: "Калининградское время (МСК−1)"},
        {value: "3", label: "Московское время"},
        {value: "4", label: "Самарское время (МСК+1)"},
        {value: "5", label: "Екатеринбургское время (МСК+2)"},
        {value: "6", label: "Омское время (МСК+3)"},
        {value: "7", label: "Красноярское время (МСК+4)"},
        {value: "8", label: "Иркутское время (МСК+5)"},
        {value: "9", label: "Якутское время (МСК+6)"},
        {value: "10", label: "Владивостокское время (МСК+7)"},
        {value: "11", label: "Магаданское время (МСК+8)"},
        {value: "12", label: "Камчатское время (МСК+9)"},
    ];

    const possiblePayments = [
        {value: PAYMENT_TYPE_SBP_ID, label: "Система Быстрых Платежей"},
        {value: PAYMENT_TYPE_CREDIT_CARD_ID, label: "Оплата банковской картой"},
    ];

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {register, reset, handleSubmit, watch, setValue, formState: {errors}} = useForm();

    const [isAnonimous, setIsAnonimous] = useState(false)
    const [isSendImmediately, setIsSendImmediately] = useState(false);
    const [showBalance, setShowBalance] = useState(false);
    const [createdOrder, setCreatedOrder] = useState<OrderInterface|undefined>(undefined)

    // после создания заказа редиректим на оплату
    usePaymentRedirect(createdOrder, dispatch)

    const formSubmit = (formParams: any) => {
        formParams['sendDate'] = moment(formParams['sendDate']).format('YYYY-MM-DD HH:mm')
        formParams['customer'] = state.customer
        formParams['region'] = state.region
        createSertificate(formParams).then((response) => {
            if (response?.order) {
                setCreatedOrder(response?.order)
            }
        }, (error: any) => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'При создании сертификата: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }

    useEffect(() => {
        reset({
            nominal: 3000,
            payerFio: state.customer?.id ? (state.customer.last_name + ' ' + state.customer.first_name) : '',
            payerEmail: state.customer?.id ? state.customer.email : '',
            payerPhone: state.customer?.id ? state.customer.phone : '',
            fromName: state.customer?.id ? (state.customer.last_name + ' ' + state.customer.first_name) : '',
            congratulation: '',
            designId: 1,
            timeZone: 3,
            paymentId: PAYMENT_TYPE_SBP_ID,
            sendDate: new Date(),
        })
    }, []);

    return (<main className="main-lk">
        <section className="lk">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Электронный подарочный сертификат</h1>
                        <p>Идеальный подарок по поводу и без для близких, друзей или коллег!</p>
                        <p>Почувствуй нашу заботу: отправим сертификат на e-mail, а воспользоваться можно любым
                            удобным способом — на сайте или в магазине
                        </p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-3 mt-2">
                        <button className={'btn btn-secondary'} onClick={() => {setShowBalance(true)}}>ПРОВЕРИТЬ БАЛАНС</button>
                    </div>
                    <div className="col-md-9 mt-2">
                        <div>
                            <b>Легко купить:</b> укажи получателя, сумму и дизайн
                        </div>
                        <div className={'mt-3'}>
                            <b>Легко подарить:</b> отправим на e-mail
                        </div>
                        <div className={'mt-3'}>
                            <b>Легко воспользоваться в тот же день:</b>
                            <ul>
                                <li>на baon.ru или в собственных магазинах</li>
                                <li>можно использовать полностью или частично вместе с баллами лояльности</li>
                                <li>действует 3 года, чтобы точно успеть все купить!</li>
                            </ul>
                        </div>
                    </div>

                </div>

                {showBalance && <BalanceFormPopup handleClose={() => {setShowBalance(false)}}/>}

                <div className="row mt-3">
                    <div className="col-md-8">
                        <Form onSubmit={handleSubmit(formSubmit)}>

                            <h3>Кому подарить</h3>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Имя и фамилия</Form.Label>
                                <Col sm="9"><Form.Control type="text" placeholder="Имя и фамилия"
                                                          className={(errors.fio ? ' is-invalid' : '')} {...register("fio", {required: true})} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Телефон</Form.Label>
                                <Col sm="9"><Form.Control type="text" placeholder="Телефон"
                                                          className={(errors.phone ? ' is-invalid' : '')} {...register("phone", {
                                    required: true,
                                    validate: v => /^[+\d \-\(\)]{10,20}$/.test(v)
                                })} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">E-mail</Form.Label>
                                <Col sm="9"><Form.Control type="text" placeholder="E-mail"
                                                          className={(errors.email ? ' is-invalid' : '')} {...register("email", {
                                    required: true,
                                    validate: {checkEmail: v => isEmail(v)}
                                })} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Номинал сертификата</Form.Label>
                                <Col sm="9">
                                    <div className="row">
                                        {nominals.map(nom => <div className="col-md-4 my-3" key={nom}>
                                            <input type="radio" name={'nominal'}
                                                   checked={nom === parseInt(watch('nominal'))}
                                                   onChange={() => {
                                                       setValue('nominal', nom)
                                                   }}/> <Money value={{amount: nom * 100, currency: {name: 'RUB'}}}/>
                                        </div>)}
                                    </div>
                                    <input type={'hidden'} {...register("nominal", {required: true})}/>

                                    {/*<Form.Control type="text" placeholder="Номинал сертификата"*/}
                                    {/*              {...register("nominal", {required: true, validate: v => /^[+\d]{1,5}$/.test(v)})}*/}
                                    {/*              className={(errors.nominal ? ' is-invalid' : '')} />*/}
                                </Col>
                            </Form.Group>

                            <div className={'row my-5'}>
                                <div className="col-md-3">
                                    Дизайн сертификата
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        {designs.map(desId => <div className={"col-md-4 p-2 pointer " + (desId === watch('designId') ? 'border border-3' : '')} key={desId}
                                                                   onClick={() => {
                                                                       setValue('designId', desId);
                                                                   }}>
                                            <img className={'w-100'} src={'/img/gift-card-' + desId + '.png'} />
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Добавьте поздравление</Form.Label>
                                <Col sm="9"><Form.Control as="textarea" rows={5}
                                                          placeholder="Добавьте поздравление"
                                                          className={(errors.congratulation ? ' is-invalid' : '')} {...register("congratulation")} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">От кого</Form.Label>
                                <Col sm="9"><Form.Control type="text" placeholder="От кого" disabled={isAnonimous}
                                                          className={(errors.fromName ? ' is-invalid' : '')} {...register("fromName")} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3"></Form.Label>
                                <Col sm="9">
                                    <Form.Check // prettier-ignore
                                        type={'checkbox'}
                                        label={'Отправить сертификат анонимно'}
                                        className={'mt-2'}
                                        onClick={() => {
                                            setIsAnonimous(!isAnonimous)
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Дата и время отправления</Form.Label>
                                <Col sm="9">
                                    <Row>
                                        <Col sm="4">
                                            <DatePicker
                                                disabled={isSendImmediately}
                                                className={"form-control"}
                                                selected={watch('sendDate')}
                                                onChange={(date) => setValue('sendDate', date)}
                                                locale="ru"
                                                showTimeSelect
                                                timeInputLabel="Время:"
                                                timeFormat="HH:mm"
                                                timeIntervals={60}
                                                minDate={moment().toDate()}
                                                dateFormat="dd.MM.yyyy HH:mm"
                                            />
                                        </Col>
                                        <Col sm={8}>
                                            <Form.Select disabled={isSendImmediately} className={'form-control ' + (errors.timeZone ? ' is-invalid' : '')} {...register("timeZone")}>
                                                {timeZones.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Form.Check // prettier-ignore
                                        type={'checkbox'}
                                        label={'Отправить сертификат сразу'}
                                        className={'mt-2'}
                                        onChange={() => {
                                            setIsSendImmediately(!isSendImmediately)
                                        }}

                                    />
                                </Col>
                            </Form.Group>


                            <h3>Контактные данные плательщика</h3>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Имя и фамилия</Form.Label>
                                <Col sm="9"><Form.Control type="text" placeholder="Имя и фамилия"
                                                          className={(errors.payerFio ? ' is-invalid' : '')} {...register("payerFio", {required: true})} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Телефон</Form.Label>
                                <Col sm="9"><Form.Control type="text" placeholder="Телефон"
                                                          className={(errors.payerPhone ? ' is-invalid' : '')} {...register("payerPhone", {
                                    required: true,
                                    validate: v => /^[+\d \-\(\)]{10,20}$/.test(v)
                                })} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">E-mail</Form.Label>
                                <Col sm="9"><Form.Control type="text" placeholder="E-mail"
                                                          className={(errors.payerEmail ? ' is-invalid' : '')} {...register("payerEmail", {
                                    required: true,
                                    validate: {checkEmail: v => isEmail(v)}
                                })} /></Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Оплата</Form.Label>
                                <Col sm="9">
                                    <Form.Select className={'form-control ' + (errors.paymentId ? ' is-invalid' : '')} {...register("paymentId")}>
                                        {possiblePayments.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mt-3">
                                <Form.Label column sm="3">Оплата</Form.Label>
                                <Col sm="9">
                                    <Button variant="secondary" type={"submit"}>Оформить заказ</Button>
                                    <div className="basket__order-annotation">
                                        <p>
                                            <AgreementLabel/>
                                        </p>
                                    </div>
                                </Col>
                            </Form.Group>

                        </Form>
                    </div>
                </div>
            </div>
        </section>
    </main>)


}