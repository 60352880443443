import Total from "./Total";
import Bonus from "./Bonus";
import Basket from "./Basket";
import {createOrder, getAutoApplyPromocodes, getRegionByIp, loadBasket} from "../../api/checkout";
import React, {useContext, useEffect, useRef, useState} from "react";
import {getRequestBasketParams, getRequestCreateOrderParams, tracking} from "./utils/Context";
import CountryCity from "./CountryCity";
import DeliveryList from "./DeliveryList";
import DeliveryDateTime from "./DeliveryDateTime";
import AddressForm from "./AddressForm";
import PaymentList from "./PaymentList";
import PersonalData from "./PersonalData";
import OrderSuccess from "./OrderSuccess";
import isEmail from "validator/es/lib/isEmail";
import NoDeliveriesAndPayments from "./NoDeliveriesAndPayments";
import CommentForm from "./CommentForm";
import SabManagers from "./SabManagers";
import AuthBlockButton from "./AuthBlockButton";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import Promocodes from "./Promocodes";
import {CountryInterface} from "../../interfaces/core/CountryInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {CheckoutResponseInterface} from "../../interfaces/checkout/CheckoutResponseInterface";
import {IntervalDateInterface} from "../../interfaces/checkout/IntervalDateInterface";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import {EcommerceAction} from "../../utils/useDataLayer";
import useDatalayer from "../../utils/useDataLayer";

import {Link} from "react-router-dom";
import {SplitDeliveryInterface} from "../../interfaces/checkout/SplitDeliveryInterface";
import {BRAND_STORE_DELIVERY_ID, INTERNET_SHOP_ID} from "../../Config";
import {BasketPartConfigInterface} from "../../interfaces/checkout/BasketPartConfigInterface";
import ApplyGiftCertificateForm from "./gift-certificate/ApplyGiftCertificateForm";
import MoneyInterface from "../../interfaces/core/MoneyInterface";
import {deliveryCostValueHelper, totalHelper} from "./useTotal";
import CurrencyInterface from "../../interfaces/core/CurrencyInterface";
import {CreateOrderResponse} from "../../interfaces/checkout/CreateOrderResponse";

const BACK_REQUEST_DELAY = 1; // делаем запрос не чаще чем раз в BACK_REQUEST_DELAY секунд

const DEFAULT_COUNTRY_CODE = 'ru';

export default function Checkout() {
    let execute = useRef(0)
    let currentState = useRef({})
    let createdOrders = useRef([])

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [basketPartConfigs, setBasketPartConfigs]  = useState<BasketPartConfigInterface[]>([])

    const [deliveries, setDeliveries] = useState<SplitDeliveryInterface[]>([]);
    const [countries, setCountries] = useState<CountryInterface[]>([]);
    const [courierDates, setCourierDates] = useState<IntervalDateInterface[]>([]);
    const [courierIntervals, setCourierIntervals] = useState<string[]>([]);
    const [activeBonusBalance, setActiveBonusBalance] = useState(0)
    const [accuralSum, setAccuralSum] = useState(0)
    const [activeBonusBalanceFromResponse, setActiveBonusBalanceFromResponse] = useState(0)

    const trackingState = tracking(state, basketPartConfigs)

    const {action: dataLayerManagerAction, transaction: dataLayerManagerTransaction} = useDatalayer()

    useEffect(() => {
        if (state.isFastOrder) return

        getAutoApplyPromocodes().then(promocodeResponse => {
            if (promocodeResponse.promocodes?.length) {
                dispatch({
                    type: ReducerActionType.SET_PARAM,
                    paramName: 'promocodes',
                    payload: promocodeResponse.promocodes
                })
            }
        })
        dataLayerManagerAction(EcommerceAction.VIEW_CART, state.basket.map(basketItem => basketItem.modelColorItem))
        dataLayerManagerAction(EcommerceAction.BEGIN_CHECKOUT, state.basket.map(basketItem => basketItem.modelColorItem))
    }, [])

    /**
     * Запрос делаем раз в BACK_REQUEST_DELAY секунд, чтоы не перегружать бек
     */
    useEffect(() => {
        // console.log('Tracking state -> ', trackingState)
        currentState.current = state
        window.clearTimeout(execute.current);
        execute.current = window.setTimeout(() => {
            load(getRequestBasketParams(state, basketPartConfigs));
        }, (state.isInitialized ? BACK_REQUEST_DELAY : 0) * 1000);
        return () => {
            window.clearTimeout(execute.current)
        }

        // eslint-disable-next-line
    }, [trackingState])

    useEffect(() => {
        if(basketPartConfigs[0]?.delivery?.delivery_type?.id){
            dataLayerManagerTransaction(EcommerceAction.ADD_SHIPPING_INFO, state.positions, {"shipping_tier" : basketPartConfigs[0]?.delivery?.delivery_type?.name})
        }
        if(basketPartConfigs.filter(part => part.delivery?.delivery_type?.id === BRAND_STORE_DELIVERY_ID).length > 0){
            setActiveBonusBalance(0)
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'writeOffBonuses', payload: 0})
        }else{
            setActiveBonusBalance(activeBonusBalanceFromResponse)
        }
    }, [basketPartConfigs]);

    useEffect(() => {
        if(state.payment){
            dataLayerManagerTransaction(EcommerceAction.ADD_SHIPPING_INFO, state.positions, {"payment_type" : state.payment.name})
        }
    }, [state.payment]);


    /**
     * Загрузка с бека
     * @param params
     */
    const load = async (params: any) => {
        dispatch({type: ReducerActionType.LOAD})
        if (params.positions.length === 0) {
            // dispatch({type: ReducerActionType.ERROR, payload: 'В вашей корзине нет позиций'})
            dispatch({type: ReducerActionType.SUCCESS})
            return
        }
        if (state.country?.id === 'ru' && !state.region?.id) {
            await getRegionByIp().then(region => {
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'region', payload: region})
                params.region = region
            })
        }

        // console.log('Basket Params -> ', params)
        loadBasket(params).then((response: CheckoutResponseInterface) => {
            // @ts-ignore
            if (response.isSkip || response.timestamp < currentState.current.lastExecutionTime) {
                // console.log('Skip by timestampp')
                dispatch({type: ReducerActionType.SUCCESS})
                return
            }

            // решение проблемы обновления состояния в момент загрузки с бека
            const responsePositionUid = response.order.positions.map(position => position.item.id + '|' + position.quantity).join(',') + ',' + (response.order.country?.id || 'nocountry') + ',' + (response.order.region?.id || 'noregion') + ',' + response.order.promocodes.map(promocode => promocode.code).join(',').toUpperCase()
            const statePositionUid = state.basket.map(position => position.itemId + '|' + position.quantity).join(',') + ',' + (state.country?.id || 'nocountry') + ',' + (((state.country?.id === 'ru' || state.country?.id === 'kz') && state.region?.id) || 'noregion') + ',' + state.promocodes.join(',').toUpperCase()

            if (responsePositionUid !== statePositionUid) {
                // console.log('Skip by position UID: ', responsePositionUid, ' <=> ', statePositionUid)
                // console.log('State region -> ', state.region, ((state.country?.id === DEFAULT_COUNTRY_CODE && state.region?.id) || 'noregion'))
                dispatch({type: ReducerActionType.SUCCESS})
                return
            }

            setDeliveries(response.deliveries_and_payments)
            setBasketPartConfigs(response.deliveries_and_payments.map(item => {
                const currentConfig = basketPartConfigs.find(basketPartConfig => basketPartConfig.divisionIndex === item.index)

                let delivery = item.deliveries.find(delivery => currentConfig?.delivery?.delivery_type.id === delivery.delivery_type.id)

                let payment = delivery?.payments.find(payment => currentConfig?.payment?.id === payment.id)

                return {
                    divisionIndex: item.index,
                    delivery: delivery || null,
                    payment: payment || null,
                    location: delivery ? (currentConfig?.location || null) : null,
                    address: delivery ?  (currentConfig?.address || null) : null,
                    courierDate: delivery ?  (currentConfig?.courierDate || null) : null,
                    courierInterval: delivery ?  (currentConfig?.courierInterval || null) : null
                }
            }))


            setCountries(response.countries)
            if (!state.country?.id) {
                const responseCountries: CountryInterface[] = response.countries;
                dispatch({
                    type: ReducerActionType.SET_PARAM,
                    paramName: 'country',
                    payload: responseCountries.find(country => country.id = DEFAULT_COUNTRY_CODE)
                })
            }
            setCourierDates(response.courier.dates)
            setCourierIntervals(response.courier.intervals)
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'region', payload: response.order.region})
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'customer', payload: response.order.customer})

            const deliveriesAndPayments: SplitDeliveryInterface[] = response.deliveries_and_payments;
            let possibleDeliveriesId: string[] = []

            deliveriesAndPayments.map(item => {
                // если новый список доставок не содержит текущую доставку - сбрасываем на первую
                item.deliveries.map(item => possibleDeliveriesId.push(item.delivery_type.id))
            })

            // определяем есть все типы оплат в доставке
            let paymentsFromResponseId: string[] = [];

            deliveriesAndPayments.map(item => {
                // если новый список доставок не содержит текущую доставку - сбрасываем на первую
                item.deliveries.map(item => item.payments.map(pitem => {
                    paymentsFromResponseId.push(pitem.id)
                    return pitem
                }))
            })

            basketPartConfigs.map(partConfig => {
                const currentResponseDeliveryPart = response.deliveries_and_payments.find(deliveriesPart => deliveriesPart.index === partConfig.divisionIndex)
                // если в ответе не нашли текущей доставки  - сбрасываем и доставку и тип оплаты
                if(!currentResponseDeliveryPart?.deliveries?.find(delivery => delivery.delivery_type.id === partConfig.delivery?.delivery_type.id)){
                    console.log('Reset Delivery', partConfig.divisionIndex)
                    setBasketPartConfigs([...basketPartConfigs.filter(item => item.divisionIndex !== partConfig.divisionIndex), {...partConfig, delivery: null, payment: null}])
                }
                // если в ответе не нашли текущей оплаты - сбрасываем  оплату
                if(!currentResponseDeliveryPart?.deliveries?.find(delivery => delivery.payments.find(payment => payment.id === partConfig?.payment?.id))){
                    console.log('Reset Payment', partConfig.divisionIndex, currentResponseDeliveryPart?.deliveries, partConfig?.payment?.id)
                    setBasketPartConfigs([...basketPartConfigs.filter(item => item.divisionIndex !== partConfig.divisionIndex), {...partConfig, payment: null}])
                }
            })

            dispatch({type: ReducerActionType.SET_POSITIONS, payload: response.order.positions})

            setActiveBonusBalance(response.order.loyalty_processing_result?.active_balance)
            setActiveBonusBalanceFromResponse(response.order.loyalty_processing_result?.active_balance)
            // setActiveBonusBalance(20000)
            // setActiveBonusBalanceFromResponse(20000)

            dispatch({type: ReducerActionType.SUCCESS})
        }).catch(error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'При обновлении корзины возникла ошибка: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
            console.log('Error', error)
        })
    }

    const dateTimeBlockAllow = (divisionIndex:string) => {
        return state.positions.filter(position => position.division_index === divisionIndex).filter((position) => position.item.from_retail_store_only || !position.model.is_own_brand).length === 0
    }

    const create = () => {
        dispatch({type: ReducerActionType.ERROR, payload: ''})

        if (state.hasSabManagers && !state.sabManager) {
            dispatch({type: ReducerActionType.ERROR, payload: 'Не указан менеджер, оформляющий заказ'})
            return
        }
        if (!state.positions.length) {
            dispatch({type: ReducerActionType.ERROR, payload: 'Не указаны позиции для заказа'})
            return
        }

        let isError = false

        basketPartConfigs.map(basketPartConfig => {
            if (!basketPartConfig.delivery) {
                dispatch({type: ReducerActionType.ERROR, payload: 'Не указан тип доставки'})
                return
            }
            if (basketPartConfig.delivery?.locations?.length > 0 && !basketPartConfig.location) {
                dispatch({type: ReducerActionType.ERROR, payload: 'Не указан пункт доставки / магазин'})
                return
            }
            if (basketPartConfig.delivery?.address_block_required && (!basketPartConfig.address?.street)) {
                dispatch({type: ReducerActionType.ERROR, payload: 'Не указана улица'})
                return
            }
            if (basketPartConfig.delivery?.address_block_required && (!basketPartConfig.address?.home)) {
                dispatch({type: ReducerActionType.ERROR, payload: 'Не указана дом'})
                return
            }
            if (!basketPartConfig.payment) {
                dispatch({type: ReducerActionType.ERROR, payload: 'Не указан тип оплаты'})
                return
            }
        })

        if(isError){
            return
        }

        if (!state.customer) {
            dispatch({type: ReducerActionType.ERROR, payload: 'Для оформления заказа вы должны быть авторизованы'})
            return
        }

        if (!state.personalData.first_name || !state.personalData.last_name || !state.personalData.phone || !state.personalData.email) {
            dispatch({type: ReducerActionType.ERROR, payload: 'Контактные данные заполнены не полностью'})
            return
        }

        if (!isEmail(state.personalData.email)) {
            dispatch({type: ReducerActionType.ERROR, payload: 'Email адрес указан неверно'})
            return
        }

        if (!/^[+\d]{10,20}$/.test(state.personalData.phone)) {
            dispatch({type: ReducerActionType.ERROR, payload: 'Телефон указан неверно ' + state.personalData.phone})
            return
        }

        dispatch({type: ReducerActionType.LOAD})

        // @ts-ignore
        const uniqueDivisionIndex = [...new Set(state.positions.map(position => position.division_index))]

        let writeOffBonuses = state.writeOffBonuses
        let writeOffBonusesCurrentOrder = 0

        uniqueDivisionIndex.forEach(divisionIndex => {
            const currentBasketConfigPart = basketPartConfigs.find(item => item.divisionIndex === divisionIndex)

            if(!currentBasketConfigPart || !currentBasketConfigPart.delivery || !currentBasketConfigPart.payment) return

            let requestParams = getRequestCreateOrderParams(state, basketPartConfigs, divisionIndex);

            requestParams.address = currentBasketConfigPart.address;
            requestParams.courier_date = currentBasketConfigPart.courierDate;
            requestParams.courier_interval = currentBasketConfigPart.courierInterval;
            requestParams.delivery = currentBasketConfigPart.delivery;
            requestParams.payment = currentBasketConfigPart.payment;
            requestParams.location = currentBasketConfigPart.location
            requestParams.gift_certificates = state.giftCertificates

            writeOffBonusesCurrentOrder = requestParams.positions.map(position => {
                return position.loyalty_processing_calculation_result?.writeoff_bonus || 0
            }).reduce((accumulator: number, curr: number) => accumulator + curr)
            if (writeOffBonusesCurrentOrder <= writeOffBonuses) {
                requestParams.payed_by_bonus = writeOffBonusesCurrentOrder
                writeOffBonuses -= writeOffBonusesCurrentOrder
            } else {
                requestParams.payed_by_bonus = writeOffBonuses
                writeOffBonuses = 0
            }

            createOrder(requestParams).then((response: CreateOrderResponse) => {
                // @ts-ignore
                createdOrders.current = [...createdOrders.current, response.order]
                dispatch({type: ReducerActionType.SUCCESS})
                dispatch({type: ReducerActionType.SET_BASKET, payload: []})
                setAccuralSum(accuralSum + response.accural_sum)
            }).catch(error => {
                console.error(error)
                dispatch({
                    type: ReducerActionType.ERROR,
                    payload: 'При создании заказа возникла ошибка: ' + (error.response?.data?.message || error.response?.data?.detail)
                })
            })
        })
    }

    const setBasketPartConfig = (basketPartConfig: BasketPartConfigInterface): void => {
        setBasketPartConfigs([...basketPartConfigs.filter(item => item.divisionIndex !== basketPartConfig.divisionIndex), basketPartConfig])
    }

    const basketQuantity = () => {
        return state.basket.reduce((sum: number, current: BasketItemInterface) => {
            return sum + current.quantity
        }, 0)
    }

    const getPositionsByDivisionIndex = (divisionIndex:string) => {
        return state.basket.map(position => {
            const responseItem = state.positions.find(positionResponse => positionResponse.item.id === position.itemId)

            if(responseItem){
                position.modelColorItem.min_price = responseItem.current_price
                position.modelColorItem.max_price = responseItem.base_price
                position.isSpecialDelivery = responseItem.item.from_retail_store_only || !responseItem.model.is_own_brand
                position.divisionIndex = responseItem.division_index
                position.currentStock = responseItem.item.stocks.find(stock => stock.shop_id === INTERNET_SHOP_ID)?.quantity || 0
                if(position.quantity > position.currentStock){
                    position.quantity = position.currentStock
                }
            }


            return position
        }).filter(position => position.divisionIndex === divisionIndex)
    }

    const getOrderCurrency = (): CurrencyInterface => {
        return state.basket[0]?.modelColorItem.min_price.currency || {'name' : 'RUB'}
    }

    const getDeliveryCost = (): MoneyInterface => {
        let deliveryCost = 0
        basketPartConfigs.map(basketPartConfig => {
            deliveryCost += deliveryCostValueHelper(basketPartConfig.location, basketPartConfig.delivery)
        })
        return {amount: deliveryCost, currency: getOrderCurrency()}
    }

    const getPayedByGiftCertificates = (): MoneyInterface => {
        let amount = 0
        state.giftCertificates.map(giftCertificate => {
            amount += giftCertificate.active_nominal.amount
        })

        return {
            amount: amount,
            currency: getOrderCurrency()
        }
    }

    const getTotal = (): MoneyInterface => {
        return totalHelper(state.basket, state.writeOffBonuses, getPayedByGiftCertificates(), getDeliveryCost(), getOrderCurrency())
    }

    return (
        <>
            <main className="main-basket mt-5">
                <section className="basket">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <ul className="breadcrumb desk">
                                    <li className="breadcrumb-item active">
                                        <a href="">
                                            <i className="ico ico-home-g"/>
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">
                                        Корзина
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {createdOrders.current.length > 0 &&
                            <OrderSuccess orders={createdOrders.current} accuralSum={accuralSum} isFastOrder={state.isFastOrder}/>}

                        {createdOrders.current.length === 0 && state.basket.length === 0  &&
                            <div className="page-404__wrap">
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <h4 className="f-exp">К сожалению, корзина не содержит позиций</h4>
                                <p>Добавьте позиции и оформите заказ.</p>
                                <Link className="btn btn-secondary" to={"/womenwear/"}>В каталог</Link>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                            </div>}

                        {createdOrders.current.length === 0 && state.basket.length > 0 && <div>
                            <div className="row">
                                <div className="col">
                                    <div className="site-title__wrap">
                                        <h2 className="site-title f-exp">Корзина ({basketQuantity()})</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row basket__row">
                            <div className="col-lg-8 basket__main">
                                    {(!state.customer?.id) && <AuthBlockButton/>}
                                    {(state.customer?.id) && <PersonalData/>}
                                    {state.customer?.id && countries?.length > 0 && <CountryCity countries={countries}/>}

                                    {deliveries.map((item, index) => {

                                        const currentBasketPartConfig = basketPartConfigs.find(litem=>litem.divisionIndex === item.index)
                                        return <React.Fragment key={index} >
                                            {basketPartConfigs.length > 1 && <h3>Отправление {index+1}</h3>}
                                            <Basket positions={getPositionsByDivisionIndex(item.index)}/>
                                            {item.deliveries?.length === 0  && state.isInitialized && <NoDeliveriesAndPayments />}
                                            {currentBasketPartConfig && item.deliveries?.length > 0 && <DeliveryList deliveries={item.deliveries} basketPartConfig={currentBasketPartConfig} setBasketPartConfig={setBasketPartConfig}/>}
                                            {currentBasketPartConfig?.delivery?.address_block_required && item.deliveries?.length > 0 && <AddressForm basketPartConfig={currentBasketPartConfig} setBasketPartConfig={setBasketPartConfig}/>}
                                            {currentBasketPartConfig?.delivery?.date_time_block_required && item.deliveries?.length > 0 && courierDates?.length > 0 && dateTimeBlockAllow(item.index) && <DeliveryDateTime  dates={courierDates} intervals={courierIntervals}  basketPartConfig={currentBasketPartConfig} setBasketPartConfig={setBasketPartConfig}/>}
                                            {currentBasketPartConfig && item.deliveries?.length > 0 && <PaymentList basketPartConfig={currentBasketPartConfig} setBasketPartConfig={setBasketPartConfig}/>}
                                        </React.Fragment>
                                    })}
                                    {state.customer?.id && <>
                                        {state.isInitialized && <Bonus activeBalance={activeBonusBalance}/>}
                                        {state.isInitialized && <CommentForm/>}
                                    </>}
                                </div>
                                <div className="col-lg-4 basket__aside">
                                    {state.isInitialized && <Promocodes/>}
                                    {state.customer?.id && state.customer?.minda && <div className={'mb-3'}>
                                        {basketPartConfigs.length > 1 && <span className={'text-muted'}>Вы не можете применить подарочный сертификат если заказ содержит более одного отправления.</span>}
                                        {basketPartConfigs.length === 1 && <ApplyGiftCertificateForm total={getTotal()} />}
                                    </div>}
                                    <Total
                                        createOrder={create}
                                        basketPartConfigs={basketPartConfigs}
                                        total={getTotal()}
                                        deliveryCost={getDeliveryCost()}
                                        payedByGiftCertificates={getPayedByGiftCertificates()}
                                    />
                                    {state.isInitialized && <SabManagers/>}
                                </div>
                            </div>
                        </div>}

                    </div>
                </section>
            </main>
        </>

    )
}