import {useContext, useEffect, useState} from "react";
import Step1 from "./refund-form/Step1";
import Step2 from "./refund-form/Step2";
import Step3 from "./refund-form/Step3";
import Step4 from "./refund-form/Step4";
import RefundInterface, {RefundPositionInterface} from "../../interfaces/customer/RefundInterface";
import {useOutletContext, useParams} from "react-router-dom";
import {CustomerCredentialResponse} from "../../interfaces/api/CustomerCredentialResponseInterface";
import {OrderInterface} from "../../interfaces/customer/OrderInterface";
import {getModelColorItems} from "../../api/catalog";
import {uuidv4} from "../../utils/Utils";
import {create} from "../../api/refund";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";

export default function RefundForm() {
    const queryData = useParams<{ id?: string, partner?: string }>();

    const data = useOutletContext<CustomerCredentialResponse>();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext)

    const [order, setOrder] = useState<OrderInterface | undefined>(undefined)

    const [params, setParams] = useState<RefundInterface>({
        orderId: queryData.id || '',

        step: 1,
        isManual: true,
        isInit: false,

        lastName: '',
        firstName: '',
        patronymicName: '',

        passportSeries: '',
        passportNumber: '',
        passportIssued: '',

        address: '',
        phone: '',
        email: '',

        comment: '',

        positions: [],

        lastNameCard: '',
        firstNameCard: '',
        patronymicNameCard: '',

        bankName: '',
        correspondentAccount: '',
        inn: '',
        bic: '',
        personalAccount: '',
        cardNumber: '',

        partner: queryData.partner || ''
    });

    const createRefund = (refund: RefundInterface) => {
        dispatch({type: ReducerActionType.LOAD})
        create(refund, state.customer).then(response => {
            setParams({...refund, ...{step: 4, printKey: response.key, id: response.id}})
            dispatch({type: ReducerActionType.SUCCESS})
        }).catch(error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'В процессе создания возврата возникла ошибка: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }

    useEffect(() => {
        if (queryData.id) {
            const order = data.orders.find(order => order.id === parseInt(queryData.id || ''))

            if (order) {
                setOrder(order)

                const [f, i, o] = order.delivery_fio.split(' ')

                const modelsId = order.positions.map(position => position.model_info?.id);

                if (modelsId?.length) {
                    getModelColorItems({id: modelsId as string[], prevent_price_control: true, prevent_stock_control: true}).then(response => {
                        const positions: RefundPositionInterface[] = order.positions.map(position => {
                            const colorItem = response?.model_color_items.find(item => item.model_id === position.model_info?.id && item.color_special === position.color_special)
                            if (colorItem) {
                                return {
                                    price: position.price,
                                    quantity: position.quantity,
                                    reason: undefined,
                                    modelColor: colorItem,
                                    size: position.size,
                                    isManual: false,
                                    isSelected: true,
                                    uuid: uuidv4()
                                }
                            }
                        }).filter(position => position?.size) as RefundPositionInterface[]

                        setParams({
                            ...params, ...{
                                isInit: true,
                                isManual: false,
                                lastName: f,
                                firstName: i,
                                patronymicName: o || '',

                                address: order.delivery_address,
                                phone: order.delivery_phone,
                                email: order.delivery_email,

                                positions: positions,

                                lastNameCard: f,
                                firstNameCard: i,
                                patronymicNameCard: o || '',


                            }
                        })
                    })
                }


            }
        }
    }, [queryData.id]);


    return (<div className="lk__refund-petition">
        <h3 className="lk__title f-exp desk">Заявление на возврат</h3>
        {params.step === 1 && <Step1 order={order} params={params} setParams={setParams}/>}
        {params.step === 2 && <Step2 order={order} params={params} setParams={setParams}/>}
        {params.step === 3 && <Step3 params={params} setParams={setParams} createRefund={createRefund}/>}
        {params.step === 4 && <Step4 refund={params} />}


    </div>);
}