import {objclone} from "./Utils";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {BasketPositionRequestInterface} from "../../../interfaces/checkout/BasketPositionRequestInterface";
import {BasketRequestParamsBasketInterface} from "../../../interfaces/checkout/BasketRequestParamsBasketInterface";
import {
    BasketRequestParamsCreateOrderInterface
} from "../../../interfaces/checkout/BasketRequestParamsCreateOrderInterface";
import {BasketPartConfigInterface} from "../../../interfaces/checkout/BasketPartConfigInterface";

const getRequestBaseParams = (state: ReducerStateInterface, basketPartConfigs: BasketPartConfigInterface[]): any => {

    const mainBasketPartConfig = basketPartConfigs.find(basketPartConfig => basketPartConfig.divisionIndex === 'baon')
    const otherBasketPartConfig = basketPartConfigs.find(basketPartConfig => basketPartConfig.divisionIndex !== 'baon')

    let delivery = (mainBasketPartConfig || otherBasketPartConfig)?.delivery ? objclone((mainBasketPartConfig || otherBasketPartConfig)?.delivery) : null
    if ((mainBasketPartConfig || otherBasketPartConfig)?.delivery) {
        delivery.locations = null
    }


    return {
        timestamp: state.lastExecutionTime,
        uid: state.uid,
        // sab_request: state.isInitialized, // показывает что нужно сделать запрос на САБ
        // loyalty_request: state.isInitialized, // показывает что нужно сделать запрос в Manzana
        sab_request: true,
        loyalty_request: true,
        is_from_mobile: false,
        comment: state.comment,
        positions: [],
        region: state.country.select_city_allow ? state.region : null,
        country: state.country,
        customer: state.customer,
        personal_data: state.personalData,
        address: (mainBasketPartConfig || otherBasketPartConfig)?.address,
        courier_date: (mainBasketPartConfig || otherBasketPartConfig)?.courierDate,
        courier_interval: (mainBasketPartConfig || otherBasketPartConfig)?.courierInterval,
        delivery: delivery,
        payment: (mainBasketPartConfig || otherBasketPartConfig)?.payment,
        location: (mainBasketPartConfig || otherBasketPartConfig)?.location,
        promocodes: state.promocodes,
        payed_by_bonus: state.writeOffBonuses,
        is_fast_order: state.isFastOrder,
        sab_manager: state.sabManager
    };
}

/**
 * Все последующие параметры для запроса на бек
 */
export function getRequestBasketParams(state: ReducerStateInterface, basketPartConfigs: BasketPartConfigInterface[]): BasketRequestParamsBasketInterface {
    let params = getRequestBaseParams(state, basketPartConfigs)
    params.positions = state.basket.map(basketItem => {
        return {
            item: {
                id: basketItem.itemId,
                color: basketItem.color,
                size: basketItem.size,
            },
            model: {
                id: basketItem.modelColorItem.model_id
            },
            quantity: basketItem.quantity
        } as BasketPositionRequestInterface
    })

    return params
}


/**
 * Все последующие параметры для запроса на бек
 */
export function getRequestCreateOrderParams(state: ReducerStateInterface, basketPartConfigs: BasketPartConfigInterface[], divisionIndex: string): BasketRequestParamsCreateOrderInterface {
    let params = getRequestBaseParams(state, basketPartConfigs)

    params.positions = divisionIndex ? state.positions.filter(position => position.division_index == divisionIndex) : state.positions;

    return params
}


/**
 * Строка на основе которой принимается решение отправлять запрос на бек или нет для расчета скидок и балолов
 * @returns {string|null}
 */
export function tracking(state: ReducerStateInterface, basketPartConfigs: BasketPartConfigInterface[] ) {
    let positionTrackPart = []
    state.basket.forEach(position => {
        positionTrackPart.push(position.itemId + '-' + position.quantity)
    })
    positionTrackPart.push(state.country?.id || '');
    positionTrackPart.push(state.region?.id || '');
    positionTrackPart.push(state.customer?.id || '');
    positionTrackPart.sort();
    positionTrackPart.push(state.promocodes.sort().join('-'));
    positionTrackPart.push(state.codeword);
    positionTrackPart.push(state.payment?.id);
    basketPartConfigs.map(basketPartConfig => {
        positionTrackPart.push(basketPartConfig.payment?.id);
    })

    // positionTrackPart.push(state.writeOffBonuses);

    return positionTrackPart.join('-') + (state.positions.length > 0);
}
