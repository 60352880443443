import React, {useEffect, useState} from "react";
import Money from "../core/Money";
import LocationMap from "./LocationMap";
import {LocationInterface} from "../../interfaces/checkout/LocationInterface";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {BasketPartConfigInterface} from "../../interfaces/checkout/BasketPartConfigInterface";
import GeoLocationInterface from "../../interfaces/main/GeoLocationInterface";

const LOCATION_TYPE_SHOP = 'shop';
const LOCATION_TYPE_PICKUP = 'pickup';
const LOCATION_TYPE_POSTAMAT = 'postamat';

function Locations({basketPartConfig, setBasketPartConfig}: {
    basketPartConfig: BasketPartConfigInterface,
    setBasketPartConfig: (basketPartConfig: BasketPartConfigInterface) => void
}) {
    // const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [searchString, setSearchString] = useState('')
    // const [locationType, setLocationType] = useState('')
    const [isCardPayment, setIsCardPayment] = useState(false)
    const [hasFitting, setHasFitting] = useState(false)
    const [geoLocation, setGeoLocation] = useState<GeoLocationInterface | undefined>(undefined)


    useEffect(() => {
    }, [basketPartConfig.delivery?.delivery_type?.id])

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // console.log("Широта:", position.coords.latitude);
                    // console.log("Долгота:", position.coords.longitude);
                    // console.log("Точность (м):", position.coords.accuracy);
                    setGeoLocation({
                        longitude: position.coords.longitude,
                        latitude: position.coords.latitude,
                        accuracy: position.coords.accuracy
                    })
                },
                (error) => {
                    console.error("Ошибка получения геолокации:", error.message);
                }
            );
        } else {
            console.log("Геолокация не поддерживается вашим браузером");
        }
    }, []);

    const getLocations = (): LocationInterface[] => {
        if (!basketPartConfig.delivery?.locations) return []
        let filteredLocations: LocationInterface[] = basketPartConfig.delivery?.locations
        if (searchString) {
            const searchStringLowCase = searchString?.toLowerCase()
            filteredLocations = basketPartConfig.delivery?.locations?.filter((location: LocationInterface) => {
                return location.address?.toLowerCase().includes(searchStringLowCase)
            })
        }

        if (hasFitting) {
            filteredLocations = filteredLocations.filter(location => location.fitting)
        }
        if (isCardPayment) {
            filteredLocations = filteredLocations.filter(location => location.cashless_payment)
        }

        if (geoLocation) {
            const locationWithCoordinates = filteredLocations.filter((location: LocationInterface) => {
                return location.coordinates && location.coordinates?.length > 0;
            }).sort((a, b) => {
                const distanceA = Math.sqrt(Math.pow(geoLocation.latitude - a.coordinates[0], 2) + Math.pow(geoLocation.longitude - a.coordinates[1], 2))
                const distanceB = Math.sqrt(Math.pow(geoLocation.latitude - b.coordinates[0], 2) + Math.pow(geoLocation.longitude - b.coordinates[1], 2))
                return distanceA - distanceB
            })

            const locationWithoutCoordinates = filteredLocations.filter((location: LocationInterface) => {
                return !location.coordinates || !location.coordinates?.length;
            })

            return [...locationWithCoordinates, ...locationWithoutCoordinates]
        }


        return filteredLocations
    }

    const setLocation = (location: LocationInterface) => {
        setBasketPartConfig({...basketPartConfig, location: location, address: null})
    }

    return (<div className={'modal-map__wrap'}>
            <div className="modal-map__info">
                <h4 className="basket__form-title f-exp">Пункты выдачи ({getLocations().length})</h4>
                <form className="form--big" action="">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder={"Быстрый поиск по списку..."}
                               onChange={(e) => {
                                   setSearchString(e.target.value)
                               }}/>
                        <i className="ico ico-delete delete center"/>
                        <p className="error-text">
                            Не удалось определить точный адрес. Если метка стоит правильно, уточните
                            адрес вручную.
                        </p>
                    </div>
                    {/* <div className="form-radios">
                        <div className="form-radio">
                            <input
                                className="hidden"
                                type="radio"
                                defaultChecked={locationType == LOCATION_TYPE_SHOP}
                            />
                            <label className="label-dot" onClick={() => {
                                setLocationType(LOCATION_TYPE_SHOP)
                            }}/>
                            <label className="label-text">
                                Магазин Baon
                            </label>
                        </div>
                        <div className="form-radio">
                            <input
                                className="hidden"
                                type="radio"
                                defaultChecked={locationType == LOCATION_TYPE_PICKUP}
                            />
                            <label className="label-dot" onClick={() => {
                                setLocationType(LOCATION_TYPE_PICKUP)
                            }}/>
                            <label className="label-text">
                                Пункты выдачи
                            </label>
                        </div>
                        <div className="form-radio">
                            <input
                                className="hidden"
                                type="radio"
                                defaultChecked={locationType == LOCATION_TYPE_POSTAMAT}
                            />
                            <label className="label-dot" onClick={() => {
                                setLocationType(LOCATION_TYPE_POSTAMAT)
                            }}/>
                            <label className="label-text"
                            >
                                Постаматы
                            </label>
                        </div>
                    </div> */}
                    <div className="form-filters mt-2">
                        <div className="form-filter">
                            <input className="hidden" type="checkbox" defaultChecked={isCardPayment}/>
                            <label className="label-text" onClick={() => {
                                setIsCardPayment(!isCardPayment)
                            }}>
                                Оплата картой <i className="ico ico-right ico-delete"/>
                            </label>
                        </div>
                        <div className="form-filter">
                            <input className="hidden" type="checkbox" defaultChecked={hasFitting}/>
                            <label className="label-text" onClick={() => {
                                setHasFitting(!hasFitting)
                            }}>
                                С примеркой <i className="ico ico-right ico-delete"/>
                            </label>
                        </div>
                    </div>
                </form>

                <div className="modal-map__info-points">
                    <SimpleBar style={{maxHeight: 800}} autoHide={false}>
                        <div
                            className="scroll-wrapper scrollbar-inner"
                            style={{position: "relative"}}
                        >
                            <SimpleBar style={{maxHeight: 800}} autoHide={false}>
                                <div
                                    className="scrollbar-inner scroll-content scroll-scrolly_visible"
                                    style={{
                                        height: "auto",
                                        marginBottom: 0,
                                        marginRight: 0,
                                        maxHeight: "400px"
                                    }}
                                >
                                    <ul>
                                        {getLocations().map((location) => <li key={location.uid}><a href="/"
                                                                                                    onClick={(event) => {
                                                                                                        event.preventDefault()
                                                                                                        setLocation(location)
                                                                                                    }}>
                                            <h4 className="f-exp">
                                                {location.address}
                                            </h4>
                                            <p>{location.fitting ? 'есть примерка' : 'нет примерки'}, {location.price.amount ?
                                                <Money value={location.price}/> :
                                                <span> бесплатно</span>}</p>
                                            {/*{state.showTechInfo &&*/}
                                            {/*    <small className={'text-muted'}>, CourierID: {location?.courier_id}</small>}*/}
                                            <p>Ближайшая дата
                                                доставки: {location.period_from}{location.period_from < location.period_to &&
                                                    <span>-{location.period_to}</span>} дн.</p>
                                        </a>
                                        </li>)}
                                    </ul>
                                </div>
                            </SimpleBar>
                        </div>
                    </SimpleBar>
                </div>
            </div>

            <div className="modal-map__iframe">
                <LocationMap locations={getLocations()} setLocation={setLocation} geoLocation={geoLocation}/>
            </div>

        </div>
    )
}

export default Locations