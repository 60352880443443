import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {ReducerStateInterface} from "./interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "./GlobalReducer";
import {mergeBasket} from "./api/customer";
import debounce from "lodash/debounce";
import {BasketItemInterface} from "./interfaces/core/BasketItemInterface";

const SYNC_PERIOD = 3 * 1000;

export default function BasketServerSync() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const syncLastTimestamp = useRef<number>(0);

    const updateBasket = (basket: BasketItemInterface[]) => {
        if ((new Date().getTime()) - syncLastTimestamp.current < SYNC_PERIOD) {
            console.log('Skip By Timestamp', syncLastTimestamp.current);
            return;
        }

        if (state.customer?.id && state.customer?.sign && window.document.hasFocus()) {
            syncLastTimestamp.current = new Date().getTime();
            mergeBasket(state.customer.id, state.customer.sign, basket, syncLastTimestamp.current).then(basketResponse => {
                const currentBasketUid = basket.map(item => item.itemId + item.quantity).sort().join('|');
                const newBasketUid = basketResponse.basket?.map(item => item.itemId + item.quantity).sort().join('|');

                if (currentBasketUid !== newBasketUid) {
                    dispatch({
                        type: ReducerActionType.SET_BASKET,
                        payload: basketResponse.basket.filter(item => !!item.modelColorItem)
                    });
                }
            });
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            // Access the current state.basket directly instead of using the closed-over value
            // console.log('Quantity -> ', state.basket.map(item => item.quantity));
            updateBasket(state.basket);
        }, 10 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [state.basket]); // Add state.basket as a dependency

    useEffect(() => {
        if (state.customer?.id && state.customer?.sign) {
            updateBasket(state.basket);
        }
    }, [state.customer?.id, state.customer?.sign, state.basket]);

    // Properly handle the window focus event
    useEffect(() => {
        const handleFocus = () => {
            if (state.customer?.id && state.customer?.sign) {
                updateBasket(state.basket);
            }
        };

        window.addEventListener('focus', handleFocus);

        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [state.customer?.id, state.customer?.sign, state.basket]);

    return (<></>);
}