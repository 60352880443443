export default function PurchaseReturn() {
    return (<div className="tab-pane fade active show">
        <div className="lk__refund-main" style={{display: "none"}}>
            <h3 className="lk__title f-exp desk">Возвраты</h3>
            <div className="lk__refund">
                <div className="top">
                    <a className="btn btn-secondary-tr" href="">
                        Новый возврат
                    </a>
                </div>
                <div className="lk__orders column">
                    <div className="lk__order bd">
                        <div className="lk__order-head">
                            <p className="desk">Заказ от 24 июля 2023</p>
                            <h5 className="desk f-exp">125 500 Р</h5>
                        </div>
                        <div className="lk__order-number">
                            <h3 className="f-exp desk">№240040204204</h3>
                            <p className="number--payment ico-cont">
                                <i className="ico ico-left ico-check-circle"/>
                                Оплачено
                            </p>
                            <p className="number--delivery">Статус вовзрата</p>
                        </div>
                        <p className="lk__order-date">Доставка курьером: 2 августа</p>
                        <ul className="lk__order-status desk">
                            <li className="active">
                                <i className="active"/>
                                <p>Новый</p> <small>заказ</small>
                            </li>
                            <li className="active">
                                <i className="active"/>
                                <p>Принят</p> <small>в работу</small>
                            </li>
                            <li className="active">
                                <i className="active"/>
                                <p>Необходимо</p> <small>подтверждение</small>
                            </li>
                            <li className="active">
                                <i className="active"/>
                                <p>Подтверждён</p>
                            </li>
                            <li className="active">
                                <i className="active"/>
                                <p>Находится</p> <small>на комплектации</small>
                            </li>
                            <li className="active">
                                <i className="active"/>
                                <p>Готов</p> <small>к отправке</small>
                            </li>
                            <li className="active">
                                <i className="active"/>
                                <p>Передан</p> <small>в службу доставки</small>
                            </li>
                            <li>
                                <i className="active"/>
                                <p>Передан</p> <small>курьеру</small>
                            </li>
                            <li>
                                <i/>
                                <p>Доставлен</p>
                            </li>
                        </ul>
                        <div className="lk__order-info">
                            <div className="lk__order-info--wrap desk">
                                <div className="lk__order-data">
                                    <ul>
                                        <li>
                                            <p>ФИО</p>
                                            <p>Виктория Адетокунбова</p>
                                        </li>
                                        <li>
                                            <p>Телефон</p>
                                            <p>+7 (906) 124-08-24</p>
                                        </li>
                                        <li>
                                            <p>Адрес</p>
                                            <p>г. Москва, Дербеневская наб., д. 7, стр. 9</p>
                                        </li>
                                        <li>
                                            <p>E-mail</p>
                                            <p>victoria_antetokounmpova@yandex.ru</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="lk__order-photo">
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="lk__order-info-col"
                                style={{display: "none"}}
                            >
                                <div className="lk__order-data">
                                    <ul>
                                        <li>
                                            <p>Дата оформления</p>
                                            <p>24.07.2023</p>
                                        </li>
                                        <li>
                                            <p>Тип доставки</p>
                                            <p>Доставка курьером</p>
                                        </li>
                                        <li>
                                            <p>Тип оплаты</p>
                                            <p>Картой онлайн</p>
                                        </li>
                                        <li>
                                            <p>Комментарий</p>
                                            <p>
                                                Код от ворот при входе в арку 6424*, кнопка 4
                                                западает, лучше предварительно позвонить
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="lk__order-table ">
                                    <div className="head f-exp desk">
                                        <ul>
                                            <li>Товар</li>
                                            <li>Цена</li>
                                            <li>Количество</li>
                                            <li>Стоимость</li>
                                        </ul>
                                    </div>
                                    <div className="body">
                                        <ul>
                                            <li>
                                                <div className="product">
                                                    <div className="img">
                                                        <img
                                                            src="/img/temp/product-slide-1.jpg"
                                                            alt=""
                                                            width={78}
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        <p>
                                                            Базовый пуховик-кокон с меховой отделкой,
                                                            эффектом "шанжан", поясом, капюшоном и
                                                            большим
                                                            описанием{" "}
                                                        </p>
                                                        <small>
                                                            <span>Размер: M</span>
                                                            <span>Цвет: Black </span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="desk">
                                                    <p>20 916 Р</p>
                                                </div>
                                                <div className="desk">
                                                    <p>1</p>
                                                </div>
                                                <div className="cost">
                                                    <p>20 916 Р</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product">
                                                    <div className="img">
                                                        <img
                                                            src="/img/temp/product-slide-1.jpg"
                                                            alt=""
                                                            width={78}
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        <p>
                                                            Базовый пуховик-кокон с меховой отделкой,
                                                            эффектом "шанжан", поясом, капюшоном и
                                                            большим
                                                            описанием{" "}
                                                        </p>
                                                        <small>
                                                            <span>Размер: M</span>
                                                            <span>Цвет: Black </span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="desk">
                                                    <p>20 916 Р</p>
                                                </div>
                                                <div className="desk">
                                                    <p>1</p>
                                                </div>
                                                <div className="cost">
                                                    <p>20 916 Р</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product">
                                                    <div className="img">
                                                        <img
                                                            src="/img/temp/product-slide-1.jpg"
                                                            alt=""
                                                            width={78}
                                                        />
                                                    </div>
                                                    <div className="text">
                                                        <p>
                                                            Базовый пуховик-кокон с меховой отделкой,
                                                            эффектом "шанжан", поясом, капюшоном и
                                                            большим
                                                            описанием{" "}
                                                        </p>
                                                        <small>
                                                            <span>Размер: M</span>
                                                            <span>Цвет: Black </span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="desk">
                                                    <p>20 916 Р</p>
                                                </div>
                                                <div className="desk">
                                                    <p>1</p>
                                                </div>
                                                <div className="cost">
                                                    <p>20 916 Р</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="foot">
                                        <ul>
                                            <li>
                                                <p>Стоимость товаров</p>
                                                <p>125 500 Р</p>
                                            </li>
                                            <li>
                                                <p>Доставка</p>
                                                <p>1 000 000 Р</p>
                                            </li>
                                            <li>
                                                <p>
                                                    <b>Итого по заказу</b>
                                                </p>
                                                <p>
                                                    <b>1 125 500 Р</b>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <a className="lk__order-info-btn f-exp desk" href="">
                                <span>Полная информация</span>
                                <span>Свернуть</span>
                            </a>
                            <div className="lk__order-foot desk">
                                <a className="btn btn-secondary" href="">
                                    Отменить заказ
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="lk__refund-petition" style={{display: "block"}}>
            <h3 className="lk__title f-exp desk">Заявление на возврат</h3>
            <div className="lk__refund-petition--lk" style={{display: "none"}}>
                <div className="row">
                    <div className="col-lg-5">
                        <ul className="lk__order-status">
                            <li>
                                <i className="active"/>
                                <p>Личные данные</p>
                            </li>
                            <li>
                                <i/>
                                <p>Позиции на возврат</p>
                            </li>
                            <li>
                                <i/>
                                <p>Реквизиты для возврата</p>
                            </li>
                        </ul>
                        <form action="">
                            <div className="form-line">
                                <label>Номер заказа</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Номер заказа"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Имя</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Имя"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Фамилия</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Фамилия"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Отчество</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Отчество"
                                    />
                                </div>
                            </div>
                            <div className="form-col">
                                <label>Паспорт</label>
                                <div className="form-col-wrap">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Серия"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Номер"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Кем выдан"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Телефон</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>E-mail</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="E-mail"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label/>
                                <div className="form-group">
                                    <div className="form-btns btns-end">
                                        <a className="btn btn-secondary" href="">
                                            Далее
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <div className="bd information">
                            <p>
                                Возврат товара надлежащего качества* возможен в том случае,
                                если сохранены его товарный вид, потребительские свойства, а
                                также документы, подтверждающие факт и условия покупки
                                указанного товара (товарный или кассовый чек). Выплата
                                денежных средств осуществляется после того, как покупатель
                                предоставит продавцу товар, подтверждающий его приобретение
                                документ, заявление с указанием причины возврата и полные
                                реквизиты банковского счета.
                            </p>
                            <div
                                className="information__collapse"
                                style={{display: "none"}}
                            >
                                <p>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                    Voluptas quisquam natus, at ab, sequi dolorem repudiandae
                                    sapiente cum nostrum consectetur optio velit dolores?
                                    Doloremque, iusto eius porro nihil odit aspernatur.
                                </p>
                            </div>
                            <a className="information__link" href="">
                                Подробнее
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="lk__refund-petition--products"
                style={{display: "block"}}
            >
                <div className="row">
                    <div className="col-lg-5">
                        <ul className="lk__order-status">
                            <li className="active">
                                <i className="active"/>
                                <p>Личные данные</p>
                            </li>
                            <li>
                                <i className="active"/>
                                <p>Позиции на возврат</p>
                            </li>
                            <li>
                                <i/>
                                <p>Реквизиты для возврата</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="lk__order-table">
                    <div className="head f-exp desk">
                        <ul>
                            <li>Товар</li>
                            <li>Артикул</li>
                            <li>Причина возврата</li>
                            <li>Количество</li>
                            <li>Сумма к возврату</li>
                            <li/>
                        </ul>
                    </div>
                    <div className="body">
                        <ul>
                            <li>
                                <div className="product">
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                    <div className="text">
                                        <p>
                                            Базовый пуховик-кокон с меховой отделкой, эффектом
                                            "шанжан", поясом, капюшоном и большим описанием{" "}
                                        </p>
                                        <small>
                                            <span>Размер: M</span>
                                            <span>Цвет: Black </span>
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <p>B0323539</p>
                                </div>
                                <div>
                                    <div className="form-selector">
                                        <a className="form-selector--btn f-exp" href="">
                                            <span>Выберите причину возврата</span>
                                        </a>
                                        <div
                                            className="form-selector--list"
                                            style={{display: "none"}}
                                        >
                                            <ul>
                                                <li>
                                                    <a href="">Брак</a>
                                                </li>
                                                <li>
                                                    <a href="">Не подошел размер</a>
                                                </li>
                                                <li>
                                                    <a href="">Отличается от фотографии на сайте</a>
                                                </li>
                                                <li>
                                                    <a href="">Товар не подходит мне</a>
                                                </li>
                                                <li>
                                                    <a href="">Получил не тот товар, что заказывал</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p>1</p>
                                </div>
                                <div>
                                    <p>20 916 Р</p>
                                </div>
                                <div>
                                    <div className="form-checkbox">
                                        <input
                                            className="hidden"
                                            id="lk-refund-prod-01"
                                            type="checkbox"
                                        />
                                        <label
                                            className="label-dot"
                                            htmlFor="lk-refund-prod-01"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="product">
                                    <div className="img">
                                        <img
                                            src="/img/temp/product-slide-1.jpg"
                                            alt=""
                                            width={78}
                                        />
                                    </div>
                                    <div className="text">
                                        <p>
                                            Базовый пуховик-кокон с меховой отделкой, эффектом
                                            "шанжан", поясом, капюшоном и большим описанием{" "}
                                        </p>
                                        <small>
                                            <span>Размер: M</span>
                                            <span>Цвет: Black </span>
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <p>B0323539</p>
                                </div>
                                <div>
                                    <div className="form-selector">
                                        <a className="form-selector--btn f-exp" href="">
                                            <span>Выберите причину возврата</span>
                                        </a>
                                        <div
                                            className="form-selector--list"
                                            style={{display: "none"}}
                                        >
                                            <ul>
                                                <li>
                                                    <a href="">Брак</a>
                                                </li>
                                                <li>
                                                    <a href="">Не подошел размер</a>
                                                </li>
                                                <li>
                                                    <a href="">Отличается от фотографии на сайте</a>
                                                </li>
                                                <li>
                                                    <a href="">Товар не подходит мне</a>
                                                </li>
                                                <li>
                                                    <a href="">Получил не тот товар, что заказывал</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p>1</p>
                                </div>
                                <div>
                                    <p>20 916 Р</p>
                                </div>
                                <div>
                                    <div className="form-checkbox">
                                        <input
                                            className="hidden"
                                            id="lk-refund-prod-02"
                                            type="checkbox"
                                        />
                                        <label
                                            className="label-dot"
                                            htmlFor="lk-refund-prod-02"
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="foot f-exp">
                        <ul>
                            <li>
                                <p>
                                    <b>Итого к возврату</b>
                                </p>
                                <p>
                                    <b>1 125 500 Р</b>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <form action="">
                        <div className="form-line">
                            <label>Комментарий в возврату</label>
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Текст комментария"
                                />
                            </div>
                        </div>
                        <div className="form-btns">
                            <a className="btn btn-secondary-tr" href="">
                                Назад
                            </a>
                            <a className="btn btn-secondary" href="">
                                Далее
                            </a>
                        </div>
                    </form>
                </div>
            </div>
            <div
                className="lk__refund-petition--requisites"
                style={{display: "none"}}
            >
                <div className="row">
                    <div className="col-lg-5">
                        <ul className="lk__order-status">
                            <li className="active">
                                <i className="active"/>
                                <p>Личные данные</p>
                            </li>
                            <li>
                                <i className="active"/>
                                <p>Позиции на возврат</p>
                            </li>
                            <li>
                                <i/>
                                <p>Реквизиты для возврата</p>
                            </li>
                        </ul>
                        <form action="">
                            <div className="form-line">
                                <label>Фамилия</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Фамилия"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Имя</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Имя"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Отчество</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Отчество"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Наименование</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Название банка"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Корр. счет</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Корреспондентский счет"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>ИНН</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="ИНН банка"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>БИК</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="БИК банка"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Лицевой счет</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Лицевой счет карты"
                                    />
                                </div>
                            </div>
                            <div className="form-line">
                                <label>Номер карты</label>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="____ ____ ____ ____"
                                    />
                                </div>
                            </div>
                            <div className="form-btns">
                                <a className="btn btn-secondary-tr" href="">
                                    Назад
                                </a>
                                <a className="btn btn-secondary" href="">
                                    Далее
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}