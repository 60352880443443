import React, {useState, PropsWithChildren, createContext, useContext} from "react";

const defaultContextValue: any = null;
const DropDownElem = createContext(defaultContextValue);

export default function FooterDropdown({children}: PropsWithChildren<{}>) {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <DropDownElem.Provider value={{open, setOpen}}>
            <>{children}</>
        </DropDownElem.Provider>
    );
}

FooterDropdown.Header = function DropdownElemHeader({children}: PropsWithChildren<{}>) {
    const {open, setOpen} = useContext(DropDownElem);
    return (
        <div className="footer__menu-link-wrap"   onClick={() => setOpen((open: any) => !open)}>
            <h3 className="footer__menu-link f-exp">{children}</h3>
            <div
                className={"footer__menu-link-dropdown" + (open ? " show" : "")}
            ></div>
        </div>
    );
};
FooterDropdown.Content = function DropdownElemContent({children}: PropsWithChildren<{}>) {
    const {open} = React.useContext(DropDownElem);
    return open ? <>{children}</> : null;
};
