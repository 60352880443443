import ModelReviewInterface from "../../../interfaces/model/ModelReviewInterface";
import {useEffect, useState} from "react";

export default function ReviewEstimateGraph({reviews} : {reviews: ModelReviewInterface[]}) {
    const [reviewsStat, setReviewsStat] = useState<any>({})
    const [reviewsTotal, setReviewsTotal] = useState(0)

    useEffect(() => {
        let reviewsStat: any = {}
        let reviewsTotal = 0

        reviews.forEach(review => {
            if(review.estimate){
                //@ts-ignore
                if(!reviewsStat[review.estimate]){
                    reviewsStat[review.estimate] = 1
                }else{
                    reviewsStat[review.estimate]++
                }
                reviewsTotal++;

            }
        })
        setReviewsStat(reviewsStat)
        setReviewsTotal(reviewsTotal)
    }, [reviews]);

    return (<ul>
        <li><p>5 звезд</p><i className="line"><i style={{width: Math.round(reviewsStat[5] / reviewsTotal * 100) + '%'}}></i></i><p>{reviewsStat[5] || 0}</p></li>
        <li><p>4 звезды</p><i className="line"><i style={{width: Math.round(reviewsStat[4] / reviewsTotal * 100) + '%'}}></i></i><p>{reviewsStat[4] || 0}</p></li>
        <li><p>3 звезды</p><i className="line"><i style={{width: Math.round(reviewsStat[3] / reviewsTotal * 100) + '%'}}></i></i><p>{reviewsStat[3] || 0}</p></li>
        <li><p>2 звезды</p><i className="line"><i style={{width: Math.round(reviewsStat[2] / reviewsTotal * 100) + '%'}}></i></i><p>{reviewsStat[2] || 0}</p></li>
        <li><p>1 звезда</p><i className="line"><i style={{width: Math.round(reviewsStat[1] / reviewsTotal * 100) + '%'}}></i></i><p>{reviewsStat[1] || 0}</p></li>
    </ul>)
}