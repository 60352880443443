import {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {Link, useParams} from "react-router-dom";
import Moment from "react-moment";
import Breadcrumbs from "../Breadcrumbs";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {getBaseImagePath} from "../../catalog/Utils";
import {getNewsById} from "../../../api/news";

export default function NewsItem() {
    const params: any = useParams();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {data, status} = useQuery({
        queryKey: [...['news', 'item', params]],
        queryFn: () => getNewsById(params.id)
    })

    const {post, meta} = data || {}

    const [showShare, setShowShare] = useState(false);

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: meta})
    }, [meta]);

    return (<main className="main-blog">
        <section className="blog pt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Breadcrumbs breadcrumbs={data?.breadcrumbs || []}/>
                    </div>
                </div>
                <div className="row blog__wrap">
                    <div className="col-lg-6 blog__aside">
                        {/*<Link className="blog__aside-back ico-cont" to={'/index/blog/'}>*/}
                        {/*    <i className="ico ico-left ico-arrow-left-b"/>*/}
                        {/*    <span className="desk">Обратно к статьям</span>*/}
                        {/*</Link>*/}
                        <h3 className="f-exp">
                            {post?.title}
                        </h3>
                        <div className="meta">
                            <p><Moment format={'DD.MM.YYYY'}>{post?.create_date}</Moment></p>
                            <div className="information">
                                <a className="ico-cont information__link f-exp" href="/" onClick={(event) => {
                                    event.preventDefault();
                                    setShowShare(!showShare)
                                }}>
                                    <i className="ico ico-left ico-share-2"/>
                                    Поделиться
                                </a>
                                {showShare && <div className="information__collapse">
                                    <ul className="social">
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-ln"/> Скопировать ссылку
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-vk"/> ВКонтакте
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-tg"/> Telegram
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-wh"/> WhatsApp
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-ok"/> Одноклассники
                                            </a>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                        {/*<div className="blog__aside-banners desk">*/}
                        {/*    <div className="blog__aside-banner banner--1">*/}
                        {/*        <img src="/img/temp/blog/banner-aside.png" alt=""/>*/}
                        {/*        <div className="text">*/}
                        {/*            <h3 className="f-exp">- 25% на блузки</h3>*/}
                        {/*            <p>промокод shirt25</p>*/}
                        {/*            <a className="btn btn-primary" href="">*/}
                        {/*                Скопировать промокод*/}
                        {/*            </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="col-lg-6 blog__main">
                        <div className="blog__main-section">
                            <div className="imgs imgs-1">
                                <div className="img">
                                    <img src={getBaseImagePath() + post?.image} style={{height: 'auto'}} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="blog__main-section" id="blog__main-section-1_1">
                            <div dangerouslySetInnerHTML={{__html: post?.body || ''}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>)
}