import {useState} from "react";
import TagInterface from "../../interfaces/core/TagInterface";

export default function BottomTags({tags, selectedTags, setSelectedTags, singleMode}:{tags: TagInterface[], selectedTags: TagInterface[], setSelectedTags: Function, singleMode: boolean}) {
    const [show, setShow] = useState<boolean>(false)

    const getTags = () => {
        // @ts-ignore
        return show ? tags : tags.slice(0, typeof NOSCRIPT !== 'undefined' && NOSCRIPT ? 1000 : 10)
    }

    return (<>{tags.length > 0 && <div className="catalog__tags">
        <ul className="catalog__tags-list">
            {getTags().map(tag => <li key={tag.id}><a className={(selectedTags.filter(selectedTag => selectedTag.id === tag.id).length ? " active" : "")} href={tag.url} onClick={(event) => {
                event.preventDefault()
                if(singleMode) {
                    if(selectedTags.length && selectedTags[0].name === tag.name) {
                        setSelectedTags([])
                    }else{
                        setSelectedTags([tag])
                    }
                }else {
                    if(selectedTags.filter(selectedTag => selectedTag.id === tag.id).length) {
                        setSelectedTags(selectedTags.filter(selectedTag => selectedTag.id !== tag.id))
                    } else {
                        setSelectedTags([...selectedTags, ...[tag]])
                    }
                }
            }}>{tag.name}</a></li>)}
        </ul>
        <a className="information__link f-exp" href="/" onClick={(event) => {
            event.preventDefault()
            setShow(!show)
        }}>{show ? 'Свернуть' : 'Показать'}</a>
    </div>}</>)
}