import Spacer from '../Spacer/Spacer.jsx'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const Image = props => {
	return (
		<>
			<div className='mblock text-center '>
				<div className='w100 '>
					{props.params.url && props.params.url !== '' ? (
						<a href={props.params.url} >
							<picture>
								<source media="(max-width: 768px)" srcSet={getBaseImagePath() + props.params.imgmob} />
								<img src={getBaseImagePath() + props.params.img} alt={props.params.img} />
							</picture>
						</a>
					) : (
						<picture>
							<source media="(max-width: 768px)" srcSet={getBaseImagePath() + props.params.imgmob} />
							<img src={getBaseImagePath() + props.params.img} alt={props.params.img} />
						</picture>
					)}
				</div>
			</div>
			<Spacer />
		</>
	)
}

export default Image
