import Spacer from '../Spacer/Spacer.jsx'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const Video = props => {
	return (
		<>
			<div className='mblock text-center '>
				<div className='w100'>
					{props.params.isLink ? (
						<video className='landingVideo' src={getBaseImagePath() + props.params.videoLink} autoPlay
							playsInline
							loop
							muted
						>
						</video>
					) : (
						<>
							<video className='landingVideo videoDesc' src={getBaseImagePath() + props.params.url} autoPlay
								playsInline
								loop
								muted>
							</video>
							<video className='landingVideo videoMob' src={getBaseImagePath() + props.params.urlMob} autoPlay
								playsInline
								loop
								muted
							>
							</video>
						</>
					)}
				</div>
			</div>
			<Spacer />
		</>
	)
}

export default Video
