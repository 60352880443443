import {mailRuCounterId} from "../../../../Config";

export function vkGoal(actionName) {
    if (window?.VK?.Goal) {
        window.VK.Goal(actionName)
    } else {
        console.error('Метод window.VK.Goal не обнаружен')
    }
}

export function mailRuGoal(actionName) {
    window._tmr = window._tmr || [];
    window._tmr.push({id: mailRuCounterId, type: 'reachGoal', goal: actionName});
}

export function ecommerceDataLayer(action, event, positions, stepId = null, option = null) {
    //
    // if(!positions[0] || !positions[0].base_price) {
    //     return
    // }
    //
    // window.dataLayer = window.dataLayer || [];
    //
    // let ecomParams = {
    //     ecommerce: {},
    //     event: event
    // }
    //
    // ecomParams["ecommerce"][action] = {}
    //
    // if (stepId !== null) {
    //     ecomParams["ecommerce"][action]["actionField"] = {
    //         'step': stepId,
    //         'option': option
    //     }
    // }
    //
    // ecomParams["ecommerce"][action]["products"] = positions.map(position => {
    //     return {
    //         id: position.model.yml_id, // артикул
    //         name: position.model.name + ' ' + position.model.articul,  // название
    //         brand: position.model.brand,
    //         category: "Одежда / " + position.model.category.name,
    //         price: position.current_price.amount / 100, // цена
    //         variant: position.item.color + "/" + position.item.size,
    //         quantity: position.quantity // количество
    //     }
    // })
    //
    // window.dataLayer.push(ecomParams)
    //
    // console.log('DataLayer:', window.dataLayer);
}

export function ecommerceDataLayerGA4(event, positions) {
    // if(!positions[0] || !positions[0].base_price) {
    //     return
    // }
    //
    // window.dataLayer = window.dataLayer || [];
    //
    // window.dataLayer.push({
    //     event: event,
    //     ecommerce: {
    //         items: positions.map(position => {
    //             return {
    //                 item_name: position.model.articul + ' ' + position.model.name, // Name or ID is required.
    //                 item_id: position.model.yml_id,
    //                 price: position.current_price.amount / 100,
    //                 item_brand: position.model.brand,
    //                 item_category: "Apparel",
    //                 item_category2: "",
    //                 item_category3: position.model.category.name,
    //                 item_category4: "",
    //                 item_variant: position.item.color + "/" + position.item.size,
    //                 quantity: position.quantity
    //             }
    //         })
    //     }
    // })
    //
    // console.log('DataLayer GA4:', window.dataLayer);
}