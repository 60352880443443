import {OrderInterface} from "../../../interfaces/customer/OrderInterface";
import Moment from "react-moment";
import Money from "../../core/Money";
import {useState} from "react";
import {getBaseImagePath} from "../../catalog/Utils";
import {Link} from "react-router-dom";

export default function Order({order}: {order: OrderInterface}) {
    const STATUS_COMPLETED = 'aa-aa-000017'

    const [isShowMore, setIsShowMore] = useState(false)

    const getUniqueImageUrl = () => {
        return order.positions.map(position => position.image?.url).filter((value, index, array) => {
            return array.indexOf(value) === index;
        })
    }

    const showRefund = () => {
        return order.history.filter(status => status.status_id === STATUS_COMPLETED).length > 0
    }

    return (<>
            <div className="lk__order bd">
                <div className="lk__order-head">
                    <h3 className="f-exp mob">№{order.id}</h3>
                    <p className="desk">Заказ от {order.create_date_formated}</p>
                    <h5 className="desk f-exp"><Money value={order.total_final}/></h5>
                </div>
                <div className="lk__order-photo mob">
                    <div className="img">
                        {getUniqueImageUrl().slice(0,1).map((url, index) => <div key={index} className="img">
                            <img src={getBaseImagePath() + url} alt="" width={78}/>
                        </div>)}
                    </div>
                </div>
                <div className="lk__order-number">
                    <h3 className="f-exp desk">№{order.id}</h3>
                    <p className="number--date mob">Заказ от {order.create_date_formated}</p>
                    <p className="number--payment ico-cont">
                        <i className="ico ico-left ico-check-circle"/>
                        {order.status.name}
                    </p>
                    {/*<p className="number--delivery"></p>*/}
                    <p className={'text-white'}>.</p>
                </div>
                {/*<p className="lk__order-date">Доставка курьером: 2 августа</p>*/}
                <p className={'text-white'}>.</p>
                <ul className="lk__order-status desk">
                    {order.history.map((item, index) => <li key={index} className="active">
                        <i className="active"/>
                        <p>{item.status_name}</p>
                    </li>)}
                </ul>
                <div className="lk__order-info">
                    <a className="lk__order-info-btn f-exp mob top" href="/" onClick={(event) => {
                        event.preventDefault()
                        setIsShowMore(!isShowMore)
                    }}>
                        Полная информация
                    </a>
                    <div className="lk__order-info--wrap desk">
                        <div className="lk__order-data">
                            <ul>
                                <li>
                                    <p>ФИО</p>
                                    <p>{order.delivery_fio}</p>
                                </li>
                                <li>
                                    <p>Телефон</p>
                                    <p>{order.delivery_phone}</p>
                                </li>
                                <li>
                                    <p>Адрес</p>
                                    <p>{order.delivery_address}</p>
                                </li>
                                <li>
                                    <p>E-mail</p>
                                    <p>{order.delivery_email}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="lk__order-photo">
                            {getUniqueImageUrl().map((url, index) => <div key={index} className="img">
                                <img src={getBaseImagePath() + url} alt="" width={78}/>
                            </div>)}
                        </div>
                    </div>
                    {isShowMore && <div className="lk__order-info-col">
                        <ul className="lk__order-status mob">
                            {order.history.map((item, index) => <li key={index} className="active">
                                <i className="active"/>
                                <p>{item.status_name}</p>
                            </li>)}
                        </ul>
                        <div className="lk__order-data mob">
                            <ul>
                                <li>
                                    <p>ФИО</p>
                                    <p>{order.delivery_fio}</p>
                                </li>
                                <li>
                                    <p>Телефон</p>
                                    <p>{order.delivery_phone}</p>
                                </li>
                                <li>
                                    <p>Адрес</p>
                                    <p>{order.delivery_address}</p>
                                </li>
                                <li>
                                    <p>E-mail</p>
                                    <p>{order.delivery_email}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="lk__order-data">
                            <ul>
                                <li>
                                    <p>Дата оформления</p>
                                    <p>{order.create_date_formated}</p>
                                </li>
                                <li>
                                    <p>Тип доставки</p>
                                    <p>{order.delivery.name}</p>
                                </li>
                                <li>
                                    <p>Тип оплаты</p>
                                    <p>{order.payment.name}</p>
                                </li>
                                <li>
                                    <p>Комментарий</p>
                                    <p>{order.delivery_comment}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="lk__order-table ">
                            <div className="head f-exp desk">
                                <ul>
                                    <li>Товар</li>
                                    <li>Цена</li>
                                    <li>Количество</li>
                                    <li>Стоимость</li>
                                </ul>
                            </div>
                            <div className="body">
                                <ul>
                                    {order.positions.map(position => <li key={position.id}>
                                        <div className="product">
                                            <div className="img">
                                                <img
                                                    src={getBaseImagePath() + position.image?.url}
                                                    alt=""
                                                    width={78}
                                                />
                                            </div>
                                            <div className="text">
                                                <p>{position.model_info?.name}</p>
                                                <small>
                                                    <span>Размер: {position.size}</span>
                                                    <span>Цвет: {position.color}</span>
                                                </small>
                                            </div>
                                        </div>
                                        <div className="desk">
                                            <p><Money value={position.price}/></p>
                                        </div>
                                        <div className="desk">
                                            <p>{position.quantity}</p>
                                        </div>
                                        <div className="cost">
                                            <p><Money value={position.total}/></p>
                                        </div>
                                    </li>)}

                                </ul>
                            </div>
                            <div className="foot">
                                <ul>
                                    <li>
                                        <p>Стоимость товаров</p>
                                        <p><Money value={order.total}/></p>
                                    </li>
                                    {order.delivery_cost.amount > 0 && <li>
                                        <p>Доставка</p>
                                        <p><Money value={order.delivery_cost}/></p>
                                    </li>}
                                    {order.bonus?.amount > 0 && <li>
                                        <p>Оплачено бонусами</p>
                                        <p>- <Money value={order.bonus}/></p>
                                    </li>}
                                    <li>
                                        <p>
                                            <b>Итого по заказу</b>
                                        </p>
                                        <p>
                                            <b><Money value={order.total_final}/></b>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>}
                    {showRefund() && <div className="lk__order-foot">
                        <Link className={'btn btn-secondary'} to={'/personal-area/refund/' + order.id}>
                            Оформить возврат
                        </Link>
                    </div>}
                    <a className="lk__order-info-btn f-exp desk" href="/" onClick={(event) => {
                        event.preventDefault()
                        setIsShowMore(!isShowMore)
                    }}>
                        <span>{isShowMore ? 'Свернуть' : 'Полная информация'}</span>
                    </a>
                </div>
            </div>
        </>
    )
}