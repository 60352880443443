export default function Subscription() {
    return (<div
        className="tab-pane fade  show active"
        id="profile-data-sect-3"
        role="tabpanel"
        aria-labelledby="profile-data-tab-3"
    >
        <form action="">
            <div className="lk-subscribe__list">
                <div className="lk-subscribe__block bd">
                    <h4>О новинках</h4>
                    <h5>будь в тренде последних новинок</h5>
                    <div className="form-tumblers">
                        <div className="form-tumbler">
                            <input
                                className="hidden"
                                id="lk-profile-tubler-email-01"
                                type="checkbox"
                            />
                            <label
                                className="label-dot"
                                htmlFor="lk-profile-tubler-email-01"
                            >
                                <i/>
                            </label>
                            <label
                                className="label-text"
                                htmlFor="lk-profile-tubler-email-01"
                            >
                                E-mail
                            </label>
                        </div>
                        <div className="form-tumbler">
                            <input
                                className="hidden"
                                id="lk-profile-tubler-sms-01"
                                type="checkbox"
                            />
                            <label
                                className="label-dot"
                                htmlFor="lk-profile-tubler-sms-01"
                            >
                                <i/>
                            </label>
                            <label
                                className="label-text"
                                htmlFor="lk-profile-tubler-sms-01"
                            >
                                SMS
                            </label>
                        </div>
                    </div>
                </div>
                <div className="lk-subscribe__block bd">
                    <h4>Об акциях </h4>
                    <h5>узнай первым об акция и скидках до 70%</h5>
                    <div className="form-tumblers">
                        <div className="form-tumbler">
                            <input
                                className="hidden"
                                id="lk-profile-tubler-email-02"
                                type="checkbox"
                            />
                            <label
                                className="label-dot"
                                htmlFor="lk-profile-tubler-email-02"
                            >
                                <i/>
                            </label>
                            <label
                                className="label-text"
                                htmlFor="lk-profile-tubler-email-02"
                            >
                                E-mail
                            </label>
                        </div>
                        <div className="form-tumbler">
                            <input
                                className="hidden"
                                id="lk-profile-tubler-sms-02"
                                type="checkbox"
                            />
                            <label
                                className="label-dot"
                                htmlFor="lk-profile-tubler-sms-02"
                            >
                                <i/>
                            </label>
                            <label
                                className="label-text"
                                htmlFor="lk-profile-tubler-sms-02"
                            >
                                SMS
                            </label>
                        </div>
                    </div>
                </div>
                <div className="lk-subscribe__block bd">
                    <h4>О бонусах</h4>
                    <h5>начисление, списание и сгорание</h5>
                    <div className="form-tumblers">
                        <div className="form-tumbler">
                            <input
                                className="hidden"
                                id="lk-profile-tubler-email-03"
                                type="checkbox"
                            />
                            <label
                                className="label-dot"
                                htmlFor="lk-profile-tubler-email-03"
                            >
                                <i/>
                            </label>
                            <label
                                className="label-text"
                                htmlFor="lk-profile-tubler-email-03"
                            >
                                E-mail
                            </label>
                        </div>
                        <div className="form-tumbler">
                            <input
                                className="hidden"
                                id="lk-profile-tubler-sms-03"
                                type="checkbox"
                            />
                            <label
                                className="label-dot"
                                htmlFor="lk-profile-tubler-sms-03"
                            >
                                <i/>
                            </label>
                            <label
                                className="label-text"
                                htmlFor="lk-profile-tubler-sms-03"
                            >
                                SMS
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="form-checkboxes">
                <div className="form-checkbox">
                    <input
                        className="hidden"
                        id="lk-profile-unsuscribe-all"
                        type="checkbox"
                    />
                    <label
                        className="label-dot"
                        htmlFor="lk-profile-unsuscribe-all"
                    />
                    <label
                        className="label-text"
                        htmlFor="lk-profile-unsuscribe-all"
                    >
                        Отписаться от всего
                    </label>
                </div>
            </div>
        </form>
    </div>)
}