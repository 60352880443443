import {OrderInterface} from "../interfaces/customer/OrderInterface";
import fetchData from "../utils/HttpClient";
import {GiftCertificateFormInterface} from "../interfaces/gift-certificate/GiftCertificateFormInterface";
import {GiftCertificateInterface} from "../interfaces/gift-certificate/GiftCertificateInterface";
import Md5 from "../utils/Md5";

export async function createSertificate(params:GiftCertificateFormInterface): Promise<{certificate: GiftCertificateInterface, order?: OrderInterface}> {
    return fetchData('/api/gift-certificate/create' , params, 'POST')
}

export async function checkBalance(params:{number: string, code: string, uid: string}): Promise<{certificate?: GiftCertificateInterface}> {
    const uid = (atob('RCw3TTgvMnxqYmt5') + (new Date()).toISOString().split("T")[0]);
    params.uid = Md5(uid + params.number)
    return fetchData('/api/gift-certificate/balance' , params, 'POST')
}