import styles from './Spacer.module.scss'

function Spacer() {
	return (
		<>
			<div className='mblock '>
				<div className={styles.spacer}></div>
			</div>
		</>
	)
}

export default Spacer
