import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import {checkBalance} from "../../../api/gift-certificate";
import {Form} from "react-bootstrap";
import {GiftCertificateInterface} from "../../../interfaces/gift-certificate/GiftCertificateInterface";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {objclone} from "../utils/Utils";
import Money from "../../core/Money";
import MoneyInterface from "../../../interfaces/core/MoneyInterface";

export default function ApplyGiftCertificateForm({total}: { total: MoneyInterface }) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const [error, setError] = useState<string | null>(null);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const giftCertificates = state.giftCertificates


    const {register, reset, handleSubmit, formState: {errors}} = useForm();

    const formSubmit = (formParams: any) => {
        setError(null);
        setIsLoaded(true)
        checkBalance(formParams).then((response) => {
                setIsLoaded(false)

                if (!response.certificate?.active_nominal) {
                    setError('Сертификат имеет нулевой баланс');
                    return;
                }

                const currentCertificate = response.certificate

                if(giftCertificates.find(gs => gs.id === currentCertificate.id || gs.number === currentCertificate.number)){
                    setError('Сертификат уже добавлен');
                    return;
                }

                const currentGiftCertificatesSum = giftCertificates.reduce((acc, certificate) => acc + certificate.active_nominal.amount, 0)

                if(currentGiftCertificatesSum + currentCertificate.active_nominal.amount > total.amount){
                    currentCertificate.active_nominal.amount = total.amount
                }

                currentCertificate.number = formParams.number
                currentCertificate.code = formParams.code

                giftCertificates.push(currentCertificate)
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'giftCertificates', payload: giftCertificates})
                reset({
                    number: '',
                    code: ''
                })
            },
            (error: any) => {
                setError(error.response?.data?.message || error.response?.data?.detail);
                setIsLoaded(false)
            })
    }

    const removeGiftCertificate = (giftCertificate: GiftCertificateInterface) => {
        let giftCertificates: GiftCertificateInterface[] = objclone(state.giftCertificates)
        giftCertificates = giftCertificates.filter(gs => gs.id !== giftCertificate.id)
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'giftCertificates', payload: giftCertificates})
    }

    return (<>
            {!showForm && <span className={'pointer dashed'} onClick={() => {
                setShowForm(true)
            }}>У меня есть подарочный сертификат</span>}
            {showForm && <div className={'basket__promocode'}>
                <form onSubmit={handleSubmit(formSubmit)}>
                    {error && <b className={'text-danger'}>{error}</b>}
                    <div className="form-group">
                        {isLoaded && <span className={'text-muted'}>Производится проверка сертификата ...</span>}

                        <div className="row">
                            <div className="col-md-7">
                                <Form.Control type="text" disabled={isLoaded} placeholder="Номер сертификата"
                                              className={(errors.number ? ' is-invalid' : '')} {...register("number", {
                                    required: true,
                                    validate: v => /^[+\d]{6,8}$/.test(v)
                                })} />
                            </div>
                            <div className="col-md-5">
                                <Form.Control type="text" disabled={isLoaded} placeholder="Проверочный код"
                                              className={(errors.code ? ' is-invalid' : '')} {...register("code", {
                                    required: true,
                                    validate: v => /^[+\d]{4}$/.test(v)
                                })} />
                                <button type={"submit"} className="btn btn-secondary">
                                    <i className="ico ico-arrow-right-w"/>
                                </button>
                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col">
                            {giftCertificates?.map(giftCertificate => <div key={giftCertificate.id}>
                                    <b className="me-2">
                                        <span className={'text-danger'}>{giftCertificate.number}</span> <span
                                        className={'desk'}>|  номинал: <Money
                                        value={giftCertificate.nominal}/>,</span> остаток <Money
                                        value={giftCertificate.active_nominal}/> <span className={'pointer'}
                                                                                       onClick={() => {
                                                                                           removeGiftCertificate(giftCertificate)
                                                                                       }}>&times;</span>
                                    </b>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>}
        </>
    )
}