export default function Contacts() {
    return (<div
        className="page-company__contacts tab-pane fade show active"
    >
        <div className="row">
            <div className="col-lg-3 offset-lg-2 order--2">
                <div className="page-company__contacts-data bd">
                    <ul>
                        <li>
                            <h6>Франчайзинг</h6>
                            <p className="ico-cont">
                                <i className="ico ico-left ico-phone"/>{" "}
                                <a href="">+7 495 698-61-76</a>, доб. 904
                            </p>
                            <p className="ico-cont">
                                <i className="ico ico-left ico-mail"/>{" "}
                                <a href="">makarova@b2b.moda</a>
                            </p>
                        </li>
                        <li>
                            <h6>Оптовые поставки</h6>
                            <p className="ico-cont">
                                <i className="ico ico-left ico-phone"/>{" "}
                                <a href="">+7 495 698-61-76</a>, доб. 903
                            </p>
                            <p className="ico-cont">
                                <i className="ico ico-left ico-mail"/>{" "}
                                <a href="">makarova@b2b.moda</a>
                            </p>
                        </li>
                        <li>
                            <h6>Интернет-магазин</h6>
                            <p className="ico-cont">
                                <i className="ico ico-left ico-phone"/>{" "}
                                <a href="">8 (800) 500 73-17</a>
                            </p>
                        </li>
                        <li>
                            <h6>Возврат товара</h6>
                            <p className="ico-cont">
                                <i className="ico ico-left ico-phone"/>{" "}
                                <a href="">+7 (965) 447 7145</a>
                            </p>
                            <p className="ico-cont">
                                <i className="ico ico-left ico-mail"/>{" "}
                                <a href="">vozvrat@baon.ru</a>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="page-company__contacts-payer bd">
                    <ul>
                        <li>
                            <b>Продавец:</b> ООО "Бифлекс"
                        </li>
                        <li>
                            <b>ОГРН:</b> 5177746033141
                        </li>
                        <li>
                            <b>ИНН:</b> 7728381442
                        </li>
                        <li>
                            <b>Юридический адрес:</b> 119330, г. Москва, вн. тер. г.
                            муниципальный округ Раменки, ул. Мосфильмовская, д. 17Б,
                            помещ. 89
                        </li>
                        <li>
                            <b>Почтовый адрес:</b> 115114, г. Москва, Дербеневская наб.,
                            дом. 7, стр. 9
                        </li>
                        <li>
                            <b>Фактический адрес:</b> ст.м. Павелецкая, Москва,
                            Дербеневская наб., дом. 7, стр. 9 (БЦ “Новоспасский“)
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-5 order--1">
                <div className="page-company__contacts-map">
                    <h5 className="f-exp">Как проехать</h5>
                    <p>
                        м. Павелецкая, Дербенёвская наб. 7с9 БЦ «Новоспасский»от метро
                        можно дойти пешком, либо проехать на автобусах № с932 и 913 от
                        остановки «Павелецкий вокзал»до остановки «Дербенёвская
                        улица». Далее пройти пешком 360 м (4 мин.) Проход на
                        территорию – «цифра 1 оранжевая стрелка».
                    </p>
                    <p>
                        Если по какой-либо причине у вас нет возможности передать нам
                        товар для возврата в допустимые сроки, свяжитесь с менеджером
                        по возврату, чтобы мы рассмотрели ваше заявление в
                        индивидуальном порядке.
                    </p>
                    <div className="page-company__contacts-map-tabs">
                        {/* <ul
                            className="nav nav-tabs f-exp"
                            id="myaddrTab"
                            role="tablist"
                        >
                            <li className="nav-item" role="company">
                                <button
                                    className="nav-link active"
                                    type="button"
                                >
                                    Проезд на машине
                                </button>
                            </li>
                            <li className="nav-item" role="company_addr">
                                <button
                                    className="nav-link"
                                    type="button"
                                >
                                    Пройти пешком
                                </button>
                            </li>
                        </ul> */}
                    </div>
                    <div className="tab-content" id="company_addrTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="company_addr-sect-1"
                            role="tabpanel"
                            aria-labelledby="company_addr-tab-1"
                        >
                            <img className="contacts-map" src="img/contacts/contacts-map.png" alt="office-map" />
                        </div>
                        <div
                            className="tab-pane fade"
                            id="company_addr-sect-2"
                            role="tabpanel"
                            aria-labelledby="company_addr-tab-2"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}