import Spacer from '../Spacer/Spacer.jsx'
import style from './GridTwoElems.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const GridTwoElems = props => {
	const elems = props.params.data
	return (
		<>
			<div className={style.rowBlock}>
				{elems.map((elem, i) => (
					<div className={style.rowBlockElem} key={i}>
						<img src={getBaseImagePath() + elem.img} alt={elem.img} />
						<div
							className={style.title}
							dangerouslySetInnerHTML={{__html: elem.title}}
						></div>
						<p
							dangerouslySetInnerHTML={{__html: elem.text}}
							className={'text-center ' + style.text}
						></p>
					</div>
				))}
			</div>
			<Spacer />
		</>
	)

}



export default GridTwoElems
