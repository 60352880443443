import React, {useState} from 'react'
import styles from './Faq.module.scss'

const QuestionContext = React.createContext()
export default function FaqElement({children, ...restProps}) {


	return (
		<div className={'mblock ' + styles.faq} {...restProps}>
			{/* <div className={styles.faqTitle}>Вопросы-ответы</div> */}
			<div>{children}</div>
		</div>
	)
}

FaqElement.Header = function FaqElementHeader({children, ...restProps}) {
	return <div {...restProps}> {children}</div>
}
FaqElement.Entity = function FaqElementEntity({children, ...restProps}) {
	const [open, setOpen] = useState(false)
	return (
		<QuestionContext.Provider value={{open, setOpen}}>
			<div className={styles.child} {...restProps}>
				{' '}
				{children}
			</div>
		</QuestionContext.Provider>
	)
}
FaqElement.Question = function FaqElementHeader({children, ...restProps}) {
	const {open, setOpen} = React.useContext(QuestionContext)

	return (
		<div
			className={styles.faqElem}
			onClick={() => setOpen(open => !open)}
			{...restProps}
		>
			{children}
			{open ? (
				<img
					src='/public/new-design/landing-materials/desktop/minus.png'
					alt=''
				/>
			) : (
				<img
					src='/public/new-design/landing-materials/desktop/plus.png'
					alt=''
				/>
			)}
		</div>
	)
}
FaqElement.Answer = function FaqElementAnswer({children, ...restProps}) {
	const {open} = React.useContext(QuestionContext)
	return open ? (
		<div {...restProps}
		>
			{children}
		</div>
	) : null
}

FaqElement.Text = function FaqElementText({children, ...restProps}) {
	return (
		<div className={styles.text} {...restProps}
			dangerouslySetInnerHTML={{__html: children}}
		>
		</div>
	)
}

FaqElement.Image = function FaqElementImage({children, ...restProps}) {
	return (
		<div className={styles.answerImage} {...restProps} >
			{children}
			<img
				src={children}
				alt={children}
			/>
		</div>
	)
}