import React, {useContext, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import isEmail from 'validator/es/lib/isEmail';
import Input from 'react-phone-number-input/input';
import {ecommerceDataLayer} from './utils/marketing/Service';
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

function PersonalData() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {register, getValues, reset, watch, control, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        if (state.customer?.id) {
            reset({
                first_name: state.customer.first_name,
                last_name: state.customer.last_name,
                phone: state.customer.phone_with_prefix || state.customer.phone,
                email: state.customer.email,
            })
            // @ts-ignore
            ecommerceDataLayer('checkout', 'checkout', state.positions, 1)
        }
    }, [state.customer?.id]) // eslint-disable-line react-hooks/exhaustive-deps

    let watchAllFields = JSON.stringify(watch())

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'personalData', payload: {...getValues()}})
        // @ts-ignore
        ecommerceDataLayer('checkout', 'checkout', state.positions, 1)
    }, [watchAllFields]) // eslint-disable-line react-hooks/exhaustive-deps

    const showLoginForm = () => {
        dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.LOGIN})
    }

    return (
        <>
            <div className="basket__form bd">
                <form action="">
                    <h5 className="basket__form-title f-exp">Ваши данные</h5>
                    <div className="form-wrap big">
                        <div className="form-group">
                            <label htmlFor="">Имя*</label>
                            <div className="form-group">
                                <input type="text" className={'form-control' + (errors.first_name ? ' is-invalid' : '')}
                                       placeholder="Имя" {...register("first_name", {required: true})}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Фамилия *</label>
                            <input type="text" className={'form-control' + (errors.last_name ? ' is-invalid' : '')}
                                   placeholder="Фамилия" {...register("last_name", {required: true})}/>
                        </div>
                    </div>
                    <div className="form-wrap big">
                        <div className="form-group">
                            <label htmlFor="">Телефон*</label>
                            <div className="form-group">
                                <Controller
                                    control={control}
                                    name="phone"
                                    rules={{
                                        required: true,
                                        validate: v => /^[+\d]{10,20}$/.test(v)
                                    }}
                                    render={({field: {onChange, name, value}}) => (
                                        <Input
                                            className={'form-control ' + (errors.phone ? ' is-invalid' : '')}
                                            placeholder="+7 ___ ___-__-__"
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">E-mail*</label>
                            <div className="form-group">
                                <input type="text" className={'form-control' + (errors.email ? ' is-invalid' : '')}
                                       placeholder="введите email" {...register("email", {
                                    required: true,
                                    validate: {
                                        checkEmail: v => isEmail(v)
                                    }
                                })}/>
                                <i className="ico ico-exclamation ico-form right"/>
                            </div>
                        </div>
                        {/*<div className="form-group">*/}
                        {/*    <label className="invisible" htmlFor="">*/}
                        {/*        <i>0</i>*/}
                        {/*    </label>*/}
                        {/*    <p className="form-annotation">*/}
                        {/*        Укажите e-mail, чтобы получать информацию по заказу и*/}
                        {/*        актуальным акциям. А мы начислим Вам 100 бонусов 🙂*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </div>
                </form>
            </div>
        </>
    )
}

export default PersonalData