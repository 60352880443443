import ChooseYourLookPointInterface from "../../../interfaces/main/ChooseYourLookPointInterface";
import ModelListCard from "../../catalog/ModelListCard";

export default function Point({point, changePoint}: { point: ChooseYourLookPointInterface, changePoint: (point:ChooseYourLookPointInterface) => void
}) {
    return (<div className={"hint hint-" + point.id}>
        <i className="hint__dot" style={{top: point.y+'%', left: point.x+'%'}} onClick={() => {
            point.isActive = !point.isActive
            changePoint(point)
        }}></i>
        <div style={{opacity: (point.isActive ? 1 : 0), pointerEvents: (point.isActive ? 'auto' : 'none')}}><ModelListCard disableBasket={true} modelColorItem={point.modelColorItem} /></div>
    </div>)
}