import PageMetaInterface from "../interfaces/core/PageMetaInterface";
import BreadcrumbInterface from "../interfaces/core/BreadcrumbInterface";
import fetchData from "../utils/HttpClient";
import {ItemInterface} from "../interfaces/news/ItemInterface";
import {PromotionInterface} from "../interfaces/news/PromotionInterface";

export function getNewsById(id: string): Promise<{ post: ItemInterface, meta: PageMetaInterface, breadcrumbs: BreadcrumbInterface[] }> {
    return fetchData('/api/news/item/' + id)
}

export function getPromotions(): Promise<{
    posts: PromotionInterface[];
    breadcrumbs: BreadcrumbInterface[];
}> {
    return fetchData("/api/news/list");
}