import Alert from 'react-bootstrap/Alert'

function NoDeliveriesAndPayments() {
    return (
        <Alert variant="danger mt-4" transition={false} show={true}>
            <Alert.Heading>Для данного региона доступных доставок не найдено</Alert.Heading>
            <p>
                К сожалению для данного региона мы не смогли найти вариантов доставки.<br />
                Вы можете выбрать ближайший большой населенный пункт, либо связаться с нашими менеджерами и мы постараемся подобрать оптимальный для вас вариант<br />
            </p>
        </Alert>
    )
}

export default NoDeliveriesAndPayments