import {useContext} from "react";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";

export default function CookieNotification() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);


    return (<div className="notification notification--right notification--bottom" >
        <div className="notification__head">
            <h5 className="notification__title f-exp">Мы используем cookies</h5>
            <i className="notification__close ico ico-close" onClick={() => {dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showCookieWarn', payload: false})}}></i>
        </div>
        <div className="notification__body">
            <p>Это необходимо, чтобы сайт работал лучше. А ещё применяем <a href="/landing/27">рекомендательные технологии</a>.</p>
        </div>
        <div className="notification__btns btns--full">
            <button className="btn btn-secondary"  type={'button'} onClick={() => {dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showCookieWarn', payload: false})}}>Хорошо</button>
        </div>
    </div>)
}