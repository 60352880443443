import React, {ReactNode, useContext, useState} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function RightModalFull({children}: {children: ReactNode}) {
    const [error, setError] = useState("");
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const renderChildren = () => {
        return React.Children.map(children as React.ReactElement<any>, (child) => {
            return React.cloneElement(child, {
                error: error,
                setError: setError,
            });
        });
    };

    return (
        <div className="modal fade modal-main show">
            <div className="modal-dialog ts" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="close"
                        onClick={() => {
                            dispatch({type: ReducerActionType.CLOSE_ALL_POPUP});
                        }}
                    ></button>
                    <div className="modal-body">{renderChildren()}</div>
                </div>
            </div>
        </div>
    );
}
