
const TableInfo = () => {

    return (
        <>
            <div className="info">
                <h4 className="f-exp">Как узнать свой размер?</h4>
                <div className="wrap">
                    <div className="text">
                        <p>
                            Все измерения фигуры выполняются при соблюдении определенных условий: измеряемый должен
                            стоять прямо, без напряжения, сохраняя привычную осанку.
                        </p>
                        <ul>
                            <li>
                                (обхват груди) - сантиметровая лента при измерении проходит горизонтально вокруг
                                туловища через выступающие точки грудных желез и замыкается на правой стороне груди.
                            </li>
                            <li>
                                (обхват талии) - лента проходит горизонтально вокруг туловища на уровне линии талии. Для
                                более точного проведения этого измерения рекомендуется предварительно повязать вокруг
                                талии тонкий шнурок.
                            </li>
                            <li>
                                (обхват бедер) - лента проходит через выступающие точки ягодиц горизонтально вокруг
                                туловища.
                            </li>
                        </ul>
                    </div>
                    <div className="img">
                        <img src="img/sizes.png" alt="img/sizes.png" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TableInfo
