import fetchData, {fetchDataDealer} from "../utils/HttpClient";
import Md5 from "../utils/Md5";
import moment from "moment/moment";
import {LOCAL_STORAGE_KEY_PARAMS} from "../Config";
import ModelInterface from "../interfaces/model/ModelInterface";
import {FiltersInterface} from "../interfaces/dealer/FiltersInterface";
import ModelPropertyInterface from "../interfaces/model/ModelPropertyInterface";
import {OrderInterface} from "../interfaces/dealer/OrderInterface";
import {ManagerInterface} from "../interfaces/dealer/ManagerInterface";
import {OrdersResponseInterface} from "../interfaces/dealer/OrdersResponseInterface";
import {SingleOrderResponseInterface} from "../interfaces/dealer/SingleOrderResponseInterface";

const basePath = '/api/dealer';

export function login(login: string, password: string) {
    return fetchData(basePath + '/login', {
        login: login,
        password: password,
        uid: Md5(login + moment().format('YYYY-MM-DD') + LOCAL_STORAGE_KEY_PARAMS)
    }, 'POST')
}

export function getFilters(): Promise<FiltersInterface>
{
    return fetchDataDealer(basePath + '/filters', {}, 'POST')
}

export function getModels(filters: FiltersInterface): Promise<{models: ModelInterface[], properties: ModelPropertyInterface[] }> {
    return fetchDataDealer(basePath + '/models', filters, 'POST')
}

export function createOrder(order: any): Promise<any> {
    return fetchDataDealer(basePath + '/save-order', order, 'POST')
}

export function sendOrder(order: OrderInterface, manager: ManagerInterface): Promise<any> {
    return fetchDataDealer(basePath + '/send-order', {order: order, manager: manager}, 'POST')
}

export function getOrders(): Promise<OrdersResponseInterface> {
    return fetchDataDealer(basePath + '/orders', {}, 'POST')
}


export function getSingleOrder(orderId: number): Promise<SingleOrderResponseInterface> {
    return fetchDataDealer(basePath + '/order/' + orderId, {}, 'POST')
}


