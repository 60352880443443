import ChooseYourLookItemInterface from "../../../interfaces/main/ChooseYourLookItemInterface";
import Point from "./Point";
import ChooseYourLookPointInterface from "../../../interfaces/main/ChooseYourLookPointInterface";
import {getBaseImagePath} from "../../catalog/Utils";
import Modal from "./Modal";
import React, {useState} from "react";
import {Link} from "react-router-dom";

const Item = ({showIntro, item, changeItem, setSelectedItem}: {
    showIntro?: boolean
    item: ChooseYourLookItemInterface,
    changeItem: (item:ChooseYourLookItemInterface) => void,
    setSelectedItem: (item:ChooseYourLookItemInterface) => void
}) =>
{
    const changePoint = (point:ChooseYourLookPointInterface) => {
        let points = [...item.points.filter(lpoint => lpoint.uid !== point.uid), ...[point]]
        item.points = points
        changeItem(item)
    }

    return (<>
        {showIntro && <div className="choose__info desk">
            <h2 className="f-exp">Подбери<br className="desk"/> свой образ</h2>
            <p>Выбирай, что нравится только тебе</p>
            {/* <Link to={'/choose-your-look'} className="btn btn-secondary desk" ><i
                className="ico ico-eye-white"></i>Посмотреть все</Link> */}
        </div>}
        {item && <div className="swiper-slide-cont">
            <div className="slide__wrap">
                <picture>
                    <source srcSet={getBaseImagePath() + item.image}
                            media="(max-width: 992px)"/>
                    <img src={getBaseImagePath() + item.image} alt=""/>
                </picture>
                <a className="btn btn-primary btn-bd" href="" onClick={(e) => {
                    e.preventDefault()
                    setSelectedItem(item)
                }}><i
                    className="ico ico-color ico-eye-black"></i>
                    <span>Весь образ</span></a>
                <p className="choose__slide-name">{item.name}</p>
                {item.points.map((point:ChooseYourLookPointInterface) => <Point key={point.uid} changePoint={changePoint} point={point}  />)}
            </div>
        </div>}
    </>)
}

export default Item