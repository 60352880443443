import {getBaseImagePath} from "../Utils";
import ModelInterface from "../../../interfaces/model/ModelInterface";
import {ModelStockShopInterface} from "../../../interfaces/model/ModelStockShopInterface";
import {RegionInterface} from "../../../interfaces/core/RegionInterface";
import {ShopInterface} from "../../../interfaces/shop/ShopInterface";
import useModel from "../useModel";
import {useContext, useEffect, useState} from "react";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../../GlobalReducer";

export default function FirstStep({model, regions, restrictedShops, shops, stocks, selectedColor, selectedSize, setSelectedColor, setSelectedSize, setSelectedShop}: {model: ModelInterface, regions: RegionInterface[], restrictedShops: ShopInterface[], shops: ShopInterface[], stocks: ModelStockShopInterface[], selectedColor: string, selectedSize: string,  setSelectedColor: Function, setSelectedSize: Function, setSelectedShop: Function})
{
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [selectedRegionId, setSelectedRegionId] = useState(regions[0]?.id)

    const {getFrontImages, getCurrentColorName} = useModel(model, selectedColor)

    const getFilteredStocks = () => stocks.filter(stock => stock.region_id === selectedRegionId && stock.special_color_name === selectedColor && stock.size === selectedSize)

    const getStockByShopId = (shopId: string) => getFilteredStocks().find(stock => stock.shop_id === shopId)

    const getShopsBySelectedRegionAndColorSize = () => shops.filter(shop => getFilteredStocks().map(stock => stock.shop_id).includes(shop.id))

    const getAvailableStocksByRegion = () => stocks.filter(stock => stock.region_id === selectedRegionId)

    const getUniqAvailableColors = () => getAvailableStocksByRegion().map(stock => stock.special_color_name).filter((x, i, a) => a.indexOf(x) == i)

    const isSizeAvailable = (size:string) => getAvailableStocksByRegion().filter(stock => stock.special_color_name === selectedColor).map(stock => stock.size).includes(size)

    useEffect(() => {
        if(regions[0]?.id){
            setSelectedRegionId(regions[0]?.id)
        }
        if(state.region?.id && regions.map(region => region.id).includes(state.region?.id)){
            setSelectedRegionId(state.region?.id)
        }
    }, [state.region?.id, regions[0]?.id]);

    useEffect(() => {
        if(!getUniqAvailableColors().includes(selectedColor)){
            setSelectedColor(getUniqAvailableColors()[0])
        }
        if(!isSizeAvailable(selectedSize)){
            setSelectedSize('');
        }
    }, [selectedRegionId]);

    useEffect(() => {
        if(!isSizeAvailable(selectedSize)){
            setSelectedSize('');
        }
    }, [selectedColor]);

    return (<div className="modal-rezerv__step-1">
        <div className="form-group">
            <p className="f-exp">Выберите город</p>

            <select className={'form-control'} onChange={(event) => {
                setSelectedRegionId(event.currentTarget.value)
            }}>
                {regions.map(region => <option key={region.id} value={region.id}>{region.name}</option>)}
            </select>
        </div>

        <div className="product__kv-info-model">
            <p>{getCurrentColorName()}</p>
            <div className="product__kv-info-colors">
                <div className="swiper-wrapper">
                    {model?.images && getFrontImages().filter(image => getUniqAvailableColors().includes(image.special_color_name)).map(image =>
                        <div key={image.url}
                             className={"swiper-slide " + (image.special_color_name === selectedColor ? "active" : "")}
                             onClick={() => {
                                 setSelectedColor(image.special_color_name)
                             }}><img
                            src={getBaseImagePath() + image.url.replace('shopcatalog-resize/big', 'shopcatalog-resize/small')}
                            alt=""/></div>)}
                </div>
            </div>
        </div>

        <div className="product__kv-info-sizes">
            <div className="top">
                <p>Выберите размер</p>
            </div>
            <ul className="all-filters-sizes">
                {model?.sizes.map(size => <li key={size}>
                    <div
                        className={'form-checkbox' + (selectedSize === size ? " active " : "") + (!isSizeAvailable(size) ? " disabled " : "")}
                        onClick={(event) => {
                            event.preventDefault()
                            if (isSizeAvailable(size)) {
                                setSelectedSize(size)
                            }
                        }}
                    >
                        <label className="label-dot ">{size}</label>
                    </div>
                </li>)}
            </ul>
        </div>

        <div className="choose-shop">
            <h3 className="f-exp">Выберите магазин</h3>
            <ul>
                {getShopsBySelectedRegionAndColorSize().map(shop => <li key={shop.id}>
                    <div className="text">
                        <h4 className="f-exp">{shop.name}</h4>
                        <p>{shop.extra_param?.address || shop.address} {shop.extra_param?.address2}</p>
                        <p>{shop.extra_param?.operation_time}</p>
                        <p>
                            <span>В наличии {getStockByShopId(shop.id)?.quantity} шт.</span>
                        </p>
                    </div>
                    <div className="btns">
                        <button className="btn btn-secondary" type={'button'} onClick={() => {
                            setSelectedShop(shop)
                        }}>
                            Выбрать
                        </button>
                    </div>
                </li>)}
            </ul>
        </div>
    </div>)
}