import React, {useState, useRef, useEffect} from "react";
import Slider from "react-slick";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import {Zoom} from "swiper/modules";
import {getBaseImagePath} from "../Utils";
import ModelImageInterface from "../../../interfaces/model/ModelImageInterface";
import {convertImageToSize, ImageSize} from "../../../utils/Image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface CardMobilePopupProps {
    isOpen: boolean;
    onClose: () => void;
    initialSlideIndex: number;
    images: ModelImageInterface[];
    altString: string;
}

const CardMobilePopup: React.FC<CardMobilePopupProps> = ({isOpen, onClose, initialSlideIndex, images, altString}) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(initialSlideIndex);
    const [slideIndex, setSlideIndex] = useState<number>(initialSlideIndex);

    const thumbSliderRef = useRef<Slider | null>(null);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const swiperRef = useRef<any>(null);

    useEffect(() => {
        setIsDesktop(window.innerWidth > 991);

        const handleResize = () => {
            setIsDesktop(window.innerWidth > 991);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("no-scroll");
            window.history.pushState(null, "", window.location.href);

            const scrollToSlide = () => {
                const container = scrollContainerRef.current;
                const targetImage = container?.querySelectorAll(".slide-container")[initialSlideIndex] as HTMLElement;

                if (container && targetImage) {
                    const containerTop = container.getBoundingClientRect().top;
                    const targetTop = targetImage.getBoundingClientRect().top;

                    const scrollOffset = targetTop - containerTop + container.scrollTop;
                    container.scrollTo({
                        top: scrollOffset,
                        behavior: "smooth",
                    });
                }
            };

            scrollToSlide();

            const handlePopState = () => {
                window.history.pushState(null, "", window.location.href);
            };

            window.addEventListener("popstate", handlePopState);

            return () => {
                window.removeEventListener("popstate", handlePopState);
                document.body.classList.remove("no-scroll");
            };
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isOpen, initialSlideIndex]);

    const thumbSliderSettings = {
        slidesToShow: Math.min(images.length, 5),
        slidesToScroll: 1,
        infinite: true,
        focusOnSelect: true, 
        swipeToSlide: true,
        vertical: true,
        centerMode: true,
        verticalSwiping: true,
        arrows: false,
        afterChange: (index: number) => {
            setSlideIndex(index);
            const scrollToSlide = () => {
                const container = scrollContainerRef.current;
                const targetImage = container?.querySelectorAll(".slide-container")[index] as HTMLElement;

                if (container && targetImage) {
                    const containerTop = container.getBoundingClientRect().top;
                    const targetTop = targetImage.getBoundingClientRect().top;

                    const scrollOffset = targetTop - containerTop + container.scrollTop;
                    container.scrollTo({
                        top: scrollOffset,
                        behavior: "smooth",
                    });
                }
            };

            scrollToSlide();
        },
    };

    if (!isOpen) {
        return null;
    }

    if (isDesktop) {
        return (
            <div className="modal fade modal-product modal-product-slider show" style={{display: "block"}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-bs-dismiss="modal" onClick={onClose}></button>
                        <div className="modal-body">
                            <div className="modal-product-wrap">
                                <div className="modal-product__slider-add">
                                    { // @ts-ignore
                                    <Slider {...thumbSliderSettings} ref={thumbSliderRef}>
                                        {images.map((image, index) => (
                                            <div key={index} className="slide">
                                                <img
                                                        src={
                                                            getBaseImagePath() +
                                                            convertImageToSize(image, ImageSize.THUMBNAIL).url
                                                        }
                                                        alt={`${altString} - ${index + 1}`}
                                                    />
                                            </div>
                                        ))}
                                    </Slider>}
                                </div>

                                <div className="modal-product__slider-main"
                                ref={scrollContainerRef}
                                style={{
                                    overflowY: "scroll",
                                    scrollSnapType: "y mandatory",
                                }}>
                                        {images.map((image, index) => (
                                            <div key={index} className="slide-container"  
                                            style={{
                                            scrollSnapAlign: "start",
                                        }}>
                                                <img
                                                        src={
                                                            getBaseImagePath() +
                                                            convertImageToSize(image, ImageSize.ORIIGINAL).url
                                                        }
                                                        alt={`${altString} - ${index + 1}`}
                                                    />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="modal fade modal-card-mobile-popup show" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="modal-feedback__wrap">
                                <div className="modal-feedback__title">
                                    <button type="button" className="close" onClick={onClose}></button>
                                </div>
                                <div className="card-mobile-popup__slider-wrapper">
                                    <Swiper
                                        modules={[Zoom]}
                                        initialSlide={initialSlideIndex}
                                        zoom={true}
                                        onSlideChange={(swiper: any) => setSlideIndex(swiper.activeIndex)}
                                        onSwiper={(swiper: any) => {
                                            swiperRef.current = swiper;
                                        }}
                                    >
                                        {images.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="swiper-zoom-container">
                                                    <img
                                                        src={
                                                            getBaseImagePath() +
                                                            convertImageToSize(image, ImageSize.ORIIGINAL).url
                                                        }
                                                        alt={`${altString} - ${index + 1}`}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <div className="card-mobile-popup__thumbnail-slider">
                                    {images.map((image, index) => (
                                        <div
                                            key={index}
                                            className="thumbnail"
                                            style={{border: slideIndex === index ? "1px solid black" : "none"}}
                                            onClick={() => {
                                                swiperRef.current.slideTo(index);
                                                setSlideIndex(index);
                                            }}
                                        >
                                            <img src={getBaseImagePath() + image.url} alt={`Thumbnail ${index + 1}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default CardMobilePopup;
