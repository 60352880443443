import {alfaUserInfo} from "../../../api/openid";
import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {LOCAL_STORAGE_KEY_CUSTOMER} from "../../../Config";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function AlfaID() {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const auth = () => {
        dispatch({type: ReducerActionType.LOAD})
        setMessage('Загружаем данные пользователя...')


        const code = queryParams.get('code');

        if (!code) {
            setError('В процессе авторизации возникла проблема: Код не найден. Попробуйте еще раз или выберите другой способ входа')
            return
        }


        alfaUserInfo(code).then(customer => {
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'customer', payload: customer})
            localStorage.setItem(LOCAL_STORAGE_KEY_CUSTOMER, JSON.stringify(customer))
            dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: ''})

            setMessage('Вы успешно авторизовались: ' + customer.first_name + ' ' + customer.last_name)
            navigate('/personal-area/')
            dispatch({type: ReducerActionType.SUCCESS})
        }).catch(error => {
            setError(error.response?.data?.message || error.response?.data?.detail)
        })
    }

    useEffect(() => {
        auth()
    }, []);

    return (<main className="main-lk">
        <section className="lk">
            <div className="container-fluid">
                <div className="row" style={{padding: '180px 0'}}>
                    <div className="col text-center">
                        {error && <h1 className={'text-danger mt-4'}>{error}</h1>}
                        {!error && message && <h1 className={'mt-4'}>{message}</h1>}
                    </div>
                </div>
            </div>
        </section>
    </main>)
}


