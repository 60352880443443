import Spacer from '../Spacer/Spacer.jsx'
import styles from './Timer.module.scss'
import {useState, useEffect} from 'react';
import {getRemainingTimeUntilMsTimestamp} from "../../../../utils/ConvertDate";


const Timer = props => {

	const date = props.params.timer_date;
	const dateObj = Date.parse(date)

	const defaultRemainingTime = {
		seconds: '00',
		minutes: '00',
		hours: '00',
		days: '00'
	}


	const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);


	useEffect(() => {
		const intervalId = setInterval(() => {
			updateRemainingTime(dateObj);
		}, 1000);
		return () => clearInterval(intervalId);
	}, [dateObj]);

	function updateRemainingTime(countdown) {
		setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
	}

	return (
		<>
			<div className="mblock banner-on-main timerBLock" >
				<div className={styles.countdownBlock}>
					<div
						className={
							props.params.clsArrayTimer ? styles.countdown + ' ' + props.params.clsArrayTimer.join(' ') : styles.countdown
						}
					>
						<div className="container text-center">
							<div className={styles.clock}>
								<div className={styles.countdownDigit + ' digit'}>{remainingTime.days.padStart(2, '0')}</div>
								<div className={styles.countdownDigit + ' digit'}>{remainingTime.hours.padStart(2, '0')}</div>
								<div className={styles.countdownDigit + ' digit'}>{remainingTime.minutes.padStart(2, '0')}</div>
							</div>
							<p className={styles.texts + ' digit'}><span>&nbsp;дней</span><span>&nbsp;&nbsp;часов</span><span>минут</span></p>
						</div>
					</div>



				</div>
			</div>
			<Spacer />
		</>
	)
}

export default Timer
