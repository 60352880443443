import {useQuery} from "@tanstack/react-query";
import {getModelColorItems} from "../../../api/catalog";
import {NOT_FOUND_REPOSITORY_ID} from "../../../Config";
import HorizontalModelSlider from "../../main/NowBuy";
import ChooseYourLook from "../../main/ChooseYourLook";
import {chooseYourLook} from "../../../api/page";
import {useContext} from "react";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../../GlobalReducer";
import {Helmet, HelmetProvider} from "react-helmet-async";


export default function NotFoundPage()
{
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {data} = useQuery({
        queryKey: ['models-on-404'],
        queryFn: () => getModelColorItems({
            repository_id: [NOT_FOUND_REPOSITORY_ID],
            limit: 600,
            randomize: true
        }),
        retry: false,
        // @ts-ignore
        enabled: !window.NOSCRIPT
    })


    const {data: chooseYourLookItems} = useQuery({
        queryKey: ['page', 'choose-your-look', !!state.customer?.id],
        queryFn: () => chooseYourLook(!!state.customer?.id)
    })

    const getFilteredAndRandomizeModels = () => {
        let colorItems = (data?.model_color_items || []).sort(() => Math.random() - 0.5)
        return colorItems.sort(() => Math.random() - 0.5).slice(0, 100)
    }

    return (<section className="page-404 pt" style={{padding: "300px 0"}}>
        <HelmetProvider><Helmet>
            <meta name="prerender-status-code" content="404"/>
        </Helmet></HelmetProvider>
        <div className="page-404__wrap">
            <h4 className="f-exp">
                404 Error
                <br/> К сожалению, запрашиваемой Вами страницы не существует
            </h4>
            <p>
                Возможно Вы перешли по устаревшей ссылке или
                <br className="mob"/> набрали адрес несуществующей страницы.
            </p>
        </div>
        {chooseYourLookItems?.length && <ChooseYourLook items={chooseYourLookItems}/>}

        <HorizontalModelSlider modelColorItems={getFilteredAndRandomizeModels()} header={'Вам может понравиться'}/>
    </section>)
}