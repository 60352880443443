import {useForm} from "react-hook-form";
import ModelPropertyFilterInterface from "../../../interfaces/model/ModelPropertyFilterInterface";
import {useEffect} from "react";
import ModelSuperCategoryInterface from "../../../interfaces/model/ModelSuperCategoryInterface";
import {ModelFilterTagInterface} from "../../../interfaces/catalog/ModelFilterTagInterface";
import ModelCategoryInterface from "../../../interfaces/model/ModelCategoryInterface";


export default function FilterByPropertyTagForm({
                                                    properties,
                                                    selectedTag,
                                                    save
                                                }:
                                                    {
                                                        properties: ModelPropertyFilterInterface[],
                                                        selectedTag: ModelFilterTagInterface | undefined,
                                                        save: (tag: ModelFilterTagInterface) => void
                                                    }) {

    const {register, handleSubmit, reset, formState: {errors}} = useForm({
        shouldUseNativeValidation: true
    });

    useEffect(() => {
        reset(selectedTag)
    }, [selectedTag]);

    const onSubmit = (data: any) => {
        const modelPropertyTagData = JSON.parse(JSON.stringify(data))
        modelPropertyTagData.params = JSON.parse(JSON.stringify(selectedTag?.params))
        save({...selectedTag, ...modelPropertyTagData})
    }

    const getProperties = () : {name: string, values: any[]}[] => {
        if (!selectedTag?.params?.properties) {
            return [];
        }

        return selectedTag?.params?.properties.map((entity: any) => {
            return {
                name: properties.find(property => property.id === entity[0])?.name,
                values: entity[1]
            }
        })

    }

    const hasFilters = () => {
        return selectedTag?.params?.categories?.length > 0
        || selectedTag?.params?.superCategories?.length > 0
        || selectedTag?.params?.colors?.length > 0
        || selectedTag?.params?.sizes?.length > 0
        || (selectedTag?.params?.priceRange?.from || selectedTag?.params?.priceRange?.to)
        || getProperties().length > 0;
    }

    return (<div>
        <form className="form--big" onSubmit={handleSubmit(onSubmit)}>
            <div className="row  mt-1">
                <div className="col-md-5">
                    <input type="text" placeholder="Короткий адрес"
                           className={'form-control ' + (errors.slug ? ' is-invalid' : '')}  {...register("slug", {required: true, pattern: /^[a-z\-\_\d]+$/})}/>
                </div>
                <div className="col-md-5">
                    <input type="text" placeholder="Тег"
                           className={'form-control ' + (errors.name ? ' is-invalid' : '')}  {...register("name", {required: true})}/>
                </div>
                <div className="col-md-2">

                    <input type="text" placeholder="Sort"
                           className={'form-control ' + (errors.sort_id ? ' is-invalid' : '')}  {...register("sort_id", {pattern: /^\d+$/})}/>
                </div>
            </div>


            <div className="row  mt-1">
                <div className="col-md-12">
                    <input type="text" placeholder="Заголовок H1"
                           className={'form-control ' + (errors.html_header ? ' is-invalid' : '')}  {...register("html_header", {required: true})}/>
                </div>
            </div>

            <div className="row  mt-1">
                <div className="col-md-12">
                    <input type="text" placeholder="Title"
                           className={'form-control  ' + (errors.title ? ' is-invalid' : '')}  {...register("title")}/>
                </div>
            </div>

            <div className="row  mt-1">
                <div className="col-md-12">
                            <textarea
                                placeholder={'Description'}
                                style={{height: '8rem'}}
                                className={'form-control mt-1 form-con-p ' + (errors.description ? ' is-invalid' : '')}  {...register("description")}
                            />
                </div>
            </div>

            <div className="row  mt-1">
                <div className="col-md-12">
                            <textarea
                                placeholder={'Seo Text'}
                                style={{height: '8rem'}}
                                className={'form-control mt-1 form-con-p ' + (errors.seo ? ' is-invalid' : '')}  {...register("seo_text")}
                            />
                </div>
            </div>

            {hasFilters() && <div className="row  mt-1">
                <div className="col-md-12">
                    <button className={'btn btn-secondary'} type={'submit'}>{selectedTag?.id ? 'Сохранить' : 'Создать'}</button>
                </div>
            </div>}
            {!hasFilters() && <div className="row  mt-1">
                <div className="col-md-12">
                    <div className="alert alert-danger" role="alert">
                        Необходимо указать хотя бы один фильтр в форме выбора фильтров
                    </div>
                </div>
            </div>}

        </form>

        <hr/>
        <div>
            <h5>Фильтры</h5>

            <div className="row">
                {selectedTag?.params.categories?.length > 0 &&
                    <div
                        className={'col-6'}>Категории: {selectedTag?.params.categories.map((category: ModelCategoryInterface) =>
                        <span key={category.id}
                            className={'badge text-bg-secondary m-1'}>{category.name}</span>)}</div>}
                {selectedTag?.params.superCategories?.length > 0 &&
                    <div
                        className={'col-6'}>Супер категории: {selectedTag?.params.superCategories.map((superCategory: ModelSuperCategoryInterface) =>
                        <span key={superCategory.id}
                            className={'badge text-bg-secondary m-1'}>{superCategory.name}</span>)}</div>}
                {selectedTag?.params.colors?.length > 0  &&
                    <div className={'col-6'}>Цвета: {selectedTag?.params.colors.map((color: { color: string }) => <span key={color.color}
                        className={'badge text-bg-secondary m-1'}>{color.color}</span>)}</div>}
                {selectedTag?.params.sizes?.length > 0  &&
                    <div className={'col-6'}>Размеры: {selectedTag?.params.sizes.map((size: string) => <span key={size}
                        className={'badge text-bg-secondary m-1'}>{size}</span>)}</div>}
                {(selectedTag?.params.priceRange?.from || selectedTag?.params.priceRange?.to) &&
                    <div className={'col-6'}>Цена: <span
                        className={'badge text-bg-secondary m-1'}>{selectedTag?.params.priceRange?.from} - {selectedTag?.params.priceRange?.to} руб.</span>
                    </div>}

                {getProperties().length > 0 &&
                    <>{getProperties().map(property =>
                        <div key={property.name} className={'col-6'}>{property.name}:
                            {property.values.map((vname: string) => <span key={vname + '-' + property.name}
                                                                          className={'badge text-bg-secondary m-1'}>{vname}</span>)}
                        </div>)}</>}

            </div>
        </div>
        <hr/>
    </div>)
}