import Moment from "react-moment";
import React, {useContext, useEffect, useRef, useState} from "react";
import {OrderPositionGroupedByModelInterface} from "../../interfaces/dealer/OrderPositionGroupedByModelInterface";
import Money from "../core/Money";
import {OrderPositionInterface} from "../../interfaces/dealer/OrderPositionInterface";
import {useReactToPrint} from "react-to-print";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {OutletInterface} from "../../interfaces/dealer/OutletInterface";
import {Col, Row} from "react-bootstrap";
import {OrderInterface as CreateOrderInterface} from "../../interfaces/dealer/create/OrderInterface";
import ModelInterface from "../../interfaces/model/ModelInterface";
import {ColorInterface} from "../../interfaces/dealer/ColorInterface";
import {getSingleOrder, sendOrder} from "../../api/dealer";
import {ManagerInterface} from "../../interfaces/dealer/ManagerInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {OrderInterface} from "../../interfaces/dealer/OrderInterface";
import {SingleOrderResponseInterface} from "../../interfaces/dealer/SingleOrderResponseInterface";
import OrderStatistic from "./OrderStatistic";

export default function OrderSingle() {

    const outletData = useOutletContext<OutletInterface>();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [orderData, setOrderData] = useState<SingleOrderResponseInterface | undefined>(undefined)
    const [orderStructure, setOrderStructure] = useState<OrderPositionGroupedByModelInterface | undefined>(undefined)

    const queryData = useParams<{ orderId: string }>();

    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({
        contentRef,
        preserveAfterPrint: true,
        bodyClass: 'print-body'
    });

    const navigate = useNavigate();

    const [selectedManager, setSelectedManager] = useState<ManagerInterface | undefined>(undefined)

    useEffect(() => {
        if(!queryData.orderId){
            return
        }
        dispatch({
            type: ReducerActionType.LOAD
        })
        getSingleOrder(parseInt(queryData.orderId)).then((response) => {
            setOrderData(response)
            setOrderStructure(convertOrderToStructure(response))
            dispatch({
                type: ReducerActionType.SUCCESS
            })

        }, error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'При загрузке заказа возникла ошибка: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }, [queryData.orderId]);

    const getTotalByPosition = (position: OrderPositionInterface) => {
        const total = position.quantity * position.price_object.amount
        return {amount: total, currency: position.price_object.currency}
    }

    const getTotalQuantityByPositions = (positions: OrderPositionInterface[]) => {
        let quantity = 0

        positions.forEach((postion) => {
            quantity += postion.quantity
        })
        return quantity
    }

    const getTotalByPositions = (positions: OrderPositionInterface[]) => {
        let amount = 0

        positions.forEach((postion) => {
            amount += postion.price_object.amount * postion.quantity
        })
        return {amount: amount, currency: positions[0].price_object.currency}
    }

    const getTotalQuantity = () => {
        if(!orderStructure) return 0;

        let quantity = 0

        orderStructure.order.positions.forEach((postion) => {
            quantity += postion.quantity
        })
        return quantity

    }

    const convertOrderToStructure = (data: SingleOrderResponseInterface): OrderPositionGroupedByModelInterface => {

        let groupedPositions = new Map<string, OrderPositionInterface[]>();

        data.order?.positions.forEach(position => {
            // @ts-ignore
            if (data?.relation[position.model_item_id]) {
                // @ts-ignore
                const modelId = data?.relation[position.model_item_id]

                let currentPositions = groupedPositions.get(modelId) || []
                currentPositions.push(position)
                groupedPositions.set(modelId, currentPositions)
            }
        })

        let orderStructure: { model: ModelInterface, positions: OrderPositionInterface[] }[] = [];

        groupedPositions.forEach((value, key) => {

            //@ts-ignore
            const model: ModelInterface = data?.models[key]
            if (model) {
                orderStructure.push({
                    model: model,
                    positions: value
                })
            }
        })

        return {order: data.order, groupedPositions: orderStructure}
    }

    const getTotal = () => {
        if(!orderStructure) return {amount: 0, currency: {name: 'RUB'}}

        let amount = 0

        orderStructure.order.positions.forEach((postion) => {
            amount += postion.price_object.amount * postion.quantity
        })

        return {amount: amount, currency: orderStructure.order.positions[0].price_object.currency}
    }

    const returnToListLocal = () => {
        navigate('/dealer/orders')

    }

    const edit = () => {
        if(!orderData) return

        const order = orderData.order

        const orderForEdit: CreateOrderInterface = {
            id: order.id,
            comment: order.comment,
            manager: order.manager,
            positions: order.positions.map(position => {
                // @ts-ignore
                const model = orderData?.models[orderData?.relation[position.model_item_id as string]] as ModelInterface

                return {
                    model: model,
                    modelItemId: position.model_item_id,
                    size: position.size,
                    color: {
                        color: position.color,
                        hex: position.hex,
                        special: position.color_special,
                        humanName: ''
                    } as ColorInterface,
                    quantity: position.quantity,
                    quantityOriginal: position.quantity
                }
            })
        }
        outletData.setCurrentOrder(orderForEdit);
        navigate("/dealer");
    }

    const send = (manager: ManagerInterface) => {
        if(!orderStructure) return

        sendOrder(orderStructure.order, manager).then((response: any) => {
            let order = orderStructure.order
            order.status = response.status
            order.manager = response.manager
            setOrderStructure({...orderStructure})
        }).catch(error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'При создании заказа возникла ошибка: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }

    return (
        <>
            <div className="col-md-6 offset-md-3">
                <div className={'my-3'}>
                    <button className={'btn btn-sm btn-secondary btn-small'} onClick={() => {
                        reactToPrintFn()
                    }}>Печать
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className={'btn btn-sm btn-secondary-tr  btn-small'} onClick={() => {
                        edit()
                    }}>редактировать
                    </button>
                    <div className="float-end">
                        <button className={'btn btn-secondary btn-small'} onClick={returnToListLocal}>&larr; к списку
                            заказов
                        </button>
                    </div>
                </div>

                {orderStructure && <div ref={contentRef} style={{width: '100%'}}>
                    {orderStructure.order && <><h1>Информация о заявке №{orderStructure.order.id} от <Moment
                        format={"DD.MM.YYYY HH.mm"}>{orderStructure.order.create_date}</Moment></h1>
                        {orderStructure.order.status &&
                            <div><b>Статус:</b> <span>{orderStructure.order.status.name}</span></div>}
                        <div><b>Комментарий:</b> <span>{orderStructure.order.comment}</span></div>
                        {orderStructure.order.manager &&
                            <div><b>Менеджер:</b> <span>{orderStructure.order.manager.fio}</span></div>}
                    </>}

                    {orderStructure.order.status.id === 1 && <Row>
                        <Col md={6} className={'my-4'}>
                            <b>Менеджер</b>
                            <select className={'form-control'} onChange={(event) => {
                                const manager = outletData.ordersData.managers.find(manager => manager.id === parseInt(event.currentTarget.value))
                                if (manager) {
                                    setSelectedManager(manager)
                                }
                            }}>
                                <option value="">-- выберите менеджера --</option>
                                {outletData.ordersData.managers.map(manager => <option key={manager.id}
                                                                                       value={manager.id}>{manager.fio}</option>)}
                            </select>
                            <button className={'btn btn-secondary btn-small mt-1'} onClick={(event) => {
                                if (!selectedManager) {
                                    alert('Необходимо укаать менеджера, которому отправляется заявка');
                                    return
                                }
                                send(selectedManager)
                            }}>Отправить
                            </button>
                        </Col>
                    </Row>}

                    <div className={'d-none d-md-block mt-2'}>
                        <table className="table ">
                            <thead>
                            <tr>
                                <th>Цвет</th>
                                <th>Размер</th>
                                <th className={'text-end'}>Цена</th>
                                <th className={'text-end'}>Количество</th>
                                <th className={'text-end'}>Всего</th>
                            </tr>
                            </thead>
                            {orderStructure.groupedPositions.map((position, index) => <tbody key={index}>
                                <tr className={'h4'}>
                                    <th colSpan={5}>{position.model.brand} &rarr; {position.model.articul} &rarr; {position.model.name}</th>
                                </tr>
                                {position.positions.map(sposition => {
                                    const printImage = position.model.images.find(image => image.special_color_name === sposition.color_special && image.type === 'print')

                                    return <tr key={sposition.id}>
                                        <td>
                                            <div className={'dealer-color-order-position d-print-none'}
                                                 style={printImage ? {background: 'url(' + printImage.url + ')', backgroundSize: 'cover'} : {backgroundColor: sposition.hex}}></div>
                                            {sposition.color} </td>
                                        <td>{sposition.size}</td>
                                        <td className={'text-end'}><Money value={sposition.price_object}/></td>
                                        <td className={'text-end'}>{sposition.quantity}</td>
                                        <td className={'text-end'}><Money value={getTotalByPosition(sposition)}/></td>
                                    </tr>
                                })}
                                <tr className={'h6 '} style={{borderTopWidth: '3px'}}>
                                    <th colSpan={3}>ИТОГО:</th>
                                    <th className={'text-end'}>{getTotalQuantityByPositions(position.positions)}</th>
                                    <th className={'text-end'}><Money value={getTotalByPositions(position.positions)}/>
                                    </th>
                                </tr>
                                <tr className={'d-print-none'}>
                                    <td colSpan={5}>&nbsp;</td>
                                </tr>
                                </tbody>
                            )}
                            <tbody>
                            <tr className={'h5'} style={{borderTopWidth: '3px'}}>
                                <th colSpan={3}>ИТОГО ПО ЗАЯВКЕ:</th>
                                <th className={'text-end'}>{getTotalQuantity()}</th>
                                <th className={'text-end'}><Money value={getTotal()}/></th>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                    <div className={'d-block d-md-none'}>
                        <table className="table mt-5 d-block d-md-none">
                            <thead>
                            <tr>
                                <th>Цвет</th>
                                <th>Размер</th>
                                <th className={'text-end'}>&nbsp;</th>
                            </tr>
                            </thead>
                            {orderStructure.groupedPositions.map((position, index) => <tbody key={index}>
                            <tr>
                                <td colSpan={3}>&nbsp;</td>
                            </tr>
                            <tr className={'h4'}>
                                <td colSpan={3}>{position.model.brand} &rarr; {position.model.articul} &rarr; {position.model.name}</td>
                            </tr>
                            {position.positions.map(position => <tr key={position.id}>
                                <td>
                                    {position.color} </td>
                                <td>{position.size}</td>
                                <td className={'text-end'} style={{whiteSpace: 'nowrap'}}>
                                    {position.quantity} / <Money value={getTotalByPosition(position)}/>
                                </td>
                            </tr>)}
                            <tr className={'h6 '} style={{borderTopWidth: '3px'}}>
                                <th colSpan={2}>ИТОГО:</th>
                                <th className={'text-end'}
                                    style={{whiteSpace: 'nowrap'}}>{getTotalQuantityByPositions(position.positions)} / <Money
                                    value={getTotalByPositions(position.positions)}/></th>
                            </tr>
                            </tbody>)}
                            <tbody>
                            <tr className={'h5 '} style={{borderTopWidth: '3px'}}>
                                <th colSpan={2}>ИТОГО ПО ЗАЯВКЕ:</th>
                                <th className={'text-end'}
                                    style={{whiteSpace: 'nowrap'}}>{getTotalQuantity()} / <Money
                                    value={getTotal()}/>
                                </th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
            <hr className={'my-5'} />
            {orderData && <OrderStatistic order={orderData.order} relation={orderData?.relation || []} models={orderData?.models || []} />}
        </>
    )
}