import Properties from "./Properties";
import ModelInterface from "../../../interfaces/model/ModelInterface";
import {useState} from "react";

export default function PropertyList({model}: { model: ModelInterface }) {
    const [isShow, setIsShow] = useState(true)

    return (<div className={"product__kv-info-cols " + (isShow && "show")}>
        <p className={"product__kv-info-cols-link f-exp " + (isShow && "active")}>О товаре <i  className="ico ico-arrow-bot-b" onClick={() => {setIsShow(!isShow)}}></i></p>
        {isShow && <div className="product__kv-info-cols-list">
            {model && <Properties model={model} mode={"prop"}/>}
        </div>}
    </div>)
}