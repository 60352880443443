export const objclone = (val: any) => {
    return JSON.parse(JSON.stringify(val))
}

export const isEmailValid = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/
        );
};