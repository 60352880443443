import Spacer from '../Spacer/Spacer.jsx'
import styles from './GridImages.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";

const GridImages = props => {
	const images = props.params.images
	return (
		<>
			<div className='mblock text-center '>
				<div className={styles.gridImages}>
					{images.map((image, i) => (
						<img className={styles.gridImage} src={getBaseImagePath() + image.img} alt={image.img} key={i} />
					))}
				</div>
			</div>
			<Spacer />
		</>
	)
}

export default GridImages
