import Contacts from "./information-page/Contacts";
import About from "./information-page/About";
import Cooperation from "./information-page/Cooperation";
import Vacancies from "./information-page/Vacancies";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../utils/Utils";
import Breadcrumbs from "./Breadcrumbs";

export enum PageType {
    ABOUT = 'about',
    COOPERATION = 'cooperation',
    VACANCIES = 'vacancies',
    CONTACTS = 'contacts'
}

export default function InformationPage({type}: {type: string}) {


    const getBreadcrumbs = () => {
        const getName = (type: string) => {
            switch(type){
                case PageType.ABOUT:
                    return 'О компании';
                case PageType.COOPERATION:
                    return 'Сотрудничество';
                case PageType.VACANCIES:
                    return 'Вакансии';
                case PageType.CONTACTS:
                    return 'Контакты';
            }
        }


        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: getName(type)
            }
        ]
    }


    return (<main className="main-company">
            <section className="page-company pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs()} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h1 className="page-company__title f-exp">О компании</h1>
                            {/*<div className="page-company__tabs">*/}
                            {/*    <ul className="nav nav-tabs f-exp" id="myTab" role="tablist">*/}
                            {/*        <li className="nav-item" >*/}
                            {/*            <Link to={'/about'} className={"btn nav-link " + (type === PageType.ABOUT && 'active')}>О бренде</Link>*/}
                            {/*        </li>*/}
                            {/*        <li className="nav-item" >*/}
                            {/*            <Link to={'/cooperation'} className={"btn nav-link " + (type === PageType.COOPERATION && 'active')}>Сотрудничество</Link>*/}
                            {/*        </li>*/}
                            {/*        <li className="nav-item" >*/}
                            {/*            <Link to={'/vacancies'} className={"btn nav-link " + (type === PageType.VACANCIES && 'active')}>Вакансии</Link>*/}
                            {/*        </li>*/}
                            {/*        <li className="nav-item" >*/}
                            {/*            <Link to={'/contacts'} className={"btn nav-link " + (type === PageType.CONTACTS && 'active')}>Контакты</Link>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="tab-content">
                        {type === PageType.ABOUT && <About/>}
                        {type === PageType.COOPERATION && <Cooperation/>}
                        {type === PageType.VACANCIES && <Vacancies/>}
                        {type === PageType.CONTACTS && <Contacts/>}
                    </div>
                </div>
            </section>

        </main>
    )
}