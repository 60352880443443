import Promotions from "../core/promotions/Promotions";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {unsubscribeByHex} from "../../api/customer";
import {useQuery} from "@tanstack/react-query";

export default function UnsubscribeByHex() {
    const [searchParams] = useSearchParams()

    useEffect(() => {
        console.log(searchParams.get('direct-crm-ticket'))
    }, [searchParams.get('direct-crm-ticket')]);

    const {data, status} = useQuery({
        queryKey: [...["unsubscribe-by-hex"]],
        queryFn: () => unsubscribeByHex(searchParams.get('direct-crm-ticket') || ''),
        enabled: !!searchParams.get('direct-crm-ticket'),
        retry: false
    });

    return (<main >
            <section style={{marginTop: '100px'}}>
                <div className="container-fluid text-center">
                    {status === 'success' && <><h1>Вы отписаны от рассылок</h1><div><a href="/">Перейти к покупкам</a></div></> }
                    {(status === 'error' ) && <><h1>В процессе отписки произошла ошибка</h1></>}
                    {(!searchParams.get('direct-crm-ticket')) && <><h1>В процессе отписки произошла ошибка</h1><div>Не указан параметр direct-crm-ticket</div></>}
                    {status === 'pending' && searchParams.get('direct-crm-ticket') && <><h1>Подождите...</h1></>}
                </div>
                <Promotions isEmbedded={true} />
            </section>
        </main>
    )
}