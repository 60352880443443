import LinkInterface from "../../interfaces/core/LinkInterface";
import {Swiper, SwiperSlide} from "swiper/react";

import {EffectFade} from "swiper/modules";
import {Autoplay, Pagination} from "swiper/modules";


interface MenuProps {
    data: LinkInterface[];
}

export default function TopStringBanner({data}: MenuProps) {
    return (
        <div className="head-line">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Swiper
                            pagination={{
                                el: ".dots",
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 3000,
                            }}
                            modules={[Autoplay, Pagination, EffectFade]}
                            effect={"fade"}
                            fadeEffect={{crossFade: true}}
                            loop={true}
                            className="head-line__slider"
                        >
                            {data &&
                                data.map((item, index) => (
                                    <SwiperSlide className="slide" key={index}>                       
                                        <a href={item.url} className="f-exp">
                                            {item.name}
                                        </a>
                                    </SwiperSlide>
                                ))}

                            <div className="head-line-bullets">
                                <div className="dots"> </div>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}
