import {useContext, useEffect, useRef, useState} from "react";
import ModelListCard from "../catalog/ModelListCard";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {useQuery} from "@tanstack/react-query";
import {getModelBySearchString} from "../../api/catalog";
import {MAX_MODEL_PER_SEARCH, SEARCH_INPUT_ADD_TO_HISTORY_DELAY, SEARCH_INPUT_DELAY} from "../../Config";
import {useNavigate} from "react-router-dom";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function SearchBoard() {
    const [searchStringDelayed, setSearchStringDelayed] = useState('')
    const [searchString, setSearchString] = useState('')

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const navigate = useNavigate();

    const addToSearchHistory = (searchString: string) => {
        let searchHistory = (state.searchHistory || [])
        searchHistory.unshift(searchString)

        searchHistory = searchHistory.filter((value: any, index: number, array: any) => {
            return array.indexOf(value) === index && value.length > 3;
        })

        dispatch({
            type: ReducerActionType.SET_PARAM,
            paramName: 'searchHistory',
            payload: searchHistory
        })
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => setSearchStringDelayed(searchString), SEARCH_INPUT_DELAY);
        return () => clearTimeout(timeOutId);
    }, [searchString]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            addToSearchHistory(searchString)
        }, SEARCH_INPUT_ADD_TO_HISTORY_DELAY);
        return () => clearTimeout(timeOutId);
    }, [searchString]);

    const {data: modelColorItemsData, status} = useQuery({
        queryKey: ['catalog', 'search', searchStringDelayed, MAX_MODEL_PER_SEARCH],
        queryFn: () => getModelBySearchString(searchString, MAX_MODEL_PER_SEARCH)
    })

    const showAll = () => {
        addToSearchHistory(searchString)
        dispatch({
            type: ReducerActionType.SET_PARAM,
            paramName: 'showSearchBoard',
            payload: false
        })
        navigate("/catalog/search/")
    }

    const {
        model_color_items: modelColorItems,
        recently_search: recentlySearch
    } = modelColorItemsData || {
        model_color_items: [],
        recently_search: []
    }

    return (<div className="search-board">
        <div className="search-board__form">
            <div className="container-fluid">
                <form action="" onSubmit={(event) => {
                    event.preventDefault()
                }} >
                    <div className="form-group">
                        <a className="form-control__search ico ico-search" href=""></a>
                        <input className="form-control" inputMode={"search"} type="text" value={searchString}
                               placeholder="Поиск" onChange={(event) => {
                            setSearchString(event.currentTarget.value)
                        }} onKeyUp={(event) => {
                            if(event.key === 'Enter' && modelColorItems?.length > 0){
                                showAll()
                            }
                        }} />
                        <a className="form-control__clear" href="/">очистить</a>
                    </div>
                    <i className="search-board__close ico ico-close" onClick={() => {
                        dispatch({type: ReducerActionType.TOGGLE_SEARCH_BOARD})
                    }}></i>
                </form>
            </div>
        </div>
        <div className="search-board__cont">
            <div className="search-board__cont-empty" style={{display: "block"}}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-2_5">
                            <div className="search-board__cont-menu">
                                {recentlySearch && recentlySearch.length > 0 && <>
                                    <h5 className="f-exp">Часто ищут</h5>
                                    <ul className="menu--empty">
                                        {recentlySearch && recentlySearch.map(searchString => <li
                                            key={searchString.request}><a href="/"
                                                                          onClick={(event) => {
                                                                              event.preventDefault()
                                                                              setSearchString(searchString.request)
                                                                              setSearchStringDelayed(searchString.request)
                                                                          }}>{searchString.request}</a>
                                        </li>)}
                                    </ul>
                                </>}
                                <h5 className="f-exp">Ранее вы искали</h5>
                                <ul className="menu--searching">
                                    {state.searchHistory.slice(0, 8).map((searchItem: string) => <li key={searchItem}><a href="/"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault()
                                                                                                setSearchString(searchItem)
                                                                                                setSearchStringDelayed(searchItem)
                                                                                            }}>{searchItem}</a></li>)}
                                </ul>
                            </div>
                        </div>
                        <div className="col-9_5 search-board__cont--catalog">
                            {searchString.length > 3 && modelColorItems?.length === 0 && status !== 'pending' &&
                                <h5 className="f-exp">Упс! По вашему запросу ничего не найдено!</h5>}
                            {searchString.length <= 3 && modelColorItems?.length > 0 &&
                                <h5 className="f-exp">Вам могут понравиться:</h5>}
                            {searchString.length > 3 && modelColorItems?.length > 0 &&
                                <h5 className="f-exp">Вот что мы нашли:</h5>}
                            <div className="row">
                                {modelColorItems?.length > 0 && modelColorItems.slice(0,5).map(modelColorItem => <div key={modelColorItem.id} className="col-20">
                                    <ModelListCard
                                        modelColorItem={modelColorItem}/></div>)}
                            </div>
                            {state.searchHistory.length > 0 && modelColorItems?.length > 0 && <div className="btns">
                                <button className="btn btn-secondary" onClick={(event) => {
                                    event.preventDefault()
                                    showAll()
                                }}>Показать все
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}