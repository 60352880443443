import {useContext, useEffect, useState} from "react";
import Breadcrumbs from "../Breadcrumbs";
import {useQuery} from "@tanstack/react-query";
import {getShops} from "../../../api/dictionary";
import {MetroStationInterface} from "../../../interfaces/core/MetroStationInterface";
import {RegionInterface} from "../../../interfaces/core/RegionInterface";
import {SimpleItemInterface} from "../../../interfaces/core/SimpleItemInterface";
import {MOSCOW_REGION_ID, SPB_REGION_ID} from "../../../Config";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../../GlobalReducer";
import ShopMap from "./ShopMap";
import {Link} from "react-router-dom";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';


type SelectType = "cities" | "metro" | "type"

export default function ShopList() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [city, setCity] = useState<RegionInterface | null>(null)
    const [metroStations, setMetroStations] = useState<MetroStationInterface[]>([])
    const [shopTypes, setShopTypes] = useState<SimpleItemInterface[]>([])

    const [currentSelect, setCurrentSelect] = useState<SelectType | null>(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const {data, status} = useQuery({
        queryKey: [...['shop', 'list']],
        queryFn: () => getShops()
    })

    const {shops, meta, breadcrumbs} = data || {}

    useEffect(() => {
        if(!shops?.length){
            return
        }

        const regionHasShops = shops.filter(shop => shop.region && shop.region.id === state.region.id).length > 0

        setCity(regionHasShops ? state.region : null)
    }, [shops]);

     useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const getUniqueMetroStations = (): MetroStationInterface[] => {
        if (!shops) {
            return []
        }
        let metrostations: Map<number, MetroStationInterface> = new Map()
        shops.filter(shop => shop.extra_param?.metro_station).map(shop => {
            metrostations.set(shop.extra_param.metro_station.id, shop.extra_param.metro_station)
        })
        return Array.from(metrostations, ([name, value]) => value)
    }

    const getUniqueCities = (): RegionInterface[] => {
        if (!shops) {
            return []
        }
        let cities: Map<string, RegionInterface> = new Map()
        shops.filter(shop => shop.region).map(shop => {
            if (shopTypes.length && !shopTypes.find(st => st.id === shop?.extra_param?.category_id)) {
                return
            }
            cities.set(shop.region.id, shop.region)
        })
        let cityArray = Array.from(cities, ([name, value]) => value).sort((a, b) => {
            return a.name > b.name ? 1 : -1
        })

        return [...cityArray.filter(city => city.id == SPB_REGION_ID || city.id == MOSCOW_REGION_ID), ...cityArray.filter(city => !(city.id == SPB_REGION_ID || city.id == MOSCOW_REGION_ID))]
    }

    const getUniqueTypes = (): SimpleItemInterface[] => {
        if (!shops) {
            return []
        }
        let types: Map<number, SimpleItemInterface> = new Map()
        shops.filter(shop => shop.extra_param.category_id).map(shop => {
            if (city && city.id !== shop.region.id) {
                return
            }

            types.set(shop.extra_param.category_id, {
                id: shop.extra_param.category_id,
                name: shop.extra_param.category_name
            })
        })
        return Array.from(types, ([name, value]) => value)
    }

    useEffect(() => {
        // @ts-ignore
        // $('.scrollbar-inner').scrollbar({"scrollx": "advanced", "scrolly": "advanced"})
    }, [currentSelect]);


    const getFilteredShops = () => {
        let selectedShops = shops

        if (city) {
            selectedShops = selectedShops?.filter(shop => shop.region_id === city.id)
        }

        if (city?.id === MOSCOW_REGION_ID && metroStations?.length) {
            selectedShops = selectedShops?.filter(shop => metroStations.find(mst => mst.id === shop?.extra_param?.metro_station?.id))
        }

        if (shopTypes?.length) {
            selectedShops = selectedShops?.filter(shop => shopTypes.find(st => st.id === shop?.extra_param?.category_id))
        }

        return selectedShops?.length ? selectedShops : shops
    }


    const renderSimpleBar = (children: any, maxHeightLarge: number, maxHeightSmall: number) => {
        return windowWidth >= 991 ? (
            <SimpleBar style={{ maxHeight: maxHeightLarge }} autoHide={false}>
                {children}
            </SimpleBar>
        ) : (
            <div style={{ maxHeight: maxHeightSmall, overflowY: 'auto' }}>
                {children}
            </div>
        );
    };

    return (<main className="main-shops">
            <section className="shops pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={breadcrumbs || []}/>
                        </div>
                    </div>
                </div>
                <div className="shops__map" style={{display: "block"}}>
                    <div className="container-fluid">
                        <h2 className="f-exp desk">Магазины Baon</h2>
                        <div className="row">
                            <div className="col-lg-4 shops__map-info">
                                <h2 className="f-exp mob">Магазины Baon</h2>
                                <form className="form--big" action="">
                                    <div className="form-wrap">
                                        <div className="form-selector big">
                                            <a className="form-selector--btn f-exp" href="/" onClick={(event) => {
                                                event.preventDefault();
                                                setCurrentSelect(currentSelect === 'cities' ? null : 'cities')
                                            }}>
                                                <span>{city?.name || 'Ваш город'}</span>
                                            </a>
                                            {currentSelect === "cities" && <div
                                                className="form-selector--list"
                                            >
                                                {renderSimpleBar(
                                                <div className="scrollbar-inner">
                                                    <ul>
                                                        <li>
                                                            <a href="/" onClick={(event) => {
                                                                event.preventDefault()
                                                                setCity(null)
                                                                setCurrentSelect(null)
                                                                setShopTypes([])
                                                            }}>-- все города --</a>
                                                        </li>
                                                        {getUniqueCities().map(city => <li key={city.id}>
                                                            <a href="/" onClick={(event) => {
                                                                event.preventDefault()
                                                                setCity(city)
                                                                setCurrentSelect(null)
                                                            }}>{city.name}</a>
                                                        </li>)}
                                                    </ul>
                                                </div>, 400, 250
                                                )}
                                            </div>}
                                        </div>
                                        {city?.id === MOSCOW_REGION_ID &&
                                            <div className="form-selector form-selector--colors">
                                                <a className="form-selector--btn f-exp" href="/" onClick={(event) => {
                                                    event.preventDefault();
                                                    setCurrentSelect(currentSelect === 'metro' ? null : 'metro')
                                                }}>
                                                    {/*<span>{metroStations?.join(',') || 'Метро'}</span>*/}
                                                    Метро
                                                </a>
                                                {currentSelect === "metro" && <div
                                                    className="form-selector--list"
                                                >
                                                    {renderSimpleBar(
                                                    <div className="scrollbar-inner">
                                                        <ul>
                                                            {getUniqueMetroStations().map(metroStation => <li
                                                                key={metroStation.id}>
                                                                <a className={"form-checkbox " + (metroStations?.find(metroStationIn => metroStationIn.id === metroStation.id) && ' active')}
                                                                   href='/' onClick={(event) => {
                                                                    event.preventDefault()
                                                                    if (metroStations?.find(metroStationIn => metroStationIn.id === metroStation.id)) {
                                                                        setMetroStations([...metroStations?.filter(metroStationIn => metroStationIn.id !== metroStation.id)])
                                                                    } else {
                                                                        let toAdd = (metroStations || [])
                                                                        toAdd.push(metroStation)
                                                                        setMetroStations([...toAdd])
                                                                    }
                                                                }}>
                                                                    <label className="label-dot"/>
                                                                    <label className="label-text"><i
                                                                        style={{background: "#CCCCCC"}}/> {metroStation.name}
                                                                    </label>
                                                                </a>
                                                            </li>)}
                                                        </ul>
                                                    </div>, 350, 280
                                                    )}
                                                </div>}
                                            </div>}
                                        {getUniqueTypes().length > 1 &&
                                            <div className="form-selector form-selector--checks big">
                                                <a className="form-selector--btn f-exp" href="/" onClick={(event) => {
                                                    event.preventDefault();
                                                    setCurrentSelect(currentSelect === 'type' ? null : 'type')
                                                }}>
                                                    <span>Тип магазина</span>
                                                </a>
                                                {currentSelect === "type" && <div
                                                    className="form-selector--list fs-custom-type"
                                                >
                                                    {getUniqueTypes().map(type => <div key={type.id}
                                                                                       className={"form-checkbox " + (shopTypes?.find(typeIn => typeIn.id === type.id) && ' active')}
                                                                                       onClick={(event) => {
                                                                                           event.preventDefault()
                                                                                           if (shopTypes?.find(typeIn => typeIn.id === type.id)) {
                                                                                               setShopTypes([...shopTypes?.filter(typeIn => typeIn.id !== type.id)])
                                                                                           } else {
                                                                                               let toAdd = (shopTypes || [])
                                                                                               toAdd.push(type)
                                                                                               setShopTypes([...toAdd])
                                                                                           }
                                                                                       }}>
                                                        <label className="label-dot"/>
                                                        <label className="label-text">{type.name}</label>
                                                    </div>)}
                                                </div>}
                                            </div>}
                                    </div>
                                </form>
                                <div className="shops__map-info-points">
                                     {renderSimpleBar(
                                    <div className="scrollbar-inner">
                                        <ul>
                                            {getFilteredShops()?.map(shop => <li key={shop.id}>
                                                <Link to={'/shop/' + shop.id}>
                                                    <h4 className="f-exp">
                                                        {shop.extra_param.name || shop.name}
                                                    </h4>
                                                    <p>{shop.extra_param.address || shop.address} {shop.extra_param.address2 && ', ' + shop.extra_param.address2}</p>
                                                    <p>{shop.extra_param.operation_time || shop.operation_time}</p>
                                                </Link>
                                            </li>)}
                                        </ul>
                                    </div>, 490, 450
                                    )}
                                </div>
                            </div>
                            <ShopMap shops={getFilteredShops() || []} allShopVisible={getFilteredShops()?.length === shops?.length}/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}