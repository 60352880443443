import {useOutletContext} from "react-router-dom";
import {CustomerCredentialResponse} from "../../../interfaces/api/CustomerCredentialResponseInterface";
import Money from "../../core/Money";
import Moment from "react-moment";
import CodeWord from "./CodeWord";

export default function Bonus() {
    const data = useOutletContext<CustomerCredentialResponse>();

    return (<div className="tab-pane fade active show">
            <h3 className="lk__title f-exp desk">Бонусы и промокоды</h3>
            {/*<div className="lk__main">*/}
            {/*    <div className="lk__main-banner">*/}
            {/*        <picture>*/}
            {/*            <source*/}
            {/*                srcSet="/img/main-banner.jpg"*/}
            {/*                media="(max-width: 1024px)"*/}
            {/*            />*/}
            {/*            <img src="/img/main-banner.jpg" alt=""/>*/}
            {/*        </picture>*/}
            {/*        <h4>до -70% на коллекцию SS’23</h4>*/}
            {/*    </div>*/}
            {/*</div>*/}
             <div className="row">
                <div className="col-lg-5">
                    {data.bonus_info && <div className="lk__bonus-card basic" style={{backgroundColor: data.bonus_info.level_card_color}}>
                        <ul className="top f-exp">
                            <li>
                                <h5>Карта лояльности</h5>
                            </li>
                            <li>
                                <h5>{data.bonus_info.level_human_name} ({data.bonus_info.profit_percent}%)</h5>
                            </li>
                        </ul>
                        <ul className="mid">
                            <li>
                                <p>Доступно баллов</p>
                                <h2 className="f-exp">{Math.round(data.bonus_info.active_balance)}</h2>
                            </li>
                            <li>
                                <p>В ожидании</p>
                                <h6 className="f-exp">{Math.round(data.bonus_info.wait_balance)}</h6>
                            </li>
                            <li>
                                <p>Списано</p>
                                <h6 className="f-exp">{Math.round(data.bonus_info.credit)}</h6>
                            </li>
                        </ul>
                        <ul className="bot">
                            <li>
                                <p>Куплено товаров</p>
                                <h6 className="f-exp">{data.bonus_info.discounted_sum} Р</h6>
                            </li>
                            {data.bonus_info.value_for_next_level_raw && <li>
                                <p>Следующий уровень</p>
                                <h6 className="f-exp"><Money value={data.bonus_info.value_for_next_level_raw.next_level_sum} /></h6>
                            </li>}
                        </ul>
                    </div>}
                    {data.bonus_history?.length > 0 && <div className="lk__bonus-score bd">
                        <h5 className="lk__bonus-score--title f-exp">Детализация</h5>
                        <div className="lk__bonus-score-list" style={{overflowY: "auto"}}>
                            <ul>
                                {data.bonus_history.filter(bonus => bonus.added || bonus.expired).map(bonus => <li className={bonus.added ? 'plus' : 'menus'}>
                                    <h4><Moment format={'DD.MM.YYYY'}>{bonus.created}</Moment></h4>
                                    <h5 className="f-exp">{bonus.description}</h5>
                                    {bonus.activationDate && <h6>Срок действия: <Moment format={'DD.MM.YYYY'}>{bonus.activationDate}</Moment> по <Moment format={'DD.MM.YYYY'}>{bonus.expirationDate}</Moment></h6>}
                                    {!bonus.activationDate && <h6>&nbsp;</h6>}
                                    <p className="f-exp">{bonus.added ? '+' : '-'}{bonus.added || bonus.expired}</p>
                                </li>)}
                            </ul>
                        </div>
                    </div>}
                    {data.bonus_error && <div className={'alert alert-danger mt-2'}>
                        {data.bonus_error}
                        {data.bonus_info && <div>Дата обновления: {data.bonus_info.last_update}</div>}
                    </div>}
                </div>
                <div className="col-lg-7">
                    <CodeWord />
                    {/*<div className="lk__bonus-banners">*/}
                    {/*    <div className="lk__bonus-banner">*/}
                    {/*        <picture>*/}
                    {/*            <source*/}
                    {/*                srcSet="img/temp/lk-bonus-banner-01.jpg"*/}
                    {/*                media="(max-width: 992px)"*/}
                    {/*            />*/}
                    {/*            <img src="/img/temp/lk-bonus-banner-01.jpg" alt=""/>*/}
                    {/*        </picture>*/}
                    {/*        <h3 className="f-exp">- 25% на блузки</h3>*/}
                    {/*        <p>промокод shirt25</p>*/}
                    {/*        <a className="btn btn-primary" href="">*/}
                    {/*            Скопировать промокод*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*    <div className="lk__bonus-banner">*/}
                    {/*        <picture>*/}
                    {/*            <source*/}
                    {/*                srcSet="img/temp/lk-bonus-banner-02.jpg"*/}
                    {/*                media="(max-width: 992px)"*/}
                    {/*            />*/}
                    {/*            <img src="/img/temp/lk-bonus-banner-02.jpg" alt=""/>*/}
                    {/*        </picture>*/}
                    {/*        <h3 className="f-exp">- 15% на whynot?</h3>*/}
                    {/*        <p>промокод notwhy?</p>*/}
                    {/*        <a className="btn btn-primary" href="">*/}
                    {/*            Скопировать промокод*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}