import {useContext, useEffect, useMemo, useState} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {BasketPositionResponseInterface} from "../../interfaces/checkout/BasketPositionResponseInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

function Bonus({activeBalance}:{activeBalance: number}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const positions: BasketPositionResponseInterface[] = state.positions;

    const [writeOffBonuses, setWriteOffBonuses] = useState(0)

    const loyaltyProcessingWriteOff = useMemo(() => {
        let writeOff = 0;

        if (state.customer) {
            positions.forEach(position => {
                if (position.loyalty_processing_calculation_result) {
                    writeOff += position.loyalty_processing_calculation_result.writeoff_bonus;
                }
            })
        } else {
            positions.forEach(position => {
                if (position.loyalty_calculation_result) {
                    writeOff += position.loyalty_calculation_result.write_off?.amount / 100;
                }
            })
        }

        return writeOff;
        // eslint-disable-next-line
    }, [state.positions])

    useEffect( () => {
        if(writeOffBonuses && writeOffBonuses > loyaltyProcessingWriteOff){
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'writeOffBonuses', payload: loyaltyProcessingWriteOff})
        }

        // eslint-disable-next-line
    }, [loyaltyProcessingWriteOff])



    return (<div className="basket__form bd">
            <h5 className="basket__form-title f-exp">Бонусы</h5>
            {!activeBalance && <h5 className={'text-center'}>Списание бонусов невозможно</h5>}
            {activeBalance > 0 && <div className="form-tumblers">
                <div className="form-tumbler vertical">
                    <input className="hidden" id="basket__bonus-tumbler" onChange={() => {}} type="checkbox" checked={state.writeOffBonuses > 0}/>
                    <label className={"label-dot "} onClick={() => {
                        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'writeOffBonuses', payload: state.writeOffBonuses ? 0 : loyaltyProcessingWriteOff})
                    }}>
                        <i/>
                    </label>
                    <label className="label-text" htmlFor="basket__bonus-tumbler">
                        <b>Использовать бонусы</b>Доступно {activeBalance}, можно списать {loyaltyProcessingWriteOff.toString()}
                    </label>
                </div>
            </div>}
        </div>
    )


}

export default Bonus