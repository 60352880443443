import {DeliveryAndPaymentInterface} from "../../../interfaces/api/DeliveryAndPaymentInterface";
import React, {useContext, useState} from "react";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../../GlobalReducer";
import Money from "../../core/Money";
import {DeliveryInterface} from "../../../interfaces/checkout/DeliveryInterface";
import {DeliveryTypeInterface} from "../../../interfaces/checkout/DeliveryTypeInterface";

export default function DeliveryInfo({deliveryAndPaymentData}: {
    deliveryAndPaymentData: DeliveryAndPaymentInterface[]
}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [isShow, setIsShow] = useState(true)

    const period = (delivery: DeliveryInterface) => {
        if (delivery.smart_period_name) {
            return delivery.smart_period_name
        }

        const minimumPeriod = delivery.first_customer_delivery_courier.period_from
        const maximumPeriod = delivery.first_customer_delivery_courier.period_to
        return (minimumPeriod === maximumPeriod ? minimumPeriod : minimumPeriod + '-' + maximumPeriod) + ' дн.'
    }

    const getIcon = (deliveryType: DeliveryTypeInterface) => {
        if (deliveryType.name.toLowerCase().includes('курьер')) {
            return "ico-car-2"
        } else if (deliveryType.name.toLowerCase().includes('пункт')) {
            return "ico-box"
        } else {
            return "ico-map"
        }
    }

    return (<div className={"product__kv-info-cols " + (isShow && "show")}>
        <p className={"product__kv-info-cols-link  f-exp " + (isShow && "active")}>Доставка в {state.region.clear_name}
            <i className="ico ico-arrow-bot-b" onClick={() => {setIsShow(!isShow)}}></i></p>
        {isShow && <div className="product__kv-info-cols-list">
            <ul>
                {deliveryAndPaymentData.map(item => <li>
                    <div className="icons"><i className={"ico " + getIcon(item.delivery_type)}></i></div>
                    <p>
                        <b>{item.delivery_type.name}</b>{period(item)} – {item.first_customer_delivery_courier.price.amount === 0 ? <>Бесплатно</> : <>от <Money
                        value={item.first_customer_delivery_courier.price}/></>}</p>
                </li>)}
            </ul>
        </div>}
    </div>)
}