import CooperationForm from "../CooperationForm/CooperationForm";
import {getBaseImagePath} from "../../../../ui/catalog/Utils";
import {useState} from "react";
import Spacer from '../Spacer/Spacer.jsx'

const AuthLandingForm = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsFormSubmitted(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal")) {
      setIsModalOpen(false);
    }
  };

  const handleFormSuccess = () => {
    setIsFormSubmitted(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setIsFormSubmitted(false);
    }, 3000);
  };

  return (
    <>
      <Spacer />
      <div className="text-center">
        <div className='mblock text-center l-fl-center' onClick={toggleModal}>
          <picture>
            <source media="(max-width: 768px)" srcSet={getBaseImagePath() + props.params.imgmob} />
            <img src={getBaseImagePath() + props.params.img} alt={props.params.img} />
          </picture>
        </div>
      </div>
      <Spacer />

      {isModalOpen && (
        <div
          className="modal fade modal-main modal-basket-wrapper show"
          tabIndex="-1"
          role="dialog"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "block",
          }}
          onClick={handleOutsideClick}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={toggleModal}
                aria-label="Close"
              >
              </button>
              <div className="modal-body">
                {isFormSubmitted ? (
                  <div className="text-center">
                    <h5>Форма успешно отправлена</h5>
                  </div>
                ) : (
                  <div className="modal-form">
                    <CooperationForm
                      params={props.params}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthLandingForm;