import Spacer from '../Spacer/Spacer.jsx'
import style from './GridThreeElems.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";

const GridThreeElems = props => {
	const elems = props.params.data
	return (
		<>
			<div className={style.rowBlock}>
				{elems.map(elem => (
					<div className={style.rowBlockElem} key={elem.id}>
						<img className={style.img} src={getBaseImagePath() + elem.img} alt={elem.img} />
						<div
							className={style.title}
							dangerouslySetInnerHTML={{__html: elem.title}}
						></div>
						<p
							dangerouslySetInnerHTML={{__html: elem.text}}
							className={'text-center ' + style.text}
						></p>
					</div>
				))}
			</div>
			<Spacer />
		</>
	)
}

export default GridThreeElems
