import {ProgressBar} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import {GlobalContext} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function HeaderProgressBar() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [currentPercent, setCurrentPercent] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPercent(currentPercent => {
                if (currentPercent >= 100) {
                    window.clearInterval(intervalId)
                    return 100
                }

                if (currentPercent >= 50 && currentPercent <= 80) {
                    return currentPercent + 0.1
                }else if (currentPercent > 80) {
                    return currentPercent + 0.05
                }else{
                    return currentPercent + 1
                }

            })
        }, 50)
        setTimeout(() => {
            setCurrentPercent(100)
            window.clearInterval(intervalId)
        }, 30000)

        return () => {
            setCurrentPercent(100)
            window.clearInterval(intervalId)
        }
    }, []);

    return (<div className="row">
        <div className="col">
            <div className="text-center ">
                {currentPercent < 100 && <ProgressBar striped animated variant="secondary" now={currentPercent}/>}
            </div>
        </div>
    </div>)
}