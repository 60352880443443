export const fallbackUrls = {
    "/00001/": "/index/news-item/id/500/",
    "/098/": "/index/news-item/id/1089/",
    "/1000/": "/index/news-item/id/1203/",
    "/10b/": "/index/news-item/id/799/",
    "/1101/": "/index/news-item/id/1074/",
    "/1136/": "/index/news-item/id/1136/",
    "/1171/": "/index/news-item/id/1171/",
    "/1191/": "/index/news-item/id/1191/",
    "/1194/": "/index/news-item/id/1194/",
    "/1195/": "/index/news-item/id/1195/",
    "/1197/": "/index/news-item/id/1197/",
    "/1198/": "/index/news-item/id/1198/",
    "/1199/": "/index/news-item/id/1199/",
    "/1200/": "/index/news-item/id/1200/",
    "/1201/": "/index/news-item/id/1201/",
    "/1220/": "/index/news-item/id/1220/",
    "/1234/": "/index/news-item/id/1242/",
    "/1246/": "/index/news-item/id/1246/",
    "/1500/": "/index/news-item/id/1103/",
    "/20/": "/index/news-item/id/1012/",
    "/2000/": "/index/news-item/id/1125/",
    "/20off/": "/catalog/index/repositoryId/2909/",
    "/21/": "/index/news-item/id/1241/",
    "/2311/": "/index/news-item/id/1243/",
    "/23a/": "/index/news-item/id/816/",
    "/23f/": "/index/news-item/id/1240/",
    "/300/": "/index/news-item/id/1209/",
    "/3000/": "/index/news-item/id/1166/",
    "/3000puhovik/": "/index/news-item/id/1229/",
    "/500za1000/": "/index/news-item/id/1126/",
    "/89/": "/index/news-item/id/722/",
    "/8m/": "/index/news-item/id/826/",
    "/a/": "/index/news-item/id/1100/",
    "/aivengo1000/": "/index/news-item/id/954/",
    "/al/": "/landing/personal-promocode/",
    "/aw18/": "/index/news-item/id/947/",
    "/b25/": "/index/news-item/id/877/",
    "/back-to-office/": "/catalog/index/repositoryId/2815/",
    "/backto2000/": "/catalog/index/repositoryId/2851/",
    "/baon-x-lamoda/": "/catalog/index/repositoryId/2706/",
    "/baonday/": "/catalog/index/repositoryId/2906/",
    "/basic/": "/catalog/index/repositoryId/2571/",
    "/bd/": "/index/news-item/id/930/",
    "/best-sale/": "/catalog/index/repositoryId/2965/",
    "/bestgift/": "/catalog/index/repositoryId/2934/",
    "/bf/": "/index/news-item/id/1239/",
    "/biletaivengo/": "/index/news-item/id/1112/",
    "/biletaivengo15/": "/index/news-item/id/1145/",
    "/blackfriday2023/": "/catalog/index/repositoryId/2894/",
    "/blackfridayknit23/": "/index/repositoryId/2894/category/tsb-tst-000037,tsb-tst-000075,tsb-tst-000072",
    "/blackfridaypants/": "/catalog/index/repositoryId/2912/",
    "/blackfridaywinter/": "/catalog/index/repositoryId/2894/category/tsb-tst-000009,tsb-tso-000014,tsb-tst-000010,tsb-tso-000012",
    "/blog/100-tepla-puhoviki-s-naturalnym-puhom/": "/index/blog-item/id/311/",
    "/blog/15-obrazov-kotorye-stoit-primerit-vesnoi-letom/": "/index/blog-item/id/515/",
    "/blog/462/stil-casual-dlya-zhenschin/": "/index/blog-item/id/462/",
    "/blog/473/modnye-demisezonnye-kurtki/": "/index/blog-item/id/473/",
    "/blog/477/detskaya-moda/": "/index/blog-item/id/477/",
    "/blog/480/zimnie-muzhskie-kurtki/": "/index/blog-item/id/480/",
    "/blog/489/s-chem-nosit-korotkiy-pukhovik/": "/index/blog-item/id/489/",
    "/blog/494/kak-vybrat-pukhovik/": "/index/blog-item/id/494/",
    "/blog/502/kak-pravilno-postirat-puhovik-v-vstiralnoy-mashinke/": "/index/blog-item/id/502/",
    "/blog/514/chto-oznachaiut-znachki-po-uhodu-za-odezhdoi/": "/index/blog-item/id/514/",
    "/blog/516/zhenskie-shorty-2022/": "/index/blog-item/id/516/",
    "/blog/518/zhenskie-rubashki/": "/index/blog-item/id/518/",
    "/blog/527/sochetaniya-tsvetov-v-odezhde/": "/index/blog-item/id/527/",
    "/blog/529/zhenskii-sportivny-i-stil-odezhdy/": "/index/blog-item/id/529/",
    "/blog/530/vidy-fasony-i-stili-iubok/": "/index/blog-item/id/530/",
    "/blog/532/letneye-muzhskie-stili/": "/index/blog-item/id/532/",
    "/blog/533/klassicheskii-stil-v-odezhde/": "/index/blog-item/id/533/",
    "/blog/539/modnye-osennie-mujskie-kurtki-2022-2023/": "/index/blog-item/id/539/",
    "/blog/540/jenskie-stili-odejdi/": "/index/blog-item/id/540/",
    "/blog/545/kak-podobrat-odezhdu-po-tipu-figury/": "/index/blog-item/id/545/",
    "/blog/553/modnaya-muzhskaya-odezhda-zima-2022-2023/": "/index/blog-item/id/553/",
    "/blog/571/muzhskie-dzhinsy-trendy-2023-old/": "/index/blog-item/id/571/",
    "/blog/571/muzhskie-dzhinsy-trendy-2023/": "/index/blog-item/id/572/",
    "/blog/6-sposobov-nosit-sportivnyi-kostium-zimoi-2024/": "/index/blog-item/id/587/",
    "/blog/bazovy-garderob-leto/": "/index/blog-item/id/526/",
    "/blog/chto-modno-letom/": "/index/blog-item/id/524/",
    "/blog/chto-nosit-zimoy/": "/index/blog-item/id/483/",
    "/blog/chyorniy-puhovik-na-kajdiy-den/": "/index/blog-item/id/497/",
    "/blog/cvet-goda-peach-fuzz/": "/index/blog-item/id/594/",
    "/blog/detskaya-odezhda-leto/": "/index/blog-item/id/523/",
    "/blog/estetika-old-money-kak-sobrat-zhenskij-obraz-v-populyarnom-stile/": "/index/blog-item/id/578/",
    "/blog/figura-priamougolnik-kak-podobrat-bazovy-i-garderob/": "/index/blog-item/id/595/",
    "/blog/gajd-po-modnym-platyam/": "/index/blog-item/id/436/",
    "/blog/gajd-po-modnym-zhenskim-bluzkam/": "/index/blog-item/id/453/",
    "/blog/idealnoe-plate-eto-prosto-vybiraem-odezhdu-po-tipu-figury/": "/index/blog-item/id/583/",
    "/blog/izdeliya-iz-shersti-naturalnoe-teplo/": "/index/blog-item/id/57/",
    "/blog/kak-najti-samyj-tyoplyj-puhovik-i-ne-oshibitsya-s-vyborom/": "/index/blog-item/id/422/",
    "/blog/kak-nosit-chernoe-platye/": "/index/blog-item/id/433/",
    "/blog/kak-nosit-dzhemper-s-rubashkoi-chtoby-ostavatsya-v-trende/": "/index/blog-item/id/585/",
    "/blog/kak-nosit-dzhemper-s-rubashkoj-chtoby-ostavatsya-v-trende/": "/index/blog-item/id/426/",
    "/blog/kak-nosit-rubashku-s-futbolkoy/": "/index/blog-item/id/432/",
    "/blog/kak-nosit-sharf-s-pukhovikom/": "/index/blog-item/id/495/",
    "/blog/kak-odetsya-na-prirodu/": "/index/blog-item/id/525/",
    "/blog/kak-odevat-rebenka-zimoy/": "/index/blog-item/id/484/",
    "/blog/kak-odevatsya-v-zharu-chtoby-vyglyadet-stilno-i-ne-poluchit-teplovoj-udar/": "/index/blog-item/id/458/",
    "/blog/kak-odevatsya-zimoj-stilno-i-teplo-razbiraem-aktualnye-obrazy/": "/index/blog-item/id/582/",
    "/blog/kakuyu-verhnyuyu-odezhdu-nosit-vesnoj/": "/index/blog-item/id/429/",
    "/blog/kapsula-zhenskoi-odezhdy-na-leto/": "/index/blog-item/id/569/",
    "/blog/kapsula-zhenskoy-odezhdy-na-vesnu/": "/index/blog-item/id/568/",
    "/blog/klassicheskie-bruki/": "/index/blog-item/id/434/",
    "/blog/luchshe-chem-chernyi-s-chem-nosit-krasnyi-puhovik/": "/index/blog-item/id/588/",
    "/blog/luchshie-muzhskie-pukhoviki/": "/index/blog-item/id/492/",
    "/blog/mini-midi-maksi-kak-najti-svoyu-dlinu/": "/index/blog-item/id/44/",
    "/blog/moda-osen/": "/index/blog-item/id/536/",
    "/blog/moda-vesna-leto-dlya-zhenshin/": "/index/blog-item/id/567/",
    "/blog/modnaya-zhenskaya-futbolka-i-ee-mesto-v-garderobe-segodnya/": "/index/blog-item/id/455/",
    "/blog/modnaya-zhenskaya-odezhda-osen/": "/index/blog-item/id/573/",
    "/blog/modnaya-zhenskaya-odezhda-zima/": "/index/blog-item/id/552/",
    "/blog/modnie-cveta-v-odejde/": "/index/blog-item/id/501/",
    "/blog/modnye-aksessuary/": "/index/blog-item/id/481/",
    "/blog/modnye-bruki-oseni/": "/index/blog-item/id/537/",
    "/blog/modnye-bryuki-osen/": "/index/blog-item/id/468/",
    "/blog/modnye-bryuki-zima/": "/index/blog-item/id/486/",
    "/blog/modnye-djempery/": "/index/blog-item/id/476/",
    "/blog/modnye-djinsy-osen/": "/index/blog-item/id/470/",
    "/blog/modnye-jenskie-obrazy-oseni/": "/index/blog-item/id/538/",
    "/blog/modnye-kurtki-osen/": "/index/blog-item/id/466/",
    "/blog/modnye-kurtki-zima/": "/index/blog-item/id/547/",
    "/blog/modnye-muzhskie-futbolky/": "/index/blog-item/id/522/",
    "/blog/modnye-muzhskie-pukhoviki/": "/index/blog-item/id/482/",
    "/blog/modnye-obrazy-leto/": "/index/blog-item/id/521/",
    "/blog/modnye-platia-osen/": "/index/blog-item/id/541/",
    "/blog/modnye-platya-osen/": "/index/blog-item/id/469/",
    "/blog/modnye-platya-zima/": "/index/blog-item/id/487/",
    "/blog/modnye-platya/": "/index/blog-item/id/556/",
    "/blog/modnye-pukhoviki/": "/index/blog-item/id/471/",
    "/blog/modnye-shapki-zima/": "/index/blog-item/id/488/",
    "/blog/modnye-svitera/": "/index/blog-item/id/475/",
    "/blog/modnye-tcveta-oseni/": "/index/blog-item/id/542/",
    "/blog/modnye-tcveta/": "/index/blog-item/id/555/",
    "/blog/modnye-tolstovki/": "/index/blog-item/id/472/",
    "/blog/modnye-tsveta-osen/": "/index/blog-item/id/464/",
    "/blog/modnye-zhenskie-bryuki/": "/index/blog-item/id/570/",
    "/blog/modnye-zhenskie-dzhinsy-osen/": "/index/blog-item/id/544/",
    "/blog/modnye-zhenskie-dzhinsy/": "/index/blog-item/id/459/",
    "/blog/modnye-zhenskie-kurtki-osen/": "/index/blog-item/id/543/",
    "/blog/modnye-zhenskie-kurtki-tendencii-i-novinki-sezona/": "/index/blog-item/id/430/",
    "/blog/modnye-zhenskie-luki-s-dzhinsami/": "/index/blog-item/id/534/",
    "/blog/modnye-zhenskie-palto/": "/index/blog-item/id/574/",
    "/blog/modnye-zhenskie-puhoviki-zima/": "/index/blog-item/id/549/",
    "/blog/modnye-zhenskie-svitery-zima/": "/index/blog-item/id/554/",
    "/blog/modnye-zhenskie-topy-leto/": "/index/blog-item/id/528/",
    "/blog/modnye-zhenskie-zhilety/": "/index/blog-item/id/463/",
    "/blog/modsnye-jenskie-puchoviki/": "/index/blog-item/id/499/",
    "/blog/modsnye-zimnie-jenskie-kurtki/": "/index/blog-item/id/500/",
    "/blog/monokhrom-v-odezhde-kak-vygliadet-stilno-v-odnom-tcvete/": "/index/blog-item/id/592/",
    "/blog/muzhskaya-moda-vesna-leto/": "/index/blog-item/id/505/",
    "/blog/muzhskie-kurtki-osen/": "/index/blog-item/id/474/",
    "/blog/muzhskoi-garderob-chto-tuda-vhodit-i-kak-sostavit-svoi/": "/index/blog-item/id/593/",
    "/blog/novaya-kolletsiya-vesna-leto/": "/index/blog-item/id/511/",
    "/blog/ofisnyj-dress-kod-strashilka-ili-modnyj-luk/": "/index/blog-item/id/457/",
    "/blog/oranzhevyi-v-odezhde-s-chem-nosit-samyi-osennii-tcvet/": "/index/blog-item/id/596/",
    "/blog/outwear-vesna-leto/": "/index/blog-item/id/510/",
    "/blog/pantone-nazval-samye-modnye-tcveta-vesny-leta/": "/index/blog-item/id/513/",
    "/blog/parizhskij-shik-sekrety-francuzskogo-stilya/": "/index/blog-item/id/272/",
    "/blog/plashi-i-vetrovki-stilnye-idei-dlya-holodnogo-leta/": "/index/blog-item/id/108/",
    "/blog/platya-vesna-leto/": "/index/blog-item/id/508/",
    "/blog/po-pogode-i-mode-chto-nosit-v-marte/": "/index/blog-item/id/383/",
    "/blog/polnoe-rukovodstvo-po-muzhskim-briukam-na-2024-god/": "/index/blog-item/id/591/",
    "/blog/primeryaem-bezhevye-bryuki-s-chem-i-kak-nosit-chtoby-vyglyadet-roskoshno/": "/index/blog-item/id/460/",
    "/blog/printcip-mnogosloi-nosti-kak-sostavit-stilnyi-obraz-iz-3-4-sloev/": "/index/blog-item/id/590/",
    "/blog/puh-i-vse-vse-vse-chto-vnutri-u-zimnej-odezhdy/": "/index/blog-item/id/373/",
    "/blog/rating-luchshih-pukhovikov/": "/index/blog-item/id/485/",
    "/blog/razrushaem-mify-7-samyh-chastyh-zabluzhdenij-o-puhovikah/": "/index/blog-item/id/203/",
    "/blog/s-chem-nosit-dlinniy-pukhovik/": "/index/blog-item/id/490/",
    "/blog/s-chem-nosit-dlinnoe-plate/": "/index/blog-item/id/427/",
    "/blog/s-chem-nosit-shirokiye-bryuki/": "/index/blog-item/id/496/",
    "/blog/s-chem-sochetaetsia-zeleny-i-tcvet-v-odezhde/": "/index/blog-item/id/531/",
    "/blog/samye-modnye-iubki-vesnoi-letom/": "/index/blog-item/id/512/",
    "/blog/samye-teplye-pukhoviki/": "/index/blog-item/id/493/",
    "/blog/sarafan-i-ego-mesto-v-letnem-garderobe/": "/index/blog-item/id/450/",
    "/blog/shokoladnyj-novyj-bazovyj-uchimsya-pravilno-ego-ispolzovat/": "/index/blog-item/id/581/",
    "/blog/short-list-glavnye-modeli-etogo-leta/": "/index/blog-item/id/396/",
    "/blog/shorty-bermudy-i-s-chem-ih-nosit/": "/index/blog-item/id/452/",
    "/blog/shorty-modnye-fasony-trendy-sochetaniya-vesnoj-letom-goda/": "/index/blog-item/id/445/",
    "/blog/siniy-tsvet-sochetaniya/": "/index/blog-item/id/546/",
    "/blog/sobiraemsya-na-more-top-5-letnih-lajfhakov/": "/index/blog-item/id/233/",
    "/blog/sostavlyaem-yarkij-obraz-s-pidzhakom-iz-kollekcii-osen-zima/": "/index/blog-item/id/580/",
    "/blog/sovety-stilista-baon-po-sozdaniyu-ofisnyh-obrazov/": "/index/blog-item/id/576/",
    "/blog/stilnye-i-teplye-obrazy-na-osen-ot-baon/": "/index/blog-item/id/575/",
    "/blog/top-12-modnyh-obrazov-na-osnove-dlinnyh-yubok/": "/index/blog-item/id/454/",
    "/blog/top-15-gotovyh-obrazov-iz-kollekcii-osen-zima-primeryaem-so-stilistom/": "/index/blog-item/id/584/",
    "/blog/top-15-modnyh-trendov-na-leto/": "/index/blog-item/id/449/",
    "/blog/top-6-parok-baon/": "/index/blog-item/id/498/",
    "/blog/top-6-rubashek-dlya-obraza-s-kostyumom/": "/index/blog-item/id/579/",
    "/blog/trendy-osen-zima/": "/index/blog-item/id/465/",
    "/blog/v-chem-vstrechat-novyj-god-2024-sovety-ot-baon/": "/index/blog-item/id/577/",
    "/blog/v-stile-hyugge-modnye-sekrety-skandinavov/": "/index/blog-item/id/254/",
    "/blog/vse-chto-nuzhno-znat-o-modnyh-bryukah-vesnoj-letom/": "/index/blog-item/id/443/",
    "/blog/vyrez-vrazrez-preimushestva-i-nedostatki/": "/index/blog-item/id/261/",
    "/blog/yubka-po-figure/": "/index/blog-item/id/435/",
    "/blog/zhenskaia-odezhda-plus-size-sostavliaem-pravilnyi-garderob/": "/index/blog-item/id/589/",
    "/blog/zhenskaya-moda-vesna-leto/": "/index/blog-item/id/503/",
    "/blog/zhenskie-bluzki/": "/index/blog-item/id/517/",
    "/blog/zhenskie-bryuki-vesna-leto/": "/index/blog-item/id/507/",
    "/blog/zhenskie-dzhinsy-vesna-leto/": "/index/blog-item/id/506/",
    "/blog/zhenskie-futbolky/": "/index/blog-item/id/519/",
    "/blog/zhenskie-kurtki-vesna-leto/": "/index/blog-item/id/504/",
    "/blog/zhenskie-sarafany/": "/index/blog-item/id/520/",
    "/blog/zhenskie-svitera/": "/index/blog-item/id/509/",
    "/blog/zima-verhnyaya-odezhda/": "/index/blog-item/id/478/",
    "/blog/zimnie-zhenskie-kurtki/": "/index/blog-item/id/479/",
    "/bluzka/": "/catalog/index/sup/26/category/tsb-tso-000002/sx/aa-aa-000001,aa-aa-000002/",
    "/bn24/": "/catalog/index/repositoryId/2944/",
    "/bonus/": "/landing/loyalty/",
    "/bonus1000/": "/index/news-item/id/1311/",
    "/braidedknitwear/": "/catalog/index/repositoryId/2911/",
    "/bryuki/": "/catalog/index/category/tsb-tst-000003/sx/aa-aa-000001,aa-aa-000002/",
    "/c/": "/index/news-item/id/491/",
    "/catalog/april/": "/catalog/index/repositoryId/2738/",
    "/catalog/kurtki/sale50/": "/catalog/index/repositoryId/2733/",
    "/catalog/levelup/": "/catalog/index/repositoryId/2728/",
    "/catalog/trendyol/": "/catalog/index/repositoryId/2563/",
    "/catalog/vse-dlya-doma/aromadiffuzory/": "/catalog/index/category/tsb-tso-000038/left-menu-mode/chemistry/",
    "/catalog/vse-dlya-doma/aromaticheskie-sprei/": "/catalog/index/category/tsb-tso-000039/left-menu-mode/chemistry/",
    "/catalog/vse-dlya-doma/chekhly-dlya-odezhdy/": "/catalog/index/category/tsb-tso-000047/left-menu-mode/chemistry/",
    "/catalog/vse-dlya-doma/geli-dlya-stirki/": "/catalog/index/category/tsb-tso-000018/left-menu-mode/chemistry/",
    "/catalog/vse-dlya-doma/sredstva-dlya-mytya-polov/": "/catalog/index/category/tsb-tso-000022/left-menu-mode/chemistry/",
    "/catalog/vse-dlya-doma/veshalki/": "/catalog/index/category/tsb-tso-000015/left-menu-mode/chemistry/",
    "/catalog/wn/": "/catalog/lookbook2019d3/",
    "/check0119/": "/index/news-item/id/1065/",
    "/check07/": "/index/news-item/id/924/",
    "/chekkids30/": "/index/news-item/id/1003/",
    "/columb/": "/index/news-item/id/1629/type/baon-news/",
    "/columbus/": "/index/news-item/id/1323/",
    "/cozydress/": "/catalog/index/repositoryId/2957/",
    "/d/": "/index/news-item/id/476/",
    "/dec23hit/": "/catalog/index/repositoryId/2945/",
    "/decemberhit/": "/catalog/index/repositoryId/2925/",
    "/decemberknit/": "/catalog/index/repositoryId/2952/",
    "/decemberoffice/": "/catalog/index/repositoryId/2928/",
    "/discount/": "/catalog/index/sx/aa-aa-000001,aa-aa-000002,aa-aa-000003/isActual/0/",
    "/djemper/": "/catalog/index/category/tsb-tst-000037,tsb-tst-000072/sx/aa-aa-000001,aa-aa-000002/",
    "/dom/": "/index/news-item/id/1170/",
    "/dr1/": "/index/news-item/id/1183/",
    "/dr2/": "/index/news-item/id/1184/",
    "/dr3/": "/index/news-item/id/1147/",
    "/dresses-sale/": "/catalog/index/repositoryId/2786/",
    "/dublenka/": "/catalog/index/category/tsb-tso-000010/sx/aa-aa-000001,aa-aa-000002/",
    "/e-mail/": "/catalog/index/repositoryId/486/",
    "/e/": "/catalog/index/repositoryId/1721/sx/aa-aa-000002/",
    "/ekopuh23-24/": "/catalog/index/repositoryId/2876/",
    "/f/": "/index/news-item/id/805/",
    "/fall-sale/": "/catalog/index/repositoryId/2848/",
    "/fallssale/": "/catalog/index/repositoryId/2861/",
    "/femalestyle/": "/catalog/index/repositoryId/2933/",
    "/fin/": "/index/news-item/id/933/",
    "/final-sale/": "/catalog/index/repositoryId/2824/",
    "/final-winter-sale/": "/catalog/index/repositoryId/2966/",
    "/flash-sale/": "/catalog/index/repositoryId/2775/",
    "/flashsale-jr/": "/catalog/index/repositoryId/2803/",
    "/flashsale-tsh/": "/catalog/index/repositoryId/2800/",
    "/flashsale-vetrovki/": "/catalog/index/repositoryId/2805/",
    "/flashsale/": "/catalog/index/repositoryId/2785/",
    "/frenchterry/": "/catalog/index/repositoryId/2901/",
    "/futbolka/": "/catalog/index/category/tsb-tso-000004/sx/aa-aa-000001,aa-aa-000002/",
    "/futbolki-mayki-polo/futbolki-khlopkovyye/": "/catalog/index/sup/10/category/tsb-tso-000004,tsb-tst-000021,tsb-tst-000028,tsb-tst-000031,tsb-tst-000038,tsb-tst-000067/sx/aa-aa-000001,aa-aa-000003/scId/106/",
    "/futbolki-mayki-polo/futbolki-s-printami/": "/catalog/index/sup/10/category/tsb-tso-000004,tsb-tst-000021,tsb-tst-000028,tsb-tst-000031,tsb-tst-000038,tsb-tst-000067/sx/aa-aa-000001,aa-aa-000003/scId/105/",
    "/futbolki-mayki-polo/topy-i-mayki/": "/catalog/index/sup/10/category/tsb-tso-000004,tsb-tst-000021,tsb-tst-000028,tsb-tst-000031,tsb-tst-000038,tsb-tst-000067/sx/aa-aa-000001,aa-aa-000003/scId/18/",
    "/futbolki-mayki-polo/topy-i-mayki/discount/": "/catalog/index/sup/10/category/tsb-tso-000004,tsb-tst-000021,tsb-tst-000028,tsb-tst-000031,tsb-tst-000038,tsb-tst-000067/sx/aa-aa-000001,aa-aa-000003/scId/18/discount/isActual/0/",
    "/g/": "/index/news-item/id/845/",
    "/half/": "/index/news-item/id/1072/",
    "/holoda/": "/catalog/index/repositoryId/2929/",
    "/hotsale/": "/catalog/index/repositoryId/2816/",
    "/how-to/": "/landing/qr1/",
    "/how-to/discount/": "/landing/how-to-discount/",
    "/i/": "/i/",
    "/index/blog/431/chernaya-kurtka/": "/index/blog-item/id/431/",
    "/index/blog/442/modnye-vetrovki-2021/": "/index/blog-item/id/442/",
    "/index/blog/tag/svadba/": "/index/blog/tag/свадьба/",
    "/index/contact/magaziny-odezhdy-vo-vladimire/": "/index/contact/regionId/tsb-tst-049477",
    "/j/": "/index/news-item/id/944/j/",
    "/jacket/": "/catalog/index/category/tsb-tst-000066/sx/aa-aa-000001,aa-aa-000002/",
    "/jackets18/": "/index/news-item/id/992/",
    "/jeans/": "/catalog/index/category/tsb-tst-000058/sx/aa-aa-000001,aa-aa-000002/",
    "/jeansall/": "/catalog/index/repositoryId/2926/",
    "/jk/": "/index/news-item/id/833/",
    "/k/": "/index/news-item/id/832/",
    "/kamchatka/": "/catalog/index/repositoryId/2849/",
    "/kari/": "/index/news-item/id/1252/",
    "/kb/": "/index/news-item/id/794/",
    "/keepwarm/": "/catalog/index/repositoryId/2889/",
    "/kid/": "/index/news-item/id/1148/",
    "/kids/": "/index/news-item/id/1121/",
    "/knitlook/": "/catalog/index/repositoryId/2954/",
    "/knitsale/": "/catalog/index/repositoryId/2880/",
    "/knitwear-sale/": "/catalog/index/repositoryId/2873/",
    "/knitwear23-24/": "/catalog/index/repositoryId/2839/",
    "/kombinezon/": "/catalog/index/category/tsb-tst-000026/sx/aa-aa-000001,aa-aa-000002/",
    "/kp/": "/index/news-item/id/850/",
    "/kupon1000ss18/": "/index/news-item/id/778/",
    "/kurtka/": "/catalog/index/category/tsb-tso-000012,tsb-tst-000010/sx/aa-aa-000001,aa-aa-000002/",
    "/kurtki-osen-vesna-2015/": "/catalog/index/repositoryId/512/",
    "/kurtki/tag/demisezonnye-kurtki-dlya-malchikov/": "/catalog/index/sup/59/category/tsb-tso-000012,tsb-tst-000010/sx/aa-aa-000004/scId/804/",
    "/landing/baon-x-lamoda/": "/landing/tqr7/",
    "/landing/blackfriday2023/": "/landing/frontend/id/22",
    "/landing/gift/": "/landing/frontend/id/25",
    "/landing/kamchatka/": "/landing/frontend/id/14",
    "/landing/knitwear/": "/landing/frontend/id/24",
    "/landing/levelup/": "/landing/frontend/id/20",
    "/landing/musthave/": "/landing/frontend/id/18",
    "/landing/office/": "/landing/frontend/id/10",
    "/landing/outwear/": "/landing/frontend/id/23",
    "/landing/podeli/": "/landing/frontend/id/12",
    "/landing/urbanstyle/": "/landing/frontend/id/15",
    "/landing/whynot/": "/landing/frontend/id/13",
    "/leto/": "/index/news-item/id/1155/",
    "/lk/": "/catalog/index/repositoryId/1433/",
    "/lowerprice/": "/catalog/index/repositoryId/2917/",
    "/lowerprice20/": "/catalog/index/repositoryId/2908/",
    "/lu/": "/index/news-item/id/1262/type/baon-news/",
    "/m/": "/index/news-item/id/1096/",
    "/malestyle/": "/catalog/index/repositoryId/2932/",
    "/mega/": "/index/news-item/id/1111/",
    "/menwear-new/": "/catalog/index/sx/aa-aa-000001,aa-aa-000003/repositoryId/2949/discountLess/30/",
    "/metro/": "/index/news-item/id/215/",
    "/midseasonsale/": "/catalog/index/repositoryId/2903/",
    "/ms5/": "/catalog/index/repositoryId/1040/",
    "/mssg/": "/landing/quick-connect/",
    "/musthave/": "/catalog/index/repositoryId/2716/",
    "/n/": "/index/news-item/id/1098/",
    "/n1/": "/default/index/lanm/m/1/",
    "/n2/": "/default/index/lanm/m/2/",
    "/n3/": "/default/index/lanm/m/3/",
    "/n4/": "/default/index/lanm/m/4/",
    "/new-collection24/": "/catalog/index/repositoryId/2949/",
    "/new14/": "/catalog/index/repositoryId/2972/",
    "/newcoats/": "/catalog/index/repositoryId/2845/",
    "/newcollection-ss24/": "/catalog/index/repositoryId/2943/",
    "/newcollection/": "/catalog/index/repositoryId/2809/",
    "/newdownjackets/": "/catalog/index/repositoryId/2902/",
    "/night-sale/": "/catalog/index/repositoryId/2834/",
    "/night/": "/catalog/index/repositoryId/2959/",
    "/novemberknit/": "/catalog/index/repositoryId/2908/category/tsb-tst-000037,tsb-tst-000075,tsb-tst-000072",
    "/novembersale/": "/landing/frontend/id/19",
    "/odezda-vesna-leto-2015/": "/index/blog-item/id/76/",
    "/office-sale/": "/catalog/index/repositoryId/2877/",
    "/office24/": "/catalog/index/repositoryId/2971/",
    "/osen-zima/": "/catalog/index/repositoryId/2629/",
    "/outerwear-weekends-sale/": "/catalog/index/repositoryId/2890/",
    "/outerwear-weekly-sale/": "/catalog/index/repositoryId/2960/",
    "/outerwear/": "/catalog/index/repositoryId/2860/",
    "/outwear/": "/catalog/index/repositoryId/2886/",
    "/p/": "/index/news-item/id/1099/",
    "/palto/": "/catalog/index/category/tsb-tst-000011/sx/aa-aa-000001,aa-aa-000002/",
    "/pay-sale/": "/catalog/index/repositoryId/2887/",
    "/payless/": "/index/news-item/id/872/",
    "/pd/": "/public/files/bonusprogram_rules,pdf",
    "/plashch/": "/catalog/index/category/tsb-tst-000013/sx/aa-aa-000001,aa-aa-000002/",
    "/puh30/": "/index/news-item/id/990/",
    "/puhovik/": "/catalog/index/category/tsb-tso-000014,tsb-tst-000009/sx/aa-aa-000001,aa-aa-000002/",
    "/puhoviki/discount/": "/catalog/index/category/tsb-tso-000014,tsb-tst-000009/isActual/0/",
    "/puteshestvie-s-baon-ot-teriberki-do-kamchatki-istoriia-odnogo-puteshestviia/": "/index/blog-item/id/586/",
    "/qr/": "/landing/update-loyalty-info/",
    "/r20/": "/index/news-item/id/896/",
    "/repozitoriy-test/": "/catalog/index/repositoryId/906/left-menu-mode/women/prevent-stock-control/true/",
    "/rubashka/": "/catalog/index/category/tsb-tso-000003/sx/aa-aa-000001,aa-aa-000002/",
    "/s/": "/index/news-item/id/1083/s/",
    "/s18/": "/index/news-item/id/800/",
    "/sale-jumper/": "/catalog/index/repositoryId/2804/",
    "/sale-ss23/": "/catalog/index/repositoryId/2757/",
    "/sale-week/": "/catalog/index/repositoryId/2820/",
    "/saleup50/": "/catalog/index/repositoryId/2913/",
    "/september-sale/": "/catalog/index/repositoryId/2858/",
    "/shop-the-look/": "/landing/instashop/",
    "/shorti/": "/catalog/index/category/tsb-tst-000005/sx/aa-aa-000001,aa-aa-000002/",
    "/ski/": "/index/news-item/id/782/",
    "/smart-sale/": "/catalog/index/repositoryId/2835/",
    "/sms/": "/index/news-item/id/1204/",
    "/sms1/": "/index/news-item/id/1073/",
    "/sms1810/": "/index/news-item/id/1017/",
    "/summer-sale/": "/catalog/index/repositoryId/2826/",
    "/sunlight/": "/index/news-item/id/871/",
    "/t-j-sale/": "/catalog/index/repositoryId/2802/",
    "/t-shirts-sale/": "/catalog/index/repositoryId/2779/",
    "/t-shirts-sales/": "/catalog/index/repositoryId/2814/",
    "/termo/": "/catalog/index/repositoryId/2927/",
    "/tolstovka/": "/catalog/index/category/tsb-tst-000036/sx/aa-aa-000001,aa-aa-000002/",
    "/top-sale/": "/catalog/index/repositoryId/2961/",
    "/topprice/": "/catalog/index/repositoryId/2885/",
    "/topsale/": "/catalog/index/repositoryId/2830/",
    "/trade-in/": "/landing/tqr3/",
    "/trade-in/online/": "/index/news-item/id/1245/",
    "/trikotazh/": "/catalog/index/repositoryId/2920/",
    "/ts/": "/index/news-item/id/913/",
    "/turtlenecksale/": "/catalog/index/repositoryId/2962/",
    "/u/": "/catalog/index/repositoryId/1648/sx/aa-aa-000002/collection/tsb-tso-000033/",
    "/urbanstyle/": "/catalog/index/repositoryId/2862/",
    "/v/": "/index/news-item/id/1084/",
    "/vd/": "/index/news-item/id/806/",
    "/vesennysale/": "/catalog/index/repositoryId/2748/",
    "/vesennysale15/": "/catalog/index/repositoryId/2755/",
    "/vesennysale25/": "/catalog/index/repositoryId/2751/",
    "/vesna-leto/women/dzhempery/": "/catalog/index/sup/24/category/tsb-tst-000037,tsb-tst-000053,tsb-tst-000072,tsb-tst-000075/sx/aa-aa-000002,aa-aa-000003/repositoryId/2561/",
    "/vesna-leto/women/futbolki-topy-polo/": "/catalog/index/sup/28/category/tsb-tso-000004,tsb-tst-000021,tsb-tst-000028,tsb-tst-000031,tsb-tst-000038,tsb-tst-000067/sx/aa-aa-000002,aa-aa-000003/repositoryId/2396/",
    "/vesna-leto/women/jackety/": "/catalog/index/sup/22/category/tsb-tst-000066/sx/aa-aa-000002,aa-aa-000003/repositoryId/2396/",
    "/vesna-leto/women/jilety/": "/catalog/index/sup/23/category/tsb-tso-000011,tsb-tst-000001/sx/aa-aa-000002,aa-aa-000003/repositoryId/2561/",
    "/vesna-leto/women/palto/": "/catalog/index/sup/20/category/tsb-tst-000011/sx/aa-aa-000002,aa-aa-000003/nodirection/tsb-tst-000004,tsb-tst-000005/repositoryId/2396/",
    "/vesna-leto/women/platya/": "/catalog/index/sup/27/category/tsb-tso-000068,tsb-tst-000012,tsb-tst-000069/sx/aa-aa-000002,aa-aa-000003/repositoryId/2561/",
    "/vesna-leto/women/rubashki-bluzki/": "/catalog/index/sup/26/category/tsb-tso-000002,tsb-tso-000003,tsb-tst-000004,tsb-tst-000073,tsb-tst-000074/sx/aa-aa-000002,aa-aa-000003/repositoryId/2561/",
    "/vesna-leto/women/sportivnye-kostyumy/": "/catalog/index/sup/33/category/tsb-tst-000002/sx/aa-aa-000002,aa-aa-000003/repositoryId/2396/",
    "/vesna-leto/women/tolstovki/": "/catalog/index/sup/25/category/tsb-tst-000036/sx/aa-aa-000002,aa-aa-000003/repositoryId/2561/",
    "/vesna-leto/women/vetrovki/": "/catalog/index/sup/45/category/tsb-tst-000006/sx/aa-aa-000002,aa-aa-000003/repositoryId/2561/",
    "/vetrovka/": "/catalog/index/category/tsb-tst-000006/sx/aa-aa-000001,aa-aa-000002/",
    "/vo/": "/index/news-item/id/1225/",
    "/vse-dlya-doma/": "/catalog/index/repositoryId/1900/",
    "/vse-dlya-doma/chehly/": "/catalog/index/sup/141/category/tsb-tso-000047/left-menu-mode/chemistry/",
    "/vse-dlya-doma/geli-dlya-stirki/": "/catalog/index/sup/89/category/tsb-tso-000018/left-menu-mode/chemistry/",
    "/vse-dlya-doma/sprei/": "/catalog/index/sup/118/category/tsb-tso-000039/left-menu-mode/chemistry/",
    "/vse-dlya-doma/veshalki/": "/catalog/index/sup/114/category/tsb-tso-000015/left-menu-mode/chemistry/",
    "/w/": "/index/news-item/id/847/",
    "/week-sale/": "/catalog/index/repositoryId/2831/",
    "/weekend-sale/": "/catalog/index/repositoryId/2841/",
    "/weekendsale/": "/catalog/index/repositoryId/2811/",
    "/weeks-sale/": "/catalog/index/repositoryId/2842/",
    "/weeksale/": "/catalog/index/repositoryId/2827/",
    "/welcome/": "/index/news-item/id/1163/",
    "/whynot/": "/catalog/index/repositoryId/2850/prevent-stock-control/true/",
    "/winterlook/": "/catalog/index/repositoryId/2953/",
    "/winteroffice/": "/catalog/index/repositoryId/2955/",
    "/womenwear-new/": "/catalog/index/sx/aa-aa-000002,aa-aa-000003/repositoryId/2949/discountLess/30/",
    "/woolknitwear/": "/catalog/index/repositoryId/2865/",
    "/x/": "/catalog/index/repositoryId/1293/",
    "/xc/": "/catalog/index/repositoryId/1365/",
    "/xx/": "/index/news-item/id/660/",
    "/yubki/": "/catalog/index/sup/31/category/tsb-tst-000008/sx/aa-aa-000001,aa-aa-000002/",
    "/z/": "/catalog/index/repositoryId/1595/",
    "/zapodpisku/": "/landing/zapodpisku/",
    "/zhakety/tag/belye/": "/catalog/index/sup/22/category/tsb-tst-000066/sx/aa-aa-000002,aa-aa-000003/simpleColor/white/",
    "/zhilet/": "/catalog/index/category/tsb-tst-000001/sx/aa-aa-000001,aa-aa-000002/",
    "/zimniy-sale/": "/catalog/index/repositoryId/2923/"
}