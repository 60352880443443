import {Map, ObjectManager, YMaps} from 'react-yandex-maps';
import {useContext, useEffect, useRef, useState} from "react";
import {GlobalContext} from "../../../GlobalReducer";
import {ShopInterface} from "../../../interfaces/shop/ShopInterface";
import {MOSCOW_REGION_ID, SPB_REGION_ID} from "../../../Config";
import {useNavigate} from "react-router-dom";

const DEFAULT_ZOOM = 12;
const LARGE_ZOOM = 10;
const SUPER_LARGE_ZOOM = 5;

let lastMapUid: any = null;

export default function ShopMap({shops, allShopVisible}: { shops: ShopInterface[], allShopVisible: boolean }) {
    const mapRef: any = useRef(null);

    const navigate = useNavigate()

    /* eslint-disable no-unused-vars */
    const [state, dispatch] = useContext(GlobalContext);

    const [centerOfMap, setCenterOfMap] = useState<any>(null)
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM)

    const [features, setFeatures] = useState({
        "type": "FeatureCollection",
        "features": []
    })

    useEffect(() => {
        if (!shops.length) return

        let shopsFiltered = shops.filter((shop) => {
            return (parseFloat(shop.extra_param.longitude) > 0 || parseFloat(shop.longitude) > 0) && (parseFloat(shop.extra_param.latitude) > 0 || parseFloat(shop.latitude) > 0);
        })

        if (shopsFiltered.length) {
            const centerCoordinate = [parseFloat(shopsFiltered[0].extra_param.longitude) > 0 ? shopsFiltered[0].extra_param.longitude : shopsFiltered[0].longitude, parseFloat(shopsFiltered[0].extra_param.latitude) > 0 ? shopsFiltered[0].extra_param.latitude : shopsFiltered[0].latitude]

            setCenterOfMap(shopsFiltered?.length > 0 && centerCoordinate)
            switch(true){
                case shopsFiltered[0].region.id == MOSCOW_REGION_ID || shopsFiltered[0].region.id == SPB_REGION_ID:
                    setZoom(LARGE_ZOOM);
                    break;
                case allShopVisible:
                    setZoom(SUPER_LARGE_ZOOM);
                    break;
                default:
                    setZoom(DEFAULT_ZOOM);
            }
        }


        const features: any = shopsFiltered.map(shop => {
            return {
                type: "Feature",
                id: shop.id,
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(shop.extra_param.longitude) > 0 ? shop.extra_param.longitude : shop.longitude, parseFloat(shop.extra_param.latitude) > 0 ? shop.extra_param.latitude : shop.latitude]
                },
                properties: {
                    hintContent: (shop.extra_param.address + shop.extra_param.address2) || shop.address
                },
                options: {
                    iconColor: '#783D0F'
                }
            }
        })

        setFeatures({
            "type": "FeatureCollection",
            "features": features
        })


    }, [shops])


    return (<>
        {centerOfMap && <div className="col-lg-8 shops__map-iframe">
            <YMaps>
                <Map state={{center: centerOfMap, zoom: zoom}} width={"100%"} height={'100%'}>
                    <ObjectManager
                        options={{
                            clusterize: true,
                        }}
                        objects={{
                            openBalloonOnClick: true,
                            preset: 'islands#fashionIcon',
                        }}
                        // @ts-ignore
                        features={features}
                        clusters={{
                            preset: 'islands#invertedBlackClusterIcons',
                        }}
                        modules={[
                            'objectManager.addon.objectsHint',
                        ]}
                        instanceRef={ref => mapRef.current = ref}
                        onLoad={() => {
                            if (lastMapUid) {
                                mapRef.current.objects.setObjectOptions(lastMapUid, {
                                    iconColor: '#FF0000'
                                })
                            }
                            mapRef.current.objects.events.add('click', (e: any) => {
                                const objectId = e.get('objectId');
                                if (lastMapUid) {
                                    mapRef.current.objects.setObjectOptions(lastMapUid, {
                                        iconColor: '#484848'
                                    })
                                }
                                lastMapUid = objectId
                                mapRef.current.objects.setObjectOptions(objectId, {
                                    iconColor: '#FF0000'
                                })
                                navigate('/shop/' + objectId)
                                // const currentLocation = locationWithCoordinates.find(location => location.uid === objectId)
                                // props.setLocation(currentLocation)
                            })
                        }}
                    >

                    </ObjectManager>
                </Map>
            </YMaps>
        </div>}
    </>)
}