import LinkExtendedInterface from "../../interfaces/core/LinkExtendedInterface";
import {getBaseImagePath} from "../catalog/Utils";

import {Swiper, SwiperSlide} from "swiper/react";

export default function Advantages({bottomBanners}: { bottomBanners: LinkExtendedInterface[] }) {

    return (<section className="advantages">
        <div className="container-fluid">
            <div className="row desk">
                {bottomBanners.map(banner => <div key={banner.title + banner.image} className="col-4">
                        <a className="banner" href={banner.url}>
                            <picture className="banner__img">
                                <source srcSet={banner.image} media="(max-width: 992px)"/>
                                <img src={banner.image} alt=""/>
                            </picture>
                            <h5 className="f-exp">{banner.title}</h5>
                        </a>
                    </div>
                )}
            </div>
            <div className="row mob">
                <div className="banner__slider">
                 <Swiper
                                className={``}
                                slidesPerView={1.5}
                                breakpoints={{
                                    992: {
                                        slidesPerView: 1.5,
                                    },
                                }}
                            >
                        {bottomBanners.map((banner) => <SwiperSlide key={banner.title+banner.image} className="swiper-slide banner">
                            <a href={banner.url}>
                                <picture className="banner__img">
                                    <source srcSet={getBaseImagePath() + banner.image} media="(max-width: 992px)"/>
                                    <img src={getBaseImagePath() + banner.image} alt=""/>
                                </picture>
                                <p className="f-exp">{banner.title}</p>
                                </a>
                            </SwiperSlide>
                        )}
           </Swiper>
           </div>
            </div>
        </div>
    </section>)
}
