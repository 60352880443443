import React, {useRef, useState, useEffect} from "react";
import {Swiper, SwiperSlide} from "swiper/react";

import {Autoplay, Pagination, EffectFade} from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {BannerInterface} from "../../interfaces/core/BannerInterface";



export default function SlideBanners({banners}: {banners: BannerInterface[]}) {

  const swiperRef = useRef<any | null>(null);
  const [autoplayDelay, setAutoplayDelay] = useState<number>(getDelayForSlide(0));
  const [videoLoaded, setVideoLoaded] = useState<boolean[]>(banners.map(() => false));


  function getDelayForSlide(index: number): number {
    const currentBanner = banners[index];
    const delay = currentBanner && typeof currentBanner.slideDelay === 'string'
    ? parseInt(currentBanner.slideDelay, 10)
    : (currentBanner?.video ? 20000 : 5000);

    return isNaN(delay) ? (currentBanner?.video ? 20000 : 5000) : delay;
  }

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.autoplay.delay = autoplayDelay;
      swiperRef.current.autoplay.start();
    }
  }, [autoplayDelay]);

  const handleSlideChange = (swiper: any) => {
    const currentIndex = swiper.realIndex;
    const delay = getDelayForSlide(currentIndex);
    setAutoplayDelay(delay);

    const videos = document.querySelectorAll<HTMLVideoElement>('.banner__video-desc, .banner__video-mob');
    videos.forEach(video => {
      video.pause();
      video.currentTime = 0;
      video.play().catch(error => {
        // console.error( error);
      });
    });

    const bullets = document.querySelectorAll<SVGCircleElement>('.progress-circleSl .circle-origin');
    bullets.forEach((circle, index) => {
      circle.style.transition = 'none';
      circle.style.strokeDashoffset = '350px';
      if (index === currentIndex) {
        setTimeout(() => {
          circle.style.transition = `stroke-dashoffset ${delay}ms linear`;
          circle.style.strokeDashoffset = '0px';
        }, 50);
      }
    });
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on('slideChange', handleSlideChange);
      handleSlideChange(swiperRef.current);
    }
  }, []);

  const handleVideoLoad = (index: number) => {
  setVideoLoaded(prevState => {
    const newState = [...prevState];
    newState[index] = true;
    return newState;
  });
};

    return (
        <section className="kv mb">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="kv__wrap">
                            <Swiper
                            onSwiper={(swiper: any) => {
                            swiperRef.current = swiper;
                            }}
                                pagination={{
                                    el: ".dots",
                                    clickable: true,
                                    renderBullet: function (index: number, className: string) {
                                        return (
                                            '<li class="' +
                                            className +
                                            '">' +
                                            '<button><i class="progressing progress-circle"></i></button>' +
                                            "</li>"
                                        );
                                    },
                                }}
                                breakpoints={{
                                    992: {
                                        pagination: {
                                            renderBullet: function (index: number, className: string) {
                                                return (
                                                     `<button class="${className}">
                          <svg class="progress-circleSl" width="100" height="100" viewBox="0 0 100 100">
                            <circle class="circle-origin" r="49" cx="50" cy="50"></circle>
                          </svg>
                          <span>${index + 1}</span>
                        </button>`
                                                );
                                            },
                                        },
                                    },
                                }}
                                autoplay={{
                                    delay: autoplayDelay,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination, EffectFade]}
                                effect={"fade"}
                                loop={true}
                                className="kv__slider"
                            >
                                {banners &&
                                    banners.map((banner, index) => (
                                        <SwiperSlide key={index}>
                                            <a className="slide-wrap banner"  href={banner.url}>
                                                {banner.image && (
                                                    <picture className="banner__img">
                                                        {banner.imageMob ? (
                                                            <source
                                                                srcSet={banner.imageMob}
                                                                media="(max-width: 992px)"
                                                            />
                                                        ) : (
                                                            <source srcSet={banner.image} media="(max-width: 992px)" />
                                                        )}
                                                        <img src={banner.image} alt="" />
                                                    </picture>
                                                )}

                                                {banner.video && (
                                                    <>
                                                    {!videoLoaded[index] && banner.preloadImage && (
                                                <picture className="banner__img">
                                                {banner.preloadImageMob ? (
                                                    <source
                                                        srcSet={banner.preloadImageMob}
                                                        media="(max-width: 992px)"
                                                    />
                                                ) : (
                                                    <source srcSet={banner.preloadImage} media="(max-width: 992px)" />
                                                )}
                                                <img src={banner.preloadImage} alt="загрузка..." />
                                                </picture>
                                                    )}
                                                        {banner.videoMob ? (
                                                            <>
                                                                <video
                                                                    className="banner__img banner__video-desc"
                                                                    src={banner.video}
                                                                    autoPlay
                                                                    playsInline
                                                                    loop
                                                                    muted
                                                                    onLoadedData={() => handleVideoLoad(index)}
                                                                ></video>
                                                                <video
                                                                    className="banner__img banner__video-mob"
                                                                    src={banner.videoMob}
                                                                    autoPlay
                                                                    playsInline
                                                                    loop
                                                                    muted
                                                                    onLoadedData={() => handleVideoLoad(index)}
                                                                ></video>
                                                            </>
                                                        ) : (
                                                            <video
                                                                className="banner__img banner__video-desc"
                                                                src={banner.video}
                                                                autoPlay
                                                                playsInline
                                                                loop
                                                                muted
                                                                onLoadedData={() => handleVideoLoad(index)}
                                                            ></video>
                                                        )}
                                                    </>
                                                )}

                                                <h1 className="banner__title f-exp">{banner.title}</h1>
                                                <p className="banner__text">{banner.description}</p>
                                                <a className="btn btn-primary" href={banner.url}>
                                                    {banner.buttonText}
                                                </a>
                                            </a>
                                        </SwiperSlide>
                                    ))}

                                <div className="slider-progress hidden">
                                    <div className="progressing progress-bar"></div>
                                </div>
                                <div className="kv__slider-dots">
                                    <div className="dots"></div>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
