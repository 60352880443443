import {Link, useLocation, useSearchParams} from "react-router-dom";
import {useEffect} from "react";

export default function Pagination({
                                       page,
                                       totalPages,
                                       setPage,
                                       additionalPages,
                                       setAdditionalPages,
                                       baseUrl,
                                       queryString = ''
                                   }: {
    page: number,
    totalPages: number,
    setPage: Function,
    additionalPages: number,
    setAdditionalPages: Function,
    baseUrl: string,
    queryString?: string,
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    useEffect(() => {
        if (searchParams.get('page')) {
            setPage(Number(searchParams.get('page')) || 1)
        }
        // setAdditionalPages(Number(searchParams.get('adp')) || 0)
    }, [location.pathname, searchParams.get('page'), searchParams.get('adp')]);


    const showMore = () => {
        if (page === totalPages) return
        searchParams.set('page', '' + (page + 1))
        // searchParams.set('adp', '' + (additionalPages + 1))
        setPage(page + 1)
        setAdditionalPages(additionalPages + 1)
        setSearchParams(searchParams)
    }

    const getBaseUrl = () => {
        return baseUrl + (baseUrl.indexOf('?') < 0 ? '?' : '') + 'page='
    }

    const getQueryString = () => {
        return queryString.replace(/&?(page|adp)=(\d+)/g, '').replace(/^&/, '')
    }

    const getPageStructure = () => {
        let t1 = false;
        let t2 = false;
        let t3 = false;
        return [
            Array(totalPages).fill(null).map((_, index) => {
                let cpage = index + 1
                if (cpage <= 2 || Math.abs(cpage - page) <= 2 || totalPages - cpage <= 1) {
                    t3 = false
                    return <li key={index}><Link className={cpage === page ? 'active' : ''}
                                                 to={getBaseUrl() + cpage + (getQueryString() ? '&' : '') + getQueryString()}>{cpage}</Link>
                    </li>
                }
                if (cpage > 2 && Math.abs(cpage - page) > 2 && !t1) {
                    t1 = true
                    t3 = true
                    return <li key={10000}><a className="empty" href="/" onClick={(event) => {
                        event.preventDefault()
                    }}>...</a></li>
                }
                if (cpage + 2 > page && Math.abs(cpage - page) > 2 && !t2 && !t3) {
                    t2 = true
                    return <li key={20000}><a className="empty" href="/" onClick={(event) => {
                        event.preventDefault()
                    }}>...</a></li>
                }
            })
        ]
    }


    return (<>
        {/*{page !== totalPages && <div className={'text-center'}>*/}
        {/*    <a className="btn btn-secondary" href="/" onClick={(event) => {*/}
        {/*        event.preventDefault()*/}
        {/*        showMore()*/}
        {/*    }}>Показать ещё</a>*/}
        {/*</div>}*/}
        <div className="paginator">
            <Link className="paginator__arrow paginator__arrow-prev"
                  to={getBaseUrl() + (page > 1 ? page - 1 : page) + (queryString ? '&' : '') + getQueryString()}><i
                className="ico ico-chevron-left-b"></i></Link>
            <ul>
                {getPageStructure()}
                {/*<li><a className="empty" href="">...</a></li>*/}
            </ul>
            <Link className="paginator__arrow paginator__arrow-next"
                  to={getBaseUrl() + (page < totalPages ? page + 1 : page) + (queryString ? '&' : '') + getQueryString()}><i
                className="ico ico-chevron-right-b"></i></Link>
        </div>
    </>)
}