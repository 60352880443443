import Breadcrumbs from "../Breadcrumbs";
import {useState} from "react";

export default function ReservationInShop() {
    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Резервирование товаров'
            }
        ]
    }

    const [currentBlock, setCurrentBlock] = useState<string|null>(null)

    const items = [
        {
            name: "Выберите товар",
            image: "/img/reserve/mob/img-1.png",
            icon: "ico-box"
        },
        {
            name: "Нажмите на кнопку «Зарезервировать в магазине»",
            image: "/img/reserve/mob/img-2.png",
            icon: "ico-map"
        },
        {
            name: "Выберите город",
            image: "/img/reserve/mob/img-3.png",
            icon: "ico-map-point"
        },
        {
            name: "Выберите размер",
            image: "/img/reserve/mob/img-4.png",
            icon: "ico-size"
        },
        {
            name: "Выберите цвет",
            image: "/img/reserve/mob/img-5.png",
            icon: "ico-colors"
        },
        {
            name: "Выберите магазин и нажмите «Забрать в магазине»",
            image: "/img/reserve/mob/img-6.png",
            icon: "ico-bag"
        },
        {
            name: "Заполните все обязательные поля и нажмите «Отправить заказ»",
            image: "/img/reserve/mob/img-7.png",
            icon: "ico-car-2"
        },
        {
            name: "Выкупите товар в магазине в течение 2 дней и получите 500 бонусов",
            image: "/img/reserve/mob/img-8.png",
            icon: "ico-wallet"
        }
    ];
    
    return (<main className="main-reserve">
            <section className="text-page reserve pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs()} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="text-page__main-banner">
                                <picture>
                                    <source
                                        srcSet="/img/temp/banner-3.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="/img/temp/banner-3.jpg" alt=""/>
                                </picture>
                                <div className="text">
                                    <h4 className="f-exp">
                                        Закажите онлайн
                                        <br/> и получите заказ
                                        <br className="mob"/> уже через 2 часа
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row reserve__head">
                        <div className="col-lg-6 text-page__left f-exp">
                            <h1 className="text-page__title">
                                Резервирование
                                <br className="desk"/> товаров
                            </h1>
                        </div>
                        <div className="col-lg-6 text-page__right">
                            <blockquote>
                                Мы готовимся к вашему приезду! Оформите резерв товаров на сайте,
                                чтобы всего через пару часов примерить все понравившиеся вещи в
                                магазине.Подтвердите свой заказ и получите его быстро и точно в
                                срок!
                            </blockquote>
                        </div>
                    </div>
                    <div className="row reserve__body">
                        <div className="col">
                            <div className="text-page__list">
                                {items.map(item => <div className={"text-page__list-block information bd " + (item.icon === currentBlock ? 'show' : '')}>
                                    <a className={"information__link f-exp " + (item.icon === currentBlock ? 'active' : '')} href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setCurrentBlock(item.icon)
                                    }}>
                                        <div className="circle">
                                            <i className={'icon ' + item.icon}/>
                                        </div>
                                        <p>{item.name}</p>
                                        <i className={"ico ico-chevron-down-b" }/>
                                    </a>
                                    {item.icon === currentBlock && <div className="information__collapse">
                                        <picture>
                                            <source
                                                srcSet={item.image}
                                                media="(max-width: 992px)"
                                            />
                                            <img src={item.image.replace('/mob/', '/')} alt=""/>
                                        </picture>
                                    </div>}
                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className="reserve__foot">
                        <div className="reserve__foot-wrap bd">
                            <div className="block">
                                <ul>
                                    <li>
                                        Если заказ был оформлен в период с 9:00 до 20:50 по московскому
                                        времени, с вами в течение получаса свяжется оператор
                                        интернет-магазина для уточнения деталей заказа. Когда заказ
                                        будет готов к выдаче, вам на телефон придет смс-уведомление с
                                        адресом магазина и сроками хранения заказа (48 часов).
                                    </li>
                                </ul>
                            </div>
                            <div className="block">
                                <ul>
                                    <li>
                                        Если заказ был оформлен в период с 21:00 ч до 9:00 следующего
                                        дня по московскому времени, ожидайте звонок оператора на
                                        следующий день – мы обязательно свяжемся с вами в порядке
                                        очерёдности.
                                    </li>
                                    <li>
                                        Если магазин не подтвердит наличие товара, мы проинформируем вас
                                        об этом дополнительным звонком.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <h3 className="f-exp">Желаем Вам приятных покупок!</h3>
                    </div>
                </div>
            </section>

        </main>
    )
}