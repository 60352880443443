import CatalogFilters from "./CatalogFilters";
import {Button, ButtonGroup, Col, Row} from "react-bootstrap";
import {FiltersInterface} from "../../interfaces/dealer/FiltersInterface";
import {useQuery} from "@tanstack/react-query";
import {getModels} from "../../api/dealer";
import React, {useContext, useEffect, useState} from "react";
import ModelList from "./ModelList";
import CreateOrder from "./CreateOrder";
import {useNavigate, useOutletContext} from "react-router-dom";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {OrderInterface as OrderForCreateInterface} from "../../interfaces/dealer/create/OrderInterface";
import {OutletInterface} from "../../interfaces/dealer/OutletInterface";
import RightModal from "../core/popup-collection/RightModal";
import ModelInterface from "../../interfaces/model/ModelInterface";
import ModelInfo from "./ModelInfo";
import PrintCatalog from "./PrintCatalog";

export default function Catalog() {
    const navigate = useNavigate();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [currentModel, setCurrentModel] = useState<ModelInterface | undefined>(undefined)

    const outletContext = useOutletContext<OutletInterface>();

    const [filters, setFilters] = useState<FiltersInterface | undefined>(undefined)

    const {data, isLoading} = useQuery({
        queryKey: ['dealer', 'catalog', 'models', filters],
        queryFn: () => getModels(filters as FiltersInterface),
        retry: false,
        enabled: !!filters
    })

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBackdrop', payload: !!currentModel})
    }, [currentModel]);

    useEffect(() => {
        if (isLoading) {
            dispatch({type: ReducerActionType.LOAD})
        } else {
            dispatch({type: ReducerActionType.SUCCESS})
        }
    }, [isLoading]);

    useEffect(() => {
        if(filters){
            // @ts-ignore
            document.getElementById('modelList')?.scrollIntoView({  behavior: 'smooth'  });
        }
    }, [data])

    const {models, properties} = data || {models: [], properties: []}

    const saveCurrentOrder = (order: OrderForCreateInterface) => {
        outletContext.setCurrentOrder(order)
    }

    const loadOrderPositions = () => {
        setFilters({id: outletContext.currentOrder.positions.map(position => position.model.id)})
    }

    return (<div>
            <div className="text-end">
                <button className={'btn btn-secondary btn-small'} onClick={() => {
                    navigate('/dealer/orders')
                }}>&larr; к списку заказов
                </button>

            </div>

            {currentModel && <RightModal additionalClass={'px-5'} closeFunction={() => {
                setCurrentModel(undefined)
            }}>
                <ModelInfo model={currentModel}/>
            </RightModal>}

            <h1>Каталог</h1>

            <CatalogFilters setFilters={setFilters}/>
            <CreateOrder order={outletContext.currentOrder} setOrder={outletContext.setCurrentOrder}
                         loadOrderPositions={loadOrderPositions}/>

            {models.length > 0 && <PrintCatalog filters={filters as FiltersInterface} />}

            <Row id={'modelList'} className={'pt-5'}>
                {models.map(model => <Col key={model.id} className={'p-md-4 model-item'} xl={6}>
                    <ModelList order={outletContext.currentOrder} setOrder={saveCurrentOrder} showInfo={setCurrentModel}
                               model={model}/>
                </Col>)}
            </Row>
        </div>
    )
}