import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {BasketPartConfigInterface} from "../../interfaces/checkout/BasketPartConfigInterface";
import {AddressInterface} from "../../interfaces/checkout/AddressInterface";

function AddressForm({basketPartConfig, setBasketPartConfig}:{
    basketPartConfig: BasketPartConfigInterface,
    setBasketPartConfig: (basketPartConfig: BasketPartConfigInterface) => void
}) {

    const {register, getValues, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    let watchAllFields = JSON.stringify(watch())

    useEffect(() => {
        setBasketPartConfig({...basketPartConfig, location: null, address: getValues() as AddressInterface})
    }, [watchAllFields]) // eslint-disable-line react-hooks/exhaustive-deps

    const isPostIndexRequired = () => {
        return basketPartConfig.delivery?.delivery_type?.is_post_index_required
    }

    return (<div className="basket__form bd">

            <h5 className="basket__form-title f-exp">Адрес доставки</h5>
            <form className="form--big" action="">
                <div className="form-group">
                    {isPostIndexRequired() && <input type="text" placeholder="индекс"
                                                     className={'form-control' + (errors.index ? ' is-invalid' : '')}  {...register("index")}/>}
                    <input type="text"
                           placeholder="Улица"
                           className={'form-control' + (errors.street ? ' is-invalid' : '')}  {...register("street", {required: true})}/>
                </div>
                <div className="form-wrap small">
                    <div className="form-group">
                        <input type="text"
                               placeholder={'Дом'}
                               className={'form-control' + (errors.home ? ' is-invalid' : '')}  {...register("home", {required: true})}/>
                        <i className="ico ico-delete delete"/>
                    </div>
                    <div className="form-group">
                        <input type="text"
                               placeholder={'корпус'}
                               className={'form-control' + (errors.housing ? ' is-invalid' : '')}  {...register("housing")}/>
                        <i className="ico ico-delete delete"/>
                    </div>
                    <div className="form-group">
                        <input type="text"
                               placeholder="Квартира"
                               className={'form-control' + (errors.flat ? ' is-invalid' : '')}  {...register("flat")}/>
                        <i className="ico ico-delete delete"/>
                    </div>
                </div>
                <div className="form-group">
          <textarea
              className="form-control form-con-p"
              placeholder={'Комментарий для курьера'}
              name=""
              defaultValue={""}
          />
                    <i className="ico ico-delete delete"/>
                </div>
            </form>
        </div>
    )
}

export default AddressForm