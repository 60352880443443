import TableSizeCalcInterface from "../../../interfaces/table-size/TableSizeCalcInterface";
import {useState, useEffect} from "react";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

interface CalcProps {
    TSCalculator: TableSizeCalcInterface;
    setState: (selectedTableOption:string) => void;
    state: string;
}


const TScalculator = ({TSCalculator, state, setState}: CalcProps) => {
    useEffect(() => {
        const change = TSCalculator.items.filter((item) => item.key === state).map((item) => item.value);

        setIsSelected(change);
    }, [state]);

    let sortKey = TSCalculator.items.filter((item) => item.key === state);
    const sortSelect = sortKey.map((item) => item.value);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedTableOption, setSelectedTableOption] = useState(state);
    const [selected, setIsSelected] = useState(sortSelect);

    const toggling = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    const onTableClicked = (value: any) => () => {
        setSelectedTableOption(value.key);
        setIsSelected(value.value);
        setState(value.key);
        setIsOpen(false);
    };

    return (
        <>
            {sortKey.map((SortItem, index) => (
                <div key={index}>
                    <p className="f-exp">{TSCalculator.name}</p>
                    <div className="form-selector big">
                        <a className="form-selector--btn f-exp" href="" onClick={toggling}>
                            <span>{selected}</span>
                        </a>
                        {isOpen && (
                            <div className="form-selector--list">
                                <SimpleBar style={{ maxHeight: 800 }}  autoHide={false}>
                                <div className="scrollbar-inner">
                                    <ul>
                                        {TSCalculator.items.map((item, index) => (
                                            <li key={index}>
                                                <a
                                                    className={"calcTab " + (SortItem.key === item.key ? "active" : "")}
                                                    onClick={onTableClicked(item)}
                                                >
                                                    {item.value}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                </SimpleBar>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default TScalculator;
