import ChooseYourLookPointInterface from "../../../interfaces/main/ChooseYourLookPointInterface";
import React, {useContext, useState} from "react";
import useModelColorItem from "../../catalog/useModelColorItem";
import {isMobile} from "react-device-detect";
import {getSuffixByCount} from "../../../utils/Utils";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import Money from "../../core/Money";
import {getBaseImagePath} from "../../catalog/Utils";

export default function ModalProduct({point}: { point: ChooseYourLookPointInterface }) {
    const AVAILABLE_COLORS_COUNT = 4;

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [selectedSize, setSelectedSize] = useState<string|null>(null);

    const {getImages, addToBasket, isExistInBasket, toggleFavorite, isExistInFavorite, isSizeAvailable, getImageAlt} = useModelColorItem(point.modelColorItem)

    const addToBasketAction = (size: string) => {
        addToBasket(size)
    }

    return (<div className="modal-basket__product">
        <div className="modal-basket__product-img">
            <img src={getBaseImagePath() + getImages().filter(image => !(image.type === 'print')).find((image, index) => index === 0)?.url} alt=""/>
        </div>
        <div className="modal-basket__product-info">
            <div className="wrap">
                <div className="left">
                    <ul className="card__meta">
                        {point.modelColorItem.review_estimate > 0 &&
                            <li><i className="ico ico-star"></i><p>{point.modelColorItem.review_estimate}</p></li>}
                        {point.modelColorItem.review_estimate === 0 &&
                            <li><i className="ico ico-star"></i><p>5</p></li>}
                        <li><i className="ico ico-feedback"></i><p>{point.modelColorItem.review_count} </p></li>
                    </ul>
                    <a href={`/catalog/${point.modelColorItem.model_id}/${point.modelColorItem.special_transliterated_name.toLowerCase()}/${point.modelColorItem.color_special.toLowerCase()}/`} className="modal-basket__product-title">{point.modelColorItem.name}</a>
                    <div className="product__kv-info-sizes ">
                        <ul className="sizes modal-mode">
                            {point.modelColorItem.sizes.map(size => <li key={size}><a
                                className={'basket-size pointer ' + (selectedSize === size ? 'active' : '') + (!isSizeAvailable(size) ? " model-size-disabled " : "") +
                                    (size.length > 4 ? " label-long" : "")}
                                onClick={(event) => {
                                    event.preventDefault()
                                    if (isSizeAvailable(size)) {
                                        setSelectedSize(size)
                                    }
                                }}>{size}</a></li>)}
                        </ul>
                    </div>
                    <ul className="card__colors">
                        {(isMobile && point.modelColorItem.sibling_colors.length > AVAILABLE_COLORS_COUNT ? point.modelColorItem.sibling_colors.slice(0, AVAILABLE_COLORS_COUNT) : point.modelColorItem.sibling_colors).map(color =>
                            <li key={color.color}
                                className={point.modelColorItem.color === color.color ? "active" : ""}>
                                <i style={{background: color.hexcolor}}></i></li>)}

                        {isMobile && point.modelColorItem.sibling_colors.length > AVAILABLE_COLORS_COUNT &&
                            <li className="card__colors-last mob">
                                <p>+ {point.modelColorItem.sibling_colors.length - AVAILABLE_COLORS_COUNT} цвет{getSuffixByCount(point.modelColorItem.sibling_colors.length - AVAILABLE_COLORS_COUNT)}</p>
                            </li>}
                    </ul>
                </div>
                <div className="right" style={{position: (isMobile ? 'static' : 'inherit')}}>
                    <div className="price f-exp">
                        {point.modelColorItem.min_price.amount !== point.modelColorItem.max_price.amount && <>
                            <h6 className="discount"><Money value={point.modelColorItem.min_price}/></h6>
                            <h6 className="old"><Money
                                value={point.modelColorItem.max_price}/></h6>
                        </>}
                        {point.modelColorItem.min_price.amount === point.modelColorItem.max_price.amount && <>
                            <h6><Money value={point.modelColorItem.min_price}/></h6>
                        </>}


                    </div>
                </div>
            </div>
            <a className="btn btn-secondary ico-cont" href="" onClick={(event) => {
                event.preventDefault()
                if(selectedSize){
                    addToBasketAction(selectedSize)
                }else{
                    dispatch({
                        type: ReducerActionType.ERROR,
                        payload: 'Выберите размер'
                    })
                }
            }}><i className="ico ico-bag-2"></i></a>
        </div>
    </div>)
}