import {useQuery} from "@tanstack/react-query";
import {getModelColorItems} from "../../api/catalog";
import {useLocation, useParams} from "react-router-dom";
import CatalogList from "./CatalogList";
import {useContext, useEffect, useState} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import NotFoundPage from "../core/pages/NotFoundPage";
import {Helmet, HelmetProvider} from "react-helmet-async";

export default function CatalogListByPath() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [clearCache, setClearCache] = useState(false)

    const location = useLocation()

    const getPathNameWithoutTag = () => {
        if (location.pathname.match(/\/tag\//)) {
            return location.pathname.replace(/\/tag\/.+/, '/')
        }

        return location.pathname
    }

    const {data: modelColorItemsData, status} = useQuery({
        queryKey: [...['catalog', 'list'], getPathNameWithoutTag(), clearCache],
        queryFn: () => getModelColorItems({
            url: getPathNameWithoutTag()
        }, clearCache),
        // @ts-ignore
        retry: !window.NOSCRIPT ? false : 1
    })

    useEffect(() => {
        // @ts-ignore
        window.prerenderReady = status !== 'pending';
    }, [status]);

    useEffect(() => {
        if (status === 'pending') {
            dispatch({type: ReducerActionType.LOAD})
        } else {
            dispatch({type: ReducerActionType.SUCCESS})
        }
    }, [status]);

    useEffect(() => {
        if (!location.pathname.match(/\/tag\//)) {
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: modelColorItemsData?.meta})
        }
    }, [location.pathname, modelColorItemsData?.meta]);

    const {
        model_color_items: modelColorItems,
        meta,
        category,
        breadcrumbs,
        banners,
        tags,
        super_categories,
        categories,
        properties,
        filter_tags,
        landing
    } = modelColorItemsData || {
        model_color_items: [],
        super_categories: [],
        categories:[],
        meta: undefined,
        properties: [],
        filter_tags: []
    }

    const getApplicationJson = () => {
        const prices = modelColorItems.map(colorItem => colorItem.min_price.amount / 100)

        const frontImages = modelColorItems.map(colorItem => 'https://baon.ru' + colorItem.images.find(image => image.type === 'front')?.url)

        return {
            "@context": "http://schema.org/",
            "@type": "Product",
            "name": meta?.html_header,
            "image": frontImages,
            "description": meta?.description,
            "url": "https://baon.ru" + location.pathname,
            "offers": {
                "@type": "AggregateOffer",
                "lowPrice": Math.min.apply(Math, prices),
                "highPrice": Math.max.apply(Math, prices),
                "priceCurrency": "RUB",
                "offerCount": modelColorItems.length,
                "availability": "http://schema.org/InStock",
                "seller": {
                    "@type": "Organization",
                    "name": "BAON"
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "877"
            }
        };
    }

    if (status === 'error') {
        return (<NotFoundPage/>);
    }

    return (<>
            <HelmetProvider>
                <Helmet>
                <script type="application/ld+json">{JSON.stringify(getApplicationJson())}</script>
                </Helmet>
            </HelmetProvider>
        <div>
            <CatalogList modelColorItems={modelColorItems} category={category} breadcrumbs={breadcrumbs}
                         banners={banners} superCategories={super_categories} categories={categories} tags={tags} properties={properties}
                         filterTags={filter_tags} landing={landing}/>
            {!clearCache && <span className={'text-white pointer px-3 py-1'} onClick={() => {
                setClearCache(true)
            }} aria-label={'очистить кеш'}>.</span>}
            {clearCache &&
                <div className={'text-center alert alert-danger mx-5'}><span>Данные без кеша / кеш обновлен</span>
                </div>}
        </div>
    </>)
}