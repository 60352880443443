import {DESKTOP, MOBILE} from '../../config/constants'
import Spacer from '../Spacer/Spacer.jsx'
import SpacerMobile from '../Spacer/Spacer.mobile.jsx'
import styles from './Subscribe.module.scss'
import {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import axios from 'axios'

const Subscribe = props => {

	const [message, setMessage] = useState("");

	const city_name = window.cityName
	const region_name = window.regionName

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState,
		formState: {errors}
	} = useForm();

	useEffect(() => {
		if (formState.isSubmitSuccessful) {
			reset({email: ""})
		}
	}, [formState, reset])


	let formSubmit = async (data) => {

		try {

			let res = await axios.post("https://baon.ru/api/v2/customer/subscribe", {
				email: data.email,
				city_name: city_name,
				region_name: region_name,
			});
			console.log("res", res.status)
			if (res.status === 200) {

				setMessage("Вы успешно подписались на рассылку");
			} else {

				setMessage("Произошла ошибка, попробуйте отправить email снова");
			}
		} catch (err) {

			setMessage("Такой email уже есть, попробуйте другой");
		}
	};

	return (
		<>
			<div
				className={
					props.params.clsSubscribe ? styles.blockSubscribe + ' subscribeCst ' + props.params.clsSubscribe.join(' ') : styles.blockSubscribe
				}
			>
				<div className={styles.blockSubscribe_header}>{props.params.title}</div>
				<p>{props.params.text}</p>
				<form onSubmit={handleSubmit(formSubmit)} className={styles.blockSubscribe_form}>
					<div className={styles.blockSubscribe_email}>
						<input
							id="email"
							type="email"
							className="form-control"
							placeholder="Введите ваш e-mail"
							{...register("email", {
								required: "Email is required",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Email заполнен неправильно"
								}
							})}
						/>

					</div>

					<button className="btn btn-secondary" type="submit">Подписаться</button>

				</form>
				<div className={styles.successMessage}>{message ? <p>{message}</p> : null}</div>
				{errors.email && <span>{errors.email.message}</span>}

			</div>
			<Spacer />
		</>
	)
}

export default Subscribe
