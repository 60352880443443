import {useContext} from "react";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../GlobalReducer";

export default function SeoBlock() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext)

    return (<>{state.meta?.seo_text && <section className="information">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div >
                        <div className="information__text" dangerouslySetInnerHTML={{__html: state.meta.seo_text}}/>
                    </div>
                </div>
            </div>
        </div>
    </section>}</>)
}