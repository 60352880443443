export const TabWomanJeans = [
    {
        name: "EUR",
        values: ["25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"],
    },
    {
        name: "RUS",
        values: ["40", "42", "42", "44", "44",  "46",  "46", "48", "50",  "50", "52", "54"],
    },
    {
        name: "обхват талии, см	",
        values: ["60", "62,5", " 65", "67,5", "70", "72,5", "75", "77,5", "80", "82,5", "87,5"],
    },
    {
        name: "обхват бедер, см	",
        values: ["88", "90,5", "93", "95,5", "98", "100,5", "103", "105,5", "108", "110,5", "115,5"],
    },
    {
        name: "рост, см	",
        values: ["170", "170", "170", "170", "170", "170", "170", "170", "170", "170", "170"],
    },
];

export const TabManJeans = [
    {
        name: "EUR",
        values: ["30", "31", "32", "33", "34", "36", "38", "40", "42"],
    },
    {
        name: "RUS",
        values: ["46", "48", "48", "50", "52", "54", "56", "58", "60"],
    },
    {
        name: "обхват талии, см	",
        values: ["80,5", "83", " 85,5", "88", "90,5", "95,5", "100,5", "105,5", "110,5"],
    },
    {
        name: "обхват бедер, см	",
        values: ["94", "96,5", "99", "101,5", "104", "109", "114", "119", "124"],
    },
    {
        name: "рост, см	",
        values: ["182", "182", "182", "182", "182", "182", "182", "182", "182"],
    },
];

export const TabWomanTop = [
    {
        name: "Размер",
        values: ["XXS", "XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL", "5XL"],
    },
    {
        name: "EUR",
        values: ["34", "36", "38", "40", "42", "44", "46", "48", "50", "52"],
    },
    {
        name: "RUS",
        values: ["40", "42", "44", "46", "48", "50", "52", "54", "56", "58"],
    },
    {
        name: "обхват груди, см	",
        values: ["80", "84", "88", " 92", "96", "100", "104", "108", "112", "116"],
    },
    {
        name: "обхват бедер, см	",
        values: ["88", "92", " 96", "100", "104", "108", "112", "116", "120", "124"],
    },
    {
        name: "обхват талии*, см	",
        values: ["60", "64", "68", "70", "74", "79", "83", "88", "93", "98"],
    },
    {
        name: "рост, см	",
        values: ["170", "170", "170", "170", "170", "170", "170", "170", "170", "170"],
    },
];

export const TabWomanSize = [
    {
        name: "RUS",
        values: ["52", "54", "56", "58", "60"],
    },
    {
        name: "обхват груди, см	",
        values: ["104", "108", "112", "116", "120"],
    },
    {
        name: "обхват бедер, см	",
        values: ["112", "116", "120", "124", "128"],
    },
    {
        name: "обхват талии*, см	",
        values: ["83", "88", "93", "98", "103"],
    },
    {
        name: "рост, см	",
        values: ["170", "170", "170", "170", "170"],
    },
];

export const TabManSize = [
    {
        name: "RUS",
        values: ["58", "60", "62	", "64"],
    },
    {
        name: "обхват груди, см	",
        values: ["116", "120", "124", " 128"],
    },
    {
        name: "обхват талии, см	",
        values: ["106", "110", " 114", "118"],
    },
    {
        name: "обхват бедер*, см	",
        values: ["113", "115", "118", "120"],
    },
    {
        name: "рост, см	",
        values: ["186", "186", "186", "186"],
    },
];

export const ManTop = [
    {
        name: "EUR",
        values: ["S", "M", "L", "XL", "XXL", "3XL", "4XL", "5XL"],
    },
    {
        name: "RUS",
        values: ["46", "48", "50", "52", "54", "56", "58", "60"],
    },
    {
        name: "обхват груди, см	",
        values: ["92", "96", "100", "104", "108-112", "112-116", "116-120", "120-124"],
    },
    {
        name: "обхват талии, см	",
        values: ["80", "84", "88", "92", "96-100", "100-104", "104-108", "108-112"],
    },
    {
        name: "обхват бедер*, см	",
        values: ["96,4", "99", "101,6", "104", "106-109", "109-112", "112-114,5", "114,5-117"],
    },
    {
        name: "рост, см	",
        values: ["176", "176", "182", "182", "188", "188", "188", "188"],
    },
];

export const ChildNew = [
    {
        name: "Возраст, лет	",
        values: ["5", "6", "7", "8", "9", "10", "11", "12-13"],
    },
    {
        name: "Рост, см	",
        values: ["110", "116", "122", "128", "134", "140", "146", "152-158"],
    },
    {
        name: "обхват груди, см	",
        values: ["56", "60", "60", "64", "68", "72", "76", "80"],
    },
    {
        name: "обхват талии, см",
        values: ["51", "54", "54", "57", "60", "63", "66", "66"],
    },
    {
        name: "Российский размер	",
        values: [
            "110-56-61",
            "116-60-54",
            "122-60-54",
            "128-64-57",
            "134-68-60",
            "140-72-63",
            "146-76-66",
            "158-80-66",
        ],
    },
];

export const ChildOld = [
    {
        name: "Группа*",
        values: ["K", "K", "K", "J", "J", "J"],
    },
    {
        name: "Возраст, лет	",
        values: ["3-4", "5-6", "7-8", "9-10", "11-12", "13"],
    },
    {
        name: "Рост, см	",
        values: ["98-104", "110-116", "122-128", "134-140", "145-152", "158"],
    },
    {
        name: "обхват груди, см	",
        values: ["56", "60", "64", "68", "76", "80"],
    },
    {
        name: "обхват талии, см",
        values: ["51", "54", "57", "60", "66", "66"],
    },
    {
        name: "Российский размер	",
        values: ["104-56-51", "116-60-54", "128-64-57	", "140-68-60	", "152-76-66	", "158-80-66"],
    },
];

export const ChildSocks = [
    {
        name: "Группа*",
        values: ["K", "K", "K", "J", "J", "J"],
    },
    {
        name: "Возраст, лет	",
        values: ["4", "5-6", "7-8", "9-10", "11-13"],
    },
    {
        name: "Длина стопы, см	",
        values: ["14-16", "16-18	", "18-20	", "20-22	", "22-24"],
    },
    {
        name: "Размер обуви	",
        values: ["23-25	", "26-28	", "29-31	", "32-34	", "35-38"],
    },
    {
        name: "Размер",
        values: ["23/25 (14-16)	", "26/28 (16-18)	", "29/31 (18-20)	", "32/34 (20-22)	", "35/38 (22-24)"],
    },
];

export const ChildHats = [
    {
        name: "Группа*",
        values: ["K", "K", "J"],
    },
    {
        name: "Возраст, лет	",
        values: ["3-6	", "6-10	", "10-13"],
    },
    {
        name: "Обхват головы, см		",
        values: ["50-52	", "52-54		", "54-56	"],
    },
    {
        name: "Размер",
        values: ["50", "52", "54"],
    },
];

export const ChildGloves = [
    {
        name: "Возраст, лет	",
        values: ["3-7	", "8-11"],
    },
    {
        name: "Обхват ладони, см		",
        values: ["13	", "16		"],
    },
    {
        name: "Размер",
        values: ["13", "16"],
    },
];

export const WomanGloves = [
    {
        name: "EUR	",
        values: ["S	", "M", "L"],
    },
    {
        name: "общая длина, см		",
        values: ["29,5	", "30,5", "31,5"],
    },
    {
        name: "ширина ладони, см			",
        values: ["10	", "10,5		", "11"],
    },
    {
        name: "ширина входа, см	",
        values: ["13", "13,5", "14,5"],
    },
];

export const ManGloves = [
    {
        name: "EUR	",
        values: ["M	", "L", "XL"],
    },
    {
        name: "общая длина, см		",
        values: ["31	", "32", "33"],
    },
    {
        name: "ширина ладони, см			",
        values: ["12	", "12,5		", "13"],
    },
    {
        name: "ширина входа, см	",
        values: ["15,5", "16", "16,5"],
    },
];

export const WomanTigths = [
    {
        name: "Вес, кг		",
        values: ["45", "50", "55", "60", "65", "70", "75", "80", "85"],
    },
    {
        name: "Рост, см	145",
        values: ["", "2", "2", "2", "2", "3", "3", "3", "4"],
    },
    {
        name: "Рост, см	150",
        values: [],
    },
    {
        name: "Рост, см	155",
        values: ["2", "", "", "", "3", "", "", "4", ""],
    },
    {
        name: "Рост, см	160",
        values: ["", "2", "2", "2", "2", "3", "3", "3", "4"],
    },
    {
        name: "Рост, см	165",
        values: ["", "", "", "3", "", "", "4", "", ""],
    },
    {
        name: "Рост, см	170",
        values: ["3", "3", "3", "", "", "4", "", "", ""],
    },
    {
        name: "Рост, см	175",
        values: [],
    },
    {
        name: "Рост, см	180",
        values: ["", "", "", "4", "4", "", "", "", ""],
    },
    {
        name: "Рост, см	185",
        values: [],
    },
];

export const WomenSizes = [
    {
        name: "Обхват груди, см",
        items: [
            {
                key: "key1",
                value: "82-86",
            },
            {
                key: "key2",
                value: "87-90",
            },
            {
                key: "key3",
                value: "91-94",
            },
            {
                key: "key4",
                value: "95-98",
            },
            {
                key: "key5",
                value: "99-102",
            },
            {
                key: "key6",
                value: "103-106",
            },
            {
                key: "key7",
                value: "107-110",
            },
            {
                key: "key8",
                value: "110-113",
            },
        ],
    },
    {
        name: "Обхват бёдер, см",
        items: [
            {
                key: "key1",
                value: "90-94",
            },
            {
                key: "key2",
                value: "95-100",
            },
            {
                key: "key3",
                value: "99-102",
            },
            {
                key: "key4",
                value: "103-106",
            },
            {
                key: "key5",
                value: "107-110",
            },
            {
                key: "key6",
                value: "111-114",
            },
            {
                key: "key7",
                value: "115-118",
            },
            {
                key: "key8",
                value: "119-122",
            },
        ],
    },
    {
        name: "Размеры",
        items: [
            {
                key: "key1",
                value: "XS (42)",
            },
            {
                key: "key2",
                value: "S (44)",
            },
            {
                key: "key3",
                value: "M (46)",
            },
            {
                key: "key4",
                value: "L (48)",
            },
            {
                key: "key5",
                value: "XL (50)",
            },
            {
                key: "key6",
                value: "XXL (52)",
            },
            {
                key: "key7",
                value: "3XL (54)",
            },
            {
                key: "key8",
                value: "4XL (56)",
            },
        ],
    },
];

export const MenSizes = [
    {
        name: "Обхват груди, см",
        items: [
            {
                key: "key1",
                value: "90-94",
            },
            {
                key: "key2",
                value: "95-98",
            },
            {
                key: "key3",
                value: "99-102",
            },
            {
                key: "key4",
                value: "103-106",
            },
            {
                key: "key5",
                value: "107-112",
            },
            {
                key: "key6",
                value: "112-116",
            },
            {
                key: "key7",
                value: "116-120",
            },
        ],
    },
    {
        name: "Обхват талии, см",
        items: [
            {
                key: "key1",
                value: "79-82",
            },
            {
                key: "key2",
                value: "83-86",
            },
            {
                key: "key3",
                value: "87-90",
            },
            {
                key: "key4",
                value: "91-94",
            },
            {
                key: "key5",
                value: "95-100",
            },
            {
                key: "key6",
                value: "100-104",
            },
            {
                key: "key7",
                value: "104-108",
            },
        ],
    },
    {
        name: "Размеры",
        items: [
            {
                key: "key1",
                value: "S (46)",
            },
            {
                key: "key2",
                value: "M (48)",
            },
            {
                key: "key3",
                value: "L (50)",
            },
            {
                key: "key4",
                value: "XL (52)",
            },
            {
                key: "key5",
                value: "XXL (54)",
            },
            {
                key: "key6",
                value: "3XL (56)",
            },
            {
                key: "key7",
                value: "4XL (58)",
            },
        ],
    },
];

export const MenJeans = [
    {
        name: "Обхват талии, см",
        items: [
            {
                key: "key1",
                value: "80-82",
            },
            {
                key: "key2",
                value: "82-84",
            },
            {
                key: "key3",
                value: "84-87",
            },
            {
                key: "key4",
                value: "87-89",
            },
            {
                key: "key5",
                value: "89-95",
            },
            {
                key: "key6",
                value: "95-99",
            },
            {
                key: "key7",
                value: "99-103",
            },
        ],
    },
    {
        name: "Обхват бёдер, см",
        items: [
            {
                key: "key1",
                value: "93-95",
            },
            {
                key: "key2",
                value: "95-97",
            },
            {
                key: "key3",
                value: "97-100",
            },
            {
                key: "key4",
                value: "100-102",
            },
            {
                key: "key5",
                value: "102-107",
            },
            {
                key: "key6",
                value: "108-112",
            },
            {
                key: "key7",
                value: "112-116",
            },
        ],
    },
    {
        name: "Размеры",
        items: [
            {
                key: "key1",
                value: "30",
            },
            {
                key: "key2",
                value: "31",
            },
            {
                key: "key3",
                value: "32",
            },
            {
                key: "key4",
                value: "33",
            },
            {
                key: "key5",
                value: "34",
            },
            {
                key: "key6",
                value: "36",
            },
            {
                key: "key7",
                value: "38",
            },
        ],
    },
];

export const WomenJeans = [
    {
        name: "Обхват талии, см",
        items: [
            {
                key: "key1",
                value: "60-62",
            },
            {
                key: "key2",
                value: "62-64",
            },
            {
                key: "key3",
                value: "64-66",
            },
            {
                key: "key4",
                value: "66-68",
            },
            {
                key: "key5",
                value: "69-71",
            },
            {
                key: "key6",
                value: "71-73",
            },
            {
                key: "key7",
                value: "74-76",
            },
            {
                key: "key8",
                value: "76-78",
            },
            {
                key: "key9",
                value: "79-81",
            },
        ],
    },
    {
        name: "Обхват бёдер, см",
        items: [
            {
                key: "key1",
                value: "88-90",
            },
            {
                key: "key2",
                value: "90-92",
            },
            {
                key: "key3",
                value: "92-94",
            },
            {
                key: "key4",
                value: "94-96",
            },
            {
                key: "key5",
                value: "97-99",
            },
            {
                key: "key6",
                value: "99-101",
            },
            {
                key: "key7",
                value: "102-104",
            },
            {
                key: "key8",
                value: "104-107",
            },
            {
                key: "key9",
                value: "107-109",
            },
        ],
    },
    {
        name: "Размеры",
        items: [
            {
                key: "key1",
                value: "25",
            },
            {
                key: "key2",
                value: "26",
            },
            {
                key: "key3",
                value: "27",
            },
            {
                key: "key4",
                value: "28",
            },
            {
                key: "key5",
                value: "29",
            },
            {
                key: "key6",
                value: "30",
            },
            {
                key: "key7",
                value: "31",
            },
            {
                key: "key8",
                value: "32",
            },
            {
                key: "key9",
                value: "33",
            },
        ],
    },
];
