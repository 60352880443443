import {useContext} from "react";
import {GlobalContext} from "../../GlobalReducer";
import {getRequestCreateOrderParams} from "../checkout/utils/Context";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function TechInfo() {
    // eslint-disable-next-line
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    return (
        <div className="card mt-4">
            <div className="card-body pb-4">
                <div className="row">
                    <div className="col block-header">
                        <h4>Tech Info</h4>
                    </div>
                </div>

                <div className={'card card-internal mt-2'}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 overflow-auto" style={{'height' : '400px'}}>
                                <pre style={{ border: '1px solid #DDDDDD'}}>{JSON.stringify(state, null, 2)}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}