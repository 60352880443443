import React, {useContext, useEffect, useState} from "react";
import Moment from "react-moment";
import Money from "../core/Money";
import {useParams, useNavigate, useOutletContext, Link} from "react-router-dom";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {OutletInterface} from "../../interfaces/dealer/OutletInterface";
import {getSingleOrder} from "../../api/dealer";
import {OrdersResponseInterface} from "../../interfaces/dealer/OrdersResponseInterface";


export default function OrderList() {

    const [dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const outletData = useOutletContext<OutletInterface>();

    const queryData = useParams<{ orderId: string }>();

    const navigate = useNavigate();

    const [data, setData] = useState<OrdersResponseInterface|undefined>(undefined);

    useEffect(() => {
        if (outletData?.ordersData) {
            setData(outletData?.ordersData)
        }
    }, [outletData?.ordersData]);

    useEffect(() => {
        if(!queryData.orderId){
            return
        }

        getSingleOrder(parseInt(queryData.orderId)).then((response) => {
            console.log(response)
        })


    }, [queryData.orderId]);

    return (
        <div>
            {data?.order_statistic && data?.order_statistic?.length > 0 && <div className="col-md-8 offset-md-2">
                <div className="text-end">
                    <button className={'btn btn-secondary btn-small'} onClick={() => {
                        navigate('/dealer')
                    }}>&larr; в каталог
                    </button>

                </div>
                <h1>Список заказов</h1>
                <table className="table">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Дата оформления</th>
                        <th className={'text-end'}>Кол-во позиций</th>
                        <th className={'text-end'}>Стоимость</th>
                        <th>Статус</th>
                        <th>Комментарий</th>
                        <th>Менеджер</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.order_statistic?.map(item => <tr className={'pointer'} key={item.order_id}
                                                              onClick={() => navigate("/dealer/order/" + item.order_id)}>
                        <td>{item.order_id}</td>
                        <td><Moment format={"DD.MM.YYYY HH.mm"}>{item.create_date}</Moment></td>
                        <td className={'text-end'}>{item.quantity}</td>
                        <td className={'text-end'}><Money value={item.total}/></td>
                        <td>{item.status_name}</td>
                        <td>{item.comment}</td>
                        <td>{item.manager_fio}</td>
                    </tr>)}
                    </tbody>

                </table>
            </div>}
        </div>
    )
}