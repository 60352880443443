import {useState} from 'react'

import Button from './components/Button/Button.jsx'
import ButtonElem from './components/ButtonElem/ButtonElem.jsx'
import Faq from './components/FAQ/Faq.jsx'
import GridImages from './components/GridImages/GridImages.jsx'
import GridThreeElems from './components/GridThreeElems/GridThreeElems.jsx'
import GridTwoElems from './components/GridTwoElems/GridTwoElems.jsx'
import Image from './components/Image/Image.jsx'
import ImageLong from './components/ImageLong/ImageLong.jsx'
import LongBanner from './components/LongBanner/LongBanner.jsx'
import Row from './components/Row/Row.jsx'
import Slider from './components/Slider/Slider.jsx'
import SliderBanner from './components/SliderBanner/SliderBanner.jsx'
import SliderBannerShort from './components/SliderBannerShort/SliderBannerShort.jsx'
import Text from './components/Text/Text.jsx'
import TextHeader from './components/TextHeader/TextHeader.jsx'
import TextMarkdown from './components/TextMarkdown/TextMarkdown.jsx'
import Video from './components/Video/Video.jsx'
import VideoFull from './components/VideoFull/VideoFull.jsx'
import Subscribe from './components/Subscribe/Subscribe.jsx'
import Timer from './components/Timer/Timer.jsx'
import RegistrationForm from './components/RegistrationForm/RegistrationForm.jsx'
import CooperationForm from './components/CooperationForm/CooperationForm.jsx'
import StylistForm from './components/StylistForm/StylistForm.jsx'
import AuthLandingForm from './components/AuthLandingForm/AuthLandingForm.jsx'
import BannerText from './components/BannerText/BannerText.jsx'
import HiddenText from './components/HiddenText/HiddenText.jsx'
import {
    DESKTOP,
    MOBILE,
    TYPE_BUTTON,
    TYPE_BUTTONELEM,
    TYPE_FAQ,
    TYPE_GRIDIMAGES,
    TYPE_GRIDTHREEELEMS,
    TYPE_GRIDTWOELEMS,
    TYPE_LONG_BANNER,
    TYPE_ROW,
    TYPE_SIMPLE_IMAGE,
    TYPE_IMAGELONG,
    TYPE_SLIDER,
    TYPE_SLIDERBANNER,
    TYPE_SLIDERBANNERSHORT,
    TYPE_TEXT,
    TYPE_TEXTHEADER,
    TYPE_TEXTMARKDOWN,
    TYPE_VIDEO,
    TYPE_VIDEOFULL,
    TYPE_SUBSCRIBE,
    TYPE_TIMER,
    TYPE_REGFORM,
    TYPE_COOPERATION,
    TYPE_STYLIST,
    TYPE_AUTH,
    TYPE_BANNERTEXT,
    TYPE_HIDDENTEXT,
} from './config/constants'

import {LandingBlockInterface} from "../../interfaces/landing/LandingBlockInterface";

export function Landing({landingItems, error}: { landingItems: LandingBlockInterface[], error: string }) {
    const [viewMode, setViewMode] = useState(DESKTOP)

    return (
        <>
            {error && <h3 className={'text-danger text-center'}>{error}</h3>}
            {landingItems.map(item => (
                <>
                    {item.type === TYPE_LONG_BANNER && (
                        <LongBanner viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_VIDEO && (
                        <Video viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_VIDEOFULL && (
                        <VideoFull viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_TEXT && (
                        <Text viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_SIMPLE_IMAGE && (
                        <Image viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_IMAGELONG && (
                        <ImageLong viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_BUTTON && (
                        <Button viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_SLIDER && (
                        <Slider viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_TEXTHEADER && (
                        <TextHeader viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_TEXTMARKDOWN && (
                        <TextMarkdown viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_GRIDIMAGES && (
                        <GridImages viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_FAQ && <Faq viewMode={viewMode} params={item}/>}
                    {item.type === TYPE_GRIDTWOELEMS && (
                        <GridTwoElems viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_GRIDTHREEELEMS && (
                        <GridThreeElems viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_ROW && <Row viewMode={viewMode} params={item}/>}
                    {item.type === TYPE_SLIDERBANNER && (
                        <SliderBanner viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_SLIDERBANNERSHORT && (
                        <SliderBannerShort viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_BUTTONELEM && (
                        <ButtonElem viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_SUBSCRIBE && (
                        <Subscribe viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_TIMER && (
                        <Timer viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_REGFORM && (

                        <RegistrationForm viewMode={viewMode} params={item}/>

                    )}
                    {item.type === TYPE_COOPERATION && (
                        <CooperationForm viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_STYLIST && (
                        <StylistForm viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_AUTH && (
                        <AuthLandingForm viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_BANNERTEXT && (
                        <BannerText viewMode={viewMode} params={item}/>
                    )}
                    {item.type === TYPE_HIDDENTEXT && (
                        <HiddenText viewMode={viewMode} params={item}/>
                    )}
                </>
            ))}
        </>
    )
}

export default Landing