export default function Cooperation() {
    return (<div
        className="page-company__coop tab-pane fade show active"
    >
        <div className="row page-company__coop-text">
            <div className="col-lg-6 offset-lg-1 img">
                <picture>
                    <source
                        srcSet="/img/company/cooperation-img-1-mob.png"
                        media="(max-width: 992px)"
                    />
                    <img src="/img/company/cooperation-img-1.png" alt=""/>
                </picture>
            </div>
            <div className="col-lg-4 text">
                <h3 className="f-exp">С нами надёжно</h3>
                <p>
                    BAON сегодня - это разработка, производство и продажа сезонных
                    коллекций мужской, женской, детской одежды и аксессуаров в
                    среднем ценовом сегменте. Бренд на рынке уже более 30 лет и
                    хорошо знаком аудитории.
                </p>
            </div>
        </div>
        <div className="row page-company__coop-text">
            <div className="col-lg-4 offset-lg-1 text">
                <h3 className="f-exp">Условия сотрудничества</h3>
                <ol>
                    <li>
                        <p>Минимальная сумма заказа 100 000 рублей.</p>
                    </li>
                    <li>
                        <p>
                            Партнерам предоставляется право на использование ТМ BAON в
                            точках розничных продаж.
                        </p>
                    </li>
                    <li>
                        <p>Партнерский магазин должен находиться на расстоянии</p>
                    </li>
                    <li>
                        <p>не менее 1,5 км от фирменного магазина BAON.</p>
                    </li>
                </ol>
            </div>
            <div className="col-lg-6 img">
                <picture>
                    <source
                        srcSet="/img/company/cooperation-img-2-mob.png"
                        media="(max-width: 992px)"
                    />
                    <img src="/img/company/cooperation-img-2.png" alt=""/>
                </picture>
            </div>
        </div>
        <div className="row page-company__coop-scheme">
            <div className="col-lg-10 offset-lg-1">
                <h3>Схема работы</h3>
                <ul>
                    <li>
                        <img src="/img/svg/cooperation-ico-1.svg" alt=""/>
                        <p className="f-exp">
                            1. Оформление заявки на&nbsp;сотрудничество
                        </p>
                        <p>
                            Вам будет присвоен индивидуальный логин и&nbsp;пароль для
                            входа на&nbsp;сайт и&nbsp;оформления заказа.
                        </p>
                    </li>
                    <li>
                        <img src="/img/svg/cooperation-ico-2.svg" alt=""/>
                        <p className="f-exp">2. Подписание договора</p>
                        <p>
                            Перечень <a href="">документов</a>, необходимых для
                            заключения договора.
                        </p>
                    </li>
                    <li>
                        <img src="/img/svg/cooperation-ico-3.svg" alt=""/>
                        <p className="f-exp">3. Формирование заказа</p>
                        <p>
                            На&nbsp;основании заявки на&nbsp;заказ формируется счёт
                            на&nbsp;оплату, согласовывается дата и&nbsp;способ отгрузки.
                        </p>
                    </li>
                    <li>
                        <img src="/img/svg/cooperation-ico-4.svg" alt=""/>
                        <p className="f-exp">4. Отгрузка и&nbsp;оплата товара</p>
                        <p>
                            Отгрузка оплаченного заказа происходит в&nbsp;течении двух
                            рабочих дней от&nbsp;даты оплаты.
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-10 offset-lg-1">
                <div className="page-company__coop-app">
                    <form action="">
                        <h4 className="modal-form__title f-exp">
                            Заявка на сотрудничество
                        </h4>
                        <div className="form-wrap">
                            <div className="form-group">
                                <label className="form-label" htmlFor="">
                                    ФИО
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="ФИО"
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="">
                                    Город проживания
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Название города"
                                />
                            </div>
                        </div>
                        <div className="form-wrap">
                            <div className="form-group">
                                <label className="form-label" htmlFor="">
                                    Телефон
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="">
                                    Где планируете открыть магазин
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Название города"
                                />
                            </div>
                        </div>
                        <div className="form-wrap">
                            <div className="form-group">
                                <label className="form-label" htmlFor="">
                                    Е-mail
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="E-mail"
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label" htmlFor="">
                                    Дополнительная информация
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Информация"
                                />
                            </div>
                        </div>
                        <div className="form-btns form-btns--full">
                            <a className="btn btn-secondary" href="">
                                Отправить заявку
                            </a>
                        </div>
                    </form>
                    <picture>
                        <source
                            srcSet="/img/company/cooperation-img-3-mob.png"
                            media="(max-width: 992px)"
                        />
                        <img src="/img/company/cooperation-img-3.png" alt=""/>
                    </picture>
                </div>
            </div>
        </div>
        <div className="row page-company__coop-menu">
            <div className="col-lg-4">
                <div className="banner">
                    <picture className="banner__img">
                        <source
                            srcSet="/img/company/cooperation-menu-img-1-mob.png"
                            media="(max-width: 992px)"
                        />
                        <img src="/img/company/cooperation-menu-img-1.png" alt=""/>
                    </picture>
                    <h3 className="banner__small-title f-exp">Level Up</h3>
                    <p className="banner__text">Новая линия бренда</p>
                    <a className="btn btn-primary" href="">
                        Смотреть
                    </a>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="banner">
                    <picture className="banner__img">
                        <source
                            srcSet="/img/company/cooperation-menu-img-2-mob.png"
                            media="(max-width: 992px)"
                        />
                        <img src="/img/company/cooperation-menu-img-2.png" alt=""/>
                    </picture>
                    <h3 className="banner__small-title f-exp">Why Not?</h3>
                    <p className="banner__text">Новая коллекция</p>
                    <a className="btn btn-primary" href="">
                        Смотреть
                    </a>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="banner">
                    <picture className="banner__img">
                        <source
                            srcSet="/img/company/cooperation-menu-img-3-mob.png"
                            media="(max-width: 992px)"
                        />
                        <img src="/img/company/cooperation-menu-img-3.png" alt=""/>
                    </picture>
                    <h3 className="banner__small-title f-exp">New collection</h3>
                    <p className="banner__text">Новая коллекция AW`23-24</p>
                    <a className="btn btn-primary" href="">
                        Смотреть
                    </a>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-10 offset-lg-1">
                <div className="page-company__coop-enter">
                    <picture>
                        <source
                            srcSet="/img/company/cooperation-img-4-mob.png"
                            media="(max-width: 992px)"
                        />
                        <img src="/img/company/cooperation-img-4.png" alt=""/>
                    </picture>
                    <form action="">
                        <h4 className="modal-form__title f-exp">
                            Вход в личный кабинет
                        </h4>
                        <div className="form-text">
                            <p>
                                Вход в систему для оформления заказов оптовых покупателей.
                                Для того, чтобы получить логин и пароль, нужно оформить
                                заявку на сотрудничество.
                            </p>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="">
                                Логин
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Логин"
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="">
                                Пароль
                            </label>
                            <input className="form-control" type="password"/>
                        </div>
                        <div className="form-btns form-btns--full">
                            <a className="btn btn-secondary" href="">
                                Войти
                            </a>
                        </div>
                        <div className="form-foot">
                            <div className="form-text">
                                <p>
                                    Нужна помощь? Вы можете обратиться к нам по указанным
                                    контактам:
                                </p>
                            </div>
                            <div className="links">
                                <a className="ico-cont" href="">
                                    <i className="ico ico-left ico-mail"/>
                                    b2b@baon.ru
                                </a>
                                <a className="ico-cont" href="">
                                    <i className="ico ico-left ico-phone"/>
                                    +7 (495) 698-61-76 (доб. 908)
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>)
}