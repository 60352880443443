import TSSwitcher from "./TSSwitcher";
import TSCalculator from "./TSCalculator";
import TSTables from "./TSTables";
import TableInfo from "./TableInfo";
import {useState} from "react";
import {
    WomenSizes,
    MenSizes,
    MenJeans,
    WomenJeans,
    TabWomanJeans,
    TabManJeans,
    TabWomanTop,
    TabWomanSize,
    TabManSize,
    ManTop,
    ChildNew,
    ChildOld,
    ChildSocks,
    ChildHats,
    ChildGloves,
    WomanGloves,
    ManGloves,
    WomanTigths,
} from "./TableConfig";

// import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export enum TableSizeTabs {
    MEN = "MEN",
    MENTOP = "MENTOP",
    WOMEN = "WOMEN",
    WOMENTOP = "WOMENTOP",
    CHILD = "CHILD",
}

const TableWomenSizes = [
    {
        key: "XS",
        chest: "82-86",
        hip: "90-94",
        size: "XS (42)",
    },
    {
        key: "S",
        chest: "87-90",
        hip: "95-98",
        size: "S (44)",
    },
    {
        key: "M",
        chest: "91-94",
        hip: "99-102",
        size: "M (46)",
    },
    {
        key: "L",
        chest: "95-98",
        hip: "103-106",
        size: "L (48)",
    },
    {
        key: "XL",
        chest: "99-102",
        hip: "107-110",
        size: "XL (50)",
    },
    {
        key: "XXL",
        chest: "103-106",
        hip: "111-114",
        size: "XXL (52)",
    },
    {
        key: "3XL",
        chest: "107-110",
        hip: "115-118",
        size: "3XL (54)",
    },
    {
        key: "4XL",
        chest: "110-113",
        hip: "119-122",
        size: "4XL (56)",
    },
];

export default function TableSizes() {
    const [currentSize, setCurrentSize] = useState<TableSizeTabs>(TableSizeTabs.WOMENTOP);

    const [tabWTState, setTabWTState] = useState("key1");
    const [tabWBState, setTabWBState] = useState("key1");
    const [tabMTState, setTabMTState] = useState("key1");
    const [tabMBState, setTabMBState] = useState("key1");

    return (
        <>
            <div className=" modal-table-sizes">
                <div className="modal-content">
                    <div>
                        <TSSwitcher currentSize={currentSize} setCurrentSize={setCurrentSize} />
                        <div className="tab-content" id="myTabContents">
                            <div
                                className={"tab-pane  " + (currentSize === TableSizeTabs.WOMENTOP ? "show active" : "")}
                                role="tabpanel"
                            >
                                <h3 className="f-exp">Калькулятор размеров одежды</h3>
                                <ul className="calculates">
                                    {WomenSizes.map((size, index) => (
                                        <li key={index}>
                                            <TSCalculator
                                                TSCalculator={size}
                                                state={tabWTState}
                                                setState={setTabWTState}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <div className="tables">
                                    <div className="tables__list">
                                        <h3 className="f-exp">Для новых коллекций</h3>
                                        <TSTables TSTable={TabWomanTop} />

                                        <h3 className="f-exp">Size plus</h3>
                                        <TSTables TSTable={TabWomanSize} />

                                        <p>
                                            * — Обхват талии является вторичным признаком, т.е. не обязательно должен
                                            соответствовать указанным параметрам.
                                        </p>

                                        <h3 className="f-exp">Горнолыжные перчатки</h3>
                                        <TSTables TSTable={WomanGloves} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={"tab-pane  " + (currentSize === TableSizeTabs.WOMEN ? "show active" : "")}
                                role="tabpanel"
                            >
                                <h3 className="f-exp">Калькулятор размеров джинс</h3>
                                <ul className="calculates">
                                    {WomenJeans.map((size, index) => (
                                        <li key={index}>
                                            <TSCalculator
                                                TSCalculator={size}
                                                state={tabWBState}
                                                setState={setTabWBState}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <div className="tables">
                                    <div className="tables__list">
                                        <h3 className="f-exp">Джинсы</h3>
                                        <TSTables TSTable={TabWomanJeans} />

                                        <h3 className="f-exp">Колготки</h3>
                                        <TSTables TSTable={WomanTigths} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={"tab-pane  " + (currentSize === TableSizeTabs.MENTOP ? "show active" : "")}
                                role="tabpanel"
                            >
                                <h3 className="f-exp">Калькулятор размеров одежды</h3>
                                <ul className="calculates">
                                    {MenSizes.map((size, index) => (
                                        <li key={index}>
                                            <TSCalculator
                                                TSCalculator={size}
                                                state={tabMTState}
                                                setState={setTabMTState}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <div className="tables">
                                    <div className="tables__list">
                                        <h3 className="f-exp">Для новых коллекций</h3>
                                        <TSTables TSTable={ManTop} />

                                        <h3 className="f-exp">Size plus</h3>
                                        <TSTables TSTable={TabManSize} />

                                        <p>
                                            * — Обхват бедер является вторичным признаком, т.е. не обязательно должен
                                            соответствовать указанным параметрам.
                                        </p>

                                        <h3 className="f-exp">Горнолыжные перчатки</h3>
                                        <TSTables TSTable={ManGloves} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={"tab-pane  " + (currentSize === TableSizeTabs.MEN ? "show active" : "")}
                                role="tabpanel"
                            >
                                <h3 className="f-exp">Калькулятор размеров джинс</h3>
                                <ul className="calculates">
                                    {MenJeans.map((size, index) => (
                                        <li key={index}>
                                            <TSCalculator
                                                TSCalculator={size}
                                                state={tabMBState}
                                                setState={setTabMBState}
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <div className="tables">
                                    <div className="tables__list">
                                        <h3 className="f-exp">Джинсы</h3>
                                        <TSTables TSTable={TabManJeans} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={"tab-pane  " + (currentSize === TableSizeTabs.CHILD ? "show active" : "")}
                                role="tabpanel"
                            >
                                <div className="tables">
                                    <div className="tables__list">
                                        <h3 className="f-exp">Для новых коллекций</h3>
                                        <TSTables TSTable={ChildNew} />
                                        <h3 className="f-exp">Для старых коллекций</h3>
                                        <TSTables TSTable={ChildOld} />
                                        <h3 className="f-exp">Носочки</h3>
                                        <TSTables TSTable={ChildSocks} />
                                        <h3 className="f-exp">Головные уборы</h3>
                                        <TSTables TSTable={ChildHats} />
                                        <h3 className="f-exp">Перчатки и варежки</h3>
                                        <TSTables TSTable={ChildGloves} />

                                        <p>* - K – Kids - дети до 8 лет, J - Junior - дети до 13 лет.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <TableInfo />
                    </div>
                </div>
            </div>
        </>
    );
}
