import React, {useContext, useEffect, useRef} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {BasketItemInterface} from "../../../interfaces/core/BasketItemInterface";
import Money from "../Money";
import ModelImageInterface from "../../../interfaces/model/ModelImageInterface";
import {MAIN_IMAGE_TYPE} from "../../../Config";
import MoneyInterface from "../../../interfaces/core/MoneyInterface";
import {getBaseImagePath} from "../../catalog/Utils";
import {Link} from "react-router-dom";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import useDatalayer, {EcommerceAction} from "../../../utils/useDataLayer";



import 'simplebar-react/dist/simplebar.min.css';

declare var $: any;

export default function BasketPopup() {
    const {action: dataLayerManagerAction} = useDatalayer();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const basket: BasketItemInterface[] = state.basket

    const modalRef = useRef<HTMLDivElement | null>(null);

    const getMainImage = (images: ModelImageInterface[]) => {
        return images.find(image => image.type === MAIN_IMAGE_TYPE)
    }

    const itemChangeQuantity = (item: BasketItemInterface, type: 'increase' | 'descrease') => {
        const basket: BasketItemInterface[] = state.basket
        let currentItem = basket.find(basketItem => basketItem.modelColorItem.model_id === item.modelColorItem.model_id && basketItem.color === item.color && basketItem.size === item.size)

        if (type === 'descrease' && currentItem?.quantity && currentItem?.quantity > 1) {
            currentItem.quantity--
        }
        if (type === 'increase' && currentItem?.quantity) {
            currentItem.quantity++
        }

        if (currentItem) {
            currentItem.createdAt = new Date()
        }

        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'basket', payload: [...basket]})
    }

    const total = (): MoneyInterface => {
        let total = 0;
        if (!state.basket.length) {
            return {
                amount: 0,
                currency: {
                    name: 'RUB'
                }
            } as MoneyInterface
        }
        const basket: BasketItemInterface[] = state.basket
        basket.map(basketItem => total += basketItem.modelColorItem.min_price.amount * basketItem.quantity)

        let totalMoney = {...basket[0].modelColorItem.min_price}
        totalMoney.amount = total;

        return totalMoney
    }

    const totalCount = () => {
        let total = 0;
        const basket: BasketItemInterface[] = state.basket
        basket.map(basketItem => total += basketItem.quantity)
        return total
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                dispatch({type: ReducerActionType.CLOSE_ALL_POPUP});
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch]);

    // useEffect(() => {
    //     $('.scrollbar-inner').scrollbar({"scrollx": "advanced", "scrolly": "advanced"});
    // }, []);

    useEffect(() => {
        let scrollY = 0;

        const handleScrollLock = () => {
            if (window.innerWidth <= 991) {
                if (state.showBasketPopup) {
                    scrollY = window.scrollY;
                    document.body.style.overflow = 'hidden';
                    document.body.style.position = 'fixed';
                    document.body.style.width = '100%';
                    document.body.style.height = '100%';
                    document.body.style.top = `-${scrollY}px`;
                } else {
                    const scrollY = document.body.style.top;
                    document.body.style.overflow = '';
                    document.body.style.position = '';
                    document.body.style.width = '';
                    document.body.style.height = '';
                    document.body.style.top = '';
                    window.scrollTo(0, parseInt(scrollY || '0') * -1);
                }
            }
        };

        handleScrollLock();
        window.addEventListener('resize', handleScrollLock);

        if (state.showBasketPopup && window.innerWidth <= 991) {
            $('.scrollbar-inner').scrollbar({"scrollx": "advanced", "scrolly": "advanced"});
        }

        return () => {
            window.removeEventListener('resize', handleScrollLock);
            if (window.innerWidth <= 991) {
                const scrollY = document.body.style.top;
                document.body.style.overflow = '';
                document.body.style.position = '';
                document.body.style.width = '';
                document.body.style.height = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            }
        };
    }, [state.showBasketPopup]);

    return (<div className={"modal fade modal-main modal-basket-wrapper " + (state.showBasketPopup && 'show')}>
        <div className="modal-dialog" ref={modalRef}>
            <div className="modal-content">
                <button type="button" className="close" onClick={() => {
                    dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBasketPopup', payload: false})
                }}></button>
                {basket.length === 0 &&
                    <div className="modal-body text-center mt-5"><h4>В вашей корзине нет товаров</h4></div>}

                {basket.length > 0 && <div className="modal-body">
                    <h5 className="modal__title f-exp">Корзина ({totalCount()})</h5>
                    <div className="modal-basket">
                        <div className="modal-basket__body">

                            {/* <div className="scrollbar-inner"> */}
                            {basket.map((basketItem, index) => <div key={index}
                                                                    className={"modal-basket__product " + (!basketItem.quantity && " bg-danger-lighter")}>
                                <div className="modal-basket__product-img">
                                    <img
                                        src={getBaseImagePath() + getMainImage(basketItem.modelColorItem.images)?.url}
                                        alt=""/>
                                </div>
                                <div className="modal-basket__product-info">
                                    <div className="wrap">
                                        <div className="left">
                                            <h6><Link
                                                to={`/catalog/${basketItem.modelColorItem.model_id}/${basketItem.modelColorItem.special_transliterated_name.toLowerCase()}/${basketItem.modelColorItem.color_special.toLowerCase()}/`}>{basketItem.modelColorItem.name}</Link>
                                            </h6>
                                            <ul>
                                                <li>Размер: {basketItem.size}</li>
                                                <li>Цвет: {basketItem.modelColorItem.color}</li>
                                                <li>Артикул: {basketItem.modelColorItem.articul}</li>
                                            </ul>
                                        </div>
                                        <div className="right">
                                            <div className="price f-exp">
                                                <h6 className={basketItem.modelColorItem.discount_value.amount > 0 ? "discount" : ""}>
                                                    <Money value={basketItem.modelColorItem.min_price}/></h6>
                                                {basketItem.modelColorItem.discount_value.amount > 0 && <>
                                                    <h6 className="old"><Money
                                                        value={basketItem.modelColorItem.max_price}/></h6>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-numbers">
                                        <i className="option opt--value opt--menus" onClick={() => {
                                            itemChangeQuantity(basketItem, 'descrease')
                                        }}></i>
                                        <input className="option opt--number" type="text"
                                               value={basketItem.quantity} onChange={() => {
                                        }} min="1" max="99" readOnly={true} maxLength={2}/>
                                        <i className="option opt--value opt--plus" onClick={() => {
                                            itemChangeQuantity(basketItem, 'increase')
                                        }}></i>
                                    </div>
                                    <a className="modal-basket__product-trash ico ico-trash" href="/"
                                       onClick={(event) => {
                                           event.preventDefault()
                                           dispatch({
                                               type: ReducerActionType.REMOVE_FROM_BASKET,
                                               payload: basketItem
                                           })
                                           dataLayerManagerAction(EcommerceAction.REMOVE_FROM_CART, [basketItem.modelColorItem])
                                       }}></a>
                                </div>
                            </div>)}
                            {/* </div> */}

                            <div className="modal-basket__foot">
                                <h5 className="f-exp"><span>Итого:</span><span><Money value={total()}/></span></h5>
                                <div className="btns">
                                    <Link to={'/checkout'} className="btn btn-secondary">Оформить заказ</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    </div>)
}