import { useEffect, useRef } from "react";
import { podeliLink, getCreditCardPaymentLink, getSbpPaymentLink } from "../../api/checkout";
import { ReducerActionType } from "../../GlobalReducer";
import { CREDIT_URL, PAYMENT_TYPE_PODELI_ID, PAYMENT_TYPE_CREDIT_CARD_ID, PAYMENT_TYPE_SBP_ID, PAYMENT_TYPE_CREDIT_ID } from "../../Config";
import { OrderInterface } from "../../interfaces/customer/OrderInterface";

const usePaymentRedirect = (order: OrderInterface|undefined, dispatch: Function, timeout: number = 5) => {
    const timeoutId = useRef<number | null>(null);

    const creditLink = (order: OrderInterface) => {
        return order.credit_application_id ? CREDIT_URL + order.credit_application_id : null;
    };

    useEffect(() => {
        if (!order) return;

        switch (order.payment.id) {
            case PAYMENT_TYPE_PODELI_ID:
                podeliLink(order).then(response => {
                    if (response.status === 'ok') {
                        timeoutId.current = window.setTimeout(() => {
                            window.location.href = response.redirect;
                        }, timeout * 1000);
                        console.log('Редирект на ' + response.redirect + ' через 5 секунд');
                    } else {
                        dispatch({ type: ReducerActionType.ERROR, payload: 'При запросе ссылки на Podeli произошла ошибка : ' + response.detail });
                    }
                }).catch(error => {
                    console.error(error);
                    dispatch({ type: ReducerActionType.ERROR, payload: 'При запросе ссылки на Podeli произошла ошибка : ' + (error.response?.data?.message || error.response?.data?.detail) });
                });
                break;
            case PAYMENT_TYPE_CREDIT_CARD_ID:
                getCreditCardPaymentLink(order.id.toString(), order.sign).then(result => {
                    if (result.url) {
                        window.location.href = result.url;
                        return;
                    }
                    if(result.is_error){
                        dispatch({ type: ReducerActionType.ERROR, payload: 'При запросе ссылки на Podeli произошла ошибка : ' + (result?.message) });
                    }
                    console.log('Редирект payment link ', result);
                });
                break;
            case PAYMENT_TYPE_SBP_ID:
                console.log('Detect SBP');
                getSbpPaymentLink(order.id.toString(), order.sign).then(result => {
                    if (result.url) {
                        window.location.href = result.url;
                        return;
                    }
                    if(result.is_error){
                        dispatch({ type: ReducerActionType.ERROR, payload: 'При запросе ссылки на SBP произошла ошибка : ' + (result?.message) });
                    }
                    console.log('Редирект payment link ', result);
                });
                break;
            case PAYMENT_TYPE_CREDIT_ID:
                if (!creditLink(order)) {
                    return;
                }
                timeoutId.current = window.setTimeout(() => {
                    //@ts-ignore
                    window.location.href = creditLink(order);
                }, timeout * 1000);
                console.log('Редирект на ' + creditLink(order) + ' через 5 секунд');
                break;
            default:
                break;
        }

        return () => {
            if (timeoutId.current) {
                window.clearTimeout(timeoutId.current);
            }
        };
    }, [order, dispatch]);
};

export default usePaymentRedirect;