import {Link, Outlet} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {DealerInterface} from "../../interfaces/dealer/DealerInterface";
import AuthForm from "./AuthForm";
import {useQuery} from "@tanstack/react-query";
import {getModels, getOrders} from "../../api/dealer";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {OrderInterface as OrderForCreateInterface} from "../../interfaces/dealer/create/OrderInterface";
import {OutletInterface} from "../../interfaces/dealer/OutletInterface";
import {FiltersInterface} from "../../interfaces/dealer/FiltersInterface";
import {OrderPositionInterface} from "../../interfaces/dealer/create/OrderPositionInterface";

const KEY = '__currentDealerOrder__'

export default function Layout() {
    const [status, setStatus] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [dealer, setDealer] = useState<DealerInterface|undefined>(undefined)
    const [updateOrderCounter, setUpdateOrderCounter] = useState<number>(0)

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    const {data, isLoading} = useQuery({
        queryKey: ['dealer', 'order-list', updateOrderCounter],
        queryFn: () => getOrders(),
        retry: false,
    })

    const [currentOrder, setCurrentOrder] = useState<OrderForCreateInterface>({
        comment: '',
        positions: [],
        manager: undefined
    } as OrderForCreateInterface)

    useEffect(() => {
        let order = JSON.parse(localStorage.getItem(KEY) || '{  "comment": "", "positions": []}')
        const modelId = order.positions.map((position: OrderPositionInterface) => position.model.id);
        if(!modelId.length) {
            setIsInitialized(true)
            return
        }
        getModels({id: order.positions.map((position: OrderPositionInterface) => position.model.id)} as FiltersInterface).then((response) => {
            order.positions.map((position: OrderPositionInterface) => {
                position.model = response.models.find((model) => model.id === position.model.id) || position.model
                return position
            })
            setCurrentOrder(order)
            setIsInitialized(true)
        }).catch(error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'При загрузке моделей возникла ошибка: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }, [])

    useEffect(() => {
        if(!isInitialized) return
        const orderWithoutModels = {...currentOrder, ...{positions: currentOrder.positions.map(position => {
            let localPositionWithoutModel:any = {...position}
             localPositionWithoutModel.model = {id: position.model.id}
            return localPositionWithoutModel
        })}}

        localStorage.setItem(KEY, JSON.stringify(orderWithoutModels))
    }, [currentOrder])



    useEffect(() => {
        if (isLoading) {
            dispatch({type: ReducerActionType.LOAD})
        } else {
            dispatch({type: ReducerActionType.SUCCESS})
        }
    }, [isLoading]);

    const updateOrderList = () => {
        setUpdateOrderCounter(updateOrderCounter + 1)
    }

    return (<main className="main-lk">
        <section className="lk">
            <div className="container-fluid">
                {dealer && <div className={'text-end my-5'}><h3>{dealer?.fio} [ID: {dealer?.id}]</h3></div>}
                {status === 'error' && <div className="row lk__wrap">
                    <h3 className={'text-center text-danger'}>{error}</h3>
                </div>}
                <div className="row lk__wrap">
                    {/*<div className="col-lg-2 lk__menu">*/}


                    {/*</div>*/}

                    {!dealer && <AuthForm setDealer={setDealer}/>}

                    {dealer && <div className="col-lg-12 lk__container">
                        <div className="tab-content">
                            <Outlet context={{dealer: dealer as DealerInterface, ordersData: data, currentOrder: currentOrder, setCurrentOrder: setCurrentOrder, updateOrderList: updateOrderList} as OutletInterface}/>
                        </div>
                    </div>}
                </div>
            </div>
        </section>
    </main>)
}