import {Col, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useQuery} from "@tanstack/react-query";
import {getFilters} from "../../api/dealer";
import {FiltersInterface} from "../../interfaces/dealer/FiltersInterface";
import {useContext, useEffect, useState} from "react";
import {FilterRelationInterface} from "../../interfaces/dealer/FilterRelationInterface";
import {ModelGenderType} from "../../interfaces/model/ModelGenderInterface";
import ModelCategoryInterface from "../../interfaces/model/ModelCategoryInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

interface SelectedFiltersInterface {
    collection_id: string[]
    category_id: any[]
    capsule_id: any[]
    gender_id: string
    direction_id: string
    season_id: string
    only_with_stock: boolean
}

export default function CatalogFilters({setFilters}: {setFilters: (filters: FiltersInterface) => void}) {
    const {register, getValues, handleSubmit, watch, formState: {errors, dirtyFields}} = useForm();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    // const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersInterface | undefined>(undefined)
    //
    // const [categories, setCategories] = useState<ModelCategoryInterface[]>([])

    const {data: filters, isLoading} = useQuery({
        queryKey: ['dealer', 'catalog', 'filters'],
        queryFn: () => getFilters(),
        retry: false
    })

    useEffect(() => {
        if (isLoading) {
            dispatch({type: ReducerActionType.LOAD})
        } else {
            dispatch({type: ReducerActionType.SUCCESS})
        }
    }, [isLoading]);

    const onSubmit = (data: any) => {
        const filterData = JSON.parse(JSON.stringify(data))
        setFilters(filterData)
    }

    // const getFilteredRelations = () => {
    //     let relations: FilterRelationInterface[] = filters?.relations || [];
    //
    //     if(selectedFilters?.category_id?.length) {
    //         relations = relations.filter(relation => selectedFilters?.category_id?.includes(relation.categoryId))
    //     }
    //
    //     if(selectedFilters?.collection_id?.length) {
    //         relations = relations.filter(relation => selectedFilters?.collection_id?.includes(relation.collectionId))
    //     }
    //
    //     if(selectedFilters?.direction_id?.length) {
    //         relations = relations.filter(relation => selectedFilters?.direction_id?.includes(relation.directionId))
    //     }
    //
    //     if(selectedFilters?.gender_id?.length) {
    //         relations = relations.filter(relation => selectedFilters?.gender_id?.includes(relation.genderId as ModelGenderType))
    //     }
    //
    //     return relations
    // }
    //
    // const getFilteredCollections = () => {
    //     return filters?.collections?.filter(collection => getFilteredRelations().map(relation => relation.collectionId).includes(collection.id))
    // }
    //
    // const getFilteredCategories = () => {
    //     return filters?.categories?.filter(category => getFilteredRelations().map(relation => relation.categoryId).includes(category.id))
    // }
    //
    // const getFilteredDirections = () => {
    //     return filters?.directions?.filter(direction => getFilteredRelations().map(relation => relation.directionId).includes(direction.id))
    // }
    //
    // const getFilteredCapsules = () => {
    //     return filters?.capsules?.filter(capsule => getFilteredRelations().map(relation => relation.capsuleId).includes(capsule.id))
    // }
    //
    // const getFilteredGenders = () => {
    //     return filters?.genders?.filter(gender => getFilteredRelations().map(relation => relation.genderId).includes(gender.id))
    // }

    return (
        <div>
            <form className="form--big" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12}>
                            <b>Поиск по артикулу (можно ввести через запятую):</b>
                            <input type="text" className={'form-control'} {...register("articules")}/>
                    </Col>
                </Row>
                <Row className={'mt-md-4'}>
                    <Col md={4}>
                        <b>Коллекции</b>
                        <select className={'form-control'} multiple={true}
                                style={{height: '120px'}} {...register("collection_id")}>
                            {filters?.collections?.map(collection => <option key={collection.id}
                                                                             // className={getFilteredCollections()?.map(scollection => scollection.id).includes(collection.id) ? 'bg-success-lighter' : ''}
                                                                             //
                                                                             value={collection.id}>{collection.name}</option>)}
                        </select>
                    </Col>
                    <Col md={4}>
                        <b>Категории</b>
                        <select className={'form-control'} multiple={true}
                                style={{height: '120px'}} {...register("category_id")}>
                            {filters?.categories?.map(category => <option key={category.id}
                                                                          // className={getFilteredCategories()?.map(scategory => scategory.id).includes(category.id) ? 'bg-success-lighter' : ''}
                                                                          value={category.id}>{category.name}</option>)}
                        </select>
                    </Col>
                    <Col md={4}>
                        <b>Капсулы</b>
                        <select className={'form-control'} multiple={true}
                                style={{height: '120px'}} {...register("capsule_id")}>
                            {filters?.capsules?.map(capsule => <option  key={capsule.id}
                                                                     value={capsule.id}>{capsule.name}</option>)}
                        </select>
                    </Col>
                </Row>
                <Row className={'mt-md-4'}>
                    <Col md={4}>
                        <b>Пол</b>
                        <select className={'form-control'} {...register("gender_id")}>
                            <option value="">-- любой пол --</option>
                            {filters?.genders?.map(gender => <option key={gender.id}
                                                                     value={gender.id}>{gender.name}</option>)}
                        </select>
                    </Col>
                    <Col md={4}>
                        <b>Направление</b>
                        <select className={'form-control'} {...register("direction_id")}>
                            <option value="">-- любое направление --</option>
                            {filters?.directions?.map(direction => <option key={direction.id}
                                                                           value={direction.id}>{direction.name}</option>)}
                        </select>
                    </Col>

                    <Col md={4}>
                        <b>Сезон</b>
                        <select className={'form-control'} {...register("season_id")}>
                            <option value="">-- любой сезон --</option>
                            {filters?.seasons?.map(season => <option key={season.id}
                                                                     value={season.id}>{season.name}</option>)}
                        </select>
                    </Col>


                </Row>
                <Row className={'mt-md-4'}>
                    <Col md={12}>
                        <b>&nbsp;</b>
                        <input type="checkbox" {...register("only_with_stock")}/> показать только модели, имеющиеся в наличии<br/><br/>
                        <button className={'btn btn-secondary '} type={'submit'}>Найти модели</button>
                    </Col>
                </Row>
            </form>
        </div>
    )
}