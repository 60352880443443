import SlideBanners from "./SlideBanners";
import WMBanners from "./WMBanners";
import WMSwitcher from "./WMSwitcher";
import AdvantagesList from "./AdvantagesList";
import Shops from "./Shops";
import About from "../core/About";
import Subscribe from "../core/Subscribe";
import TileBanner from "./TileBanner";
import CollectionBanner from "./CollectionBanner";
import Advantages from "./Advantages";
import {useQuery} from "@tanstack/react-query";
import {chooseYourLook, mainPageData} from "../../api/page";
import {useContext, useEffect, useState} from "react";
import {getModelColorItems} from "../../api/catalog";
import {ModelGenderType} from "../../interfaces/model/ModelGenderInterface";
import HorizontalModelSlider from "./NowBuy";
import LinkExtendedInterface from "../../interfaces/core/LinkExtendedInterface";
import ChooseYourLook from "./ChooseYourLook";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {useLocation} from "react-router-dom";

export enum GenderType {
    MEN = "men",
    WOMEN = "women",
    ALL = "all",
    UNISEX = "unisex"
}


export default function Main() {
    const [currentGender, setCurrentGender] = useState<GenderType>(GenderType.ALL);
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const location = useLocation();
    const [disableVideo, setDisableVideo] = useState<boolean>(false);

    const {data: initData} = useQuery({
        queryKey: ['page', 'main'],
        queryFn: () => mainPageData()
    })

    const {data: chooseYourLookItems} = useQuery({
        queryKey: ['page', 'choose-your-look', !!state.customer?.id],
        queryFn: () => chooseYourLook(!!state.customer?.id)
    })

    const {data: nowBuyModelColorItemsData} = useQuery({
        queryKey: ['models-on-main', initData?.showOnMainRepositoryId],
        queryFn: () => getModelColorItems({
            repository_id: [initData?.showOnMainRepositoryId || ''],
            limit: 60,
            randomize: true
        }),
        enabled: !!initData?.showOnMainRepositoryId,
        retry: false
    })

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: {
                html_header: "Интернет-магазин одежды с доставкой по Москве и России - Baon.ru",
                title: "Интернет-магазин одежды с доставкой по Москве и России - Baon.ru",
                description: "Интернет-магазин модной одежды BAON. Стиль, комфорт и натуральные материалы. Более 4000 моделей женской, мужской и детской одежды в каталоге на официальном сайте бренда. Бесплатная доставка по всей России при заказе от 3000 ₽"
            }})
        setDisableVideo( !!(new URLSearchParams(location.search)).get('disable_video') )
    }, []);

    const getFilteredAndRandomizeModels = () => {
        let colorItems = (nowBuyModelColorItemsData?.model_color_items || []).sort(() => Math.random() - 0.5)

        return colorItems.sort(() => Math.random() - 0.5).filter(modelColorItem => {
            if(currentGender === GenderType.MEN){
                return modelColorItem.gender_id === ModelGenderType.MEN ||modelColorItem.gender_id === ModelGenderType.UNISEX
            }
            if(currentGender === GenderType.WOMEN){
                return modelColorItem.gender_id === ModelGenderType.WOMEN ||modelColorItem.gender_id === ModelGenderType.UNISEX
            }
            return true
        }).slice(0, 100)
    }

    const {model_color_items: nowBuyModelItems, meta: _} = nowBuyModelColorItemsData || {model_color_items: [], meta: undefined}

    return (
        <main className="main-index">
            {initData && <SlideBanners banners={initData.topBanners}/>}
            {initData && <WMBanners banners={initData.switchBanners}/>}
            <WMSwitcher currentGender={currentGender} setCurrentGender={setCurrentGender}/>

            {initData && Array.isArray(initData.collectionBanners) && (<CollectionBanner banners={initData.collectionBanners} currentGender={currentGender} disableVideo={disableVideo} /> )}

            {initData && initData.tileBanners.filter(banner => currentGender === GenderType.ALL || banner.gender === currentGender || banner.gender == GenderType.UNISEX).map((banner, index) =>
                <noindex><section key={index} className={"collections " + (index > 0 ? 'collections--pb' : '')}><TileBanner
                    tileBanner={banner}/></section></noindex>)}
            {chooseYourLookItems?.length && initData && <ChooseYourLook items={chooseYourLookItems}/>}
            {initData && <noindex><Advantages bottomBanners={initData.bottomBanners}/></noindex>}
            {!!nowBuyModelColorItemsData?.model_color_items?.length && <noindex><HorizontalModelSlider modelColorItems={getFilteredAndRandomizeModels()} header={'Сейчас купили'}/></noindex>}
            
            {initData && <noindex><AdvantagesList advantagesBanners={initData.advantagesBanners}/></noindex>}
            <Shops shop={initData?.shopBanner as LinkExtendedInterface}/>
            <About/>
            <Subscribe/>
        </main>
    );
}