import fetchData from "../utils/HttpClient";
import TileBannerInterface from "../interfaces/main/TileBannerInterface";
import LinkExtendedInterface from "../interfaces/core/LinkExtendedInterface";
import {BannerInterface} from "../interfaces/core/BannerInterface";
import ChooseYourLookItemInterface from "../interfaces/main/ChooseYourLookItemInterface";
import CollectionBannerInterface from "../interfaces/main/CollectionBannerInterface";
import AdvantagesBanners from "../interfaces/main/AdvantagesBannerInterface";

const basePathPage = '/api/page'

export function mainPageData(): Promise<{
    tileBanners: TileBannerInterface[],
    switchBanners: BannerInterface[]
    topBanners: BannerInterface[]
    bottomBanners: LinkExtendedInterface[],
    shopBanner: LinkExtendedInterface,
    showOnMainRepositoryId: string
    chooseYourLookItems: ChooseYourLookItemInterface[],
    advantagesBanners: AdvantagesBanners[],
    collectionBanners: CollectionBannerInterface[]
}> {
    return fetchData(basePathPage + '/main')
}

export function chooseYourLook(isAuth: boolean): Promise<ChooseYourLookItemInterface[]> {
    return fetchData(basePathPage + '/choose-your-look', {is_auth: isAuth}, 'POST')
}