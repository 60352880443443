import {Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {DEALER_BORDER_QUANTITY} from "../../Config";
import ModelInterface from "../../interfaces/model/ModelInterface";
import Money from "../core/Money";
import {ColorInterface} from "../../interfaces/dealer/ColorInterface";
import {OrderInterface as OrderForCreateInterface} from "../../interfaces/dealer/create/OrderInterface";
import useModel from "../catalog/useModel";
import {getBaseImagePath} from "../catalog/Utils";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {convertImageToSize, ImageSize} from "../../utils/Image";
import ModelImageInterface from "../../interfaces/model/ModelImageInterface";

export default function ModelList({model, order, setOrder, showInfo}: {
    model: ModelInterface,
    order: OrderForCreateInterface,
    setOrder: (order: OrderForCreateInterface) => void,
    showInfo: (model: ModelInterface) => void
}) {


    const [currentColor, setCurrentColor] = useState<string | undefined>(undefined)

    const {getUniqueSizes, getPrintByColor, getUniqueColors} = useModel(model)

    useEffect(() => {
        setCurrentColor(model.extra_param.main_color_name || model.images.find(image => image.type === 'front')?.special_color_name)
    }, [model])

    const getImage = (): ModelImageInterface | undefined => {
        let image = model.images.find(image => (!currentColor || image.special_color_name === currentColor) && image.type === 'front');
        if(!image){
            image = model.images.find(image => image.type === 'tech1');
        }
        if(!image){
            image = model.images.find(image => image.type !== 'print');
        }
        return image;
    }

    const getStockLabel = (color: string, size: string): string => {
        const stock = model.stocks[color] && model.stocks[color][size];

        return stock && stock[1] && stock[1] > DEALER_BORDER_QUANTITY ? '> ' + DEALER_BORDER_QUANTITY : (stock ? stock[1] : '0')
    }

    const getStock = (color: string, size: string): string => {
        const stock = model.stocks[color] && model.stocks[color][size];

        return stock && stock[1] && stock[1] && 0;
    }

    const hasStock = (color: string, size: string): boolean => {
        return model.stocks[color] && model.stocks[color][size] && model.stocks[color][size][1] && model.stocks[color][size][1] > 0;
    }

    const setOrderPosition = (color: ColorInterface, size: string, quantity: number) => {
        const item = model.items.find(item => item.color === color.color && item.size === size)
        const stock = model.stocks[color.color] && model.stocks[color.color][size] && model.stocks[color.color][size][1]
        const modelItemId = item?.id
        order.positions.find(position => position.modelItemId === modelItemId)
        const positions = order.positions.filter(position => position.modelItemId !== modelItemId).filter(position => position.quantity > 0)
        if (quantity) {
            positions.push({
                model: model,
                color: color,
                size: size,
                modelItemId: modelItemId || '',
                quantity: Math.min(stock, quantity),
                quantityOriginal: quantity
            })
        }
        setOrder({
            ...order,
            positions
        })
    }

    const getCurrentValue = (color: ColorInterface, size: string) => {
        const item = model.items.find(item => item.color === color.color && item.size === size)
        const modelItemId = item?.id
        const position = order.positions.find(position => position.modelItemId === modelItemId)
        return position?.quantity || 0
    }

    const isVisible = (color: ColorInterface, size: string) => {
        return model.items.find(item => item.color === color.color && item.size === size)?.is_showable
    }

    const ifCurrentValueOverflow = (color: ColorInterface, size: string) => {
        const item = model.items.find(item => item.color === color.color && item.size === size)
        const modelItemId = item?.id
        const position = order.positions.find(position => position.modelItemId === modelItemId)
        return position && position?.quantity > 0 && position?.quantityOriginal > 0 && position?.quantity < position?.quantityOriginal
    }

    const goToNext = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // @ts-ignore
            const elem = $(event.target)
            const tb = elem.closest('.model-item');
            const tr = elem.closest('tr');
            const td =  elem.closest('td');
            let ex = false;

            td.nextAll().each(function(x){
                var input = $('input:not(:disabled):visible', $(this));

                if(input.length) {
                    input.focus().select();
                    ex = true;
                    return false;
                }
            });

            if(ex) return;

            tr.nextAll().each(function(x){
                if( $('td input:not(:disabled):visible', $(this)).length ){
                    $('td input:not(:disabled):visible', $(this)).first().focus().select();
                    ex = true;
                    return false;
                }
            });

            if(ex) return;

            tb.nextAll().each(function(x){
                if( $('td input:not(:disabled):visible', $(this)).length ){
                    $('td input:not(:disabled):visible', $(this)).first().focus().select();
                    ex = true;
                    return false;
                }
            });

            if(ex) return;
        }
    }

    return (<>
        <Row>
            <Col md={3}>
                {getImage() &&  <Zoom><img src={getBaseImagePath() +convertImageToSize(getImage() as ModelImageInterface, ImageSize.BIG).url} alt={model.name} className={'img-fluid'}/></Zoom>}
            </Col>
            <Col md={9}>
                {model?.dealer_price && <div className="float-end">
                    <h2><Money value={model?.dealer_price}/></h2>
                </div>}
                <h2>{model.name}</h2>
                <div><b>Артикул: </b>{model.articul}</div>
                <div className={'dashed pointer'} onClick={() => { showInfo(model) }}>подробная информация</div>
                <div className={'table-responsive-md size-edit-table '}>
                    <table className={'table'}>
                        <thead>
                        <tr>
                            <td>&nbsp;</td>
                            {getUniqueSizes(true).map(size => <th key={size}>{size}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {getUniqueColors(true).map(color => <tr key={color.color}>
                            <td className={'text-center'}>
                            <span className={'pointer'} onClick={() => {
                                setCurrentColor(color.special)
                            }}>{color.color}</span>
                                <div className={'dealer-color pointer'} onClick={() => {
                                    setCurrentColor(color.special)
                                }}
                                     style={{background: getPrintByColor(color.special) ? 'url(' + getPrintByColor(color.special) + ') no-repeat center center ' + color.hex : color.hex}}></div>
                            </td>
                            {getUniqueSizes(true).map(size => <td key={size} className={'text-center'}>
                                <div
                                    className={'' + (ifCurrentValueOverflow(color, size) && 'text-danger')}>{getStockLabel(color.color, size)}</div>
                                <input type="text"
                                       className={'form-control dealer-size-control  text-center ' + (getCurrentValue(color, size) > 0 && ' bg-warning-lighter ') + (ifCurrentValueOverflow(color, size) && ' bg-danger-lighter ')}
                                       value={getCurrentValue(color, size)}
                                       onKeyUp={goToNext}
                                       onChange={(event) => {
                                           setOrderPosition(color, size, parseInt((event.target as HTMLInputElement).value))
                                       }} onClick={(event) => {
                                    event.currentTarget.select()
                                }} disabled={!hasStock(color.color, size) || !isVisible(color, size)}/>
                            </td>)}
                        </tr>)}
                        </tbody>
                    </table>
                    {model.is_preorder && <p className={'text-end text-danger'}>Данная модель доступна ТОЛЬКО для предзаказа. <br/>Пожалуйста используйте отдельный заказ для оформления.</p>}
                    {model.extra_param?.tech_comment && <p className={'text-end'}>{model.extra_param?.tech_comment}</p>}
                </div>
            </Col>
        </Row>
    </>)
}