import TableSizes from "../../catalog/table-size/TableSizes";
import RightModalFull from "./RightModalFull";

export default function TableOfSizes() {
    return (
        <RightModalFull>
         <TableSizes />
        </RightModalFull>
    );
}
