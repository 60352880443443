import Sort, {SortType} from "./catalog-list/Sort";
import FilterTags from "./catalog-list/FilterTags";
import Subscribe from "../core/Subscribe";
import ModelListCard from "./ModelListCard";
import Breadcrumbs from "../core/Breadcrumbs";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import ModelColorItemInterface from "../../interfaces/model/ModelColorItemInterface";
import {objclone} from "../../utils/Utils";
import ModelSuperCategoryInterface from "../../interfaces/model/ModelSuperCategoryInterface";
import BreadcrumbInterface from "../../interfaces/core/BreadcrumbInterface";
import BannerInterface from "../../interfaces/catalog/BannerInterface";
import {FilterSuperCategoryInterface} from "../../interfaces/catalog/FilterSuperCategoryInterface";
import Filters from "./catalog-list/Filters";
import {SelectedFiltersInterface} from "../../interfaces/catalog/SelectedFiltersInterface";
import Pagination from "../core/Pagination";
import BottomTags from "../core/BottomTags";
import TagInterface from "../../interfaces/core/TagInterface";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import useDatalayer, {EcommerceAction} from "../../utils/useDataLayer";
import Header from './catalog-list/Header'
import ModelPropertyInterface from "../../interfaces/model/ModelPropertyInterface";
import ModelPropertyFilterInterface from "../../interfaces/model/ModelPropertyFilterInterface";
import useUrlFilterConvertor from "./catalog-list/useUrlFilterConvertor";
import FilterByPropertyTag from "../minda/catalog/FilterByPropertyTag";
import {ModelFilterTagInterface} from "../../interfaces/catalog/ModelFilterTagInterface";
import debounce from 'lodash/debounce';
import ModelFilterTags from "./catalog-list/ModelFilterTags";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import LandingInterface from "../../interfaces/landing/LandingInterface";
import LandingContent from "./model-card/LandingContent";
import {isArray} from "lodash";
import ModelCategoryInterface from "../../interfaces/model/ModelCategoryInterface";
import ModelListCardObserver from "./ModelListCardObserver";

const INIT_DELAY_URL_PARAMS = 3;

const NEVER_NO_DATA_INDEX = '__never-no-data-index__'

export default function CatalogList({
                                        modelColorItems,
                                        category,
                                        breadcrumbs,
                                        banners,
                                        tags,
                                        superCategories,
                                        categories,
                                        properties,
                                        filterTags,
                                        landing = undefined
                                    }: {
    modelColorItems: ModelColorItemInterface[],
    category: ModelSuperCategoryInterface | undefined,
    breadcrumbs: BreadcrumbInterface[] | undefined,
    banners: BannerInterface[] | undefined,
    tags: TagInterface[] | undefined,
    superCategories: FilterSuperCategoryInterface[],
    categories: ModelCategoryInterface[],
    properties: ModelPropertyInterface[],
    filterTags: ModelFilterTagInterface[],
    landing?: LandingInterface | undefined
}) {

    const defaultFilterState: SelectedFiltersInterface = {
        colors: [],
        sizes: [],
        superCategories: [],
        categories: [],
        priceRange: null,
        properties: new Map()
    };

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isFiltersOpen, setIsFilterOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [additionalPages, setAdditionalPages] = useState(0);
    const [modelsPerPage, setModelsPerPage] = useState(36);
    const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersInterface>(defaultFilterState);
    const [sortBy, setSortBy] = useState<SortType | null>(null);
    const [selectedTags, setSelectedTags] = useState<TagInterface[]>([])
    const [initTime, setInitTime] = useState<number>(Date.now())
    const [filterTag, setFilterTag] = useState<ModelFilterTagInterface|undefined>(undefined)

    const [isFiltersMindaOpen, setIsFiltersMindaOpen] = useState(false);

    const {convertToArray, convertToUrl, convertFromUrl} = useUrlFilterConvertor(selectedFilters, selectedTags, sortBy)

    const {category: dataLayerCategory} = useDatalayer()

    const getBaseUrl = () => {
        return location.pathname
    }

    const getUniqueProperties = () => {
        let uniqProperties = new Map<number, ModelPropertyFilterInterface>();

        if(properties){
            properties.map(property => {
                let values = uniqProperties.get(property.property_id)?.values || [];
                if (!values.find(value => value === property.value)) {
                    values.push(property.value)
                }
                uniqProperties.set(property.property_id, {
                    id: property.property_id,
                    name: property.property_name,
                    values: values
                });
            })
        }

        return Array.from(uniqProperties.values())
    }



    /**
     * Отфильтрованные модели
     */
    const getFilteredAndSortedModelColorItems = useMemo((): ModelColorItemInterface[] => {

        // TODO: Фиксировать количество моделей, чтобы не перезапускать функцию

        let modelColorItemsFilterd: ModelColorItemInterface[] = objclone(modelColorItems)

        // сбрасывать на фильтры при открытии
        const selectedFiltersLocal =  filterTag?.params as SelectedFiltersInterface || selectedFilters

        if(filterTag?.params?.properties?.length){
            const filtertagParamMap = new Map();

            filterTag?.params?.properties?.map((property:any) => {
                filtertagParamMap.set(property[0], property[1])
            })
            selectedFiltersLocal.properties = filtertagParamMap
        }

        // @ts-ignore
        for (const [key, value] of selectedFiltersLocal.properties) {
            if (!value || (isArray(value) && value.length === 0)) {
                selectedFiltersLocal.properties.delete(key);
            }
        }

        if (selectedFiltersLocal.colors?.length) {
            modelColorItemsFilterd = modelColorItemsFilterd.filter(colorItem => selectedFiltersLocal.colors.map(color => color.color).indexOf(colorItem.color_human_name) > -1)
        }
        if (selectedFiltersLocal.sizes?.length) {
            modelColorItemsFilterd = modelColorItemsFilterd.filter(colorItem => {
                let flag = false
                selectedFiltersLocal.sizes.map(size => {
                    if (colorItem.stocks[size] && (colorItem.stocks[size][2] || colorItem.stocks[size][4])) {
                        flag = true
                    }
                })
                return flag
            })
        }

        if (selectedFiltersLocal.superCategories?.length) {
            const selectedSuperCategoryId = selectedFiltersLocal.superCategories.map(superCategory => superCategory.id).flat()
            modelColorItemsFilterd = modelColorItemsFilterd.filter(colorItem => selectedSuperCategoryId.includes(colorItem.super_category_id))
        }

        if (selectedFiltersLocal.categories?.length) {
            const selectedCategoryId = selectedFiltersLocal.categories.map(category => category.id)
            modelColorItemsFilterd = modelColorItemsFilterd.filter(colorItem => selectedCategoryId.includes(colorItem.category_id))
        }

        if (selectedFiltersLocal.priceRange?.to) {
            const priceFrom = selectedFiltersLocal.priceRange.from;
            const priceTo = selectedFiltersLocal.priceRange.to;
            modelColorItemsFilterd = modelColorItemsFilterd.filter(colorItem => {
                return colorItem.min_price.amount / 100 >= priceFrom && colorItem.min_price.amount / 100 <= priceTo
            })
        }

        if (selectedFiltersLocal.properties?.size > 0) {
            let propertiesModelsId: string[] = [];
            selectedFiltersLocal.properties.forEach((values, propertyId) => {
                properties.filter(property => property.property_id === propertyId && values.find(val => val === property.value)).map(property => propertiesModelsId.push(property.model_id))
            })

            if (propertiesModelsId?.length > 0) {
                modelColorItemsFilterd = modelColorItemsFilterd.filter(colorItem => propertiesModelsId.indexOf(colorItem.model_id) > -1 && propertiesModelsId.filter(modelId => modelId === colorItem.model_id).length === selectedFiltersLocal.properties.size)
            }
        }

        if (selectedTags?.length) {
            modelColorItemsFilterd = modelColorItemsFilterd.filter(colorItem => {
                let pass = false
                selectedTags.map(tag => {
                    if (colorItem.tag_relations.indexOf(tag.id) !== -1) {
                        pass = true
                    }
                })
                return pass
            })
        }

        modelColorItemsFilterd.sort((a, b) => {
            switch (sortBy) {
                case SortType.RAITING:
                    if (a.score === b.score) return 0;
                    return a.score < b.score ? 1 : -1;
                case SortType.DISCOUNT:
                    if (a.discount === b.discount) return 0;
                    return a.discount < b.discount ? 1 : -1;
                case SortType.PRICE_ASC:
                    if (a.min_price.amount === b.min_price.amount) return 0;
                    return a.min_price.amount < b.min_price.amount ? 1 : -1;
                case SortType.PRICE_DESC:
                    if (a.min_price.amount === b.min_price.amount) return 0;
                    return a.min_price.amount > b.min_price.amount ? 1 : -1;
                case SortType.NEW:
                    if (a.model_start_date === b.model_start_date) return (a.score < b.score ? 1 : -1);
                    return a.model_start_date < b.model_start_date ? 1 : -1;
                default:
                    if (a.score === b.score) return 0;
                    return a.score < b.score ? 1 : -1;
            }
        });

        return modelColorItemsFilterd
    }, [modelColorItems, sortBy, filterTag, selectedFilters, selectedTags]);

    /**
     * Модели на конкретной странице
     */
    const getModelsOnPage = () => {
        const modelOnPage = getFilteredAndSortedModelColorItems.slice((page - 1 - additionalPages) * modelsPerPage, page * modelsPerPage)

        if(!modelOnPage.length && page > 1){
            searchParams.set('page', '' + 1)
            searchParams.set('adp', '')
            setPage(1)
            setAdditionalPages(0)
        }

        return modelOnPage
    }

    /**
     * Количество выбранных фильтров
     */
    const getFilterCount = (): number => {
        let filterQuantity = (selectedFilters.priceRange?.from || selectedFilters.priceRange?.to) ? 1 : 0
        filterQuantity += selectedFilters.colors.length
        filterQuantity += selectedFilters.sizes.length
        filterQuantity += selectedFilters.superCategories.length
        // TODO: Добавить свойств
        return filterQuantity
    }

    const getFilterTagName = () => {
        const data = /\/tag\/(.+)\//.exec(location.pathname)
        return data?.length ? data[1] : NEVER_NO_DATA_INDEX
    }

    const getFilterTag = () => {
        const filterTagName = getFilterTagName()
        return filterTags?.find(tag => tag.slug === filterTagName)
    }

    const resetFilterTag = () => {
        setFilterTag(undefined)

    }

    useEffect(() => {
        if(getFilterTagName() && getFilterTagName() !== NEVER_NO_DATA_INDEX){
            if(getFilterTag()){
                const filterTag = getFilterTag()
                setFilterTag(filterTag)
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: {
                        html_header: filterTag?.html_header || filterTag?.name,
                        title: filterTag?.title || filterTag?.html_header || filterTag?.name,
                        description: filterTag?.description,
                        seo_text: filterTag?.seo_text
                    }})
            }
        }else{
            setFilterTag(undefined)
        }
    }, [location.pathname, filterTags]);

    const setSearchParamsDebounced = useCallback(debounce(setSearchParams, 500), []);

    /**
     * Меням URL на основе фильтров, тегов и сортрровки
     */
    useEffect(() => {
        if (Date.now() - initTime > INIT_DELAY_URL_PARAMS * 1000 || selectedFilters.colors.length || selectedFilters.sizes.length || selectedFilters.superCategories.length || selectedFilters.categories.length || selectedFilters.priceRange?.from || selectedFilters.priceRange?.to || selectedTags.length || selectedFilters.properties.size || sortBy) {
            let needToCheck: string[] = []

            searchParams.forEach((value, key) => {
                if (['page', 'adp'].indexOf(key) === -1 && key.indexOf('utm_') === -1) {
                    needToCheck.push(key)
                }
            })

            convertToArray().map(item => {
                if (item.values) {
                    searchParams.set(item.name, item.values)
                    needToCheck = needToCheck.filter(nm => nm !== item.name)
                }
            })

            needToCheck.map(name => {
                searchParams.delete(name)
            })

            setSearchParamsDebounced(searchParams)
        }

    }, [selectedFilters, selectedTags, sortBy]);


    /**
     * Проставляем сортировку и теги на основе URL
     */
    useEffect(() => {
        const paramsFromFilters = convertFromUrl(searchParams.toString())

        paramsFromFilters.map(item => {
            if (item.name === 'tags' && tags?.length) {
                const tagsFromParams = tags.filter(tag => item.value.indexOf('' + tag.id) > -1)
                setSelectedTags(tagsFromParams)
            }

            if (item.name === 'sort') {
                setSortBy(item.value[0] as SortType)
            }
        })

    }, [searchParams, tags]);

    /**
     * Отслеживаем номер страницы из URL
     */
    // useEffect(() => {
    //     setPage((Number(searchParams.get('page')) + Number(searchParams.get('adp'))) || 1)
    //     setAdditionalPages(Number(searchParams.get('adp')) || 0)
    // }, [location.pathname, searchParams.get('page'), searchParams.get('adp')]);

    /**
     * DataLayer
     */
    useEffect(() => {
        if (modelColorItems?.length) {
            dataLayerCategory(EcommerceAction.VIEW_CATEGORY, modelColorItems[0].category_uid)
        }
    }, [modelColorItems]);


    const totalPages = Math.ceil(getFilteredAndSortedModelColorItems.length / modelsPerPage)

    return (<>
        {<Filters
            isOpen={isFiltersOpen}
            setIsOpen={setIsFilterOpen}
            modelColorItems={modelColorItems}
            superCategories={superCategories}
            categories={categories}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
            properties={getUniqueProperties()}/>}

        {state.customer?.id && state.customer?.minda && category && <FilterByPropertyTag isOpen={isFiltersMindaOpen} setIsOpen={setIsFiltersMindaOpen} selectedFilters={selectedFilters} properties={getUniqueProperties()} superCategory={category as ModelSuperCategoryInterface} />}

        <main className="main-catalog">
            <section className="catalog">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={breadcrumbs || []}/>
                            {state.meta?.html_header && <Header header={state.meta.html_header} count={getFilteredAndSortedModelColorItems.length}/>}
                            {state.customer?.id && state.customer?.minda && landing && <LandingContent landings={[landing]} />}
                            <div className="filters">
                                <div className="filters__wrap">
                                    <div className="filters__list">
                                        <div className="filters__list-first">
                                            <a className="filters__list-link filters__list-link--all f-exp" href="/"
                                               onClick={(event) => {
                                                   event.preventDefault()
                                                   setIsFilterOpen(!isFiltersOpen)
                                                   if(getFilterTag()) {
                                                       setFilterTag(undefined)
                                                       setSelectedFilters(getFilterTag()?.params)
                                                   }
                                               }}><i
                                                className="ico ico-burger-equalizer ico-left"></i><span
                                                className="desk">Все фильтры</span><sup
                                                className="numbers">{getFilterCount()}</sup></a>
                                            {state.customer?.id && state.customer?.minda && category && <span className={'dashed pointer'} onClick={() => {setIsFiltersMindaOpen(!isFiltersMindaOpen)}}>редактировать теги</span>}

                                        </div>
                                        {/*<FilterTags tags={(tags || []).filter(tag => tag.tag_position === 0)}*/}
                                        {/*            selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>*/}
                                        <ModelFilterTags tags={(filterTags || [])}  resetFilterTag={resetFilterTag}/>
                                        <Sort sortBy={sortBy} setSortBy={setSortBy}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="catalog__list row">
                    {getModelsOnPage().map((modelColorItem, index) =>
                        <div
                            key={index} className={"col-lg-3 " + (index > 3 && index % 3 === 0 ? ` col-12` : " col-6")}>
                                <ModelListCardObserver modelColorItem={modelColorItem} />
                        </div>
                    )}
                        {/*{getModelsOnPage().map((modelColorItem, index) => <div key={index} className={ ( index >= 10 && index % 10 === 0 ? "col-lg-6 " :  "col-lg-3 ") + (index > 3 && (index % 3 === 0) ? ' col-12' : ' col-6')}>*/}
                        {/*    { (index < 10 || index % 10 != 0) && <ModelListCard modelColorItem={modelColorItem}/>}*/}
                        {/*    { (banners && index >= 10 && index % 10 === 0) && <Banner banner={banners[0]}/>}*/}
                        {/*</div>)}*/}
                    </div>
                    {totalPages > 1 && <div className="row">
                        <div className="col">
                            <Pagination page={page} setPage={setPage}
                                        totalPages={totalPages}
                                        additionalPages={additionalPages}
                                        setAdditionalPages={setAdditionalPages}
                                        baseUrl={getBaseUrl()}
                                        queryString={convertToUrl()}
                            />
                        </div>
                    </div>}
                </div>
            </section>
            <section className="information">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="information__collapse">
                                <BottomTags tags={(tags || []).filter(tag => tag.tag_position === 1)}
                                            selectedTags={selectedTags} setSelectedTags={setSelectedTags}
                                            singleMode={false}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe/>
        </main>
    </>)
}