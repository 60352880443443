import {SelectedFiltersInterface} from "../../../interfaces/catalog/SelectedFiltersInterface";
import ModelPropertyFilterInterface from "../../../interfaces/model/ModelPropertyFilterInterface";
import ModelSuperCategoryInterface from "../../../interfaces/model/ModelSuperCategoryInterface";
import FilterByPropertyTagForm from "./FilterByPropertyTagForm";
import {useQuery} from "@tanstack/react-query";
import {deleteModelFilterTag, getModelFilterTags, saveModelFilterTag} from "../../../api/minda";
import {useContext, useEffect, useState} from "react";
import {ModelFilterTagInterface} from "../../../interfaces/catalog/ModelFilterTagInterface";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";


export default function FilterByPropertyTag({
                                                isOpen,
                                                setIsOpen,
                                                selectedFilters,
                                                properties,
                                                superCategory,
                                                // modelPropertyTags
                                            }:
                                                {
                                                    isOpen: boolean,
                                                    setIsOpen: Function,
                                                    selectedFilters: SelectedFiltersInterface,
                                                    properties: ModelPropertyFilterInterface[],
                                                    superCategory: ModelSuperCategoryInterface
                                                    // modelPropertyTags: ModelFilterTagInterface[]
                                                }) {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [modelFilterTagsLocal, setModelFiterTagsLocal] = useState<ModelFilterTagInterface[]>([]);

    const [selectedTag, setSelectedTag] = useState<ModelFilterTagInterface | undefined>(undefined)

    const [deletedTagId, setDeletedTagId] = useState<number[]>([])

    const {data: modelFilterTags, refetch, status} = useQuery({
        queryKey: ['modelTag', 'list', superCategory.id, deletedTagId],
        queryFn: () => getModelFilterTags(superCategory.id),
        // @ts-ignore
        retry: false
    })

    useEffect(() => {
        setModelFiterTagsLocal(modelFilterTags as ModelFilterTagInterface[])
    }, [modelFilterTags]);

    const _addUserCredentials = (tag: ModelFilterTagInterface) => {
        tag.user_id = state.customer?.id;
        tag.user_sign = state.customer?.sign;
        return tag;
    }

    useEffect(() => {
        createNew()
    }, [selectedFilters]);

    const createNew = () => {
        const params: any = JSON.parse(JSON.stringify(selectedFilters))
        params.properties = Array.from(selectedFilters.properties.entries())

        const modelFilterTag: ModelFilterTagInterface = {
            description: '',
            seo_text: '',
            super_category_id: superCategory.id,
            html_header: '',
            is_active: true,
            name: '',
            slug: '',
            sort_id: '',
            title: '',
            params: params
        }

        setSelectedTag(_addUserCredentials(modelFilterTag))
    }

    const save = (tag: ModelFilterTagInterface) => {
        dispatch({
            type: ReducerActionType.LOAD,
        })
        saveModelFilterTag(_addUserCredentials(tag)).then(tag => {
            setSelectedTag(tag)
            setModelFiterTagsLocal([...[tag], ...modelFilterTagsLocal.filter(ltag => ltag.id !== tag.id)])
            dispatch({
                type: ReducerActionType.SUCCESS,
            })

        }, error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'Ошибка на сервере: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }


    return (<div className={"all-filters all-filters-minda 100vh-fix " + (isOpen && 'active')}>
        <div className="head">
            <h5 className="f-exp">Управление тегами</h5>
            <i className="close ico ico-close pointer" onClick={() => {
                setIsOpen(false)
            }}></i>
        </div>
        <div className="body">
            <div className="row">
                <div className="col-8">
                    <h5>Редактирование {selectedTag?.id && <span>#{selectedTag?.id}</span>}</h5>
                    <FilterByPropertyTagForm properties={properties} selectedTag={selectedTag} save={save}/>
                </div>
                <div className="col-4">
                    <h5>Список тегов</h5>
                    <ul className={'list-group list-group-flush'}>
                        {modelFilterTagsLocal?.map(tag => <li key={tag.id} className={' list-group-item'}><span
                            className={'badge text-bg-secondary me-2'}>{tag.id}</span> <span className={'pointer'}
                                                                                             onClick={() => {
                                                                                                 setSelectedTag(tag)
                                                                                             }}>{tag.name}</span> <span
                            className={'text-muted ms-2 me-2'}>/{tag.slug}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span
                            className={'text-danger pointer dashed'} onClick={() => {
                            if (window.confirm('Точно удалить #' + tag.id + '?')) {
                                deleteModelFilterTag(_addUserCredentials(tag))
                                setModelFiterTagsLocal(modelFilterTagsLocal.filter(ltag => ltag.id !== tag.id))
                            }
                        }}>удалить</span></li>)}
                    </ul>
                </div>
            </div>
        </div>
    </div>)
}