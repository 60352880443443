import {GenderType} from "./Main";

interface WMSwitcherProps {
    currentGender: GenderType,
    setCurrentGender: (currentGender: GenderType) => void
}

export default function WMSwitcher(props: WMSwitcherProps)
{
    return (<>
        <section className="section-who">
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="section-who__wrap f-exp">
                        <h4>Для кого вам интересно<br className="mob" /> смотреть товары?</h4>
                        <ul className="nav nav-tabs" >
                            <li className="nav-item" >
                                <button className={"nav-link " + (props.currentGender === GenderType.WOMEN ? "active" : "")} onClick={() => props.setCurrentGender(GenderType.WOMEN)}>Женщины</button>
                            </li>
                            <li className="nav-item" >
                                <button className={"nav-link " + (props.currentGender === GenderType.MEN ? "active" : "")} onClick={() => props.setCurrentGender(GenderType.MEN)} >Мужчины</button>
                            </li>
                            <li className="nav-item" >
                                <button className={"nav-link " + (props.currentGender === GenderType.ALL ? "active" : "")}  onClick={() => props.setCurrentGender(GenderType.ALL)}>Все</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
