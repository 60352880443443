import React, { useEffect, useState } from "react";
import { BannerInterface } from "../../../interfaces/core/BannerInterface";
import CollectionBannerInterface from "../../../interfaces/main/CollectionBannerInterface";

interface BlogAsideBannersProps {
    topBanners?: BannerInterface[];
    collectionBanners?: CollectionBannerInterface[];
}

const BlogAsideBanners: React.FC<BlogAsideBannersProps> = ({ topBanners = [], collectionBanners = [] }) => {
    const allBanners = [...topBanners, ...collectionBanners];
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
   		const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const totalHeight = document.documentElement.scrollHeight - windowHeight;
        const footerHeight = 33 * 16; // высота футера около 33rem, чтобы не было наслоения баннера на футер
        const adjustedHeight = totalHeight - footerHeight; 
        const scrollFraction = scrollPosition / adjustedHeight;
        const newIndex = Math.floor(scrollFraction * allBanners.length);

            setHighlightedIndex(newIndex);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [allBanners.length]);

    if (!allBanners || allBanners.length === 0) {
        return null;
    }

    return (
        <div className="blog__aside-banners desk">
            {allBanners.map((banner, index) => (
                <a
                    key={index}
                    className={`blog__aside-banner ${index === highlightedIndex ? "highlight" : ""}`}
                    href={banner.url}
                >
                    {banner.image && (
                        <>
                            <img src={banner.image} alt={banner.title} />
                            <div className="text">
                                <h3 className="f-exp">{banner.title}</h3>
                                {banner.description && <p>{banner.description}</p>}
                                {banner.buttonText && (
                                    <a className="btn btn-primary" href={banner.url}>
                                        {banner.buttonText}
                                    </a>
                                )}
                            </div>
                        </>
                    )}
                    {banner.video && (
                        <>
                                <video
                                    className="banner__img banner__video-desc"
                                    src={banner.video}
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                ></video>
								<div className="text">
                                <h3 className="f-exp">{banner.title}</h3>
                                {banner.description && <p>{banner.description}</p>}
                                {banner.buttonText && (
                                    <a className="btn btn-primary" href={banner.url}>
                                        {banner.buttonText}
                                    </a>
                                )}
                            </div>
                        </>
                    )}
                </a>
            ))}
        </div>
    );
};

export default BlogAsideBanners;
