import fetchData from "../utils/HttpClient";
import LandingInterface from "../interfaces/landing/LandingInterface";
import {SubscribeInterface} from "../interfaces/landing/SubscribeInterface";
import SubscribeResponseInterface from "../interfaces/api/SubscribeResponseInterface";

export async function loadLanding(landingId: string): Promise<LandingInterface>  {
    return fetchData('/api/landing/' + landingId)
}

export function sendSubscribeMail(data:SubscribeResponseInterface): Promise<SubscribeInterface> {
    return fetchData('/api/customer/subscribe', data , 'POST')
}