import React, {useEffect} from "react";
import RefundInterface from "../../../interfaces/customer/RefundInterface";

export default function Step4({refund}: {refund: RefundInterface}) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (<div className="lk__refund-petition--requisites">
            <div className="row">
                <div className="col-lg-5">
                    <h4>Ваше заявление на возврат №{refund.id} успешно оформлено</h4>
                    <div>В ближайшее время с Вами свяжется наш менеджер для уточнения деталей</div>
                    <div><a target={'_blank'} className={'btn btn-secondary mt-3'} href={'/api/refund/print/' + refund.id + '/' + refund.printKey + '/'}>Скачать заявление</a></div>
                </div>
            </div>
        </div>
    )
}