import Order from "./Order";
import {useOutletContext} from "react-router-dom";
import {CustomerCredentialResponse} from "../../../interfaces/api/CustomerCredentialResponseInterface";

export default function Orders() {
    const data = useOutletContext<CustomerCredentialResponse>();


    return (<div className="tab-pane fade active show">
            <h3 className="lk__title f-exp desk">Заказы</h3>
            <div className="lk__orders column">
                {data.orders.map(order => <Order key={order.id} order={order} />)}
            </div>
        </div>
    )
}