import Spacer from '../Spacer/Spacer.jsx'
import React from 'react'
import styles from './SliderBanner.module.scss'
import {Swiper, SwiperSlide} from 'swiper/react'
import '../../styles/styles.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


import {Navigation, Autoplay} from "swiper/modules";

const SliderBanner = props => {
	const slides = props.params.slides

	const [swiper, setSwiper] = React.useState()
	const prevRef = React.useRef(null);
	const nextRef = React.useRef(null);

	return (
		<>
			<div className='mblock '>
				<div className={'banner-on-main ' + styles.centeredSlider}>
					<div className={'carousel-container ' + styles.sliderBanner} >
						<div className='swiper-button sliderBanner_left' ref={prevRef}>
							<img
								src='/public/new-design/design2019/main/arrow-left-big.png'
								alt=''
							/>
						</div>

						<Swiper
							grabCursor={true}
							centeredSlides={false}
							slidesPerView={1}
							loop={true}
							speed={400}
							spaceBetween={0}
							modules={[Autoplay, Navigation]}
							autoplay={{
								delay: 5000,
							}}
							navigation={{
								prevEl: prevRef.current,
								nextEl: nextRef.current,
							}}
							breakpoints={{
								768: {
									navigation: {
										enabled: true,
									},
								},
							}}
							onSwiper={setSwiper}
						>
							{slides.map((slide, i) => {
								return (
									<SwiperSlide key={i}>
										<a href={slide.url}>
											<img src={getBaseImagePath() + slide.img} alt={slide.img} />
										</a>
									</SwiperSlide>
								)
							})}
						</Swiper>
						<div className='swiper-button sliderBanner_right' ref={nextRef}>
							<img
								src='/public/new-design/design2019/main/arrow-right-big.png'
								alt=''
							/>
						</div>
					</div>
				</div>
				<div className={styles.sliderSpace}></div>
			</div>
			<Spacer />
		</>
	)
}

export default SliderBanner