// import {alfaRedirectLink} from "../../api/openid";

import {ALFA_CLIENT_ID} from "../../Config";

export default function OpenidBlock() {

    const redirectToAlfaUrl = () => {
        window.location.href =  "https://id.alfabank.ru/oidc/authorize?response_type=code&client_id=" + ALFA_CLIENT_ID + "&redirect_uri=https://baon.ru/openid/alfa/&scope=openid%20profile%20email%20phone&state=" + Math.random().toString(36).substring(2) + Date.now()

        // долго грузит
        // return alfaRedirectLink().then(response => {
        //     window.location.href = response.uri;
        // })
    }

    return (<>
        <div className="form-text mt-3">
            <p>или</p>
        </div>
        <div className="form-btns btns-vertical btns--logos">
            {/*<a className="btn btn-secondary-tr" href=""><img src="/img/logos/tinkoff.svg" alt=""*/}
            {/*                                                 width="auto" height="24"/> Войти c Tinkoff*/}
            {/*    ID</a>*/}
            {/*<a className="btn btn-secondary-tr" href=""><img src="/img/logos/yandex.svg" alt="" width="auto"*/}
            {/*                                                 height="24"/> Войти c Яндекс ID</a>*/}
            <button className="btn btn-secondary-tr" onClick={redirectToAlfaUrl}><img src="/img/logos/alfa.svg" alt=""
                                                                                      width="auto"
                                                                                      height="24"/> Войти c Alfa ID
            </button>
            {/*<a className="btn btn-secondary-tr" href=""><img src="/img/logos/sber.svg" alt="" width="auto"*/}
            {/*                                                 height="24"/> Войти со Сбер ID</a>*/}
        </div>
    </>)
}