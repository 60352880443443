import Form from "react-bootstrap/Form";

import Spacer from '../Spacer/Spacer.jsx'
import styles from './RegistrationForm.module.scss'
import {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import axios from 'axios'

const RegistrationForm = props => {
	const shops = props.params.shop

	const [message, setMessage] = useState("");


	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState,
		formState: {errors}
	} = useForm({
		defaultValues: {
			landing_form: true,
			email: "",
			first_name: "",
			phone: "",
			bill_number: "",
			approve_subscription: 0,
		}
	});

	useEffect(() => {
		if (formState.isSubmitSuccessful) {
			reset({
				landing_form: true,
				email: "",
				first_name: "",
				phone: "",
				bill_number: "",
				approve_subscription: 0,
				bill_date: "",
				approve: false,
			},)
		}
	}, [formState, reset])

	let formSubmit = async (data) => {

		try {

			let res = await axios.post("https://baon.ru/api/v2/customer/subscribe", {
				landing_form: data.landing_form,
				email: data.email,
				first_name: data.first_name,
				phone: data.phone,
				bill_number: data.bill_number,
				bill_date: data.bill_date,
				approve_subscription: data.approve_subscription,
				shop_id: data.shop_id
			});
			console.log("res", res.status)
			if (res.status === 200) {

				setMessage("Вы успешно зарегистрировались!");
			} else {

				setMessage("Произошла ошибка, попробуйте отправить email снова");
			}
		} catch (err) {

			setMessage("Такой email уже есть, попробуйте другой");
		}
	};

	return (
		<>
			<div
				className={
					styles.blockSubscribe
				}
			>
				<div className={styles.blockSubscribe_header}> Регистрация участника</div>
				<p className={styles.blockSubscribe_desc}>Пожалуйста, заполните все поля формы </p>
				<form onSubmit={handleSubmit(formSubmit)} className={styles.blockSubscribe_form}>

					<Form.Group>
						<Form.Label>Ваше Имя</Form.Label>
						<Form.Control
							type='text'
							className={styles.formInput}
							{...register('first_name', {required: false})}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Номер телефона</Form.Label>
						<Form.Control
							type='text'
							className={styles.formInput}
							placeholder="+7 ___ ___-__-__"
							{...register('phone', {required: false})}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Email</Form.Label>
						<Form.Control
							type='text'
							className={`${styles.formInput} ${styles.blockSubscribe_email}`}
							{...register("email", {
								required: "Email обязателен для заполнения",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Email заполнен неправильно"
								}
							})}
						/>
					</Form.Group>



					<Form.Group>
						<Form.Label>Номер чека или заказа</Form.Label>
						<Form.Control
							type='text'
							className={styles.formInput}
							{...register('bill_number', {required: false})}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Дата чека или заказа</Form.Label>
						<Form.Control
							type="date"
							dateformat="yyyy-mm-dd"
							className={styles.formInput}
							{...register("bill_date", {
								valueAsDate: true,
							})}
						/>
					</Form.Group>



					<Form.Group>
						<Form.Label>Адрес магазина</Form.Label>
						<Form.Select
							type="date"
							className={styles.formInput}
							{...register("shop_id", {
								valueAsNumber: true,
							})}
						>
							{shops.map((shop, i) => (
								<option value={i + 1} key={shop}>{shop}</option>
							))}
						</Form.Select>
					</Form.Group>


					<div className={styles.formCheckbox}>

						<input {...register("approve_subscription", {valueAsNumber: true, })} type="checkbox" value="1" />
						<label htmlFor="approve_subscription">Я согласен получать рассылку</label>
					</div>

					<div className={styles.formCheckbox}>

						<input  {...register("approve", {
							required: "Подтвердите правила и условия",
						})} type="checkbox" value="approve" />
						<label htmlFor="approve">Я старше 18 лет и принимаю <a href="https://baon.ru/public/new-design/baon-1111-2023/rules-2023-11.pdf" target="blank">правила акции</a></label>
					</div>

					<div className={styles.formBtn}>
						<button className="subscribe-button" type="submit">Зарегистрироваться</button>
					</div>


				</form>
				<div className={styles.successMessage}>{message ? <p>{message}</p> : null}</div>
				{errors.email && <p className={styles.validate}>{errors.email.message}</p>}
				{errors.approve && <p className={styles.validate}>{errors.approve.message}</p>}
			</div >
			<Spacer />
		</>
	)
}

export default RegistrationForm
