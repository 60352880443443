import BasketPopup from "./popup-collection/BasketPopup";
import Warning from "./popup-collection/Warning";
import React, {useContext, useEffect, useRef} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import AddToBasketPopup from "./popup-collection/AddToBasketPopup";
import AddToFavoritePopup from "./popup-collection/AddToFavoritePopup";
import {BASKET_POPUP_AUTOMATIC_DISAPPEAR, FAVORITE_POPUP_AUTOMATIC_DISAPPEAR} from "../../Config";
import AuthPopup from "./popup-collection/AuthPopup";
import {useLocation} from "react-router-dom";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import CityFinderModal from "./popup-collection/CityFinderModal";
import TableOfSizes from "./popup-collection/TableOfSizes";
import QuestionPopup from "./popup-collection/QuestionPopup";


export default function PopupCollection(){
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const basketTimer = useRef<number>(0);
    const favoriteTimer = useRef<number>(0);

    const location = useLocation();

    // автоматически убираем popup через BASKET_POPUP_AUTOMATIC_DISAPPEAR секунд
    useEffect(() => {
        if(state.addToBasketLastItem){
            if(basketTimer.current){
                window.clearTimeout(basketTimer.current)
            }
            basketTimer.current = window.setTimeout( () => {
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'addToBasketLastItem', payload: null})
            }, BASKET_POPUP_AUTOMATIC_DISAPPEAR)
            return () => {
                window.clearTimeout(basketTimer.current)
            }
        }
    }, [state.addToBasketLastItem]);

    useEffect(() => {
        if(state.addToFavoriteLastItem){
            if(favoriteTimer.current){
                window.clearTimeout(favoriteTimer.current)
            }
            favoriteTimer.current = window.setTimeout( () => {
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'addToFavoriteLastItem', payload: null})
            }, FAVORITE_POPUP_AUTOMATIC_DISAPPEAR)
            return () => {
                window.clearTimeout(favoriteTimer.current)
            }
        }
    }, [state.addToFavoriteLastItem]);

    useEffect(() => {
        dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
    }, [location]);

    return (<>
        {state.showBasketPopup && <BasketPopup/>}
        {state.addToBasketLastItem && <AddToBasketPopup basketItem={state.addToBasketLastItem} />}
        {state.addToFavoriteLastItem && <AddToFavoritePopup modelColorItem={state.addToFavoriteLastItem} />}
        { (state.showAuthPopup || state.showAuthByPhonePopup || state.showRegistrationPopup || state.showPasswordRecoveryPopup) && <AuthPopup />}
        {state.showRegionFinder && <CityFinderModal />}
        {state.showTableOfSizes && <TableOfSizes/>}
        {state.showQuestionPopup && <QuestionPopup/>}
        <Warning />
    </>)
}