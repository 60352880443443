

import TableSizeTableInterface from "../../../interfaces/table-size/TableSizeTableInterface";

// interface TableProps {
//     TSTable: TableSizeTableInterface;
// }

const TSTables = ({TSTable}:any) => {

	// console.log(TSTable)

	
let tableTemplate: any;

function makeColumns(row: any) {
    return (
        <>
            <li className="f-exp">{row.name}</li>
            {row.values.map((value: any, index:number) => (
                <li key={index}>{value}</li>
            ))}
        </>
    );
}

tableTemplate = TSTable.map((row:any, index: number) => {
    return <ul key={index}>{makeColumns(row)}</ul>;
});

    return (
        <>
            <div className="tables__window">
                <div className="tables__window-wrap">{tableTemplate}</div>
            </div>
        </>
    );
}

export default TSTables
