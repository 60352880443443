import ModelColorItemInterface from "../../../interfaces/model/ModelColorItemInterface";
import {useCallback, useEffect, useState} from "react";
import {FilterColorInterface} from "../../../interfaces/catalog/FilterColorInterface";
import {FilterSuperCategoryInterface} from "../../../interfaces/catalog/FilterSuperCategoryInterface";
import {SelectedFiltersInterface} from "../../../interfaces/catalog/SelectedFiltersInterface";
import {useLocation, useSearchParams} from "react-router-dom";
import ModelPropertyFilterInterface from "../../../interfaces/model/ModelPropertyFilterInterface";
import {SortType} from "./Sort";
import useUrlFilterConvertor from "./useUrlFilterConvertor";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {SIZE_ORDER} from "../../../Config";
import ModelCategoryInterface from "../../../interfaces/model/ModelCategoryInterface";

declare var $: any;

export default function Filters({
                                    isOpen,
                                    setIsOpen,
                                    modelColorItems,
                                    superCategories,
                                    categories,
                                    selectedFilters,
                                    setSelectedFilters,
                                    properties
                                }:
                                    {
                                        isOpen: boolean,
                                        setIsOpen: Function,
                                        modelColorItems: ModelColorItemInterface[],
                                        superCategories: FilterSuperCategoryInterface[],
                                        categories: ModelCategoryInterface[],
                                        selectedFilters: SelectedFiltersInterface,
                                        setSelectedFilters: (selectedFilters: SelectedFiltersInterface) => void,
                                        properties: ModelPropertyFilterInterface[]
                                    }) {

    const [selectedSizes, setSelectedSizes] = useState<string[]>([])
    const [selectedColors, setSelectedColors] = useState<FilterColorInterface[]>([])
    const [selectedSuperCategories, setSelectedSuperCategories] = useState<FilterSuperCategoryInterface[]>([])
    const [selectedCategories, setSelectedCategories] = useState<ModelCategoryInterface[]>([])
    const [selectedProperties, setSelectedProperties] = useState(new Map<number, string[]>())
    const [isFilterSelected, setIsFilterSelected] = useState(false)
    const [activeFolders, setActiveFolders] = useState<string[]>(['colors', 'sizes'])
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [fromPrice, setFromPrice] = useState(0)
    const [toPrice, setToPrice] = useState(0)

    const [searchParams, setSearchParams] = useSearchParams();
    const {convertFromUrl, filterFields} = useUrlFilterConvertor(selectedFilters, [], SortType.RAITING)

    const location = useLocation();

    const applyFilters = () => {
        setIsOpen(false)
    }



    const resetFilters = () => {
        setSelectedSizes([])
        setSelectedColors([])
        setSelectedSuperCategories([])
        setSelectedCategories([])
        setFromPrice(getInitPriceRange().min)
        setToPrice(getInitPriceRange().max)
        setSelectedProperties(new Map())
        applyFilters()

        filterFields().map(field => {
            searchParams.delete(field)
        })

        selectedProperties.forEach((value, key) => {
            searchParams.delete('prop_' + key)
        })

        setSearchParams(searchParams)
    }

    const applyPriceRqange = () => {
        $('.js-one-price-slider').ionRangeSlider({
            skin: "round",
            type: "double",
            from: getInitPriceRange().min,
            to: getInitPriceRange().max,
            min: getInitPriceRange().min,
            max: getInitPriceRange().max,
            onFinish: (slider: any) => {
                setFromPrice(slider.from)
                setToPrice(slider.to)
            }
        });


        // $('.scrollbar-inner').scrollbar({"scrollx": "advanced", "scrolly": "advanced"});
    }

    const getUniqueColors = useCallback((): FilterColorInterface[] => {
        let uniqueColors = new Map();

        modelColorItems.map(colorItem => {
            if (colorItem.color_human_name && colorItem.hexcolor && colorItem.hexcolor.length === 7) {
                uniqueColors.set(colorItem.color_human_name, {
                    'color': colorItem.color_human_name,
                    'hex': colorItem.hexcolor
                })
            }
            return colorItem

        })

        return Array.from(uniqueColors.values())
    }, [modelColorItems])

    const getUniqueSizes = useCallback(() => {
        const uniqueSizes = modelColorItems.map(colorItem => Object.keys(colorItem.stocks)).flat()
            .filter((size, index, sizes) => sizes.indexOf(size) === index)

        const uniqueSorteredSize = SIZE_ORDER.split('|').filter(size => uniqueSizes.indexOf(size) > -1)

        return uniqueSorteredSize
    }, [modelColorItems])


         useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!modelColorItems.length) return

        if (!location.search) return

        const paramsFromFilters = convertFromUrl(searchParams.toString())

        paramsFromFilters.map(item => {

            if (item.name === 'supercategory' && superCategories?.length) {
                const superCategoriesFromParams = superCategories.filter(superCategory => item.value.indexOf('' + superCategory.id) > -1)
                setSelectedSuperCategories(superCategoriesFromParams)
                setActiveFolders([...activeFolders, 'supercategory'])
            }

            if (item.name === 'category' && categories?.length) {
                const categoriesFromParams = categories.filter(category => item.value.indexOf('' + category.id) > -1)
                setSelectedCategories(categoriesFromParams)
                setActiveFolders([...activeFolders, 'category'])
            }

            if (item.name === 'price') {
                const [from, to] = item.value

                if (from) {
                    setFromPrice(parseInt(from))
                }
                if (to) {
                    setToPrice(parseInt(to))
                }
            }

            if (item.name === 'size') {
                setSelectedSizes(item.value)
            }

            if (item.name === 'color') {
                setSelectedColors(getUniqueColors().filter(color => item.value.indexOf(color.color) > -1))
            }

            if (item.id) {
                setProperties(item.id, item.value)
                setActiveFolders([...activeFolders, 'prop' + item.id])
            }
        })

    }, [searchParams, modelColorItems.length]);

    useEffect(() => {
        if (!location.search) {
            resetFilters()
        }
    }, [location.pathname]);

    const getFilteredSuperCategories = useCallback((): FilterSuperCategoryInterface[] => {
        if (!superCategories) {
            return []
        }

        let uniqueSuperCategoryIdInModels: number[] = [];

        modelColorItems.map(colorItem => {
            uniqueSuperCategoryIdInModels.push(colorItem.super_category_id)
        })

        return superCategories.filter(superCategory => {
            let exist = false;
            superCategory.id.map(sid => {
                if (uniqueSuperCategoryIdInModels.indexOf(sid) !== -1) {
                    exist = true
                }
            })
            return exist
        })
    }, [modelColorItems])

    const getInitPriceRange = () => {
        if (!modelColorItems.length) {
            return {
                min: 0,
                max: 0
            }
        }

        const uniquePrices = modelColorItems.map(colorItem => colorItem.min_price.amount)

        return {
            min: Math.min.apply(null, uniquePrices) / 100,
            max: Math.max.apply(null, uniquePrices) / 100
        }
    }

    const maxPrice = getInitPriceRange().max

useEffect(() => {
    const handleScrollLock = () => {
        if (isOpen && window.innerWidth <= 991) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    };

    handleScrollLock();
    window.addEventListener('resize', handleScrollLock);

    return () => {
        window.removeEventListener('resize', handleScrollLock);
        document.body.style.overflow = '';
    };
}, [isOpen]);

    useEffect(() => {
        if (maxPrice) {
            if(!searchParams.get('price')){
                setFromPrice(getInitPriceRange().min)
                setToPrice(getInitPriceRange().max)
            }
            applyPriceRqange()
        }

    }, [maxPrice]);

    useEffect(() => {
        if (maxPrice) {
            $('.js-one-price-slider').data("ionRangeSlider").update({
                from: fromPrice,
                to: toPrice,
            })
        }
    }, [fromPrice, toPrice]);

    useEffect(() => {
        setSelectedFilters({
            colors: selectedColors,
            sizes: selectedSizes,
            superCategories: selectedSuperCategories,
            priceRange: fromPrice > getInitPriceRange().min || toPrice < getInitPriceRange().max ? {
                from: fromPrice,
                to: toPrice
            } : null,
            categories: selectedCategories,
            properties: selectedProperties
        })

        setIsFilterSelected(selectedSizes.length > 0 || selectedColors.length > 0 || selectedSuperCategories.length > 0 || selectedCategories.length > 0 || fromPrice > getInitPriceRange().min || toPrice < getInitPriceRange().max || selectedProperties.size > 0)
    }, [selectedSizes, selectedColors, fromPrice, toPrice, selectedSuperCategories, selectedCategories, selectedProperties])


    // -------


    const addColor = (inColor: FilterColorInterface) => {
        let uniqueColors = new Map(selectedColors.map(color => [color.color, color]));

        uniqueColors.set(inColor.color, inColor)

        setSelectedColors(Array.from(uniqueColors).map(([_, value]) => value))
    }

    const removeColor = (inColor: FilterColorInterface) => {
        setSelectedColors(selectedColors && selectedColors.filter(color => color.color !== inColor.color))
    }

    const isColorActive = (inColor: FilterColorInterface) => {
        return selectedColors?.filter(color => color.color === inColor.color).length > 0
    }

    // -------

    const addSize = (size: string) => {
        setSelectedSizes([...selectedSizes, ...[size]].filter((size, index, sizes) => sizes.indexOf(size) === index))
    }

    const removeSize = (inSize: string) => {
        setSelectedSizes(selectedSizes?.filter(size => size != inSize) || [])
    }


    const isSizeActive = (inSize: string) => {
        return selectedSizes.filter(size => size === inSize).length > 0
    }

    // -------

    const isSuperCategoryActive = (superCategoryIn: FilterSuperCategoryInterface) => {
        return selectedSuperCategories.filter(superCategory => superCategory.name === superCategoryIn.name).length > 0
    }

    const addSuperCategory = (inSuperCategory: FilterSuperCategoryInterface) => {
        let uniqueSuperCategories = new Map(selectedSuperCategories.map(color => [color.name, color]));

        uniqueSuperCategories.set(inSuperCategory.name, inSuperCategory)

        setSelectedSuperCategories(Array.from(uniqueSuperCategories).map(([_, value]) => value))
    }

    const removeSuperCategory = (inSuperCategory: FilterSuperCategoryInterface) => {
        setSelectedSuperCategories(selectedSuperCategories.filter(superCategory => superCategory.name !== inSuperCategory.name))
    }

    // ---------

    const isCategoryActive = (categoryIn: ModelCategoryInterface) => {
        return selectedCategories.filter(category => category.name === categoryIn.name).length > 0
    }

    const addCategory = (inCategory: ModelCategoryInterface) => {
        let uniqueCategories = new Map(selectedCategories.map(category => [category.name, category]));

        uniqueCategories.set(inCategory.name, inCategory)

        setSelectedCategories(Array.from(uniqueCategories).map(([_, value]) => value))
    }

    const removeCategory = (inCategory: ModelCategoryInterface) => {
        setSelectedCategories(selectedCategories.filter(category => category.name !== inCategory.name))
    }

    // ---------

    const isPropertyActive = (id: number, value: string) => {
        return (selectedProperties.get(id) || []).find(svalue => value === svalue)
    }

    const addProperty = (id: number, value: string) => {
        let values = selectedProperties.get(id) || []
        values.push(value)
        selectedProperties.set(id, values)
        setSelectedProperties(new Map(selectedProperties))
    }

    const setProperties = (id: number, values: string[]) => {
        selectedProperties.set(id, values)
        setSelectedProperties(new Map(selectedProperties))
    }

    const removeProperty = (id: number, value: string) => {
        let values = selectedProperties.get(id) || []
        let nvalues = values.filter(svalue => svalue !== value)
        selectedProperties.set(id, nvalues)
        setSelectedProperties(new Map(selectedProperties))
    }

    const toggleFolder = (name: string) => {
        setActiveFolders(isFolderActive(name) ? [...activeFolders.filter(val => val !== name)] : [...activeFolders, name])
    }

    const isFolderActive = (name: string) => {
        return activeFolders.find(sname => sname === name)
    }

// const renderSimpleBar = (children:any) => {
//          if (windowWidth >= 2560) {
//         return (
//             <SimpleBar style={{ maxHeight: 1050 }}  autoHide={false}>
//                 {children}
//             </SimpleBar>
//         );
//     }
//     else if (windowWidth >= 1480) {
//         return (
//             <SimpleBar style={{ maxHeight: 680 }}  autoHide={false}>
//                 {children}
//             </SimpleBar>
//         );
//     } else if (windowWidth >= 991) {
//         return (
//             <SimpleBar style={{ maxHeight: 500 }}  autoHide={false}>
//                 {children}
//             </SimpleBar>
//         );
//     } else {
//         return (
//             <div style={{ maxHeight: 530, overflowY: 'auto' }}>
//                 {children}
//             </div>
//         );
//     }
// }

    return (<div className={"all-filters 100vh-fix " + (isOpen && 'active')}>
        <div className="head">
            <h5 className="f-exp">Фильтры</h5>
            <i className="close ico ico-close pointer" onClick={() => {
                setIsOpen(false)
            }}></i>
        </div>
        <div className="body">

                <div className="scrollbar-inner">
                   {/* {getFilteredSuperCategories().length > 1 && <div className="all-filters-block">
                        <div className="all-filters__accord">
                            <p className={"all-filters__title all-filters__link f-exp " + (isFolderActive('supercategory') && 'active')}
                               onClick={() => {
                                   toggleFolder('supercategory')
                               }}>Категория <i
                                className="ico ico-arrow-bot-b"></i></p>
                            {isFolderActive('supercategory') && <div className="all-filters__col">
                                <div className="all-filters__height">
                                    <SimpleBar style={{maxHeight: 230}} autoHide={false}>
                                        <div className="scrollbar-inner">

                                            <ul className="filters__collapse-checkboxes">
                                                {getFilteredSuperCategories().map(superCategory => <li
                                                    key={superCategory.name}>
                                                    <div
                                                        className={"form-checkbox " + (isSuperCategoryActive(superCategory) && "active")}
                                                        onClick={() => isSuperCategoryActive(superCategory) ? removeSuperCategory(superCategory) : addSuperCategory(superCategory)}>
                                                        <label className={"label-dot"}></label>
                                                        <label className="label-text">{superCategory.name}</label>
                                                    </div>
                                                </li>)}
                                            </ul>

                                        </div>
                                    </SimpleBar>
                                </div>
                            </div>}
                        </div>
                    </div>}
                    {getFilteredSuperCategories().length > 1 && <ul className="all-filters__activated">
                        {selectedSuperCategories.map((superCategory) => <li key={superCategory.name}><a
                            className="ico-cont"
                            href="/">
                            <i className="ico ico-right ico-delete"
                               onClick={(event) => {
                                   event.preventDefault()
                                   removeSuperCategory(superCategory)
                               }}></i>{superCategory.name}</a></li>)}
                    </ul>}
*/}


                    {categories?.length > 1 && <div className="all-filters-block">
                    <div className="all-filters__accord">
                        <p className={"all-filters__title all-filters__link f-exp " + (isFolderActive('category') && 'active')}
                           onClick={() => {
                               toggleFolder('category')
                           }}>Категория <i
                            className="ico ico-arrow-bot-b"></i></p>
                        {isFolderActive('category') && <div className="all-filters__col">
                            <div className="all-filters__height">
                                <SimpleBar style={{maxHeight: 230}} autoHide={false}>
                                    <div className="scrollbar-inner">

                                        <ul className="filters__collapse-checkboxes">
                                            {categories.map(category => <li
                                                key={category.name}>
                                                <div
                                                    className={"form-checkbox " + (isCategoryActive(category) && "active")}
                                                    onClick={() => isCategoryActive(category) ? removeCategory(category) : addCategory(category)}>
                                                    <label className={"label-dot"}></label>
                                                    <label className="label-text">{category.name}</label>
                                                </div>
                                            </li>)}
                                        </ul>

                                    </div>
                                </SimpleBar>
                            </div>
                        </div>}
                    </div>
                </div>}
                    {categories?.length > 1 && <ul className="all-filters__activated">
                        {selectedCategories.map((category) => <li key={category.name}><a
                            className="ico-cont"
                            href="/">
                            <i className="ico ico-right ico-delete"
                               onClick={(event) => {
                                   event.preventDefault()
                                   removeCategory(category)
                               }}></i>{category.name}</a></li>)}
                    </ul>}



                    <div className="all-filters-block">
                        <div className="all-filters-price">
                            <p className="all-filters__title f-exp">Цена</p>
                            <div className="range-wrap">
                                <div className="range-inputs">
                                    <div className="range-inputs-group">
                                        <input type="text" className="js-one-price-from" value={fromPrice}
                                               onChange={(event) => {
                                                   setFromPrice(parseInt(event.target.value) || 0)
                                               }} onBlur={() => {
                                            if (fromPrice < getInitPriceRange().min) {
                                                setFromPrice(getInitPriceRange().min)
                                            }
                                        }}/>
                                        <p>от</p>
                                    </div>
                                    <div className="range-inputs-group">
                                        <input type="text" className="js-one-price-to" value={toPrice}
                                               onChange={(event) => {
                                                   setToPrice(parseInt(event.target.value) || 0)
                                               }} onBlur={() => {
                                            if (toPrice > getInitPriceRange().max) {
                                                setToPrice(getInitPriceRange().max)
                                            }
                                        }}/>
                                        <p>до</p>
                                    </div>
                                </div>
                                <div className="range-slider">
                                    <input type="text" className="js-one-price-slider"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="all-filters-block all-filters-block--size">
                        <div className="all-filters__accord">
                            <p className={"all-filters__title all-filters__link f-exp " + (isFolderActive('sizes') && 'active')}
                               onClick={() => {
                                   toggleFolder('sizes')
                               }}>Размер <i
                                className="ico ico-arrow-bot-b"></i></p>
                            {isFolderActive('sizes') && <div className="all-filters__col">
                                <div className="all-filters__height">
                                    <ul className="all-filters-sizes">
                                        {getUniqueSizes().map(size => <li key={size}>
                                            <div className={"form-checkbox " + (isSizeActive(size) && "active")}>
                                                <label className={"label-dot " + (size.length > 4 ? "label-long" : '')}
                                                       onClick={() => {
                                                           isSizeActive(size) ? removeSize(size) : addSize(size)
                                                       }}>{size}</label>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <ul className="all-filters__activated">
                        {selectedSizes.map(size => <li key={size}><a className="ico-cont" href="/" onClick={(event) => {
                            event.preventDefault()
                            removeSize(size)
                        }}>{size}<i className="ico ico-right ico-delete"></i></a></li>)}
                    </ul>
                    <div className="all-filters-block">
                        <div className="all-filters__accord">
                            <p className={"all-filters__title all-filters__link f-exp " + (isFolderActive('colors') && 'active')}
                               onClick={() => {
                                   toggleFolder('colors')
                               }}>Цвет <i
                                className="ico ico-arrow-bot-b" onClick={() => {
                                toggleFolder('colors')
                            }}></i></p>
                            {isFolderActive('colors') && <div className="all-filters__col">
                                <div className="all-filters__height">
                                    <SimpleBar style={{maxHeight: 230}} autoHide={false}>
                                        <div className="scrollbar-inner">
                                            <ul className="filters__collapse-checkboxes">
                                                {getUniqueColors().map(color => <li key={color.color}>
                                                    <div
                                                        className={"form-checkbox " + (isColorActive(color) && "active")}
                                                        onClick={() => isColorActive(color) ? removeColor(color) : addColor(color)}>
                                                        <label className={"label-dot"}></label>
                                                        <label className="label-text"><i
                                                            style={{background: color.hex}}></i>{color.color}</label>
                                                    </div>
                                                </li>)}
                                            </ul>
                                        </div>
                                    </SimpleBar>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <ul className="all-filters__activated">
                        {selectedColors.map((color) => <li key={color.color}><a className="ico-cont" href="/"><i
                            className="color" style={{background: color.hex}}></i><i
                            className="ico ico-right ico-delete"
                            onClick={(event) => {
                                event.preventDefault()
                                removeColor(color)
                            }}></i></a></li>)}
                    </ul>
                    {properties.length > 0 && properties.map(property =>
                        <div
                            key={property.id} className="all-filters-block">
                            <div className="all-filters__accord">
                                <p className={"all-filters__title all-filters__link f-exp " + (isFolderActive('prop' + property.id) && 'active')}
                                   onClick={() => {
                                       toggleFolder('prop' + property.id)
                                   }}>{property.name}
                                    <i
                                        className="ico ico-arrow-bot-b"></i></p>
                                {isFolderActive('prop' + property.id) && <div className="all-filters__col">
                                    <div className="">
                                        <SimpleBar style={{maxHeight: 230}} autoHide={false}>
                                            <div className="scrollbar-inner">
                                                <ul className="filters__collapse-checkboxes">
                                                    {property.values.map(value => <li key={value}>
                                                        <div
                                                            className={"form-checkbox " + (isPropertyActive(property.id, value) && "active")}
                                                            onClick={() => isPropertyActive(property.id, value) ? removeProperty(property.id, value) : addProperty(property.id, value)}>
                                                            <label className={"label-dot"}></label>
                                                            <label className="label-text">{value}</label>
                                                        </div>
                                                    </li>)}
                                                </ul>
                                            </div>
                                        </SimpleBar>
                                    </div>
                                </div>}
                            </div>
                        </div>)}
                    {/*<div className="all-filters-block all-filters-block--other">*/}
                    {/*    <div className="all-filters__accord">*/}
                    {/*        <p className="all-filters__title all-filters__link f-exp">Утеплитель <i*/}
                    {/*            className="ico ico-arrow-bot-b"></i></p>*/}
                    {/*        <div className="all-filters__col">*/}
                    {/*            <div className="all-filters__height">*/}
                    {/*                <div className="form-checkbox">*/}
                    {/*                    <input className="hidden" id="check-all-filter-5-01" type="checkbox"/>*/}
                    {/*                    <label className="label-dot" htmlFor="check-all-filter-5-01"></label>*/}
                    {/*                    <label className="label-text" htmlFor="check-all-filter-5-01">Пух/перо</label>*/}
                    {/*                </div>*/}
                    {/*                <div className="form-checkbox">*/}
                    {/*                    <input className="hidden" id="check-all-filter-5-02" type="checkbox"/>*/}
                    {/*                    <label className="label-dot" htmlFor="check-all-filter-5-02"></label>*/}
                    {/*                    <label className="label-text" htmlFor="check-all-filter-5-02">Эко-пух</label>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

        </div>
        {isFilterSelected && <div className="btns">
            <a className="btn btn-secondary" href="/" onClick={(event) => {
                event.preventDefault()
                applyFilters()
            }}>Применить</a>
            <a className="btn  btn-outline-secondary" href="/" onClick={(event) => {
                event.preventDefault()
                resetFilters()
            }}>Сбросить</a>
        </div>}
    </div>)
}