import {createContext} from "react";
import {BasketItemInterface} from "./interfaces/core/BasketItemInterface";
import ModelColorItemInterface from "./interfaces/model/ModelColorItemInterface";
import {PaymentInterface} from "./interfaces/checkout/PaymentInterface";
import {ReducerStateInterface} from "./interfaces/core/ReducerStateInterface";
import {PromotionInterface} from "./interfaces/news/PromotionInterface";


const defaultContextValue: any = null;

const DEFAULT_COUNTRY_CODE = 'ru';


export const GlobalContext = createContext(defaultContextValue);

const broofa = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const initReducerParams = {
    headerMenu: null,  // текущее открытое меню
    basket: [],
    favorites: [],
    hideNavbar: false,
    menu: [],
    error: null,

    showBasketPopup: false,
    showFavoritePopup: false,
    showIsYourCityPopup: false,
    showSearchBoard: false,

    showAuthPopup: false,
    showLoginPopup: false,
    showPasswordRecoveryPopup: false,
    showAuthByPhonePopup: false,
    showRegistrationPopup: false,
    showRegionFinder: false,

    showTableOfSizes: false,
    showQuestionPopup: false,

    isLoading: false,
    isLoadingCounter: 0,

    addToBasketLastItem: null,
    addToFavoriteLastItem: null,

    showCookieWarn: true,
    authNotification: true,
    

    searchHistory: [],

    nextAction: null,  // следующее действие (как правило для глобального popup)

    showBackdrop: false, // показываем backdrop

    // checkout

    positions: [],
    promocodes: [],
    giftCertificates: [],
    codeword: null,
    region: {
        id: null,
        name: null
    },
    delivery: null,
    payment: null,
    // delivery: getOrderParams().is_fast_order ? {"delivery_type": {"id": FAST_ORDER_DELIVERY_ID, "payments" : []}} : null,
    // payment: getOrderParams().is_fast_order ? {"id": FAST_ORDER_PAYMENT_ID} : null,
    location: null,
    writeOffBonuses: 0,
    isBonusDisabled: false,
    uid: broofa(),
    address: null,
    courierDate: null,
    courierInterval: null,
    comment: null,
    country: {
        id: DEFAULT_COUNTRY_CODE,
        select_city_allow: true
    },
    customer: {
        id: null,
        sign: null
    },
    personalData: null,
    isInitialized: false,
    lastExecutionTime: Date.now(),
    isFastOrder: false,
    showTechInfo: false,
    hasSabManagers: false,
    sabManager: null,
    authBlock: null,
    meta: null // мета параметры страницы
}



interface ReducerAction {
    paramName?: string
    payload: any
    type: ReducerActionType
}

export enum ReducerActionType {
    SET_PARAM = 'SET_PARAM',
    SET_HEADER_MENU = 'SET_HEADER_MENU',

    ADD_TO_BASKET = 'ADD_TO_BASKET',
    REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET',
    SET_BASKET = 'SET_BASKET',

    ADD_TO_FAVORITE = 'ADD_TO_FAVORITE',
    REMOVE_FROM_FAVORITE = 'REMOVE_FROM_FAVORITE',

    TOGGLE_BASKET_POPUP = 'TOGGLE_BASKET_POPUP',
    TOGGLE_SEARCH_BOARD = 'TOOGLE_SEARCH_BOARD',

    TOGGLE_TABLEOFSIZES_POPUP = 'TOGGLE_TABLEOFSIZES_POPUP',
    TOGGLE_QUESTION_POPUP = 'TOGGLE_QUESTION_POPUP',

    SHOW_POPUP = 'SHOW_POPUP',
    CLOSE_ALL_POPUP = 'CLOSE_ALL_POPUP',

    // SET_PROMOTION = 'SET_PROMOTION',

    SET_POSITIONS = 'SET_POSITIONS',
    SET_DELIVERY = 'SET_DELIVERY',
    SET_LOCATION = 'SET_LOCATION',
    SET_COUNTRY = 'SET_COUNTRY',
    SET_ADDRESS = 'SET_ADDRESS',

    LOAD = 'LOAD',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    SHOW_AUTH_NOTIFICATION = 'SHOW_AUTH_NOTIFICATION',
}

export enum PopupType {
    LOGIN = 'showAuthPopup',
    PASSWORD_RECOVERY = 'showPasswordRecoveryPopup',
    REGISTRATION = 'showRegistrationPopup',
    LOGIN_BY_PHONE = 'showAuthByPhonePopup',
}

export const resetPopup = {
    headerMenu: null,

    showBasketPopup: false,
    showFavoritePopup: false,
    showIsYourCityPopup: false,
    showSearchBoard: false,
    addToFavoriteLastItem: null,
    addToBasketLastItem: null,

    showAuthPopup: false,
    showLoginPopup: false,
    showPasswordRecoveryPopup: false,
    showAuthByPhonePopup: false,
    showRegistrationPopup: false,
    showRegionFinder: false,

    showTableOfSizes: false,
    showQuestionPopup: false,

    error: null
}

export const showBackdrop = {
    showBasketPopup: false,
    showFavoritePopup: false,
    showIsYourCityPopup: false,
    showSearchBoard: false,

    showAuthPopup: false,
    showLoginPopup: false,
    showPasswordRecoveryPopup: false,
    showAuthByPhonePopup: false,
    showRegistrationPopup: false,
    showRegionFinder: false,

    showTableOfSizes: false,
    showQuestionPopup: false,

    error: null
}


export function globalReducer(state: ReducerStateInterface, action: ReducerAction) {
    state.error = null
    switch (action.type) {
        case ReducerActionType.SET_PARAM: {
            if (!action.paramName) {
                return
            }

            if (!(action.paramName in state)) {
                throw new Error('Wrong param name ' + action.paramName);
            }
            return {
                ...state,
                [action.paramName]: action.payload,
            };
        }
        case ReducerActionType.SHOW_POPUP: {
            return {
                ...state,
                ...resetPopup,
                [action.payload]: true
            };
        }
        case ReducerActionType.CLOSE_ALL_POPUP: {
            return {
                ...state,
                ...resetPopup
            };
        }
        case  ReducerActionType.SET_HEADER_MENU: {
            return {
                ...state,
                ...resetPopup,
                headerMenu: action.payload
            };
        }
        case  ReducerActionType.ADD_TO_BASKET: {
            let basket: BasketItemInterface[] = state.basket
            let item: BasketItemInterface = action.payload
            const existItem = basket.find(basketItem => basketItem.modelColorItem.model_id === item.modelColorItem.model_id && basketItem.modelColorItem.color_special === item.modelColorItem.color_special && basketItem.size === item.size)
            if (existItem) {
                existItem.quantity += 1;
                existItem.createdAt = new Date()
            } else {
                basket.push(item);
            }

            basket = basket.filter(item => !!item.modelColorItem)

            return {
                ...state,
                ...resetPopup,
                addToBasketLastItem: item,
                basket: [...basket]
            }
        }
        case  ReducerActionType.SET_BASKET: {
            return {
                ...state,
                basket: [...action.payload]
            }
        }
        case  ReducerActionType.TOGGLE_BASKET_POPUP: {
            return {
                ...state,
                ...resetPopup,
                showBasketPopup: !state.showBasketPopup
            }
        }
        case  ReducerActionType.TOGGLE_SEARCH_BOARD: {
            return {
                ...state,
                ...resetPopup,
                showSearchBoard: !state.showSearchBoard
            }
        }
        case  ReducerActionType.REMOVE_FROM_BASKET: {
            let basket: BasketItemInterface[] = state.basket
            let item: BasketItemInterface = action.payload

            return {
                ...state,
                addToBasketLastItem: null,
                basket: [...basket.filter(basketItem => !(basketItem.modelColorItem.model_id === item.modelColorItem.model_id && basketItem.modelColorItem.color_special === item.modelColorItem.color_special && basketItem.size === item.size)).map(item => {
                    item.createdAt = new Date()
                    return item
                })]
            }
        }
        case  ReducerActionType.ADD_TO_FAVORITE: {
            let favorites: ModelColorItemInterface[] = state.favorites
            let item: ModelColorItemInterface = action.payload
            const existItem = favorites.find(favoriteItem => favoriteItem.model_id === item.model_id && favoriteItem.color_special === item.color_special)
            if (!existItem) {
                favorites.push(item);
            }

            return {
                ...state,
                ...resetPopup,
                addToFavoriteLastItem: item,
                favorites: [...favorites]
            }
        }
        case  ReducerActionType.REMOVE_FROM_FAVORITE: {
            let favorites: ModelColorItemInterface[] = state.favorites
            let item: ModelColorItemInterface = action.payload
            return {
                ...state,
                addToFavoriteLastItem: null,
                favorites: [...favorites.filter(favoriteItem => !(favoriteItem.model_id === item.model_id && favoriteItem.color_special === item.color_special))]
            }
        }

        case  ReducerActionType.TOGGLE_QUESTION_POPUP: {
            return {
                ...state,
                ...resetPopup,
                showQuestionPopup: !state.showQuestionPopup
            }
        }

        case  ReducerActionType.TOGGLE_TABLEOFSIZES_POPUP: {
            return {
                ...state,
                ...resetPopup,
                showTableOfSizes: !state.showTableOfSizes
            }
        }

        case ReducerActionType.SHOW_AUTH_NOTIFICATION: {
            return {
                ...state,
                showAuthNotification: action.payload
            };
        }

        // case ReducerActionType.SET_PROMOTION: {
        // let item: PromotionInterface = action.payload
        //     return {
        //         ...state,
        //         ...resetPopup,
        //         setPromotion: item
        //     }
        // }

        // ---
        case ReducerActionType.SET_POSITIONS: {
            return {
                ...state,
                isInitialized: true,
                positions: action.payload
            };
        }
        case ReducerActionType.SET_DELIVERY: {

            const payloadPayments: PaymentInterface[] = action.payload.payments

            let payment = state.payment && (state.isFastOrder || payloadPayments.map(payment => payment.id).includes(state.payment.id)) ? state.payment : null

            // если только одинн вид оплаты - ставим его
            if (!payment && action.payload.payments.length === 1) {
                payment = action.payload.payments[0]
            }

            return {
                ...state,
                location: state.delivery?.delivery_type?.id === action.payload?.delivery_type.id ? state.location : null,
                courierInterval: null,
                courierDate: null,
                writeOffBonuses: action.payload.bonus_apply_allow ? state.writeOffBonuses : 0,
                isBonusDisabled: !action.payload.bonus_apply_allow,
                delivery: action.payload,
                payment: payment
            }
        }
        case ReducerActionType.SET_LOCATION: {
            return {
                ...state,
                address: null,
                location: action.payload
            }
        }
        case ReducerActionType.SET_COUNTRY: {
            return {
                ...state,
                address: null,
                location: null,
                country: action.payload,
                region: action.payload.default_region
            }
        }
        case ReducerActionType.SET_ADDRESS: {
            return {
                ...state,
                address: action.payload,
                location: null
            }
        }

        // ----------


        case  ReducerActionType.LOAD: {
            return {
                ...state,
                lastExecutionTime: Date.now(),
                isLoading: true,
                isLoadingCounter: state.isLoadingCounter + 1
            };
        }
        case  ReducerActionType.SUCCESS: {
            return {
                ...state,
                isLoading: state.isLoadingCounter - 1 > 0,
                isLoadingCounter: state.isLoadingCounter - 1 > 0 ? state.isLoadingCounter - 1 : 0
            };
        }
        case  ReducerActionType.ERROR: {
            return {
                ...state,
                error: typeof action.payload === 'string' ? {'text' : action.payload} : action.payload,
                isLoading: state.isLoadingCounter - 1  > 0,
                isLoadingCounter: state.isLoadingCounter - 1 > 0 ? state.isLoadingCounter - 1 : 0
            };
        }
  
        default:
            return state
    }
}