import fetchData from "../utils/HttpClient";
import {ModelFilterTagInterface} from "../interfaces/catalog/ModelFilterTagInterface";

export function saveModelFilterTag(modelFilterTag: any): Promise<ModelFilterTagInterface> {
    return fetchData('/api/minda/model-property-tag/save', modelFilterTag, 'POST')
}
export function deleteModelFilterTag(modelFilterTag: ModelFilterTagInterface): Promise<{ status: string }> {
    return fetchData('/api/minda/model-property-tag/delete', modelFilterTag, 'POST')
}

export function getModelFilterTags(superCategoryId: number): Promise<ModelFilterTagInterface[]> {
    return fetchData('/api/minda/model-property-tag/list/' + superCategoryId)
}