import {useContext, useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import FileUpload from "../../utils/FileUpload";
import {Rating} from "react-simple-star-rating";
import {useForm, Controller} from "react-hook-form";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {addReview} from "../../api/customer";

export default function ModalReview({
    model,
    selectedSize,
    selectedColor,
    getFrontImages,
    getBaseImagePath,
    getImageAlt,
    setShowReviewPopup,
}: {
    model: any;
    selectedSize: string;
    selectedColor: any;
    getFrontImages: any;
    getBaseImagePath: any;
    getImageAlt: any;
    setShowReviewPopup: any;
}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    // const [showReviewPopup, setShowReviewPopup] = useState(false);
    const [reviewPopup, setReviewPopup] = useState(null);
    const [reviewSend, setReviewSend] = useState(false);
    const [reviewImages, setReviewImages] = useState([]);
    const [rating, setRating] = useState(0);
    const [error, setError] = useState<string | null>(null);

    const handleRating = (rate: number) => {
        setRating(rate);
    };

    const {register, handleSubmit, reset, formState, control} = useForm();

    const setBase64 = async (id: any, data: any) => {
        // console.log(" -->> ", data);
        setReviewImages(data);
    };

    useEffect(() => {
        const test = reviewImages;
        console.log("state.customer.id ->", state);
        // console.log("model.id ->", model.id);
    }, [reviewImages]);

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            // reset({name: "", phone: "", email: "", order_number: "", question: ""});
        }
    }, [formState, reset]);

    let formSubmit = async (data: any) => {
        //     console.log(
        // 		{
        // "id" : state.customer.id,
        // "sign": state.customer.sign,
        // "model_id" :  model.id,
        // "review" : data.reviewText,
        // "estimate": data.rating,
        // "color": selectedColor.toUpperCase(),
        // "size": selectedSize,
        // "fit_to_size": Number(data.size),
        // "customer_name": state.customer.first_name,
        // "images": reviewImages,
        // 		}
        //     );
        try {
            const res = await addReview({
                id: state.customer.id,
                sign: state.customer.sign,
                model_id: model.id,
                review: data.reviewText,
                estimate: data.rating,
                color: selectedColor.toUpperCase(),
                size: selectedSize,
                fit_to_size: Number(data.size),
                customer_name: state.customer.first_name,
                images: reviewImages,
            });

            setReviewSend(true);
            setError(null);
        } catch (err) {
            console.log(err);
            setError("Не удалось отправить отзыв. Пожалуйста, попробуйте еще раз.");
        }
    };

    return (
        <>
            <div className="modal fade modal-review show" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            {!reviewSend && (
                                <>
                                    <div className="modal-feedback__wrap">
                                        <div className="modal-feedback__title desk">
                                            <h5 className="f-exp">Напишите отзыв</h5>
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={() => {
                                                    setShowReviewPopup(false);
                                                }}
                                            />
                                        </div>
                                        <div className="mob">
                                            <div className="modal-feedback__title">
                                                <button
                                                    type="button"
                                                    className="ico ico-arrow-left-b"
                                                    data-bs-dismiss="modal"
                                                ></button>
                                                <h5 className="f-exp">Новый отзыв</h5>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-bs-dismiss="modal"
                                                ></button>
                                            </div>
                                        </div>
                                        <div className="modal-feedback__product">
                                            <div className="img">
                                                {model?.images &&
                                                    getFrontImages().map((image: any, index: any) => (
                                                        <Link
                                                            to={
                                                                "/catalog/" +
                                                                model.id +
                                                                "/" +
                                                                model.special_transliterated_name +
                                                                "/" +
                                                                image.special_color_name.toLowerCase() +
                                                                "/"
                                                            }
                                                            key={image.url}
                                                            className={
                                                                "image " +
                                                                (image.special_color_name.toLowerCase() ===
                                                                selectedColor
                                                                    ? "active"
                                                                    : "")
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    getBaseImagePath() +
                                                                    image.url.replace(
                                                                        "shopcatalog-resize/big",
                                                                        "shopcatalog-resize/small"
                                                                    )
                                                                }
                                                                alt={getImageAlt() + (index ? " " + (index + 1) : "")}
                                                            />
                                                        </Link>
                                                    ))}
                                            </div>
                                            <div className="info">
                                                <h4>{model?.inetshop_name}</h4>
                                                <ul className="opt">
                                                    {selectedColor && <li>Цвет: {selectedColor.toUpperCase()}</li>}
                                                    {selectedSize && <li>Размер: {selectedSize}</li>}
                                                </ul>
                                                <div className="stars">
                                                    <Controller
                                                        name="rating"
                                                        control={control}
                                                        defaultValue={5}
                                                        rules={{required: true}}
                                                        render={({field: {onChange}}) => (
                                                            <Rating
                                                                onClick={onChange}
                                                                fillColor={"#000000"}
                                                                emptyColor={"#fff"}
                                                                SVGstrokeColor={"#000000"}
                                                                SVGstorkeWidth={1}
                                                                size={21}
                                                            />
                                                        )}
                                                    />
                                                    <h5 className="title">Оцените покупку</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-feedback__form">
                                            <form onSubmit={handleSubmit(formSubmit)}>
                                                <div className="form-group">
                                                    <label className="form-label  f-exp">Расскажите о товаре</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Ваш отзыв"
                                                        id="name"
                                                        {...register("reviewText", {required: false})}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label f-exp">
                                                        Оцените соответствие размера
                                                    </label>
                                                    <div className="form-radios">
                                                        <div className="form-radio">
                                                            <input
                                                                className="hidden"
                                                                id="check-radio-feed-01"
                                                                type="radio"
                                                                value="1"
                                                                {...register("size")}
                                                            />
                                                            <label
                                                                className="label-dot"
                                                                htmlFor="check-radio-feed-01"
                                                            ></label>
                                                            <label className="label-text" htmlFor="check-radio-feed-01">
                                                                Маломерит
                                                            </label>
                                                        </div>
                                                        <div className="form-radio">
                                                            <input
                                                                className="hidden"
                                                                id="check-radio-feed-02"
                                                                type="radio"
                                                                value="2"
                                                                {...register("size")}
                                                            />
                                                            <label
                                                                className="label-dot"
                                                                htmlFor="check-radio-feed-02"
                                                            ></label>
                                                            <label className="label-text" htmlFor="check-radio-feed-02">
                                                                Соответствует размеру
                                                            </label>
                                                        </div>
                                                        <div className="form-radio">
                                                            <input
                                                                className="hidden"
                                                                id="check-radio-feed-03"
                                                                type="radio"
                                                                value="3"
                                                                {...register("size")}
                                                            />
                                                            <label
                                                                className="label-dot"
                                                                htmlFor="check-radio-feed-03"
                                                            ></label>
                                                            <label className="label-text" htmlFor="check-radio-feed-03">
                                                                Большемерит
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label f-exp" htmlFor="">
                                                        Добавьте фотографии
                                                    </label>

                                                    <FileUpload id={1} setBase64={setBase64} />
                                                    <div className="form-loads__text">
                                                        <h5>Нажмите на область с иконкой</h5>
                                                        <h6>до 7 изображений в формате JPG, JPEG, PNG</h6>
                                                    </div>
                                                </div>
                                                <div className="form-btns">
                                                    <button className="btn btn-secondary">Отправить отзыв</button>
                                                </div>
                                                <div className="form-foot">
                                                    <p>
                                                        Перед отправкой отзыва, пожалуйста, ознакомьтесь с{" "}
                                                        <a href="/landing/56/" target="_blank">
                                                            правилами публикации
                                                        </a>
                                                    </p>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-feedback__"></div>
                                    </div>
                                </>
                            )}
                            {reviewSend && (
                                <>
                                    <div className="modal-review__send">
                                        <div className="modal-feedback__title desk">
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={() => {
                                                    setShowReviewPopup(false);
                                                    setReviewSend(false);
                                                }}
                                            />
                                        </div>
                                        <div className="form-text">
                                            <h5 className="f-exp">Ваш отзыв отправлен</h5>
                                            <div className="form-btns">
                                                <button
                                                    className="btn btn-secondary "
                                                    onClick={() => {
                                                        setShowReviewPopup(false);
                                                        setReviewSend(false);
                                                    }}
                                                >
                                                    Ок
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
