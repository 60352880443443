import Spacer from '../Spacer/Spacer.jsx';
import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {getMainImage, getModelColorUrl} from '../../../../utils/Model';
import '../../styles/styles.scss';
import {getBaseImagePath} from "../../../../ui/catalog/Utils";

import {Navigation, Autoplay} from "swiper/modules";
import {useQuery} from "@tanstack/react-query";
import {getModelColorItems} from "../../../../api/catalog";

const Slider = props => {
	const articules = props.params.articules;

	const isOldData = Array.isArray(articules) && typeof articules[0] === 'string';

	const articuleIds = isOldData ? articules : articules.map(item => item.articule);

	const {data: modelColorItemsData, isLoading, isError} = useQuery({
		queryKey: ['modelColorItems', articuleIds],
		queryFn: () => getModelColorItems({
			articules: articuleIds
		}),
		staleTime: 0,
		cacheTime: 0
	});

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const [swiperInstance, setSwiperInstance] = useState(null);

	useEffect(() => {
		if (swiperInstance) {
			swiperInstance.params.navigation.prevEl = navigationPrevRef.current;
			swiperInstance.params.navigation.nextEl = navigationNextRef.current;
			swiperInstance.navigation.init();
			swiperInstance.navigation.update();
		}
	}, [swiperInstance]);

	if (isLoading) {
		return null;
	}

	if (isError || !modelColorItemsData) {
		return null;
	}

	const {model_color_items: modelColorItems} = modelColorItemsData || {model_color_items: []};

	if (isOldData) {
		return (
			<div className='mblock '>
				<div className='carousel-container'>
					<div className='swiper-button' ref={navigationPrevRef}>
						<img
							src='/public/new-design/lookbook2019d3/desktop/arrow_1.png'
							alt=''
						/>
					</div>

					<Swiper
						grabCursor={true}
						centeredSlides={false}
						slidesPerView={1}
						loop={true}
						speed={400}
						spaceBetween={0}
						modules={[Autoplay, Navigation]}
						autoplay={{
							delay: 3000,
						}}
						navigation={{
							prevEl: navigationPrevRef.current,
							nextEl: navigationNextRef.current,
						}}
						breakpoints={{
							640: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							700: {
								slidesPerView: 2,
								spaceBetween: 30,
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 30,
							},
						}}
						onSwiper={setSwiperInstance}
					>
						{modelColorItems.map((colorItem, i) => {
							const image = getMainImage(colorItem);
							return (
								<SwiperSlide key={i}>
									<img src={getBaseImagePath() + image.url} alt='' />
									<div className='model-label slLabel'>
										<a target='_blank' href={getModelColorUrl(colorItem)}>
											КУПИТЬ
										</a>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>

					<div className='swiper-button' ref={navigationNextRef}>
						<img
							src='/public/new-design/lookbook2019d3/desktop/arrow_2.png'
							alt=''
						/>
					</div>
				</div>
				<Spacer />
			</div>
		);
	}

	const uniqueCategoryIds = articules.reduce((acc, current) => {
		const duplicate = acc.find(item => item.articule === current.articule && !item.selectedColor);
		if (!duplicate) {
			acc.push(current);
		} else if (current.selectedColor) {
			acc = acc.map(item =>
				item.articule === current.articule ? current : item
			);
		}
		return acc;
	}, []);

	const orderedSlides = uniqueCategoryIds.flatMap(({articule, selectedColor}) => {
		const slidesForArticule = modelColorItems.filter(item => {
			if (item.articul !== articule) return false;
			if (!selectedColor) return true;
			return item.color_special.toLowerCase() === selectedColor.toLowerCase();
		});

		return slidesForArticule;
	});

	if (orderedSlides.length === 0) {
		return null;
	}

	return (
		<div className='mblock '>
			<div className='carousel-container'>
				<div className='swiper-button' ref={navigationPrevRef}>
					<img
						src='/public/new-design/lookbook2019d3/desktop/arrow_1.png'
						alt=''
					/>
				</div>

				<Swiper
					grabCursor={true}
					centeredSlides={false}
					slidesPerView={1}
					loop={true}
					speed={400}
					spaceBetween={0}
					modules={[Autoplay, Navigation]}
					autoplay={{
						delay: 3000,
					}}
					navigation={{
						prevEl: navigationPrevRef.current,
						nextEl: navigationNextRef.current,
					}}
					breakpoints={{
						640: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						700: {
							slidesPerView: 2,
							spaceBetween: 30,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 30,
						},
					}}
					onSwiper={setSwiperInstance}
				>
					{orderedSlides.map((colorItem, i) => {
						const image = getMainImage(colorItem);
						return (
							<SwiperSlide key={i}>
								<img src={getBaseImagePath() + image.url} alt='' />
								<div className='model-label slLabel'>
									<a target='_blank' href={getModelColorUrl(colorItem)}>
										КУПИТЬ
									</a>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>

				<div className='swiper-button' ref={navigationNextRef}>
					<img
						src='/public/new-design/lookbook2019d3/desktop/arrow_2.png'
						alt=''
					/>
				</div>
			</div>
			<Spacer />
		</div>
	);
};

export default Slider;
