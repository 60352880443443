import {Map, ObjectManager, YMaps} from 'react-yandex-maps';
import {useContext, useEffect, useRef, useState} from "react";
import {GlobalContext} from "../../GlobalReducer";
import {LocationInterface} from "../../interfaces/checkout/LocationInterface";
import GeoLocationInterface from "../../interfaces/main/GeoLocationInterface";

let locationWithCoordinates: LocationInterface[] = [];
let lastMapUid: string | null = null;

function LocationMap({locations, setLocation, geoLocation}: {locations: LocationInterface[], setLocation: (location: LocationInterface) => void, geoLocation: GeoLocationInterface|undefined}) {
    const mapRef = useRef<any>(null);

    /* eslint-disable no-unused-vars */
    const [state, dispatch] = useContext(GlobalContext);

    const [centerOfMap, setCenterOfMap] = useState<number[]>([])
    const [currentZoom, setCurrentZoom] = useState<number>(12)

    const [features, setFeatures] = useState<any>({
        "type": "FeatureCollection",
        "features": []
    })

    useEffect(() => {
        if (!locations.length) return

        locationWithCoordinates = locations.filter((location: LocationInterface) => {
            return location.coordinates && location.coordinates[0] > 0 && location.coordinates[1] > 0;
        })

        if(locationWithCoordinates?.length > 0 && locationWithCoordinates[0].coordinates){
            if(!geoLocation){
                setCenterOfMap(locationWithCoordinates[0].coordinates)
            }else{
                setCenterOfMap([geoLocation.latitude, geoLocation.longitude])
                setCurrentZoom(14)
            }
        }

        const features = locationWithCoordinates.map((location: LocationInterface) => {
            return {
                type: "Feature",
                id: location.uid,
                geometry: {
                    type: "Point",
                    coordinates: location.coordinates
                },
                properties: {
                    hintContent: location.address
                },
                options: {
                    iconColor: '#783D0F'
                }
            }
        })

        setFeatures({
            "type": "FeatureCollection",
            "features": features
        })
    }, [locations])

    return (<>
        {centerOfMap.length === 2 && <YMaps>
            <Map state={{center: centerOfMap, zoom: currentZoom}} width={"100%"} height={"100%"}>
                <ObjectManager
                    options={{
                        clusterize: true,
                    }}
                    objects={{
                        openBalloonOnClick: true,
                        preset: 'islands#fashionIcon',
                    }}
                    features={features}
                    clusters={{
                        preset: 'islands#invertedBrownClusterIcons',
                    }}
                    modules={[
                        'objectManager.addon.objectsHint',
                    ]}
                    instanceRef={ref => mapRef.current = ref}
                    onLoad={() => {
                        if (lastMapUid) {
                            mapRef.current.objects.setObjectOptions(lastMapUid, {
                                iconColor: '#FF0000'
                            })

                        }
                        mapRef.current.objects.events.add('click', (e: any) => {
                            const objectId = e.get('objectId');
                            if (lastMapUid) {
                                mapRef.current.objects.setObjectOptions(lastMapUid, {
                                    iconColor: '#783D0F'
                                })
                            }
                            lastMapUid = objectId
                            mapRef.current.objects.setObjectOptions(objectId, {
                                iconColor: '#FF0000'
                            })
                            if (state.location && objectId === state.location?.uid) return
                            const currentLocation = locationWithCoordinates.find(location => location.uid === objectId)
                            if(currentLocation){
                                setLocation(currentLocation)
                            }
                        })
                    }}
                >

                </ObjectManager>
            </Map>
        </YMaps>}
    </>)
}

export default LocationMap