import {useState, useEffect, useContext} from "react";
import {useLocation} from "react-router-dom";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";

export default function AuthNotification() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const [isVisible, setIsVisible] = useState(false);
    const location = useLocation();
 
    useEffect(() => {
        const notificationShown = localStorage.getItem('authNotificationShown');

        // if (!state.customer?.id && !location.pathname.startsWith("/checkout") && !notificationShown) {
        //     const timer = setTimeout(() => {
        //         setIsVisible(true);
        //         localStorage.setItem('authNotificationShown', 'true');
        //     }, 8000);

        //     return () => clearTimeout(timer);
        // }
    }, [state.customer, location.pathname]);

    if (!isVisible || state.customer?.id || location.pathname.startsWith("/checkout")) {
        return null;
    }

    return (
        <div className="notification notification--right notification--top">
            <div className="notification__head">
                <h5 className="notification__title f-exp">Если авторизоваться, будет интереснее!</h5>
                <i
                    className="notification__close ico ico-close"
                    onClick={() => {
                        setIsVisible(false);
                    }}
                ></i>
            </div>
            <div className="notification__body">
                <p>Зарегистрируйтесь или войдите в свой аккаунт BAON, чтобы получать информацию по актуальным акциям и видеть персональные предложения.</p>
            </div>
            <div className="notification__btns">
                <button
                    className="btn btn-secondary-tr"
                    type={"button"}
                    onClick={() => {
                        dispatch({type: ReducerActionType.SHOW_POPUP, payload: "showAuthPopup"});
                    }}
                >
                    Авторизация
                </button>
                <button
                    className="btn btn-secondary"
                    type={"button"}
                    onClick={() => {
                        dispatch({type: ReducerActionType.SHOW_POPUP, payload: "showRegistrationPopup"});
                    }}
                >
                    Регистрация
                </button>
            </div>
        </div>
    );
}
