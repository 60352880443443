import React, {useEffect, useReducer, useState} from "react";
import {Outlet, ScrollRestoration} from 'react-router-dom';
import TopStringBanner from "./ui/core/TopStringBanner";
import Header from "./ui/core/Header";
import SearchBoard from "./ui/core/SearchBoard";
import MobileMenu from "./ui/core/MobileMenu";
import Footer from "./ui/core/Footer";
import {
    globalReducer,
    GlobalContext,
    initReducerParams,
    showBackdrop, ReducerActionType
} from "./GlobalReducer";
import ErrorBoundary from "./ui/core/ErrorBoundary";
import {DictionaryInitInterface} from "./interfaces/api/DictionaryInitInterface";
import {loadInit} from "./api/dictionary";
import {useQuery} from "@tanstack/react-query";
import PopupCollection from "./ui/core/PopupCollection";
import {
    LOCAL_STORAGE_KEY_BASKET,
    LOCAL_STORAGE_KEY_CUSTOMER,
    LOCAL_STORAGE_KEY_PARAMS
} from "./Config";
import MetaParams from "./MetaParams";
import SeoBlock from "./ui/core/SeoBlock";
import CookieNotification from "./ui/core/CookieNotification";
import AuthNotification from "./ui/core/popup-collection/AuthNotification";
import BasketServerSync from "./BasketServerSync";
import ModalBackdrop from "./ui/core/ModalBackdrop";

export default function App() {

    const [dictionary, setDictionary] = useState<DictionaryInitInterface | null>(null)

    const {status, data, error} = useQuery({
        queryKey: ['dictionary', 'init'],
        queryFn: () => loadInit()
    })

    useEffect(() => {
        if (status === 'success') {
            setDictionary(data)
        }
    }, [status]);

    // @ts-ignore
    const [state, dispatch] = useReducer(globalReducer, [], () => {
        if(localStorage.getItem(LOCAL_STORAGE_KEY_CUSTOMER)){
            if(localStorage.getItem(LOCAL_STORAGE_KEY_CUSTOMER) === 'undefined'){
                initReducerParams.customer = {"id" : null, "sign" : null}
            }else{
                initReducerParams.customer = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_CUSTOMER) || '{"id" : null, "sign" : null}')
            }
        }
        if(localStorage.getItem(LOCAL_STORAGE_KEY_BASKET)){
            if(localStorage.getItem(LOCAL_STORAGE_KEY_BASKET) === 'undefined'){
                initReducerParams.basket = []
            }else{
                initReducerParams.basket = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_BASKET) || '[]')
            }
        }
        if(localStorage.getItem(LOCAL_STORAGE_KEY_PARAMS)){
            if(localStorage.getItem(LOCAL_STORAGE_KEY_PARAMS) === 'undefined'){
                // @ts-ignore
                initReducerParams.region =  { "parent_id": "", "clear_name": "Москва г", "region_type": "г", "sort_index": 1, "country_code": "ru", "id": "tsb-tst-000001", "name": "Москва г", "yml_id": "2000001" };
                initReducerParams.searchHistory =  []
                initReducerParams.showCookieWarn = true
                initReducerParams.favorites =  []
            }else{
                const localParams = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PARAMS) || '{}');
                initReducerParams.region = localParams?.region || { "parent_id": "", "clear_name": "Москва г", "region_type": "г", "sort_index": 1, "country_code": "ru", "id": "tsb-tst-000001", "name": "Москва г", "yml_id": "2000001" };
                initReducerParams.searchHistory = localParams?.searchHistory || []
                initReducerParams.showCookieWarn = localParams?.showCookieWarn
                initReducerParams.favorites =  localParams?.favorites || []
            }

        }
        return initReducerParams
    })

    useEffect( () => {
        localStorage.setItem(LOCAL_STORAGE_KEY_BASKET, JSON.stringify(state.basket))
    }, [state.basket])

    useEffect( () => {
        localStorage.setItem(LOCAL_STORAGE_KEY_CUSTOMER, JSON.stringify(state.customer))
    }, [state.customer])

    useEffect( () => {
        localStorage.setItem(LOCAL_STORAGE_KEY_PARAMS, JSON.stringify({
            showCookieWarn: state.showCookieWarn,
            region: state.region,
            searchHistory: state.searchHistory,
            favorites: state.favorites
        }))
    }, [state.showCookieWarn, state.region, state.searchHistory, state.favorites])

    const isShowBackdrop = () => {
        if(state.showBackdrop) {
            return true
        }

        let isShow = false;
        Object.keys(showBackdrop).map(propertyName => {
            if(state[propertyName] ){
                isShow = true
            }
        })
        return isShow
    }

    return (
        <>
            <ErrorBoundary>
                <GlobalContext.Provider value={[state, dispatch]}>
                    <MetaParams />
                    <BasketServerSync />
                    {dictionary && <TopStringBanner data={dictionary.topBanners}/>}
                    {dictionary && <Header menuItems={dictionary.menu} menuLinkItems={dictionary.menu_links} logo={dictionary.logo}/>}


                    {state.showSearchBoard && <SearchBoard/>}
                    <PopupCollection/>
                    {dictionary && <MobileMenu menuItems={dictionary.menu}/>}

                    <ScrollRestoration />
                    <Outlet/>
                    <SeoBlock />
                    {state.showCookieWarn && <CookieNotification />}
                    {state.authNotification && <AuthNotification />}

                    {dictionary && <Footer
                        partners={dictionary.footer.partners}
                        navigation={dictionary.footer.navigation}
                        socialServices={dictionary.footer.socialServices}
                        paymentTypes={dictionary.footer.paymentTypes}
                        contact={dictionary.contact}
                    />}
                    {isShowBackdrop() && <ModalBackdrop />}
                </GlobalContext.Provider>
            </ErrorBoundary>
        </>
    )
}