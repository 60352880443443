import {useContext, useState} from "react";
import {isEmailValid} from "../checkout/utils/Utils";
import {rememberPassword} from "../../api/customer";
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import LoginControlBlock from "./LoginControlBlock";

function PasswordRecovery({setError, setLoading}: { setError: Function, setLoading: Function }) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [email, setEmail] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    const send = () => {
        setError('')
        if (!email) {
            setError('Не указан email')
            return
        }
        if (!isEmailValid(email)) {
            setError('Не валидный email')
            return
        }
        setLoading(true)
        rememberPassword(email).then(response => {
            if (!response.error) {
                setIsSuccess(true)
            }
        }).catch(error => {
            setError((error.response?.data?.type === 'SendPulseException' ? 'Ошибка отправки сообщения: ' : '') + (error.response?.data?.detail || error.response?.data?.message))
        }).finally(() => {
            setLoading(false)
        })
    }

    return (<>
        <h4 className="modal-form__title f-exp">Восстановление пароля</h4>
        <form>
            {!isSuccess && <>
                <div className="form-text">
                    <p>Укажите почту и мы отправим вам на неё письмо<br/> для восстановления пароля.</p>
                </div>
                <div className="form-group">
                    <label className="form-label">E-mail</label>
                    <input className={"form-control"} placeholder={"E-mail"} value={email} onChange={(e) => {
                        setEmail(e.target.value)
                    }}/></div>
                <div className="form-btns">
                    <button type={'button'} className="btn btn-secondary" onClick={send} disabled={!email}>Отправить</button>
                </div>
                <LoginControlBlock />
            </>}
            {isSuccess && <>
                <div className="form-text">
                    <p>Письмо для восстановления пароля отправлено<br/> на указанный e-mail адрес.</p>
                </div>
                <div className="form-btns">
                    <button type={'button'}  className="btn btn-secondary" onClick={() => {
                        dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
                    }}>Ок</button>
                </div>
            </>}
        </form>
    </>)
}

export default PasswordRecovery