import {useState} from "react";

import PromotionSwitcher from "./PromotionSwitcher";
import {PromotionInterface} from "../../../interfaces/news/PromotionInterface";
import {PromotionTabs} from "./Promotions";

export default function PromotionsList({
    promotions,
    tab,
    setTab,
}: {
    promotions: PromotionInterface[];
    tab: PromotionTabs;
    setTab: (tab: PromotionTabs) => void;
}) {
    const [showPromotion, setShowPromotion] = useState(false);
    const [currentPromotion, setCurrentPromotion] = useState<PromotionInterface | null>(null);

    const ShowPromotion = (promotion: PromotionInterface) => {
        setCurrentPromotion(promotion);
        setShowPromotion(true);
    };

    return (
        <>
            {showPromotion && (
                <>
                    {currentPromotion && (
                        <div className="modal fade modal-stocks show" style={{display: "block"}}>
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="modal-stocks__head">
                                            <img
                                                className="desk"
                                                src={currentPromotion.image}
                                                alt={currentPromotion.image}
                                            />
                                            <div className="info">
                                                <h3 className="f-exp">{currentPromotion.title}</h3> 
                                                <p>{currentPromotion.subtitle}</p> 
                                            </div>
                                            <button
                                                type="button"
                                                className="close"
                                                onClick={() => {
                                                    setShowPromotion(false);
                                                }}
                                            />
                                        </div>
                                    <div
                                    className="modal-stocks__body"
                                    dangerouslySetInnerHTML={{__html: currentPromotion.body || ""}}
                                ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <div className="row">
                <div className="col">
                    <h1 className="stocks__title f-exp">Акции и промокоды</h1>

                    <div className="stocks__tabs">
                        <PromotionSwitcher tab={tab} setTab={setTab} />
                    </div>
                    <div className="tab-content">
                        <div className={"tab-pane show active"}>
                            <div className="stocks__list row">
                                {promotions.map((promotion) => (
                          
                                    <div className="col-lg-4" key={promotion.id}>
                                              
                                        <div className="stocks__block">
                                            <picture>
                                                <source srcSet={promotion.image} media="(max-width: 992px)" />
                                                <img src={promotion.image} alt={promotion.image} />
                                            </picture>
                                           <h3 className="f-exp">{promotion.title}</h3> 
                                            <p>{promotion.subtitle}</p> 
                                            <button
                                                className="btn btn-primary"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    ShowPromotion(promotion);
                                                }}
                                            >
                                                {" "}
                                                Подробнее
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
