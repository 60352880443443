import Breadcrumbs from "../core/Breadcrumbs";
import {useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {changePasswordByMail} from "../../api/customer";
import {useParams} from "react-router-dom";

export default function ChangePasswordByMail() {
    const {control, register, reset, getValues, trigger, formState: {errors, dirtyFields}} = useForm({
        mode: "onChange"
    });
    const params = useParams()


    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        console.log(getValues(), dirtyFields)
    }, [getValues()])

    const send = async () => {

        setError('')
        setMessage('')
        const result = await trigger()

        if (!result) {
            setError('Не корректно заполнены поля формы')
            return
        }

        let fields: any = getValues()

        if (fields.password1 !== fields.password2) {
            setError('Пароли не совпадают')
            return
        }

        fields = {id: params.id, sign: params.sign, password: fields.password1}

        dispatch({type: ReducerActionType.LOAD})
        changePasswordByMail(fields).then(status => {
            setMessage('Пароли успешно обновлены')
        }).catch(error => {
            setError(error.response?.data?.detail || error.response?.data?.message)
        }).finally(() => {
            dispatch({type: ReducerActionType.SUCCESS})
        })
    }

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Восстановление пароля'
            }
        ]
    }

    return (<main >
            <section className={'mt-4'}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs() || []}/>
                            <div className="site-title__wrap">
                                <h1>Восстановление пароля</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div
                                className="tab-pane fade  show active"
                            >
                                {error && <h3 className={'text-danger'}>{error}</h3>}
                                {message && <h3 className={'text-success'}>{message}</h3>}
                                <form>
                                    <div className="form-line">
                                        <div className="form-group form-pass">
                                            <label>Новый пароль</label>
                                            <div className="form-group true">
                                                <input type="text"
                                                       className={'form-control ' + (errors.password1 ? ' is-invalid ' : '') + (!errors.password1 && dirtyFields.password1 ? ' is-valid ' : '')}
                                                       placeholder="введите пароль" {...register("password1", {required: true})}/>

                                                <i className="ico ico-check-circle-2"/>
                                                <i className="ico ico-color ico-eye-grey eye active"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-line">
                                        <div className="form-group form-pass">
                                            <label>Новый пароль еще раз</label>
                                            <div className="form-group ">
                                                <input type="text"
                                                       className={'form-control ' + (errors.password2 ? ' is-invalid ' : '') + (!errors.password2 && dirtyFields.password2 ? ' is-valid ' : '')}
                                                       placeholder="повторите пароль" {...register("password2", {required: true})}/>

                                                <i className="ico ico-failed"/>
                                                <i className="ico ico-color ico-eye-grey eye active"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-btns">
                                            <button type={"button"} className="btn btn-secondary" onClick={() => {
                                                send()
                                            }}>
                                                Сохранить
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}