import React, {useContext, useEffect, useState} from "react";
import BasketItem from "./BasketItem";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {INTERNET_SHOP_ID} from "../../Config";
import useDatalayer, {EcommerceAction} from "../../utils/useDataLayer";

function Basket({positions}:{positions: BasketItemInterface[]}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {action: dataLayerManagerAction} = useDatalayer();

    const [selectedPositions, setSelectedPositions] = useState<string[]>(state.basket.map(basketItem => basketItem.itemId))
    // const [positions, setPositions] = useState<BasketItemInterface[]>(state.basket)

    useEffect(() => {
        const basketPositions = state.basket.map(position => {
            const responseItem = state.positions.find(positionResponse => positionResponse.item.id === position.itemId)

            if(responseItem){
                position.modelColorItem.min_price = responseItem.current_price
                position.modelColorItem.max_price = responseItem.base_price
                position.isSpecialDelivery = responseItem.item.from_retail_store_only || !responseItem.model.is_own_brand
                position.currentStock = responseItem.item.stocks.find(stock => stock.shop_id === INTERNET_SHOP_ID)?.quantity || 0
                if(position.quantity > position.currentStock){
                    position.quantity = position.currentStock
                }
                position.responseItem = responseItem
            }


            return position
        })

        // dispatch({type: ReducerActionType.SET_BASKET, payload: [...basketPositions]})

        // setPositions(basketPositions, index)
    }, [state.basket, positions]);

    const setPosition = (position: BasketItemInterface) => {
        const currentBasketPositions: BasketItemInterface[] = state.basket
        let currentBasketPosition = currentBasketPositions.find(basketPosition => basketPosition.itemId === position.itemId)
        if (currentBasketPosition) {
            currentBasketPosition.quantity = position.quantity
        }
        dispatch({type: ReducerActionType.SET_BASKET, payload: [...currentBasketPositions]})
    }

    const removePosition = (position: BasketItemInterface) => {
        const currentBasketPositions: BasketItemInterface[] = state.basket
        let currentBasketPositionsChanged = currentBasketPositions.filter(basketPosition => basketPosition.itemId !== position.itemId)
        dispatch({type: ReducerActionType.SET_BASKET, payload: [...currentBasketPositionsChanged]})
        dataLayerManagerAction(EcommerceAction.REMOVE_FROM_CART, [position.modelColorItem])
    }

    const removeSelected = () => {
        const currentBasketPositions: BasketItemInterface[] = state.basket
        let currentBasketPositionsChanged = currentBasketPositions.filter(basketPosition => !selectedPositions.includes(basketPosition.itemId || ''))
        dispatch({type: ReducerActionType.SET_BASKET, payload: [...currentBasketPositionsChanged]})

        setSelectedPositions([])
    }


    const isSelected = (position: BasketItemInterface) => {
        return selectedPositions.includes(position.itemId)
    }

    const setSelectedStatus = (position: BasketItemInterface) => {
        if (selectedPositions.includes(position.itemId)) {
            setSelectedPositions([...selectedPositions.filter(inItemId => inItemId !== position.itemId)])
        } else {
            let selectedPositionsDubled = [...selectedPositions]
            selectedPositionsDubled.push(position.itemId)
            setSelectedPositions(selectedPositionsDubled)
        }
    }

    const selectAll = () => {
        setSelectedPositions(positions.map(position => position.itemId))
    }

    return (<div className="basket__products bd">
        <div className="top">
            <div className={"form-checkbox basket-all-checks " + (selectedPositions.length === positions.length && 'active')}>
                <label
                    className="label-dot"
                    htmlFor="check-basket-all-prod"
                    onClick={() => {
                        selectAll()
                    }}
                />
                <label className="label-text">
                    Выбрать все
                </label>
            </div>
            <a className="ico-cont" href="/" onClick={(event) => {
                event.preventDefault()
                removeSelected()
            }}>
                <i className="ico ico-left ico-trash"/>
                Удалить выбранные
            </a>
        </div>
        <div className="basket__products-list">
            {positions.map(position => <BasketItem
                key={position.itemId}
                position={position}
                isSelected={isSelected(position)}
                setSelectedStatus={setSelectedStatus}
                setPosition={setPosition}
                removePosition={removePosition}
            />)
            }
        </div>
    </div>)
}

export default Basket