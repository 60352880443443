import React, {useEffect} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {Form, Button, Container, Row, Col} from 'react-bootstrap';
import {DealerInterface} from "../../interfaces/dealer/DealerInterface";
import {login} from "../../api/dealer";

interface IFormInput {
    username: string;
    password: string;
}

export const KEY = '__currentDealer__'

export default function AuthForm({setDealer}: { setDealer: (dealer: DealerInterface) => void }) {
    const {register, handleSubmit, formState: {errors}} = useForm<IFormInput>();
    const [error, setError] = React.useState<string|undefined>(undefined)

    useEffect(() => {
        const currentDealer = localStorage.getItem(KEY)
        if (currentDealer) {
            setDealer(JSON.parse(currentDealer))
        }
    }, []);

    const onSubmit: SubmitHandler<IFormInput> = data => {
        // Handle form submission logic here

        login(data.username, data.password).then(response => {
            setDealer(response)
            localStorage.setItem(KEY, JSON.stringify(response))
        }, error => {
            setError('Неверный логин или пароль')
        })
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h2>Вход в личный кабинет</h2>
                    <div>Вход в систему для оформления заказов оптовых покупателей</div>
                    <div>Для того, чтобы получить логин и пароль, нужно оформить заявку на сотрудничество</div>
                    <hr/>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Логин</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="введите логин..."
                                {...register('username', {required: true})}
                            />
                            {errors.username &&
                                <span className="text-danger">Это поле обязательно для заполнения</span>}
                        </Form.Group>

                        <Form.Group controlId="formPassword" className={'mt-4'}>
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="введите пароль..."
                                {...register('password', {required: true})}
                            />
                            {errors.password &&
                                <span className="text-danger">Это поле обязательно для заполнения</span>}
                        </Form.Group>

                        <Button variant="secondary" type="submit" className="mt-3">
                            Войти
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}