import {DESKTOP, MOBILE} from '../../config/constants'
import Spacer from '../Spacer/Spacer.jsx'
import SpacerMobile from '../Spacer/Spacer.mobile.jsx'
import style from './Row.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const Row = props => {
	const blockClasses = props.params.clsArray
	const data = props.params

	return (
		<>
			<div
				className={
					blockClasses
						? 'mblock ' +
						blockClasses.join(' ') +
						style.rowBlock +
						' ' +
						(data.reverse ? style.reverse : '')
						: 'mblock ' +
						style.rowBlock +
						' ' +
						(data.reverse ? style.reverse : '')
				}
			>
				<picture>
					<source media="(max-width: 768px)" srcSet={getBaseImagePath() + data.imgmob} />
					<img src={getBaseImagePath() + data.img} alt={data.img} />
				</picture>

				{data.isVideo ? (
					<div>
						<video className='w100'>
							<source src={data.video} autoPlay playsInline loop muted />
						</video>
					</div>
				) : (
					<div className={'text-center ' + style.rowBlockText}>
						<div
							className={style.title}
							dangerouslySetInnerHTML={{__html: data.title}}
						></div>
						<p
							className={style.text}
							dangerouslySetInnerHTML={{__html: data.text}}
						></p>
						<a href={data.url}>
							<button className={style.btn + ' btn btn-secondary'}>Купить</button>
						</a>
					</div>
				)}
			</div>

			<Spacer />
		</>
	)
}

export default Row
