import {useQuery} from "@tanstack/react-query";
import {createStoreReservOrder, getStoreReservData} from "../../api/checkout";
import ModelInterface from "../../interfaces/model/ModelInterface";
import React, {useContext, useEffect, useState} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {ShopInterface} from "../../interfaces/shop/ShopInterface";
import FirstStep from "./store-reserv/FirstStep";
import SecondStep from "./store-reserv/SecondStep";
import {PersonalDataInterface} from "../../interfaces/store-reserv/PersonalDataInterface";
import {OrderDataInterface} from "../../interfaces/store-reserv/OrderDataInterface";
import {OrderInterface} from "../../interfaces/customer/OrderInterface";
import {ModelStockShopInterface} from "../../interfaces/model/ModelStockShopInterface";
import {RegionInterface} from "../../interfaces/core/RegionInterface";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {getModelById, getModelStockShopsById} from "../../api/catalog";


export default function StoreReserv({model, closeFunction}: {model: ModelInterface, closeFunction?: () => void}) {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [selectedColor, setSelectedColor] = useState('')
    const [selectedSize, setSelectedSize] = useState('')
    const [selectedShop, setSelectedShop] = useState<ShopInterface|null>(null)
    const [quantity, setQuantity] = useState(1)
    const [error, setError] = useState('')
    const [order, setOrder] = useState<OrderInterface | null>(null)

    const {data, status} = useQuery({
        queryKey: ['store.stocks', model.id],
        queryFn: () => getModelStockShopsById(model.id),
        retry: 2
    })

    const {
        store_reserv_stocks :  stocks,
        store_reserv_shops: shops,
        store_reserv_restricted_shops: restrictedShops,
        store_reserv_regions : regions,
    } = data || {model: undefined}


    const getCurrentStock = () => {
        return stocks?.find(stock => stock.special_color_name === selectedColor && stock.shop_id === selectedShop?.id && stock.size === selectedSize)
    }

    const createOrder = (personalData: PersonalDataInterface) => {
        const currentStock = getCurrentStock()

        if(!currentStock){
            return
        }

        const orderData: OrderDataInterface = {
            personalData: personalData,
            stock: currentStock,
            quantity: quantity,
            customer: state.customer,
            sabManager: state.sabManager
        }

        createStoreReservOrder(orderData).then(response => {
            if(!response.isError && response.order){
                setOrder(response.order)
                dispatch({type: ReducerActionType.SUCCESS})
            }else{
                setError(response?.error || 'В процессе заказа возникли ошибки')
            }
        }).catch(error => {
            setError(error.response?.data?.message || error.response?.data?.detail)
        })
    }

    return (
        <SimpleBar style={{ maxHeight: 800 }} forceVisible="y" autoHide={false}>
        <div className="scrollbar-inner">
            <h4 className="modal-rezerv__title f-exp">Зарезервировать товар</h4>
            {error  && <h4 className={'text-danger text-center'}>{error}</h4>}

            {!selectedShop && stocks && !order && <FirstStep
                model={model}
                regions={regions as RegionInterface[]}
                restrictedShops={restrictedShops as ShopInterface[]}
                shops={shops as ShopInterface[]}
                stocks={stocks as ModelStockShopInterface[]}
                selectedColor={selectedColor}
                selectedSize={selectedSize}
                setSelectedColor={setSelectedColor}
                setSelectedSize={setSelectedSize}
                setSelectedShop={setSelectedShop}
            />}
            {selectedShop && !order && <SecondStep
                model={model}
                stocks={stocks as ModelStockShopInterface[]}
                selectedColor={selectedColor}
                selectedSize={selectedSize}
                selectedShop={selectedShop}
                quantity={quantity}
                setQuantity={setQuantity}
                createOrder={createOrder}
            />}
            {order && <div className="modal-rezerv__finish">
                <form action="">
                    <div className="form-text">
                        <p>Ваш заказ №{order.id} <br />
                            Товар зарезервирован для Вас в магазине на 48 часов. Подробности заказа направили Вам в
                             письме на указанный адрес электронной почты.
                        </p>
                    </div>
                    <div className="form-btns">
                        <button className="btn btn-secondary" onClick={() => {
                            if(closeFunction) {
                                closeFunction()
                            }
                        }}>
                            Ок
                        </button>
                    </div>
                </form>
            </div>}
        </div>
        </SimpleBar>
    )
}