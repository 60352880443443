import {TableSizeTabs} from "./TableSizes";

interface TSProps {
    currentSize: TableSizeTabs;
    setCurrentSize: (currentSize: TableSizeTabs) => void;
}

export default function TSSwitcher(props: TSProps) {
    return (
        <>
            <ul className="nav nav-tabs f-exp" id="myTabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (props.currentSize === TableSizeTabs.WOMENTOP ? "active" : "")}
                        onClick={() => props.setCurrentSize(TableSizeTabs.WOMENTOP)}
                        type="button"
                        role="tab"
                    >
                        Женская верх
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (props.currentSize === TableSizeTabs.WOMEN ? "active" : "")}
                        onClick={() => props.setCurrentSize(TableSizeTabs.WOMEN)}
                        type="button"
                        role="tab"
                    >
                        Женская низ
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (props.currentSize === TableSizeTabs.MENTOP ? "active" : "")}
                        onClick={() => props.setCurrentSize(TableSizeTabs.MENTOP)}
                        type="button"
                        role="tab"
                    >
                        Мужская верх
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (props.currentSize === TableSizeTabs.MEN ? "active" : "")}
                        onClick={() => props.setCurrentSize(TableSizeTabs.MEN)}
                        type="button"
                        role="tab"
                    >
                        Мужская низ
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (props.currentSize === TableSizeTabs.CHILD ? "active" : "")}
                        onClick={() => props.setCurrentSize(TableSizeTabs.CHILD)}
                        type="button"
                        role="tab"
                    >
                        Детская одежда
                    </button>
                </li>
            </ul>
        </>
    );
}
