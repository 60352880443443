import {useForm, Controller} from "react-hook-form";
import {useContext, useState} from "react";
import isEmail from "validator/es/lib/isEmail";
import Input from "react-phone-number-input/input";
import {register as regApi} from "../../api/customer";
import {vkGoal} from "../checkout/utils/marketing/Service";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {useNavigate} from "react-router-dom";

function Registration({setError, setLoading, setCustomer}: {
    setError: Function,
    setLoading: Function,
    setCustomer: Function
}) {
    const {control, register, getValues, trigger, formState: {errors, dirtyFields}} = useForm({
        mode: "onChange"
    });

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [gender, setGender] = useState('')
    const [subscribe, setSubscribe] = useState(false)

    const [agree, setAgree] = useState(true)

    const navigate = useNavigate();


    const send = async () => {
        setError('')
        const result = await trigger()

        if (!result) {
            setError('Не корректно заполнены поля формы')
            return
        }

        const fields = getValues()

        if (fields.password !== fields.password2) {
            setError('Пароли не совпадают')
            return
        }

        if (!gender) {
            setError('Не указан пол')
        }

        setLoading(true)
        regApi(fields).then(status => {
            setCustomer(status.user)
            if (state.nextAction === 'goToCabinet') {
                navigate('/personal-area/')
            }
            dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: ''})

        }).catch(error => {
            setError(error.response?.data?.detail || error.response?.data?.message)
        }).finally(() => {
            setLoading(false)
            vkGoal('complete_registration')
        })
    }

    return (<>
        <h4 className="modal-form__title f-exp">Регистрация</h4>
        <form action="">
            <div className="form-group">
                <label className="form-label">ФИО</label>
                <input type="text"
                       className={'form-control ' + (errors.first_name ? ' is-invalid ' : '') + (!errors.first_name && dirtyFields.first_name ? ' is-valid ' : '')}
                       placeholder="введите имя" {...register("first_name", {required: true})}/>
                <br/>
                <input type="text"
                       className={'form-control ' + (errors.last_name ? ' is-invalid ' : '') + (!errors.last_name && dirtyFields.last_name ? ' is-valid ' : '')}
                       placeholder="введите фамилию" {...register("last_name", {required: true})}/>
            </div>
            <div className="form-group">
                <label className="form-label">Номер телефона</label>
                <Controller
                    control={control}
                    name="phone"
                    rules={{
                        required: true,
                        validate: v => /^[+\d]{12,20}$/.test(v)
                    }}
                    render={({field: {onChange, name, value}}) => (
                        <Input
                            className={'form-control ' + (errors.phone ? ' is-invalid ' : '') + (!errors.phone && dirtyFields.phone ? ' is-valid ' : '')}
                            placeholder="+7 ___ ___-__-__"
                            name={name}
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </div>
            <div className="form-group">
                <label className="form-label">E-mail</label>
                <input type="text"
                       className={'form-control ' + (errors.email ? ' is-invalid ' : '') + (!errors.email && dirtyFields.email ? ' is-valid ' : '')}
                       placeholder="введите email" {...register("email", {
                    required: true, validate: {
                        checkEmail: v => isEmail(v)
                    }
                })}/>
            </div>
            <div className={"form-group " + (subscribe ? 'active' : '')}>
                <div className="form-checkbox ">
                    <input className="hidden" id="check-enter-reg" type="checkbox" checked={subscribe}/>
                    <label className="label-dot" onClick={() => {
                        setSubscribe(!subscribe)
                    }}></label>
                    <label className="label-text">Подписаться на новостную рассылку</label>
                </div>
            </div>
            <div className="form-group">
                <label className="form-label">Пароль</label>
                <input type="password"
                       className={'form-control ' + (errors.password ? ' is-invalid ' : '') + (!errors.password && dirtyFields.password ? ' is-valid ' : '')}
                       placeholder="введите пароль" {...register("password", {required: true})}/>
            </div>
            <div className="form-group">
                <label className="form-label">Пароль ещё раз</label>
                <input type="password"
                       className={'form-control ' + (errors.password2 ? ' is-invalid ' : '') + (!errors.password2 && dirtyFields.password2 ? ' is-valid ' : '')}
                       placeholder="повторите пароль" {...register("password2", {required: true})}/>
            </div>
            <div className="form-line">
                <label className="form-label">Пол</label>
                <div className="form-radios">
                    <div className={"form-radio " + (gender == 'm' && 'active')}>
                        <label className="label-dot" onClick={() => setGender('m')}></label>
                        <label className="label-text">Мужской</label>
                    </div>
                    <div className={"form-radio " + (gender == 'f' && 'active')}>
                        <label className="label-dot" onClick={() => setGender('f')}></label>
                        <label className="label-text">Женский</label>
                    </div>
                </div>
            </div>
            <div className="form-btns">
                <button type={'button'} className="btn btn-secondary" onClick={send}>Отправить</button>
            </div>
            <div className="form-foot">
                <p>Нажимая кнопку “Отправить”, Вы соглашаетесь с <a href="/landing/26/">Политикой конфиденциальности</a> и <a
                    href="/landing/27/">Политикой обработки cookies</a></p>
            </div>
        </form>
    </>)
}

export default Registration


