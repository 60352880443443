import {Link, useParams} from "react-router-dom";
import Breadcrumbs from "../core/Breadcrumbs";
import {useQuery} from "@tanstack/react-query";
import {getTrasactionStatus} from "../../api/checkout";
import {useContext, useEffect, useState} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";


export default function TransactionStatus() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [lastTransaction, setLastTransaction] = useState<any>(null)

    const queryData = useParams()

    const {data, status} = useQuery({
        queryKey: [...['transaction', 'status'], queryData],
        queryFn: () => getTrasactionStatus(queryData.orderId || '', queryData.secret || ''),
        enabled: !!(queryData.orderId && queryData.secret),
        retry: false
    })

    useEffect(() => {
        switch (status) {
            case 'pending':
                dispatch({type: ReducerActionType.LOAD})
                return;
            case 'error':
                dispatch({type: ReducerActionType.ERROR, payload: 'При обновлении статуса транзакции возникли ошибки'})
                return;
            case 'success':
                dispatch({type: ReducerActionType.SUCCESS})
                if (!data.length) {
                    dispatch({
                        type: ReducerActionType.ERROR,
                        payload: 'При обновлении статуса транзакции возникли ошибки - 2'
                    })
                }
                setLastTransaction(data[data.length - 1])
                return;
        }
    }, [status]);

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Статус транзакции'
            }
        ]
    }

    return (<main className="main-404">
            <section className="page-404 pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs()}/>
                        </div>
                    </div>
                </div>
                <div className="page-404__wrap">
                    {status === 'pending' && <h4 >Подождите, идет обновление статуса транзакции</h4>}
                    {!lastTransaction && status !== 'pending' && <h4 className="f-exp">
                        Ошибка
                        <br/> К сожалению, в процессе обновления транзакции произошла ошибка
                    </h4>}

                    {lastTransaction?.vendor_status == 'pending' && <><h3>Ожидается платеж</h3>
                        <p>Если Вы сделали оплату - попробуйте перегрузить страницу или свяжитесь с нашим
                            менеджером.</p>
                    </>}

                    { (lastTransaction?.vendor_status == 'not actual' || lastTransaction?.vendor_status == 'canceled') && <>
                        <h3>В процесссе обработки транзакции возникла проблема</h3>
                        <p>Свяжитесь с нашим менеджером. <span className={'text-muted'}>Status: {lastTransaction?.vendor_status}, ID: {lastTransaction?.id}</span>
                        </p>

                    </>}

                    {(lastTransaction?.vendor_status == 'waiting_for_capture' || lastTransaction?.vendor_status == 'succeeded') && <><h3>Заказ успешно оплачен!</h3>
                        <p>Ваш заказ {lastTransaction?.order_id} на сумму {lastTransaction?.amount / 100} руб. успешно оплачен!
                            {lastTransaction?.additional_params?.gift_sertificate_id && <><br />Подарочный сертификат сертификат будет отправлен согласно указанным Вами контактным данным.</>}
                        </p>

                    </>}


                    <Link className="btn btn-secondary" to="/">
                        Вернуться на главную
                    </Link>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </div>
            </section>
        </main>
    )
}