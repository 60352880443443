import fetchData from "../utils/HttpClient";
import CatalogFiltersInterface from "../interfaces/api/CatalogFiltersInterface";
import ModelColorItemInterface from "../interfaces/model/ModelColorItemInterface";
import ModelInterface from "../interfaces/model/ModelInterface";
import PageMetaInterface from "../interfaces/core/PageMetaInterface";
import ModelColorItemsResponseInterface from "../interfaces/api/ModelColorItemsResponseInterface";
import ModelItemInterface from "../interfaces/model/ModelItemInterface";
import MoneyInterface from "../interfaces/core/MoneyInterface";
import BreadcrumbInterface from "../interfaces/core/BreadcrumbInterface";
import {ModelStockShopInterface} from "../interfaces/model/ModelStockShopInterface";
import {ShopInterface} from "../interfaces/shop/ShopInterface";
import {RegionInterface} from "../interfaces/core/RegionInterface";
import LandingInterface from "../interfaces/landing/LandingInterface";
import {DeliveryAndPaymentInterface} from "../interfaces/api/DeliveryAndPaymentInterface";

const basePath = '/api/catalog'

export function getModelColorItems(filters: CatalogFiltersInterface, clearCache = false): Promise<ModelColorItemsResponseInterface | undefined> {
    return fetchData(basePath + '/color-items' + (clearCache ? '/true' : ''), filters, 'POST')
}


export function getModelById(modelId: string, clearCache = false): Promise<{
    model: ModelInterface,
    meta: PageMetaInterface,
    model_color_items: ModelColorItemInterface[],
    accrual_sum: MoneyInterface,
    breadcrumbs: BreadcrumbInterface[],
    store_reserv_stocks_quantity: number,
    landings: LandingInterface[],
    capsule_relations: any,
    capsule_model_colors: ModelColorItemInterface[],
    fashion_collection: {color: string, items: ModelColorItemInterface[]}[],
    same_color_items: ModelColorItemInterface[]
}> {
    if(!modelId){
        throw Error('Не указан modelId')
    }

    return fetchData(basePath + '/model/' + modelId  + (clearCache ? '/true' : ''))
}

export function getModelStockShopsById(modelId: string, clearCache = false): Promise<{
    model: ModelInterface,
    store_reserv_stocks: ModelStockShopInterface[],
    store_reserv_shops: ShopInterface[],
    store_reserv_restricted_shops: ShopInterface[],
    store_reserv_regions: RegionInterface[]
}> {
    if(!modelId){
        throw Error('Не указан modelId')
    }

    return fetchData(basePath + '/model-stock-shops/' + modelId  + (clearCache ? '/true' : ''))
}

export function getModelItemByParams(modelId: string, color: string, size: string): Promise<ModelItemInterface> {
    return fetchData(basePath + '/model-item-by-params', {
        model_id: modelId,
        color: color,
        size: size
    }, 'POST')
}

export function getModelBySearchString(searchString: string | undefined, maxModelCount: number | null = null): Promise<ModelColorItemsResponseInterface> {
    return fetchData(basePath + '/search', {
        search_string: searchString,
        max_model_count: maxModelCount
    }, 'POST')
}

export function getModelDeliveryAndPayment(model_id: string, region_id: string, clearCache:boolean = false): Promise<DeliveryAndPaymentInterface[]> {
    return fetchData(basePath + '/model-possible-deliveries-and-payments' + (clearCache ? '/true' : ''), {
        model_id: model_id,
        region_id: region_id
    }, 'POST')
}