import React, { useState } from 'react';
import { getBaseImagePath } from './Utils';

interface MediaItemProps {
    media: {
        url: string;
        type?: string;
    };
    index: number;
    currentImageIndex: number;
    inView?: boolean;
	getImageAlt: () => string;
  firstSliderImage: string;
}

const ModelListCardMedia: React.FC<MediaItemProps> = ({ media, index, currentImageIndex, inView, getImageAlt, firstSliderImage }) =>  {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const handleVideoLoaded = () => {
        setIsVideoLoaded(true);
    };

    const isVideo = media.url.match(/\.(mp4|webm|ogg)$/i);

    return (
              <div
                key={index}
                className="card__img-slider-images"
              >
                {isVideo  && inView ? (
                <>
                    {!isVideoLoaded && (
                        <picture
                            className="card__slide"
                            style={{
                                opacity: currentImageIndex === index ? 1 : 0,
                            }}
                        >
                            <source
                                srcSet={getBaseImagePath() + firstSliderImage}
                                media="(max-width: 992px)"
                            />
                            <img
                                src={getBaseImagePath() + firstSliderImage}
                                alt={getImageAlt() + (index ? ' - ' + (index + 1) : '')}
                            />
                        </picture>
                    )}
                  <video
                    muted
                    loop
                    autoPlay
                    playsInline
                    preload="auto"
                    onLoadedData={handleVideoLoaded}
                    style={{ width: "100%",  
                    opacity: currentImageIndex === index ? 1 : 0,
                    // transition: "opacity 0.5s ease",    
                    }}
                    className="product-kv-slideVideo"
                  >
                    <source src={media.url} type="video/mp4" />
                    Видео не поддерживается браузером
                  </video>
                </>
                ) : (
                  <picture className={"card__slide"} 
                style={{
                  opacity: currentImageIndex === index ? 1 : 0,
                }}
                  >
                    <source
                      srcSet={getBaseImagePath() + media.url}
                      media="(max-width: 992px)"
                    />
                    <img
                      src={getBaseImagePath() + media.url}
                      alt={
                        getImageAlt() +
                        (index ? " - " + (index + 1) : "")
                      }
                    />
                  </picture>
                )}
              </div>
    );
};

export default ModelListCardMedia;
