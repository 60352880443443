import React, {useContext, useEffect, useState, useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";

import {Navigation} from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {auto} from "@popperjs/core";
import ChooseYourLookItemInterface from "../../interfaces/main/ChooseYourLookItemInterface";
import Item from "./choose-your-look/Item";
import ChooseYourLookPointInterface from "../../interfaces/main/ChooseYourLookPointInterface";
import Modal from "./choose-your-look/Modal";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {Link} from "react-router-dom";
import { Swiper as SwiperClass } from 'swiper/types';

export default function ChooseYourLook({items, showIntro = true}: {
    items: ChooseYourLookItemInterface[],
    showIntro?: boolean
}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const swiperRef = useRef<SwiperClass | null>(null);

    const [currentSlide, setCurrentSlide] = useState<number>(0)
    const [localItems, setLocalItems] = useState<ChooseYourLookItemInterface[]>([])

    const [selectedItem, setSelectedItem] = useState<ChooseYourLookItemInterface | null>(null)

    useEffect(() => {
        setLocalItems(items.filter(item => item.on_main && item.is_active).sort((a: ChooseYourLookItemInterface, b: ChooseYourLookItemInterface) => {
            if (a.sort_id && b.sort_id) {
                if (a.sort_id === b.sort_id) return 0
                // @ts-ignore
                return parseInt(a.sort_id) < parseInt(b.sort_id) ? 1 : -1
            }

            return a.id > b.id ? 1 : -1
        }))
    }, [items]);


    useEffect(() => {
        if (swiperRef.current && swiperRef.current.navigation) {
            swiperRef.current.navigation.update(); 
        }
    }, [navigationPrevRef, navigationNextRef]);

    const changeItem = (item: ChooseYourLookItemInterface) => {
        const objIndex = localItems.findIndex(litem => item.id == litem.id);
        localItems[objIndex].points = item.points
        setLocalItems([...localItems])
        setTimeout(() => {
            if(Math.abs(getRecenterDelta()) > 5){
                //@ts-ignore
                swiperRef.current.setTranslate(swiperRef.current.getTranslate() + getRecenterDelta())
            }
        }, 400);
    }

    const getRecenterDelta = () => {
        const windowWidth = $(window).width();
        const bannerWidth = $(".choose__slider .swiper-slide-active").width();
        const bannerOffsetLeft = $(".choose__slider .swiper-slide-active").offset()?.left;

        let delta = 0
        if (windowWidth && bannerWidth && bannerOffsetLeft) {
            delta = (windowWidth - bannerWidth) / 2 - bannerOffsetLeft
        }

        return delta
    }

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showBackdrop', payload: !!selectedItem})
    }, [selectedItem]);

    const isScaleNeeded = () => {
        return localItems.filter(item => item.points.filter(point => point.isActive).length > 0).length > 0
    }

    return (<>
            {selectedItem && <Modal setSelectedItem={setSelectedItem} item={selectedItem}/>}
            <section className={"choose " + (isScaleNeeded() && 'scale')}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="mob">
                                <div className="choose__info">
                                    <h2 className="f-exp">
                                        Подбери
                                        <br className="desk"/> свой образ
                                    </h2>
                                    <p>Выбирай, что нравится только тебе</p>
                                </div>
                            </div>
                            <div className={"choose__slider " + (currentSlide === 0 ? 'first-slide' : '')}>
                                {items.length && <Swiper
                                    navigation={{
                                        prevEl: navigationPrevRef.current,
                                        nextEl: navigationNextRef.current,
                                    }}
                                    onSlideChange={(swiper: any) => {
                                        setCurrentSlide(swiper.realIndex)
                                        setLocalItems([...localItems.map(item => {
                                            item.points.map((point: ChooseYourLookPointInterface) => {
                                                point.isActive = false
                                                return point
                                            })
                                            return item
                                        })])
                                    }}
                                    onBeforeInit={(swiper: any) => {
                                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                                        swiper.params.navigation.nextEl = navigationNextRef.current;
                                    }}
                                    onAfterInit={(swiper: any) => {
                                        if (!showIntro) {
                                            setTimeout(() => {
                                                swiper.slideNext()
                                                swiper.slideNext()
                                            }, 100)
                                        }
                                        swiperRef.current = swiper
                                    }}
                                    onTransitionEnd={(swiper: any) => {
                                        const delta = getRecenterDelta();
                                        if (Math.abs(delta) > 5) {
                                            swiper.setTranslate(swiper.getTranslate() + delta);
                                        }
                                    }}
                                    breakpoints={{
                                        992: {
                                            centeredSlides: true,
                                            spaceBetween: 0,
                                        },
                                    }}
                                    modules={[Navigation]}
                                    slidesPerView={auto}
                                    slideToClickedSlide={true}
                                    centeredSlides={true}
                                    loop={false}
                                    updateOnWindowResize={false}
                                    touchReleaseOnEdges={true}
                                    resistance={true}
                                    resistanceRatio={0.5}
                                    className="choose__slider-main f-exp"
                                >
                                    {localItems.map((item, index) => <SwiperSlide key={index}>
                                        <Item key={item.id} item={item} setSelectedItem={setSelectedItem}
                                              changeItem={changeItem} showIntro={showIntro && !index}/>
                                    </SwiperSlide>)}
                                    <div className="swiper-conrol">
                                        <div ref={navigationPrevRef} className="swiper-btn swiper-btn-prev"></div>
                                        <div ref={navigationNextRef} className="swiper-btn swiper-btn-next"></div>
                                    </div>
                                </Swiper>}
                                {/* {showIntro && <div className="mob">
                                    <Link to={'/choose-your-look/'} className="btn btn-secondary btn-bd" >
                                        <i className="ico ico-color ico-eye-white"></i>Посмотреть все
                                    </Link>
                                </div>} */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
