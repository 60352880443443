import ModelListCard from "../catalog/ModelListCard";
import ModelColorItemInterface from "../../interfaces/model/ModelColorItemInterface";

import React, {useEffect, useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";

import { Navigation, FreeMode } from 'swiper/modules';

export default function HorizontalModelSlider({modelColorItems, header, slidesPerViewDesktop = 5}: {modelColorItems: ModelColorItemInterface[] | undefined, header: string, slidesPerViewDesktop?: number;}) {
    const navigationPrevRef = React.useRef<HTMLButtonElement | null>(null);
    const navigationNextRef = React.useRef<HTMLButtonElement | null>(null);
    const swiperRef = useRef<any | null>(null);

    useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.params.navigation.prevEl = navigationPrevRef.current;
      swiperRef.current.params.navigation.nextEl = navigationNextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, []);

  const sectionClassName = `now-buy ${slidesPerViewDesktop === 4 ? "now-buy--big" : ""}`;

    return (
        <>
            <section className={sectionClassName}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <h2 className="title f-exp">{header}</h2>
                        </div>
                    </div>
                    <div className="now-buy__slider">
                        <button
                            ref={navigationPrevRef}
                            className="slick-prev slick-arrow slick-disabled"
                            aria-label="Previous"
                            type="button"
                            aria-disabled="true"
                        >
                            Previous
                        </button>
                        <button
                            ref={navigationNextRef}
                            className="slick-next slick-arrow"
                            aria-label="Next"
                            type="button"
                            aria-disabled="false"
                        >
                            Next
                        </button>
                        <Swiper
                        onSwiper={(swiper:any) => (swiperRef.current = swiper)}
                        freeMode={{
                            enabled: true,
                            // momentum: false,
                            sticky: false,
                            }}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onBeforeInit={(swiper: any) => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current;
                                swiper.params.navigation.nextEl = navigationNextRef.current;
                            }}
                            breakpoints={{
                                1199: {
                                    spaceBetween: 30,
                                    slidesPerView: slidesPerViewDesktop,
                                    speed: 1000,
                                },
                                992: {
                                    slidesPerView: 5,
                                    speed: 1000,
                                },
                            }}
                            modules={[Navigation, FreeMode]}
                            style={{
                                scrollBehavior: "smooth",
                                scrollSnapType: "none", 
                            }}
                            slidesPerView={2}
                            spaceBetween={15}
                            loop={false}
                        >
                        {modelColorItems &&
                        modelColorItems.map((modelColorItem, index) => (
                        <SwiperSlide key={index}>
                            <ModelListCard modelColorItem={modelColorItem} />
                        </SwiperSlide>
                        ))}
                        {modelColorItems && modelColorItems.length === 1 && (
                            <SwiperSlide />
                        )}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    );
}
