import Breadcrumbs from "../core/Breadcrumbs";
import {Link, useLocation, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getCreditCardPaymentLink} from "../../api/checkout";
import {useContext, useEffect} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function TransactionRedirect() {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const location = useLocation();

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Оплата заказа'
            }
        ]
    }

    const queryData = useParams()

    const {data, status, error} = useQuery({
        queryKey: [...['transaction', 'status'], queryData],
        queryFn: () => getCreditCardPaymentLink(queryData.orderId || '', queryData.secret || ''),
        enabled: !!(queryData.orderId && queryData.secret),
        retry: false
    })

    useEffect(() => {
        switch (status) {
            case 'pending':
                dispatch({type: ReducerActionType.LOAD})
                return;
            case 'error':
                console.log(data, error)
                // @ts-ignore
                dispatch({type: ReducerActionType.ERROR, payload: 'При обновлении статуса транзакции возникли ошибки: ' + (error.response?.data?.message || error.response?.data?.detail)})
                return;
            case 'success':
                dispatch({type: ReducerActionType.SUCCESS})
                window.location.href = data.url
                return;
        }
    }, [status]);

    return (<main className="main-404">
        <section className="page-404 pt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Breadcrumbs breadcrumbs={getBreadcrumbs()}/>
                    </div>
                </div>
            </div>
            <div className="page-404__wrap">

                <h3>Оплата заказа</h3>
                <p>Производится автоматическая переадресация на страницу оплаты.</p>


                <Link className="btn btn-secondary" to="/">
                    Вернуться на главную
                </Link>

                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
        </section>
    </main>)
}