import {Link, useNavigate} from "react-router-dom";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {useContext} from "react";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import MenuInterface from "../../interfaces/header/MenuInterface";

export default function MobileMenu({menuItems} : {menuItems: MenuInterface[]}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const navigate = useNavigate()

    const getBasketCount = () => {
        return state.basket.reduce((sum: number, basketPosition: BasketItemInterface) => {
            return sum + basketPosition.quantity
        }, 0)
    }

    const toggleCurrentMenu = (menu:MenuInterface) => {
        if(!menu){
            dispatch({type:  ReducerActionType.SET_HEADER_MENU, payload: null})
            return
        }

        if(state.headerMenu && state.headerMenu.name == menu.name){
            dispatch({type: ReducerActionType.SET_HEADER_MENU, payload: state.headerMenu ? null : menu})
        } else {
            dispatch({type: ReducerActionType.SET_HEADER_MENU, payload: menu})

        }
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'hideNavbar' , payload: false})
    }

    return (<div className="mobile-menu mob">
        <div className="mobile-menu__wrap">
            <ul>
                <li><Link to={'/'} ><span><i className="ico ico-home-b"></i></span></Link></li>
                <li><a href="/" onClick={(event) => {
                    event.preventDefault()
                    toggleCurrentMenu(menuItems[0])
                }}><span><i className="ico ico-burger-search"></i></span></a></li>
                <li><a href="/" onClick={(event) => {
                    event.preventDefault()
                    dispatch({type:ReducerActionType.TOGGLE_BASKET_POPUP})
                }}><span><i className="ico ico-bag"></i>{state.basket.length > 0 && <span className="nubmer active">{getBasketCount()}</span>}</span></a></li>
                <li><Link to={"/favorites/"}><span><i className="ico ico-heart"></i><span className="nubmer">{state.favorites.length||0}</span></span></Link></li>
                <li><a href="/" onClick={(event) => {
                        event.preventDefault()
                        if (state.customer && state.customer?.id && state.customer?.sign) {
                            navigate('/personal-area/')
                            return
                        }
                        dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'})
                        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: 'goToCabinet'})
                    }}><span><i className="ico ico-profile-b"></i></span></a>

                </li>
            </ul>
        </div>
    </div>)
}