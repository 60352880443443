import Spacer from '../Spacer/Spacer.jsx'
import React from 'react'

import {Swiper, SwiperSlide} from 'swiper/react'
import '../../styles/styles.scss'
import styles from './SliderBannerShort.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


import {Navigation, Autoplay, Pagination} from "swiper/modules";

const SliderBannerShort = props => {
	const slides = props.params.slidesShort

	const [swiper, setSwiper] = React.useState()
	const prevRef = React.useRef(null);
	const nextRef = React.useRef(null);

	return (
		<>
			<div className='carousel-container sliderBanner sliderBanner__sm'>
				<div className='swiper-button sliderBanner_left' ref={prevRef}>
					<img
						src='/public/new-design/design2019/main/arrow-left-big.png'
						alt=''
					/>
				</div>

				<Swiper
					grabCursor={true}
					centeredSlides={false}
					slidesPerView={1}
					loop={true}
					speed={400}
					spaceBetween={0}
					modules={[Autoplay, Navigation, Pagination]}
					pagination={{
						clickable: true,
						el: '.pag',
						type: 'custom',
						renderCustom: function(swiper, current, total) {
							return current + '/' + total
						},
					}}
					autoplay={{
						delay: 5000,
					}}
					navigation={{
						prevEl: prevRef.current,
						nextEl: nextRef.current,
					}}
					breakpoints={{
						768: {
							navigation: {
								enabled: true,
							},
						},
					}}
					onSwiper={setSwiper}
				>
					{slides.map((slide, i) => {
						return (
							<SwiperSlide key={i}>
								<a href={slide.url}>
									<img className={styles.slide} src={getBaseImagePath() + slide.img} alt={slide.img} />
								</a>
							</SwiperSlide>
						)
					})}
					{/* <div className='pag'></div> */}
					{/* убрана пагинация */}
				</Swiper>
				<div className='swiper-button sliderBanner_right' ref={nextRef}>
					<img
						src='/public/new-design/design2019/main/arrow-right-big.png'
						alt=''
					/>
				</div>
			</div>
			<Spacer />
		</>
	)
}

export default SliderBannerShort