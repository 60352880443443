import {getCities} from "../../../api/checkout";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {useQuery} from "@tanstack/react-query";
import {RegionInterface} from "../../../interfaces/core/RegionInterface";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export default function CityFinderModal() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [isLoading, setIsLoading] = useState(false)
    const [searchString, setSearchString] = useState('')

    const [regions, setRegions] = useState<RegionInterface[]>([])

    const getCitiesBySearchString = (searchString: string) => {
        setIsLoading(true)
        return getCities(searchString, state.country?.id).then((cities) => {
            setIsLoading(false)
            setRegions(cities)
        }).catch(error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'При запросе списка городов: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }

    const selectRegion = (region: RegionInterface) => {
        dispatch({
            type: ReducerActionType.SET_PARAM,
            paramName: 'region',
            payload: region
        })
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showRegionFinder', payload: false})
    }

    useEffect(() => {
        // @ts-ignore
        // $('.scrollbar-inner').scrollbar({"scrollx": "advanced", "scrolly": "advanced"})
    }, []);

    const {data: City, status} = useQuery({
        queryKey: [...['citysearch', searchString || '']],
        queryFn: () => getCitiesBySearchString(searchString)
    })


    return (<div
            className="modal fade modal-city show" style={{display: 'block'}}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-city__title">
                            <h5 className="f-exp">Поиск города</h5>
                            <button type="button" className="close" onClick={() => {
                                dispatch({
                                    type: ReducerActionType.SET_PARAM,
                                    paramName: 'showRegionFinder',
                                    payload: false
                                })
                            }}/>
                        </div>
                        <form action="">
                            <div className="form-group">
                                <a className="form-control__search ico ico-search" href="/" style={{zIndex: 1}}
                                   onClick={(event) => {
                                       event.preventDefault()
                                   }}/>

                                {searchString && <a className="form-control__clear" href="/"
                                                    style={{opacity: 1, zIndex: 10000, pointerEvents: "painted"}}
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        setSearchString('')
                                                        setRegions([])
                                                    }}>очистить</a>}


                                <input type="text" className={'form-control'} onKeyUp={(event) => {
                                    setSearchString(event.currentTarget.value)
                                    if (searchString.length < 4) return
                                    getCitiesBySearchString(searchString)
                                }}/>


                            </div>
                        </form>

                        {!searchString && <div className="modal-city__list">
                            <ul>
                                {regions.slice(0, 12).map(region => <li key={region.id}><a href="/"
                                                                                           onClick={(event) => {
                                                                                               event.preventDefault()
                                                                                               selectRegion(region)
                                                                                           }}>{region.clear_name}</a>
                                </li>)}
                            </ul>
                            <ul>
                                {regions.slice(13, 24).map(region => <li key={region.id}><a href="/"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault()
                                                                                                selectRegion(region)
                                                                                            }}>{region.name}</a></li>)}
                            </ul>

                        </div>}

                        {<div className="modal-city__list modal-city__list-full"
                              style={{display: searchString ? 'block' : 'none'}}>
                            <SimpleBar style={{ maxHeight: 500 }}  autoHide={false}>
                            <div className="scrollbar-inner">
                                <ul>
                                    {regions.map(region => <li key={region.id}><a href="/" onClick={(event) => {
                                        event.preventDefault()
                                        selectRegion(region)
                                    }}>{region.name}</a></li>)}
                                </ul>
                            </div>
                            </SimpleBar>
                        </div>}


                    </div>
                </div>
            </div>
        </div>
    )
}