import SimpleTypeInterface from "./SimpleTypeInterface";

export enum ModelGenderType {
    MEN = 'aa-aa-000001',
    WOMEN = 'aa-aa-000002',
    UNISEX = 'aa-aa-000003',
    CHILDREN = 'aa-aa-000004',
}

export default interface ModelGenderInterface extends SimpleTypeInterface
{
    id: ModelGenderType
    name: string
}