import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        console.log('-->>', error, errorInfo)
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return <div className="row">
                <div className="col text-center top-container">
                    <div className="card">
                        <div className="card-body">
                            <p className={"d-none d-lg-block"}>&nbsp;</p>
                            <h3 className={"py-4"}>Что-то пошло не так. </h3>
                            <p>Мы уже занимаемся решением возникшей проблемы. </p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary