import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import ErrorPage from "./ui/core/ErrorPage";

import {navigation} from './Navigation'
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient();


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

let routeMap = [{
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: navigation
}];

const router = createBrowserRouter(routeMap);

root.render(
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router}/>
        </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
