import React, {useContext} from "react";
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";


function AuthBlockButton() {
    // eslint-disable-next-line
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    return (<>
        <div className="basket__form bd">
            <h5 className="basket__form-title f-exp text-center">Для оформления заказа требуется зарегистрироваться или
                авторизоваться</h5>
            <div className="row">
                <div className="col">
                    <button className="btn btn-secondary btn-block" onClick={() => {
                        dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.LOGIN})
                    }}>Авторизоваться
                    </button>
                </div>
                <div className="col">
                    <button className="btn btn-outline-secondary btn-block"
                            onClick={() => {
                                dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.REGISTRATION})
                            }}>Зарегистрироваться
                    </button>
                </div>
            </div>
        </div>


    </>)
}

export default AuthBlockButton