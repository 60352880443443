import {Controller, useForm} from "react-hook-form";
import {update as regApi} from "../../../../api/customer";
import {useContext, useEffect, useState} from "react";
import Input from "react-phone-number-input/input";
import isEmail from "validator/es/lib/isEmail";
import {ReducerStateInterface} from "../../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../../GlobalReducer";
import moment from "moment";

export default function Registration() {
    const {control, register, reset, getValues, trigger, formState: {errors, dirtyFields}} = useForm({
        mode: "onChange"
    });

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [gender, setGender] = useState('')

    useEffect(() => {
        reset({
            first_name: state.customer.first_name,
            last_name: state.customer.last_name,
            phone: '+7' + state.customer.phone,
            email: state.customer.email,
            birthday: moment(state.customer.birthday).format('YYYY-MM-DD')
        })

        setGender(state.customer.gender)

    }, []);

    useEffect(() => {
        console.log(getValues(), dirtyFields)
    }, [getValues(), gender])

    const send = async () => {

        setError('')
        setMessage('')
        const result = await trigger()

        if (!result) {
            setError('Не корректно заполнены поля формы')
            return
        }

        let fields:any = getValues()

        fields = {...fields, ...{gender: gender, id: state.customer.id, sign: state.customer.sign}}

        if (!gender) {
            setError('Не указан пол')
            return
        }
        //
        dispatch({type: ReducerActionType.LOAD})
        regApi(fields).then(status => {
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'customer', payload: status.user})
            dispatch({type: ReducerActionType.SUCCESS})
            setMessage('Данные обновлены')
        }).catch(error => {
            setError(error.response?.data?.detail || error.response?.data?.message)
        }).finally(() => {
            dispatch({type: ReducerActionType.SUCCESS})
        })
    }

    return (<div
        className="tab-pane fade show active"
        id="profile-data-sect-1"
        role="tabpanel"
        aria-labelledby="profile-data-tab-1"
    >
        <form action="">
            {error && <h3 className={'text-center text-danger'}>{error}</h3>}
            {message && <h3 className={'text-center text-success'}>{message}</h3>}
            <div className="form-line">
                <label>Телефон</label>
                <div className="form-group true">
                    <Controller
                        control={control}
                        name="phone"
                        rules={{
                            required: true,
                            validate: v => /^[+\d]{12,20}$/.test(v)
                        }}
                        render={({field: {onChange, name, value}}) => (
                            <Input
                                className={'form-control ' + (errors.phone ? ' is-invalid ' : '') + (!errors.phone && dirtyFields.phone ? ' is-valid ' : '')}
                                placeholder="+7 ___ ___-__-__"
                                name={name}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <i className="ico ico-check-circle-2"/>
                </div>
                {/*<div className="form-link ico-cont desk">*/}
                {/*    <a href="">Изменить</a>*/}
                {/*    <div className="hint">*/}
                {/*        <i className="ico ico-right ico-question"/>*/}
                {/*        <div className="hint__text" style={{display: "none"}}>*/}
                {/*            <p>*/}
                {/*                Lorem ipsum dolor sit amet consectetur, adipisicing*/}
                {/*                elit. Aut officiis ullam nisi consectetur unde, quod*/}
                {/*                omnis explicabo quasi et id quia cumque adipisci*/}
                {/*                inventore ex ipsam saepe error asperiores*/}
                {/*                necessitatibus.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="form-line">
                <label>E-mail</label>
                <div className="form-group ">
                    <input type="text"
                           className={'form-control ' + (errors.email ? ' is-invalid ' : '') + (!errors.email && dirtyFields.email ? ' is-valid ' : '')}
                           placeholder="введите email" {...register("email", {
                        required: true, validate: {
                            checkEmail: v => isEmail(v)
                        }
                    })}/>
                    {/*<p className="error-text">Неверный формат email</p>*/}
                    {/*<i className="ico ico-failed"/>*/}
                </div>
                {/*<div className="form-annotation">*/}
                {/*    <p>*/}
                {/*        Укажите e-mail, чтобы получать информацию по заказу и*/}
                {/*        актуальным акциям. А мы начислим Вам 100 бонусов 🙂*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
            <div className="form-line">
                <label>Имя</label>
                <div className="form-group">
                    <input type="text"
                           className={'form-control ' + (errors.first_name ? ' is-invalid ' : '') + (!errors.first_name && dirtyFields.first_name ? ' is-valid ' : '')}
                           placeholder="введите имя" {...register("first_name", {required: true})}/>

                    <i className="ico ico-color ico-eye-grey eye"/>
                </div>
            </div>
            <div className="form-line">
                <label>Фамилия</label>
                <div className="form-group">
                    <input type="text"
                           className={'form-control ' + (errors.last_name ? ' is-invalid ' : '') + (!errors.last_name && dirtyFields.last_name ? ' is-valid ' : '')}
                           placeholder="введите фамилию" {...register("last_name", {required: true})}/>
                </div>
            </div>
            <div className="form-line">
                <label>Дата рождения</label>
                <div className="form-group">
                    <input type="date"
                           className={'form-control ' + (errors.birthday ? ' is-invalid ' : '') + (!errors.birthday && dirtyFields.birthday ? ' is-valid ' : '')}
                           placeholder="введите дату рождения" {...register("birthday", {required: true})}/>
                </div>
                {/*<div className="form-annotation">*/}
                {/*    <p>*/}
                {/*        Никому не расскажем 😉 Но подготовим, что–то интересное*/}
                {/*        на Ваш День рождения*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
            <div className="form-line">
                <label>Пол</label>
                <div className="form-group">
                    <div className="form-radios">
                        <div className="form-radio">
                            <div className={"form-radio " + (gender == 'm' && 'active')}>
                                <label className="label-dot" onClick={() => setGender('m')}></label>
                                <label className="label-text">Мужской</label>
                            </div>
                            <div className={"form-radio " + (gender == 'f' && 'active')}>
                                <label className="label-dot" onClick={() => setGender('f')}></label>
                                <label className="label-text">Женский</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="form-annotation">*/}
                {/*    <p>*/}
                {/*        Укажите Ваш пол, чтобы мы отправляли интересные для вас*/}
                {/*        акции 💃🕺 . А с нас ещё 100 бонусов 🙂*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
            <div className="form-line">
                <label/>
                <div className="form-group">
                    <div className="form-btns form-btns--full">
                        <button type={"button"} className="btn btn-secondary" onClick={() => {
                            send()
                        }}>
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
            <div className={'text-muted '}>ID: {state.customer.id}</div>
        </form>
    </div>)
}