import ModalProduct from "./ModalProduct";
import ChooseYourLookItemInterface from "../../../interfaces/main/ChooseYourLookItemInterface";
import {getBaseImagePath} from "../../catalog/Utils";
import {isMobile} from "react-device-detect";

export default function Modal({item, setSelectedItem}: {
    item: ChooseYourLookItemInterface,
    setSelectedItem: (item: ChooseYourLookItemInterface | null) => void
}) {
    return (<div className="modal fade modal-main modal-fashion show" style={{zIndex: 60}}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <button type="button" className="close desk" data-bs-dismiss="modal" onClick={() => {
                    setSelectedItem(null)
                }}></button>
                <div className="mob">
                    <div className="modal-feedback__title">
                        <button type="button" className="ico ico-arrow-left-b"  onClick={() => {
                            setSelectedItem(null)
                        }}></button>
                        <h5 className="f-exp">Весь образ</h5>
                        <button type="button" className="close" onClick={() => {
                            setSelectedItem(null)
                        }}></button>
                        </div>
                    </div>
                <div className="modal-fashion__imgs">
                    <div className="img"><img src={getBaseImagePath() + item.image} alt=""/></div>
                    {/* {!isMobile && <div className="img"><img src={getBaseImagePath() + item.image} alt=""/></div>} */}
                </div>
                <div className="modal-body">
                    <h5 className="modal__title f-exp desk">Весь образ</h5>
                    <div className="modal-basket">
                        <div className="modal-basket__body">
                            <div className="scrollbar-inner">
                                {item.points.map(point => <ModalProduct key={point.uid} point={point}/>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}


