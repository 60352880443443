import {Button, Col, Form, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {checkBalance} from "../../../api/gift-certificate";
import {useContext, useState} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function BalanceForm() {
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);


    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {register, reset, handleSubmit, watch, setValue, getValues, formState: {errors}} = useForm();

    const formSubmit = (formParams: any) => {
        setIsLoading(true)
        dispatch({type: ReducerActionType.LOAD})
        setError(null);
        setMessage(null);
        checkBalance(formParams).then((response) => {
            if(!response.certificate?.active_nominal){
                setError('Сертификат имеет нулевой баланс');
                return;
            }

            setMessage('Баланс сертификата: ' + (response.certificate?.active_nominal.amount / 100) + ' руб.');
            dispatch({type: ReducerActionType.SUCCESS})
            setIsLoading(false)
        }, (error: any) => {
            setError(error.response?.data?.message || error.response?.data?.detail);
            dispatch({type: ReducerActionType.SUCCESS})
            setIsLoading(false)
        })
    }

    return (<Form onSubmit={handleSubmit(formSubmit)}>
        {error && <h4 className={'text-danger text-center'}>{error}</h4>}
        {message && <h4 className={'text-center'}>{message}</h4>}
        <Form.Group as={Row} className="mt-3">
            <Form.Label column sm="3">Номер сертификата</Form.Label>
            <Col sm="9"><Form.Control type="text" placeholder="Номер сертификата"  className={(errors.number ? ' is-invalid' : '')} {...register("number", { required: true, validate: v => /^[+\d]{6,8}$/.test(v) })} /></Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-3">
            <Form.Label column sm="3">Проверочный код</Form.Label>
            <Col sm="9"><Form.Control type="text" placeholder="Проверочный код"  className={(errors.code ? ' is-invalid' : '')} {...register("code", { required: true, validate: v => /^[+\d]{4}$/.test(v) })} /></Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-3">
            <Form.Label column sm="3">&nbsp;</Form.Label>
            <Col sm="9">
                <Button variant="secondary" disabled={isLoading} type={"submit"}>Проверить баланс</Button>
            </Col>
        </Form.Group>

    </Form>)
}