import React, {useState, useEffect} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import {EcommerceAction} from "../../utils/useDataLayer";
import useDatalayer from "../../utils/useDataLayer";

interface SizeSelectionPopupProps {
    sizes: string[];
    isSizeAvailable: (size: string) => boolean;
    onClose: () => void;
    onSelectSize: (size: string) => void;
    selectedColorItem: any; 
    dispatch: Function;
}

const SizeSelectionPopup: React.FC<SizeSelectionPopupProps> = ({
    sizes,
    isSizeAvailable,
    onClose,
    onSelectSize,
    selectedColorItem,
    dispatch,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const {action: dataLayerManagerAction} = useDatalayer();

    useEffect(() => {
        const timeout = setTimeout(() => setIsVisible(true), 10);
        return () => clearTimeout(timeout);
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(onClose, 300);
    };

    const handleSizeSelect = (size: string) => {
        // console.log("size", size);
        // console.log("selectedColorItem:", selectedColorItem);

        if (!isSizeAvailable(size)) return;

        if (!selectedColorItem || !selectedColorItem.color_special) {
            console.log("error selectedColorItem:", selectedColorItem);
            return;
        }

        onSelectSize(size);

        const itemId = selectedColorItem.items?.find(
            (item: any) =>
                item.color_special?.toLowerCase() === selectedColorItem.color_special?.toLowerCase() &&
                item.size === size
        )?.id;

        if (!itemId) {
              console.log("error size:", size);
            return;
        }

        const basketItem: BasketItemInterface = {
            modelColorItem: selectedColorItem,
            itemId: String(itemId),
            color: selectedColorItem.color_special.toLowerCase(),
            size: size,
            quantity: 1,
            createdAt: new Date(),
        };

        // console.log("basketItem SizeSelectionPopup:", basketItem);

        onSelectSize(size);
        dispatch({type: ReducerActionType.ADD_TO_BASKET, payload: basketItem});
        dataLayerManagerAction(EcommerceAction.ADD_TO_CART, [selectedColorItem]);
        handleClose();
    };

    return (
        <div
            className={`modal fade modal-system modal-main-system ${isVisible ? "show" : ""}`}
            style={{
                display: isVisible ? "block" : "none",
                opacity: isVisible ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
            }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-system__head">
                            <h5 className="modal-system__title f-exp">Не указан размер</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-system__text">Для заказа позиции укажите, пожалуйста, размер</div>
                        <div className="modal-system__extra-section">
                            <div className="product__kv-info-sizes inv">
                                <ul>
                                    {sizes.map((size) => (
                                        <li key={size}>
                                            <a
                                                className={
                                                    (size.length > 4 ? " label-long" : "") +
                                                    (!isSizeAvailable(size) ? " disabled " : "")
                                                }
                                                href="/"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    handleSizeSelect(size);
                                                }}
                                            >
                                                {size}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="form-btns btns-end">
                            <button className="btn btn-secondary" onClick={handleClose}>
                                Понятно
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SizeSelectionPopup;
