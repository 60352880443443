import fetchData from "../utils/HttpClient";
import {CustomerCredentialResponse} from "../interfaces/api/CustomerCredentialResponseInterface";
import {CustomerInterface} from "../interfaces/customer/CustomerInterface";
import {BasketItemInterface} from "../interfaces/core/BasketItemInterface";
import AddReviewInterface from "../interfaces/api/AddReviewInterface";
import {ReviewInterface} from "../interfaces/customer/ReviewInterface";
import Md5 from "../utils/Md5";
import moment from "moment";
import {LOCAL_STORAGE_KEY_PARAMS} from "../Config";
import {QuestionInterface} from "../interfaces/customer/QuestionInterface";

const basePathCustomer = '/api/customer';

export async function login(email: string, password: string) {
    return fetchData(basePathCustomer + '/login', {
        username: email,
        password: password,
        uid: Md5(email + moment().format('YYYY-MM-DD') + LOCAL_STORAGE_KEY_PARAMS)
    }, 'POST')
}

export async function loginByPhone(phone: string) {
    return fetchData(basePathCustomer + '/login-by-phone-code', {
        phone: phone
    }, 'POST')
}

export async function checkPhoneCode(id: number, code: string) {
    return fetchData(basePathCustomer + '/check-phone-code', {
        id: id,
        code: code
    }, 'POST')
}

export async function rememberPassword(email: string) {
    return fetchData(basePathCustomer + '/remember-password', {email: email}, 'POST')
}

export async function register(fields: any): Promise<{ user: CustomerInterface }> {
    return fetchData(basePathCustomer + '/register', fields, 'POST')
}

export async function update(fields: any): Promise<{ user: CustomerInterface }> {
    return fetchData(basePathCustomer + '/update', fields, 'POST')
}

export async function changePassword(fields: { id: string, sign: string, password: string }): Promise<{
    user: CustomerInterface
}> {
    return fetchData(basePathCustomer + '/change-password', fields, 'POST')
}

export async function changePasswordByMail(fields: { id: string, sign: string, password: string }): Promise<{
    user: CustomerInterface
}> {
    return fetchData(basePathCustomer + '/change-password-by-mail', fields, 'POST')
}

export async function credentials(id: number, sign: string): Promise<CustomerCredentialResponse> {
    return fetchData(basePathCustomer + '/credentials', {id: id, sign: sign}, 'POST')
}

export async function logout(): Promise<CustomerCredentialResponse> {
    return fetchData(basePathCustomer + '/logout', {}, 'POST')
}

export async function unsubscribeByHex(uid: string) {
    return fetchData(basePathCustomer + '/email-unsubscribe-by-hex?direct-crm-ticket=' + uid)
}

export async function mergeBasket(id: number, sign: string, basket: BasketItemInterface[], timestamp: number,  isForce = false): Promise<{basket: BasketItemInterface[], timestamp: number}> {
    let basketCopy = basket.map(item => {
        return {...item, ...{createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}}
    })

    return fetchData(basePathCustomer + '/merge-basket' ,  {
        id: id,
        sign: sign,
        basket: basketCopy,
        is_force: isForce,
        timestamp: timestamp
    },'POST')
}


export async function addReview(data:AddReviewInterface): Promise<ReviewInterface>  {
    return fetchData(basePathCustomer + '/add-review', data , 'POST')
}

export async function addQuestion(data:QuestionInterface)  {
    return fetchData(basePathCustomer + '/add-question', data , 'POST')
}