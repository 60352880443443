import Money from "../core/Money";
import {useState, useEffect, useContext} from "react";
import {positonQuantity, totalBaseHelper, accuralSumHelper} from "./useTotal";
import {PAYMENT_TYPE_PODELI_ID} from "../../Config";
import {GlobalContext} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import MoneyInterface from "../../interfaces/core/MoneyInterface";
import {BasketPartConfigInterface} from "../../interfaces/checkout/BasketPartConfigInterface";
import AgreementLabel from "./AgreementLabel";

declare var CartWidget: any

function Total({createOrder, basketPartConfigs, total, deliveryCost, payedByGiftCertificates }: {
    createOrder: () => void,
    basketPartConfigs: BasketPartConfigInterface[],
    total: MoneyInterface,
    deliveryCost: MoneyInterface,
    payedByGiftCertificates: MoneyInterface
}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext)
    const currency = state.basket[0]?.modelColorItem.min_price.currency || {'name' : 'RUB'}

    const [isPodeliScriptLoaded, setIsPodeliScriptLoaded] = useState(false)

    const [agree, setAgree] = useState(true);

    const hasPodeli = () => {
        let hasPodeli = false
        basketPartConfigs.map(basketPartConfig => {
            if (basketPartConfig.payment?.id === PAYMENT_TYPE_PODELI_ID) {
                hasPodeli = true
            }
        })
        return hasPodeli
    }

    useEffect(() => {
        basketPartConfigs.map(basketPartConfig => {
            if (basketPartConfig.payment?.id !== PAYMENT_TYPE_PODELI_ID ) {
                return;
            }

            if(isPodeliScriptLoaded && CartWidget){
                CartWidget.init({
                    total: total.amount / 100,
                    selectorToInject: "#podeli_widget",
                    notShowPenny: false,
                    greyLogo: true,
                    minLimit: 2000,
                    maxLimit: 30000,
                    mainColor: '#FF0000'
                });
            } else{
                const timeoutId = window.setTimeout(() => {

                    var podeliScript = document.createElement('script');
                    podeliScript.src = 'https://cdn.podeli.ru/New-Widgets/ReactWidget/widget-podeliv2.js';
                    podeliScript.defer = true;

                    document.body.appendChild(podeliScript);

                    podeliScript.onload = function() {
                        CartWidget.init({
                            total: total.amount / 100,
                            selectorToInject: "#podeli_widget",
                            notShowPenny: false,
                            greyLogo: true,
                            minLimit: 2000,
                            maxLimit: 30000,
                            mainColor: '#FF0000'
                        });
                    };
                }, 500)


                return () => {
                    window.clearTimeout(timeoutId)
                }
            }
        })


    }, [basketPartConfigs, total.amount])

    const isDisabled = () => {
        let isDisabled = false
        basketPartConfigs.map(basketPartConfig => {
            if(
                !basketPartConfig.payment ||
                !basketPartConfig.delivery ||
                (basketPartConfig.delivery?.address_block_required && (!basketPartConfig.address?.street || !basketPartConfig.address?.home) ) ||
                (basketPartConfig.delivery?.locations?.length > 0 && !basketPartConfig.location )
            ){
                isDisabled = true
            }
        })

        return isDisabled || !agree || state.isLoading
    }

    const discount = () => {
        return {
            amount: totalBaseHelper(state.basket, deliveryCost, currency).amount - total.amount - payedByGiftCertificates.amount,
            currency: currency
        }
    }

    const payedByBonus = () => {
        return {
            amount: state.writeOffBonuses * 100,
            currency: currency
        }
    }


    const getPostfix = (num: number) => {
        if ((num > 1 && num < 5) || (num > 21 && num < 25) || (num > 31 && num < 35)) {
            return 'а'
        } else if ((num >= 5 && num <= 20) || (num > 25 && num < 31)) {
            return 'ов'
        }
        return ''
    }

    return (<div className="basket__order">
            <div className="basket__order-all f-exp">
                <ul>
                    <li>
                        <p>Всего: {positonQuantity(state.positions)} товар{getPostfix(positonQuantity(state.positions))}</p>
                        <p><Money value={totalBaseHelper(state.basket, deliveryCost, currency)}/></p>
                    </li>
                </ul>
            </div>
            <div className="basket__order-discount">
                <ul>
                    <li>
                        <h6>Скидка</h6>
                        <h6 className="f-exp">
                            <span>- <Money value={discount()}/></span>
                        </h6>
                    </li>
                    {payedByBonus().amount > 0 && <li>
                        <h6>Оплачено бонусами</h6>
                        <h6 className="f-exp">
                            <span>- <Money value={payedByBonus()}/></span>
                        </h6>
                    </li>}
                    {payedByGiftCertificates.amount > 0 && <li>
                        <h6>Оплачено подарочными сертификатми</h6>
                        <h6 className="f-exp">
                            <span>- <Money value={payedByGiftCertificates}/></span>
                        </h6>
                    </li>}
                </ul>
                {/*<div className="container-wrap">*/}
                {/*    <a className="container-btn" href="">*/}
                {/*        Показать примененные скидки*/}
                {/*    </a>*/}
                {/*    <div className="container-col">*/}
                {/*        <ul>*/}
                {/*            <li>*/}
                {/*                <p>Промокод «Хочу промокод»</p>*/}
                {/*                <p>*/}
                {/*                    <span>– 1 500 Р</span>*/}
                {/*                </p>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {deliveryCost.amount > 0 && <div className="basket__order-delivery">
                <ul>
                    <li>
                        <p>Доставка</p>
                        <p><Money value={deliveryCost}/></p>
                    </li>
                </ul>
            </div>}
            <div className="basket__order-total">
                <ul>
                    <li className="f-exp">
                        <h6>Итого</h6>
                        <h6><Money value={total}/></h6>
                    </li>
                    <li>
                        <p>Начислим бонусы</p>
                        <p className="f-exp">+{accuralSumHelper(state.positions, currency).amount / 100}</p>
                    </li>
                </ul>
            </div>
            <div className="mb-3">
                <div id="podeli_widget" style={{
                    width: "auto",
                    display: hasPodeli() ? "block" : "none"
                }}></div>
            </div>
            <div className="form-btns form-btns--full">
                <button className="btn btn-secondary" onClick={() => {
                    createOrder()
                }} disabled={isDisabled()}>
                    Оформить заказ
                </button>
            </div>
            <div className="basket__order-annotation">
                <p>
                    <AgreementLabel />
                </p>
            </div>
        </div>
    )
}

export default Total