import {useState} from "react";
import {useSearchParams} from "react-router-dom";

export enum SortType {
    PRICE_ASC = 'price_asc',
    PRICE_DESC = 'price_desc',
    NEW = 'new',
    RAITING = 'raiting',
    DISCOUNT = 'discount'
}


export default function Sort({sortBy, setSortBy}: { sortBy: string | null, setSortBy: Function }) {
    const [isOpen, setIsOpen] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams();


    const sortTypeList = [
        {
            name: 'По убыванию цены',
            sortBy: SortType.PRICE_ASC
        },
        {
            name: 'По возрастанию цены',
            sortBy: SortType.PRICE_DESC
        },
        {
            name: 'По новинкам',
            sortBy: SortType.NEW
        },
        {
            name: 'По рейтингу',
            sortBy: SortType.RAITING
        },
        {
            name: 'По скидке',
            sortBy: SortType.DISCOUNT
        },
    ];

    const resetPage = () => {
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    }

    const selectedSortBy = () => {
        return sortTypeList.find(sortByItem => sortByItem.sortBy === (sortBy || SortType.RAITING)) || null
    }

    return (<div className="filters__list-last">
        <a className="filters__list-link filters__list-link--sort f-exp" href="/" onClick={(event) => {
            event.preventDefault()
            setIsOpen(!isOpen)
        }}><i className="ico ico-sort ico-left"></i>
            <span className="desk text">{selectedSortBy()?.name}</span></a>
        {isOpen && <div className="filters__collapse filter--sort">
            <div className="filters__collapse-wrap">
                <ul className="filters__collapse-checkboxes">
                    <li>
                        {sortTypeList.map(sortType => <div key={sortType.sortBy}
                                                           className={"form-radio " + (sortType.sortBy === sortBy ? " active" : "")}>
                            <input className="hidden" type="radio"/>
                            <label className="label-dot" onClick={() => {
                                setSortBy(sortType.sortBy);
                                setIsOpen(false)
                                resetPage()
                            }}></label>
                            <label className="label-text" onClick={() => {
                                setSortBy(sortType.sortBy);
                                setIsOpen(false)
                                resetPage()
                            }}>{sortType.name}</label>
                        </div>)}
                    </li>
                </ul>
            </div>
        </div>}
    </div>)
}