import ModelInterface from "../interfaces/model/ModelInterface";
import ModelColorItemInterface from "../interfaces/model/ModelColorItemInterface";

export const getMainImage = (model: ModelInterface | ModelColorItemInterface) => {
    if (!model.images.length) {
        return null;
    }
    const mainColorName = 'extra_param' in model && model.extra_param?.main_color_name ? model.extra_param.main_color_name : null;

    let mainImage = null;

    if (mainColorName) {
        mainImage = model.images.find(image => image.type === 'front' && image.special_color_name === mainColorName);
    }
    if (!mainImage) {
        mainImage = model.images.find(image => image.type === 'front');
    }
    if (!mainImage) {
        mainImage = model.images[0];
    }

    return mainImage;
};

export const getModelColorUrl = (model: ModelColorItemInterface) => {
    const mainImage = getMainImage(model);
    console.log('info', model);
    return '/catalog/' + model.model_id + '/' + model.special_transliterated_name.toLowerCase() + '/' + (mainImage ? mainImage.special_color_name.toLowerCase() : '');
};
