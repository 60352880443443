import {useContext, useEffect} from "react";
import {ecommerceDataLayer} from "./utils/marketing/Service";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {PaymentInterface} from "../../interfaces/checkout/PaymentInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {BasketPartConfigInterface} from "../../interfaces/checkout/BasketPartConfigInterface";

const PAYMENT_TYPE_CREDIT_ID = 'tsb-tso-000010'

function PaymentList({basketPartConfig, setBasketPartConfig}: {basketPartConfig: BasketPartConfigInterface, setBasketPartConfig: (basketPartConfig: BasketPartConfigInterface) => void}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const getAvailablePayments = (): PaymentInterface[] => {
        if (!basketPartConfig.location && !basketPartConfig.delivery) return []
        // @ts-ignore
        return (basketPartConfig.location || basketPartConfig.delivery)?.payments;
    }

    useEffect(() => {
        if(!(basketPartConfig.location || basketPartConfig.delivery)?.payments.find(payment => payment.id === basketPartConfig?.payment?.id)){
            setBasketPartConfig({...basketPartConfig, payment: null})
        }
    }, [basketPartConfig.delivery, basketPartConfig.location]);

    return (<div className="basket__form bd">
            <h5 className="basket__form-title f-exp">Способ оплаты</h5>
            {getAvailablePayments().length === 0 && <div className={'mt-2 text-center text-muted'}>
                <h5>выберите сначала способ доставки...</h5>
            </div>}
            <div className="form-radios--list">
                {getAvailablePayments().map((payment) => <label key={payment.name}
                        onClick={() => {
                            // dispatch({type: ReducerActionType.SET_PARAM, paramName: 'payment', payload: payment})
                            setBasketPartConfig({...basketPartConfig, payment: payment})
                            // @ts-ignore
                            ecommerceDataLayer('checkout', 'checkout', state.positions, 4, payment.name)
                            if (payment.id === PAYMENT_TYPE_CREDIT_ID) {
                                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'writeOffBonuses', payload: 0})
                            }
                            dispatch({
                                type: ReducerActionType.SET_PARAM,
                                paramName: 'isBonusDisabled',
                                payload: state.isBonusDisabled || payment.id === PAYMENT_TYPE_CREDIT_ID
                            })
                        }}
                    className={"min-height form-radio bd " + (basketPartConfig.payment && basketPartConfig.payment.name === payment.name ? 'active' : '')}>
                    <i className="label-dot" />
                    {/*<img src="/img/payment-card.png"  width="auto" height={32}/>*/}
                    <p className="f-exp">{payment.name}</p>
                    <small>{payment.template}</small>
                </label>)}
            </div>
        </div>
    );
}

export default PaymentList