import {useForm} from "react-hook-form";
import {login} from "../../api/customer";
import {mailRuGoal} from "../checkout/utils/marketing/Service";
import {useContext, useState} from "react";
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import LoginTypeSwitch from "./LoginTypeSwitch";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {useNavigate} from "react-router-dom";
import {LOCAL_STORAGE_KEY_CUSTOMER} from "../../Config";
import LoginControlBlock from "./LoginControlBlock";
import OpenidBlock from "./OpenidBlock";

function Login({setError, setLoading, setCustomer}: {
    setError: Function,
    setLoading: Function,
    setCustomer: Function
}) {

    const [inputType, setInputType] = useState('password');

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {register, getValues, formState: {errors}} = useForm({
        mode: "onChange"
    });

    const navigate = useNavigate();

    const auth = () => {
        setError('')
        const fields = getValues()
        if (!fields.email || !fields.password) {
            setError('Не указаны поля')
            return
        }
        setLoading(true)
        login(fields.email, fields.password).then(status => {
            setCustomer(status.user)
            localStorage.setItem(LOCAL_STORAGE_KEY_CUSTOMER, JSON.stringify(status.user))
            mailRuGoal('login')
            if(state.nextAction === 'goToCabinet'){
                navigate('/personal-area/')
            }
            dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'nextAction', payload: ''})
        }).catch(error => {
            const errors = error.toJSON();
            if (errors.status === 401) {
                setError("Неверный логин или пароль")
                return
            }
            setError(error.response?.data?.detail || error.response?.data?.message)
        }).finally(() => {
            setLoading(false)
        })

    }

    return (<>
        <h4 className="modal-form__title f-exp">Вход или регистрация</h4>
        <LoginTypeSwitch/>
        <div className="tab-content">
            <div className="tab-pane fade active show">
                <form onSubmit={(e) => { e.preventDefault(); }}>
                    <div className="form-group">
                        <label className="form-label">E-mail</label>
                        <input className={'form-control ' + (errors.email ? ' is-invalid' : '')} type="text"
                               placeholder="E-mail" {...register("email", {required: true})}/>
                    </div>
                    <div className="form-group">
                        <input className={'form-control ' + (errors.password ? ' is-invalid' : '')} type={inputType}
                               placeholder="Пароль" {...register("password", {required: true})}/>
                        <div className={'text-end'}><span className={'control pointer'} onClick={() => {
                            setInputType(inputType === 'password' ? 'text' : 'password')
                        }}>{inputType === 'password' ? 'показать' : 'скрыть'} пароль</span></div>
                    </div>
                    <div className="form-wrap">
                        <div className="form-checkbox">
                            <input className="hidden" id="check-enter-reg" type="checkbox"/>
                            <label className="label-dot"></label>
                            <label className="label-text">Запомнить меня</label>
                        </div>
                        <a className="form-link" href="/" onClick={(event) => {
                            event.preventDefault()
                            dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.PASSWORD_RECOVERY})
                        }}>Забыли пароль?</a>
                    </div>
                    <div className="form-btns">
                        <button type={'button'} className="btn btn-secondary" onClick={auth}>Войти</button>
                    </div>
                    <LoginControlBlock />
                    <OpenidBlock />
                </form>
            </div>
        </div>
    </>)

    // return (<>
    //     <div className={'text-muted'}>Введите e-mail и пароль, если вы зарегистрированы на сайте</div>
    //     <hr/>
    //     <div className="row mb-2">
    //         <label htmlFor="">Email</label>
    //         <div className="col">
    //             <input type="text" className={'form-control ' + (errors.email ? ' is-invalid' : '')}
    //                    placeholder="введите email" {...register("email", {required: true})}/>
    //         </div>
    //     </div>
    //     <div className="row mb-4">
    //         <label htmlFor="">Пароль</label>
    //         <div className="col">
    //             <input type={inputType} className={'form-control ' + (errors.password ? ' is-invalid' : '')}
    //                    placeholder="введите пароль" {...register("password", {required: true})}/>
    //             <div className={'text-right'}><span className={'control'} onClick={() => {
    //                 setInputType(inputType === 'password' ? 'text' : 'password')
    //             }}>{inputType === 'password' ? 'показать' : 'скрыть'} пароль</span></div>
    //         </div>
    //     </div>
    //     <div className="row  mb-4">
    //         <div className="col">
    //             <button className="btn btn-warning btn-custom shadow-warning col-12" onClick={auth}>Войти</button>
    //         </div>
    //     </div>
    //     <div className={'mt-4'}></div>
    //     <div className={'mt-4'}></div>
    //     <div className="row">
    //         <div className="col-5">
    //             <hr/>
    //         </div>
    //         <div className="col-2 text-center">или</div>
    //         <div className="col-5">
    //             <hr/>
    //         </div>
    //     </div>
    //     <div className={'text-center'}><span className={"control font-size-110"} onClick={() => {
    //         dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.LOGIN_BY_PHONE})
    //     }}>Войдите по телефону</span></div>
    //     <div className={'mt-4'}></div>
    //
    //     {/*<SocialIcons/>*/}
    // </>)
}

export default Login

