import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {EcommerceAction} from "../../utils/useDataLayer";
import ModelColorItemInterface from "../../interfaces/model/ModelColorItemInterface";
import {useContext, useEffect} from "react";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {getModelItemByParams} from "../../api/catalog";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import useDatalayer from "../../utils/useDataLayer";
import {DEFAULT_SHOP_ID} from "../../Config";

export default function useModelColorItem(modelColorItem: ModelColorItemInterface) {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {action: dataLayerManagerAction} = useDatalayer()

    const toggleFavorite = () => {
        dispatch({
            type: isExistInFavorite() ? ReducerActionType.REMOVE_FROM_FAVORITE : ReducerActionType.ADD_TO_FAVORITE,
            payload: modelColorItem
        })
        dataLayerManagerAction(isExistInFavorite() ? EcommerceAction.REMOVE_FROM_WISHLIST : EcommerceAction.ADD_TO_WISHLIST, [modelColorItem])
    }

    const isExistInFavorite = () => {
        const favorites: ModelColorItemInterface[] = state.favorites
        return favorites.filter(modelColorItemIterate => modelColorItemIterate.model_id === modelColorItem.model_id && modelColorItemIterate.color_special?.toLowerCase() === modelColorItem.color_special?.toLowerCase()).length > 0
    }

    const addToBasket = (size: string) => {
        getModelItemByParams(modelColorItem.model_id, modelColorItem.color, size).then((modelItem) => {
            const basketItem: BasketItemInterface = {
                modelColorItem: modelColorItem,
                itemId: modelItem.id,
                color: modelColorItem.color,
                size: size,
                quantity: 1,
                createdAt: new Date()
            }
            dispatch({type: ReducerActionType.ADD_TO_BASKET, payload: basketItem})
            dataLayerManagerAction(EcommerceAction.ADD_TO_CART, [modelColorItem])
        })
    }

    const isExistInBasket = (size: string) => {
        const basket: BasketItemInterface[] = state.basket
        return basket.filter(basketItem => basketItem.modelColorItem?.color_special === modelColorItem.color_special && basketItem.modelColorItem?.model_id === modelColorItem.model_id && basketItem?.size === size).length > 0
    }

    const isSizeAvailable = (size: string, shopId = DEFAULT_SHOP_ID): boolean => {
        if (!modelColorItem.stocks) return true
        return (modelColorItem.stocks[size] && (modelColorItem.stocks[size][DEFAULT_SHOP_ID] || 0) > 0) || false
    }

    const getImages = () => modelColorItem.images.filter(image => !(image.type === 'print'))

    const getVideos = () => modelColorItem.videos.filter(videos => !(videos.type === 'print'))

    const getImageAlt = (): string => {
        return modelColorItem.name + ' Baon ' + modelColorItem.articul
    }

    return {toggleFavorite, addToBasket, isSizeAvailable, isExistInBasket, isExistInFavorite, getImages, getImageAlt, getVideos}
}