import {useContext, useState} from "react";
import {checkCodeword} from "../../../api/checkout";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function CodeWord() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [codeword, setCodeword] = useState('')

    const applyCodeword = () => {
        setDisabled(true)
        setError('')
        if (!codeword) {
            setError('Не указано кодовое слово для обработки')
            return
        }

        checkCodeword(codeword, 'manzana', state.customer).then((response) => {
            if (response.error) {
                setError(response.error || 'При проверке кодового слова произошла ошибка')
                setDisabled(false)
                return
            } else {
                if (response.processing_result?.returnCode === "0") {
                    setSuccess('Кодовое слово применено. Количество баллов на бонусном счету: ' + response.processing_result?.cardActiveBalance)
                    dispatch({type: ReducerActionType.SET_PARAM, paramName: 'codeword', payload: codeword})
                } else {
                    setDisabled(false)
                    setError(response.processing_result?.message)
                }

            }
        }).catch(error => {
            setDisabled(false)
            setError(error.data?.detail || 'При проверке кодового слова произошла ошибка')
        })
    }

    return (
        <>
            <div className="lk__bonus-code bd">
                <h5 className="f-exp">
                    Есть кодовое слово для начисления бонусных баллов? Введите его
                    здесь
                </h5>
                <form action="">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder="Кодовое слово" disabled={disabled}
                               value={codeword} onChange={(e) => {
                            setCodeword(e.target.value)
                        }}/>
                        {!disabled && <button className="btn btn-secondary" onClick={applyCodeword}> Ок </button>}
                    </div>
                    <div>
                        {error && <small className={'text-danger'}>{error}</small>}
                        {success && <small className={'text-success'}>{success}</small>}
                    </div>
                </form>
            </div>
        </>
    )
}