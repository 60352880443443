import Main from "../ui/main/Main";
import ModelCard from "../ui/catalog/ModelCard";
import LandingByRoute from "../ui/landing/LandingByRoute";
import RouteNavigationInterface from "../interfaces/core/RouteNavigationInterface";
import CatalogListByPath from "../ui/catalog/CatalogListByPath";
import Favorites from "../ui/catalog/Favorites";
import CatalogListBySearchString from "../ui/catalog/CatalogListBySearchString";
import Checkout from "../ui/checkout/Checkout";
import PersonalArea from "../ui/customer/PersonalArea";
import PersonalAreaMain from "../ui/customer/personal-area/Main";
import Profile from "../ui/customer/personal-area/Profile";
import Orders from "../ui/customer/personal-area/Orders";
import Bonus from "../ui/customer/personal-area/Bonus";
import Favorite from "../ui/customer/personal-area/Favorite";
import PurchaseReturn from "../ui/customer/personal-area/PurchaseReturn";
import ShopList from "../ui/core/shops/ShopList";
import ShopItem from "../ui/core/shops/ShopItem";
import FashionBlogList from "../ui/core/fashion-blog/FashionBlogList";
import FashionBlogItem from "../ui/core/fashion-blog/FashionBlogItem";
import InformationPage, {PageType} from "../ui/core/InformationPage";
import Delivery from "../ui/core/pages/Delivery";
import Payment from "../ui/core/pages/Payment";
import ReservationInShop from "../ui/core/pages/ReservationInShop";
import FallbackUrls from "../ui/core/FallbackUrls";
import NewsItem from "../ui/core/news/NewsItem";
import Sizes from "../ui/core/pages/Sizes";
import Care from "../ui/core/pages/Care";
import BonusPage from "../ui/core/pages/BonusPage";
import TransactionStatus from "../ui/checkout/TransactionStatus";
import TransactionRedirect from "../ui/checkout/TransactionRedirect";
import ChangePasswordByMail from "../ui/customer/ChangePasswordByMail";
import Promotions from "../ui/core/promotions/Promotions";
import PromotionItem from "../ui/core/promotions/PromotionItem";
import UnsubscribeByHex from "../ui/customer/UnsubscribeByHex";
import LinkPhoneAndEmail from "../ui/core/loyalty/LinkPhoneAndEmail";
import CheckEmailLink from "../ui/core/loyalty/CheckEmailLink";
import {Page as ChoosYourLookPage} from "../ui/main/choose-your-look/Page"

import DealerLayout from "../ui/dealer/Layout";
import DealerCatalog from "../ui/dealer/Catalog";
import OrderList from "../ui/dealer/OrderList";
import AlfaID from "../ui/customer/openid/AlfaID";
import RefundForm from "../ui/customer/RefundForm";
import NullableRefund from "../ui/customer/NullableRefund";
import CreateGiftCertificateForm from "../ui/checkout/gift-certificate/CreateGiftCertificateForm";
import OrderSingle from "../ui/dealer/OrderSingle";


export const navigation: RouteNavigationInterface[] = [
    {
        path: '/',
        element: <Main />,
    },
    {
        path: '/choose-your-look/',
        element: <ChoosYourLookPage />,
    },
    // Catalog
    {
        path: '/catalog/search/',
        element: <CatalogListBySearchString />,
    },
    {
        path: '/catalog/index/repositoryId/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/menwear/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/womenwear/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/detskaya-odezhda/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/euro-brand/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/bytovaya-tehnika/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/set/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/catalog/index/*',
        element: <CatalogListByPath />,
    },
    {
        path: '/favorites/',
        element: <Favorites />,
    },
    {
        path: '/catalog/:modelId/:slug/:color/',
        element: <ModelCard />,
    },
    // -------
    {
        path: '/landing/:landingId',
        element: <LandingByRoute />,
    },
    {
        path: '/investors',
        element: <LandingByRoute />,
    },
    // -------
    {
        path: '/checkout',
        element: <Checkout />,
    },
    // -------
    {
        path: '/shops',
        element: <ShopList />,
    },
    {
        path: '/shop/:shopId',
        element: <ShopItem />,
    },
    // -------
    {
        path: '/index/blog/*',
        element: <FashionBlogList />
    },
    {
        path: '/index/blog-item/id/:id/',
        element: <FashionBlogItem />
    },
    {
        path: '/blog/*',
        element: <FashionBlogItem />
    },
    {
        path: '/index/news/id/:id/',
        element: <NewsItem />
    },
    {
        path: "/promotions/",
        element: <Promotions />,
    },
    {
        path: "/privilege/",
        element: <BonusPage />,
    },
    {
        path: "/promotion/:promotionId",
        element: <PromotionItem />,
    },
    {
        path: '/blog/:slug/',
        element: <FashionBlogItem />
    },
    {
        path: '/blog/:id/:slug/',
        element: <FashionBlogItem />
    },
    {
        path: '/qr/',
        element: <LinkPhoneAndEmail />
    },
    {
        path: '/qr-check/',
        element: <CheckEmailLink />
    },
    {
        path: '/personal-area',
        element: <PersonalArea />,
        children: [
            {
                path: '',
                element: <PersonalAreaMain />,
            },
            {
                path: 'profile',
                element: <Profile />,
            },
            {
                path: 'orders',
                element: <Orders />,
            },
            {
                path: 'privilege',
                element: <Bonus />,
            },
            {
                path: 'favorite',
                element: <Favorite />,
            },
            {
                path: 'purchase-returns',
                element: <PurchaseReturn />,
            },
            {
                path: 'refund/:id',
                element: <RefundForm />
            }
        ]
    },
    {
        path: 'refund/:partner/',
        element: <NullableRefund />
    },
    {
        path: 'gift-certificate',
        element: <CreateGiftCertificateForm />
    },
    {
        path: '/openid/alfa/',
        element: <AlfaID />,
    },
    {
        path: '/dealer',
        element: <DealerLayout  />,
        children: [
            {
                path: '',
                element: <DealerCatalog />,
            },
            {
                path: 'orders',
                element: <OrderList />,
            },
            {
                path: 'order/:orderId',
                element: <OrderSingle />,
            }
        ]
    },
    {
        path: '/index/about',
        element: <InformationPage type={PageType.ABOUT} />
    },
    {
        path: '/cooperation',
        element: <InformationPage type={PageType.COOPERATION} />
    },
    {
        path: '/vacancies',
        element: <InformationPage type={PageType.VACANCIES} />
    },
    {
        path: '/customer/get-delivery',
        element: <Delivery />
    },
    {
        path: '/customer/remind-password/:id/:sign',
        element: <ChangePasswordByMail />
    },
    {
        path: '/customer/email-unsubscribe-by-hex',
        element: <UnsubscribeByHex />
    },
    {
        path: '/index/payment',
        element: <Payment />
    },
    {
        path: '/index/sizes',
        element: <Sizes />
    },
    {
        path: '/index/recomendation',
        element: <Care />
    },
    {
        path: '/reservation-in-shop',
        element: <ReservationInShop />
    },
    {
        path: '/contacts',
        element: <InformationPage type={PageType.CONTACTS} />
    },
    {
        path: '/yandex-kassa/transaction-status/id/:orderId/secret/:secret/',
        element: <TransactionStatus />
    },
    {
        path: '/yandex-kassa/redirect-url/orderId/:orderId/orderSign/:secret/',
        element: <TransactionRedirect />
    },
    {
        path: '*',
        element: <FallbackUrls />,
    },

]