import {Link, useLocation, useNavigate} from "react-router-dom";
import {ModelFilterTagInterface} from "../../../interfaces/catalog/ModelFilterTagInterface";

export default function ModelFilterTags({tags, resetFilterTag}:{tags: ModelFilterTagInterface[], resetFilterTag: () => void}) {
    const location = useLocation();
    const navigate = useNavigate()

    const getFilterTagName = () => {
        const data = /\/tag\/(.+)\/$/.exec(location.pathname)
        return data?.length ? data[1] : null
    }

    const getBasePath = () => {
        const data = /(.+)\/tag\/.+/.exec(location.pathname)
        return data?.length ? data[1] + '/' : location.pathname
    }



    return (<div className="filters__activities">
        <div className="swiper-wrapper">
            {tags.map(tag => <div key={tag.id} className="swiper-slide filters__active"> <Link onClick={(event) => {
                if(getFilterTagName() === tag.slug) {
                    event.preventDefault()
                    resetFilterTag()
                    navigate(getBasePath())
                }
            }} className={getFilterTagName() === tag.slug ? 'active' : ''} to={getBasePath() + 'tag/' + tag.slug + '/'} >{tag.name}</Link></div>)}
            </div>
    </div>)
}