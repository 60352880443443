import React from "react";
import CollectionBannerInterface from "../../interfaces/main/CollectionBannerInterface";
import {GenderType} from "./Main";

interface CollectionBannerProps {
    banners: CollectionBannerInterface[];
    currentGender: GenderType;
    disableVideo?: boolean;
}

const CollectionBanner: React.FC<CollectionBannerProps> = ({banners, currentGender, disableVideo = false}) => {

    if (!banners || !Array.isArray(banners)) {
        return null;
    }

    const filteredBanners = banners.
    filter( banner => currentGender === GenderType.ALL || banner.gender === currentGender || banner.gender === GenderType.UNISEX).
    filter(banner => !disableVideo || (disableVideo && !banner.video));

    return (
        <>
            {filteredBanners.map((banner, index) => (
                <section
                    key={index}
                    className={`collections collections__banner mb ${
                        index > 0 ? "collections--pb collections__banner--pb mb" : ""
                    }`}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className="collection__banner--wrap">
                                    <a className="collection__banner banner" href={banner.url}>
                                        {banner.image && (
                                            <picture className="banner__img">
                                                {banner.imageMob ? (
                                                    <source srcSet={banner.imageMob} media="(max-width: 992px)" />
                                                ) : (
                                                    <source srcSet={banner.image} media="(min-width: 992px)" />
                                                )}
                                                <img src={banner.image} alt={banner.title} />
                                            </picture>
                                        )}

                                        {banner.video && (
                                            <>
                                                {banner.videoMob ? (
                                                    <>
                                                        <video
                                                            className="banner__img banner__video-desc"
                                                            src={banner.video}
                                                            autoPlay
                                                            playsInline
                                                            loop
                                                            muted
                                                            preload="none"
                                                        ></video>
                                                        <video
                                                            className="banner__img banner__video-mob"
                                                            src={banner.videoMob}
                                                            autoPlay
                                                            playsInline
                                                            loop
                                                            muted
                                                            preload="none"
                                                        ></video>
                                                    </>
                                                ) : (
                                                    <video
                                                        className="banner__img banner__video-desc"
                                                        src={banner.video}
                                                        autoPlay
                                                        playsInline
                                                        loop
                                                        muted
                                                        preload="none"
                                                    ></video>
                                                )}
                                            </>
                                        )}
                            {(banner.title || banner.description || banner.buttonText) && (
                                            <div className="banner__content">
                                                {banner.title && (
                                                    <h1 className="banner__title f-exp">{banner.title}</h1>
                                                )}
                                                {banner.description && (
                                                    <p className="banner__text">{banner.description}</p>
                                                )}
                                                {banner.buttonText && (
                                                    <a className="btn btn-primary" href={banner.url}>
                                                        {banner.buttonText}
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </>
    );
};

export default CollectionBanner;
