export const DESKTOP = 'desktop'
export const MOBILE = 'mobile'

export const TYPE_LONG_BANNER = 'long-banner'
export const TYPE_VIDEO = 'video'
export const TYPE_VIDEOFULL = 'video-full'
export const TYPE_TEXT = 'text'
export const TYPE_TEXTHEADER = 'text-header'
export const TYPE_TEXTMARKDOWN = 'text-markdown'
export const TYPE_SIMPLE_IMAGE = 'simple-image'
export const TYPE_IMAGELONG = 'long-image'
export const TYPE_BUTTON = 'button'
export const TYPE_SLIDER = 'slider'
export const TYPE_GRIDIMAGES = 'grid-images'
export const TYPE_FAQ = 'faq'
export const TYPE_GRIDTWOELEMS = 'grid-two-elements'
export const TYPE_GRIDTHREEELEMS = 'grip-three-elemnts'
export const TYPE_ROW = 'row'
export const TYPE_SLIDERBANNER = 'slider-banner'
export const TYPE_SLIDERBANNERSHORT = 'slider-banner-short'
export const TYPE_BUTTONELEM = 'button-elem'
export const TYPE_SUBSCRIBE = 'subscribe'
export const TYPE_TIMER = 'timer'
export const TYPE_REGFORM = 'registration-form'
export const TYPE_COOPERATION = 'cooperation-form'
export const TYPE_STYLIST = 'stylist-form'
export const TYPE_AUTH = 'auth-landing-form'
export const TYPE_BANNERTEXT = 'langing-banner-text'
export const TYPE_HIDDENTEXT = 'landing-hidden-text'