import Login from "./Login";
import PasswordRecovery from "./PasswordRecovery";
import Registration from "./Registration";
import {useContext, useEffect, useState} from "react";
import LoginByPhone from "./LoginByPhone";
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import {CustomerInterface} from "../../interfaces/customer/CustomerInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

function AuthBlock() {
    // eslint-disable-next-line
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const setCustomer = (customer: CustomerInterface) => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'customer', payload: customer})
        // dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
    }

    useEffect(() => {
        if (loading) {
            dispatch({type: ReducerActionType.LOAD})
        } else {
            dispatch({type: ReducerActionType.SUCCESS})
        }
    }, [loading]);

    return (<>
            {error && <h4 className={'text-danger text-center'}>{error}</h4>}
            {state.showAuthPopup && <Login setCustomer={setCustomer} setError={setError} setLoading={setLoading} />}
            {state.showAuthByPhonePopup && <LoginByPhone setCustomer={setCustomer} setError={setError} setLoading={setLoading} />}
            {state.showPasswordRecoveryPopup && <PasswordRecovery setError={setError} setLoading={setLoading}/>}
            {state.showRegistrationPopup && <Registration setCustomer={setCustomer} setError={setError} setLoading={setLoading} />}

        </>)
}

export default AuthBlock