import TopNavigation from "./TopNavigation";
import RefundInterface, {RefundPositionInterface} from "../../../interfaces/customer/RefundInterface";
import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import RefundPosition from "./RefundPosition";
import {OrderInterface} from "../../../interfaces/customer/OrderInterface";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import ModelColorItemInterface from "../../../interfaces/model/ModelColorItemInterface";
import {getModelColorItems} from "../../../api/catalog";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {uuidv4} from "../../../utils/Utils";
import MoneyInterface from "../../../interfaces/core/MoneyInterface";
import Money from "../../core/Money";

export default function Step2({order, params, setParams}: {
    order: OrderInterface | undefined,
    params: RefundInterface,
    setParams: (params: RefundInterface) => void
}) {
    const {register, reset, handleSubmit, formState: {errors}} = useForm()

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext)


    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState<ModelColorItemInterface[]>([])
    const [sum, setSum] = useState<MoneyInterface>({amount: 0, currency: {name: 'RUB'}})


    useEffect(() => {
        if (params.isInit) {
            reset(params)
        }
    }, [params.isInit]);

    useEffect(() => {
        if (params.positions.length) {
            let sum = 0
            params.positions.forEach(position => {
                sum += position.price.amount * position.quantity
            })
            setSum({amount: sum, currency: params.positions[0].price.currency})
        }
    }, [params.positions]);

    const formSubmit = (formParams: any) => {
        const selectedPositions = params.positions.filter(position => position.isSelected)

        if (!selectedPositions?.length) {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'Необходимо выбрать хотя бы одну позицию для возврата'
            })
            return
        }

        const positionsWithoutSize = params.positions.filter(position => !position.size)

        if (positionsWithoutSize?.length) {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'Необходимо указать размер для всех позиций'
            })
            return
        }

        const positionsWithoutReason = params.positions.filter(position => !position.reason && position.isSelected)

        if (positionsWithoutReason?.length) {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'Необходимо указать причину возврата для всех позиций'
            })
            return
        }

        setParams({...params, ...{comment: formParams.comment, positions: params.positions.filter(position => position.isSelected), step: 3}})
    }

    const setPosition = (position: RefundPositionInterface) => {
        const index = params.positions.findIndex(lpos => lpos.uuid === position.uuid);
        const localPositions = params.positions
        if (index > -1) {
            localPositions[index] = position
        }
        setParams({...params, ...{positions: localPositions}})
    }

    return (<div className="lk__refund-petition--products">
            <form onSubmit={handleSubmit(formSubmit)}>
                <div className="row">
                    <div className="col-lg-5">
                        <TopNavigation step={2}/>
                    </div>
                </div>
                {params.isManual && <div className="row">
                    <div className="col-lg-5">
                        <label><b>Поиск модели для добавления</b></label>
                        <AsyncTypeahead
                            delay={500}
                            id={'typeahead'}
                            isLoading={isLoading}
                            filterBy={() => true}
                            useCache={false}
                            // @ts-ignore
                            labelKey={option => `${option.name}, арт. ${option.articul} - ${option.color} (${option.color_human_name})`}
                            placeholder={"введите артикул..."}
                            searchText={"производим поиск..."}
                            onSearch={(query) => {
                                if (query.length < 4) return
                                setIsLoading(true)
                                getModelColorItems({articules: [query], prevent_price_control: true, prevent_stock_control: true, prevent_disable_control: true}).then(modelColors => {
                                    setIsLoading(false)
                                    setOptions(modelColors?.model_color_items || [])
                                }).catch(error => {
                                    dispatch({
                                        type: ReducerActionType.ERROR,
                                        payload: 'При запросе списка моделей: ' + (error.response?.data?.message || error.response?.data?.detail)
                                    })
                                })
                            }}
                            onChange={(selected) => {
                                if (selected.length) {
                                    const modelColor: ModelColorItemInterface = selected[0] as ModelColorItemInterface

                                    const position: RefundPositionInterface = {
                                        modelColor: modelColor,
                                        quantity: 1,
                                        reason: undefined,
                                        price: modelColor.min_price,
                                        size: undefined,
                                        isManual: true,
                                        isSelected: true,
                                        uuid: uuidv4()
                                    }
                                    setParams({...params, ...{positions: [...params.positions, position]}})
                                }
                            }}
                            options={options}
                        />
                    </div>
                </div>}

                <div className="lk__order-table">
                    <div className="head f-exp desk">
                        <ul>
                            <li>Товар</li>
                            <li>Артикул</li>
                            <li>Причина возврата</li>
                            <li>Количество</li>
                            <li>Сумма к возврату</li>
                            <li></li>
                        </ul>
                    </div>
                    <div className="body">
                        <ul>
                            {params.positions.map(position => <RefundPosition key={position.uuid}
                                                                              setPosition={setPosition}
                                                                              position={position}/>)}
                        </ul>
                    </div>
                    <div className="foot f-exp">
                        <ul style={{width: '95%'}}>
                            <li><p><b>Итого к возврату</b></p><p><b><Money value={sum}/></b></p></li>
                        </ul>
                    </div>
                    <div className="form-line">
                        <label>Комментарий в возврату</label>
                        <div className="form-group">
                            <input
                                className={"form-control" + (errors.comment ? ' is-invalid' : '')} {...register("comment")} type="text" placeholder="Текст комментария"/>
                        </div>
                    </div>
                    <div className="form-line">
                        <div className="form-btns">
                            <button className="btn btn-secondary-tr" onClick={(event) => {
                                event.preventDefault();
                                setParams({...params, ...{step: 1}})
                            }} style={{marginRight: '1.5rem', width: '18.5rem'}}>Назад
                            </button>
                            <button className="btn btn-secondary" style={{width: '18.5rem'}}>Далее</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}