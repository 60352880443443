import BreadcrumbInterface from "../../interfaces/core/BreadcrumbInterface";
import {Link} from "react-router-dom";

export default function Breadcrumbs({breadcrumbs} : {breadcrumbs : BreadcrumbInterface[]}) {
    return (<ul className="breadcrumb desk">
        {breadcrumbs && breadcrumbs.map((breadcrumb, index) => <li key={index} className={"breadcrumb-item " + (breadcrumb.isActive ? "active" : "")}>
            {breadcrumb.url && <Link to={breadcrumb.url}>{breadcrumb.icon && <i className={"ico " + breadcrumb.icon}></i>}{breadcrumb.name}</Link>}
            {!breadcrumb.url && <>{breadcrumb.icon && <i className={"ico " + breadcrumb.icon}></i>}{breadcrumb.name}</>}
        </li>)}
    </ul>)
}