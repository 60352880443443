
import Form from "react-bootstrap/Form";
import Spacer from '../Spacer/Spacer.jsx'
import styles from './StylistForm.module.scss'
import {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import axios from 'axios'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";
import Md5 from "../../../../utils/Md5";
import {LOCAL_STORAGE_KEY_PARAMS} from "../../../../Config";
import moment from "moment";

const StylistForm = props => {

	const [message, setMessage] = useState("");

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState,
		formState: {errors}
	} = useForm({
		defaultValues: {
			email: "",
			fio: "",
			phone: "",
			city: "",
		}
	});

	useEffect(() => {
		if (formState.isSubmitSuccessful) {
			reset({
				email: "",
				fio: "",
				phone: "",
				city: "",
			},)
		}
	}, [formState, reset])

	let formSubmit = async (data) => {
		try {
			const uid = Md5(data.email + moment().format('YYYY-MM-DD') + LOCAL_STORAGE_KEY_PARAMS);

			const payload = {
				uid,
				first_name: data.fio,
				phone: data.phone,
				email: data.email,
				city: data.city,
			};


			console.log("payload:", payload);

			const res = await axios.post("https://baon.ru/api/dealer/anketa", payload);

			if (res.status === 200) {
				setMessage("Ваша заявка отправлена!");
			} else {
				setMessage("Произошла ошибка, попробуйте снова.");
			}

		} catch (err) {
			console.error("error form:", err);
			setMessage("Произошла ошибка, попробуйте снова");
		}
	};

	return (
		<>
			<div className={styles.registrationContainer}>
				<div className={styles.formContainer}>
					<div className={styles.blockSubscribe}>
						<div className={styles.blockSubscribe_header}> Заявка на сотрудничество</div>
						<p className={styles.blockSubscribe_desc}>Пожалуйста, заполните все поля формы </p>
						<div className="row">
							{/* {props.params.img && (
								<div className="col-lg-6 col-12">
									<div className={styles.form_image + ' w100'}>
										{
											<picture>
												<source media="(max-width: 768px)" srcSet={getBaseImagePath() + props.params.imgmob} />
												<img src={getBaseImagePath() + props.params.img} alt={props.params.img} />
											</picture>
										}
									</div>
								</div>
							)} */}
							<div className={"col-12"}>
								<form onSubmit={handleSubmit(formSubmit)} className={styles.blockSubscribe_form}>

									<Form.Group>
										<Form.Label>ФИО</Form.Label>
										<Form.Control
											type='text'
											className={styles.formInput}
											{...register('fio', {required: false})}
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label>Номер телефона</Form.Label>
										<Form.Control
											type='text'
											className={styles.formInput}
											placeholder="+7 ___ ___-__-__"
											{...register('phone', {required: false})}
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label>Email</Form.Label>
										<Form.Control
											type='text'
											className={`${styles.formInput} ${styles.blockSubscribe_email}`}
											{...register("email", {
												required: "Email обязателен для заполнения",
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													message: "Email заполнен неправильно"
												}
											})}
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label>Город</Form.Label>
										<Form.Control
											type='text'
											className={styles.formInput}
											{...register('city', {required: false})}
										/>
									</Form.Group>

									<div className={styles.formBtn}>
										<button className="btn btn-secondary" type="submit">Отправить заявку</button>
									</div>

								</form>
								<div className={styles.successMessage}>{message ? <p>{message}</p> : null}</div>
								{errors.email && <p className={styles.validate}>{errors.email.message}</p>}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Spacer />
		</>
	)
}

export default StylistForm
