import React, {useState} from 'react';
import Form from "react-bootstrap/Form";

function FileUpload(props) {
	const [images, setImages] = useState([]);

	const toBase64 = file => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

	const handleChange = async (event) => {
		const files = event.target.files;
		const base64Files = [];

		if (files.length + images.length > 7) {
			alert('Можно загрузить не более 7 фотографий. Пожалуйста повторите загрузку');
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (files[i].type !== 'image/jpeg') {
				alert('Можно загружать фотографии только в формате JPEG. Пожалуйста повторите загрузку');
				return;
			}

			const base64 = await toBase64(files[i]);
			base64Files.push(base64);
		}

		const newImages = [...images, ...base64Files];
		setImages(newImages);
		props.setBase64(props.id + '__base64__', newImages);
		console.log('base64Files', base64Files);
	}

	const handleDelete = (index) => {
		const newImages = images.filter((_, i) => i !== index);
		setImages(newImages);
		props.setBase64(props.id + '__base64__', newImages);
	}

	return (
		<>
			<div className="form-loads">
				{images.map((image, index) => (
					<div className="form-load" key={index}>
						<img src={image} alt={`uploaded ${index}`} />
						<i className="form-load__delete" onClick={() => handleDelete(index)}></i>
					</div>
				))}
				{images.length < 7 && (
					<div className="form-load">
						<input className="hidden" id="load-photo-01" type="file" />
						<Form.Control className='add-photo' type='file' multiple onChange={handleChange} />
						<label className="label-dot" htmlFor="load-photo-01"></label>
					</div>
				)}
			</div>
		</>
	);
}

export default FileUpload;