import React, {useContext, useEffect} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {BasketItemInterface} from "../../../interfaces/core/BasketItemInterface";
import Money from "../Money";
import ModelImageInterface from "../../../interfaces/model/ModelImageInterface";
import {MAIN_IMAGE_TYPE} from "../../../Config";
import {Link} from "react-router-dom";
import {getBaseImagePath} from "../../catalog/Utils";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

declare var $: any;

export default function AddToBasketPopup({basketItem}: {basketItem: BasketItemInterface}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const getMainImage = (images: ModelImageInterface[]) => {
        return images.find(image => image.type === MAIN_IMAGE_TYPE)
    }

    useEffect(() => {
        // $('.scrollbar-inner').scrollbar({ "scrollx": "advanced", "scrolly": "advanced" });
    }, []);

    return(<div className="notification notification--right" >
        <div className="notification__head">
            <h5 className="notification__title f-exp">Товар добавлен в корзину</h5>
            <i className="notification__close ico ico-close" onClick={() => {
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'addToBasketLastItem', payload: null})
            }}></i>
        </div>
         <SimpleBar style={{ maxHeight: 800 }}  autoHide={false}>
        <div className="scrollbar-inner" >
            <div className="notification__product">
                <div className="notification__product-img">
                    <img src={getBaseImagePath() + getMainImage(basketItem.modelColorItem.images)?.url} alt=""/>
                </div>
                <div className="notification__product-info">
                    <div className="wrap">
                        <div className="left">
                            <h6>{basketItem.modelColorItem.name}</h6>
                            <ul>
                                <li>Размер: {basketItem.size}</li>
                                <li>Цвет: {basketItem.modelColorItem.color}</li>
                                <li>Артикул: {basketItem.modelColorItem.articul}</li>
                            </ul>
                            {/*<p>Осталось 2 шт</p>*/}
                        </div>
                        <div className="right desk">
                            <div className="price f-exp">
                                <h6 className={ (basketItem.modelColorItem.discount_value.amount > 0 ? "discount " : "") + " nobr"}><Money value={basketItem.modelColorItem.min_price}/></h6>
                                {basketItem.modelColorItem.discount_value.amount > 0 && <>
                                    <h6 className="old nobr"><Money value={basketItem.modelColorItem.max_price}/></h6>
                                </>}
                            </div>
                        </div>
                    </div>
                    <a className="notification__product-trash ico-cont desk" href="/" onClick={(event) => {
                        event.preventDefault()
                        dispatch({type: ReducerActionType.REMOVE_FROM_BASKET, payload: basketItem})
                    }}><i className="ico ico-left ico-trash"></i>Удалить</a>
                </div>
            </div>
        </div>
        </SimpleBar>
        <div >
            <Link to={'/checkout'} className="btn btn-secondary btn-block"><span >Перейти в корзину</span></Link>
        </div>
    </div>)
}