import {useContext, useState} from "react";
import {checkPromocode} from "../../api/checkout";
import {objclone} from "./utils/Utils";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function Promocodes() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [error, setError] = useState('')
    const [promocode, setPromocode] = useState('')

    const promocodes: string[] = state.promocodes

    const addPromocode = () => {
        setError('')
        if (!promocode) {
            setError('Не указан промокод для обработки')
            return
        }

        if (state.promocodes.indexOf(promocode) > -1) {
            setError('Данный промокод уже использован Вами')
            return
        }

        checkPromocode(promocode).then((response) => {
            if (response.error) {
                setError(response.error || 'При проверке промокода произошла ошибка')
            } else {
                let promocodes = objclone(state.promocodes)
                promocodes.push(promocode)
                dispatch({type: ReducerActionType.SET_PARAM, paramName: 'promocodes', payload: promocodes})
                setPromocode('')
            }
        })
    }

    const removePromocode = (promocode: string) => {
        let promocodes: string[] = objclone(state.promocodes)
        promocodes = promocodes.filter(code => code !== promocode)
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'promocodes', payload: promocodes})
    }

    return (<div className="basket__promocode">
            <form action="">
                {error && <small className={'text-danger'}>{error}</small>}
                <div className="form-group">

                    <input type="text" className="form-control" placeholder="Промокод" value={promocode}
                           onChange={(e) => {
                               setPromocode(e.target.value)
                           }}/>

                    <button type={"button"} className="btn btn-secondary" onClick={(e) => {
                        addPromocode()
                    }}>
                        <i className="ico ico-arrow-right-w"/>
                    </button>

                </div>

                <div className="row">
                    <div className="col">
                        {promocodes?.map(promocode => <span key={promocode} className="badge bg-black me-2">
                                {promocode} <span className="badge text-white" onClick={() => {
                                removePromocode(promocode)
                            }}>&times;</span>
                            </span>
                        )}
                    </div>
                </div>
            </form>
        </div>
    )

}