import ModelColorItemInterface from "../interfaces/model/ModelColorItemInterface";
import {BasketPositionResponseInterface} from "../interfaces/checkout/BasketPositionResponseInterface";
import {OrderPositionInterface} from "../interfaces/customer/OrderPositionInterface";
import {useContext} from "react";
import {ReducerStateInterface} from "../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../GlobalReducer";

export enum EcommerceAction {
    VIEW_ITEM = 'view_item',
    VIEW_CATEGORY = 'view_category',
    ADD_TO_CART = 'add_to_cart',
    REMOVE_FROM_CART = 'remove_from_cart',
    VIEW_CART = 'view_cart',
    BEGIN_CHECKOUT = 'begin_checkout',
    ADD_SHIPPING_INFO = 'add_shipping_info',
    ADD_PAYMENT_INFO = 'add_payment_info',
    PURCHASE = 'purchase',
    ADD_TO_WISHLIST = 'add_to_wishlist',
    REMOVE_FROM_WISHLIST = 'remove_from_wishlist'
}

export default function useDatalayer() {
    const [state] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const getUserData = () => {
        return {
            user_id: state.customer?.phone || null,
            user_name: state.customer?.first_name || state.customer?.last_name ? (state.customer?.first_name || '') + ' ' + (state.customer?.last_name || '') : null,
            user_email: state.customer?.email || (state.customer?.phone ? state.customer?.phone + '@unknown.email' : null),
        }
    }

    const action = (action: EcommerceAction, modelColorItems: ModelColorItemInterface[], ecommerceParams = {}) => {
        // @ts-ignore
        const dataLayer: any = window.dataLayer || [];

        if (!modelColorItems?.length || !dataLayer) {
            return
        }


        const ecommerce = {
            ...getUserData(),
            ...{
                currency: "RUB",
                value: modelColorItems.reduce((sum: number, current: ModelColorItemInterface) => {
                    return sum += current.min_price.amount / 100
                }, 0),
                items: modelColorItems.reduce((redval: any, modelColorItem: ModelColorItemInterface) => {
                    redval.push(
                        {
                            item_id: modelColorItem.uid,  // ID цветомодель, формат: 1026262001
                            item_name: modelColorItem.name,
                            discount: modelColorItem.discount_value.amount / 100,   //скидка на товар
                            item_brand: modelColorItem.brand,        // BAON / Trendyol / Desam
                            item_category: modelColorItem.collection_name,  // ВЕСНА-ЛЕТО 2024 - Коллекция товара
                            item_category2: modelColorItem.gender_name,  // Женский / Мужской / Детский - пол
                            item_category3: modelColorItem.category_name, // Платья / Шорты - Класс товара - Тип одежды
                            item_variant: modelColorItem.color_human_name_eng,  // Цвет добавляем английский BLACK
                            price: modelColorItem.min_price.amount / 100,
                            quantity: 1
                        }
                    )
                    return redval
                }, []),
            },
            ...ecommerceParams
        }

        const dataLayerData = {
            event: action,
            ecommerce: ecommerce
        }


        dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.

        console.log('DataLayer Action -> ', dataLayerData)

        dataLayer.push(dataLayerData)
        return

    }

    const category = (action: EcommerceAction, categoryId: string, ecommerceParams = {}) => {
        // @ts-ignore
        const dataLayer: any = window.dataLayer || [];

        const ecommerce = {
            ...getUserData(),
            ...{
                category_id: categoryId
            },
            ...ecommerceParams
        }

        const dataLayerData = {
            event: action,
            ecommerce: ecommerce
        }


        dataLayer.push({ecommerce: null})

        console.log('DataLayer Category -> ', dataLayerData)

        dataLayer.push(dataLayerData)
        return
    }

    const transaction = (action: EcommerceAction, positions: BasketPositionResponseInterface[], ecommerceParams = {}) => {
        // @ts-ignore
        const dataLayer: any = window.dataLayer || [];

        if (!positions?.length || !dataLayer) {
            return
        }

        const ecommerce = {
            ...getUserData(),
            ...{
                currency: "RUB",
                value: positions.reduce((sum: number, current: BasketPositionResponseInterface) => {
                    sum += current.current_price.amount / 100 * current.quantity
                    return sum
                }, 0),
                items: positions.reduce((redval: any, basketItem: BasketPositionResponseInterface) => {
                    if (basketItem.model_color_item) {
                        redval.push(
                            {
                                item_id: basketItem.model_color_item.uid,  // ID цветомодель, формат: 1026262001
                                item_name: basketItem.model_color_item.name,
                                discount: basketItem.model_color_item.discount_value.amount / 100,   //скидка на товар
                                item_brand: basketItem.model_color_item.brand,        // BAON / Trendyol / Desam
                                item_category: basketItem.model_color_item.collection_name,  // ВЕСНА-ЛЕТО 2024 - Коллекция товара
                                item_category2: basketItem.model_color_item.gender_name,  // Женский / Мужской / Детский - пол
                                item_category3: basketItem.model_color_item.category_name, // Платья / Шорты - Класс товара - Тип одежды
                                item_variant: basketItem.model_color_item.color_human_name_eng,  // Цвет добавляем английский BLACK
                                price: basketItem.model_color_item.min_price.amount / 100,
                                quantity: basketItem.quantity
                            }
                        )
                    } else {
                        console.error('DataLayer Error / position not contain modelColorItem  -> ', basketItem);
                    }

                    return redval
                }, []),
            }, ...ecommerceParams
        }

        const dataLayerData = {
            event: action,
            ecommerce: ecommerce
        }


        dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.

        console.log('DataLayer Action -> ', dataLayerData)

        dataLayer.push(dataLayerData)
        return
    }


    const successOrder = (positions: OrderPositionInterface[], ecommerceParams = {}) => {
        // @ts-ignore
        const dataLayer: any = window.dataLayer || [];

        if (!positions?.length || !dataLayer) {
            return
        }

        const ecommerce = {
            ...getUserData(),
            ...{
                currency: "RUB",
                value: positions.reduce((sum: number, current: OrderPositionInterface) => {
                    sum += current.price_with_bonus.amount / 100 * current.quantity
                    return sum
                }, 0),
                items: positions.reduce((redval: any, position: OrderPositionInterface) => {
                    if (position.model_color_item) {
                        redval.push(
                            {
                                item_id: position.model_color_item.uid,  // ID цветомодель, формат: 1026262001
                                item_name: position.model_color_item.name,
                                discount: position.model_color_item.discount_value.amount / 100,   //скидка на товар
                                item_brand: position.model_color_item.brand,        // BAON / Trendyol / Desam
                                item_category: position.model_color_item.collection_name,  // ВЕСНА-ЛЕТО 2024 - Коллекция товара
                                item_category2: position.model_color_item.gender_name,  // Женский / Мужской / Детский - пол
                                item_category3: position.model_color_item.category_name, // Платья / Шорты - Класс товара - Тип одежды
                                item_variant: position.model_color_item.color_human_name_eng,  // Цвет добавляем английский BLACK
                                price: position.price_with_bonus.amount / 100,
                                quantity: position.quantity
                            }
                        )
                    } else {
                        console.error('DataLayer Error / position not contain modelColorItem  -> ', position);
                    }

                    return redval
                }, []),
            }, ...ecommerceParams
        }

        const dataLayerData = {
            event: EcommerceAction.PURCHASE,
            ecommerce: ecommerce
        }

        dataLayer.push({ecommerce: null}); // Clear the previous ecommerce object.

        console.log('DataLayer Action -> ', dataLayerData)

        dataLayer.push(dataLayerData)
        return
    }

    return {action, category, transaction, successOrder}
}