import {IntervalDateInterface} from "../../interfaces/checkout/IntervalDateInterface";
import {BasketPartConfigInterface} from "../../interfaces/checkout/BasketPartConfigInterface";

function DeliveryDateTime({dates, intervals, basketPartConfig, setBasketPartConfig}: {dates: IntervalDateInterface[], intervals: string[], basketPartConfig: BasketPartConfigInterface, setBasketPartConfig: (basketPartConfig: BasketPartConfigInterface) => void}) {
    return (<div className="basket__form bd">
            <h5 className="basket__form-title f-exp">Дата и время доставки</h5>
            <div className="basket__date-time">
                <ul className="basket__date">
                    {dates.map(date => <li key={date.id}>
                        <a className={"bd " + (basketPartConfig.courierDate?.id === date?.id ? 'active' : '') } href="/" onClick={(event) => {
                            event.preventDefault()
                            setBasketPartConfig({...basketPartConfig, courierDate: date})
                        }}>
                            <p className="f-exp">{date.title2}, {date.name_short}</p>
                            <small>0 рублей</small>
                        </a>
                    </li>)}
                </ul>
                <ul className="basket__time">
                    {intervals.map(interval => <li key={interval}>
                        <a className={"bd " + (basketPartConfig.courierInterval === interval ? 'active' : '')} href="/" onClick={(event) => {
                            event.preventDefault()
                            setBasketPartConfig({...basketPartConfig, courierInterval: interval})
                        }}>
                            <p className="f-exp">{interval}</p>
                            <small>0 рублей</small>
                        </a>
                    </li>)}
                </ul>
            </div>
        </div>
    )
}

export default DeliveryDateTime