import {useState} from "react";

export default function InfoText(){

    const [showMore, setShowMore] = useState(false);

    return (<div className="col-lg-6 offset-lg-1">
        <div className="bd information">
            <p>Если по какой-либо причине у вас нет возможности передать нам товар для возврата в допустимые сроки, свяжитесь с менеджером по возврату, чтобы мы рассмотрели ваше заявление в индивидуальном порядке.</p>
            <hr/>
            <p>Возврат товара надлежащего качества* возможен в том случае, если сохранены его товарный вид, потребительские свойства, а также документы, подтверждающие факт и условия покупки указанного товара (товарный или кассовый чек). Выплата денежных средств осуществляется после того, как покупатель предоставит продавцу товар, подтверждающий его приобретение документ, заявление с указанием причины возврата и полные реквизиты банковского счета.</p>
            {!showMore && <div><a href="" className={'dashed'} onClick={(e) => {setShowMore(true); e.preventDefault(); }}>подробнее...</a></div>}
            {showMore && <div>
                <p>* - Возврату по данному основанию не подлежат непродовольственные товары надлежащего качества,
                    предусмотренные Перечнем непродовольственных товаров (парфюмерно - косметические товары, швейные и
                    трикотажные изделия (изделия швейные и трикотажные бельевые, изделия чулочно-носочные, текстильные
                    товары (хлопчатобумажные, льняные, шелковые, шерстяные и синтетические ткани, товары из нетканых
                    материалов типа тканей - ленты, тесьма, кружево и другие)) надлежащего качества, не подлежащих
                    возврату или обмену на аналогичный товар других размера, формы, габарита, фасона, расцветки или
                    комплектации, утвержденным Постановлением Правительства РФ от 19.01.98г. № 55 (с изменениями от
                    20.10.98г., 02.10.99г., 06.02.2002г.).</p>
                <p>Возврат товара ненадлежащего качества осуществляется в соответствии с условиями, описанными выше,
                    перечисление денежных средств производится после товароведческой экспертизы.</p>
                <p>Вы вправе отказаться от товара в течение 10 рабочих дней с момента его получения.</p>
                <p>Вернуть товар можно в один из фирменных магазинов сети, либо отправив посылку по почте России,
                    обязательно приложив заявление на возврат.</p>
                <p>- Адрес для отправки посылок Почтой России: 143430. обл. Московская, г Красногорск, рп. Нахабино, ул.
                    Советская, дом 28, ООО "Бифлекс".</p>
                <p>Обратите внимание: расходы по пересылке товара покупатель берёт на себя. В том случае, если вы
                    возвращаете товар по причине брака, расходы будут компенсированы. Для этого сохраняйте квитанцию об
                    оплате почтовых услуг.</p>
            </div>}
        </div>
    </div>)
}