import Breadcrumbs from "../Breadcrumbs";

export default function Delivery() {
    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Доставка'
            }
        ]
    }

    return (<main className="main-delivery">
            <section className="delivery pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs()} />
                        </div>
                    </div>
                    <div className="row delivery__head">
                        <div className="col-lg-4">
                            <h1 className="delivery__head-title f-exp">Доставка</h1>
                            {/*<ul className="nav nav-tabs f-exp desk" id="myTab" role="tablist">*/}
                            {/*    <li className="nav-item" role="delivery">*/}
                            {/*        <button*/}
                            {/*            className="btn active"*/}
                            {/*            id="delivery-tab-1"*/}
                            {/*            data-bs-toggle="tab"*/}
                            {/*            data-bs-target="#delivery-sect-1"*/}
                            {/*            type="button"*/}
                            {/*            role="tab"*/}
                            {/*            aria-controls="delivery-sect-1"*/}
                            {/*            aria-selected="true"*/}
                            {/*        >*/}
                            {/*            Россия*/}
                            {/*        </button>*/}
                            {/*    </li>*/}
                            {/*    <li className="nav-item" role="delivery">*/}
                            {/*        <button*/}
                            {/*            className="btn"*/}
                            {/*            id="delivery-tab-2"*/}
                            {/*            data-bs-toggle="tab"*/}
                            {/*            data-bs-target="#delivery-sect-2"*/}
                            {/*            type="button"*/}
                            {/*            role="tab"*/}
                            {/*            aria-controls="delivery-sect-2"*/}
                            {/*            aria-selected="true"*/}
                            {/*        >*/}
                            {/*            Казахстан*/}
                            {/*        </button>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                        <div className="col-lg-6 offset-lg-2">
                            <p className="f-exp">
                                Осуществляем доставку по всей России. Доставка по всей России БЕСПЛАТНО при покупке на сумму от 3 000 рублей! При полном отказе от покупки после примерки или при выкупе на сумму менее 3000 рублей услуги доставки оплачиваются согласно тарифной зоне.
                            </p>
                            <div className="mob">
                                <ul className="nav nav-tabs f-exp" id="myTab" role="tablist">
                                    <li className="nav-item" role="delivery">
                                        <button
                                            className="btn active"
                                            id="delivery-tab-1"
                                            data-bs-toggle="tab"
                                            data-bs-target="#delivery-sect-1"
                                            type="button"
                                            role="tab"
                                            aria-controls="delivery-sect-1"
                                            aria-selected="true"
                                        >
                                            Россия
                                        </button>
                                    </li>
                                    <li className="nav-item" role="delivery">
                                        <button
                                            className="btn"
                                            id="delivery-tab-2"
                                            data-bs-toggle="tab"
                                            data-bs-target="#delivery-sect-2"
                                            type="button"
                                            role="tab"
                                            aria-controls="delivery-sect-2"
                                            aria-selected="true"
                                        >
                                            Казахстан
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="col">*/}
                    {/*        <div className="tab-content" id="mylkTabContent">*/}
                    {/*            <div*/}
                    {/*                className="tab-pane fade show active"*/}
                    {/*                id="delivery-sect-1"*/}
                    {/*                role="tabpanel"*/}
                    {/*                aria-labelledby="delivery-tab-1"*/}
                    {/*            >*/}
                    {/*                <div className="delivery__change-city f-exp">*/}
                    {/*                    <div className="delivery__change-city-title">*/}
                    {/*                        <h4>Способы доставки по Москве</h4>*/}
                    {/*                        <a className="delivery__change-city-link" href="">*/}
                    {/*                            Изменить город*/}
                    {/*                        </a>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="delivery__change-city-form">*/}
                    {/*                        <form action="">*/}
                    {/*                            <div className="form-group">*/}
                    {/*                                <a*/}
                    {/*                                    className="form-control__search ico ico-search"*/}
                    {/*                                    href=""*/}
                    {/*                                />*/}
                    {/*                                <input*/}
                    {/*                                    className="form-control"*/}
                    {/*                                    type="text"*/}
                    {/*                                    placeholder="Поиск города"*/}
                    {/*                                />*/}
                    {/*                                <a className="form-control__clear" href="">*/}
                    {/*                                    очистить*/}
                    {/*                                </a>*/}
                    {/*                            </div>*/}
                    {/*                        </form>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="delivery__list">*/}
                    {/*                    <div className="delivery__list-block">*/}
                    {/*                        <h5 className="f-exp">Курьерская доставка</h5>*/}
                    {/*                        <div className="row">*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Стоимость:</h6>*/}
                    {/*                                <ul>*/}
                    {/*                                    <li>300 руб. до 3 000 руб.</li>*/}
                    {/*                                    <li>бесплатно от 3 000 руб.</li>*/}
                    {/*                                </ul>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Примерка:</h6>*/}
                    {/*                                <p>есть</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Время доставки:</h6>*/}
                    {/*                                <p>1-4 дня</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Способы оплаты:</h6>*/}
                    {/*                                <ul>*/}
                    {/*                                    <li>наличный расчёт</li>*/}
                    {/*                                    <li>безналичный расчёт</li>*/}
                    {/*                                </ul>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="delivery__list-block">*/}
                    {/*                        <h5 className="f-exp">Доставка в фирменный магазин BAON</h5>*/}
                    {/*                        <div className="row">*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Стоимость:</h6>*/}
                    {/*                                <p>бесплатно</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Примерка:</h6>*/}
                    {/*                                <p>есть</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Время доставки:</h6>*/}
                    {/*                                <p>2-3 дня</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Способы оплаты:</h6>*/}
                    {/*                                <ul>*/}
                    {/*                                    <li>Спасибо от Сбербанка</li>*/}
                    {/*                                    <li>Рассрочка Мокка</li>*/}
                    {/*                                    <li>Бонусная программа</li>*/}
                    {/*                                    <li>Подарочные сертификат</li>*/}
                    {/*                                </ul>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <a className="btn btn-secondary" href="">*/}
                    {/*                            Посмотреть адреса*/}
                    {/*                        </a>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="delivery__list-block">*/}
                    {/*                        <h5 className="f-exp">Самовывоз (Пятёрочка и Перекрёсток)</h5>*/}
                    {/*                        <div className="row">*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Стоимость:</h6>*/}
                    {/*                                <ul>*/}
                    {/*                                    <li>160 - 220 руб. до 3 000 руб.</li>*/}
                    {/*                                    <li>бесплатно от 3 000 руб.</li>*/}
                    {/*                                </ul>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Примерка:</h6>*/}
                    {/*                                <p>нет</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Время доставки:</h6>*/}
                    {/*                                <p>2-4 дня</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Способы оплаты:</h6>*/}
                    {/*                                <ul>*/}
                    {/*                                    <li>Спасибо от Сбербанка</li>*/}
                    {/*                                    <li>Наличный расчёт</li>*/}
                    {/*                                </ul>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <a className="btn btn-secondary" href="">*/}
                    {/*                            Посмотреть адреса*/}
                    {/*                        </a>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="delivery__list-block">*/}
                    {/*                        <h5 className="f-exp">Самовывоз (СДЭК)</h5>*/}
                    {/*                        <div className="row">*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Стоимость:</h6>*/}
                    {/*                                <ul>*/}
                    {/*                                    <li>180 руб. до 3 000 руб.</li>*/}
                    {/*                                    <li>бесплатно от 3 000 руб.</li>*/}
                    {/*                                </ul>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Примерка:</h6>*/}
                    {/*                                <p>есть</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Время доставки:</h6>*/}
                    {/*                                <p>1-2 дня</p>*/}
                    {/*                            </div>*/}
                    {/*                            <div className="col-lg-3">*/}
                    {/*                                <h6 className="f-exp">Способы оплаты:</h6>*/}
                    {/*                                <ul>*/}
                    {/*                                    <li>Спасибо от Сбербанка</li>*/}
                    {/*                                    <li>Наличный расчёт</li>*/}
                    {/*                                </ul>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <a className="btn btn-secondary" href="">*/}
                    {/*                            Посмотреть адреса*/}
                    {/*                        </a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                className="tab-pane fade"*/}
                    {/*                id="delivery-sect-2"*/}
                    {/*                role="tabpanel"*/}
                    {/*                aria-labelledby="delivery-tab-2"*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row" id="freeFit">
                        <div className="col">
                            <div className="delivery__info-list">
                                <div className="delivery__info-block bd">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h5 className="f-exp">
                                                Осуществляем доставку по всей России.
                                            </h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>
                                                Доставка по всей России БЕСПЛАТНО при покупке на сумму от 3 000 рублей! При полном отказе от покупки после примерки или при выкупе на сумму менее 3000 рублей услуги доставки оплачиваются согласно тарифной зоне.
                                            </p>
                                            <p>
                                                Заботимся о вашей безопасности. Курьерские службы, с которыми мы сотрудничаем, подтверждают безопасность доставки: сотрудники проходят инструктаж и осмотр перед выходом на маршрут. Для предоплаченных заказов действует услуга БЕСКОНТАКТНОГО ВРУЧЕНИЯ - по вашей просьбе курьер оставит пакет возле двери квартиры или на объекте охраны.
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>
                                                Заказы принимаются по телефону 8 (800) 500 7317 – с
                                                понедельника по воскресенье с 9:00 до 21:00. Через сайт
                                                заказы принимаются круглосуточно.
                                            </p>
                                            <p>
                                                Для жителей большинства крупных городов России доступна
                                                услуга примерки. Количество товаров, которое мы можем
                                                отправить вам для примерки, ограничено. Менеджеры
                                                call-центра уточнят информацию по вашему заказу в момент
                                                подтверждения по телефону и предложат удобный вариант. Если
                                                ваш заказ предоплачен на сайте, частичный или полный возврат
                                                заказа при доставке невозможен. Просим вас получить заказ
                                                полностью и произвести возврат в соответствии с правилами,
                                                указанными в разделе «Возврат товара».
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="delivery__info-block bd">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h5 className="f-exp">Бесплатная примерка</h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>
                                                Теперь услуга примерки доступна для жителей многих городов
                                                России! Возможность примерки товара в вашем городе уточняйте
                                                у операторов call-центра.
                                            </p>
                                            <p>
                                                При курьерской доставке, при получении заказа на пункте
                                                выдачи, а также в случае самовывоза из фирменного магазина
                                                Вы можете в течение 15 минут примерить вещи перед
                                                совершением покупки. Закажите несколько разных моделей или
                                                одну и ту же модель в нескольких размерах, купите только то,
                                                что вам подойдет, а остальные вещи верните продавцу или
                                                курьеру.
                                            </p>
                                            <p>
                                                Услуга примерки оказывается бесплатно (в некоторых случаях
                                                курьер может попросить оставить залоговую стоимость
                                                примеряемого товара). Доставка товара оплачивается на общих
                                                условиях (в случае полного отказа от покупки или при выкупе
                                                товара на сумму менее 3000 руб. во время курьерской доставки
                                                и при доставке в пункты выдачи заказов, необходимо оплатить
                                                услугу доставки согласно тарифной зоне доставки).
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p>
                                                Количество позиций, которое вы можете заказать на примерку,
                                                зависит от объема заказа и вида доставки. Обратите внимание:
                                                при выборе доставки в фирменный магазин в одном заказе может
                                                присутствовать не более 4 товаров. При курьерской доставке
                                                не более 4 пуховиков/курток/жилеток и/или не более 10
                                                товаров. Подробную информацию по действующему ограничению
                                                уточняйте у операторов call-центра.
                                            </p>
                                            <p>
                                                Если вы оплачивали товар через систему электронных платежей,
                                                частичный или полный возврат курьеру невозможен. Необходимо
                                                получить заказ у курьера полностью и произвести возврат в
                                                соответствии с правилами, указанными в разделе «возврат
                                                товара».
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}