import {Link, useNavigate, useOutletContext} from "react-router-dom";
import {CustomerCredentialResponse} from "../../../interfaces/api/CustomerCredentialResponseInterface";
import {data} from "jquery";
import {CustomerInterface} from "../../../interfaces/customer/CustomerInterface";
import {logout} from "../../../api/customer";
import {useContext} from "react";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {LOCAL_STORAGE_KEY_CUSTOMER} from "../../../Config";

export default function Navigation({customer}: { customer: CustomerInterface }) {
    const BASE_URL = '/personal-area';

    const credentials: CustomerCredentialResponse = useOutletContext();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const navigate = useNavigate();

    const getAvatarLetter = () => {
        return customer.first_name?.substring(0, 1) + customer.last_name?.substring(0, 1)
    }

    const navigations: { name: string, path: string }[] = [
        {
            name: 'Главная',
            path: BASE_URL + '/'
        },
        {
            name: 'Профиль',
            path: BASE_URL + '/profile'
        },
        {
            name: 'Заказы',
            path: BASE_URL + '/orders'
        },
        {
            name: 'Бонусы и промокоды',
            path: BASE_URL + '/privilege'
        },
        {
            name: 'Избранное',
            path: BASE_URL + '/favorite'
        },
        // {
        //     name: 'Возвраты',
        //     path: BASE_URL + '/purchase-returns'
        // },
    ]

    const logoutAction = () => {
        // logout().then(response => {
        //
        // })

        let customer = state.customer
        customer.id = null
        customer.sign = null

        localStorage.removeItem(LOCAL_STORAGE_KEY_CUSTOMER)
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'customer', payload: {id: null, sign: null}})

        window.location.href = '/'
    }

    return (<>
        <div className="lk__menu-user f-exp">
            <div className="photo">
                <h4>{getAvatarLetter()}</h4>
            </div>
            <div className="name">
                <h5>{customer.first_name} {customer.last_name} </h5>
            </div>
            <div className="exit mob"><i className="ico ico-exit" onClick={() => {
                logoutAction()
            }}></i></div>
        </div>
        <ul className="nav nav-tabs f-exp">
        {navigations.map(navigation => <li key={navigation.name} className="nav-item">
                <Link className="nav-link" to={navigation.path}>{navigation.name}</Link>
            </li>)}

            <li className="nav-item desk">
                <button
                    className="nav-link"
                    type={"button"}
                    onClick={() => {
                        logoutAction()
                    }}
                >
                    Выйти
                </button>
            </li>
        </ul>
    </>)
}