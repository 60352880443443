import {BasketPositionResponseInterface} from "../../interfaces/checkout/BasketPositionResponseInterface";
import {LocationInterface} from "../../interfaces/checkout/LocationInterface";
import {DeliveryInterface} from "../../interfaces/checkout/DeliveryInterface";
import CurrencyInterface from "../../interfaces/core/CurrencyInterface";
import MoneyInterface from "../../interfaces/core/MoneyInterface";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";

export const positonQuantity = (positions: BasketPositionResponseInterface[]) => {
    return positions.reduce((prev, current) => {
        return prev + current.quantity
    }, 0)
}

export const deliveryCostValueHelper = (location: LocationInterface|null, delivery: DeliveryInterface|null) => {
    let deliveryCostValue = 0;

    if(location?.price.amount){
        deliveryCostValue = location.price.amount
    }else if(delivery?.first_customer_delivery_courier?.price?.amount){
        deliveryCostValue = delivery.first_customer_delivery_courier.price.amount
    }

    return deliveryCostValue
}

export const totalHelper = (positions: BasketItemInterface[], writeOffBonuses: number, payedByGiftSertificates: MoneyInterface,  deliveryCost: MoneyInterface, currency: CurrencyInterface): MoneyInterface => {
    let totalValue = positions.reduce((prev, curpos) => {
        return prev + curpos.modelColorItem.min_price.amount * curpos.quantity
    }, 0)

    if(writeOffBonuses){
        totalValue -= writeOffBonuses * 100
    }

    totalValue += deliveryCost.amount

    if(payedByGiftSertificates.amount){
        totalValue -= payedByGiftSertificates.amount
    }

    return {
        amount: totalValue,
        currency: currency

}
}

export const totalBaseHelper = (positions: BasketItemInterface[], deliveryCost: MoneyInterface, currency: CurrencyInterface) => {
    let totalValue = positions.reduce((prev, curpos) => {
        return prev + (curpos.modelColorItem.max_price || curpos.modelColorItem.min_price).amount  * curpos.quantity
    }, 0)

    totalValue += deliveryCost.amount

    return {
        amount: totalValue,
        currency: currency
    }
}

export const accuralSumHelper = (positions: BasketPositionResponseInterface[], currency: CurrencyInterface) => {
    let totalValue = positions.reduce((prev, curpos) => {
        return prev + curpos.loyalty_calculation_result?.accrual_sum.amount
    }, 0)

    return {
        amount: totalValue,
        currency: currency
    }
}