import {useQuery} from "@tanstack/react-query";
import {getModelBySearchString} from "../../api/catalog";
import CatalogList from "./CatalogList";
import {useContext, useEffect, useState} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function CatalogListBySearchString() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext)

    const {data: modelColorItemsData} = useQuery({
        queryKey: ['catalog', 'search', state.searchHistory[0] || null],
        queryFn: () => getModelBySearchString(state.searchHistory[0] || undefined),
        enabled: state.searchHistory.length > 0,
        retry: false
    })

    const {
        model_color_items: modelColorItems,
        meta,
        category,
        breadcrumbs,
        banners,
        tags,
        super_categories,
        categories,
        properties
    } = modelColorItemsData || {
        model_color_items: [],
        super_categories: [],
        categories: [],
        meta: undefined,
        properties: []
    }

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: modelColorItemsData?.meta})
    }, [modelColorItemsData?.meta]);


    return (<CatalogList modelColorItems={modelColorItems}  category={category} breadcrumbs={breadcrumbs}
                         banners={banners} superCategories={super_categories} categories={categories} tags={tags} properties={properties} filterTags={[]}></CatalogList>)
}