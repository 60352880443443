import fetchData from "../utils/HttpClient";
import {ItemInterface as FashionBlogItemInterface} from "../interfaces/fashion-blog/ItemInterface";
import {BlogResponseInterface} from "../interfaces/api/BlogResponseInterface";
import PageMetaInterface from "../interfaces/core/PageMetaInterface";
import BreadcrumbInterface from "../interfaces/core/BreadcrumbInterface";

const basePathFashionBlog = '/api/fashion-blog';

export function getBlogItems(): Promise<BlogResponseInterface> {
    return fetchData(basePathFashionBlog + '/list')
}

export function getBlogItemBySlug(path: string): Promise<{ post: FashionBlogItemInterface, meta: PageMetaInterface, breadcrumbs: BreadcrumbInterface[] }> {
    return fetchData(basePathFashionBlog + '/item', {path: path}, 'POST')
}