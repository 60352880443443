import React, {useContext, useState, useEffect} from 'react'
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import LinkExtendedInterface from "../../../interfaces/core/LinkExtendedInterface";
import DataInterface from "../../../interfaces/header/DataInterface";
import {Link} from "react-router-dom";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import MenuLinkInterface from "../../../interfaces/header/MenuLinkInterface";

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';


// -------- / SELECTED LINE / ----------
interface SelectedCollectionProps {
    selectedCollection: LinkExtendedInterface
}

const SelectedCollection = ({selectedCollection}: SelectedCollectionProps) => {
     return (
        <div className="collapse__menu--block">
            <div className="collapse__menu-kv">
                <a href={selectedCollection?.url}>
                    <img src={selectedCollection?.image} alt="" />
                    <h3 className="f-exp">{selectedCollection?.name}</h3>
                    <h5 className="collapse__menu-subtitle">{selectedCollection?.description}</h5>
                </a>
            </div>
        </div>
    )
}

// -------- \ SELECTED LINE \ ----------


interface MenuProps {
    menu: DataInterface
    additionalLinks: MenuLinkInterface[]
    isShow: boolean;
    hideMobMenu: () => void; 
    selectedMobMenu: string;
    setSelectedMobMenu: (menu: string) => void;
}

const Menu = ({menu, additionalLinks, isShow, hideMobMenu, selectedMobMenu, setSelectedMobMenu }: MenuProps) => {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const [selectedCollection, setSelectedCollection] = useState(menu.collections[0]);
    // const [selectedMobMenu, setSelectedMobMenu] = useState('');

    const getSplitedClotheMenu = (data: DataInterface) => {
        return [
            data.menu.slice(0, data.breakMenu),
            data.menu.slice(data.breakMenu, data.menu.length)
        ]
    }

    const showMobMenu = (menuName: string) => {
        setSelectedMobMenu(menuName)
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'hideNavbar', payload: true})
    }

    // const hideMobMenu = () => {
    //     setSelectedMobMenu('')
    //     dispatch({type: ReducerActionType.SET_PARAM, paramName: 'hideNavbar', payload: false})
    // }

    

    const closeMenu = () => {
        dispatch({type: ReducerActionType.SET_HEADER_MENU, payload: null})
    }

    // useEffect(() => {
    //     $('.scrollbar-inner').scrollbar({ "scrollx": "advanced", "scrolly": "advanced" });
    // }, []);

    return (<div className={"collapse__menu collapse " + (isShow ? ' active show ' : '')}>
        <div className="collapse__menu-wrap">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 desk">
                        <div className="collapse__menu--block">
                            <SelectedCollection selectedCollection={selectedCollection}/>
                        </div>
                    </div>
                      <div className="col-lg-9">
                         <SimpleBar style={{ maxHeight: 2000 }}  autoHide={false} className='simple__bar'>
                                            <div className="row scrollbar-inner">           
                    <div className="col-lg-4">
                        <ul className="collapse__menu-list--big">
                            {menu.links.map(item => <li key={item.name}><Link onClick={() => {
                                closeMenu()
                            }} className={"f-exp " + item.color} to={item.url}>{item.name}</Link></li>)}
                        </ul>
                        <ul className="collapse__menu-list--big desk">
                            {menu.collections.map((item, index) => <li key={item.name}>
                                {!index && <h3>коллекции</h3>}
                                <Link onClick={() => {
                                    closeMenu()
                                }} className="f-exp" to={item.url} onMouseOver={() => setSelectedCollection(item)}>{item.name}</Link>
                                <p className="desk">{item.description}</p>
                            </li>)}
                        </ul>
                        {/*<ul className="collapse__menu-list--big desk">*/}
                        {/*    {menu.lines.map((item, index) => <li key={item.name}>*/}
                        {/*        {!index && <h3>линейки</h3>}*/}
                        {/*        <Link onClick={() => {*/}
                        {/*            closeMenu()*/}
                        {/*        }} className="f-exp" to={item.url}*/}
                        {/*              onMouseOver={() => setSelectedCollection(item)}>{item.name}</Link>*/}
                        {/*        <p className="desk">{item.description}</p>*/}
                        {/*    </li>)}*/}
                        {/*</ul>*/}
                    </div>
                    {getSplitedClotheMenu(menu).map((items, index) =>
                        <div key={index} className="col-lg-4">
                            {items.map((item, sindex) =>
                                <React.Fragment key={item.name}>
                                    <h4 className="collapse__menu-btn" onClick={() => {
                                        showMobMenu(item.name)
                                    }}>{item.name}</h4>
                                    <div
                                        className={"collapse__menu-list--small-wrap outer " + (selectedMobMenu === item.name ? 'active' : '')}>
                                        <div className="mob"><p className="back ico-cont f-exp"><i
                                            className="ico ico-left ico-arrow-left-b" onClick={() => {
                                            setSelectedMobMenu('');
                                            hideMobMenu()
                                        }}></i>{item.name}</p></div>
                                        <ul className="collapse__menu-list--small mb--big">
                                            {item.items.map(sitem => <li key={sitem.name}><Link onClick={() => {
                                                hideMobMenu()
                                                closeMenu()
                                            }} className="next" to={sitem.url} data-div="div1-1">{sitem.name}</Link>
                                            </li>)}
                                            <li className="last"><Link className="f-exp" to={item.url}>Смотреть
                                                все</Link></li>
                                        </ul>
                                    </div>
                                    <div className="collapse__menu-list--small-wrap inner mob">
                                        <p className="back ico-cont f-exp"><i className="ico ico-left ico-arrow-left-b"
                                                                              onClick={() => {
                                                                                  hideMobMenu()
                                                                              }}></i>{item.name}</p>
                                        <ul className="collapse__menu-list--small mb--big" id="div1-1">
                                            {item.items.map(sitem => <li key={sitem.name}><Link onClick={() => {
                                                closeMenu()
                                            }} to={sitem.url}>{sitem.name}</Link></li>)}
                                        </ul>
                                    </div>
                                    {(index == menu.breakMenu - 1) && (sindex == items.length - 1) && <>
                                        <ul className="collapse__menu-list--big mt--top mob">
                                                    {menu.collections.map((item) => (
                                                        <li key={item.name}>
                                                            <Link
                                                                onClick={() => {
                                                                    closeMenu();
                                                                }}
                                                                className={"f-exp " + item.color}
                                                                to={item.url}
                                                            >
                                                                {item.name}
                                                                <p className="collection-desc--mob">
                                                                    {item.description}
                                                                </p>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                        <ul className="collapse__menu-list--big mob">
                                            
                                            {/*<li>*/}
                                            {/*    <h3>линейки</h3>*/}
                                            {/*    <div className="collapse__menu-slider collapse__menu--lines">*/}
                                            {/*        {menu.lines.map(item => <div key={item.name} className="slide">*/}
                                            {/*            <img src={item.image} alt=""/>*/}
                                            {/*            <h3 className="f-exp">{item.name}</h3>*/}
                                            {/*            <p>{item.description}</p>*/}
                                            {/*        </div>)}*/}
                                            {/*    </div>*/}
                                            {/*</li>*/}
                                            <li>
                                                <h3>Коллекции</h3>
                                                <div className="collapse__menu-slider collapse__menu--collections">
                                                    {menu.collections.map(item => <a  href={item.url} key={item.name}
                                                                                       className="slide">
                                                        <img src={item.image} alt=""/>
                                                        <h3 className="f-exp">{item.name}</h3>
                                                        <h5 className="f-exp">{item.description}</h5>
                                                        </a>
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="collapse__menu-list--big mob">
                                            {additionalLinks.filter(item => item.showOnMobile).map((menu: MenuLinkInterface) => <li key={menu.name}
                                                                                                className={"nav-item mob "}>
                                                <Link  className="f-exp" onClick={() => {
                                                    closeMenu()
                                                }}
                                                      to={menu.url}>{menu.name}</Link>
                                            </li>)}
                                        </ul>
                                    </>}
                                </React.Fragment>)}
                        </div>)}
</div>
</SimpleBar>
</div>

                </div>
                <div className="navbar-city-cont mob">
                    <div className="wrap">
                        <a className="navbar-city ico-cont" href="/" onClick={(event) => {
                            event.preventDefault()
                            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showRegionFinder', payload: true})
                        }}><i className="ico ico-point"></i>{state.region.clear_name || 'Выберите город'}
                        </a>
                        <a className="ico-cont" href="/" onClick={(event) => {
                            event.preventDefault()
                            dispatch({type: ReducerActionType.SET_PARAM, paramName: 'showRegionFinder', payload: true})
                        }}><i className="ico ico-edit"></i></a>
                    </div>
                </div>
                <a className="collapse__menu-close desk" onClick={closeMenu}>
                    <i className="ico ico-close-white"></i>
                </a>
            </div>
        </div>
    </div>)
}
export default Menu