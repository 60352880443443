import ModelPropertyInterface from "../../../interfaces/model/ModelPropertyInterface";
import {isObject} from "lodash";
import ModelInterface from "../../../interfaces/model/ModelInterface";

export default function Properties({model, mode}: {
    model: ModelInterface,
    mode: "prop" | "desc"
}) {

    const groupedProperties = () => {
        const grouped: {[key: string]: string[]} = {}

        let properties = model.model_properties

        if(isObject(properties)) {
            properties = Object.values( model.model_properties)
        }

        properties.map(property => {
            if (!grouped[property.property_name]) {
                grouped[property.property_name] = []
            }
            grouped[property.property_name].push(property.value)
        })

        const out = []


        for (const [key, value] of Object.entries(grouped)) {
            out.push({
                name: key,
                value: value.join(', ')
            })
        }

        return out
    }

    const getMaterialCount = () => {
        return  model?.extra_param?.materials.length
    }

    return (<ul>
        {mode === 'prop' && groupedProperties().slice(0, 5 - getMaterialCount()).map(property => <li key={property.name}><p><span>{property.name}</span><span>{property.value}</span></p></li>)}
        {mode === 'prop' &&  model?.extra_param?.materials.map(material => <li key={material.name}><p><span>{material.name}</span><span>{material.value}</span></p></li>)}
        {mode === 'desc' && groupedProperties().map(property => <li key={property.name}><p>{property.name}</p><p>{property.value}</p></li>)}
        {mode === 'desc' &&  model?.extra_param?.materials.map(material => <li key={material.name}><p>{material.name}</p><p>{material.value}</p></li>)}
    </ul>)
}