import styles from './Spacer.module.scss'

function SpacerMobile() {
	return (
		<>
			<div className={styles.spacer}></div>
		</>
	)
}

export default SpacerMobile
