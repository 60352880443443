export default function Stars({reviewAverageEstimate}:{reviewAverageEstimate: number}) {
    const getActivaStars = (estimate: number): null[] => {
        return Array(Math.floor(estimate)).fill(null)
    }

    const getNonActiveStars = (estimate: number) => {
        return Array(Math.floor(5 - Math.floor(estimate))).fill(null)
    }

    return (<ul className="stars">
        {getActivaStars(reviewAverageEstimate).map((_, index) => <li key={index} className="desk"><i className="active"></i></li>)}
        {getNonActiveStars(reviewAverageEstimate).map((_, index) => <li key={index} className="desk"><i></i></li>)}
        <li className="mob">
            <p>{reviewAverageEstimate}</p>
        </li>
    </ul>)
}