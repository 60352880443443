import {OrderInterface} from "../../interfaces/dealer/OrderInterface";
import CreateOrderPosition from "./CreateOrderPosition";
import {OrderItemGroupedByModel} from "../../interfaces/dealer/create/OrderItemGroupedByModel";
import Money from "../core/Money";
import MoneyInterface from "../../interfaces/core/MoneyInterface";
import React, {useContext, useEffect, useState} from "react";
import {createOrder} from "../../api/dealer";
import {OrderInterface as OrderForCreateInterface} from "../../interfaces/dealer/create/OrderInterface";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {useNavigate, useOutletContext} from "react-router-dom";
import {OutletInterface} from "../../interfaces/dealer/OutletInterface";
import {ManagerInterface} from "../../interfaces/dealer/ManagerInterface";
import OrderStatisticForCreateOrder from "./OrderStatisticForCreateOrder";

export default function CreateOrder({order, setOrder, loadOrderPositions}: {
    order: OrderForCreateInterface,
    setOrder: (order: OrderForCreateInterface) => void,
    loadOrderPositions: () => void
}) {

    const navigate = useNavigate();

    const outletContext = useOutletContext<OutletInterface>();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [createdOrder, setCreatedOrder] = useState<OrderInterface | undefined>(undefined)
    const [isOrderShow, setIsOrderShow] = useState(false)
    const [selectedManager, setSelectedManager] = useState<ManagerInterface | undefined>(undefined)

    useEffect(() => {
        setIsOrderShow(true)
    }, []);

    const getPositionsGroupedByModel = () => {
        let positionsGrouped: OrderItemGroupedByModel[] = []
        order.positions.forEach(position => {
            const model = positionsGrouped.find(group => group.model.id === position.model.id)
            if (model) {
                model.positions.push(position)
            } else {
                positionsGrouped.push({model: position.model, positions: [position]})
            }
        })
        return positionsGrouped
    }

    const getTotalQuantity = () => {
        return order.positions.reduce((acc, position) => acc + position.quantity, 0)
    }

    const getTotalSumm = () => {
        const total = order.positions.reduce((acc, position) => acc + position.quantity * (position.model.dealer_price?.amount || 0), 0)
        return {
            amount: total,
            currency: order.positions[0].model.dealer_price?.currency || 'RUB'
        } as MoneyInterface
    }

    const editOrder = () => {
        setIsOrderShow(false)
        loadOrderPositions()
    }

    const saveOrder = () => {
        dispatch({type: ReducerActionType.LOAD})
        if (!order.positions?.length) {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'Заказ не содержит позиций'
            })
            return
        }
        order.manager = selectedManager
        if (!order.manager) {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'Необходимо указать менеджера по заявке'
            })
            return
        }
        createOrder(order).then((response) => {
            setCreatedOrder(response)
            setOrder({comment: '', positions: [], manager: undefined})
            dispatch({type: ReducerActionType.SUCCESS})
            outletContext.updateOrderList()
        }).catch(error => {
            dispatch({
                type: ReducerActionType.ERROR,
                payload: 'При создании заказа возникла ошибка: ' + (error.response?.data?.message || error.response?.data?.detail)
            })
        })
    }

    return (
        <div className={'row'}>
            {createdOrder && <div className="col-lg-8 offset-lg-2 col-sm-12">
                <div
                    className={'h3 text-center m-5'}>Заявка {createdOrder.id} успешно {order.id ? 'сохранена' : 'оформлена'}</div>
                <div className={'text-center'}>
                    <button className={'btn btn-secondary'} onClick={() => {
                        setCreatedOrder(undefined)
                    }}>Новая заявка
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className={'btn btn-secondary-tr'} onClick={() => {
                        navigate('/dealer/orders')
                    }}>К списку заказов
                    </button>
                </div>
                <hr/>
            </div>}

            {order.positions.length > 0 && <div className="col-lg-8 offset-lg-2 col-sm-12">
                <div>
                    <div className={'dashed pointer text-end'} style={{padding: '0.5rem'}} onClick={() => {
                        setIsOrderShow(!isOrderShow)
                    }}>
                        <button className={'btn btn-secondary btn-small'}>{isOrderShow ? 'Скрыть' : 'Показать'} заявку
                        </button>
                    </div>

                    <div className={'h4 text-end '}>Ваша текущая заявка: <Money
                        value={getTotalSumm()}/> / {getTotalQuantity()} шт.
                    </div>
                </div>

                <hr/>

                {isOrderShow && <div>
                    <h1>Ваша текущая заявка {order.id}</h1>
                    <table className={'table table-striped'}>
                        <thead>
                        <tr>
                            <td>Фото</td>
                            <td>Артикул</td>
                            <td>Название</td>
                            <td>Цена</td>
                            <td>Кол-во</td>
                            <td>Позиции</td>
                            <td>Сумма</td>
                        </tr>
                        </thead>
                        <tbody>
                        {getPositionsGroupedByModel().map(item => <CreateOrderPosition key={item.model.id}
                                                                                       item={item}/>)}
                        </tbody>
                        <thead>
                        <tr className={'h4'}>
                            <th colSpan={4} className={'py-3'}>ИТОГО</th>
                            <th className={'py-3'}>{getTotalQuantity()}</th>
                            <th>&nbsp;</th>
                            <th className={'text-end py-3'}><Money value={getTotalSumm()}/></th>
                        </tr>
                        </thead>
                    </table>
                    <div>
                        <div><b>Ваши пожелания к заявке:</b></div>
                        <textarea className={'form-control'} style={{height: '120px'}} value={order.comment}
                                  onChange={(event) => {
                                      setOrder({
                                          ...order,
                                          comment: event.target.value
                                      })
                                  }}></textarea>
                    </div>
                    <div className={'mt-3'}>
                        <b>Менеджер</b>
                        <select className={'form-control'} onChange={(event) => {
                            const manager = outletContext.ordersData?.managers.find(manager => manager.id === parseInt(event.currentTarget.value))
                            if (manager) {
                                setSelectedManager(manager)
                            }
                        }}>
                            <option value="">-- выберите менеджера --</option>
                            {outletContext.ordersData?.managers.map(manager => <option key={manager.id}
                                                                                       value={manager.id}>{manager.fio}</option>)}
                        </select>
                    </div>


                    <div>
                        <button className={'btn btn-secondary btn-lg mt-3 mr-3'}
                                onClick={saveOrder}>{order?.id ? 'Сохранить' : 'Оформить'} заявку
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className={'btn btn-secondary btn-secondary-tr btn-lg mt-3'}
                                onClick={editOrder}>Редактировать заявку
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button className={'btn btn-danger btn-lg mt-3 mr-3 pull-right'} onClick={() => {
                            const localOrder = {...order}
                            localOrder.positions = []
                            localOrder.comment = ''
                            localOrder.id = undefined
                            setOrder(localOrder)
                        }}>Очистить заявку
                        </button>
                    </div>
                    <div className={'mt-5'}>
                        <OrderStatisticForCreateOrder order={order} />
                    </div>


                </div>}

            </div>}

        </div>
    )

}