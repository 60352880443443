import Money from "../core/Money";
import {INTERNET_SHOP_ID} from "../../Config";
import {ecommerceDataLayer, ecommerceDataLayerGA4} from './utils/marketing/Service';
import React, {useContext, useState} from "react";
import {getBaseImagePath} from "../catalog/Utils";
import {BasketItemInterface} from "../../interfaces/core/BasketItemInterface";
import {Link} from "react-router-dom";
import useModelColorItem from "../catalog/useModelColorItem";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../GlobalReducer";


function BasketItem({position, setPosition, removePosition, isSelected, setSelectedStatus}: {position: BasketItemInterface, setPosition: Function, removePosition: Function, isSelected: boolean, setSelectedStatus: Function}) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const {toggleFavorite, isExistInFavorite} = useModelColorItem(position.modelColorItem)

    const [showSabInfo, setShowSabInfo] = useState(false);

    const currentImage = () => {
        if (!position.modelColorItem.images.length) {
            return null;
        }
        let currentImage = position.modelColorItem.images.find(image => image.type === 'front')
        if(currentImage?.url){
            currentImage.url = currentImage?.url.replace(/thumbnail/, 'small')  // TODO: заменить на беке
        }
        return currentImage
    }

    const changeQuantity = (position: BasketItemInterface, quantity: number) => {
        position.quantity = quantity
        position.createdAt = new Date()
        setPosition(position)
    }

    const removePositionInternal = (position: BasketItemInterface) => {
        removePosition(position)
        // ecommerceDataLayer('remove', 'removeFromCart', [position])
        // ecommerceDataLayerGA4('remove_from_cart', [position])
    }

    const incrementQuantity = () => {
        if (isIncrementDisabled()) {
            return
        }
        changeQuantity(position, position.quantity+1)
    }

    const decrementQuantity = () => {
        // eslint-disable-next-line
        if (position.quantity == 1) return
        changeQuantity(position, position.quantity-1)
    }

    const isIncrementDisabled = () => {
        return !position.currentStock || position.currentStock == position.quantity
    }

    const isDecrementDisabled = () => {
        // eslint-disable-next-line
        return position.quantity == 1
    }

    return (<div className={"basket__products-block " + (!position.quantity && 'bg-danger') }>
        <div className={"form-checkbox " + (isSelected && ' active')}>
            <label
                className="label-dot"
                onClick={() => {setSelectedStatus(position)}}
            />
        </div>
        <div className="img">
            <img src={getBaseImagePath() + currentImage()?.url} alt=""/>
        </div>
        <div className="info">
            <div className="text">
                <div className="left">
                    <h4 style={{width: '100%'}}><Link
                        to={`/catalog/${position.modelColorItem.model_id}/${position.modelColorItem.special_transliterated_name.toLowerCase()}/${position.modelColorItem.color_special.toLowerCase()}/`}>{position.modelColorItem.name}</Link> </h4>
                    <ul>
                        <li>Размер: {position.size}</li>
                        <li>Цвет: {position.color}</li>
                        <li>Артикул: {position.modelColorItem.articul}</li>
                    </ul>

                    {state.customer?.id && state.customer?.minda && <div>
                        <span className={'pointer dashed'} onClick={(e) => {setShowSabInfo(!showSabInfo); e.preventDefault();}}>показать информацию из САБ</span>
                        {showSabInfo && <div className={'border p-3 m-2 rounded-3'}>
                            <div><b>Discount Comment:</b> {position.responseItem?.response_item?.discount_comment}</div>
                            <div><b>Discount Percent:</b> {position.responseItem?.response_item?.discount_percent}</div>
                            <div><b>Discount Percent:</b> {position.responseItem?.response_item?.discount_percent}</div>
                            <div><b>Price:</b> {position.responseItem?.response_item?.price}</div>
                            <div><b>Base Price:</b> {position.responseItem?.response_item?.base_price}</div>
                            <div><b>Type Discount:</b> {position.responseItem?.response_item?.type_discount}</div>
                            <div><b>UID Discount Reason:</b> {position.responseItem?.response_item?.uid_discount_reason}
                            </div>
                        </div>}
                    </div>}

                    {/*<p>Осталось 2 штуки</p>*/}
                    {(position.isSpecialDelivery) && <div className="small text-muted mt-3">
                        Товар из ассортимента розничного магазина доставляется отдельно курьером или в пункты выдачи
                        СДЭК
                    </div>}
                </div>
                <div className="right f-exp">
                    {position.modelColorItem.min_price.amount < position.modelColorItem.max_price.amount && <>
                        <p className="discount"><Money value={position.modelColorItem.min_price}/></p>
                        <p className="old"><Money value={position.modelColorItem.max_price}/></p>
                    </>}
                    {position.modelColorItem.min_price.amount >= position.modelColorItem.max_price.amount && <>
                        <p><Money value={position.modelColorItem.min_price}/></p>
                    </>}
                </div>
            </div>
            <div className="meta">
                <div className="left">
                    <a className="ico-cont" href={'/'} onClick={(event) => {
                        event.preventDefault()
                        toggleFavorite()
                    }}>
                        <i className={"ico ico-left ico-heart " + (isExistInFavorite() && "active")}/>
                        <span className="desk" >В избранное</span>
                    </a>
                    <a className="ico-cont" href="/">
                        <i className="ico ico-left ico-trash" onClick={(event) => { event.preventDefault(); removePositionInternal(position) }}/>
                        <span className="desk" onClick={(event) => { event.preventDefault(); removePositionInternal(position) }}>Удалить</span>
                    </a>
                </div>
                <div className="right">
                    <div className="product-numbers">
                        <i className={"option opt--value opt--menus " + (isDecrementDisabled() ? 'disabled' : '')} onClick={decrementQuantity}/>
                        <input
                            className="option opt--number"
                            type="text"
                            value={position.quantity}
                            onChange={(event) => { changeQuantity(position, parseInt(event.currentTarget.value)) }}
                            min={1}
                            max={99}
                            maxLength={2}
                        />
                        <i className={"option opt--value opt--plus " + (isIncrementDisabled() ? 'disabled' : '')} onClick={incrementQuantity}/>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default BasketItem