import Spacer from "../Spacer/Spacer.jsx";
import styles from "./TextMarkdown.module.scss";
import Markdown from "markdown-to-jsx";

const TextMarkdown = (props) => {
	const blockClasses = props.params.clsArray;
	const text = props.params.text;

	return (
		<>
			<div
				className={
					blockClasses
						? "mblock " + blockClasses.join(" ")
						: "mblock text-center w70 font-size-110 line-height-15"
				}
			>
				<div className={styles.textEl} />
				{text && <Markdown options={{forceBlock: true}}>{text}</Markdown>}
			</div>
			<Spacer />
		</>
	);
};

export default TextMarkdown;
