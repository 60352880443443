export default function DeliveryAddresses() {
    return (<div
        className="tab-pane fade show active"
        role="tabpanel"
    >
        <div className="lk__address-list">
            <div className="lk__address">
                <div className="lk__address-title f-exp desk">
                    <h4>Курьерская доставка</h4>
                    <a href="">+ Добавить адрес</a>
                </div>
                <div className="lk__address-row swiper-initialized swiper-horizontal">
                    <div
                        className="swiper-wrapper"
                        style={{
                            transitionDuration: "0ms",
                            transitionDelay: "0ms"
                        }}
                    >
                        <div className="swiper-slide bd">
                            <div className="head">
                                <a className="lk__address-main ico-cont" href="">
                                    <i className="ico ico-left ico-check-circle"/>
                                    Основной адрес
                                </a>
                                <div className="lk__address-dots">
                                    <i className="lk__address-btn ico ico-dots"/>
                                    <ul
                                        className="lk__address-col"
                                        style={{display: "none"}}
                                    >
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="ico ico-left ico-edit"/>
                                                Редактировать
                                                <i className="ico ico-right ico-chevron-right-b"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="ico ico-left ico-trash"/>
                                                Удалить
                                                <i className="ico ico-right ico-chevron-right-b"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="body">
                                <p>г. Москва, Дербеневская наб., д. 7, стр. 9</p>
                            </div>
                        </div>
                        <div className="swiper-slide bd">
                            <div className="head">
                                <a className="lk__address-main ico-cont" href="">
                                    Сделать основным
                                </a>
                                <a
                                    className="lk__address-dots ico ico-dots"
                                    href=""
                                />
                            </div>
                            <div className="body">
                                <p>
                                    Санкт-Петербург, 18-я линия Васильевского острова,
                                    д. 29, корпус Е, офис 404
                                </p>
                            </div>
                        </div>
                        <div className="swiper-slide bd">
                            <div className="head">
                                <a className="lk__address-main ico-cont" href="">
                                    Сделать основным
                                </a>
                                <a
                                    className="lk__address-dots ico ico-dots"
                                    href=""
                                />
                            </div>
                            <div className="body">
                                <p>
                                    Санкт-Петербург, 18-я линия Васильевского острова,
                                    д. 29, корпус Е, офис 404
                                </p>
                            </div>
                        </div>
                        <div className="swiper-slide bd">
                            <div className="head">
                                <a className="lk__address-main ico-cont" href="">
                                    Сделать основным
                                </a>
                                <a
                                    className="lk__address-dots ico ico-dots"
                                    href=""
                                />
                            </div>
                            <div className="body">
                                <p>
                                    Lorem ipsum, dolor sit amet consectetur
                                    adipisicing elit. Laboriosam ut illum nesciunt
                                    recusandae, hic consequatur architecto, ullam
                                    repellat, magni voluptatem quibusdam cupiditate
                                    rerum atque maxime praesentium saepe eveniet
                                    ducimus odio ipsam nemo in exercitationem nostrum
                                    nihil mollitia! Quae, praesentium quos eveniet
                                    consequatur nulla, fugit quas, ullam esse hic
                                    vitae sequi!
                                </p>
                            </div>
                        </div>
                    </div>
                    <span
                        className="swiper-notification"
                        aria-live="assertive"
                        aria-atomic="true"
                    />
                </div>
            </div>
        </div>
    </div>)
}