import Breadcrumbs from "../Breadcrumbs";
import {useState, useContext} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";



export default function Payment() {

        const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
        const [block, setBlock] = useState('')


    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Оплата'
            }
        ]
    }

    return (<main className="main-payment">
            <section className="text-page pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs()} />
                        </div>
                    </div>
                    <div className="row text-page__wrap">
                        <div className="col-lg-6 text-page__left f-exp">
                            <h1 className="text-page__title">Способы оплаты</h1>
                            <blockquote>
                                Способ оплаты заказа зависит от выбираемого вами вида доставки, а
                                также страны покупателя/доставки. При оформлении заявки на нашем
                                сайте Вам автоматически будет предложено несколько вариантов оплаты.
                            </blockquote>
                        </div>
                        <div className="col-lg-6 text-page__right">
                            <div className="text-page__list">
                                <div className={"text-page__list-block information bd "  + (block === 't1' ? 'show' : '')}>
                                      <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't1' ? '' : 't1')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-map"/>
                                        </div>
                                        <p>Получение заказа в фирменном магазине</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    
                                      {block === 't1' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <ul>
                                                <li>
                                                    Оплата производится наличными или картой на кассе
                                                    магазина.
                                                </li>
                                                <li>
                                                    Расчет ведется согласно ценам, указанным в акте
                                                    сдачи-приемки.
                                                </li>
                                                <li>
                                                    Вы можете воспользоваться бонусными баллами «СПАСИБО» от
                                                    Сбербанка и оплатить ими до 50% покупки.
                                                </li>
                                                <li>
                                                    В качестве подтверждения покупки кассир выдаст вам
                                                    кассовый чек.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>}
                                </div>
                               <div className={"text-page__list-block information bd "  + (block === 't2' ? 'show' : '')}>
                                      <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't2' ? '' : 't2')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-car-2"/>
                                        </div>
                                        <p>Доставка курьером или в пункт самовывоза</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't2' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Оплата наличными или картой курьеру</h5>
                                            <p>
                                                Производится в присутствии курьера, осуществляющего доставку
                                                товара. Перед оплатой обязательно сверьте комплектацию
                                                заказа. Расчет ведется согласно ценам, указанным в акте
                                                сдачи-приемки. В качестве подтверждения покупки курьер
                                                оставит вам кассовый чек и акт сдачи-приемки.
                                            </p>
                                            <p>
                                                В некоторых случаях курьер может иметь с собой терминал для
                                                приема платежей банковской картой. Вы можете оплатить свой
                                                заказ картой согласно условиям, описанным выше.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Оплата через систему Яндекс.Касса</h5>
                                            <p>
                                                К оплате принимаются банковские карты VISA, MasterCard,
                                                Maestro и МИР.
                                            </p>
                                            <p>
                                                Вы формируете заказ на сайте интернет-магазина и выбираете
                                                Online оплату. Интернет-магазин переадресует Вас на
                                                защищенную форму оплаты процессингового центра Яндекс.Касса,
                                                где вам нужно ввести номер карты, код CVC и подтвердить
                                                оплату — если у карты включена защита 3?D Secure. Если вам
                                                нужен чек, укажите свою электронную почту. С карты Visa,
                                                MasterCard, Maestro или Мир можно заплатить максимум 250 000
                                                руб. за один раз, в месяц — 500 000 руб.
                                            </p>
                                            <p>
                                                До момента отгрузки товара со склада денежные средства на
                                                вашем счету «заморожены», то есть не списаны окончательно. В
                                                случае перерасчета суммы к оплате (по причине скидки,
                                                акционного предложения или отсутствия товара на складе) с
                                                вашего счета будет списана меньшая сумма, а остаток
                                                разморозится.
                                            </p>
                                            <p>
                                                Если по какой-то причине вы не смогли оплатить заказ и ушли
                                                со страницы со ссылкой на оплату, обратитесь к операторам
                                                call-центра, чтобы вам выслали ссылку для оплаты.
                                            </p>
                                            <p>Подробнее – https://yookassa.ru/payments/</p>
                                        </div>
                                    </div>}
                                </div>
                               <div className={"text-page__list-block information bd "  + (block === 't3' ? 'show' : '')}>
                                      <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't3' ? '' : 't3')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-wallet"/>
                                        </div>
                                        <p>Подели. Оплата по частям</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't3' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Оплата наличными или картой курьеру</h5>
                                            <p>
                                                Сегодня оплачивайте только 25% стоимости покупки, остальное
                                                – тремя платежами раз в 2 недели. Это не кредит, никаких
                                                дополнительных платежей и процентов – как обычная оплата
                                                картой!
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Как оформить покупку:</h5>
                                            <ul>
                                                <li>Выберите Подели в способах оплаты в корзине;</li>
                                                <li>Заполните короткую анкету;</li>
                                                <li>Оплатите 25% стоимости и покупка уже ваша!</li>
                                                <li>
                                                    Остальные три части будут списываться с вашей банковской
                                                    карты автоматически раз в две недели;
                                                </li>
                                                <li>
                                                    Следите за графиком платежей в мобильном приложении
                                                    Подели.
                                                </li>
                                            </ul>
                                            <p>
                                                ООО «А-4 Технологии», ОГРН 1227700064734, 115432, Россия, г.
                                                Москва, пр. Андропова, д.18, к. 3, этаж/помещ./ ком.
                                                9/XIV/1. Подробная информация о работе сервиса на странице
                                                https://www.baon.ru/podeli/ или на сайте сервиса
                                                https://podeli.ru.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                               <div className={"text-page__list-block information bd "  + (block === 't4' ? 'show' : '')}>
                                      <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't4' ? '' : 't4')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-box"/>
                                        </div>
                                        <p>Почта России</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't4' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Оплата наложенным платежём.</h5>
                                            <p>
                                                Оплата производится на почтовом отделении в момент получения
                                                заказа. Сумма оплаты не может превышать 100 000 руб. За
                                                перевод наложенного платежа почтовым отделением может
                                                дополнительно взиматься комиссия от 3 до 8% от полной
                                                стоимости заказа, указанного в акте сдачи-приемки*. В
                                                качестве подтверждения совершения оплаты сотрудник почтового
                                                отделения выдаст вам чек.
                                            </p>
                                            <p>
                                                Возврат денежных средств осуществляется на банковский счет
                                                заказчика в течение 10 дней с момента получения товара
                                                продавцом и предоставления полных реквизитов.
                                            </p>
                                            <small>
                                                * - согласно договору со службой доставки, на данный момент
                                                комиссия за перевод наложенного платежа не взимается. При
                                                получении посылки вы оплачиваете только стоимость товара.
                                                Если на почтовом отделении к указанной стоимости добавляют
                                                комиссию за перевод наложенного платежа, просьба связаться с
                                                сотрудниками интернет-магазина до получения посылки.
                                            </small>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Оплата через систему Яндекс.Касса</h5>
                                            <p>
                                                К оплате принимаются банковские карты VISA, MasterCard,
                                                Maestro и МИР.
                                            </p>
                                            <p>
                                                Вы формируете заказ на сайте интернет-магазина и выбираете
                                                Online оплату. Интернет-магазин переадресует Вас на
                                                защищенную форму оплаты процессингового центра Яндекс.Касса,
                                                где вам нужно ввести номер карты, код CVC и подтвердить
                                                оплату — если у карты включена защита 3?D Secure. Если вам
                                                нужен чек, укажите свою электронную почту. С карты Visa,
                                                MasterCard, Maestro или Мир можно заплатить максимум 250 000
                                                руб. за один раз, в месяц — 500 000 руб.
                                            </p>
                                            <p>
                                                До момента отгрузки товара со склада денежные средства на
                                                вашем счету «заморожены», то есть не списаны окончательно. В
                                                случае перерасчета суммы к оплате (по причине скидки,
                                                акционного предложения или отсутствия товара на складе) с
                                                вашего счета будет списана меньшая сумма, а остаток
                                                разморозится.
                                            </p>
                                            <p>
                                                Если по какой-то причине вы не смогли оплатить заказ и ушли
                                                со страницы со ссылкой на оплату, обратитесь к операторам
                                                call-центра, чтобы вам выслали ссылку для оплаты.
                                            </p>
                                            <p>Подробнее – https://yookassa.ru/payments/</p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Оплата почтовым переводом</h5>
                                            <p>
                                                Оплата производится после оформления заказа на сайте
                                                интернет-магазина. Вы скачиваете квитанцию в формате .xls,
                                                заполняете её и совершаете оплату на почтовом отделении.
                                                Максимальная сумма перевода – 60 000 руб. Переводы
                                                принимаются только в рублях, дополнительно будет взиматься
                                                комиссия за перевод платежа, которая составляет от 5 до 8%
                                                от его суммы (точную информацию можно уточнить на вашем
                                                почтовом отделении). После получения и обработки перевода
                                                сотрудниками интернет-магазина будет произведена отправка
                                                вашего заказа. Обратите внимание: оплату необходимо
                                                произвести в течение пяти дней с момента оформления заказа,
                                                иначе заявка будет аннулирована.
                                            </p>
                                            <p>
                                                Возврат денежных средств осуществляется на банковский счет
                                                заказчика в течение 10 дней с момента получения товара
                                                продавцом и предоставления полных реквизитов.
                                            </p>
                                        </div>
                                        <div className="text-page__col-wrap">
                                            <h5 className="f-exp">Оплата банковским переводом</h5>
                                            <p>
                                                Оплата производится после оформления заказа на сайте
                                                интернет-магазина. Вы скачиваете бланк для оплаты в формате
                                                .xls и осуществляете платеж в любом отделении банка.
                                                Максимальная сумма перевода – 60 000 руб. Переводы
                                                принимаются только в рублях, дополнительно будет взиматься
                                                комиссия за перевод платежа, которая составляет от 5 до 8%
                                                от его суммы (точную информацию можно уточнить в вашем
                                                отделении банка). После получения и обработки перевода
                                                сотрудниками интернет-магазина будет произведена отправка
                                                вашего заказа. Обратите внимание: оплату необходимо
                                                произвести в течение пяти дней с момента оформления заказа,
                                                иначе заявка будет аннулирована.
                                            </p>
                                            <p>
                                                Возврат денежных средств осуществляется на банковский счет
                                                заказчика в течение 10 дней с момента получения товара
                                                продавцом и предоставления полных реквизитов.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                               <div className={"text-page__list-block information bd "  + (block === 't5' ? 'show' : '')}>
                                      <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't5' ? '' : 't5')
                                    }}>
                                        <div className="circle">
                                            <i className="ico ico-discount"/>
                                        </div>
                                        <p>Оплата через СБП</p>
                                        <i className="ico ico-chevron-down-b"/>
                                    </a>
                                    {block === 't5' && <div className="information__collapse">
                                        <div className="text-page__col-wrap">
                                        <ul>
                                            <li>
                                                Клиентам интернет-магазина во время оформления заказа будет доступен способ оплаты через систему быстрых платежей.
                                            </li>
                                            <li>
                                                Система быстрых платежей — способ оплаты заказа со счёта в интернет-банке, подключённого к СБП.
                                            </li>
                                        </ul>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-page__banners">
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="img/text-page-banner-1.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="img/text-page-banner-1.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Магазины</h2>
                                <p>
                                    Найти ближайший магазин Baon
                                    <br/> в России
                                </p>
                                <a className="btn btn-primary" href="/shops">
                                    Найти магазин
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="img/text-page-banner-2.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="img/text-page-banner-2.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Есть вопрос?</h2>
                                <p>
                                    Звоните 8 (800) 500-73-17 (звонок по России
                                    <br/> бесплатный) или задайте вопрос на сайте
                                </p>
                                <button className="btn btn-primary" onClick={(event) => {
                                    event.preventDefault()
                                    dispatch({type: ReducerActionType.TOGGLE_QUESTION_POPUP})
                                }}>
                                    Подробнее
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}