import ModelImageInterface from "../interfaces/model/ModelImageInterface";

export enum ImageSize {
    BIG = 'big',
    CARD = 'card',
    MICRO = 'micro',
    MIDLLE = 'midlle',
    PAGESIZE = 'pagesize',
    ORIIGINAL = 'original',
    SMALL = 'small',
    THUMBNAIL = 'thumbnail',
    COLORTHUMBNAIL = 'colorthumbnail'
}

export const convertImageToSize = (image: ModelImageInterface, size: string, fromSize: string = ImageSize.BIG) => {
    const  re = fromSize === ImageSize.ORIIGINAL ? new RegExp('shopcatalog\/', 'g') : new RegExp('shopcatalog-resize\/' + fromSize + '\/', 'g');

    if (size === ImageSize.ORIIGINAL) {
        image.url = image.url.replace(re, 'shopcatalog/')
    } else {
        image.url = image.url.replace(re, 'shopcatalog-resize/' + size + '/')
    }
    return image
}