import RefundForm from "./RefundForm";

export default function NullableRefund() {
    return (<main className="main-lk">
        <section className="lk">
            <div className="container-fluid">
                <div className="row lk__wrap">
                    <div className="col-lg-12 lk__container">
                        <div className="tab-content">
                            <RefundForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>)
}