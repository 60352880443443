import ModelColorItemInterface from "../../interfaces/model/ModelColorItemInterface";
import {Link} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Money from "../core/Money";
import {getBaseImagePath} from "./Utils";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {isMobile} from 'react-device-detect';
import {MIN_SWIPE_DISTANCE} from "../../Config";
import {getSuffixByCount} from "../../utils/Utils";
import useModelColorItem from "./useModelColorItem";
import ModalChooseSizes from "./ModalChooseSizes";
import ModelListCardMedia from "./ModelListCardMedia";


export default function ModelListCard({modelColorItem, disableBasket, inView}: { modelColorItem: ModelColorItemInterface, disableBasket?: boolean, inView?: boolean }) {

    const AVAILABLE_COLORS_COUNT = 4;

    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const [showBasket, setShowBasket] = useState(false)
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [touchStart, setTouchStart] = useState(0)
    const [touchEnd, setTouchEnd] = useState(0)

    const {getImages, addToBasket, isExistInBasket, toggleFavorite, isExistInFavorite, isSizeAvailable, getImageAlt, getVideos} = useModelColorItem(modelColorItem)

    useEffect(() => {
        setCurrentImageIndex(0)
    }, [modelColorItem.id]);

    const getImageIndex = (event: React.MouseEvent) => {
        if (!getImages().length) {
            return 0;
        }

        const target: any = event.target
        const zoneWidth = Math.ceil(target.getBoundingClientRect().width / getImages().length);
        const relPosition = Math.ceil(Math.abs((event.clientX - target.getBoundingClientRect().left)))
        return Math.floor(relPosition / zoneWidth)
    }

    const addToBasketAction = (size: string) => {
        addToBasket(size)
        setShowBasket(false)
    }

  const mediaItems = [
    ...(inView
      ? getVideos()
      : []),
    ...getImages().filter((image) => !(image.type === "print")),
  ];

    return (<div className="card" onMouseOver={() => {
        if (isMobile) return
        setShowBasket(true);
    }} onMouseOut={() => {
        if (isMobile) return
        setShowBasket(false);
    }}>
        <Link
            to={`/catalog/${modelColorItem.model_id}/${modelColorItem.special_transliterated_name.toLowerCase()}/${modelColorItem.color_special.toLowerCase()}/`}>
            <div className="card__img">
                <div className="card__img-slider" onMouseMove={(event) => {
                    if (isMobile) return
                    setCurrentImageIndex(getImageIndex(event))
                }}
                     onTouchStart={(event: React.TouchEvent) => {
                         setTouchEnd(0) // otherwise the swipe is fired even with usual touch events
                         setTouchStart(event.targetTouches[0].clientX)
                     }}

                     onTouchMove={(event: React.TouchEvent) => setTouchEnd(event.targetTouches[0].clientX)}


                     onTouchEnd={() => {
                         if (!touchStart || !touchEnd) return
                         const distance = touchStart - touchEnd
                         const isLeftSwipe = distance > MIN_SWIPE_DISTANCE
                         const isRightSwipe = distance < -MIN_SWIPE_DISTANCE
                         if (isLeftSwipe || isRightSwipe) {
                             if (isRightSwipe) {
                                 const currentIndex = currentImageIndex + 1;
                                 setCurrentImageIndex(currentIndex >= getImages().length ? 1 : currentIndex)
                             } else {
                                 const currentIndex = currentImageIndex - 1;
                                 setCurrentImageIndex(currentIndex <= 0 ? getImages().length - 1 : currentIndex)
                             }
                         }

                     }}>
 {mediaItems.map((media, index) => (
    <ModelListCardMedia
        key={index}
        media={media}
        index={index}
        currentImageIndex={currentImageIndex}
        inView={inView}
        getImageAlt={getImageAlt}
        firstSliderImage={getImages()[0]?.url || ''} 
    />
            ))}
                    {/* TODO: Сделать заглушку если нет фотки ^ */}
                    <ul className="card__img-slider-dots">
                        {mediaItems.map((_, index)  => <li
                            key={index} className="card__slide-dot active"
                            style={{opacity: index === currentImageIndex ? 1 : 0.25}}></li>)}
                    </ul>
                </div>
                <div className="top">
                    <i className={"like " + (isExistInFavorite() && "active")} onClick={(event) => {
                        event.preventDefault()
                        toggleFavorite()
                    }}></i>
                </div>
                <div className="bot">
                    <div className="bot__notes">
                        <i className="notes__left"></i>
                        <ul>
                            {modelColorItem.labels.map(label => <li key={label.id || label.name}
                                                                    style={{backgroundColor: '#' + label.hex}}
                                                                    className="note">{label.name}</li>)}
                        </ul>
                        <i className="notes__right"></i>
                    </div>
                    {!isMobile && !disableBasket && <div className="card__basket" style={{marginBottom: (showBasket ? '0' : '-6rem')}}
                                       onClick={(event) => {
                                           event.preventDefault()
                                           event.stopPropagation()
                                       }}>
                        <p>Добавить в корзину</p>
                        <ul className="sizes">
                            {modelColorItem.sizes.map(size => <li key={size}><span
                                className={'basket-size ' + (isExistInBasket(size) ? 'active' : '') + (!isSizeAvailable(size) ? " model-size-disabled " : "") +
                                    (size.length > 4 ? " label-long" : "")}
                                onClick={(event) => {
                                    event.preventDefault()
                                    if (isSizeAvailable(size)) {
                                        addToBasketAction(size)
                                    }
                                }}>{size}</span></li>)}
                        </ul>
                    </div>}
                </div>
            </div>
            <div className="card__info">
                <ul className="card__meta">
                    {modelColorItem.review_estimate > 0 && modelColorItem.review_count > 0 && <li><i className="ico ico-star"></i><p>{modelColorItem.review_estimate}</p></li>}
                    {modelColorItem.review_count > 0 && <li><i className="ico ico-feedback"></i><p>{modelColorItem.review_count} </p></li>}
                    { (modelColorItem.review_estimate === 0 || modelColorItem.review_count === 0) && <li><p>&nbsp;</p></li>}
                </ul>
                <p className="card__text">{modelColorItem.name}</p>
                {modelColorItem.min_price.amount !== modelColorItem.max_price.amount &&
                    <p className="card__price f-exp"><span className="new"><Money
                        value={modelColorItem.min_price}/></span><span className="old"><Money
                        value={modelColorItem.max_price}/></span></p>}
                {modelColorItem.min_price.amount === modelColorItem.max_price.amount &&
                    <p className="card__price f-exp"><Money value={modelColorItem.min_price}/></p>}
                <ul className="card__colors">
                    {(isMobile && modelColorItem.sibling_colors.length > AVAILABLE_COLORS_COUNT ? modelColorItem.sibling_colors.slice(0, AVAILABLE_COLORS_COUNT) : modelColorItem.sibling_colors).map(color =>
                        <li key={color.color}
                            className={modelColorItem.color === color.color ? "active" : ""}>
                            <i style={{background: color.hexcolor}}></i></li>)}

                    {isMobile && modelColorItem.sibling_colors.length > AVAILABLE_COLORS_COUNT &&
                        <li className="card__colors-last mob">
                            <p>+ {modelColorItem.sibling_colors.length - AVAILABLE_COLORS_COUNT} цвет{getSuffixByCount(modelColorItem.sibling_colors.length - AVAILABLE_COLORS_COUNT)}</p>
                        </li>}
                </ul>
                {!disableBasket && <span className="card__info-add-basket mob" onClick={(event) => {
                    event.preventDefault()
                    setShowBasket(true)
                }}><i
                    className="ico ico-bag"></i></span>}
            </div>
        </Link>

    {isMobile && showBasket &&
            <ModalChooseSizes isOpen={showBasket} onClose={() => setShowBasket(false)}>
            <div className="modal-body">
            <h5 className="f-exp">Выберите размер</h5>
                <ul>
                    {modelColorItem.sizes.map(size => <li key={size}><a href={'/'}
                        className={'basket-size ' + (isExistInBasket(size) ? 'active' : '') + (!isSizeAvailable(size) ? " model-size-disabled " : "") +
                            (size.length > 4 ? " label-long" : "")}
                        onClick={(event) => {
                            event.preventDefault()
                            if (isSizeAvailable(size)) {
                                addToBasketAction(size)
                            }
                    }}>{size}</a></li>)}
                </ul>
            <a href="/" className={'link'} onClick={(event) => {
                                event.preventDefault()
                                dispatch({type: ReducerActionType.TOGGLE_TABLEOFSIZES_POPUP})
                                setShowBasket(false)
            }}>таблица размеров</a>
            </div>
        </ModalChooseSizes>
        }
        {/* {isMobile && showBasket && <div className="modal-backdrop show"></div>} */}
    </div>)
}