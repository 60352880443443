export default function HowToUse() {
    return (<>
        <div className="product__text-block">
            <h3 className="product__text-block-link">Стирка <i
                className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <p>к стирке пухового изделия целиком прибегайте только в
                    крайних случаях. Если Вы все-таки решили постирать
                    пуховое изделие целиком, то возможна машинная стирка
                    в деликатном режиме при температуре 30 с
                    использованием специальных моющих средств, не
                    содержащих отбеливающих веществ. Перед стиркой
                    следует отстегнуть меховые оторочки и капюшон от
                    изделия, соединить вместе рукава и застегнуть
                    застежки типа Velcro (липучки). Стирка изделия с
                    другими вещами не рекомендуется.</p>
                <blockquote>
                    <i className="ico ico-left ico-exclamation"></i>
                    <span>Помните, что пух хорошо впитывает моющее средство, но плохо его отдает. Поэтому, во избежание мыльных разводов на ткани, изделие нуждается в дополнительном полоскании до полного вымывания моющего средства. После основной стирки рекомендуется дополнительное полоскание изделия не менее 2-3 раз.</span>
                </blockquote>
            </div>
        </div>
        <div className="product__text-block">
            <h3 className="product__text-block-link">Отжим <i
                className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <p>производится при высоких оборотах барабана.</p>
            </div>
        </div>
        <div className="product__text-block">
            <h3 className="product__text-block-link">Сушка <i
                className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <p>производится в расправленном подвешенном состоянии
                    при периодическом встряхивании. Запрещается сушить
                    изделие на или вблизи нагревательных приборов
                    (батареи центрального отопления и
                    электронагреватели).</p>
                <blockquote>
                    <i className="ico ico-left ico-exclamation"></i>
                    <span>Помните, что пух после стирки теряет часть своих основных качеств (упругость и теплоизоляцию). На изделиях со светлым верхом после стирки целиком возможно образование небольших разводов и пятен, а также потемнение в районе магнитных кнопок.</span>
                </blockquote>
            </div>
        </div>
        <div className="product__text-block">
            <h3 className="product__text-block-link">Глажение <i
                className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <p>не допускается, при этом возможно отпаривание в
                    щадящем режиме.</p>
            </div>
        </div>
        <div className="product__text-block">
            <h3 className="product__text-block-link">Хранение <i
                className="ico ico-arrow-bot-b"></i></h3>
            <div className="product__text-block-col">
                <p>производится в сухом, хорошо проветриваемом месте в
                    чистом виде. Нежелательно хранение рядом с вещами,
                    имеющими резкий запах, т.к. пух имеет свойство их
                    впитывать.</p>
            </div>
        </div>
    </>)
}
