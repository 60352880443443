import Registration from "./profile/Registration";
import ChangePassword from "./profile/ChangePassword";
import Subscription from "./profile/Subscription";
import DeliveryAddresses from "./profile/DeliveryAddresses";
import {useState} from "react";

export default function Profile() {
    const [current, setCurrent] = useState<"registration"|"change-password"|"subscription"|"delivery-addresses">('registration')

    return (<div className="tab-pane fade active show">
            <div className="col-lg-8">
                <div className="lk__profile">
                    <h3 className="lk__title f-exp desk">Профиль</h3>
                    <ul className="nav nav-tabs f-exp">
                        <li className="nav-item"><button className={"nav-link " + (current === 'registration' && 'active') } onClick={() => { setCurrent('registration') }} type="button" > Личные данные </button> </li>
                        <li className="nav-item"><button className={"nav-link " + (current === 'change-password' && 'active') } onClick={() => { setCurrent('change-password') }} type="button" >Изменение пароля</button></li>
                        {/*<li className="nav-item"><button className={"nav-link " + (current === 'subscription' && 'active') } onClick={() => { setCurrent('subscription') }} type="button" >Управление подписками</button></li>*/}
                        {/*<li className="nav-item"><button className={"nav-link " + (current === 'delivery-addresses' && 'active') } onClick={() => { setCurrent('delivery-addresses') }} type="button">Адреса доставки</button></li>*/}
                    </ul>
                    <div className="tab-content">
                        {current === 'registration' && <Registration />}
                        {current === 'change-password' && <ChangePassword />}
                        {current === 'subscription' && <Subscription />}
                        {current === 'delivery-addresses' && <DeliveryAddresses />}
                    </div>
                </div>
            </div>
        </div>
    )
}