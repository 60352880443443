import ModelInterface from "../../interfaces/model/ModelInterface";
import React, {useRef, useState} from "react";
import useModel from "../catalog/useModel";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import ReactImageMagnify from "react-image-magnify";
import {getBaseImagePath} from "../catalog/Utils";
import {convertImageToSize, ImageSize} from "../../utils/Image";
import Description from "../catalog/model-card/Description";
import Properties from "../catalog/model-card/Properties";
import Moment from "react-moment";

export default function ModelInfo({model}: { model: ModelInterface }) {
    const swiperRef = useRef();
    const [selectedColor, setSelectedColor] = useState(model.extra_param.main_color_name || model.images.find(image => image.type === 'front')?.special_color_name);

    const {
        getFrontImages,
        getCurrentColorName,
        getImagesByColor,
        getPrintByColor,
        getUniqueSizes,
        getUniqueColors
    } = useModel(model, selectedColor)

    const handleImageClick = (index: number) => {
        console.log(index)
    }

    return (<div>
        <h2 className={'mt-5'}>{model.name} (арт: {model.articul})</h2>
        <Swiper
            style={{minHeight: '400px'}}
            loop={true}
            spaceBetween={5}
            slidesPerView={1}
            onSwiper={(swiper: any) => {
                swiperRef.current = swiper
            }}
            breakpoints={{
                992: {
                    slidesPerView: 2
                }
            }}
        >
            {model.videos?.length > 0 && model.videos.map((video, index) => <SwiperSlide key={video.url}
                                                                                     virtualIndex={index + 100}>
                <video
                    muted
                    loop
                    autoPlay
                    playsInline
                    preload="none"
                    style={{width: '100%'}}
                >
                    <source src={getBaseImagePath() + video.url} type="video/mp4"/>
                    Видео не поддерживается браузером
                </video>
            </SwiperSlide>)}

            {getImagesByColor().map((image, index) => <SwiperSlide key={image.url} virtualIndex={index}>
                <a key={image.url} className="slide" href="/" onClick={(event) => {
                    event.preventDefault();
                    handleImageClick(index);
                }}>
                    <ReactImageMagnify
                        {...{
                            smallImage: {
                                isFluidWidth: true,
                                src: getBaseImagePath() + image.url,
                            },
                            largeImage: {
                                src: getBaseImagePath() + convertImageToSize(image, ImageSize.ORIIGINAL).url,
                                width: 1200,
                                height: 1800,
                            },
                            className: 'custom-zoom',
                            style: {
                                cursor: 'zoom-in',
                            },
                            enlargedImagePosition: 'over',
                            // isHintEnabled: true,
                            // hintTextMouse: "Наведите для увеличения",
                            // hintTextTouch: "Наведите для увеличения",
                        }}
                    />
                </a>
            </SwiperSlide>)}
        </Swiper>

        <div className="product__kv-info-model">
            <p>{getCurrentColorName()}</p>
            <div >
                {getUniqueColors().map((color, index) => <div className={'dealer-color-2 pointer'} onClick={() => {
                    setSelectedColor(color.special)
                }}
                style={{background: getPrintByColor(color.special) ? 'url(' + getPrintByColor(color.special) + ') no-repeat center center ' + color.hex : color.hex}}></div>)}
            </div>
        </div>

        <h4 className={'mt-5'}>Описание модели</h4>
        <div><Description model={model}/></div>
        <div className="product__kv-info-cols show my-5">
            <p className="product__kv-info-cols-link active f-exp ">О товаре </p>
            <div className="product__kv-info-cols-list">
                {model?.model_properties &&
                    <Properties model={model} mode={"prop"}/>}
                {model?.extra_param?.materials?.length && <ul>
                    {model?.extra_param?.materials.map(material => <li key={material.name}><p>
                        <span>{material.name}</span><span>{material.value}</span></p></li>)}
                </ul>}
            </div>
        </div>
        <div><b>Дата начала продаж: </b><Moment format={"DD.MM.YYYY"}>{model.collection.begin_date}</Moment></div>
        <div className={'mt-2'}><b>Размеры:</b> {getUniqueSizes(true).join(', ')}</div>
        {model.capsules?.length > 0 &&
            <div className={'mt-2'}><b>Капсулы:</b> {model.capsules?.map(capsule => capsule.name).join(', ')}</div>}

    </div>)
}