import {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getBlogItemBySlug} from "../../../api/fashionBlog";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Moment from "react-moment";
import Breadcrumbs from "../Breadcrumbs";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {getBaseImagePath} from "../../catalog/Utils";
import NotFoundPage from "../pages/NotFoundPage";
import {mainPageData} from "../../../api/page";
import BlogAsideBanners from "./BlogAsideBanners";
import {Helmet, HelmetProvider} from "react-helmet-async";


export default function FashionBlogItem() {
    const location = useLocation()
    const navigate = useNavigate()

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [redirect, setRedirect] = useState<string | null>(null)


    const {data, status} = useQuery({
        queryKey: [...['fashion-blog', 'item', location.pathname]],
        queryFn: () => getBlogItemBySlug(location.pathname),
        retry: false
    })

    const {data: initData} = useQuery({
        queryKey: ['main'],
        queryFn: () => mainPageData()
    })


    const {post, meta} = data || {}

    const [showShare, setShowShare] = useState(false);

    useEffect(() => {
        if (!location.pathname || !post?.slug) {
            return
        }

        if (location.pathname.includes('/id/') || location.pathname.match(/^\/blog\/\d+\//)) {
            const newUrl = '/blog/' + post.slug + '/'
            navigate(newUrl)
            setRedirect(newUrl)
        }
    }, [location.pathname, post?.slug]);


    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: meta})
    }, [meta]);

    if (status === 'error') {
        return (<NotFoundPage/>);
    }

    return (<main className="main-blog">
        {redirect && <HelmetProvider><Helmet>
            <meta name="prerender-status-code" content="301"/>
            <meta name="prerender-header" content={'Location: https://baon.ru' + redirect}/>
        </Helmet></HelmetProvider>}
        <section className="blog pt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Breadcrumbs breadcrumbs={data?.breadcrumbs || []}/>
                    </div>
                </div>
                <div className="row blog__wrap">
                    <div className="col-lg-6 blog__aside">
                        <Link className="blog__aside-back ico-cont" to={'/index/blog/'}>
                            <i className="ico ico-left ico-arrow-left-b"/>
                            <span className="desk">Обратно к статьям</span>
                        </Link>
                        <h1 className="f-exp">
                            {post?.title}
                        </h1>
                        <div className="meta">
                            <p>{post?.type.name} / <Moment format={'DD.MM.YYYY'}>{post?.create_date}</Moment></p>
                            <div className="information">
                                <a className="ico-cont information__link f-exp" href="/" onClick={(event) => {
                                    event.preventDefault();
                                    setShowShare(!showShare)
                                }}>
                                    <i className="ico ico-left ico-share-2"/>
                                    Поделиться
                                </a>
                                {showShare && <div className="information__collapse">
                                    <ul className="social">
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-ln"/> Скопировать ссылку
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-vk"/> ВКонтакте
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-tg"/> Telegram
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-wh"/> WhatsApp
                                            </a>
                                        </li>
                                        <li>
                                            <a className="ico-cont" href="">
                                                <i className="soc soc-ok"/> Одноклассники
                                            </a>
                                        </li>
                                    </ul>
                                </div>}
                            </div>
                        </div>
                        {initData && (
                                <BlogAsideBanners 
                                    topBanners={initData.topBanners || []} 
                                    collectionBanners={initData.collectionBanners || []} 
                                />
                        )}
                    </div>
                    <div className="col-lg-6 blog__main">
                        <div className="blog__main-section">
                            <div className="imgs imgs-1">
                                <div className="img">
                                    <img src={getBaseImagePath() + post?.top_image} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="blog__main-section" id="blog__main-section-1_1">
                            <div dangerouslySetInnerHTML={{__html: post?.body_modified || ''}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>)
}