import {useQuery} from "@tanstack/react-query";
import {getShops} from "../../../api/dictionary";
import Breadcrumbs from "./../Breadcrumbs";
import {Link, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {ShopInterface} from "../../../interfaces/shop/ShopInterface";
import ShopMap from "./ShopMap";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import PageMetaInterface from "../../../interfaces/core/PageMetaInterface";
import NotFoundPage from "../pages/NotFoundPage";
import {getModelColorItems} from "../../../api/catalog";
import HorizontalModelSlider from "../../main/NowBuy";

export default function ShopItem(){
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);



    const {data, status} = useQuery({
        queryKey: [...['shop', 'list']],
        queryFn: () => getShops()
    })

    const {shops} = data || {}

    const [shop, setShop] = useState<ShopInterface|null>(null)
    const [shopNotExist, setShopNotExist] = useState<boolean>(false)

    const queryData = useParams<{shopId: string}>();

    useEffect(() => {
        const findedShop = shops?.find(shop => shop.id === queryData.shopId)
        if(findedShop){
            setShop(findedShop)
        }
        if(shops?.length && !findedShop){
            setShopNotExist(true)
        }
    }, [shops]);

    const {data: modelsInStock} = useQuery({
        queryKey: ['model-in-stock' + queryData.shopId],
        queryFn: () => getModelColorItems({
            by_shop_stock_id: [queryData.shopId || ''],
            limit: 100
        }),
        retry: false,
        // @ts-ignore
        enabled: !window.NOSCRIPT && !!queryData.shopId
    })

    const showMap = () => {
        if(!shop){
            return false;
        }
        return parseInt(shop.extra_param?.longitude) || parseInt(shop.longitude)
    }

    const getRandomizeModels = () => {
        let colorItems = (modelsInStock?.model_color_items || []).sort(() => Math.random() - 0.5)

        return colorItems.sort(() => Math.random() - 0.5).slice(0, 100)
    }


    const breadcrumbs = [
        {
            icon: "ico-home-g",
            url: "/",
            isActive: true
        },
        {
            name: 'Магазины',
            url: '/shops'
        },
        {
            name: shop?.name,
        }
    ]

    useEffect(() => {
        const meta: PageMetaInterface = {
            title: "Магазин BAON: " + shop?.name + ' по адресу ' + shop?.address,
            description: "Магазин BAON: " + shop?.name + "Cписок филиалов бренда в Москве, Санкт-Петербурге и других городах России. На этой странице вы найдете адреса, номера телефонов, а также подробную карту с местонахождением всех магазинов сети"
        };

        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: meta})
    }, [shop]);

    if(shopNotExist){
        return (<NotFoundPage />);
    }

    return (<main className="main-shops">
            <section className="shops pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={breadcrumbs || []}/>
                        </div>
                    </div>
                </div>
                {shop && <div className="shops__store" style={{display: "block"}}>
                    <div className="container-fluid">
                        <div className="row desk">
                            <div className="col">
                                <div className="shops__store-head">
                                    <Link className="ico ico-arrow-left-b" to={'/shops'}/>
                                    <h3 className="f-exp">{shop.name}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row shops__store-body">
                            <div className="col-lg-5 shops__store-text">
                                <div className="shops__store-info">
                                    <h3 className="f-exp mob">{shop.name}</h3>
                                    <div className="shops__store-info-title">
                                        <h5 className="f-exp">О магазине</h5>
                                        <p>{shop.extra_param.category_name}</p>
                                    </div>
                                    <div className="shops__store-body--address">
                                        <p className="ico-cont">
                                            <i className="ico ico-left ico-map-point"/>
                                            {shop.extra_param.address || shop.address} {shop.extra_param.address2 && ', ' + shop.extra_param.address2}
                                        </p>
                                        {shop.extra_param.metro_station && <p className="ico-cont">
                                            <i
                                                className="ico ico-left"
                                                style={{background: "#484848"}}
                                            />
                                            {shop.extra_param.metro_station.name}
                                        </p>}
                                    </div>
                                    {/*<div className="shops__store-body--address-get">*/}
                                    {/*    <h6 className="f-exp">Как добраться:</h6>*/}
                                    {/*    <p>*/}
                                    {/*        здесь описание того как добираться до магазина, на каком*/}
                                    {/*        транспорте, какими путями, на чём ехать/плыть/лететь*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                    <div className="shops__store-body--data">
                                        <ul>
                                            <li className="ico-cont">
                                                <i className="ico ico-left ico-phone"/> {shop.extra_param.phone} {shop.extra_param.phone2} {!shop.extra_param.phone && shop.phone}</li>
                                            <li className="ico-cont">
                                                <i className="ico ico-left ico-clock"/>{shop.extra_param.operation_time || shop.operation_time}</li>
                                        </ul>
                                    </div>
                                    {/*<div className="shops__store-body--collections">*/}
                                    {/*    <h6 className="f-exp">Коллекции</h6>*/}
                                    {/*    <p>Whynot?</p>*/}
                                    {/*    <p>LevelUp</p>*/}
                                    {/*</div>*/}
                                    <div className="shops__store-body--opportunities">
                                        <div className="block">
                                            <h6 className="f-exp">Возможности оплаты</h6>
                                            <ul>
                                                {shop.extra_param.payment_types.map(paymentType => <li key={paymentType.name}>{paymentType.name}</li>)}
                                            </ul>
                                        </div>
                                        {/*<div className="block">*/}
                                        {/*    <h6 className="f-exp">Способы получения:</h6>*/}
                                        {/*    <ul>*/}
                                        {/*        <li>Самовывоз</li>*/}
                                        {/*        <li>Курьерская доставка</li>*/}
                                        {/*        <li>Доставка в ПВЗ</li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                    {shop.extra_param.has_reserv_online === 1 && <div className="shops__store-body--rezerv">
                                        <p className="ico-cont">
                                            Резерв товаров онлайн
                                            <i className="ico ico-right ico-question"/>
                                        </p>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-lg-7 shops__store-map">
                                <div className="shops__store-img">
                                    <a className="back mob" href="/shops">
                                        <i className="ico ico-arrow-left-b"/>
                                    </a>
                                    <img src={shop.image || "/img/temp/store.jpg"} alt=""/>
                                </div>
                            </div>
                        </div>
                        {showMap() && <div className="row">
                            <div className="col">

                                    <ShopMap shops={[shop]} allShopVisible={false} />

                            </div>
                        </div>}
                        {!!modelsInStock?.model_color_items?.length && <HorizontalModelSlider modelColorItems={getRandomizeModels()} header={'Новинки в магазине ' + shop.name.replace(/Магазин/, '')}/>}
                    </div>
                </div>}
            </section>
        </main>
    )
}