import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import Breadcrumbs from "../Breadcrumbs";
import {getPromotions} from "../../../api/news";
import PromotionsList from "./PromotionsList";

export enum PromotionTabs {
    ONLINE = "ONLINE",
    RETAIL = "RETAIL",
}

export default function Promotions({isEmbedded = false}: { isEmbedded?: boolean }) {
    const [tab, setTab] = useState<PromotionTabs>(PromotionTabs.ONLINE);

    const {data} = useQuery({
        queryKey: [...["promotions"]],
        queryFn: () => getPromotions(),
    });

    const {posts: promotions, breadcrumbs} = data || {};

    const getSortedAndFilteredPromotions = () => {
        return (promotions || []).sort((a, b) => Date.parse(b.news_date) - Date.parse(a.news_date)).filter(promotion => {
            switch (tab) {
                case PromotionTabs.ONLINE:
                    return promotion.is_online;
                case PromotionTabs.RETAIL:
                    return promotion.is_retail;
            }
        })
    };

    if (isEmbedded) {
        return (<section className="stocks pt">
                <PromotionsList promotions={getSortedAndFilteredPromotions()} tab={tab} setTab={setTab}/>
            </section>)
    }

    return (
        <main className="main-stocks">
            <section className="stocks pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={breadcrumbs || []}/>
                        </div>
                    </div>
                    <PromotionsList promotions={getSortedAndFilteredPromotions()} tab={tab} setTab={setTab}/>
                </div>
            </section>
        </main>
    );
}
