import React from "react";
import { useInView } from "react-intersection-observer";
import ModelListCard from "./ModelListCard";
import ModelColorItemInterface from "../../interfaces/model/ModelColorItemInterface";

interface ModelListCardObserverProps {
  modelColorItem: ModelColorItemInterface;
}

const ModelListCardObserver: React.FC<ModelListCardObserverProps> = ({ modelColorItem }) => {
  const { ref, inView } = useInView({
    threshold: 0.5, 
    triggerOnce: false, 
	delay: 200,
  });

  return (
    <div ref={ref} className={`model-list-card`}>
      <ModelListCard modelColorItem={modelColorItem} inView={inView} />
    </div>
  );
};

export default ModelListCardObserver;