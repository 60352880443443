import fetchData from "../utils/HttpClient";
import {CustomerInterface} from "../interfaces/customer/CustomerInterface";
import {OrderInterface} from "../interfaces/customer/OrderInterface";
import {RegionInterface} from "../interfaces/core/RegionInterface";
import {ShopInterface} from "../interfaces/shop/ShopInterface";
import {ModelStockShopInterface} from "../interfaces/model/ModelStockShopInterface";
import {OrderDataInterface} from "../interfaces/store-reserv/OrderDataInterface";
import {TransactionInterface} from "../interfaces/checkout/TransactionInterface";

const basePathCheckout = '/api/checkout'

export async function loadBasket(params: any) {
    return fetchData(basePathCheckout + '/basket', params, 'POST')
}

export async function createOrder(params:any) {
    return fetchData(basePathCheckout + '/create-order', params, 'POST')
}

export async function getAutoApplyPromocodes() {
    return fetchData(basePathCheckout + '/auto-apply-promocode-list')
}

export async function checkPromocode(promocode: string) {
    return fetchData(basePathCheckout + '/check-promocode', {promocode: promocode}, 'POST')
}

export async function getRegionByIp() {
    return fetchData(basePathCheckout + '/region-by-ip')
}

export async function checkCodeword(promocode: string, type: string, customer: CustomerInterface) {
    return fetchData(basePathCheckout + '/check-promocode', {promocode: promocode, type: type, customer: customer}, 'POST')
}

export async function sabManagers(customer: CustomerInterface) {
    return fetchData(basePathCheckout + '/sab-managers', {
        customer: customer
    }, 'POST')
}

export async function getCities(cityName: string, countryCode: string ): Promise<RegionInterface[]>{
    return fetchData(basePathCheckout + '/city-by-name', {name: cityName, country_code: countryCode}, 'POST')
}

export async function getBanners(groupId: number[]) {
    return fetchData('/api/banners/' + groupId.join(','))
}


export async function podeliLink(order: OrderInterface) {
    return fetchData('/api/bnpl/podeli/create', {
        order_id: order.id,
        customer: order.customer
    }, 'POST')
}


export async function getCreditCardPaymentLink(id: string, secret: string) {
    return fetchData('/api/yookassa/create-payment/' + id + '/' + secret , {}, 'POST')
}

export async function getSbpPaymentLink(id: string, secret: string) {
    return fetchData('/api/yookassa/create-payment-sbp/' + id + '/' + secret , {}, 'POST')
}

export async function getTrasactionStatus(id: string, secret: string): Promise<TransactionInterface[]> {
    return fetchData('/api/yookassa/transaction-status/' +id + '/' + secret , {}, 'POST')
}

export async function getStoreReservData(modelId: string): Promise<{regions: RegionInterface[], restricted_shops: ShopInterface[], shops: ShopInterface[], stocks: ModelStockShopInterface[]}> {
    return fetchData('/api/store-reserv/list' , {model_id: modelId}, 'POST')
}

export async function createStoreReservOrder(params:OrderDataInterface): Promise<{isError: boolean, error?: string, order?: OrderInterface}> {
    return fetchData('/api/store-reserv/create-order' , params, 'POST')
}