import {useState} from "react";

export default function About()
{
    const [isOpen, setIsOpen] = useState(false)
    
    return (<section className="information">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="information__block">
                        <div className="information__img"><img src="/img/temp/information-img-1.jpg" alt="" /></div>
                        <div className="information__text">
                            <h5 className="f-exp">BAON - интернет-магазин одежды и аксессуаров для всей семьи</h5>
                            <p>Уже более 30 лет мы создаем функциональные, технологичные, комфортные и стильные линии
                                одежды и аксессуаров для женщин, мужчин и детей. Удобная и качественная одежда BAON
                                пользуются стабильным спросом не только в России, но и в других странах: Казахстане,
                                Белоруссии. </p>
                            <a className="information__link f-exp" href="/" onClick={(event) => {
                                event.preventDefault()
                                setIsOpen(!isOpen)
                            }}>{!isOpen && <span>Подробнее</span>}{isOpen && <span>Свернуть</span>}</a>
                        </div>
                    </div>
                    {<div className="information__collapse" style={{display: (isOpen ? 'block' : 'none')}}>
                        <div className="information__block order">
                            <div className="information__img"><img src="/img/temp/information-img-2.jpg" alt="" /></div>
                            <div className="information__text">
                                <h5 className="f-exp">Ценность бренда</h5>
                                <p>Мы дорожим репутацией и гарантируем качество моделей: используя наработанный опыт и
                                    технологии – от инновационных до проверенных временем. Комфорт покупателей для нас в
                                    приоритете, поэтому мы делаем ставку на натуральные материалы - шерсть, хлопок,
                                    вискозу и лён – а также на качественные пуховые наполнители и надежную фурнитуру. В
                                    своей работе мы учитываем мнение поклонников бренда: для нас важно сохранять гибкие
                                    цены и поддерживать высокий уровень сервиса. Мы работаем, чтобы к нам возвращались
                                    за покупками всей семьей.</p>
                            </div>
                        </div>
                        <div className="information__block">
                            <div className="information__img"><img src="/img/temp/information-img-3.jpg" alt="" /></div>
                            <div className="information__text">
                                <h5 className="f-exp">Коллекции BAON</h5>
                                <p>Мы выпускаем по две коллекции в год. В сезоне «Осень-Зима» превалирует утепленная
                                    верхняя одежда: от легких пальто и курток до зимних пуховиков, комбинезонов, брюк и
                                    юбок. Большое внимание уделено трикотажу: как вязаным джемперам, так и аксессуарам –
                                    варежкам, перчаткам, шапкам и шарфам. Коллекция сезона «Весна-Лето» включает лёгкие
                                    куртки, ветровки и плащи, а также одежду для отпуска и пляжа. Костюмы, платья,
                                    блузки, футболки и брюки в стиле casual выпускаются каждый сезон. BAON WHY NOT – это
                                    лимитированная коллекция трендовой одежды. Модели из этой капсулы также выходят два
                                    раза в год, но ограниченным тиражом. За наиболее популярными вещами разворачивается
                                    настоящая охота!</p>
                            </div>
                        </div>
                        <div className="information__block order">
                            <div className="information__img"><img src="/img/temp/information-img-4.jpg" alt="" /></div>
                            <div className="information__text">
                                <h5 className="f-exp">Заказывайте online</h5>
                                <p>В интернет-магазине BAON и в мобильном приложении вы можете легко купить одежду в
                                    любое удобное время, причем с бесплатной курьерской доставкой (от 3000 рублей) до
                                    порога и с примеркой на дому (услуга доступна для клиентов из Москвы и Московской
                                    области). Можно забрать заказ самовывозом в любом из наших розничных магазинов, в
                                    почтовом отделении или постамате – как вам удобнее. Для получения подробной
                                    информации о продукции покупатели могут обратиться к операторам службы поддержки
                                    клиентов, которые доступны ежедневно с 9.00 до 21.00 по телефону:
                                    8-800-500-73-17</p>
                            <a className="information__link f-exp" href="/" onClick={(event) => {
                                event.preventDefault()
                                setIsOpen(!isOpen)
                            }}>{!isOpen && <span>Подробнее</span>}{isOpen && <span>Свернуть</span>}</a>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </section>)
}
