import {useContext, useEffect, useState} from "react";
import {fallbackUrls} from "../../api/fallback-urls";
import {Link, useLocation, useNavigate} from "react-router-dom";

import HorizontalModelSlider from "../main/NowBuy";
import {useQuery} from "@tanstack/react-query";
import {getModelColorItems} from "../../api/catalog";
import {NOT_FOUND_REPOSITORY_ID} from "../../Config";
import ChooseYourLook from "../main/ChooseYourLook";
import {chooseYourLook} from "../../api/page";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {GlobalContext} from "../../GlobalReducer";
import {Helmet, HelmetProvider} from "react-helmet-async";


export default function FallbackUrls() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const location = useLocation()
    const navigate = useNavigate()

    const [status404, setStatus404] = useState(false)
    const [redirect, setRedirect] = useState<string | null>(null)

    const {data: chooseYourLookItems} = useQuery({
        queryKey: ['page', 'choose-your-look', !!state.customer?.id],
        queryFn: () => chooseYourLook(!!state.customer?.id)
    })

    useEffect(() => {
        const urls: any = fallbackUrls

        if(urls[location.pathname] || false){
            setRedirect(urls[location.pathname])
            navigate(urls[location.pathname])
            return
        }

        setStatus404(true)

    }, []);

    const {data} = useQuery({
        queryKey: ['models-on-404'],
        queryFn: () => getModelColorItems({
            repository_id: [NOT_FOUND_REPOSITORY_ID],
            limit: 600,
            randomize: true
        }),
        retry: false,
        //@ts-ignore
        enabled: !window.NOSCRIPT
    })

    const getFilteredAndRandomizeModels = () => {
        let colorItems = (data?.model_color_items || []).sort(() => Math.random() - 0.5)
        return colorItems.sort(() => Math.random() - 0.5).slice(0, 100)
    }

    return (<main className="main-404">
            {status404 && <HelmetProvider><Helmet>
                <meta name="prerender-status-code" content="404"/>
            </Helmet></HelmetProvider>}
            {redirect && <Helmet>
                <meta name="prerender-status-code" content="301"/>
                <meta name="prerender-header" content={'Location: https://baon.ru' + redirect}/>
            </Helmet>}
            <section className="page-404 pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <ul className="breadcrumb desk">
                                <li className="breadcrumb-item active">
                                    <a href="">
                                        <i className="ico ico-home-g"/>
                                    </a>
                                </li>
                                <li className="breadcrumb-item" aria-current="page">
                                    Ошибка 404
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="page-404__wrap">
                    <h4 className="f-exp">
                        404 Error
                        <br/> К сожалению, запрашиваемой Вами страницы не существует
                    </h4>
                    <p>
                        Возможно Вы перешли по устаревшей ссылке или
                        <br className="mob"/> набрали адрес несуществующей страницы.
                    </p>
                    <Link className="btn btn-secondary" to="/">
                        Вернуться назад
                    </Link>
                </div>
                {chooseYourLookItems?.length && <ChooseYourLook items={chooseYourLookItems}/>}

                <HorizontalModelSlider modelColorItems={getFilteredAndRandomizeModels()} header={'Вам может понравиться'}/>

            </section>
        </main>
    )
}