import {useQuery} from "@tanstack/react-query";
import {getBlogItems} from "../../../api/fashionBlog";
import {useContext, useEffect, useState} from "react";
import Pagination from "../Pagination";
import BottomTags from "../BottomTags";
import {SimpleItemInterface} from "../../../interfaces/core/SimpleItemInterface";
import TagInterface from "../../../interfaces/core/TagInterface";
import {transliterate} from "../../../utils/Utils";
import Breadcrumbs from "../Breadcrumbs";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {Link, useLocation, useParams, useSearchParams} from "react-router-dom";
import {getBaseImagePath} from "../../catalog/Utils";


export default function FashionBlogList() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const location = useLocation();
    const queryData = useParams();
    const [searchParams, setSearchParams] = useSearchParams();



    const [currentType, setCurrentType] = useState<SimpleItemInterface | null>(null)
    const [page, setPage] = useState(1);
    const [additionalPages, setAdditionalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(16);
    const [selectedTags, setSelectedTags] = useState<TagInterface[]>([])

    const {data, status} = useQuery({
        queryKey: [...['fashion-blog', 'list']],
        queryFn: () => getBlogItems()
    })

    const getBaseUrl = () => {
        return location.pathname
    }
    useEffect(() => {
        if(status === 'pending'){
            dispatch({type: ReducerActionType.LOAD})
        }else{
            dispatch({type: ReducerActionType.SUCCESS})
        }
    }, [status]);

    useEffect(() => {
        const typeResult = location.pathname.match(/\/index\/blog\/typeId\/(\d)/)
        if (typeResult) {
            const sType = data?.types.find(type => type.id === parseInt(typeResult[1]))
            if (sType) {
                setCurrentType(sType)
                return
            }
        }
        const tagResult = location.pathname.match(/\/index\/blog\/tag\/(.+)/)
        if (tagResult) {
            const sType = data?.types.find(type => type.id === parseInt(tagResult[1]))
            if (sType) {
                setCurrentType(sType)
                return
            }
        }

        if (data?.types.length) {
            setCurrentType(data.types[0])
        }

    }, [location.pathname, data?.types])

    useEffect(() => {
        if(!data?.meta){
            return
        }
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: data?.meta})
    }, [data?.meta]);

    useEffect(() => {
        if (additionalPages) return
        // @ts-ignore
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [page]);

    useEffect(() => {
        setPage(Number(searchParams.get('page')) || 1)
        setAdditionalPages(0)
    }, [location.pathname, queryData, selectedTags]);


    const getFilteredPosts = () => {
        let filteredPosts = [...(data?.posts || [])];
        if (currentType && !selectedTags.length) {
            filteredPosts = filteredPosts.filter(post => post.type.id === currentType.id)
        }

        if(selectedTags.length) {
            filteredPosts = filteredPosts.filter(post => post.tags_array.find(tag => tag === selectedTags[0].name))
        }

        return filteredPosts
    }

    const getItemsOnPage = () => {
        return getFilteredPosts().slice((page - 1 - additionalPages) * itemsPerPage, page * itemsPerPage);
    }

    const getTags = (): TagInterface[] => {
        if (!data?.tags.length) {
            return []
        }

        let tags: string[] = []

        data.posts.map(post => post.tags_array.map(tag => {
            tags.push(tag)
        }))

        tags.sort()

        tags = tags.filter((value, index, array) => {
            return array.indexOf(value) === index;
        });

        return tags.map((tag, index) => {
            return {
                id: index + 1,
                slug: transliterate(tag),
                name: tag
            }
        })
    }

    const totalPages = Math.ceil(getFilteredPosts().length / itemsPerPage)

    return (<main className="main-blogs-list">
            <section className="blogs-list">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={data?.breadcrumbs || []}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h2 className="blogs-list__title f-exp">Fashion-блог</h2>
                            <div className="blogs-list__tabs">
                                <ul className="nav nav-tabs f-exp">
                                    {data?.types.map(type => <li key={type.id}
                                                                 className={"nav-item "}>
                                        <button className={"nav-link " + (currentType?.id === type.id && "active")}
                                                type="button" onClick={() => {
                                                    setSelectedTags([])
                                                    setCurrentType(type)
                                        }}>{type.name}</button>
                                    </li>)}

                                </ul>
                            </div>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="blogs-list-sect-1"
                                    role="tabpanel"
                                    aria-labelledby="blogs-list-tab-1"
                                >
                                    <div className="blogs-list__table row">

                                        {getItemsOnPage()?.map(blogItem => <div key={blogItem.id}
                                                                                className="col-lg-3">
                                            <div className="blogs-list__block bd">
                                                <picture>
                                                    <source
                                                        srcSet={getBaseImagePath() + blogItem.top_image}
                                                        media="(max-width: 992px)"
                                                    />
                                                    <img src={getBaseImagePath() + blogItem.top_image} alt=""/>
                                                </picture>
                                                <div className="info">
                                                    <ul>
                                                        {blogItem.tags_array.map(tag => <li key={tag}>{tag}</li>)}
                                                    </ul>
                                                    <h5 className="f-exp">{blogItem.title}</h5>
                                                    <Link className="f-exp" to={'/blog/' + blogItem.slug}>
                                                        Подробнее
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>)}

                                    </div>
                                    <div className="information">
                                        <Pagination page={page} setPage={setPage}
                                                    totalPages={totalPages}
                                                    additionalPages={additionalPages}
                                                    setAdditionalPages={setAdditionalPages}
                                                    baseUrl={getBaseUrl()}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="information">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <BottomTags tags={getTags()} selectedTags={selectedTags} setSelectedTags={setSelectedTags} singleMode={true}/>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}