import CentralModal from "./CentralModal";
import {useContext} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function Warning() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    return (<CentralModal>
        <div className="modal-system__head">
            <h5 className="modal-system__title f-exp">{state.error?.title || 'Ошибка' }</h5>
            <button type="button" className="close" onClick={() => {
                dispatch({type: ReducerActionType.ERROR, payload: null})
            }}></button>
        </div>
        <div className="modal-system__text">{state.error?.text }</div>
            {state.error?.extra && (
                <div className="modal-system__extra-section">{state.error.extra}</div>
            )}
        <div className="form-btns btns-end">
            <button className="btn btn-secondary" onClick={() => {
                dispatch({type: ReducerActionType.ERROR, payload: null})
            }}>{state.error?.button || 'Понятно'}</button>
        </div>
    </CentralModal>)
}