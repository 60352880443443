import React, {useEffect, useState} from 'react'
import Spacer from '../Spacer/Spacer.jsx'
import styles from './VideoFull.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const getVideoDimensionsOf = (setImageDimensions, url) => {
	return new Promise(resolve => {
		const video = document.createElement('video');
		video.addEventListener("loadedmetadata", function() {
			setImageDimensions({
				height: this.videoHeight,
				width: this.videoWidth,
			})
			const height = this.videoHeight;
			const width = this.videoWidth;
			resolve({height, width});
		}, false);
		video.src = url;
	});
}

const VideoFull = props => {
	const [imageDimensions, setImageDimensions] = useState({})

	const bannerImg = props.params.url

	useEffect(() => {
		getVideoDimensionsOf(setImageDimensions, bannerImg)
	}, [])

	return (
		<>
			{Object.keys(imageDimensions).length === 0 ? (
				<></>
			) : (
				<>
					<div className='mblock'>
						<div className={'banner-on-main ' + styles.centerVideo}>
							<video className='landingVideoFull videoDesc' src={getBaseImagePath() + bannerImg} fullpage-banner-size=''
								width={imageDimensions.width} autoPlay
								playsInline
								loop
								muted>
							</video>
							<video className='landingVideo videoMob' src={getBaseImagePath() + props.params.urlMob} autoPlay
								playsInline
								loop
								muted>
							</video>
						</div>
						<div className={styles.imageHeight} style={{height: imageDimensions.height + 'px'}}>
							&nbsp;
						</div>
					</div>
				</>
			)}
			<Spacer />
		</>
	)
}

export default VideoFull
