export const DEFAULT_SHOP_ID=2
export const MAIN_IMAGE_TYPE = 'front'

export const LOCAL_STORAGE_KEY_BASKET = 'basket'

export const LOCAL_STORAGE_KEY_CUSTOMER = 'customer'

export const LOCAL_STORAGE_KEY_PARAMS = 'local-storage-params'



export const BASKET_POPUP_AUTOMATIC_DISAPPEAR = 5000;

export const FAVORITE_POPUP_AUTOMATIC_DISAPPEAR = 5000;

export const MAX_MODEL_PER_SEARCH = 5;

export const SEARCH_INPUT_DELAY = 1000;
export const SEARCH_INPUT_ADD_TO_HISTORY_DELAY = 3000;

// ------------ checkout  -----------

export const INTERNET_SHOP_ID = 2

export const VIRTUAL_SHOP_ID = '4'

export const VIRTUAL_MOSCOW_SHOP_ID = '5'

export const BASKET_SECONDS_BETWEEN_UPDATE_QUERY = 600

export const CREDIT_URL = 'https://api.7seconds.ru/home/forms?applicationId='

export const mailRuCounterId = '3239178'

export const VKPriceId = 178170;

export const NOT_FOUND_REPOSITORY_ID = '3045'

export const PAYMENT_TYPE_PODELI_ID = 'tsb-tso-000016'
export const PAYMENT_TYPE_CREDIT_CARD_ID = 'tsb-tso-000015'
export const PAYMENT_TYPE_CREDIT_ID = 'tsb-tso-000010'
export const PAYMENT_TYPE_SBP_ID = 'tsb-it-000001'

export const MIN_SWIPE_DISTANCE = 50

export const LAST_OPEN_MENU_KEY = 'lastOpenMenuKey'

export const MOSCOW_REGION_ID = 'tsb-tst-000001'
export const SPB_REGION_ID = 'tsb-tst-000095'

export const BRAND_STORE_DELIVERY_ID = 'tsb-tso-000068'

export const SIZE_ORDER = 'XXS|XS|S|M|L|XL|XXL|3XL|4XL|5XL|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|35/37|38/40|40/42|Без/раз|Б/р 56|Б/р 58';

export const DEALER_BORDER_QUANTITY = 10

export const ALFA_CLIENT_ID = '9dc70a58-ea7a-4464-ab33-1fe8acaf40eb'