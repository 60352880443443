import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React, {useContext, useState, useEffect} from "react";
import {getCities} from "../../api/checkout";
import {ecommerceDataLayer} from './utils/marketing/Service';
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import {CountryInterface} from "../../interfaces/core/CountryInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";
import {RegionInterface} from "../../interfaces/core/RegionInterface";


function CountryCity({countries}: { countries: CountryInterface[] }) {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState<RegionInterface[]>([])

    useEffect(() => {
        // @ts-ignore
        ecommerceDataLayer('checkout', 'checkout', state.positions, 2, state.region.clear_name)
    }, [state.region && state.region.clear_name])

    return (
        <div className="card mt-4">
            <div className="card-body pb-2">
                <div className="row">
                    {/* <div className="col-lg-4 col-sm-6">
                        <div className="block-header">
                            Выберите страну
                        </div>
                        <div className="mt-2">
                            <div className="form-group select-arrow">
                                <select className={"form-control"} onChange={(e) => {
                                    // dispatch({type: 'setParam', paramName: 'country', payload: props.countries.find(country => country.id === e.target.value)})
                                    const country = props.countries.find(country => country.id === e.target.value)
                                    dispatch({type: 'setCountry', payload: country})
                                }} value={state.country?.id}>
                                    {props.countries.map(country => <option key={country.id} value={country.id}>{country.name}</option>)}
                                </select>
                            </div>
                        </div>
                    </div> */}
                    {state.country?.select_city_allow && <div className="basket__form bd">
                        <form action="">
                            <h5 className="basket__form-title f-exp">Выберите город доставки</h5>
                            <div className="form-wrap big">
                                <div className="form-group">
                                    <div className="form-group">
                                        <AsyncTypeahead
                                            delay={500}
                                            id={'typeahead'}
                                            isLoading={isLoading}
                                            filterBy={() => true}
                                            useCache={false}
                                            labelKey={"name"}
                                            placeholder={"укажите населенный пункт..."}
                                            searchText={"производим поиск..."}
                                            defaultSelected={[state.region?.name || '']}
                                            onSearch={(query) => {
                                                if (query.length < 4) return
                                                setIsLoading(true)
                                                getCities(query, state.country?.id).then((cities) => {
                                                    setIsLoading(false)
                                                    setOptions(cities)
                                                }).catch(error => {
                                                    dispatch({
                                                        type: ReducerActionType.ERROR,
                                                        payload: 'При запросе списка городов: ' + (error.response?.data?.message || error.response?.data?.detail)
                                                    })
                                                })
                                            }}
                                            onChange={(selected) => {
                                                if (selected.length) {
                                                    dispatch({
                                                        type: ReducerActionType.SET_PARAM,
                                                        paramName: 'region',
                                                        payload: selected[0]
                                                    })
                                                }
                                            }}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default CountryCity