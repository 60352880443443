import useModel from "../catalog/useModel";
import Money from "../core/Money";
import React from "react";
import {OrderItemGroupedByModel} from "../../interfaces/dealer/create/OrderItemGroupedByModel";
import {getBaseImagePath} from "../catalog/Utils";
import MoneyInterface from "../../interfaces/core/MoneyInterface";

export default function CreateOrderPosition({item}: {item: OrderItemGroupedByModel}) {

    const {getMainImage} = useModel(item.model)

    const getTotalQuantityByPositions = () => {
        return item.positions.reduce((acc, position) => acc + position.quantity, 0)
    }

    const getTotalByPositions = () => {
        const total = item.positions.reduce((acc, position) => acc + position.quantity * (position.model.dealer_price?.amount || 0), 0)
        return {
            amount: total,
            currency: item.positions[0].model.dealer_price?.currency || 'RUB'
        } as MoneyInterface
    }

    return (<tr>
        <td><img src={getBaseImagePath() + getMainImage()?.url.replace(/\/big\//, '/thumbnail/')} alt=""/></td>
        <td>{item.model.articul}</td>
        <td>{item.model.inetshop_name || item.model.name}</td>
        <td className={'text-end'}>{item.model?.dealer_price && <Money value={item.model?.dealer_price}/>}</td>
        <td>{getTotalQuantityByPositions()}</td>
        <td>
            {item.positions.map(position => <div key={position.color.color + position.size}>{position.color.color} / {position.size} : {position.quantity}</div>)}
        </td>
        <td className={'text-end'}><Money value={getTotalByPositions()}/></td>
    </tr>)
}