import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import {useContext} from "react";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function LoginControlBlock() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    return (<div className="row">
        <div className="col text-center">
            <small>
                {!state.showPasswordRecoveryPopup && <span className={"control pointer"} onClick={() => {
                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.PASSWORD_RECOVERY})
                }}>Забыли пароль?</span>} {state.showRegistrationPopup && <span>&nbsp; / &nbsp;</span>}
                {(!state.showAuthPopup && !state.showAuthByPhonePopup) &&
                    <span className={"control pointer"} onClick={() => {
                        dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.LOGIN})
                    }}>Авторизация</span>} {!state.showRegistrationPopup && <span>&nbsp; / &nbsp;</span>}
                {!state.showRegistrationPopup && <span className={"control pointer"} onClick={() => {
                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.REGISTRATION})
                }}>Регистрация</span>}
            </small>
        </div>
    </div>)
}