import {useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Breadcrumbs from "../Breadcrumbs";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {checkEmail} from "../../../api/manzana";

export default function CheckEmailLink() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Регистрация в программе лояльности'
            }
        ]
    }

    useEffect(() => {
        if(!searchParams.get('code')){
            dispatch({type: ReducerActionType.ERROR, payload: 'В ссылке не указан код'})
            return
        }
        if(!searchParams.get('identity')){
            dispatch({type: ReducerActionType.ERROR, payload: 'В ссылке не указан identity'})
            return
        }

        dispatch({type: ReducerActionType.LOAD})

        checkEmail({
            code: searchParams.get('code') as string,
            identity: searchParams.get('identity') as string
        }).then(
            response => {
                if(response.status === 'Ok'){
                    setIsChecked(true)
                }else{
                    setErrorMessage('В процессе возникла ошибка')
                }
                dispatch({type: ReducerActionType.SUCCESS})
            }, error => {
                setErrorMessage(error.response?.data?.message || error.response?.data?.detail)
                dispatch({type: ReducerActionType.SUCCESS})
            } )


    }, []);

    return <main className="main-company">
        <section className="page-company pt">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Breadcrumbs breadcrumbs={getBreadcrumbs()}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>

                        {state.isLoading && <h2 className="f-exp text-center">Проверяем данные...</h2>}
                        {isChecked && <h2 className="f-exp text-center">Ваш email успешно подтвержден!</h2>}
                        {errorMessage &&  <h2 className="f-exp text-center text-danger">{errorMessage}</h2>}

                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </section>
    </main>

}