import {VKShareButton, TelegramShareButton, WhatsappShareButton, OKShareButton} from "react-share";

export default function SocialShare(getCurrentUrl: any) {
    console.log("show url", getCurrentUrl);

    const shareUrl = `https://baon.ru${getCurrentUrl.getCurrentUrl}`;

    const copyToClipboard = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(shareUrl).then(
            () => {
                alert("Ссылка скопирована");
            },
            (err) => {
                console.error("Ошибка копирования: ", err);
            }
        );
    };

    return (
        <div className="information__collapse">
            <ul className="social">
                <li>
                    <a className="ico-cont" href="#" onClick={copyToClipboard}>
                        <i className="soc soc-ln" /> Скопировать ссылку
                    </a>
                </li>
                <li>
                    <VKShareButton className="ico-cont btn-ico" url={shareUrl} title="ВКонтакте">
                        <div className="soc-text">
                            <i className="soc soc-vk" /> ВКонтакте
                        </div>
                    </VKShareButton>
                </li>
                <li>
                    <TelegramShareButton className="ico-cont btn-ico" url={shareUrl}>
                        <div className="soc-text">
                            <i className="soc soc-tg" /> Telegram
                        </div>
                    </TelegramShareButton>
                </li>
                <li>
                    <WhatsappShareButton className="ico-cont btn-ico" url={shareUrl} title="WhatsApp">
                        <div className="soc-text">
                            <i className="soc soc-wh" /> WhatsApp
                        </div>
                    </WhatsappShareButton>
                </li>
                <li>
                    <OKShareButton className="ico-cont btn-ico" url={shareUrl} title="Одноклассники">
                        <div className="soc-text">
                            <i className="soc soc-ok" /> Одноклассники
                        </div>
                    </OKShareButton>
                </li>
            </ul>
        </div>
    );
}
