import {PromotionTabs} from "./Promotions";

export interface PromotionProps {
    tab: PromotionTabs;
    setTab: (tab: PromotionTabs) => void;
}

export default function PromotionSwitcher(props: PromotionProps) {
    const tabs = [
        {
            type: PromotionTabs.ONLINE,
            name: 'Акции интернет-магазина'
        },
        {
            type: PromotionTabs.RETAIL,
            name: 'Акции розничной сети'
        },
    ];

    return (
        <>
            <ul className="nav nav-tabs f-exp" id="myTab" role="tablist">
                {tabs.map(tab => <li key={tab.type} className="nav-item" role="presentation">
                    <button
                        className={"nav-link " + (props.tab === tab.type ? "active" : "")}
                        onClick={() => props.setTab(tab.type)}
                    >
                        {tab.name}
                    </button>
                </li>)}
            </ul>
        </>
    );
}
