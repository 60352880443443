import Spacer from '../Spacer/Spacer.jsx'
import styles from './TextBlock.module.scss'

const Text = props => {
	const blockClasses = props.params.clsArray
	const text = props.params.text

	return (
		<>
			<div
				className={
					blockClasses
						? 'mblock ' + blockClasses.join(' ')
						: 'mblock text-center w70 font-size-110 line-height-15'
				}
			>
				<div
					className={styles.textEl}
					dangerouslySetInnerHTML={{__html: text}}
				/>
			</div>
			<Spacer />
		</>
	)
}

export default Text
