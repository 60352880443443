import Breadcrumbs from "../Breadcrumbs";
import {useState, useContext} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";
import Subscribe from "../../core/Subscribe";

export default function BonusPage() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const [block, setBlock] = useState('')

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Бонусы'
            }
        ]
    }

 return(
    <main className="main-bonus">
    <section className="text-page bonus pt">
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <ul className="breadcrumb desk">
                        <li className="breadcrumb-item active"><a href=""><i className="ico ico-home-g"></i></a></li>
                        <li className="breadcrumb-item" aria-current="page">Баон Привилегия</li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-10 offset-lg-1">
                    <h1 className="text-page__title f-exp text-center">Баон Привилегия</h1>
                    <div className="text-page__main-banner">
                        <picture>
                            <source srcSet="img/temp/banner-4.jpg" media="(max-width: 992px)" />
                            <img src="img/temp/banner-4.jpg" alt="" />
                        </picture>
                        <div className="text">
                            <h4 className="f-exp">для тех, кто любит умный шопинг</h4>
                            <p>Оплачивайте бонусами до 50% покупки!</p>
                        </div>
                    </div>
                    <div className="form-btns">
                                                <button className="btn btn-secondary"
                                                        onClick={(event) => {
                                                    event.preventDefault();
                                                    if (!state.customer?.id) {
                                                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'});
                                                    } else {
                                                        window.location.href = '/personal-area/privilege';
                                                    }
                                                }}
                                                        >Стать участником</button>
                                                <button className="btn btn-secondary-tr"
                                                        onClick={(event) => {
                                                    event.preventDefault();
                                                    if (!state.customer?.id) {
                                                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'});
                                                    } else {
                                                        window.location.href = '/personal-area/privilege';
                                                    }
                                                }}
                                                        >Войти в личный кабинет</button>
                        {/* <a className="btn btn-secondary-tr" href="/personal-area">Войти в личный кабинет</a> */}
                    </div>
                    <h3 className="text-page__title f-exp text-center">Выгоды участия</h3>
                    <div className="text-page__advantages">
                        <div className="text-page__advantages-list">
                            <div className="text-page__advantages-col">
                                <div className="text-page__advantages-block bd">
                                    <div className="img"><img src="img/svg/pig.svg" alt="" /></div>
                                    <h6 className="f-exp">1 бонус = 1 рубль</h6>
                                    <p>Понятная и простая система начисления</p>
                                </div>
                            </div>
                            <div className="text-page__advantages-col">
                                <div className="text-page__advantages-block bd">
                                    <div className="img"><img src="img/svg/cash.svg" alt="" /></div>
                                    <h6 className="f-exp">Получайте кэшбек бонусами</h6>
                                    <p>От 5% до 10% бонусами* от суммы каждой покупки</p>
                                </div>
                            </div>
                            <div className="text-page__advantages-col">
                                <div className="text-page__advantages-block bd">
                                    <div className="img"><img src="img/svg/credit-card.svg" alt="" /></div>
                                    <h6 className="f-exp">Расплачивайтесь бонусами</h6>
                                    <p>Оплачивайте до 50% от первоначальной стоимости товара на baon.ru и в розничной сети</p>
                                </div>
                            </div>
                            <div className="text-page__advantages-col">
                                <div className="text-page__advantages-block bd">
                                    <div className="img"><img src="img/svg/gift.svg" alt="" /></div>
                                    <h6 className="f-exp">До 2 000 бонусов в подарок</h6>
                                    <p>Получайте до 2 000 бонусов на День Рождения</p>
                                </div>
                            </div>
                            <div className="text-page__advantages-col">
                                <div className="text-page__advantages-block bd">
                                    <div className="img"><img src="img/svg/mood-up.svg" alt="" /></div>
                                    <h6 className="f-exp">Увеличенные бонусы и скидки</h6>
                                    <p>Получайте дополнительные бонусы<br /> и персональные предложения во время акций</p>
                                </div>
                            </div>
                            <div className="text-page__advantages-col">
                                <div className="text-page__advantages-block bd">
                                    <div className="img"><img src="img/svg/discount-2.svg" alt="" /></div>
                                    <h6 className="f-exp">Доступ к закрытым распродажам</h6>
                                    <p>Участвуйте в распродажах для «своих»</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-page__advantages-foot">
                            {/* <p>От 3% до 30% бонусами* от суммы каждой покупки</p> */}
                              <button className="btn btn-secondary"
                                                    onClick={(event) => {
                                                    event.preventDefault();
                                                    if (!state.customer?.id) {
                                                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'});
                                                    } else {
                                                        window.location.href = '/personal-area/privilege';
                                                    }
                                                }}
                                                        >Стать участником</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="text-page how-work">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h3 className="f-exp">Как это работает?</h3>
                    <ul>
                        <li>
                            <img src="img/svg/ico-work-1.svg" alt=""  />
                            <h6 className="f-exp">1. Зарегистрируйтесь<br /> в программе лояльности</h6>
                            <p>на сайте baon.ru или заполните анкету<br  className="desk" /> в розничном магазине</p>
                        </li>
                        <li>
                            <img src="img/svg/ico-work-2.svg" alt="" />
                            <h6 className="f-exp">2. Получайте кэшбек бонусами</h6>
                            <p>от 5% до 10% и оплачивайте бонусами<br className="desk"/> до 50%<br  className="mob"/> от первоначальной<br className="mob"/> стоимости товара</p>
                        </li>
                        <li>
                            <img src="img/svg/ico-work-3.svg" alt="" />
                            <h6 className="f-exp">3. Переходите<br  className="mob" /> на уровень выше</h6>
                            <p>и получайте ещё больше специальных<br  className="desk" /> акций<br  className="mob" /> и персональных предложений</p>
                        </li>
                    </ul>
                    <div className="form-btns btns-center">
                     <button className="btn btn-secondary"
                                                      onClick={(event) => {
                                                    event.preventDefault();
                                                    if (!state.customer?.id) {
                                                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'});
                                                    } else {
                                                        window.location.href = '/personal-area/privilege';
                                                    }
                                                }}
                                                        >Стать участником</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="text-page bonus pt">
        <div className="container-fluid">
            <div className="row reserve__head">
                <div className="col-lg-6 text-page__left f-exp">
                    <h3 className="text-page__title">Уровни участия</h3>
                </div>
                <div className="col-lg-6 text-page__right">
                    <blockquote>
                        <b>Возможности для всех<br className="desk" /> уровней участия:</b>
                        <ul>
                            <li>Виртуальная карта (накопление и списание бонусов по номеру телефона)</li>
                            <li>доступ к дополнительным бонусам в личном кабинете</li>
                        </ul>
                    </blockquote>
                </div>
            </div>
            <div className="row text-page__cards">
                <div className="col-lg-4">
                    <div className="text-page__card basic">
                        <div className="img f-exp">
                            <div className="top">
                                <h2>BASIC</h2>
                                <h5>5%</h5>
                            </div>
                            <div className="bot">
                                <h5>BAON Привилегия</h5>
                            </div>
                        </div>
                        <div className="advantage information">
                            <h5 className="information__link f-exp">
                                <b>Преимущества</b>
                                <i className="ico ico-chevron-down-b"></i>
                            </h5>
                            <div className="information__collapse">
                                <ul>
                                    <li>
                                        <p><b>Условия перехода на следующий уровень:</b></p>
                                        <p>Совершите покупки на сумму от 1 до 24 999 рублей</p>
                                    </li>
                                    <li>
                                        <p><b>Кэшбек бонусами</b></p>
                                        <ol>
                                            <li><p>от суммы первой покупки – 10%</p></li>
                                            <li><p>дальнейшая покупка товаров – 5%</p></li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p><b>Подарок в День Рождения</b></p>
                                        <p>1 000 бонусов</p>
                                    </li>
                                    <li>
                                        <p><b>Персональные предложения</b></p>
                                        <p>—</p>
                                    </li>
                                    <li>
                                        <p><b>Доступ к закрытым распродажам</b></p>
                                        <p>—</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-page__card silver">
                        <div className="img f-exp">
                            <div className="top">
                                <h2>SILVER</h2>
                                <h5>7%</h5>
                            </div>
                            <div className="bot">
                                <h5>BAON Привилегия</h5>
                            </div>
                        </div>
                        <div className="advantage information">
                            <h5 className="information__link f-exp">
                                <b>Преимущества</b>
                                <i className="ico ico-chevron-down-b"></i>
                            </h5>
                            <div className="information__collapse">
                                <ul>
                                    <li>
                                        <p><b>Условия перехода на следующий уровень:</b></p>
                                        <p>Совершите покупки на сумму от 25 000 до 49 999 рублей</p>
                                    </li>
                                    <li>
                                        <p><b>Кэшбек бонусами</b></p>
                                        <ol>
                                            <li><p>от суммы первой покупки – 10%</p></li>
                                            <li><p>дальнейшая покупка товаров – 7%</p></li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p><b>Подарок в День Рождения</b></p>
                                        <p>1 500 бонусов</p>
                                    </li>
                                    <li>
                                        <p><b>Персональные предложения</b></p>
                                        <p>Есть</p>
                                    </li>
                                    <li>
                                        <p><b>Доступ к закрытым распродажам</b></p>
                                        <p>—</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-page__card gold">
                        <div className="img f-exp">
                            <div className="top">
                                <h2>GOLD</h2>
                                <h5>10%</h5>
                            </div>
                            <div className="bot">
                                <h5>BAON Привилегия</h5>
                            </div>
                        </div>
                        <div className="advantage information">
                            <h5 className="information__link f-exp">
                                <b>Преимущества</b>
                                <i className="ico ico-chevron-down-b"></i>
                            </h5>
                            <div className="information__collapse">
                                <ul>
                                    <li>
                                        <p><b>Условия перехода на следующий уровень:</b></p>
                                        <p>Совершите покупки на сумму от 50 000 рублей</p>
                                    </li>
                                    <li>
                                        <p><b>Кэшбек бонусами</b></p>
                                        <ol>
                                            <li><p>от суммы первой покупки – 10%</p></li>
                                            <li><p>дальнейшая покупка товаров – 10%</p></li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p><b>Подарок в День Рождения</b></p>
                                        <p>2 000 бонусов</p>
                                    </li>
                                    <li>
                                        <p><b>Персональные предложения</b></p>
                                        <p>Есть</p>
                                    </li>
                                    <li>
                                        <p><b>Доступ к закрытым распродажам</b></p>
                                        <p>Есть</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="form-btns btns-center">
                   <button className="btn btn-secondary"
                                                            onClick={(event) => {
                                                    event.preventDefault();
                                                    if (!state.customer?.id) {
                                                    dispatch({type: ReducerActionType.SHOW_POPUP, payload: 'showAuthPopup'});
                                                    } else {
                                                        window.location.href = '/personal-area/privilege';
                                                    }
                                                }}
                                                        >Стать участником</button>
                    </div>
                </div>
            </div>
            <div className="row reserve__head">
                <div className="col-lg-6 text-page__left f-exp">
                    <h3 className="text-page__title">Вопросы-ответы</h3>
                </div>
            </div>
            <div className="row faq mb">
                <div className="col">
                    <div className="text-page__list faq__list">
 <div className={"text-page__list-block information bd " + (block === 't1' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't1' ? '' : 't1')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Кто такой участник бонусной программы?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                                {block === 't1' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Участник бонусной программы – клиент, зарегистрировавшийся в бонусной программе в фирменном розничном магазине BAON или на сайте baon.ru/privilege/</p>
                                </div>
                            </div>}
                        </div>
       <div className={"text-page__list-block information bd " + (block === 't2' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't2' ? '' : 't2')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Что такое бонусный счёт?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                          {block === 't2' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Бонусный счет – отображение всех начислений и списаний бонусных баллов в личном кабинете участника baon.ru/privilege/</p>
                                </div>
                            </div>}
                        </div>
                <div className={"text-page__list-block information bd " + (block === 't3' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't3' ? '' : 't3')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Как узнать, сколько бонусов у меня на бонусной карте?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                                {block === 't3' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>
                                        Состояние бонусного счета можно узнать<br />
                                        1. У персонала фирменного магазина BAON<br />
                                        2. В личном кабинете на сайте baon.ru/privilege/<br />
                                        3. Через запрос на почту my@baon.ru<br />
                                        4. В приложении BAON<br />
                                    </p>
                                </div>
                            </div>}
                        </div>
                       <div className={"text-page__list-block information bd " + (block === 't4' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't4' ? '' : 't4')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Что делать, если покупка не учтена на бонусном счёте?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                           {block === 't4' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>
                                        Участнику бонусной программы необходимо написать на электронную почту my@baon.ru и в письме указать:<br />
                                        1. дату покупки<br />
                                        2. магазин совершения покупки<br />
                                        3. сумму покупки<br />
                                        4. номер бонусной карты участника<br />
                                        5. номер мобильного телефона участника<br />
                                    </p>
                                </div>
                            </div>}
                        </div>
              <div className={"text-page__list-block information bd " + (block === 't5' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't5' ? '' : 't5')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Мне неправильно начислили бонусы. Что делать?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                           {block === 't5' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Напишите на электронную почту my@baon.ru</p>
                                </div>
                            </div>}
                        </div>
                          <div className={"text-page__list-block information bd " + (block === 't6' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't6' ? '' : 't6')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Как я буду узнавать об акциях и закрытых распродажах?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                             {block === 't6' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Информация обо всех акциях, спецпредложениях, начислениях и списаниях бонусов выводится в личном кабинете на сайте baon.ru/privilege/.Также все участники бонусной программы получают информацию об акциях, распродажах и спецпредложениях через каналы коммуникации, на которые они подписаны: смс-информирование, email -информирование, сообщения в личный кабинет участника бонусной программы.</p>
                                </div>
                            </div>}
                        </div>
                    <div className={"text-page__list-block information bd " + (block === 't7' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't7' ? '' : 't7')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Какие есть виды бонусов и как они действуют?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                        {block === 't7' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <h5 className="f-exp">Приветственные бонусы</h5>
                                    <p>Приветственные бонусы начисляются только для новых участников бонусной программы единоразово в размере 10% от итоговой суммы первой покупки. При проведении дополнительных акций размер начисляемых приветственных бонусов может быть увеличен до 30%.</p>
                                </div>
                                <div className="text-page__col-wrap">
                                    <h5 className="f-exp">Регулярные бонусы</h5>
                                    <p>Регулярные бонусы активируются на бонусном счете участника через 15 дней после начисления.</p>
                                </div>
                                <div className="text-page__col-wrap">
                                    <h5 className="f-exp">Бонусы на день рождения клиента.</h5>
                                    <p>
                                        Бонусы в подарок на день рождения клиента начисляются один раз в год за неделю до даты рождения клиента на бонусный счет. Бонусы начисляются в зависимости от уровня карты:<br />
                                        1 уровню карты начисляются 1000 бонусов;<br />
                                        2 уровню карты начисляются 1500 бонусов;<br />
                                        3 уровню карты начисляются 2000 бонусов.<br />
                                    </p>
                                </div>
                                <div className="text-page__col-wrap">
                                    <h5 className="f-exp">Бонусы за покупку интернет-заказа в магазине.</h5>
                                    <p>При выкупе интернет-заказа в магазине в течение 2 дней клиент получает 500 бонусов. Бонусы активны 30 дней с момента начисления.</p>
                                </div>
                            </div>}
                        </div>
                      <div className={"text-page__list-block information bd " + (block === 't8' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't8' ? '' : 't8')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Когда бонусы появятся на бонусном счёте?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                           {block === 't8' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Все сроки действия бонусов отображены в личном кабинете на сайте baon.ru/privilege/</p>
                                </div>
                            </div>}
                        </div>
                       <div className={"text-page__list-block information bd " + (block === 't9' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't9' ? '' : 't9')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>За покупку каких товаров/услуг не начисляются бонусы?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                             {block === 't9' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Бонусы не начисляются за покупку:</p>
                                    <ul>
                                        <li>подарочных сертификатов</li>
                                        <li>пакетов</li>
                                        <li>бытовой техники</li>
                                        <li>товаров для дома</li>
                                        <li>услуг доставки</li>
                                    </ul>
                                </div>
                            </div>}
                        </div>
                      <div className={"text-page__list-block information bd " + (block === 't10' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't10' ? '' : 't10')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Какие товары/услуги нельзя оплатить бонусами?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                            {block === 't10' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Бонусами нельзя оплатить:</p>
                                    <ul>
                                        <li>товары со скидкой более 50% от первоначальной цены</li>
                                        <li>подарочные сертификаты</li>
                                        <li>чулочно-носочные изделия</li>
                                        <li>нижнее белье</li>
                                        <li>гели для стирки</li>
                                        <li>услуги доставки</li>
                                        <li>зонты</li>
                                        <li>бытовая техника</li>
                                        <li>косметика</li>
                                        <li>товары для дома</li>
                                        <li>прочие сопутствующие товары</li>
                                    </ul>
                                </div>
                            </div>}
                        </div>
                   <div className={"text-page__list-block information bd " + (block === 't11' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't11' ? '' : 't11')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Как оплачивать покупку бонусами «СПАСИБО»?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                            {block === 't11' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Покупка оплачивается бонусами в порядке очередности: сначала покупка оплачивается бонусами “СПАСИБО”, потом бонусами “БАОН”. Общая сумма скидки при оплате бонусами не может превышать 50%.</p>
                                </div>
                            </div>}
                        </div>
                    <div className={"text-page__list-block information bd " + (block === 't12' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't12' ? '' : 't12')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Могу ли я использовать бонусы при покупке товара в кредит?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                           {block === 't12' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Нет, бонусными баллами нельзя оплатить товар, который покупается в кредит. Но бонусы будут начислены на товар, который куплен в кредит.</p>
                                </div>
                            </div>}
                        </div>
                       <div className={"text-page__list-block information bd " + (block === 't13' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't13' ? '' : 't13')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Могу ли я одновременно использовать и бонусы, и подарочный сертификат?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                    {block === 't13' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Да. Покупку можно оплатить бонусами, а потом подарочным сертификатом.</p>
                                </div>
                            </div>}
                        </div>
                        <div className={"text-page__list-block information bd " + (block === 't14' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't14' ? '' : 't14')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Если я возвращаю товар, что произойдет с моими бонусами?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                         {block === 't14' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>При возврате товара бонусы, начисленные за эту покупку, будут возвращены.</p>
                                </div>
                            </div>}
                        </div>
                 <div className={"text-page__list-block information bd " + (block === 't15' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't15' ? '' : 't15')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Где я могу воспользоваться бонусной картой?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                          {block === 't15' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Во всех магазинах BAON и в интернет-магазине baon.ru.</p>
                                </div>
                            </div>}
                        </div>
                     <div className={"text-page__list-block information bd " + (block === 't16' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't16' ? '' : 't16')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Могу ли я подарить бонусы?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                              {block === 't16' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Нет. Бонусы нельзя дарить.</p>
                                </div>
                            </div>}
                        </div>
                    <div className={"text-page__list-block information bd " + (block === 't17' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't17' ? '' : 't17')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Какие сроки сгорания бонусных баллов?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                             {block === 't17' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>
                                        Все сроки действия бонусов отображены в личном кабинете на сайте.<br />
                                        Регулярные бонусы аннулируются через 1 год после начисления.<br />
                                        Бонусы за регистрацию в личном кабинете на сайте аннулируются через 1 месяц после начисления.<br />
                                        Приветственные бонусы аннулируется через 1 месяц после начисления.<br />
                                        Бонусы на день рождения клиента аннулируются через 15 дней после начисления.<br />
                                        Акционные бонусные аннулируются в соответствии с условиями акции.<br />
                                        Бонусы за выкуп интернет-заказа в розничном магазине аннулируются через 30 дней с момента начисления.<br />
                                    </p>
                                </div>
                            </div>}
                        </div>
                  <div className={"text-page__list-block information bd " + (block === 't18' ? 'show' : '')}>
                                    <a className="information__link f-exp" href="/" onClick={(event) => {
                                        event.preventDefault()
                                        setBlock(block === 't18' ? '' : 't18')
                                    }}>
                                <div className="circle"><i className="ico ico-question"></i></div>
                                <p>Когда истекает период участия в программе?</p>
                                <i className="ico ico-chevron-down-b"></i>
                            </a>
                                  {block === 't18' && <div className="information__collapse">
                                <div className="text-page__col-wrap">
                                    <p>Бонусная программа лояльности бессрочная.</p>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="text-center">
                        <a className="text-page__link f-exp" href="/landing/30/">Полные правила бонусной программы лояльности BAON</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="text-page bonus">
        <div className="container-fluid">
 <div className="row text-page__banners">
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="/img/text-page-banner-1.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="/img/text-page-banner-1.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Магазины</h2>
                                <p>
                                    Найти ближайший магазин Baon
                                    <br/> в России и Казахстане
                                </p>
                                <a className="btn btn-primary" href="/shops">
                                    Найти магазин
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="/img/text-page-banner-2.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="/img/text-page-banner-2.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Есть вопрос?</h2>
                                <p>
                                    Звоните 8 (800) 500-73-17 (звонок по России
                                    <br/> бесплатный) или задайте вопрос на сайте
                                </p>
                                <button className="btn btn-primary" onClick={(event) => {
                                    event.preventDefault()
                                    dispatch({type: ReducerActionType.TOGGLE_QUESTION_POPUP})
                                }}>
                                    Подробнее
                                </button>
                            </div>
                        </div>
                    </div>
        </div>
    </section>

       <Subscribe/>

</main>

 )
 }