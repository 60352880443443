import ModelReviewInterface from "../../../interfaces/model/ModelReviewInterface";
import Stars from "./Stars";
import moment from "moment";
import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from "swiper";
import {Navigation,Pagination} from "swiper/modules";


export default function Review({ review }: { review: ModelReviewInterface }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const getDate = (date: string) => {
        return moment(date).format('DD.MM.YYYY');
    };

    const openModal = (index: number) => {
        setActiveIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="product__feedback-block">
                <div className="img">
                    <img src="/img/svg/feeedback-img-1.svg" alt="" />
                </div>
                <div className="info">
                    <div className="top">
                        <div className="top-block">
                            <h6>{review.customer_name}</h6>
                            <p>{getDate(review.create_date)}</p>
                        </div>
                        {review.partner && review.partner !== 'baon' && <p className="desk">{review.partner}</p>}
                    </div>
                    <div className="meta">
                        {review.estimate && <Stars reviewAverageEstimate={review.estimate}/>}
                        {review.fit_to_size_name && <p className="desk">{review.fit_to_size_name}</p>}
                    </div>
                    <div className="mob">
                        <div className="bot"></div>
                    </div>
                    <div className="collapse-mob">
                        <div className="body">
                            <p>{review.review}</p>
                        </div>
                        {review.images && review.images.length > 0 && (
                            <div className="imgs">
                                {review.images.map((image, index) => (
                                    <a key={index} className="img" href="#" onClick={(e) => { e.preventDefault(); openModal(index); }}>
                                        <img src={image} alt={`Review image ${index + 1}`} />
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {review.moderator_note && (
                <div className="product__feedback-block feedback-note">
                    <div className="img">
                        <img src="/img/svg/feeedback-img-2.svg" alt="" />
                    </div>
                    <div className="info">
                        <div className="top">
                            <div className="top-block">
                                <h6>Baon</h6>
                            </div>
                            <p>{getDate(review.create_date)}</p>
                        </div>
                        <div className="meta">
                            <h6>Компания Baon</h6>
                        </div>
                        <div className="collapse-mob">
                            <div className="body">
                                <p>{review.moderator_note}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <div className="modal reviewImagesPopup" style={{ display: 'block' }} onClick={closeModal}>
                    <div className="modal-dialog modal-md" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" onClick={closeModal}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Swiper
                                    initialSlide={activeIndex}
                                    navigation
                                    pagination={{ clickable: true }}
                                    spaceBetween={50}
                                    slidesPerView={1}
                                >
                                    {review.images.map((image, index) => (
                                        <SwiperSlide key={index} className="reviewImagesPopup_item">
                                            <img src={image} alt={`Review image ${index + 1}`} style={{ width: '100%' }} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}