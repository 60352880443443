import React, {useState} from 'react';
import Spacer from '../Spacer/Spacer.jsx';

const HiddenText = (props) => {
	const [isCollapsed, setIsCollapsed] = useState(true);
	const toggleCollapse = (e) => {
		e.preventDefault();
		setIsCollapsed(!isCollapsed);
	};

	return (
		<>
			<div className="text-page__list-block bd">
				<div className="text-page__col-wrap information">
					<p>{props.params.title}</p>
					<div className={`information__collapse ${isCollapsed ? 'hidden' : ''}`}>
						<p>{props.params.text}</p>
					</div>
					<a
						className="information__link information__link--common"
						href="#"
						onClick={toggleCollapse}
					>
						<span>{isCollapsed ? 'Подробнее' : 'Свернуть'}</span>
					</a>
				</div>
			</div>
			<Spacer />
		</>
	);
};

export default HiddenText;