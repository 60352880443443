import Spacer from '../Spacer/Spacer.jsx'
import FaqElement from './FaqElement.jsx'
import style from './Faq.module.scss'
import {getBaseImagePath} from "../../../../ui/catalog/Utils";


const Faq = props => {
	const questions = props.params.dataQuestions
	return (
		<>
			<FaqElement>
				{questions.map((question, i) => (
					<FaqElement.Entity key={i}>
						<FaqElement.Question>{question.question}</FaqElement.Question>
						<FaqElement.Answer>
							<FaqElement.Text>
								{question.answer}
							</FaqElement.Text>
							{question.img &&
								<div className={style.answerImage}>
									<img
										src={getBaseImagePath() + question.img}
										alt={question.img}
									/>
								</div>
							}
						</FaqElement.Answer>

						<hr />
					</FaqElement.Entity>
				))}
			</FaqElement>
			<Spacer />
		</>
	)
}

export default Faq
