import {useQuery} from "@tanstack/react-query";
import {getModelColorItems} from "../../api/catalog";
import CatalogList from "./CatalogList";
import {useContext, useEffect} from "react";
import {GlobalContext, ReducerActionType} from "../../GlobalReducer";
import ModelColorItemInterface from "../../interfaces/model/ModelColorItemInterface";
import PageMetaInterface from "../../interfaces/core/PageMetaInterface";
import Breadcrumbs from "../core/Breadcrumbs";
import BreadcrumbInterface from "../../interfaces/core/BreadcrumbInterface";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function Favorites() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const getPathName = () => {
        const favorites: ModelColorItemInterface[] = state.favorites
        return favorites.map(modelColorItem => modelColorItem.model_id).join(',')
    }

    const {data: modelColorItemsData} = useQuery({
        queryKey: ['favorite'],
        queryFn: () => getModelColorItems({
            url: '/catalog/index/models/' + getPathName()
        })
    })

    const {model_color_items: modelColorItems, meta, category, breadcrumbs, banners, tags, properties} = modelColorItemsData || {
        model_color_items: [],
        meta: undefined,
        properties: []
    }

    const getFavoriteItems = () => {
        const favorites: ModelColorItemInterface[] = state.favorites
        return modelColorItems.filter(modelColorItem => favorites.find(modelColorItemFav => modelColorItemFav.model_id === modelColorItem.model_id && modelColorItemFav.color_special === modelColorItem.color_special))
    }

    useEffect(() => {
        dispatch({type: ReducerActionType.SET_PARAM, paramName: 'meta', payload: {
                html_header: 'Избранное'
            }})
    }, []);

    const getBreadcrumbs = (): BreadcrumbInterface[] => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Избранное'
            }
        ]
    }

    if (!getFavoriteItems().length) {
        return (<main className="main-catalog">
            <section className="catalog promo">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs() || []}/>
                        </div>
                    </div>
                    <div className="catalog__empty">
                        <h3 className="f-exp">В избранном пока ничего нет</h3>
                        <a className="btn btn-secondary" href="/">Вернуться на главную</a>
                    </div>
                </div>
            </section>
        </main>)

    }

    return (<CatalogList modelColorItems={getFavoriteItems()} category={category}
                         breadcrumbs={getBreadcrumbs()} superCategories={[]} banners={[]} tags={tags} categories={[]} properties={properties} filterTags={[]}></CatalogList>)
}