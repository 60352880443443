import TopNavigation from "./TopNavigation";
import RefundInterface from "../../../interfaces/customer/RefundInterface";
import {useForm} from "react-hook-form";
import React, {useEffect} from "react";
import {OrderInterface} from "../../../interfaces/customer/OrderInterface";

export default function Step3({params, setParams, createRefund}: {
    params: RefundInterface,
    setParams: (params: RefundInterface) => void,
    createRefund: (params: RefundInterface) => void
}) {

    const {register, reset, handleSubmit, formState: {errors}} = useForm();

    useEffect(() => {
        reset(params)
    }, []);

    const formSubmit = (formParams: any) => {
        createRefund({...params, ...formParams})
    }


    useEffect(() => {
        let localParams = {
            ...params, ...{
                lastNameCard: params.lastNameCard || params.lastName,
                firstNameCard: params.firstNameCard || params.firstName,
                patronymicNameCard: params.patronymicNameCard || params.patronymicName,
            }
        }

        reset(localParams)
    }, []);

    return (<div className="lk__refund-petition--requisites">
            <form onSubmit={handleSubmit(formSubmit)}>
                <div className="row">
                    <div className="col-lg-5">
                        <TopNavigation step={3}/>
                        <div className="form-line">
                            <label>Фамилия держателя карты (рус.)</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.lastNameCard ? ' is-invalid' : '')} {...register("lastNameCard", {
                                    required: true
                                })} type="text" placeholder="Фамилия держателя карты (рус.)"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>Имя держателя карты (рус.)</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.firstNameCard ? ' is-invalid' : '')} {...register("firstNameCard", {
                                    required: true
                                })} type="text" placeholder="Имя держателя карты (рус.)"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>Отчество держателя карты (рус.)</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.patronymicNameCard ? ' is-invalid' : '')} {...register("patronymicNameCard", {
                                    required: true
                                })} type="text" placeholder="Отчество держателя карты (рус.)"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>Наименование банка</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.bankName ? ' is-invalid' : '')} {...register("bankName", {
                                    required: true
                                })} type="text" placeholder="Наименование банка"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>Корреспондентский счет</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.correspondentAccount ? ' is-invalid' : '')} {...register("correspondentAccount", {
                                    required: true,
                                    validate: v => /^\d{20}$/.test(v)
                                })} type="text" placeholder="Корреспондентский счет"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>ИНН банка</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.inn ? ' is-invalid' : '')} {...register("inn", {
                                    required: true,
                                    validate: v => /^\d{10}$/.test(v)
                                })} type="text" placeholder="ИНН банка"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>БИК банка</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.bic ? ' is-invalid' : '')} {...register("bic", {
                                    required: true,
                                    validate: v => /^\d{9}$/.test(v)
                                })} type="text" placeholder="БИК банка"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>Лицевой счет карты (только цифры)</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.personalAccount ? ' is-invalid' : '')} {...register("personalAccount", {
                                    required: true,
                                    validate: v => /^\d{20}$/.test(v)
                                })} type="text" placeholder="Лицевой счет карты"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <label>Номер карты</label>
                            <div className="form-group">
                                <input
                                    className={"form-control" + (errors.cardNumber ? ' is-invalid' : '')} {...register("cardNumber", {
                                    required: true,
                                    validate: v => /^\d{4} ?\d{4} ?\d{4} ?\d{4,6}$/.test(v)
                                })} type="text" placeholder="____ ____ ____ ____"/>
                            </div>
                        </div>
                        <div className="form-line">
                            <div className="form-btns">
                                <button className="btn btn-secondary-tr" onClick={(event) => {
                                    event.preventDefault();
                                    setParams({...params, ...{step: 1}})
                                }} style={{marginRight: '1.5rem', width: '18.5rem'}}>Назад
                                </button>
                                <button className="btn btn-secondary" style={{width: '18.5rem'}}>Далее</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}