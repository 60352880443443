
import React, { useEffect } from 'react';
import AdvantagesBanners from "../../interfaces/main/AdvantagesBannerInterface";

const iconClasses = ["ico-discount", "ico-notebook", "ico-car", "ico-buyback"];

export default function AdvantagesList({advantagesBanners}: { advantagesBanners: AdvantagesBanners[] })

{

    return (<section className="advantages-list">
            <div className="container-fluid">
                <div className="row desk">
                {advantagesBanners.map((banner, index) => <div key={banner.title} className="col-3">
                        <a className="block" href={banner.url}>
                            <div className="circle">
                                <i className={`ico ${iconClasses[index % iconClasses.length]}`} />
                            </div>
                            <p>{banner.title}</p>
                        </a>
                    </div>
                )}
                </div>
                <div className="row mob">
                    <div className="advantages-list__slider">
                        <div className="swiper-wrapper">

                {advantagesBanners.map((banner, index) => <a key={banner.title} href={banner.url}  className="swiper-slide block">
                            <div className="circle">
                                <i className={`ico ${iconClasses[index % iconClasses.length]}`} />
                            </div>
                            <p>{banner.title}</p>
                    </a>
                )}
                            {/* <div className="swiper-slide block">
                                <div className="circle">
                                    <i className="ico ico-car"/>
                                </div>
                                <p>
                                    Бесплатная доставка
                                    <br/> от 3 000 Р
                                </p>
                            </div>
                            <div className="swiper-slide block">
                                <div className="circle">
                                    <i className="ico ico-notebook"/>
                                </div>
                                <p>Скидка 5% при оплате онлайн</p>
                            </div>
                            <div className="swiper-slide block">
                                <div className="circle">
                                    <i className="ico ico-discount"/>
                                </div>
                                <p>Цена ниже при оплате бонусами</p>
                            </div>
                            <div className="swiper-slide block">
                                <div className="circle">
                                    <i className="ico ico-buyback"/>
                                </div>
                                <p>
                                    Лёгкий возврат
                                    <br/> в магазине
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}