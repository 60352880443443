import Spacer from '../Spacer/Spacer.jsx'
import style from './TextHeader.module.scss'

const TextHeader = props => {
	const data = props.params

	return (
		<>
			<div className={'text-center ' + style.rowBlockText}>
				<div
					className={style.title}
					dangerouslySetInnerHTML={{__html: data.title}}
				></div>
				{data.text && data.text !== '' ? (
					<p
						className={style.text}
						dangerouslySetInnerHTML={{__html: data.text}}
					></p>
				) : null}

				{data.url && data.url !== '' ? (
					<a href={data.url} className={style.btn}>
						<button className={style.btn + ' btn btn-secondary'}>Купить</button>
					</a>
				) : null}
			</div>
			<Spacer />
		</>
	)
}

export default TextHeader
