import React, {useState} from "react";
import {RefundPositionInterface} from "../../../interfaces/customer/RefundInterface";
import Money from "../../core/Money";

export default function RefundPosition({position, setPosition} : {position: RefundPositionInterface, setPosition: (position: RefundPositionInterface) => void}) {

    const [isReasonsOpen, setIsReasonsOpen] = useState(false);
    const [isSizeOpen, setIsSizeOpen] = useState(false);

    const reasons = [
        {id: 1, name: 'Брак'},
        {id: 2, name: 'Не подошел размер'},
        {id: 3, name: 'Отличается от фотографии на сайте'},
        {id: 4, name: 'Товар не подходит мне'},
        {id: 5, name: 'Получил не тот товар, что заказывал'},
    ];

    const getReasonName = () => {
        return reasons.find(reason => reason.id === position.reason?.id)?.name
    }

    return (<li>
        <div className="product">
            <div>
                <div className="form-checkbox mt-5">
                    <input className="hidden" id="lk-refund-prod-01" type="checkbox" onChange={() => {}} checked={position.isSelected}/>
                    <label className="label-dot" htmlFor="lk-refund-prod-01" onClick={() => {
                        setPosition({...position, ...{isSelected: !position.isSelected}})
                    }}></label>
                </div>
            </div>

            <div className="img mx-4"><img src={position.modelColor.images.find(image => image.type === 'front')?.url} alt=""
                                      width="78"/>
            </div>
            <div className="text">
                <p>{position.modelColor.name}</p>
                <small><span>Цвет: {position.modelColor.color} </span></small>
                {!position.size && <div className="form-selector">
                    <a className="form-selector--btn f-exp" onClick={(e) => {
                        setIsSizeOpen(!isSizeOpen);
                        e.preventDefault();
                    }} href="">{position.size ?
                        <span>{position.size}</span> :
                        <span className={'text-secondary'}>Выберите размер</span>}</a>
                    {isSizeOpen && <div className="form-selector--list">
                        <ul>
                            {position.modelColor.sizes.map(size => <li key={size}><a href="" onClick={(e) => {
                                e.preventDefault()
                                setPosition({...position, ...{size: size}})
                                setIsSizeOpen(false)
                            }}>{size}</a></li>)}
                        </ul>
                    </div>}
                </div>}
                {position.size && <small><span className={position.isManual ? 'dashed pointer' : ''} onClick={() => {
                    if (position.isManual) {
                        setPosition({...position, ...{size: undefined}});
                    }
                    ;
                }}>Размер: {position.size}</span></small>}
            </div>
        </div>
        <div><p>{position.modelColor.articul}</p></div>
        <div>

            <div className="form-selector">
                <a className="form-selector--btn f-exp" onClick={(e) => {
                    setIsReasonsOpen(!isReasonsOpen);
                    e.preventDefault();
                }} href="">{getReasonName() ?
                    <span>{getReasonName()}</span> :
                    <span className={'text-secondary'}>Выберите причину возврата</span>}</a>
                {isReasonsOpen && <div className="form-selector--list">
                    <ul>
                        {reasons.map(reason => <li key={reason.id}><a href="" onClick={(e) => {
                            e.preventDefault()
                            setPosition({...position, ...{reason: reason}})
                            setIsReasonsOpen(false)
                        }}>{reason.name}</a></li>)}
                    </ul>
                </div>}
            </div>
        </div>
        <div><p><input type="number" className={'form-control'} style={{width: '100px'}} value={position.quantity} onChange={(e) => {
            if(parseInt(e.currentTarget.value) <= position.quantity && parseInt(e.currentTarget.value) > 1){
                setPosition({...position, ...{quantity: parseInt(e.currentTarget.value)}})
            }
        }} /></p></div>
        <div>
            {!position.isManual && <p><Money value={position.price} /></p>}
            {position.isManual && <><input type="number" className={'form-control text-center'} style={{width: '160px', display: 'inline-block'}} value={position.price.amount / 100}
                           onChange={(e) => {
                               if (parseInt(e.currentTarget.value) > 0) {
                                   setPosition({
                                       ...position, ...{
                                           price: {
                                               amount: parseInt(e.currentTarget.value) * 100,
                                               currency: position.price.currency
                                           }
                                       }
                                   })
                               }
                           }}/><span style={{display: 'inline-block'}}>₽</span></>}
        </div>
    </li>)
}