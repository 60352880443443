import {useContext} from "react";
import {GlobalContext, PopupType, ReducerActionType} from "../../GlobalReducer";
import {ReducerStateInterface} from "../../interfaces/core/ReducerStateInterface";

export default function LoginTypeSwitch() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);


    return (<ul className="nav nav-tabs f-exp" >
        <li className="nav-item" >
            <button type={'button'}  className={"nav-link "  + (state.showAuthByPhonePopup && "active")} onClick={() => {
                dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.LOGIN_BY_PHONE})
            }}>По телефону</button>
        </li>
        <li className="nav-item" >
            <button type={'button'}  className={"nav-link " + (state.showAuthPopup && "active")} onClick={() => {
                dispatch({type: ReducerActionType.SHOW_POPUP, payload: PopupType.LOGIN})
            }}>По e-mail</button>
        </li>
    </ul>)
}