import TagInterface from "../../../interfaces/core/TagInterface";
import {SelectedFiltersInterface} from "../../../interfaces/catalog/SelectedFiltersInterface";


export interface ParamsFromFilterItem {
    name: string
    value: string[]
    id?: number
}

export default function useUrlFilterConvertor(filters:SelectedFiltersInterface, tags: TagInterface[], sortBy:string|null) {
    const DELIMITER = '--'
    const SPACE_REPLACEMENT = '__'
    const PROP_PREFIX = 'prop_'

    const filterFields = () => {
        return ['color', 'price', 'size', 'category']
    }

    const convertToArray = () => {
        let out: {name: string, values: string}[] = []

        if(filters.colors.length){
            out.push({
                name: 'color',
                values: filters.colors.map(color => color.color).join(DELIMITER)
            })
        }

        if(filters.priceRange){
            if(filters.priceRange?.from || filters.priceRange?.to){
                out.push({
                    name: 'price',
                    values: (filters.priceRange?.from ||'') + DELIMITER + (filters.priceRange?.to || ''),
                })
            }
        }

        if(filters.sizes.length){
            out.push({
                name: 'size',
                values:  filters.sizes.join(DELIMITER),
            })

        }

        if(filters.superCategories.length){
            out.push({
                name: 'supercategory',
                values: filters.superCategories.map(superCategory => superCategory.id.join(',')).join(DELIMITER)
            })
        }

        if(filters.categories.length){
            out.push({
                name: 'category',
                values: filters.categories.map(superCategory => superCategory.id).join(DELIMITER)
            })
        }

        if(filters.properties.size){
            filters.properties.forEach((value, key) => {
                out.push({
                    name: PROP_PREFIX + key,
                    values: value.join(DELIMITER).replace(/ /, SPACE_REPLACEMENT)
                })
            })
        }

        if(tags.length){
            out.push({
                name: 'tags',
                values: tags.map(tag => tag.id).join(DELIMITER),
            })
        }

        if(sortBy){
            out.push({
                name: 'sort',
                values: sortBy,
            })
        }

        return out
    }

    const  convertToUrl = ():string => {
        const urlSearchParams = new URLSearchParams(window.location.search)

        convertToArray().map(item => {
            urlSearchParams.set(item.name, item.values)
        })

        return urlSearchParams.toString()
    }

    const convertFromUrl = (url: string): ParamsFromFilterItem[] => {
        const urlSearchParams = new URLSearchParams(url)

        let out: ParamsFromFilterItem[] = [];

        urlSearchParams.forEach((value, key) => {
            value = value.replace(SPACE_REPLACEMENT, ' ')
            if(key.indexOf(PROP_PREFIX) === 0){
                out.push({
                    name: 'property',
                    id: parseInt(key.replace(PROP_PREFIX, '')),
                    value: value.split(DELIMITER)
                })
            }else{
                out.push({
                    name: key,
                    value: value.split(DELIMITER)
                })
            }
        })

        return out
    }

    return {convertToArray, convertToUrl, convertFromUrl, filterFields}
}