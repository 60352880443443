import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                {error.statusText === 'Not Found' && <h1 className="display-1 fw-bold">500</h1>}
                <p className="fs-3"><span className="text-danger">Opps!</span> Runtime Error.</p>
                <p className="lead">
                    {error.statusText || error.message}
                </p>
                <a href="/" className="btn btn-primary">Go Home</a>
            </div>
        </div>
    );
}