import {useContext} from "react";
import {ReducerStateInterface} from "./interfaces/core/ReducerStateInterface";
import {GlobalContext} from "./GlobalReducer";
import {useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";

export default function MetaParams() {
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const location = useLocation()

    const getCanonical = () => {
        const existCanonical = state.meta?.meta_tags?.find(tag => tag.name === 'canonical')
        if (existCanonical) {
            return existCanonical.value
        }

        let canonical = 'https://baon.ru' + window.location.pathname;
        ['userSort', 'event', 'simpleColor', 'sizes', 'priceRange', 'partner', 'page'].map(paramName => {
            canonical = canonical.replace("/" + paramName + "/[^/]+/?/", '')
        })
        return canonical
    }

    return (<HelmetProvider>
            <Helmet>
            { /* Standard metadata tags */}
            {state.meta?.title && <title>{state.meta.title}</title>}
            {state.meta?.description && <meta name="description" content={state.meta.description}/>}
            {state.meta?.meta_tags && state.meta.meta_tags.map(tag => tag.name !== 'canonical' &&
                <meta name={tag.name} content={tag.value}/>
            )}
            <link rel="canonical" href={getCanonical()}/>

            <meta property="og:title" content={state.meta?.html_header || state.meta?.title}/>
            <meta property="og:type" content={location.pathname.indexOf('/blog/') === 0 ? 'article' : 'website'}/>
            <meta property="og:description" content={state.meta?.description}/>
            <meta property="og:url" content={"https://baon.ru" + location.pathname}/>
            <meta property="og:locale" content="ru_RU"/>
            <meta property="og:image" content={state.meta?.image || "https://baon.ru/public/new-design/main/top-logo.svg"}/>
            <meta property="og:site_name" content="Baon"/>

        </Helmet>
    </HelmetProvider>)

}


/**
 * $prt = explode("?", $_SERVER['REQUEST_URI']);
 *         $url = reset($prt);
 *         foreach(array('userSort', 'event', 'simpleColor', 'sizes', 'priceRange', 'partner', 'page') as $template){
 *             $url = preg_replace("#{$template}/[^/]+/?#i", "", $url);
 *         }
 *         return $url;
 */