import React, {ReactNode, useContext, useState, useEffect, useRef} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";

export default function RightModal({children, additionalClass = 'modal-form', closeFunction}: {children: ReactNode, additionalClass?: string, closeFunction?: () => void}) {
    const [error, setError] = useState('')
    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);
    const modalRef = useRef<HTMLDivElement>(null);

    const renderChildren = () => {
        return React.Children.map(children as React.ReactElement<any>, (child) => {
            return React.cloneElement(child, {
                error: error,
                setError: setError
            });
        });
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                dispatch({ type: ReducerActionType.CLOSE_ALL_POPUP });
                closeFunction && closeFunction();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch, closeFunction]);

    return (<div className="modal fade modal-main show">
        <div className="modal-dialog" role="document"  ref={modalRef}>
            <div className="modal-content">
                <button type="button" className="close" onClick={() => {
                    dispatch({type: ReducerActionType.CLOSE_ALL_POPUP})
                    {closeFunction && closeFunction()}
                }}></button>
                <div className="modal-body">
                    <div className={additionalClass}>
                        {renderChildren()}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}