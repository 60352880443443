import Breadcrumbs from "../Breadcrumbs";
import TableSizes from "../../catalog/table-size/TableSizes";

import {useContext} from "react";
import {GlobalContext, ReducerActionType} from "../../../GlobalReducer";
import {ReducerStateInterface} from "../../../interfaces/core/ReducerStateInterface";


export default function Sizes() {

    const [state, dispatch] = useContext<[ReducerStateInterface, Function]>(GlobalContext);

    const getBreadcrumbs = () => {
        return [
            {
                icon: "ico-home-g",
                url: "/",
                isActive: true
            },
            {
                name: 'Таблица размеров'
            }
        ]
    }

    return (<main className="main-payment">
            <section className="text-page pt">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <Breadcrumbs breadcrumbs={getBreadcrumbs()} />
                        </div>
                    </div>
                    <div className="row text-page__wrap">
                        <div className="f-exp">
                            <h1 className="text-page__title">Таблица размеров</h1>
                            <TableSizes />
                        </div>
                    </div>
                    <div className="row text-page__banners">
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="img/text-page-banner-1.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="img/text-page-banner-1.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Магазины</h2>
                                <p>
                                    Найти ближайший магазин Baon
                                    <br/> в России
                                </p>
                                <a className="btn btn-primary" href="/shops">
                                    Найти магазин
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-page__banner">
                                <picture>
                                    <source
                                        srcSet="img/text-page-banner-2.jpg"
                                        media="(max-width: 992px)"
                                    />
                                    <img src="img/text-page-banner-2.jpg" alt=""/>
                                </picture>
                                <h2 className="f-exp">Есть вопрос?</h2>
                                <p>
                                    Звоните 8 (800) 500-73-17 (звонок по России
                                    <br/> бесплатный) или задайте вопрос на сайте
                                </p>
                                    <button className="btn btn-primary" onClick={(event) => {
                                    event.preventDefault()
                                    dispatch({type: ReducerActionType.TOGGLE_QUESTION_POPUP})
                                }}>
                                    Подробнее
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}